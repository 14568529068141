/* @flow */

export function getCaligrafixBookProgress({ child_id }) {
    return {
      type: 'REQUEST_CALIGRAFIX_BOOK_PROGRESS',
      payload: {
        child_id
      }
    };
  }
  