/* @flow */

import React from 'react';
import {
  ResponsiveContainer, Legend, LineChart, XAxis, YAxis, CartesianGrid,
  Tooltip, Line
} from 'recharts';
import _ from 'lodash';
import classNames from 'classnames';
import { shouldUpdate, compose } from 'recompose';

type Props = {
  data: {
    name: string,
    value: number
  },
  height?: number,
  width?: number,
  linesData?: Array<Object>
};

const xAxisStyles = {
  fill: '#989898',
  fontSize: '0.75rem',
  fontWeight: 'normal'
};

function renderLine(id, name, color) {
  return (
    <Line
      key={id}
      name={name}
      type="linear"
      isAnimationActive={false}
      dataKey={id}
      stroke={color}
      strokeWidth={2}
      dot={{ fill: color, strokeWidth: 2 }}
      activeDot={{ r: 8 }} />
  );
}

/**
 * A component that renders data into a LineChart component from Recharts
 *
 * @method LineChartWrapper
 * @param   {Object}        data  - an object of type Props to render into the LineChart
 *
 * We can't use ResponsiveContainer from Recharts as it causes tests to fail, in order
 * to set a width for the chart using media queries, you must use react-responsive:
 *
 * @example
 *
 * <MediaQuery maxWidth={576}>
 *   <LineChart width={260} height={240} data={data} />
 * </MediaQuery>
 * <MediaQuery minWidth={768}>
 *   <LineChart width={600} height={240} data={data} yAxis />
 * </MediaQuery>
 *
 */
const LineChartWrapper = (props: Props) => {
  // The axis puts a lot of margin to the left
  // We're removing that space when we display the axis.
  const leftMargin = props.yAxis ? -30 : 10;

  const containerClass = classNames('linechart-container', { 'linechart-container--loading': props.loading });

  const formatter = (value) => {
    const roundedValue = Math.round(value * 100) / 100;

    return roundedValue + '%';
  };

  return (
    <ResponsiveContainer>
      <LineChart
        {...props}
        margin={{top: 10, right: 10, left: leftMargin, bottom: 5}}
        className={containerClass}>
        <XAxis
          axisLine={false}
          tick={xAxisStyles}
          dataKey="name"
          padding={{ left: 20, right: 20 }}
          tickLine={false} />
        {
          props.yAxis ? (
          <YAxis
            tick={{ fontSize: '0.625rem' }}
            tickLine={false}
            axisLine={false}
            interval={0}
            tickCount={6}
            domain={[0, 100]}
            />
          ) : null
        }
        <CartesianGrid vertical={false} />
        {props.linesData ? null : <Tooltip separator="" formatter={formatter}/>}
        {props.linesData && <Legend verticalAlign="bottom" iconType="line" />}
        {
          props.linesData
            ? props.linesData.map(({ id, name, color }) => renderLine(id, name, color))
            : renderLine('value', 'value', '#16cc90')
        }
      </LineChart>
    </ResponsiveContainer>
  );
};

export const LineChartWrapperLifecycle = {
  componentShouldUpdate: (props, nextProps) => {
    return !_.isEqual(props, nextProps);
  }
};

const LineChartWrapperComposed = compose(
  shouldUpdate(LineChartWrapperLifecycle.componentShouldUpdate)
)(LineChartWrapper);

export default LineChartWrapperComposed;
