/* @flow */

export function getAchievementCards({ intelligence_id, language }: Object) {
  return {
    type: 'REQUEST_ACHIEVEMENT_CARDS',
    payload: {
      intelligence_id,
      language
    }
  };
}

export function getAchievementCardScores({ intelligence_id, child_id }: Object) {
  return {
    type: 'REQUEST_ACHIEVEMENT_CARD_SCORES',
    payload: {
      intelligence_id,
      child_id
    }
  };
}
