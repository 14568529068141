/* @flow */

import React from 'react';
import { connect } from 'react-redux';
import { compose, pure, withHandlers } from 'recompose';
import { CollapseGroup } from '../components/';
import type { RecomposeHandler, ReduxFunction } from '../components/types';
import { toggleSchoolLevelsCollapseItem } from '../../actions/schoolLevelsCollapseStructure';
import { isSchoolLevelsCollapseExpanded } from '../../selectors/schoolLevelsCollapseStructure';

type Props = {
  id: string,
  onHeaderClick: () => mixed,
  headerClickHandler: RecomposeHandler,
  dispatch: ReduxFunction
};

const CollapseGroupContainer = ({ headerClickHandler, ...otherProps }: Props) => {
  return (
    <CollapseGroup
      {...otherProps}
      onHeaderClick={headerClickHandler}
    />
  );
};

export const CollapseGroupContainerHandlers = {
  headerClickHandler: ({ dispatch, onHeaderClick, id, expanded }: Props) => () => {
    // only call onHeaderClick if the state is going from closed to open
    if (!expanded && onHeaderClick) {
      onHeaderClick();
    }

    dispatch(toggleSchoolLevelsCollapseItem(id));
  }
};

const CollapseGroupContainerComposed = compose(
  withHandlers(CollapseGroupContainerHandlers),
  pure
)(CollapseGroupContainer);

const mapStateToProps = (state: Object, ownProps: Object) => ({
  expanded: isSchoolLevelsCollapseExpanded(state, ownProps.id),
  all: ownProps.all,
  hideButtons: ownProps.hideButtons
});

const mapDispatchToProps = (dispatch) => ({ dispatch });

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CollapseGroupContainerComposed);
