/* @flow */

import React from 'react';
import { connect } from 'react-redux';
import { pure, compose, withHandlers } from 'recompose';
import { PleIQCodeForm } from '../components';
import { startSubmit } from 'redux-form';
import { FORM_NAME as PLEIQ_CODE_FORM_NAME } from '../components/PleIQCodeForm';
import type { RecomposeHandler } from '../components/types';

type Props = {
  onSubmit: RecomposeHandler
};

const PleIQCodeFormContainer = ({ onSubmit }: Props) => {
  return <PleIQCodeForm onSubmit={onSubmit} />;
};

export const PleIQCodeFormContainerHandlers = {
  onSubmit: (props: Object) => ({ pleiq_code }: Object) => {
    const { dispatch } = props;
    const actionType = 'REQUEST_PARENT_ACTIVATE';
    const payload = {
      parent: {
        pleiq_code
      }
    };

    dispatch(startSubmit(PLEIQ_CODE_FORM_NAME));
    dispatch({ type: actionType, payload });
  }
};

const PleIQCodeFormContainerEnhancements = compose(
  withHandlers(PleIQCodeFormContainerHandlers)
);

const mapDispatchToProps = (dispatch) => {
  return { dispatch };
};

const PleIQCodeFormContainerComposed = compose(
  PleIQCodeFormContainerEnhancements,
  pure
)(PleIQCodeFormContainer);

const PleIQCodeFormContainerConnected = connect(
  null,
  mapDispatchToProps
)(PleIQCodeFormContainerComposed);

export default PleIQCodeFormContainerConnected;
