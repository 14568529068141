/* @flow */

import { call, takeEvery, all } from 'redux-saga/effects';
import { workerRequest } from '../diplomatico/workers';
import type { Action } from '../diplomatico/types';

/**
 * Worker to handle the request of CaligrafixBook History
 */
export const workerRequestCaligrafixBookHistoryOptions = ({ child_id, caligrafix_book_id, group_by, start_date, end_date }) => ({
  requestParams: {
    endpoint: `/children/${child_id}/caligrafix_book_scores/history?caligrafix_book_id=${caligrafix_book_id}&start_date=${start_date}&end_date=${end_date}&group_by=${group_by}`,
    method: 'get',
    child_id,
    caligrafix_book_id,
    group_by
  }
});

export function* workerRequestCaligrafixBookHistory(action: Action): any {
  let workerRequestGenerator = workerRequest(workerRequestCaligrafixBookHistoryOptions(action.payload));

  yield call(workerRequestGenerator, action);
}

export function* watchCaligrafixBookHistoryRequest(): any {
  yield all([
    takeEvery('REQUEST_CALIGRAFIX_BOOK_HISTORY', workerRequestCaligrafixBookHistory)
  ]);
}
