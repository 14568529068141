/* @flow */

import React from 'react';
import { TopicProgress, BookProgress } from '../components/';
import  LoadingStatus from "../containers/LoadingStatus"
import  RequestError from "../containers/RequestError"
import { getCaligrafixBookProgressById } from '../../selectors/caligrafixBookProgress';
import { pure, compose } from 'recompose'; 
import _ from 'lodash';

/**
 * Component that receives the data to display all the progress
 * over each of the CaligrafixBooks in a TopicProgress Component
 * @method GeneralCaligrafixBookProgressContainer
 * @param  {array}               currentProgress - injected by Redux includes all intelligence_progress from the store for the current child
 * @param  {array}               caligrafixBooks - injected by Redux includes all caligrafixBooks from the store
 */
export const GeneralCaligrafixBookProgressContainer = ({ user_books, currentProgress, caligrafixBooks }) => {
  const currentCaligrafixBookProgress = getCaligrafixBooksProgress({ user_books,currentProgress, caligrafixBooks });
 
  return (
    <div>

      <LoadingStatus request={["CALIGRAFIX_BOOKS","CALIGRAFIX_BOOK_PROGRESS"]}/> 

      {currentCaligrafixBookProgress.map((book,index) => 
      (book.visible)?<BookProgress key={book.id} slug={book.slug} value={book.value} />: null)}
    </div>
  );
};

export const getCaligrafixBooksProgress = ({ currentProgress, caligrafixBooks ,user_books}) => {
  return _.map(caligrafixBooks, (caligrafixBook) => {
    const currentCaligrafixBookProgress = getCaligrafixBookProgressById({ caligrafix_book_progress: currentProgress }, caligrafixBook.id);
    const progress = _.get(currentCaligrafixBookProgress, 'score') || 0; 
    return {
      id: caligrafixBook.id,
      slug: caligrafixBook.slug,
      value: parseInt(progress, 10),

      visible: caligrafixBook.active_in_schools || user_books.includes(caligrafixBook.slug)
    };
  });
};

const GeneralCaligrafixBookProgressContainerComposed = compose(
  pure
)(GeneralCaligrafixBookProgressContainer);

export default GeneralCaligrafixBookProgressContainerComposed;
