/* @flow */

import { call, takeEvery, all } from 'redux-saga/effects';
import { workerRequest } from '../diplomatico/workers';
import type { Action } from '../diplomatico/types';

/**
 * Worker to handle the request of Student Intelligence Progress
 */
export const workerRequestStudentIntelligenceProgressOptions = (student_id: string) => ({
  requestParams: {
    endpoint: `/school/students/${student_id}/intelligence_scores/current`,
    method: 'get',
    student_id
  }
});

export function* workerRequestStudentIntelligenceProgress(action: Action): any {
  let { student_id } = action.payload;
  let workerRequestGenerator = workerRequest(workerRequestStudentIntelligenceProgressOptions(student_id));

  yield call(workerRequestGenerator, action);
}

export const workerRequestStudentIntelligenceHistoryOptions = ({ student_id, intelligence_id, group_by, start_date, end_date }) => ({
  requestParams: {
    endpoint: `/school/students/${student_id}/intelligence_scores/history?intelligence_id=${intelligence_id}&start_date=${start_date}&end_date=${end_date}&group_by=${group_by}`,
    method: 'get',
    student_id,
    intelligence_id,
    group_by
  }
});

export function* workerRequestStudentIntelligenceHistory(action: Action): any {
  let workerRequestGenerator = workerRequest(workerRequestStudentIntelligenceHistoryOptions(action.payload));

  yield call(workerRequestGenerator, action);
}

export const workerRequestStudentAchievementCardScoresOptions = ({ intelligence_id, student_id }) => ({
  requestParams: {
    endpoint: `/school/students/${student_id}/intelligences/${intelligence_id}/achievement_card_scores/current`,
    method: 'get',
    intelligence_id,
    student_id
  }
});

export function* workerRequestStudentAchievementCardScores(action: Action): any {
 let workerRequestGenerator = workerRequest(workerRequestStudentAchievementCardScoresOptions(action.payload));

 yield call(workerRequestGenerator, action);
}

export function* watchStudentIntelligenceProgressRequest(): any {
  yield all([
    takeEvery('REQUEST_STUDENT_INTELLIGENCE_PROGRESS', workerRequestStudentIntelligenceProgress),
    takeEvery('REQUEST_STUDENT_INTELLIGENCE_HISTORY', workerRequestStudentIntelligenceHistory),
    takeEvery('REQUEST_STUDENT_ACHIEVEMENT_CARDS_PROGRESS', workerRequestStudentAchievementCardScores)
  ]);
}
