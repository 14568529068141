import React from 'react';
import ReactDOM from 'react-dom';
import Root from './Root';
import 'whatwg-fetch';
import 'moment';

/*
  require all the languages needed within the application,
  in order to be able to set the locale globally by running
  moment.locale(xx);
  https://github.com/moment/moment/issues/1875#issuecomment-263220119
 */
import 'moment/locale/es.js';

ReactDOM.render(
  React.createElement(Root),
  document.getElementById('root')
);
