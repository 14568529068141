/* @flow */

import React from 'react';
import { GeneralIntelligenceProgressContainer } from './';
import { pure, compose, lifecycle } from 'recompose';
import { getAllIntelligenceProgressByChild } from '../../selectors/intelligenceProgress';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import actions from '../../actions';
import { findStudentAcrossReducers } from '../../selectors/students';
import _ from 'lodash';

/**
 * Container that receives the data to display all the progress
 * over each of the Intelligences
 * @method StudentIntelligenceProgressContainer
 * @param  {array}               currentStudentProgress - injected by Redux includes all intelligence_progress from the store for the current child
 * @param  {array}               intelligences - injected by Redux includes all intelligences from the store
 */
export const StudentIntelligenceProgressContainer = ({ currentStudentProgress, intelligences }) => {
  return (
    <GeneralIntelligenceProgressContainer currentProgress={currentStudentProgress} intelligences={intelligences} />
  );
};

export const StudentIntelligenceProgressContainerLifecycle = {
  componentDidMount() {
    const { dispatch, student_id } = this.props;

    if (student_id) {
      dispatch(actions.getStudentIntelligenceProgress({ student_id }));
    }
  }
};

const StudentIntelligenceProgressContainerComposed = compose(
  lifecycle(StudentIntelligenceProgressContainerLifecycle),
  pure
)(StudentIntelligenceProgressContainer);

const mapStateToProps = (state, ownProps) => {
  const { student_intelligence_progress, intelligences } = state;
  const { params } = ownProps.match;
  const { student_id } = params;

  const student = findStudentAcrossReducers(state, student_id);
  const currentStudentProgress = getAllIntelligenceProgressByChild({ intelligence_progress: student_intelligence_progress }, student_id);

  return {
    student_id: _.get(student, 'id'),
    currentStudentProgress,
    intelligences
  }
};

const mapDispatchToProps = (dispatch) => ({ dispatch });

const IntelligenceProgressContainerConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(StudentIntelligenceProgressContainerComposed);

export const StudentGeneralIntelligencesStatic = ({student_id})=>{
  return <IntelligenceProgressContainerConnected match={{params: {student_id}}}/>;
}
export default withRouter(IntelligenceProgressContainerConnected);

/*
let esr = 0;

export const StudentGeneralIntelligencesPdf = connect(({}, props)=>{return {...props, esr: esr++};},mapDispatchToProps)( withPdf({pre:({dispatch,student_id})=>{ 
  dispatch(actions.getStudentIntelligenceProgress({ student_id }));
  return true;
}}, ({student_id})=>{
  return <IntelligenceProgressContainerConnected match={{params: {student_id}}}/>;
}) );*/