/* @flow */

import React from 'react';

/**
 * Model to hold the information related to all the supported intelligences
 * within our application this will avoid us to list the same information
 * in different parts of the project
 */
class Intelligences {
  static TYPES = {
    LIN: 'linguistics',
    MAT: 'logic',
    NAT: 'nature',
    VIS: 'visual',
    MUS: 'music',
    KIN: 'kinesthetic',
    INTRA: 'intrapersonal',
    INTER: 'interpersonal'
  };

  static DEFAULT_SLUG = 'lin';

  static ICON_LIN = 'pleiq-icon-icon_intelligence_linguistic';

  static ICON_MAT = 'pleiq-icon-icon_intelligence_logical';

  static ICON_NAT = 'pleiq-icon-icon_intelligence_naturalistic';

  static ICON_VIS = 'pleiq-icon-icon_intelligence_visual';

  static ICON_MUS = 'pleiq-icon-icon_intelligence_musical';

  static ICON_KIN = 'pleiq-icon-icon_intelligence_kinesthetic';

  static ICON_INTRA = 'pleiq-icon-icon_intelligence_intrapersonal';

  static ICON_INTER = 'pleiq-icon-icon_intelligence_interpersonal';

  static COLOR_LIN = '#FF5A66';

  static COLOR_MAT = '#FCC035';

  static COLOR_NAT = '#00CD93';

  static COLOR_VIS = '#F7602C';

  static COLOR_MUS = '#DD6DFA';

  static COLOR_KIN = '#678CFF';

  static COLOR_INTRA = '#FF7AC4';

  static COLOR_INTER = '#25CEF0';

  static getIntelligenceIcon = (type: $Keys<typeof Intelligences.TYPES>) => {
  const iconClass = Intelligences.getIntelligenceProperty(type, 'ICON');
    return <span className={iconClass} />;
  }

  static getIntelligenceLabel = (type: $Keys<typeof Intelligences.TYPES>) => {
    return Intelligences.TYPES[type];
  }

  static getIntelligenceProperty = (type: $Keys<typeof Intelligences.TYPES>, property: 'ICON') => {
    let key = `${property}_${type.toUpperCase()}`;
    return Intelligences[key];
  }

  static getIntelligenceColor = (type: $Keys<typeof Intelligences.TYPES>) => {
    return Intelligences.getIntelligenceProperty(type, 'COLOR');
  }

  static getIntelligenceInformation = (type: $Keys<typeof Intelligences.TYPES>) => {
    return {
      label: Intelligences.getIntelligenceLabel(type),
      icon: Intelligences.getIntelligenceIcon(type),
      color: Intelligences.getIntelligenceColor(type)
    };
  }
}

export default Intelligences;
