/* @flow */

import { put, takeEvery, all } from 'redux-saga/effects';
import { reset, stopSubmit } from 'redux-form';
import { workerRequest } from '../diplomatico/workers';
import { FORM_NAME as UNIQUE_ACCOUNT_FORM } from '../js/components/UniqueAccountForm';
import { addAlert } from '../actions/alerts';
import { exitEditMode } from '../actions/uniqueAccount';

/**
 * Worker to get unique account information
 */
export const workerRequestUniqueAccountOptions = {
  requestParams: {
    endpoint: `/school/unique_account`,
    method: 'get'
  }
};

export const workerRequestUniqueAccount = workerRequest(workerRequestUniqueAccountOptions);

/**
 * Worker to update an specific level for current school
 */
export const workerRequestUniqueAccountUpdateOptions = {
  requestHandlers: {
    onSuccess: function* ({ action, response }: Object): any {
      yield put(addAlert({ type: 'success', message: 'password_succesfully_updated' }));
      yield put(reset(UNIQUE_ACCOUNT_FORM));
      yield put(exitEditMode());
      yield put(stopSubmit(UNIQUE_ACCOUNT_FORM));
    },
    onError: function* ({ action, response }: Object): any {
      yield put(reset(UNIQUE_ACCOUNT_FORM));
      yield put(exitEditMode());
      yield put(stopSubmit(UNIQUE_ACCOUNT_FORM));
    }
  },
  requestParams: {
    endpoint: `/school/unique_account`,
    method: 'put'
  },
  buildRequestBody: (actionPayload: Object) => actionPayload
};

export const workerRequestUniqueAccountUpdate = workerRequest(workerRequestUniqueAccountUpdateOptions);

export function* watchUniqueAccountRequest(): any {
  yield all([
    takeEvery('REQUEST_UNIQUE_ACCOUNT', workerRequestUniqueAccount),
    takeEvery('REQUEST_UNIQUE_ACCOUNT_UPDATE', workerRequestUniqueAccountUpdate)
  ]);
}
