/* @flow */

import React from 'react';
import { Collapse } from 'reactstrap';
import classNames from 'classnames';
import { compose, pure } from 'recompose';
import CollapseHeader from './CollapseHeader';

type Props = {
  title: string,
  icon: string,
  count: string,
  expanded: boolean,
  children: mixed,
  className?: string,
  onHeaderClick: () => mixed,
  onEditButtonClick: () => mixed
};

/**
 * component to display the header of a CollapseGroup
 *
 * @method CollapseGroup
 * @param  {string}            title                - title for the header
 * @param  {string}            icon                 - icon class to display, should be one of the keys on the `$icons` map at `src/styles/_settings--icons.scss`
 * @param  {string}            count                - count of how many courses or students
 * @param  {boolean}           expanded             - indicates if collapse should be open (expanded)
 * @param  {React.Components}  children             - a tree of elements within the component
 * @param  {string}            className            - additional (and optional) className for the wrapper div
 * @param  {function}          onHeaderClick        - callback for when the whole header gets clicked
 * @param  {function}          onEditButtonClick    - callback for the edit button
 *
 */
const CollapseGroup = ({ title,editRoles, icon, all=false, hideButtons=false, count, expanded, children, className, onHeaderClick, onEditButtonClick,onPdfButtonClick }: Props) => {
  const collapseClassName = classNames('collase-group', className);
  return (
    <div className={collapseClassName}>
      <CollapseHeader  editRoles={editRoles} useButtons={!all&&!hideButtons } title={title} count={count} icon={icon} expanded={expanded || all } onHeaderClick={onHeaderClick} onEditButtonClick={onEditButtonClick} onPdfButtonClick={onPdfButtonClick} />
      <Collapse isOpen={expanded || all }>
        {children}
      </Collapse>
    </div>
  );
};

const CollapseGroupEnchanced = compose(
  pure
)(CollapseGroup);

export default CollapseGroupEnchanced;
