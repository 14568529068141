/* @flow */

import React from 'react';
import { Paper, InfoTooltip } from '../components';
import { Col, Row } from 'reactstrap';
import {
  SchoolDashboardNavContainer, SchoolDashboardHeadingContainer, HeaderContainer,
  IntelligencesNavContainer, SchoolGeneralTimelineProgressContainer,
  SchoolGeneralAchievementCardProgressContainer, PageTitleContainer
} from '../containers';
import { Intelligences } from '../models';
import Translations from '../hocs/TranslationsHelper'; 
import { ChallengesReportPdf } from '../pdfview/ChallengesView';
import topScroll from '../hocs/topScroll';


/**
 * screen for the school SchoolChallenges
 *
 * @method SchoolChallenges
 *
 */
const SchoolChallenges = ({ translations }) => {
  topScroll();
  
  return (
    <div id="school-dashboard" className="school-dashboard layout-base">
      <PageTitleContainer pageTitle="title_school_challenges" />
      <HeaderContainer />
      <SchoolDashboardHeadingContainer />
      <Paper.Container lead>
        <SchoolDashboardNavContainer currentTab="challenges"  pdfTarget={"pleiq_report_school_challenges"} pdfFilter="challenges_report"/>
        <Row>
          <Col xs="12">
            <Paper.Title>
              <h1>{translations.t('school_progress_by_intelligence_title')}</h1>
            </Paper.Title>
          </Col>
          <Col xs="12">
            <IntelligencesNavContainer redirectRouteBase="/school/challenges" defaultRoute={`/school/challenges/${Intelligences.DEFAULT_SLUG}`} />
          </Col>
          <Col xs="12">
            <h5 className="mt-2 mb-4">{translations.t('intelligence_screen_timeline_label')}</h5>
          </Col>
          <Col xs="12">
            <SchoolGeneralTimelineProgressContainer />
          </Col>
          <Col xs="12">
            <Paper.Title>
              <h5 className="mt-5 mb-4">
                {translations.t('intelligence_screen_achievements_label')} {' '}
                <InfoTooltip message="tooltips_intelligence_screen_achievements_label" />
              </h5>
            </Paper.Title>
          </Col> 
          <Col xs="12">
            <SchoolGeneralAchievementCardProgressContainer />
          </Col>
        </Row>
      </Paper.Container>
      <ChallengesReportPdf/>
    </div>
  );// <PdfData path={"school_challenges"}>
};

export default Translations(SchoolChallenges);
