

import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

class CanvasInterface{

}

const idleStart=140/30.0, idleEnd =270/30.0;

let audioCtx = null;
export const Canvas3D = ({className, bridge,modelId})=>{
 


    const winRef = useRef();
    let scene = null; 
    let renderer = null;    
    useEffect(()=>{
        scene = new THREE.Scene();
        const camera = new THREE.PerspectiveCamera( 35, window.innerWidth / window.innerHeight, 0.1, 1000 );
        const geometry = new THREE.BoxGeometry( 1, 1, 1 );
        const material = new THREE.MeshBasicMaterial( { color: 0x00ff00 } );
        camera.position.z = 2.5;
        camera.position.y = 1.1;
        renderer = new THREE.WebGLRenderer();
        const directionalLight = new THREE.DirectionalLight( 0xffffff, 1.0 );
        scene.add( directionalLight );
        const light = new THREE.AmbientLight( 0x808080 ); // soft white light
        scene.add( light );
        directionalLight.position.set(1,1,1) ;
        scene.background  = new THREE.Color(0xe4b949);

        let audio1 = null;


        let audioSource = null;
        let analyser = null;
        let dataArray = null;
        
        bridge.stop = ( )=>{
            if(audio1)
                audio1.pause();

        }
        bridge.onNewClip = (src, salute)=>{
            if(audio1)
                audio1.pause();

            
            audio1 = new Audio(src); 

            if(audioCtx==null) audioCtx = new (window.AudioContext || window.webkitAudioContext)();

            audio1.play();
            audioSource = audioCtx.createMediaElementSource(audio1);
            analyser = audioCtx.createAnalyser();
            audioSource.connect(analyser);
            analyser.connect(audioCtx.destination);
            analyser.fftSize = 32;
            dataArray = new Float32Array(analyser.fftSize );
            if(salute && mixer)
                mixer.setTime(0);

        }
        const rezi = ()=> { 
            if(winRef.current){

                renderer.setSize( winRef.current.clientWidth, winRef.current.clientHeight );
                camera.aspect = winRef.current.clientWidth / winRef.current.clientHeight
                camera.updateProjectionMatrix();
            }
        }
        const ro = new ResizeObserver(rezi) 
        ro.observe(winRef.current);
        rezi();
        winRef.current.appendChild( renderer.domElement ); 
        let   clock = new THREE.Clock();
        let mixer = null;
        let cancel = false;
        let meshObj = null;
        function animate() {
            if(cancel)return; 
            requestAnimationFrame( animate );
            const delta = clock.getDelta();
            if ( mixer ) {
                mixer.update( delta );
                if(mixer.time > idleEnd){
                    mixer.setTime(idleStart); 
                }

            }
            if(meshObj && analyser){
                analyser.getFloatTimeDomainData(dataArray);
                meshObj.morphTargetInfluences[ 3]=Math.abs(dataArray[0])*2;
            }

            renderer.render( scene, camera );
        }
        animate();

        const loader = new GLTFLoader();
        loader.load(
            // resource URL
            '/resources/' + (modelId || 'gptx_avatar.gltf'),
            // called when the resource is loaded
            function ( gltf ) {
        
                scene.add( gltf.scene );
                mixer = new THREE.AnimationMixer( gltf.scene );
                
                meshObj = gltf.scene.getObjectByName("Companion_Body"); 
                /*if(jacketColor){

                    let jacket = gltf.scene.getObjectByName("companion_female_teacher_jacket2").children[0]; 
                    jacket.material.color.setHex(jacketColor);
                }*/

                gltf.animations.forEach( ( clip ) => { 
                    mixer.clipAction( clip ).play();
                    
                } );
                mixer.setTime(idleStart);
        
        
            },
            // called while loading is progressing
            function ( xhr ) {
        
                console.log( ( xhr.loaded / xhr.total * 100 ) + '% loaded' );
        
            },
            // called when loading has errors
            function ( error ) {
        
                console.error( 'An error happened', error );
        
            }
        );
        
        return ()=>{
            cancel = true;
            bridge.onNewClip = null;

            if(winRef.current)
                ro.unobserve(winRef.current);
        };
    }, [])

    return <div ref={winRef} className={className}/>;
}

export default Canvas3D;