import React from 'react';
import { GeneralCaligrafixProgress } from '../components/GeneralCaligrafixProgress';
import { mockBooks } from '../mock/cx';
import CaligrafixProgressAnalisis from '../components/CaligrafixProgressAnalisis';
import { Paper, InfoTooltip } from '../components';
import { Col, Row } from 'reactstrap';
import Translations from '../hocs/TranslationsHelper';
import  {StudentCaligrafixBookProgressContainer}  from './';

const mockProgress = [
    {book_id:"0", score:50},
    {book_id:"1", score:60},
    {book_id:"2", score:70},
    {book_id:"3", score:20},
    {book_id:"4", score:30} 
  ];
  
export const StudentCaligrafixProgressContainer = Translations(
    ({translations})=>{
        return <div style={{width:"100%", height:"100%"}}>

            <Col xs="12">
                <Paper.Title> 
                    <h1>
                    { translations.t('tooltips_current_caligrafix_progress') }{' '} 
                    <InfoTooltip message="caligrafix_current_general_progress" />
                    </h1>  
                </Paper.Title>
            </Col>
            <Col xs="12">
                <Paper.Content> 
                    <StudentCaligrafixBookProgressContainer/>
                </Paper.Content>
            </Col>

            <Col xs="12">
                <Paper.Title> 
                    <h1 className="mt-5">
                    { translations.t('caligrafix_progress_analysis') } {' '} 
                <InfoTooltip message="tooltips_intelligences_progress_analysis" />
                    </h1> 
                </Paper.Title>
            </Col>
            <Col xs="12">
                <Paper.Content> 
                    <CaligrafixProgressAnalisis/>
                </Paper.Content>
            </Col> 
        </div>
    }
);
