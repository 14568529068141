/* @flow */

import React from 'react';
import { Modal, Paper } from './';

const HTMLContentModal = ({ title, html, active, onCancel }: Props) => {
  return (
    <Modal backdropClassName="overlay-backdrop"
           contentClassName="overlay-modal-content"
           className="html-content-modal-container"
           active={active}
           toggle={onCancel}>
      <div className="html-content-modal">
        <span className="modal-close pleiq-icon-icon_plus" onClick={onCancel} />
        <Paper.Container>
          <div className="html-content-modal">
            <h4 className="html-content-modal-title">
              {title}
            </h4>
            {
              (Array.isArray(html))?
                html.map((frag)=>(
                  <div className="html-content-modal-content" dangerouslySetInnerHTML={{ __html: frag }} />
                )) 
              :<div className="html-content-modal-content" dangerouslySetInnerHTML={{ __html: html }} />
            }
           
          </div>
        </Paper.Container>
      </div>
    </Modal>
  );
};

export default HTMLContentModal;
