import { SchoolGeneralAchievementCardProgressContainerPdf } from "../containers/SchoolGeneralAchievementCardProgressContainer";
 
import React from 'react';
import {connect} from 'react-redux' 

import { withPdfHandlers, PdfOnlyData } from "../../pdfer/Pdfable";
 
//import _ from 'lodash'; 
import { getIntelligenceBySlug } from "../../selectors/intelligences";
import { withRouter } from 'react-router-dom';
import { SchoolGeneralTimelineProgressContainerPdf } from "../containers/SchoolGeneralTimelineProgressContainer";
//import { Paper } from "../components"; 
import Translations from '../hocs/TranslationsHelper';

/*
var ChallengesReportDiv =  ( {intelligences, translations}) =>(
    <div> 
            
        { 
            intelligences.map( 
                (intelligence) =>(
                    <div>  
                        <PdfOnlyData path={"school.intelligence_"+intelligence.slug}>
                            <h2>name: {intelligence.name}</h2> 
                        </PdfOnlyData>
                        <SchoolGeneralAchievementCardProgressContainerPdf path={"school.intelligence_data_"+intelligence.slug} match={{params:{slug: intelligence.slug}}}/>
                    </div>
                )
            ) 
        } 
    </div>
)*/

var ChallengesReportDiv =  Translations(( {intelligence, translations}) =>(
    <div>  
        <PdfOnlyData path={"school.intelligence_"+intelligence.slug}> 
            <h1>  {translations.t(intelligence.slug.toUpperCase())}</h1>  
            <h5 className="mt-2 mb-4">{translations.t('intelligence_screen_timeline_label')}</h5>
        </PdfOnlyData> 
        <SchoolGeneralTimelineProgressContainerPdf  path={"school.intelligence_hist_"+intelligence.slug} match={{params:{slug: intelligence.slug}}}/>
        
 
        <SchoolGeneralAchievementCardProgressContainerPdf path={"school.intelligence_data_"+intelligence.slug} match={{params:{slug: intelligence.slug}}}/>

    </div>
) );

const mapStateToProps  = (state, ownProps)=>{ 
    //var {pdf} = state;
    var {intelligences} = state; 
    return {
        intelligences: intelligences,
        intelligence: getIntelligenceBySlug(state,ownProps.match.params.slug)
    };
}
const mapDispatchToProps  = (dispatch) => ({ dispatch });
export var ChallengesReportPdf =withRouter( connect(mapStateToProps ,mapDispatchToProps )( withPdfHandlers (
    {path:"school.challenges", 
    filter: "challenges_report",
    pre: ({intelligences/*, dispatch*/}) =>{ 
      //  console.log("HELLO CHALLENGE!");

        if(!intelligences){//undefined course name
         //   console.log("intelligences is undefined, what should we do? ..."); 
          // dispatch({ type: 'REQUEST_LEVELS' });
          // dispatch({ type: 'REQUEST_ALL_COURSES' });
           
          // return true;//wait for students, ok? 
        }
        return false;
    }
})(ChallengesReportDiv)));