/* @flow */

import { responseHasError } from '../diplomatico/reducers/helper';
import type { Action } from '../diplomatico/types';
import { enableInitializing } from 'initializable-reducer';
import _ from 'lodash';

const initialState = {};

function intelligenceHistory(state: Object = initialState, { type, payload }: Action) {
  switch (type) {
    case 'RESPONSE_INTELLIGENCE_HISTORY':
      if (responseHasError(payload)) {
        return state;
      }

      const { child_id, intelligence_id, group_by } = payload._meta.payload.request;

      const newHistory = {
        [child_id]: {
          [intelligence_id]: {
            [group_by]: payload.body
          }
        }
      };

      return _.merge({}, state, newHistory);
    default:
      return state;
  }
}

export {
  intelligenceHistory,
  initialState
};

export default enableInitializing(intelligenceHistory);
