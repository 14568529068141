/* @flow */

import React, { useContext, useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { Paper, InfoTooltip } from '../../components/';
import {
  DataDisplayParentContainer, IntelligencesTopicContainer, IntelligencesProgressContainer,
  ActivityFeedContainer, HeaderContainer, PageTitleContainer, SchoolDashboardNavContainer, SchoolDashboardHeadingContainer
} from '../../containers/'; 
import Translations from '../../hocs/TranslationsHelper';
import TranslationsHelper from '../../hocs/TranslationsHelper';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'react-router-redux';
import { SchoolRoleFilter } from '../../components/SchoolRoleFilter';
import topScroll from '../../hocs/topScroll';
 
import LicenseFilter from '../../containers/LicenseFilter';
import icon_activity_generator_par from '../../../img/home/icon_activity_generator_parvulario.png';
import icon_activity_generator_bas from '../../../img/home/icon_activity_generator_basica.png';
 
const pgLocations = {  
  "CL": [
    {
      name: "Para Educación Parvularia",
      location: "CL",
      desc: "Genera tus planificación de clase para Educación Parvularia. Ambito, Nucleo, Objetivos.",
      icon: icon_activity_generator_par
    },
    {
      name: "Para Educación Básica",
      location: "CL_EB",
      desc: "Genera tus planificación de clase para Educación Básica. Eje, Nivel, Objetivos.",
      icon: icon_activity_generator_bas
    }

  ]
};


const PGLink = TranslationsHelper(  ({name,desc,to ,icon})=>{ 
  const dispatch = useDispatch(); 
     
  
  //( premium_status=="none" && pro );
 const color =  "blue";

  return  <div className={"school-home-link school-home-"+color} onClick={
      (e)=>{ 
            dispatch(push(to)); 
      }
    }>
      <img className="school-home-link-icon" src={icon}/>
      <p className="school-home-link-title-down">{ (name)}</p>
         
        <div className={`school-home-link-over school-home-${color}-hl`}>
          <p className="school-home-link-title">{ (name)}</p> 
          <p>{desc}</p>
        </div>
    </div> ;
});


const PGLocationSelection = ({ translations }) => {
  topScroll();
  const dispatch = useDispatch(); 

  const user = useSelector(({user})=>user);
  useEffect(()=>{ 

    if(!(user.location && pgLocations[user.location]))
      dispatch(push("/school/gptx")); 
  },[user]);
  console.log("USSSSSERRRR:",user)
  return (
    <div id="school-dashboard" className="school-dashboard layout-base">
    <PageTitleContainer pageTitle="home" />
    <HeaderContainer />
    <SchoolDashboardHeadingContainer />
    <Paper.Container lead>
      <SchoolDashboardNavContainer currentTab="home" />
      <Row> 
        <Col xs="12">
            <Paper.Title>
              <h1 className="school-home-title">{translations.t("planification_generator" ) } </h1> 
              <span className="school-home-subtitle">{translations.t("school_planifications_ai_desc" )}</span>
            </Paper.Title>
        </Col>    
        <Col xs="12" >
        <div className='school-home-links'> 
          {
            pgLocations[user.location] && pgLocations[user.location].map(({name, location,desc,icon})=>(
              <PGLink name={name} desc={desc} icon={icon} to={"/school/gptx?location="+location} translations={translations}/>
            ))
 
          }
          </div>
        </Col>
        
      </Row> 
    </Paper.Container> 
  </div>
  );
};

export default Translations(PGLocationSelection);
