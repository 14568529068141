/* @flow */

import React from 'react';
import { Col } from 'reactstrap';
import { Paper, InfoTooltip, LineChart, TimelineProgress } from './';
import Translations from '../hocs/TranslationsHelper';
import type { TranslationsObject } from './types';

type Props = {
  translations: TranslationsObject
};

const CoursesCompareChart = ({ translations, selectedOption, onClickOption, ...chartProps }: Props) => {
  return (
    <div className="courses-compare-chart row">
      <Col xs="12">
        <Paper.Title>
          <h1 className="mt-5">
            { translations.t('courses_progress_in_time') }{' '}
            <InfoTooltip message="tooltips_current_school_courses_average_progress" />
          </h1>
        </Paper.Title>
      </Col>
      <Col xs="12">
        <Paper.Content>
          <div className="timeline-progress-container">
            <TimelineProgress selectedOption={selectedOption} onItemSelected={onClickOption}>
              <LineChart {...chartProps} yAxis />
            </TimelineProgress>
          </div>
        </Paper.Content>
      </Col>
    </div>
  );
};

export default Translations(CoursesCompareChart);
