/* @flow */

import React from 'react';
import { Form, FormGroup, FormFeedback,Label } from 'reactstrap'; 
import { Field, reduxForm } from 'redux-form';
import { required, minLength, email } from './validations';
import Translations from '../hocs/TranslationsHelper';
import type { ReduxFormFunction, ReduxFormErrorGlobal, ReduxFormFlag, TranslationsObject } from '../components/types';
import classNames from 'classnames';
import RegistrationSubmitButton from './RegistrationSubmitButton';
import { toBoolean } from './normalizers';

import { Button, SubmitButton, InputField, InputFieldCheckbox } from '../components/';   
import InputFieldSelect from './InputFieldSelect';
import { languages } from './UserInfoForm';
  
const lower = value => value && value.toLowerCase()
const SchoolUserSignupForm = ({error,  handleSubmit,  persisted, submitting, valid, dirty, translations } ) => {
 

  const formClassName = classNames({ 'has-danger': error }, 'form', "student-data-form");
  let disabled = false;
  return (
    <Form className={formClassName} onSubmit={handleSubmit}>
      
      <div >
        { error &&
            <FormGroup>
              <FormFeedback>{translations.t(error)}</FormFeedback>
            </FormGroup>}

        <div >
            <Field
              className="text-center"
              type="password"
              name="password"
              placeholder={translations.t('password')}
              disabled={disabled}
              component={InputField}
              validate={[required, minLength(8)]}
            />
            <Field
              className="text-center"
              type="password"
              name="password_confirmation"
              placeholder={translations.t('password_confirmation')}
              disabled={disabled}
              component={InputField}
              validate={[required, minLength(8)]}
            />
      
            <Field
              className="parent-sign-up-form-newsletter text-center"
              type="checkbox"
              name="receive_marketing_emails"
              label={translations.t('receive_marketing_emails')}
              disabled={submitting}
              component={InputFieldCheckbox}
              normalize={toBoolean}
            />
            <SubmitButton loading={submitting} className="btn-primary--green " disabled={!dirty || !valid}>
               Crear
            </SubmitButton> 
        </div>
        {persisted}
      </div>
    </Form>
  );
};

export const FORM_NAME = 'schoolUserSignupForm';

 

export default reduxForm({
  form: FORM_NAME,
  enableReinitialize: true
})(Translations(SchoolUserSignupForm));
