/* @flow */

import React from 'react';
import { GeneralIntelligenceProgressContainer } from './';
import actions from '../../actions';
import { getActiveChild } from '../../selectors/children';
import { getAllIntelligenceProgressByChild } from '../../selectors/intelligenceProgress';
import { pure, compose, lifecycle } from 'recompose';
import { connect } from 'react-redux'

/**
 * Container that receives the data to display all the progress
 * over each of the Intelligences
 * @method IntelligencesProgressContainer
 * @param  {array}               currentChildProgress - injected by Redux includes all intelligence_progress from the store for the current child
 * @param  {array}               intelligences - injected by Redux includes all intelligences from the store
 */
export const IntelligencesProgressContainer = ({ currentChildProgress, intelligences }) => {
  return (
    <GeneralIntelligenceProgressContainer currentProgress={currentChildProgress} intelligences={intelligences} />
  );
};

const dispatchGetIntelligenceProgress = (dispatch, child_id) => {
  if (child_id) {
    dispatch(actions.getIntelligenceProgress({ child_id }));
  }
}

export const IntelligencesProgressContainerLifecycle = {
  componentDidUpdate(prevProps) {
    const { dispatch, child_id } = this.props;
    const lastChild = prevProps.child_id;

    if (lastChild !== child_id) {
      dispatchGetIntelligenceProgress(dispatch, child_id);
    }
  },
  componentDidMount() {
    const { dispatch, child_id } = this.props;

    dispatchGetIntelligenceProgress(dispatch, child_id);
  }
};

const IntelligencesProgressContainerComposed = compose(
  lifecycle(IntelligencesProgressContainerLifecycle),
  pure
)(IntelligencesProgressContainer);

const mapStateToProps = ({ children, intelligence_progress, intelligences }, ownProps) => {
  const currentChild = getActiveChild({ children });
  const currentChildProgress = getAllIntelligenceProgressByChild({ intelligence_progress }, currentChild.id);

  return {
    child_id: currentChild.id,
    currentChildProgress,
    intelligences
  }
};

const mapDispatchToProps = (dispatch) => ({ dispatch });

const IntelligenceProgressContainerConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(IntelligencesProgressContainerComposed);

export default IntelligenceProgressContainerConnected;
