/* @flow */

import React from 'react';
import { pure, withHandlers, compose } from 'recompose';
import { Card, CardBody, CardTitle } from 'reactstrap';
import RadialProgressBar from './RadialProgressBar';
import TextTruncate from 'react-text-truncate';

const CardTag = ({ children }) => <span className="card-tag">{children}</span>;

type Props = {
  excerpt: string,
  title: string,
  description: string,
  tags: Array<string>,
  value: number,
  onClickCard: () => mixed
};

/**
 * A component to display what is known as AchivementCard within the
 * spec document of PleIQ project, we try to keep the same naming of the
 * components related on reactstrap.
 *
 * @method CardProgress
 * @param  {string}       excerpt     - brief introduction to describe card
 * @param  {string}       title       - the title of the card
 * @param  {string}       description - information about the activity related to the card
 * @param  {[]string}     tags        - set of tags to represent the scientific base of this activity
 * @param  {number}       value       - a number to be represented as percentage of progress [0, 100]
 *
 * @example
 * <CardProgress
 *   title="foo"
 *   description="description foo"
 *   value={45}
 *   tags={['BCEP', 'CCSS']}
 * />
 */
const CardProgress = ({ title, description, excerpt, tags, value, onClickCard }: Props) => {
  return (
    <Card className="card-progress" onClick={onClickCard}>
      <CardBody className="card-block card-block-top">
        <TextTruncate
          line={2}
          truncateText="…"
          containerClassName="card-title h4"
          text={title}
        />
      </CardBody>
      <CardBody className="card-block card-block-middle">
        <div className="pie-progress-graph centered">
          <RadialProgressBar value={Math.round(value)} />
        </div>
      </CardBody>
      <CardBody className="card-block card-block-bottom">
        <TextTruncate
          line={3}
          truncateText="…"
          containerClassName="card-text"
          text={excerpt}
        />
        {tags.map((tag, key) => <CardTag key={key}>{tag}</CardTag>)}
      </CardBody>
    </Card>
  );
};

export const CardProgressHandlers = {
  onClickCard: ({ onClick, title, description, standards }) => () => {
    
    onClick({ title, html: [ ... standards.map(({description,name,slug})=>`<strong>${(name!==null && name!=="")? name : slug}</strong><br>` +description)]});
  }
};

export default compose(
  withHandlers(CardProgressHandlers),
  pure
)(CardProgress);
