/* @flow */

import { fork, all } from 'redux-saga/effects';
import { watchAchievementCardScoresRequest } from './achievementCardScores';
import { watchAchievementCardsRequest } from './achievementCards';
import { watchLessonPlansRequest } from './lessonPlans';
import { watchActivityRequest } from './activity';
import { watchApplicationActions } from './app';
import { watchChildrenRequest } from './children';
import { watchcoursesProgressOverTimeRequest } from './coursesProgressOverTime';
import { watchCoursesRequest } from './courses';
import { watchErrors } from './error';
import { watchIntelligenceHistoryRequest } from './intelligenceHistory';
import { watchIntelligenceProgressRequest } from './intelligenceProgress';
import { watchIntelligencesRequest } from './intelligences';
import { watchCaligrafixBookHistoryRequest } from './caligrafixBookHistory';
import { watchCaligrafixBookProgressRequest } from './caligrafixBookProgress';
import { watchCaligrafixBooksRequest } from './caligrafixBooks';
import { watchLevelsRequest } from './levels';
import { watchParentRequest } from './parent';
import { watchRequest } from '../diplomatico/sagas/request';
import { watchSchoolIntelligenceProgressRequest } from './schoolIntelligenceProgress';
import { watchStudentIntelligenceProgressRequest } from './studentIntelligenceProgress';
import { watchSchoolCaligrafixBookProgressRequest } from './schoolCaligrafixBookProgress';
import { watchStudentCaligrafixBookProgressRequest } from './studentCaligrafixBookProgress';
import { watchStudentsRequest } from './students';
import { watchUniqueAccountRequest } from './uniqueAccount';
import { watchUserRequest } from './user';
import { watchDirectorRequest } from './director'
import {watchStudentComments} from './studentComments'
import {watchSchoolUserInvitations } from './schoolUserInvitations'
import {watchSchoolUsersRequest} from "./schoolUsers"
import {watchGenericDataRequests} from "./genericData"

export default function* rootSaga(): any {
  yield all([
    fork(watchAchievementCardScoresRequest),
    fork(watchAchievementCardsRequest),
    fork(watchLessonPlansRequest),
    fork(watchActivityRequest),
    fork(watchApplicationActions),
    fork(watchChildrenRequest),
    fork(watchcoursesProgressOverTimeRequest),
    fork(watchCoursesRequest),
    fork(watchErrors),
    fork(watchIntelligenceHistoryRequest),
    fork(watchIntelligenceProgressRequest),
    fork(watchIntelligencesRequest),
    fork(watchCaligrafixBookHistoryRequest),
    fork(watchCaligrafixBookProgressRequest),
    fork(watchCaligrafixBooksRequest),
    fork(watchLevelsRequest),
    fork(watchParentRequest),
    fork(watchRequest),
    fork(watchSchoolIntelligenceProgressRequest),
    fork(watchStudentIntelligenceProgressRequest),
    fork(watchSchoolCaligrafixBookProgressRequest),
    fork(watchStudentCaligrafixBookProgressRequest),
    fork(watchStudentsRequest),
    fork(watchUniqueAccountRequest),
    fork(watchUserRequest),
    fork(watchDirectorRequest),
    fork(watchStudentComments),
    fork(watchSchoolUserInvitations),
    fork(watchSchoolUsersRequest),
    fork(watchGenericDataRequests)
  ]);
}
