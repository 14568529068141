/* @flow */

import { select } from 'redux-saga/effects';
import { getAuthorization } from './selectors/authorization';
import _ from 'lodash';

/**
 * Class to define parameters and functions used to shape the way that
 * the requests are going to be made.
 */
class RequestOptions {
  static _API_PATH = process.env.REACT_APP_API_URL;

  static TIMEOUT_THRESHOLD = parseInt(process.env.REACT_APP_TIMEOUT_THRESHOLD, 10);

  static buildFinalURL = (endpoint) => `${RequestOptions._API_PATH}${endpoint}`;

  static buildPayload = (action) => {
    let { body } = action.payload;

    return { ...body };
  }

  static _getAuthorization = function* _getAuthorization(): Object {
    // see: https://github.com/reactjs/reselect
    const authorization = yield select(getAuthorization);
    return authorization;
  }

  static getHeaders = function* getHeaders(authorizable: boolean) {
    let headers = new window.Headers({
      'Content-Type': 'application/json'
    });

    if (authorizable) {
      const authorization = yield RequestOptions._getAuthorization();
      if (!_.isEmpty(authorization)) {
        headers.append('Authorization', `Bearer ${authorization.token}`);
      } else {
        // if we get here it means we are trying to make a request
        // that needs auth, but we are not authenticated 
        return false;
      }
    }

    return headers;
  }
}

export default RequestOptions;
