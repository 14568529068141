 

import React from 'react';
import {connect} from 'react-redux' 

import {  withPdfHandlers,  PdfOnlyData } from "../../pdfer/Pdfable";  
import { CoursesCompareChartPdf } from "../containers/CoursesCompareChartContainer";
import { SchoolCoursesProgressPdf } from "../containers/SchoolCoursesProgressContainer";


var SchoolReportDiv =  ( { levels }) =>(
    <div> 
            
        { 
            levels.map( 
                (level) =>(
                    <div key={level.id}>  
                        <PdfOnlyData path={"school.level_name"+level.id}>
                            <h2>{level.name}</h2> 
                        </PdfOnlyData>
                        <SchoolCoursesProgressPdf level_id={level.id} path={"school.course_progress"+level.id}/>
                        <CoursesCompareChartPdf level_id={level.id} path={"school.course_compare"+level.id} selectedOption="month"/>

                    </div>
                )
            ) 
        } 
    </div>
)

const mapStateToProps  = (state )=>{  
    var {levels} = state; 
    return {
        levels: levels 
    };
}
const mapDispatchToProps  = (dispatch) => ({ dispatch });
export var SchoolReportPdf = connect(mapStateToProps ,mapDispatchToProps )( withPdfHandlers (
    {path:"school.current", 
    filter: "course_report",
    pre: ({levels, dispatch}) =>{  

        if(!levels){//undefined course name 
           dispatch({ type: 'REQUEST_LEVELS' });
           dispatch({ type: 'REQUEST_ALL_COURSES' });
           
           return true;//wait for students, ok? 
        }
        return false;
    }
})(SchoolReportDiv));
 
  