import React, { useContext, useState } from 'react';
import { useData, invalidateRetriever } from '../../hocs/DataRetriever';
import { Label, FormGroup, Form,FormFeedback, Row, Col } from 'reactstrap';
import { InfoTooltip, InputField, SubmitButton, InputFieldSelect, Paper, Button } from '../../components';
import { required, minLength, email } from '../../components/validations';
import { Field, reduxForm, startSubmit, stopSubmit, initialize, reset } from 'redux-form';
import logo from '../../../img/pleiq-caligrafix_color_logo.png'; 

import classNames from 'classnames'; 
import TranslationsHelper from '../../hocs/TranslationsHelper';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'react-router-redux';
import actions from '../../../actions';
import { showConfirmationModal } from '../../../actions/confirmationModal';
import { PdfData, pdfContext, usePdf } from '../../../pdfer/Pdfable';
import { formatProgressReportDate, formatProgressReportName } from './common';
import RoleFilter from '../RoleFilter';
const Sections = ({sections, cmp,uprops})=>{ 
    return <FormGroup>
        {
            sections.map((s)=><Section  uprops={uprops} section={s} cmp={cmp}/>)
        }
    </FormGroup>;
}
const fieldValues = [
    { value: 'N', label:'N'},
    { value: 'I', label:'I'},
    { value: 'P', label:'P'},
    { value: 'A', label:'A'}
] 

const Section = ({cmp,section,uprops})=>{
    const {title, description, fields, children, level,id} = section; 
    return <FormGroup>

        <PdfData path={"progress_report_section_"+id} pdf={{hpad: 8}} filter="progress_report">
            {level == 2 && <h3 className={"progress-report-section-title"}>{title}</h3>}
            {level == 1 && <h4 className={"progress-report-section-title"}>{title}</h4>}
            {level > 2 && <h2 className={"progress-report-section-title"}>{title}</h2>}
        </PdfData>

        { /*<p>{description}</p>*/}
        {fields.length > 0 && <Fields fields = {fields} uprops={uprops} cmp ={cmp}/> }
        <Sections sections = {children} cmp ={cmp}  uprops={uprops}/> 
    </FormGroup>
}
           
const ProgressReportFieldInput  = ({ className, formGroupClassName, searchable, selectOptions, translations, disabled, ...otherProps }) => {
  const { input, label, name, placeholder, meta } = otherProps;
  const { touched, dirty, error, valid, submitting }: ReduxFormMeta = meta;
  const showErrors = (touched || dirty) && error;
  const formColor = classNames({
    danger: showErrors,
    success: valid && (touched || dirty) && !error
  });
  const printing = usePdf();

  if(Array.isArray(input.value))
    return <div className="progress-report-cmp-field">
        {input.value.map((v)=>(
            <div>
                <span>{v}</span>
            </div>
        ))}
    </div>;
    
  return (printing )?(
  <span className="progress-report-field-value">{input.value}</span>

  ) : ( 
      <select
        className="progress-report-field-input"
        value={input.value}
        onChange={(value) => {
            input.onChange(value); 
        
        }} 
        placeholder={placeholder}
        searchable={searchable}
        disabled={submitting || disabled}
      >
          <option></option>
          {
                selectOptions.map(({value, label})=> (
                    <option value={value}>{label}</option>
                ))
          }
      </select>
  );
};                                                                                                                                 
const ProgressReportNotesInput  = ({ className, formGroupClassName, searchable, selectOptions, translations, disabled, ...otherProps }) => {
  const { input, label, name, placeholder, meta } = otherProps;
  const { touched, dirty, error, valid, submitting }: ReduxFormMeta = meta;
  const showErrors = (touched || dirty) && error;
  const formColor = classNames({
    danger: showErrors,
    success: valid && (touched || dirty) && !error
  });

  return ( 
      <textarea
        rows="8"
        className={className}
        value={input.value}
        onChange={(value) => {
            input.onChange(value); 
        
        }} 
        placeholder={placeholder}
        searchable={searchable}
        disabled={submitting || disabled}
      />  
  );
};                       
const Fields =TranslationsHelper(  ({cmp, fields, uprops,values, setValues, translations })=>{

    return <FormGroup>
        <div className ="progress-report-fields-container">
        <PdfData path={"progress_report_fields_"+fields[0].id} pdf={{hpad: 3}} filter="progress_report">
        {
                (!cmp)?( 
                    <div className="progress-report-domain-container">
                        <span className="progress-report-domain">{translations.t("domain")}</span>

                    </div>):(
                    <div className="progress-report-cmp-domain-container">
                        {
                            cmp.map((id, i)=>(
                                <div key={i} className="progress-report-cmp-domain">
                                    <span >{translations.t("report")+ " " + i}</span> 
                                    <span >{translations.t("domain")}</span> 
                                </div>
                            ))

                        } 
                    </div>

                )
        }
        </PdfData>
        {
            fields.map(({id, title, description,achievement_card_id}, index)=>(
                <PdfData path={"progress_report_field_"+id} pdf={{wrap: true,hpad: 2  }} filter="progress_report">
       
                <div className="progress-report-field">
                   <span class={
                       `${achievement_card_id&& "pleiq-icon-icon_phone"} progress-report-field-icon`
                   }></span> 
                   {/*
                    <Label>{title}</Label>
                    <input name={id} disabled={achievement_card_id}/>
                    
                   
                    <Field 
                    type="text"
                    name={id}
                    placeholder={title}
                    label={title}
                    disabled={false}
                    component={InputField}
                    validate={[ required ]}
                    disabled={achievement_card_id}
                    />
                   
                   */ }

                    <Label className = "progress-report-field-index" >{index+1}. </Label>
                    <Label className = "progress-report-field-label" for={"field_data."+id}>{title}</Label>
                
                    <Field
                        name={"field_data."+id} 
                        selectOptions={fieldValues}
                        component={ProgressReportFieldInput}
                        disabled={ (achievement_card_id && !uprops.isPremium)  || !uprops.canEdit}
 
                    /> 
                </div>
                </PdfData>
            ))
        }
        </div>
    </FormGroup>
})

function computeSectionLevel(section){
    var level = 1
    section.children.forEach((c)=>{
        computeSectionLevel(c);
        level = Math.max(level, 1 + c.level);
    });
    section.level = level;
}
const FORM_NAME = "PROGRESS_REPORT_RECORD_FORM";


const  ReportActions = ({cmp,submitting, dirty, valid,isNew, translations, label,student, course, level,date}) =>  <div className="progress-report-form-actions"  >
        {
            
            !cmp &&label&& <span>{
                translations.t(label)
            }</span>
        } 
       { (!cmp) &&
                <RoleFilter roles={['coordinator', 'teacher']}> <SubmitButton loading={submitting} className="btn btn-primary--blue" disabled={!isNew && !dirty || !valid}>
            {translations.t('save')}
        </SubmitButton></RoleFilter>}
        <SubmitButton loading={submitting} type="button" className="btn btn-primary--blue"  onClick={ 
                ()=>{
                    pdfContext.print(formatProgressReportName(student, course, level,date )  , "progress_report",{ },{width: 846 });// dispatch(push('pdf/course/'+ course.id));
                }
            }>
            {translations.t('download')}
        </SubmitButton>
        
    </div>; 

let ProgressReportFormComponent =  reduxForm({
    form: FORM_NAME,
    enableReinitialize: true
  })(
  
  ({cmp,template_data,error, date, user, student, course, level, isNew,  initialValues,  handleSubmit,onDelete, persisted, submitting, valid, dirty, translations})=>{
         
        const {name, logo_url, premium_status,role} = useSelector(({user})=>user );

        const canEdit = role=='coordinator'||role=='teacher';

        const isPremium = premium_status=="premium";
        
        const formClassName = classNames({ 'has-danger': error }, 'form' );
                     
        
        const {sections, fields,title,description} = template_data;
        const section_map = {};
        const free_fields = [];
        const free_sections = [];
        sections.forEach(s => {
            s.children = [];
            s.fields = [];
            s.level = 1;
            section_map[s.id] = s;
        });

        sections.forEach(s => {
            if(s.section_id){
                section_map[s.section_id].children.push(s);
            }else free_sections.push(s);
        });
 
        free_sections.forEach((section) => {
            computeSectionLevel(section);
        });
        fields.forEach(f => {
            if(f.section_id){
                section_map[f.section_id].fields.push(f);
            }else free_fields.push(f);
        });
         
        const uprops = {canEdit, isPremium};
        
        return   <Row> 
            <Col xs="12">
                <Paper.Title>
                <h1 >{translations.t("progress_report_for",{name:  (student)?student.name : course &&course.name } ) } {' '}
                    
                </h1> 
                </Paper.Title>
            </Col>    
            
            <Col xs="12">
                <p>
                    {translations.t("progress_report_explanation")}
                </p>
                <p className="text-center">

                {translations.t("progress_report_legend")}
                </p> 

                {
                    !cmp && !isPremium && <div className="premium-promo-notice">
                    <span className="premium-promo-notice-crown"/>{
                        translations.t("progress_reports_premium_notice")
                    }</div>
                }
            </Col>
            <Col xs="12">
                <hr/>
                <Form className={formClassName} onSubmit={handleSubmit}>
                <ReportActions {...{submitting, dirty, isNew,valid, translations,cmp,student, course, level,date}} 
                                label="progress_report_reminder"/>  
                <div className="progress-report-container">
                { error &&
                    
                    <FormGroup>
                        <FormFeedback>{translations.t(error)}</FormFeedback>
                        </FormGroup>}

                    <PdfData path="progress_report_header"  filter="progress_report" pdf={{}}>
                        <div className="progress-report-header">
                            <div className="progress-report-header-logo">
                                <img src={logo_url} alt="School Logo" crossOrigin="use-credentials
use-credentials"/>
                            </div>
                            
                            <h2 className="progress-report-header-title">{title}</h2>
                            <div className="progress-report-header-logo">
                                <img src={logo} alt="PleIQ" />
                            </div>
                        </div>
                    </PdfData>

                    <PdfData path="progress_report_header_data"  filter="progress_report"
                        >
                    

                    
                    <table className="progress-report-data">
                        {   
                            student&&<tr>
                                <th>{translations.t("student")}:</th>
                                <td>{student.name}</td>  
                            </tr>
                        }
                        <tr>
                            <th>{translations.t("level")}:</th>
                            <td>{level&& level.name}</td>
                        </tr> 
                        <tr>                
                            <th>{translations.t("course")}:</th>
                            <td>{course&& course.name}</td>
                        </tr> 
                        <tr>
                            <th>{translations.t("teacher")}:</th>
                            <td>{user&& user.name}</td>
                        </tr> 
                        <tr>
                            <th>{translations.t("date")}:</th>
                            <td>{formatProgressReportDate(date) }</td>
                        </tr> 
                        

                        
                        
                    </table>
                    </PdfData>
 
                    {free_fields.length>0 && <Fields fields = {free_fields}  uprops={uprops} cmp={cmp}/> }
                 
                    <Sections sections = {free_sections} uprops={uprops}  cmp={cmp}/> 
                    <PdfData path="progress_report_q_report"  filter="progress_report" pdf={{wrap: true}}>
                    {

                        (!cmp)&&<h4 className={"progress-report-notes-title"}>{translations.t('qualitative_report')}</h4>//<Label for="notes">{translations.t('qualitative_report')}</Label>
                    }
                    {

                        (!cmp)&&
                        <Field
                            type="text"
                            name="notes" 
                            label={translations.t('qualitative_report')}  
                            disabled={submitting || !canEdit}
                            className={"progress-report-notes"}
                            component={ProgressReportNotesInput} 
                        />
                    }
                    </PdfData>
                    </div>
                    <ReportActions {...{submitting, dirty,isNew, valid, translations,cmp,student, course, level,date}}/>   
                </Form>
            </Col>      

        </Row> 
    }
)
ProgressReportFormComponent =  TranslationsHelper(ProgressReportFormComponent);
export const ProgressReportForm = 
  
  ({ template_id, student_id, course_id,record_id  , onSave})=>{
        const dispatch = useDispatch();

     //   const template_data = useData({id: "progress_report_template", 
         //                       url: "/progress_reports/"+ template_id});

        const id_param = (student_id)? ("student_id="+student_id):("course_id="+course_id);
        
        const current_data = useData({id: "progress_report_record_data", url: 
        "/progress_reports/"+ template_id+"/values?"+ id_param });
                           
        //if(!template_data.data || !template_data.data.body || template_data.data.error)
       // return null; 
        if(!current_data.data || !current_data.data.body|| current_data.data.status>=400)
        return null;  

        var values = {field_data: {}};
        const {course, student, level, user,field_data, progress_report} =   current_data.data.body;

        progress_report.fields.forEach(f => {
            values.field_data[f.id] = 'N';
             
        });
        field_data.forEach(({id, value})=>
                values.field_data[id] = value);


        return <ProgressReportFormComponent template_data={progress_report} 
                    {...{course, student, level, user}}
                    isNew
                    date={new Date()}
                    initialValues={values}
                     onSubmit={(values)=>{
                        dispatch(startSubmit(FORM_NAME));
                        dispatch(actions.genericDataRequest('progress_report_record_new', '/school/progress_reports','POST'
                        ,{progress_report_record:{notes:values.notes,progress_report_id: template_id, field_data: Object.entries(values.field_data).map(
                            ([key,value])=>({id: key, value})
                        )}, student_id,course_id},  ({status,errors, body})=>{
                            if(status < 400){ 
                              dispatch(stopSubmit(FORM_NAME )); 
                              invalidateRetriever("school_users_list");  
                              dispatch(push("/school/progress_reports/edit?record_id=" + body.id  ) ); 

                              dispatch(showConfirmationModal({
                                title: 'progress_report_saved',
                                message: 'progress_report_saved_message',
                                kind: 'succeed',
                                actions: [  
                                ]
                              })); 
                            }else  {  
                              dispatch(stopSubmit(FORM_NAME, {_error: "invalid value"})); 
                            }   
                          })); 
                     }}/>;

    }
 
export const ProgressReportEditForm = 
    
    ({ record_id  , onSave})=>{
        const dispatch = useDispatch();

        const current_data = useData({id: "progress_report_record_edit_data", url: 
        "/school/progress_reports/"+ record_id  });
        

                            
        if(!current_data.data || !current_data.data.body|| current_data.data.status>=400)
            return null; 

        const {progress_report,field_data,created_at, student, course,level, user}=  current_data.data.body;


        var values = {field_data: {}, notes:current_data.data.body.notes }; 

        field_data.forEach(({id, value}) => values.field_data[id] = value);

        return <ProgressReportFormComponent date={new Date(created_at)} user={user} student={student} level= {level} course={course} template_data={progress_report} initialValues={values}
                        onSubmit={(values)=>{
                        dispatch(startSubmit(FORM_NAME));
                        dispatch(actions.genericDataRequest('progress_report_record_edit', '/school/progress_reports/'+record_id ,'PUT'
                        ,{progress_report_record:{notes:values.notes,progress_report_id: current_data.progress_report_id, field_data: Object.entries(values.field_data).map(
                            ([key,value])=>({id: key, value})
                        )} },  ({status,errors, body})=>{
                            if(status < 400){ 
                                dispatch(stopSubmit(FORM_NAME )); 
                                invalidateRetriever("school_users_list"); 
                                dispatch(showConfirmationModal({
                                    title: 'progress_report_saved',
                                    message: 'progress_report_saved_message',
                                    kind: 'succeed',
                                    actions: [] })); 
                               // dispatch(reset(FORM_NAME));
                                //invalidateRetriever("progress_report_record_edit_data"); 
                                 
                                //dispatch(push("/school/progress_reports/edit?record_id=" + body.id  ) ); 
                              // dispatch(initialize(FORM_NAME, {...values}));
                              // dispatch(reset(FORM_NAME));

                            }else  {  
                                dispatch(stopSubmit(FORM_NAME, {_error: "invalid value"})); 
                            }   
                            })); 
        }}/>;

    }
export const ProgressReportCmpForm = 
  
    ({ template_id, student_id, course_id })=>{
          const dispatch = useDispatch();
  
       //   const template_data = useData({id: "progress_report_template", 
           //                       url: "/progress_reports/"+ template_id});
  
          const id_param = (student_id)? ("student_id="+student_id):("course_id="+course_id);
          
          const current_data = useData({id: "progress_report_record_cmp", url: 
          "/school/progress_reports/compare?"+  id_param });
                             
          //if(!template_data.data || !template_data.data.body || template_data.data.error)
         // return null; 
          if(!current_data.data || !current_data.data.body|| current_data.data.status>=400)
          return null;  
  
          var values = {field_data: {}};
          const {base, records} =   current_data.data.body;
          const all_records = [base, ...records];
          all_records.reverse();
          
          const {course, student, level, user,field_data, progress_report} =   base;
          progress_report.fields.forEach(({id})=> { 
            values.field_data[id] =  all_records.map(()=>null);
          } );

          all_records.forEach(({field_data}, index)=>{ 
            field_data.forEach(({id, value})=> values.field_data[id][index] = [value]);
          });

          const ids = all_records.map(({id})=>id);

         /* field_data.forEach(({id, value})=>
                  values.field_data[id] = [value]);
          records.forEach(({field_data})=>{

          field_data.forEach(({id, value})=>
                values.field_data[id].push( value));

          })*/
          return <ProgressReportFormComponent template_data={progress_report} 
                      {...{course, student, level, user}}
                      date={new Date()}
                      initialValues={values}
                       onSubmit={(values)=>{ 
                       }} cmp={ids}/>;
  
      }
   

export default ProgressReportForm;
