/* @flow */

import React from 'react';
import { connect } from 'react-redux';
import { compose, pure, withHandlers } from 'recompose';
import { HTMLContentModal } from '../components/';
import actions from '../../actions';
import type { RecomposeHandler, ReduxFunction } from '../components/types';

type Props = {
  active: boolean,
  title: string,
  html: string,
  onCancel: RecomposeHandler,
  dispatch: ReduxFunction
};

const HTMLContentModalContainer = (props: Props) => {
  return <HTMLContentModal {...props} />;
};

export const HTMLContentModalContainerHandlers = {
  onCancel: ({ dispatch }: Props) => () => {
    dispatch(actions.hideHtmlContentModal());
  }
};

const HTMLContentModalContainerComposed = compose(
  withHandlers(HTMLContentModalContainerHandlers),
  pure
)(HTMLContentModalContainer);

const mapStateToProps = ({ html_content_modal }) => ({
  active: html_content_modal.active,
  title: html_content_modal.title,
  html: html_content_modal.html
});

const mapDispatchToProps = (dispatch) => ({ dispatch });

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HTMLContentModalContainerComposed);
