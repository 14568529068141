/* @flow */

import React from 'react';
import { pure, compose, lifecycle } from 'recompose';
import actions from '../../actions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { SchoolTimelineProgressContainer } from './';
import moment from 'moment';
import Translations from '../hocs/TranslationsHelper';
import { getIntelligenceBySlug } from '../../selectors/intelligences';
import { getIntelligenceHistory } from '../../selectors/intelligenceProgress';
import { findStudentAcrossReducers } from '../../selectors/students';
import _ from 'lodash';

const StudentTimelineProgressContainer = ({ data, student_id, intelligence_id, dispatch }: Props) => {
  return (
    <SchoolTimelineProgressContainer noborder data={data} dispatchGetSchoolHistory={dispatchStudentIntelligenceHistory} student_id={student_id} intelligence_id={intelligence_id} dispatch={dispatch}/>
  );
};

const getDate = (option) => {
  const format = 'YYYY-MM-DD';

  switch (option) {
    case 'month':
      return moment().subtract(5, 'months').format(format);
    case 'week':
      return moment().subtract(3, 'weeks').format(format);
    case 'day':
      return moment().subtract(6, 'days').format(format);
    default:
      return moment().add(1, 'day').format(format);
  }
};

const dispatchStudentIntelligenceHistory = ({ student_id, intelligence_id, dispatch, selectedOption }) => {
  const groupBy = selectedOption || 'day';
  const start_date = getDate(groupBy);
  const end_date = getDate();

  if (intelligence_id && student_id) {
    dispatch(actions.getStudentIntelligenceHistory({
      student_id,
      intelligence_id,
      start_date,
      end_date,
      group_by: groupBy
    }));
  }
};

export const ContainerLifecycle = {
  componentDidUpdate(prevProps) {
    const lastParam = prevProps.match.params.slug;
    const nextParam = this.props.match.params.slug;

    if (lastParam !== nextParam) {
      dispatchStudentIntelligenceHistory(this.props);
    }
  },
  componentDidMount() {
    dispatchStudentIntelligenceHistory(this.props);
  }
};

const ContainerTranslated = Translations(StudentTimelineProgressContainer);

const ContainerComposed = compose(
  lifecycle(ContainerLifecycle),
  pure
)(ContainerTranslated);

const mapStateToProps = (state, ownProps) => {
  const { student_intelligence_history, intelligences } = state;
  const { params } = ownProps.match;
  const { slug, student_id } = params;

  const intelligence = getIntelligenceBySlug({ intelligences }, slug);
  const student = findStudentAcrossReducers(state, student_id);
  const data = getIntelligenceHistory({ intelligence_history: student_intelligence_history }, student_id, intelligence.id);

  return {
    student_id: _.get(student, 'id'),
    intelligence_id: intelligence.id,
    data
  };
};

const mapDispatchToProps = (dispatch) => ({ dispatch });

const ContainerConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(ContainerComposed);

export default withRouter(ContainerConnected);
