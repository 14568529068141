
import React from 'react';
import { withState, compose } from "recompose";

const { default: DataRetriever } = require("../../hocs/DataRetriever");
const { default: TranslationsHelper } = require("../../hocs/TranslationsHelper");
const { Table } = require("reactstrap");

const SchoolUserChildrenSelector = ({data }) => {
    if(!data)return null;
    const {loading, payload, errors} = data; 
  
    if(loading || !payload) return <span>loading</span> ;
    if(payload.status>=400)return <span>error</span>;
  
    const {body} = payload; 
    if(!body.data)return null;

    return <div>
        <Table>
        <tr>
                { 
                  ["name", "course_name" ].map(
                    (c)=>(

                      <th key={c}>
                        {c} 
                      </th>

                      ) 
                  )


                } 
                <th></th>
              </tr>
              {
                  body.data.map(({id, name, course_name})=>(
                      <tr key={id}>
                          <td> 
                            {name}
                          </td>
                          <td> 
                            {course_name}
                          </td> 
                           
                      </tr>
                  ))
              }
        </Table>

    </div>;
}


const SchoolUserChildrenRetriever = DataRetriever(SchoolUserChildrenSelector);

const SchoolUserChildrenSelectorContainer = ({ search, setSearch}) => {

    return <div>    
        <input value={search} onChange={(e)=>setSearch(e.target.value)}/>
        <SchoolUserChildrenRetriever id="SCHOOL_USER_CHILDREN" dataUrl ={"/school/students?q="+ search}/>


    </div>;
} 
const SchoolUserChildrenSelectorContainerComposed = compose( 
  withState('search', 'setSearch', ""), 
  TranslationsHelper, 
)(SchoolUserChildrenSelectorContainer);

export default SchoolUserChildrenSelectorContainerComposed;