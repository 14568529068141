/* @flow */

import React from 'react';
import { connect } from 'react-redux'; 
import _ from 'lodash';
import Translations from '../hocs/TranslationsHelper';
import { Alert } from 'reactstrap';

/**
 * This component sets the title of the screen
 * It uses [react-helmet](https://github.com/nfl/react-helmet)
 *
 * We generate titles based on the account type and appending the screen title
 *
 * @param {string} pageTitle        - The current page string to complete the title
 */
const RequestErrorContainer = ({status, retry,translations }) => { 
  if(status ){  
    return <Alert type='error' >
    <div >
      { translations.t("server_problem")}
      <a href="#" onClick={()=>retry()}>{ translations.t("server_problem_retry")}</a>
    
    </div>
  </Alert> ;//connection_trouble_message
  //<div >error <button onClick={()=>retry()}>Retry</button></div>
  }else return null;
};  
const mapStateToProps = ({ request_errors }, {request}) => {
  return {
    status: request_errors[request]
  };
};
const mapDispatchToProps = (dispatch , {request})=>{
  return {
    retry: ()=>{
      dispatch({type: "REQUEST_" + request})
    }
  }
}

const ContainerConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(Translations(RequestErrorContainer));

export default ContainerConnected;
