import React from 'react'
import {connect} from 'react-redux'
import { width, height } from 'window-size';

const LicenseFilter = ({license_type, filter, children})=>{

    if(!(
        Array.isArray(filter) && filter.indexOf(license_type)!=-1
        || typeof(filter)==="string"&& filter ===license_type
        || typeof(filter)==="function" && filter(license_type)
        ))
        return null;  

    return (React.Children.count(children)===1)?
                React.Children.only(children):( <div style={{width:"100%", height:"100%"}}>
                    {
                        children
                    }
                </div>);
}

export const LicenseFilterContainer = connect(({user}, otherProps)=>({
    license_type: user.license_type,
    ...otherProps    
}))(LicenseFilter);

export default LicenseFilterContainer;