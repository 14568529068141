
import { times } from 'lodash';

export const coreMap = {
  'Desarrollo Personal y Social': [
    "Identidad y autonomía",
    "Convivencia y ciudadanía",
    "Corporalidad y movimiento"
  ],
  'Comunicación integral': [
    "Lenguaje verbal",
    "Lenguajes artísticos",
  ],
  'Interacción y comprensión del entorno': [
    "Exploración del entorno natural",
    "Comprensión del entorno sociocultural",
    "Pensamiento matemático"
  ]
};
export const oaMap = {
  "Identidad y autonomía": 13,
  "Convivencia y ciudadanía": 11,
  "Corporalidad y movimiento": 9,

  "Lenguaje verbal": 10,
  "Lenguajes artísticos": 7,

  "Exploración del entorno natural": 12,
  "Comprensión del entorno sociocultural": 11,
  "Pensamiento matemático": 12
};

export const allFiltersCL = [
  {
    id:"education_level"  ,
    label:"Nivel Educativo",
    options: [
    { value: 3, label: "3 años" },  
    { value: 4, label: "4 años" },  
    { value: 5, label: "5 años" },  
    { value: 6, label: "6 años" },  
    { value: 7, label: "7 años" },    
    ],
  },
  {
    id:"expertice_scope"  ,
    label:"Ambito de Experiencia",
    options: [
      { value: 'Desarrollo Personal y Social', label: 'Desarrollo Personal y Social' },  
      { value: 'Comunicación integral', label: 'Comunicación integral' },  
      { value: 'Interacción y comprensión del entorno', label: 'Interacción y comprensión del entorno' },  
    ],
  }, 
  {
    id:"education_core"  ,
    label:"Núcleo de Aprendizaje",
    options: [
      "Identidad y autonomía",
      "Convivencia y ciudadanía",
      "Corporalidad y movimiento",
      "Lenguaje verbal",
      "Lenguajes artísticos",
      "Exploración del entorno natural",
      "Comprensión del entorno sociocultural",
      "Pensamiento matemático"].map((k)=>(
        {
          value: k,
          label: k
        }
      ))
  }, 
  {
    id:"education_target"  ,
    label:"Objetivo de Aprendizaje",
    options: times(13,(n)=>({label: "OA"+(n+1), value: ''+(n+1)})),
  }, 
  {
    id:"activity_time"  ,
    label:"Tiempo de la Actividad",
    options: [
      { value: 15, label: '15min' },    
      { value: 30, label: '30min' },   
      { value: 45, label: '45min' },    
    ],
  },  
  {
    id:"complexity"  ,
    label:"Nivel de Complejidad",
    options: [
      { value: 'Baja', label: 'Bajo' },    
      { value: 'Media', label: 'Medio' },   
      { value: 'Alta', label: 'Alto' },    
    ],
  },
]