/* @flow */

import React from 'react';
import { SignUpForm, RegistrationFooter } from '../components';
import {
  ParentSignUpFormContainer, PleIQCodeFormContainer, ChildrenFormContainer,
  SignInFormContainer, DirectorIEFormContainer, HeaderContainer, UserPasswordResetFormContainer,
  UserPasswordNewFormContainer, PageTitleContainer, DownloadAppContainer
} from '../containers';
import _ from 'lodash';
import { DeleteAccountFormContainer } from '../containers/DeleteAccountFormContainer';
import { DeletedAccountComponent } from '../components/DeleteAccountForm';

type Props = {
  children?: React.Component<*>,
  queryParams?: Object
};

/**
 * Container component for the registration screen forms
 *
 * @method BaseRegistrationScreen
 * @param  {React.Components}   children  - a tree of elements within the component
 */
export const BaseRegistrationScreen = ({ children }: Props) => {
  return (
    <div id="sign-in" className="layout-base layout-registration">
      <HeaderContainer />
      <div className="layout-registration-content">
        {children}
      </div>
      <div className="layout-registration-footer">
        <RegistrationFooter />
      </div>
    </div>
  );
};

export const BaseDownloadApp = ({ children }: Props) => {
  return (
    <div className="layout-registration">
      <div className="layout-registration-content download">
        {children}
      </div>
      <div className="layout-registration-footer">
        <RegistrationFooter />
      </div>
    </div>
  );
};

const SignIn = ({ queryParams }) => {
  return (
    <BaseRegistrationScreen>
      <PageTitleContainer pageTitle="title_registration_signin" />
      <SignInFormContainer queryParams={queryParams} />
    </BaseRegistrationScreen>
  );
};

const DirectorIE = ({ queryParams }) => {
  return (
    <BaseRegistrationScreen>
      <PageTitleContainer pageTitle="title_administrador_institucion_educativa" />
      <DirectorIEFormContainer queryParams={queryParams} />
    </BaseRegistrationScreen>
  );
};

const DownloadApp = ({ queryParams }) => {
  return (
    <BaseDownloadApp>
      <PageTitleContainer pageTitle="title_download_app" />
      <DownloadAppContainer queryParams={queryParams} />
    </BaseDownloadApp>
  );
};


const AccountDelete = ({ queryParams }) => {
  return (
    <BaseRegistrationScreen>
      <PageTitleContainer pageTitle="title_registration_deletes" />
      <DeleteAccountFormContainer queryParams={queryParams} />
    </BaseRegistrationScreen>
  );
};

const AccountDeleted = ({ queryParams }) => {
  return (
    <BaseRegistrationScreen>
      <PageTitleContainer pageTitle="title_registration_deletes" />
      <DeletedAccountComponent  />
    </BaseRegistrationScreen>
  );
};
const OAuth = () => {
  return (
    <BaseRegistrationScreen>
      <SignUpForm.OAuth />
    </BaseRegistrationScreen>
  );
};

const ParentSignUpForm = ({ queryParams }) => {
  return (
    <BaseRegistrationScreen>
      <PageTitleContainer pageTitle="title_registration_signup" />
      <ParentSignUpFormContainer queryParams={queryParams} />
    </BaseRegistrationScreen>
  );
};

const PleiqCode = () => {
  return (
    <BaseRegistrationScreen>
      <PageTitleContainer pageTitle="title_registration_activation" />
      <PleIQCodeFormContainer />
    </BaseRegistrationScreen>
  );
};

const ChildrenInfo = () => {
  return (
    <BaseRegistrationScreen>
      <PageTitleContainer pageTitle="title_registration_children" />
      <ChildrenFormContainer />
    </BaseRegistrationScreen>
  );
};

const EmailConfirmation = () => {
  return (
    <BaseRegistrationScreen>
      <PageTitleContainer pageTitle="title_registration_confirm_email" />
      <SignUpForm.EmailConfirmation />
    </BaseRegistrationScreen>
  );
};

const Congratulations = ({ onClick = _.noop }) => {
  return (
    <BaseRegistrationScreen>
      <PageTitleContainer pageTitle="title_registration_congratulations" />
      <SignUpForm.Congratulations onClick={onClick} />
    </BaseRegistrationScreen>
  );
};

const AccountConfirmed = () => {
  return (
    <BaseRegistrationScreen>
      <PageTitleContainer pageTitle="title_registration_account_confirmed" />
      <SignUpForm.AccountConfirmed />
    </BaseRegistrationScreen>
  );
};

const UserPasswordNewForm = () => {
  return (
    <BaseRegistrationScreen>
      <PageTitleContainer pageTitle="title_registration_reset_password" />
      <UserPasswordNewFormContainer />
    </BaseRegistrationScreen>
  );
};

/*
 * When using WithQueryParamsRoute you need to accept queryParams props
 * this props comes with all the queryParams passed to the route
 * You can also add validations, check App.js for more info
 */
const UserPasswordResetForm = ({ queryParams }) => {
  return (
    <BaseRegistrationScreen>
      <PageTitleContainer pageTitle="title_registration_reset_password" />
      <UserPasswordResetFormContainer queryParams={queryParams} />
    </BaseRegistrationScreen>
  );
};

const Registration = {
  AccountConfirmed,
  ChildrenInfo,
  Congratulations,
  EmailConfirmation,
  OAuth,
  ParentSignUpForm,
  PleiqCode,
  SignIn,
  UserPasswordNewForm,
  UserPasswordResetForm,
  AccountDelete,
  DirectorIE,
  DownloadApp,
  AccountDeleted
};

export default Registration;
