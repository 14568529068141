/* @flow */

import React from 'react';
import { Form, FormGroup, FormFeedback } from 'reactstrap';
import { InputField, RegistrationForm, InputFieldCheckbox } from './';
import { Field, reduxForm } from 'redux-form';
import { required, minLength, email } from './validations';
import Translations from '../hocs/TranslationsHelper';
import type { ReduxFormFunction, ReduxFormErrorGlobal, ReduxFormFlag, TranslationsObject } from '../components/types';
import classNames from 'classnames';
import RegistrationSubmitButton from './RegistrationSubmitButton';
import { toBoolean } from './normalizers';

type Props = {
  handleSubmit: ReduxFormFunction,
  translations: TranslationsObject,
  error: ReduxFormErrorGlobal,
  submitting: ReduxFormFlag,
  valid: ReduxFormFlag,
  onBackButtonClick: () => mixed
};
const lower = value => value && value.toLowerCase()

/**
 * component to display the sign up form for a parent
 *
 * @method ParentSignUpForm
 * @param  {Function}  handleSubmit  - injected by 'redux-form', supposed to handle submit
 *
 */
const ParentSignUpForm = ({ handleSubmit, translations, error, valid, submitting, onBackButtonClick, ...otherProps }: Props) => {
  const formClassName = classNames({ 'has-danger': error }, 'form');
  const disabled = submitting;

  return (
    <RegistrationForm
      title={translations.t('parent_sign_up_form_title')}
      subtitle={translations.t('parent_sign_up_form_subtitle')}
      onBackButtonClick={onBackButtonClick}
      backButton>
      <Form id="parent-sign-up-form" className={formClassName} onSubmit={handleSubmit}>
        {
          error &&
          <FormGroup>
            <FormFeedback>{translations.t(error)}</FormFeedback>
          </FormGroup>
        }
        <Field
          className="text-center"
          type="text"
          name="firstName"
          placeholder={translations.t('first_name')}
          disabled={disabled}
          component={InputField}
          validate={[ required ]}
        />
        <Field
          className="text-center"
          type="text"
          name="lastName"
          placeholder={translations.t('last_name')}
          disabled={disabled}
          component={InputField}
          validate={[ required ]}
        />
        <Field
          className="text-center lowercased"
          type="text"
          name="email"
          placeholder={translations.t('email_placeholder')}
          disabled={disabled}
          component={InputField}
          validate={[ required, email ]}
          normalize={lower}
        />
        <Field
          type="text"
          name="emailRetype"
          className="text-center lowercased"
          placeholder={translations.t('email_placeholder_retype')}
          component={InputField}
          validate={[ required, email]}
          disabled={disabled}
          normalize={lower}
        />
        <Field
          className="text-center"
          type="password"
          name="password"
          placeholder={translations.t('password')}
          disabled={disabled}
          component={InputField}
          validate={[required, minLength(8)]}
        />
        <Field
          className="text-center"
          type="password"
          name="passwordRetype"
          placeholder={translations.t('password_confirmation')}
          disabled={disabled}
          component={InputField}
          validate={[required, minLength(8)]}
        />
        <Field
          className="parent-sign-up-form-newsletter text-center"
          type="checkbox"
          name="preferences.receive_marketing_emails"
          label={translations.t('receive_marketing_emails')}
          disabled={submitting}
          component={InputFieldCheckbox}
          normalize={toBoolean}
        />
        <p className="mt-2 text-center parent-sign-up-form-terms-and-conditions"
          dangerouslySetInnerHTML={{ __html: translations.t('terms_and_conditions') }}
        />
        <div className="registration-form-button-wrapper">
          <RegistrationSubmitButton loading={submitting} disabled={!valid}>
            {translations.t('continue')}
          </RegistrationSubmitButton>
          <span className="registration-form-next-icon pleiq-icon-icon_arrow_right" />
        </div>
      </Form>
    </RegistrationForm>
  );
};

export const FORM_NAME = 'signUpForm';

export default reduxForm({
  form: FORM_NAME,
  initialValues: {
    preferences: {
      receive_marketing_emails: true
    }
  }
})(Translations(ParentSignUpForm));
