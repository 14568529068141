/* @flow */

import React from 'react';
import { Paper } from '../../components';
import {
  SchoolDashboardNavContainer, SchoolDashboardHeadingContainer, HeaderContainer,
  CoursesSectionContainer, PageTitleContainer
} from '../../containers';
import { StudentPdfSelection, StudentCommentsPdfSelection, StudentCaligrafixPdfSelection } from '../../pdfview/StudentView';
import { CourseSelection } from '../../pdfview/CourseView';

import { HierarchyReportPdf } from '../../pdfview/Hierarchy';
import LicenseFilter from '../../containers/LicenseFilter';
import SchoolUserInvitationsContainer from "../../containers/SchoolUserInvitationsContainer"
import SchoolUserInvitationFormContainer from "../../containers/SchoolUserInvitationFormContainer"
import { Col, Row } from 'reactstrap'; 
import SchoolAdminNav, {adminNavItems} from "./SchoolAdminNav"
/**
 * screen for the school courses
 *
 * @method SchoolProgress
 *
 */  
const SchoolUserInvitations = ({queryParams}) => {
  return (
    <div id="school-dashboard" className="school-dashboard layout-base">
      <PageTitleContainer pageTitle="title_school_courses" />
      <HeaderContainer />
      <SchoolDashboardHeadingContainer />
      <Paper.Container lead>
       {

         <SchoolAdminNav currentTab="invitations"   />
       }

        <Row>
          <Col>
            <h3>Crear nueva invitacion</h3>
          </Col>
        </Row>
        <Row>
          <Col>
            <SchoolUserInvitationFormContainer/>
          </Col>
        </Row>
        <hr/>
        <Row>
          <Col>
            <SchoolUserInvitationsContainer/>
          </Col>
        </Row> 
      </Paper.Container>

   
    </div>
  );
};

export default SchoolUserInvitations;
