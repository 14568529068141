/* @flow */

import React from 'react';
import classNames from 'classnames';
import { compose, pure } from 'recompose';
import Translations from '../hocs/TranslationsHelper';

/**
 * Renders the IntelligencesNav.
 *
 * @method IntelligencesNav
 * @param {Array}    slugs   - An array of slugs for intelligences
 * @param {boolean}  general - Wether or not to include the general tab
 * @param {Function} onClickIntelligence - Callback called on click on an intelligence
 * @param {boolean}  selected - Slug of the selected intelligence to render as active
 */
const IntelligencesNav = ({ slugs, general, onClickIntelligence, selected, translations }) => {
  const intelligences = general ? ['general', ...slugs] : slugs;

  return (
    <div className="scrollable intelligences-nav">
      <div className="container-scrollable-outer">
        <div className="container-scrollable-inner d-flex align-items-center justify-content-between">
          {
            intelligences.map((slug) => {
              const type = slug.toUpperCase();
              const active = type === selected.toUpperCase();
              const intelligenceClassName = classNames(
                'intelligences-nav-item',
                'd-inline-block',
                { 'intelligences-nav-item--active': active }
              );

              return (
                <div
                  key={slug}
                  onClick={() => onClickIntelligence(slug)}
                  className={intelligenceClassName}>
                  { translations.t(type) }
                </div>
              );
            })
          }
        </div>
      </div>
    </div>
  );
};

const IntelligencesNavTranslated = Translations(IntelligencesNav);

export default compose(
  pure
)(IntelligencesNavTranslated);
