
import React from 'react';
import {StudentCommentsListContainerConnected} from "./StudentCommentListContainer"
import { withRouter } from 'react-router-dom'; 
import {connect} from "react-redux"
import { Paper, InfoTooltip, Button } from '../components';
import { Col, Row } from 'reactstrap';

import Translations from '../hocs/TranslationsHelper';
import { findStudentAcrossReducers } from '../../selectors/students';

import iconComments from "../../img/ui_icon_comment_section.svg"
import { showCommentsStudent } from '../../actions/students';
import RoleFilter from './RoleFilter';
const SchoolStudentDashboardComments= ({comments_count,student,hideHeader,  translations, dispatch, match:{params:{student_id}}})=>{
    
    return  <Row>
        <Col xs="12"> 
        {
            (hideHeader)?null:
            ( 
                <Paper.Title>
                    <h1 className="mt-5 comments-student-title-box" style={{marginBottom:"0px"}}>
                    <img src={iconComments} className="comments-student-title-icon"/> 
                    { translations.t('student_comments') } {(comments_count>0)?("("+comments_count+")"):null} 
                    <div className="student-info-item-comments right">
                        
                        <RoleFilter roles={["teacher","coordinator"]}>
                            <Button type="edit" onClick={()=>dispatch(showCommentsStudent(student))}> {translations.t('add_comment')}</Button>
                        </RoleFilter> 
                    </div> 

                    </h1>
                </Paper.Title>

            )
        }
        <Paper.Content>
        <StudentCommentsListContainerConnected isStatic={true} student_id={student_id}/>
        </Paper.Content> 
        </Col>
    </Row>;
}

export const SchoolStudentComments = Translations(
    connect(
        (state, ownProps)=>{
            const {match:{params:{student_id}}} = ownProps;

            const student = findStudentAcrossReducers(state, student_id);
            return {
                comments_count: student.child_comments_count,
                student
            }
        },
        (dispatch)=>({dispatch})
    )( SchoolStudentDashboardComments)
    
    
    );

export default withRouter(SchoolStudentComments);