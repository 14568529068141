/* @flow */

import React from 'react';
import { ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText } from 'reactstrap';
import type { FeedGroupItemProps, TranslationsObject } from './types';
import Translations from '../hocs/TranslationsHelper';

type Props = {
  children?: React.Component<*>,
  className?: string
};

/**
 * A special type of ListGroup http://reactstrap.github.io/components/listgroup/
 * that is used to render a feed within our application, each feed can have different
 * types that determines the elements inside each entry. Types for a feed can be:
 * ActivityFeed or manual input of object literal that match the format
 *
 * @method FeedGroup
 * @param  {FeedGroupItem}  children - N FeedGroupItems that will represent each of the entries
 *
 * @example
 * <Feed.Group>
 *    <Feed.GroupItem data={{ ...ActivityFeed.TYPES.RECORD, subheading: 'Reto Linguistico', time: 'Hace 20 min' }} />
 *    <Feed.GroupItem data={{ icon: 'imported_icon', heading: 'Cool', subheading: 'Even Better', time: 'Hace 1 hora' }} />
 * </Feed.Group>
 */
const FeedGroup = ({ children, empty, translations }: { children: React.Component<*>, empty: boolean, translations: TranslationsObject }) => {
  if (empty) {
    return (
      <ListGroup className="feed">
        <ListGroupItem className="empty-feedback">
          <div className="info-wrapper">
            <ListGroupItemHeading>{ translations.t('no_activity_yet') }</ListGroupItemHeading>
            <ListGroupItemText>{ translations.t('play_and_comeback') }</ListGroupItemText>
          </div>
        </ListGroupItem>
      </ListGroup>
    );
  }

  return createElement(ListGroup, { className: 'feed', children });
};

const IconWrapper = ({ icon }) => {
  if (icon) {
    return (
      <div className="icon-wrapper">
        <img className="img-fluid" src={icon} alt={`feed-item-${icon}`} />
      </div>
    );
  }
};

const FeedGroupItem = ({ data, translations }: FeedGroupItemProps) => {
  const { icon, heading, subheading, time, id } = data;

  return (
    <ListGroupItem id={id}>
      {IconWrapper({ icon }) || null}
      <div className="info-wrapper">
        <ListGroupItemHeading className="truncate-feed-entry-description">{translations.t(heading)}</ListGroupItemHeading>
        <FeedGroupItemSubHeading className="truncate-feed-entry-description">{translations.t(subheading)}</FeedGroupItemSubHeading>
        <ListGroupItemText>{time}</ListGroupItemText>
      </div>
    </ListGroupItem>
  );
};

const FeedGroupItemSubHeading = ({ children, className = '' }: Props) => {
  return createElement(ListGroupItemHeading, { className: `${className} list-group-item-subheading`, children });
};

const createElement = (element, { className, children }) => {
  return React.createElement(element, { className }, children);
};

const Feed = {
  Group: Translations(FeedGroup),
  GroupItem: Translations(FeedGroupItem)
};

export default Feed;
