/* @flow */

import { branch, renderNothing } from 'recompose';

/**
 * Component that renders nothing if account_type isn't Parent
 *
 * @method ParentComponent
 * Usage:
 *
 * This component needs to be passed the account_type from the User reducer in
 * the Redux store. So the container that gets that data must be connected to
 * Redux.
 *
 * Then you can just pass it along `compose`:
 *
 * const ComponentParentBla = compose(
 *  ParentComponent,
 *  pure,
 *  ...
 * )(Component);
 */
 export const ParentComponent = branch(
   ({ account_type }) => account_type === 'School',
   renderNothing
 );
