/* @flow */

import React from 'react';
import { connect } from 'react-redux';
import { pure, compose, withHandlers } from 'recompose';
import Registration from '../screens/Registration';
import { updateUserInfo } from '../../actions/user';
import { push } from 'react-router-redux';
import type { ReduxFunction, ReduxFormFunction, RecomposeHandler } from '../components/types';

type Props = {
  dispatch: ReduxFunction,
  onSubmit: RecomposeHandler,
  handleSubmit: ReduxFormFunction,
  queryParams?: Object
};

/**
 * Container that will handle the logic to allow a user to reset password
 *
 * @method CongratulationsContainer
 */
const CongratulationsContainer = ({ onClick }: Props) => {
  return <Registration.Congratulations onClick={onClick} />;
};

export const CongratulationsContainerHandlers = {
  onClick: (props: Props) => () => {
    const { dispatch, user } = props;

    // After we go to the dashboard from the congratulations screen
    // we update the `is_new` field to be false and allow to render
    // the dashboard correctly
    if (user.is_new) {
      dispatch(updateUserInfo({
        body: {
          is_new: false
        }
      }));
    }

    dispatch(push('/'));
  }
};

const CongratulationsContainerEnhancements = compose(
  withHandlers(CongratulationsContainerHandlers),
);

const mapStateToProps = ({ user }) => {
  return { user };
};

const mapDispatchToProps = (dispatch) => {
  return { dispatch };
};

const CongratulationsContainerComposed = compose(
  CongratulationsContainerEnhancements,
  pure
)(CongratulationsContainer);

const CongratulationsContainerConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(CongratulationsContainerComposed);

export { CongratulationsContainerConnected as CongratulationsContainer };
export default CongratulationsContainerConnected;
