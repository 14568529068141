/* @flow */

import { responseHasError } from '../diplomatico/reducers/helper';
import type { Action } from '../diplomatico/types';
import mergeWithReplace from '../helpers/mergeWithReplace';
import { enableInitializing } from 'initializable-reducer';

const initialState = {};

function achievementCards(state: Object = initialState, { type, payload }: Action) {
  switch (type) {
/*     case 'REQUEST_ACHIEVEMENT_CARDS':

      const { intelligence_id, language } = payload.request; 
      const newAchievementCard = {
        [intelligence_id]: {
          [language]:  []
        }
      }; 
      return mergeWithReplace({}, [state, newAchievementCard])
     */
    
    case 'RESPONSE_ACHIEVEMENT_CARDS':
      if (responseHasError(payload)) {
        return state;
      }

      const { intelligence_id, language } = payload._meta.payload.request;

      const newAchievementCard = {
        [intelligence_id]: {
          [language]:  payload.body
        }
      };

      return mergeWithReplace({}, [state, newAchievementCard]);
    default:
      return state;
  }
}

export {
  achievementCards,
  initialState
};

export default enableInitializing(achievementCards);
