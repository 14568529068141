/* @flow */

import React from 'react';
import { Paper, InfoTooltip, InputAddon } from '../components';
import { compose, lifecycle, shouldUpdate } from 'recompose';
import { connect } from 'react-redux';
import {
  SchoolDashboardNavContainer, SchoolDashboardHeadingContainer,
  HeaderContainer, SchoolGeneralIntelligenceProgressContainer,
  DataDisplayGeneralSchoolContainer,
  SchoolGeneralCoursesProgressContainer,
  PageTitleContainer
} from '../containers'; 
import { Col, Row, Modal } from 'reactstrap';
import Translations from '../hocs/TranslationsHelper'; 

import { PdfData } from '../../pdfer/Pdfable';
import { SchoolReportPdf } from '../pdfview/SchoolView';
import { withRouter } from 'react-router';
import LessonPlansContainer from '../containers/activity-bank/LessonPlansContainer';
import LessonPlanDataContainer from '../containers/activity-bank/LessonPlanDataContainer';
import FilterBarContainer from '../containers/activity-bank/FilterBar';
import { push } from 'react-router-redux';
import { SearchInput } from '../components';
import tutorials from '../../data/tutorials_es'
import topScroll from '../hocs/topScroll';


var categories = [... new Set(tutorials.map(({category})=> category))] 
/**
 * screen for the school Progress
 *
 * @method ActivityBank
 *
 */
const VideoTutorials = ({ translations, match,location, dispatch }) => {
  topScroll();
  const { params } = match;
  const { kind } = params;
  
 
  //<input type="text" value={search} onChange={(e)=>{dispatch(push(`/lesson_plans/${kind}?search=${encodeURIComponent(e.target.value)}&&filter=${encodeURIComponent(filter)}`))}} placeholder="search"/>  
              

  return (
    <div id="school-dashboard" className=" layout-base">
      <PageTitleContainer pageTitle={"ab_"+kind+"_title"} />
      <HeaderContainer />
      <SchoolDashboardHeadingContainer /> 
      <Paper.Container lead >
        <Row>
          <Col xs="12">
            <Paper.Title>
              <h1 style={{marginBottom:"0px"}}>{ translations.t('video_tutorials_title') }</h1>
            </Paper.Title> 
          </Col> 
          <Col xs="12"> 
        <div className="school-nav paper-nav d-flex justify-content-left align-items-center" style={{marginBottom:"0px"}}> 
            {
              categories.map((k)=>(

                <Paper.NavItem
                  key={k}
                  onClick={() => {dispatch(push(`/video_tutorials/${k}`))} }
                  active={k === kind}   
                  label={translations.t("video_tutorials_" + k+"_title")}
                  hideLabelSm
                /> 

              ))

            }  
        </div>
          </Col> 
          <Col xs="12">
            
            <Paper.Content> 
              {
                tutorials.filter(({category})=>(category===kind)).map(({title, description,duration,url},key)=><div className="section-intro-video">
					        <h4  className="vt-title">
                    {title}
                  </h4>
                  <p>
                    {description}
                  </p>
                  <p className="vt-duration">
                    {translations.t('video_tutorials_duration' )}: {duration}
                  </p>
                  <iframe title="Video" width="560" height="315" src={url} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
				
                </div>)
              }  
            </Paper.Content> 
          </Col>
        </Row>
      </Paper.Container>
 
    </div>
  );
};

export default connect(()=>({}), (dispatch)=>({dispatch}))(Translations(withRouter(  
  
    VideoTutorials 
)));
