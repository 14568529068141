/* @flow */

import React from 'react';
import { connect } from 'react-redux';
import { startSubmit } from 'redux-form';
import { compose, pure, withHandlers } from 'recompose';
import { FORM_NAME } from '../components/StudentDataForm';
import { StudentDataForm } from '../components/';
import type { ReduxFunction } from '../components/types';
import { mapCoursesForSelect } from '../../selectors/courses';
import { apiValidColorValues, mapColorStringToHex } from '../components/ColorSelect';
import { showConfirmationModal } from '../../actions/confirmationModal';
import TranslationsHelper from '../hocs/TranslationsHelper';

type Props = {
  dispatch: ReduxFunction,
  initialValues: Object
};

const StudentDataFormContainer = (props: Props) => {
  return <StudentDataForm {...props} />;
};

export const StudentDataFormContainerHandlers = {
  onSubmit: ({ dispatch, initialValues }: Props) => (values) => {
    const { student } = values;
    const payload = {
      student: {
        ...student,
        color: apiValidColorValues[student.color]
      },
      original_course_id: initialValues.student.course_id
    };

    dispatch(startSubmit(FORM_NAME));

    // if student id is truthy, that means we should update it
    if (student.id) {
      dispatch({ type: 'REQUEST_STUDENT_UPDATE', payload });
      return;
    }

    // otherwise, we create the student
    dispatch({ type: 'REQUEST_STUDENT_CREATE', payload });
  },
  onDeleteClick: ({ dispatch, initialValues }: Props) => () => {
    const { student } = initialValues;
    const actions = [
      startSubmit(FORM_NAME),
      { type: 'REQUEST_STUDENT_DELETE', payload: { student } }
    ];

    dispatch(showConfirmationModal({
      title: 'delete_student_confirmation_title',
      message: 'delete_student_confirmation_message',
      actions
    }));
  }
};

const StudentDataFormContainerComposed =  compose(
  withHandlers(StudentDataFormContainerHandlers),
  pure
)(StudentDataFormContainer);

const mapStateToProps = (state, ownProps) => ({
  initialValues: { student: mapColorStringToHex(ownProps.student) },
  coursesOptions: mapCoursesForSelect(state,ownProps.translations),
  persisted: !!(ownProps.student && ownProps.student.id)
});

const mapDispatchToProps = (dispatch) => ({ dispatch });

export default TranslationsHelper( connect(
  mapStateToProps,
  mapDispatchToProps
)(StudentDataFormContainerComposed));
