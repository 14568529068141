/* @flow */

export function getStudentIntelligenceProgress({ student_id }) {
  return {
    type: 'REQUEST_STUDENT_INTELLIGENCE_PROGRESS',
    payload: {
      student_id
    }
  };
}

export function getStudentIntelligenceHistory({ student_id, intelligence_id, start_date, end_date, group_by }: Object) {
  return {
    type: 'REQUEST_STUDENT_INTELLIGENCE_HISTORY',
    payload: {
      student_id,
      intelligence_id,
      start_date,
      end_date,
      group_by
    }
  };
}

export function getStudentAchievementCardsProgress({ intelligence_id, student_id }: Object) {
  return {
    type: 'REQUEST_STUDENT_ACHIEVEMENT_CARDS_PROGRESS',
    payload: {
      intelligence_id,
      student_id
    }
  };
}

//caligrafix

export function getStudentCaligrafixBookProgress({ student_id }) {
  return {
    type: 'REQUEST_STUDENT_CALIGRAFIX_BOOK_PROGRESS',
    payload: {
      student_id
    }
  };
}

export function getStudentCaligrafixBookHistory({ student_id, caligrafix_book_id, start_date, end_date, group_by }: Object) {
  return {
    type: 'REQUEST_STUDENT_CALIGRAFIX_BOOK_HISTORY',
    payload: {
      student_id,
      caligrafix_book_id,
      start_date,
      end_date,
      group_by
    }
  };
}