
import React, { Component } from 'react'; 
import _ from 'lodash';
import { useSelector } from 'react-redux';
import axios, { AxiosHeaders } from "axios";
const _API_PATH = process.env.REACT_APP_API_URL;


export const useApi = ()=>{
    const authorization = useSelector(({authorization})=> authorization);
    let headers = new AxiosHeaders({
        'Content-Type': 'application/json'
    }); 
    if (!_.isEmpty(authorization)) {
        headers.set('Authorization', `Bearer ${authorization.token}`);
    } 
    
    return {
        post: (url, body)=> axios.post( `${_API_PATH}${url}`,body, {headers}),
        get: (url)=> axios.get( `${_API_PATH}${url}`, {headers}),
        put: (url, body)=> axios.put( `${_API_PATH}${url}`, body, {headers}),
        delete: (url)=> axios.delete(  `${_API_PATH}${url}`,  {headers}) 
    } 
}