/**
* @flow
*
* data for tutorials_es  
*
*/
const tutorials_es = [ 
	{
		title:"Conoce los 9 criterios para la enseñanza digital efectiva en preescolar",
		description:"Conoce los 9 criterios para una enseñanza digital remota y presencial en educación preescolar.",
		duration:"3:46 min",
		url:"https://www.youtube.com/embed/stekyMQPb6w",
		category:"general"
	},
	/*{
		title:"Beneficios y elementos principales del Aula Inteligente PleIQ School",
		description:"Conoce los beneficios y elementos que conforman el Aula Inteligente 4.0 Caligrafix + PleIQ.",
		duration:"8:27 min",
		url:"https://www.youtube.com/embed/Pqpan-XVkZk",
		category:"general"
	}, */

	{
		title:"Uso de la App",
		description:"Introducción al uso de la aplicación PleIQ, como iniciar sesión con tu cuenta y escanear las experiencias de realidad aumentada.",
		duration:"2:58 min",
		url:"https://www.youtube.com/embed/bnVa6KIhV-U",
		category:"pleiq_app"
	},
	{
		title:"Cambio de perfil en la App de PleIQ",
		description:"Conoce cómo cambiar el perfil de un niño o niña en un dispositivo a través de la App de PleIQ.",
		duration:"4:23 min",
		url:"https://www.youtube.com/embed/S_6KLyxSsfE",
		category:"pleiq_app"
	},
	{
		title:"Trabajando sin internet en la App",
		description:"Conoce los pasos para descargar las actividades a la App de PleIQ y así trabajar sin conexión a internet.",
		duration:"5:31min",
		url:"https://www.youtube.com/embed/rJVzYqv0HYk",
		category:"pleiq_app"
	},
	{
		title:"Cómo trabajar de forma efectiva con los cuadernos interactivos Caligrafix + PleIQ.",
		description:"Conoce la dinámica de trabajo con los cuadernos de la línea interactiva para preescolar Caligrafix + PleIQ.",
		duration:"6:14 min",
		url:"https://www.youtube.com/embed/bhd4WGkgodU",
		category:"books"
	},
	{
		title:"Tipos de marcadores en los cuadernos de la línea preescolar interactiva",
		description:"Conoce los tipos de marcadores de los cuadernos de la línea preescolar interactiva Caligrafix + PleIQ: Motivacionales, de ejercitación y complementarios.",
		duration:"13:22 min",
		url:"https://www.youtube.com/embed/kw7uXFjZ6g0",
		category:"books"
	},
	{
		title:"Conociendo las inteligencias múltiples en los cubos PleIQ",
		description:"Descubre una aproximación al trabajo con los cubos PleIQ desde las ocho inteligencias múltiples que forman parte de la propuesta educativa de PleIQ School.",
		duration:"6:46 min",
		url:"https://www.youtube.com/embed/YMUczdbEAp0",
		category:"cubes"
	},
	{
		title:"Cómo trabajar en un segundo idioma en la App de PleIQ",
		description:"Conoce cómo realizar el cambio de idioma en la App de PleIQ para aprender en un segundo idioma.",
		duration:"4:42 min",
		url:"https://www.youtube.com/embed/5QOLWmMQaVs",
		category:"cubes"
	},
	{
		title:"Diferencias entre experiencias y actividades de aprendizaje en PleIQ",
		description:"Conoce la diferencia entre las experiencias y actividades de aprendizaje con los cubos PleIQ.",
		duration:"1:52 min",
		url:"https://youtube.com/embed/6lZftt3A9aA",
		category:"cubes"
	},
	{
		title:"Trabajando la segmentación silábica con el cubo lingüístico",
		description:"Conoce la actividad de segmentación silábica con los cubos.",
		duration:"2:44 min",
		url:"https://www.youtube.com/embed/ZQGaRnJ0b8w",
		category:"cubes"
	},
	{
		title:"Conociendo la historia lingüística con los cubos",
		description:"Conociendo la actividad de comprobación de lectura con el cubo lingüístico.",
		duration:"4:13 min",
		url:"https://www.youtube.com/embed/rrofQbZcyCQ",
		category:"cubes"
	},
	{
		title:"Reconociendo los sonidos y la grafías de las letras del alfabeto",
		description:"Conoce la actividad del monstruo come galletas, para la discriminar las letras por su fonética y tipo de grafema.",
		duration:"2:15 min",
		url:"https://www.youtube.com/embed/tsCrAvyCiUg",
		category:"cubes"
	},
	{
		title:"Completa la palabra con la vocal faltante",
		description:"Conoce la actividad para el desarrollo de la conciencia fonológica a través de la completación de vocales faltantes en una palabra.",
		duration:"3:11 min",
		url:"https://www.youtube.com/embed/W5kVt79FHIU",
		category:"cubes"
	},
	{
		title:"Creando palabras uniendo letras en el espacio",
		description:"Conoce la actividad de realidad mixta para el desarrollo de los procesos pre lectores y lectores a través de la motricidad gruesa.",
		duration:"2:47 min",
		url:"https://www.youtube.com/embed/hvvd1UQdqM0",
		category:"cubes"
	},
	{
		title:"Activación de cuentas",
		description:"Aprende a activar tu cuenta school tras recibir la invitación vía email.",
		duration:"2:07 min",
		url:"https://www.youtube.com/embed/LY88LFXsSPI",
		category:"pleiq_school"
	},
	{
		title:"Ingresando a la plataforma PleIQ School con los datos de la cuenta web.",
		description:"Conoce los pasos para ingresar a la plataforma PleIQ School con los datos de la cuenta web de tu escuela y un breve recorrido para conocer la estructura de la plataforma.",
		duration:"5:07 min",
		url:"https://www.youtube.com/embed/xqrc--Vzesc",
		category:"pleiq_school"
	},
	{
		title:"Especificación de la escuela en la plataforma",
		description:"Habilita tu escuela en la plataforma, conoce sobre los niveles, cursos y estudiantes.",
		duration:"3:30 min",
		url:"https://www.youtube.com/embed/FMR9lhwyDUg",
		category:"pleiq_school"
	},
	{
		title:"Resumen de las secciones",
		description:"Un resumen de las secciones más importantes de la plataforma.",
		duration:"4:49 min",
		url:"https://www.youtube.com/embed/LzBcsErUMXw",
		category:"pleiq_school"
	},
	{
		title:"Cómo crear la estructura de mi escuela con la cuenta web en PleIQ School",
		description:"Conoce los pasos para ingresar con la cuenta web y crear la estructura de mi escuela en la plataforma de PleIQ School.",
		duration:"8:39 min",
		url:"https://www.youtube.com/embed/CrSh1oc7v2U",
		category:"pleiq_school"
	},
	{
		title:"Cómo generar un informe de progreso individual en PleIq School",
		description:"Conoce cómo descargar un informe automatizado en la plataforma PleIQ School.",
		duration:"3:23 min",
		url:"https://www.youtube.com/embed/8Qg_6OBC4e4",
		category:"pleiq_school"
	},
	{
		title:"Cómo interpretar los gráficos de progreso individual de un estudiante",
		description:"Conoce como interpretar los gráficos de progreso individual en la plataforma PleIQ School.",
		duration:"9:43 min",
		url:"https://www.youtube.com/embed/q3nnMKgdZ0o",
		category:"pleiq_school"
	},
	{
		title:"Cómo crear un registro anecdótico y pedagógico de un estudiante",
		description:"Conoce como crear un registro anecdótico y pedagógico de un estudiante en la plataforma PleIQ School.",
		duration:"4:47 min",
		url:"https://www.youtube.com/embed/wfZnVecYDAA",
		category:"pleiq_school"
	},
]
export default tutorials_es;
