/* @flow */

import { put, takeEvery, all } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { workerRequest } from '../diplomatico/workers';
import { stopSubmit } from 'redux-form';
import { FORM_NAME as PLEIQ_CODE_FORM_NAME } from '../js/components/PleIQCodeForm';
import { FORM_NAME as USER_INFO_FORM_NAME } from '../js/components/UserInfoForm';
import { setPleiqCode } from '../actions/pleiqCode';
import { updateUserInfo } from '../actions/user';

/**
 * Worker to handle the parent account activation process
 */
export const workerRequestParentActivateOptions = {
  requestHandlers: {
    onError: function* ({ action, response }: Object): any {
      yield put(stopSubmit(PLEIQ_CODE_FORM_NAME, response.payload.errors));
    },
    onSuccess: function* ({ action, response }: Object): any {
      yield put(setPleiqCode({ ...action.payload.parent, ...response.payload }));
      yield put(push('/add_children'));
    }
  },
  requestParams: {
    endpoint: '/parents/activate',
    method: 'put',
    reportable: false
  },
  buildRequestBody: (actionPayload: Object) => actionPayload
};

export const workerRequestParentActivate = workerRequest(workerRequestParentActivateOptions);

/**
 * Worker to update parent name
 * this saga is related to the parent, but wont be called directly here
 * instead it will be called on the user saga
 */
export const workerRequestUpdateParentOptions = {
  requestHandlers: {
    onError: function* ({ action, response }: Object): any {
      yield put(stopSubmit(USER_INFO_FORM_NAME, response.payload.errors));
    },
    onSuccess: function* ({ action, response }: Object): any {
      yield put(updateUserInfo({ ...action.payload.parent, ...response.payload }));
      yield put(stopSubmit(USER_INFO_FORM_NAME));
    }
  },
  requestParams: {
    endpoint: '/parents/',
    method: 'put',
    reportable: false
  },
  buildRequestBody: (actionPayload: Object) => actionPayload
};

export const workerRequestUpdateParent = workerRequest(workerRequestUpdateParentOptions);

export function* watchParentRequest(): any {
  yield all([
    takeEvery('REQUEST_PARENT_ACTIVATE', workerRequestParentActivate),
    takeEvery('REQUEST_PARENT_UPDATE', workerRequestUpdateParent)
  ]);
}
