import React, { useState, useEffect } from 'react'
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf'
import canvg from 'canvg';
 

export const global_w = 1124;
  
let pdfstyle = {
    width: (global_w+ "px")
};

class Observable{
    constructor(){
        this.handlers = []; 
    }

    subscribe(handler){
        this.handlers.push(handler);
        return ()=>{
            this.unsubscribe(handler);
        };
    }
    unsubscribe(handler){
        this.handlers = this.handlers.filter(
            function(item) {
                return (item !== handler);
            }
        );
    }
    
    fire(data){
        this.handlers.forEach((handler)=>{
            handler(data);
        });
    }
}
 

class PdfContext{
    constructor(){
        this.state = new Observable();  
        this.progress = new Observable(); 
        //this.elements = new Item();
        this.switches = [];
        this.printing = false;
        this.pdfname = "generated.pdf";
        this.items = [];
        this.props = {};
        this.mapp = {};
        this.header = [];
        this.ctx = [];

    } 

    pushContext(){
        this.ctx.push([]);
    }
    popContext(){
        var lc = this.ctx[this.ctx.length-1];
        lc.forEach(({path, item, style, props})=>{ 
            this.add(path, item, style, props);
        });

        this.ctx.pop();
    }
 
    addSwitch(handler){
        
    //   console.log("ADDED SWITCH", handler, this.printing);
            
        this.switches.push(handler);
    }
    removeSwitch(handler){
        this.switches = this.switches.filter(
            function(item) {
                return (item !== handler) ;
            } 
        );
    }
    
    report(path, item, style = "", props){
       // console.log("PTH", props, item, style);
        if(!path)
            console.warn("path problem!");
            
       // console.log(item);
        if(!item)
            throw new Error("element was null");

        if(this.ctx.length>0)
            this.ctx[this.ctx.length-1].push({path, item, style, props});
        else
            this.add( path, item, style, props );
 
    } 
    
    add(path, item, style = "", props){ 
        var clon = item.cloneNode(true);
        clon.style = style;// "width:120px;height:120px;";
      //  console.log("style, " , clon.style);

        
        if(path in this.mapp){
            this.items[this.mapp[path]].item = clon;
            return;
        }
        this.mapp[path] = this.items.length;
        this.items.push({item: clon, path:path, ...props} );
        this.count+=1; 
    } 


    async genPdf(){ 
        const global_W = this.conf.width || global_w;
        window.scrollTo(0, 0);
        //var WW = global_W+"px";
        //this.items= this.items.sort((a,b)=>((a.path=== b.path)?0:(a.path < b.path ? -1:1)));

        var tmpdiv =document.createElement("DIV");
        tmpdiv.style = "padding:0px;width:"+global_W; 
        var hider = document.createElement("DIV");
        hider.style ="opacity: 0.0;width:"+global_W; 
        hider.appendChild(tmpdiv);
        this.progress.fire(0); 
         
      //  console.log("items:", this.items);
       // console.log(this.items.length, "items,");
       // var y = 0;
    
        const pdf = new jsPDF(  'p' , 'mm', "a4");//[595.28, 595.28*ratio ]);


        var pad = 12.0;
    
        document.body.appendChild(hider);
      //  var doc = tmpdiv; 
        const pageHeight= pdf.internal.pageSize.height;

     //   var headers = [];

    //    function addHeaders(){

     //   }
        //render everything...
        for(let i =0;i< this.items.length;i++){
            var {item }= this.items[i]; 
            var container =document.createElement("DIV");//
            container.style = "opacity: 0.0;position:fixed;padding:0px;width:"+ global_W +"px !important;";//+WW; 
            var clone = item;
           // var n = 0; 
            

            var elems = clone.getElementsByTagName('*');
            for(let k=0;k< elems.length;k++){
                elems[k].classList.add( "pdf-render");
            }
           // console.log("modified ",  elems.length, "children");

            container.appendChild(clone);
            document.body.appendChild(container);  // 

            var svgl1 = clone.getElementsByTagName('svg');
            var svgl = [];
            for(let k=0;k< svgl1.length;k++)
                svgl.push(svgl1[k]);

           // console.log(svgl.length, "svg elements...");
            for(let k=0;k< svgl.length;k++){
                var s = svgl[k]; 
                var xml = new XMLSerializer().serializeToString(s); 
                //  var svg64 = btoa(xml);
                var can2 = document.createElement("CANVAS");
                var vb=  s.getBoundingClientRect();

                
                // document.body.append(can2);
                can2.style = s.style;
                can2.className = s.className.animVal;

                can2.width=vb.width;
                can2.height=vb.height;

                s.parentElement.replaceChild(can2, s);

               // console.log("svg before", can2.width,can2.height, s.className, typeof s.className );
                canvg(can2, xml);
                ///    console.log("svg after", can2.width,can2.height );

              //  document.body.re(can2);
            }
            // console.log("WWWW", global_W); 
            let canvas = await html2canvas(item,{scale:1.0,scrollX:0, scrollY:0 , windowWidth:global_W, 

                useCORS: true

            } ); 
            //canvas.style="border:2px;border-style:solid;"
            //document.body.append(canvas);
            const img = canvas.toDataURL("image/png");
            this.items[i].canvas = canvas;
            this.items[i].img = img;
            document.body.removeChild(container);   
            this.progress.fire(i* 50.0/ this.items.length);
        }
        var W = 210;
 
        var pages = [ ];
        var edited = false;
        var page = null;

        const foot = 8;
        let headerH = 0; 
        for(let i =0;i< this.items.length;i++){ 
            if(this.items[i].header){
                let {canvas} =this.items[i];
                let ratio = canvas.height/canvas.width;
                headerH+= W*ratio-pad*ratio*2 + pad; 
            }
        } 
       /* p.forEach(({canvas})=>{
            var ratio = canvas.height/canvas.width;
            h+= W*ratio-pad*ratio*2 + pad;
        });*/
 
 
        let acc_h = headerH;
 
        let lastW = null;
        for(let i =0;i< this.items.length;i++){ 
            if(this.items[i].header)continue; 
            let {canvas} =this.items[i];
            let ratio = canvas.height/canvas.width;
                
            const itemH = W*ratio-pad*ratio*2 + ((this.items[i].hpad) || pad); 
            if(itemH + acc_h > pageHeight-foot  ){
                acc_h = headerH; 
                if(lastW  )
                    lastW.newpage= true; 
            }  
            acc_h+= itemH;
            if(this.items[i].wrap)
                lastW = this.items[i] ; 
        } 
        const addPage =()=>{
            page = [];
            for(let i =0;i< this.items.length;i++){ 
                if(this.items[i].header){
                    page.push(this.items[i]);
                }
            }
            edited = false;
            pages.push(page);
            acc_h = headerH;
        }
        addPage();
        //let lastWrap = false;
        for(let i =0;i< this.items.length;i++){ 
            if(this.items[i].header)continue;
                edited = true; 
            let {canvas} =this.items[i];
            let ratio = canvas.height/canvas.width;
           // const itemH = W*ratio-pad*ratio*2 + ((this.items[i].hpad) || pad); 
         //   if(acc_h + itemH > pageHeight-foot  && lastWrap)addPage();
          //  acc_h+= itemH;
            page.push(this.items[i]);
         //   lastWrap = this.items[i].wrap;
            if(this.items[i].newpage){
                addPage();
            }
        } 
        if(!edited){
            pages.pop();
        }
        var datestr =  ( new Date()).toLocaleDateString();
        for(let i=0;i< pages.length;i++){
            var p = pages[i];
            //compute height
            //console.log("PRINTING PAGE", i);
            var h = 0;
            for(let k=0;k< p.length;k++){
                let {canvas} = p[k];
                let ratio = canvas.height/canvas.width;
                h+= W*ratio-pad*ratio*2 + ((p[k].hpad) || pad);
            }
           /* p.forEach(({canvas})=>{
                var ratio = canvas.height/canvas.width;
                h+= W*ratio-pad*ratio*2 + pad;
            });*/

            var scale = 1.0; 

            if( h > pageHeight-foot){
                 scale = (pageHeight-foot)/h;
            }
            var y =0;
            //p.forEach(({path, canvas,img})=>{
            for(let k=0;k< p.length;k++){
                let {/*path,*/ canvas,img} = p[k];
                if(canvas.width*canvas.height===0)
                    continue;
                y+= ((p[k].hpad) || pad)*scale;
                let ratio = canvas.height/canvas.width;
               // console.log("image",path,img);
                pdf.addImage(img, 'PNG',pad*scale,y   , (W-pad*2)*scale ,(W*ratio -pad*ratio*2)*scale);
               // console.log("PRINTING ELEMENT", path,canvas.width, canvas.height);
                
                y+= (W*ratio -pad*ratio*2  )*scale;

            } 
            pdf.setFontSize(foot-1);

            pdf.text(datestr,  pdf.internal.pageSize.width/2 - datestr.length*(foot-1)*0.5*0.5, pageHeight-foot);
           // console.log("DATE", datestr); 


            if(i< pages.length-1)
                pdf.addPage(); 
                this.progress.fire(50.0 + (i+1)* 50.0/  pages.length);

        } 


 
        await pdf.save(this.pdfname+".pdf");
        document.body.removeChild(hider);
    }

    async print(target, filter, props={}, conf={ }){
         
        pdfstyle = {
            width: ((conf.width || global_w)  + "px")
        };
        //console.log("PRINTING", props);
        this.ctx = [ ];
        this.mapp = {};
        this.props = props;
        this.filter= filter;
        this.conf = conf;

        this.pdfname = target;

        this.count = 0;

 

        //this.elements =new Item();
        this.items = [];
        

        this.state.fire(true); 
        this.progress.fire(0);

        this.printing = true;
        //foreach state.. 
        for(var i=0;i<this.switches.length;i++ ){
            var sw = this.switches[i]; 
            await sw.renderVariants(); 
        } 
 

        await this.genPdf();

        this.printing = false;
        this.state.fire(false);
 
    }
 
    
}

 

let context = new PdfContext(); 

export const pdfContext = context;
export const usePdf = ()=>{
    const [printing, setPrinting] = useState (false);
    useEffect(()=>{

        const unsub = context.state.subscribe((printing)=>{
        //    console.log("pdf change, detected");
             setPrinting( printing  );
        }); 
        return unsub;
    });
    return printing;

}
export function withPdfProps({path, filter, pre}){

    return (WrappedComponent)=> class extends React.Component{
        constructor(props){
            super(props);
            this.div = null;
            this.state = {
                visible: false,
                props:{}
            }
            this.updat = null;

        }
        componentDidMount(){ 
 
            this.unsubscribe = context.state.subscribe((printing)=>{
            //    console.log("pdf change, detected");
                this.setState({visible: printing &&  filter===context.filter , props:context.props});
            }); 
        }   
        componentDidUpdate(){
        //    console.log("pdf props, updated...");
        }
        componentWillUnmount(){
            this.unsubscribe(); 
        }
        render(){
 
            if(this.state.visible)
                return( 
                    <div ref = {(element) => {this.div = element;} }  style={context.printing? pdfstyle:null} > <WrappedComponent {... Object.assign({}, this.props, this.state.props) }/></div>  )
            else return null;
        }
    }
}


export function withPdfHandlers({path, filter, pre}){

    return (WrappedComponent)=> class extends React.Component{
        constructor(props){
            super(props);
            this.div = null;
            this.state = {
                visible: false,
                props:{}
            }
            this.updat = null;

        }
        componentDidMount(){
                
            context.addSwitch(this);
 
            this.unsubscribe = context.state.subscribe((printing)=>{
                if(!printing)
                    this.setState({visible: false});
                //this.setState({visible: printing &&  filter==context.filter , props:context.props});
            }); 
        }
        waitForChange(){ 
            return new Promise((resolve, reject)=>{
                this.updat = resolve; 
            }); 
        }
        async renderVariants(){
            if(filter===context.filter){
                this.changed = true;
                this.setState({visible: true, props:context.props}); 
                if(this.changed)
                    await this.waitForChange();
                if(pre!=null){
                    if(pre(this.props)){
                        this.changed = true;
                        await this.waitForChange();
                    }
                }
               // this.setState({visible: false});
            }
        }
        componentDidUpdate(){
            this.changed = false;
           // console.log("component2 did update", this.props, UPC++);
 
            if(this.updat){
                this.updat();
                this.updat = null; 
            }
        }
        componentWillUnmount(){
            this.unsubscribe();
            context.removeSwitch(this);
        }
        render(){

           // console.log("RENDERING2", this.state.props, this.state.visible);

            var wc = <WrappedComponent {... Object.assign({}, this.props, this.state.props) }/>;
            if(this.state.visible)

                return( 
                    (context.printing)?<div style={pdfstyle} ref={(element) => {this.div = element;} }>{wc}</div>:wc

                     )
            else return null;
        }
    }
}

export function withPdf({  filter, pre,props}){

    return (WrappedComponent)=> class extends React.Component{
        constructor(props){
            super(props);
            this.div = null;
            this.state = {
                visible: false,
                props:{}
            }
            this.updat = null;
            this.changed = false;

        }
        componentDidMount(){
                
            context.addSwitch(this);
            
/*
            this.unsubscribe = context.state.subscribe((printing)=>{
                this.setState({visible: printing &&  filter==context.filter , props:context.props});
            });*/
        }
        waitForChange(){ 
            return new Promise((resolve, reject)=>{
                this.updat = resolve; 
            }); 
        }
        async renderVariants(){
            if(filter===context.filter){

                //console.log("RENDER VARIANTES BEING CALLED");

                if(pre(  Object.assign({}, this.props,  context.props) )){

                    await this.waitForChange();
                }
                // console.log("current state", this.state);
                    ///console.log("CHANGING STATE TO VISIBLE");
                this.setState({visible: true, props:context.props});
                // console.log("RENDERING...");
                context.report(this.props.path,this.div/*this.divRef.current*/,"", props); 
                // console.log("EXITING...");
                //console.log("wainting for post change");
                // await this.waitForChange();
                this.setState({visible: false});

                //console.log("pre, called");
                //this.setState({visible: true, props:context.props});
               // console.log("wainting for pre change");

            }
        }
        componentDidUpdate(){
           // console.log("component did update", this.props, UPC++);
            if(this.updat){
                this.updat();
                this.updat = null; 
            }
        }
        componentWillUnmount(){
           // this.unsubscribe();
            context.removeSwitch(this);
        }
        render(){

            //console.log("RENDERING", this.state.props, this.state.visible);

            if(this.state.visible)
                return( 
                    <div ref = {(element) => {this.div = element;} }  style={context.printing?pdfstyle:null}> <WrappedComponent {... Object.assign({}, this.props, this.state.props) }/></div>  )
            else return null;
        }
    }
}

 
export function withPdfVariants(getChangers, delay){

    return (WrappedComponent) => class extends React.Component{
        constructor(props){
            super(props);
            this.div = null;
            this.updat = null;
            this.state = {
                printing:null
            };
            this.changing = false;
        }

        async renderVariants(){
            var changers = getChangers(this.props);
            for(var i = 0;i < changers.length;i++){
                var change = changers[i];
                this.changing = true; 

                if(change()){
                   // console.log("waiting for status update, ok? ");

                    if(this.changing){
                        await this.waitForChange();   
                    }
                } 
            } 
        }

        waitForChange(){ 
            return new Promise((resolve, reject)=>{
                this.updat = resolve; 
            }); 
        }

        componentDidMount(){
            context.addSwitch(this);
        }

        componentDidUpdate(){
            this.changing = false;
            if(this.updat){
                this.updat();
                this.updat = null;
            }
        }
        componentWillUnmount(){
            context.removeSwitch(this);
        }
        render(){  

            return <div style={(this.props.pdf.printing)?pdfstyle:null}><WrappedComponent {...this.props}/> </div>  ;
        }
    }
}

export class PdfData extends React.Component{


    constructor(props){
        super(props);
        this.div = null;
        this.state = {printing : false};

        //this.divRef = React.createRef();
    }

    getTarget(){
        return this.props.target;
    }

    report(){ 
        if(!this.props.filter || this.props.filter===context.filter)context.report(this.props.path,this.div/*this.divRef.current*/ , this.props.style,  this.props.pdf) ;

    }
    componentDidMount(){
        if(context.print)
            this.report();
            
        this.unsubscribe = context.state.subscribe((printing)=>{
            if(printing){
                this.report();
            }
            this.setState({printing: printing});
        });
    }
    componentWillUnmount(){
        this.unsubscribe();
    }
    componentDidUpdate(){
        if(context.printing){
            this.report();
        }
    }

    render(){ //TODO: change for the updated way , when possible... 
        return <div ref = {(element) => {this.div = element;}}  style={this.state.printing?pdfstyle:null}> 
            {this.props.children} 
        </div>;
    }
} 
export class PdfOnly extends React.Component{
    constructor(props){
        super(props);  
        this.state = {visible:false};

    } 
    componentDidMount(){
        if(context.print)
            this.setState({visible:(this.props.filter===context.filter)});

        this.unsubscribe = context.state.subscribe((printing)=>{
            this.setState({visible:(printing && this.props.filter===context.filter)});
        });
    }
    componentWillUnmount(){
        this.unsubscribe();
    } 

    render(){ //TODO: change for the updated way , when possible... 
        return (this.state.visible)?this.props.children:null;
    }
} 
 
export class PdfOnlyData extends React.Component{
    constructor(props){
        super(props);  
        this.state = {visible:false};
        this.div = null;
        this.changed = false;
        this.updat = null;
    } 
    report(){
        if(this.div!=null)
            context.report(this.props.path,this.div/*this.divRef.current*/, this.props.pstyle, this.props.pdf); 
    }

    waitForChange(){ 
        if(!this.changed)
            return Promise.resolve(); 
        return new Promise((resolve, reject)=>{
            this.updat = resolve; 
        }); 
    }

    async renderVariants(){
        if(!this.props.filter || this.props.filter===context.filter){
            this.changed = true;
            this.setState({visible:true});
          //  console.log("waiting for pdfonlydata update...", this.changed);

            await this.waitForChange();
            this.report();
            this.setState({visible:false});
           // console.log("renderinng end, ok?");
        }
    }

    componentDidMount(){
        context.addSwitch(this);
 
    }
    componentDidUpdate(){ 
        //console.log("component dit update, ok? ", this.changed);

        this.changed = false;
            
        if(this.updat!=null){
          //  console.log("calling updt");
            this.updat()
            this.updat = null;
        }

    }
    componentWillUnmount(){
        context.removeSwitch(this); 
    } 

    render(){ //TODO: change for the updated way , when possible... 
        return (this.state.visible)?<div style={pdfstyle} ref = {(element) => {this.div = element;}}> 
            {this.props.children} 
        </div>:null;
    }
}  






export function withPdf2({  filter, pre,props}){

    return (WrappedComponent)=> class extends React.Component{
        constructor(props){
            super(props);
            this.div = null;
            this.state = {
                visible: false,
                props:{}
            }
            this.updat = null;
            this.changed = false; 

        }
        componentDidMount(){
                
            context.addSwitch(this);
            
/*
            this.unsubscribe = context.state.subscribe((printing)=>{
                this.setState({visible: printing &&  filter==context.filter , props:context.props});
            });*/
        }
        waitForChange(){ 
            return new Promise((resolve, reject)=>{
                this.updat = resolve; 
            }); 
        }
        async renderVariants(){
            if(filter===context.filter){

                //console.log("RENDER VARIANTES BEING CALLED");

                if(pre(  Object.assign({}, this.props,  context.props) )){

                    await this.waitForChange();
                }
                // console.log("current state", this.state);
                    ///console.log("CHANGING STATE TO VISIBLE");
                this.setState({visible: true, props:context.props});
                // console.log("RENDERING..."); 
                context.report(this.props.path,this.div/*this.divRef.current*/,"", props); 
                // console.log("EXITING...");
                //console.log("wainting for post change");
                // await this.waitForChange();
                this.setState({visible: false});

                //console.log("pre, called");
                //this.setState({visible: true, props:context.props});
               // console.log("wainting for pre change");

            }
        }
        componentDidUpdate(){
           // console.log("component did update", this.props, UPC++);
            if(this.updat && this.props.pre_done){
                this.updat();
                this.updat = null; 
            }
        }
        componentWillUnmount(){
           // this.unsubscribe();
            context.removeSwitch(this);
        }
        render(){

            //console.log("RENDERING", this.state.props, this.state.visible);

            if(this.state.visible)
                return( 
                    <div ref = {(element) => {this.div = element;} }  style={context.printing?pdfstyle:null}> <WrappedComponent {... Object.assign({}, this.props, this.state.props) }/></div>  )
            else return null;
        }
    }
}





export function withPdfHandlers2({path, filter, pre}){

    return (WrappedComponent)=> class extends React.Component{
        constructor(props){
            super(props);
            this.div = null;
            this.state = {
                visible: false,
                props:{}
            }
            this.updat = null;

        }
        componentDidMount(){
                
            context.addSwitch(this);
 
            this.unsubscribe = context.state.subscribe((printing)=>{
                if(!printing)
                    this.setState({visible: false});
                //this.setState({visible: printing &&  filter==context.filter , props:context.props});
            }); 
        }
        waitForChange(){ 
            return new Promise((resolve, reject)=>{
                this.updat = resolve; 
            }); 
        }
        async renderVariants(){
            if(filter===context.filter){
                this.changed = true;
                this.setState({visible: true, props:context.props}); 
                if(this.changed)
                    await this.waitForChange();
                if(pre!=null){
                    if(pre(this.props)){
                        this.changed = true;
                        await this.waitForChange();
                    }
                }
               // this.setState({visible: false});
            }
        }
        componentDidUpdate(){
            this.changed = false;
           // console.log("component2 did update", this.props, UPC++);
 
            if(this.updat && this.props.pre_done){
                this.updat();
                this.updat = null; 
            }
        }
        componentWillUnmount(){
            this.unsubscribe();
            context.removeSwitch(this);
        }
        render(){

           // console.log("RENDERING2", this.state.props, this.state.visible);

            var wc = <WrappedComponent {... Object.assign({}, this.props, this.state.props) }/>;
            if(this.state.visible)

                return( 
                    (context.printing)?<div style={pdfstyle} ref={(element) => {this.div = element;} }>{wc}</div>:wc

                     )
            else return null;
        }
    }
}