/* @flow */

import React from 'react';
import { Paper } from '../components';
import {
  SchoolDashboardNavContainer, SchoolDashboardHeadingContainer, HeaderContainer,
  CoursesSectionContainer, PageTitleContainer
} from '../containers';
import { StudentPdfSelection, StudentCommentsPdfSelection, StudentCaligrafixPdfSelection } from '../pdfview/StudentView';
import { CourseSelection } from '../pdfview/CourseView';

import { HierarchyReportPdf } from '../pdfview/Hierarchy';
import LicenseFilter from '../containers/LicenseFilter';
import topScroll from '../hocs/topScroll';

/**
 * screen for the school courses
 *
 * @method SchoolProgress
 *
 */  
const SchoolCourses = () => { 
  topScroll();
  
  return (
    <div id="school-dashboard" className="school-dashboard layout-base">
      <PageTitleContainer pageTitle="title_school_courses" />
      <HeaderContainer />
      <SchoolDashboardHeadingContainer />
      <Paper.Container lead>
        <SchoolDashboardNavContainer currentTab="courses" pdfTarget="pleiq_report_courses" pdfFilter="school_tree" />
        <CoursesSectionContainer /> 
      </Paper.Container>


      <LicenseFilter filter={["pleiq_cx", "pleiq_cx_plus"]}>  
        <StudentCaligrafixPdfSelection path="student"/>
      </LicenseFilter>
      <LicenseFilter filter={["pleiq_school", "pleiq_cx_plus"]}>  
        <StudentPdfSelection path="student_int"/>
      </LicenseFilter>
      <StudentCommentsPdfSelection path="comments"/>
      <CourseSelection/>  
      
      <HierarchyReportPdf/>
    </div>
  );
};

export default SchoolCourses;
