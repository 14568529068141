/* @flow */

import React from 'react';
import ColorSelect from './ColorSelect';
import type { ReduxFormInput } from './types';

type Props = {
  input: ReduxFormInput
};

/**
 * this component is supposed to be used with the Field component
 * from redux-form, passing this as the component prop.
 * This component will render a series of colors to select them
 *
 * @example
 * <Field
 *   name="some_input_name"
 *   component={InputFieldColorSelect}
 * />
 */
const InputFieldColorSelect = ({ input }: Props) => {
  return (
    <ColorSelect
      selected={input.value}
      onClick={({ color }) => input.onChange(color)}
    />
  );
};

export default InputFieldColorSelect;
