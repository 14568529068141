/* @flow */

import React from 'react';
import InputTextWithColor from './InputTextWithColor';
import InputFieldSelect from './InputFieldSelect';
import { FormGroup } from 'reactstrap';
import { Field } from 'redux-form';
import { required } from './validations';
import type { Child, ReduxFormFlag, TranslationsObject } from './types';
import { generateChildAgeOptions } from './UserChildForm';
import Translations from '../hocs/TranslationsHelper';

type Props = {
  child: Child,
  onColorSelect: () => mixed,
  onUpdateChild: () => mixed,
  activeIndex: number,
  submitting: ReduxFormFlag,
  translations: TranslationsObject
};

const ChildForm = ({ child, onUpdateChild, activeIndex, submitting, translations }: Props) => {
  const selectedColor = child.color;
  const disabled = submitting;
  const selectOptions = generateChildAgeOptions(translations);

  return (
    <div className="child-form">
      <FormGroup>
        <InputTextWithColor
          selectedColor={selectedColor}
          onUpdateChild={onUpdateChild}
          child={child}
          submitting={submitting}
        />
      </FormGroup>
      <Field
        className="text-center"
        component={InputFieldSelect}
        disabled={disabled}
        // $FlowFixMe
        name={`age-${child.reference}`}
        onChange={(event, newValue) => onUpdateChild({ age: newValue })}
        placeholder={translations.t('age')}
        selectOptions={selectOptions}
        validate={[ required ]}
      />
    </div>
  );
};

export default Translations(ChildForm);
