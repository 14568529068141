/* @flow */

import React from 'react';
import { connect } from 'react-redux';
import { compose, pure, withHandlers } from 'recompose';
import { startSubmit } from 'redux-form';
import { UserInfoForm } from '../components/';
import { FORM_NAME } from '../components/UserInfoForm';
import { requestParentUpdate } from '../../actions/parent';
import { requestUserResetPasswordEmail } from '../../actions/user';
import type { RecomposeHandler, ReduxFunction } from '../components/types';
import { Paper } from '../components/';
import Translations from '../hocs/TranslationsHelper'

type Props = {
  data: Object,
  onSubmit: RecomposeHandler,
  dispatch: ReduxFunction
};

/**
 * @method UserInfoFormContainer
 * @param  {Object}  data
 */
const UserInfoFormContainer = ({ data, onSubmit, onClickButton, translations }: Props) => {
  const labelTitle = data.account_type === 'Parent' ? 'parent_settings_details' : 'school_settings_details';

  return (
    <div>
      <Paper.Nav>
        <Paper.NavItem iconClass="pleiq-icon-icon_settings_user" label={ translations.t(labelTitle) } />
      </Paper.Nav>
      
      <UserInfoForm initialValues={data} onSubmit={onSubmit} onClickButton={onClickButton} />
    </div>
  );
};

/*
 *  Gets the organizable payload depending of the account_type
 */
export const getAccountPayload = (values, account_type) => {
  if (account_type === 'School') {
    return {
      parent: {
        name: values.first_name,
        user_attributes: {
          preferences: {
            locale: values.preferences.locale,
            locale2: values.preferences.locale2,
          }
        }
      }
    };
  }

  return {
    parent: {
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email,
      user_attributes: {
        email: values.email,
        preferences: {
          receive_weekly_reports: values.preferences.receive_weekly_reports,
          locale: values.preferences.locale,
          locale2: values.preferences.locale2
        }
      }
    }
  }
};

export const UserInfoFormContainerHandlers = {
  onSubmit: ({ dispatch, data }: Props) => (values: Object) => {
    const payload = getAccountPayload(values, data.account_type);

    dispatch(startSubmit(FORM_NAME));
    dispatch(requestParentUpdate(payload));
  },
  onClickButton: ({ dispatch, data }: Props) => (event: Object) => {
    const { email } = data;
    dispatch(requestUserResetPasswordEmail({ email }));
  }
};

const UserPreferencesFormContainerComposed = compose(
  withHandlers(UserInfoFormContainerHandlers),
  Translations,
  pure
)(UserInfoFormContainer);

const mapStateToProps = ({ user, language }) => {
  const receive_weekly_reports = (user && user.preferences)
    ? user.preferences.receive_weekly_reports
    : false;
  const locale = (user && user.preferences)
    ? user.preferences.locale
    : language;

  const locale2 = (user && user.preferences)
    ? user.preferences.locale2
    : null;

  return {
    data: {
      email: user.email,
      first_name: user.first_name,
      last_name: user.last_name,
      pleiq_code: user.pleiq_code,
      account_type: user.account_type,
      preferences: { receive_weekly_reports, locale,locale2 }
    }
  };
};

const mapDispatchToProps = (dispatch) => ({ dispatch });

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserPreferencesFormContainerComposed);
