/* @flow */

import React from 'react';
import { Row, Col } from 'reactstrap';
import Paper from './Paper';

type Props = {
  title: string,
  subtitle: string,
  image?: Object,
  backButton?: boolean,
  onBackButtonClick?: Function,
  classnames?: string,
  children?: React.Component<*>
};

const BackButton = ({ onClick }) => {
  return (
    <div className="registration-form-back-icon" onClick={onClick}>
      <span className="pleiq-icon-icon_arrow_left" />
    </div>
  );
};

/**
 * A container component for the forms of the sign in / up section.
 *
 * @method RegistrationForm
 * @param  {string}               title             - title for the form
 * @param  {string}               subtitle          - subtitle for the form
 * @param  {boolean}              backButton        - to show or hide the backbutton
 * @param  {function}             onBackButtonClick - callback to define what to do on click back
 * @param  {React.Components}     children          - a tree of elements within the component
 *
 */
const RegistrationForm = ({ title, subtitle, backButton, image, children, onBackButtonClick, classnames }: Props) => {
  return (
    <div className={`registration-form ${classnames}`}>
      <div className="registration-form-container">
        { backButton ? <BackButton onClick={onBackButtonClick} /> : null }
        <Paper.Container>
          <figure className='text-center'>
            {
              image && (
                <img
                  src={image.src}
                  alt={image.alt}
                  width={image.width}
                />
              )
            }
          </figure>
          <Paper.Title>
            <h1 className="registration-form-title text-center">
              {title}
            </h1>
          </Paper.Title>
          <h2 className="registration-form-subtitle text-center">
            {subtitle}
          </h2>
          <Row>
            <Col xs={{ size: 12 }} md={{ size: 10, offset: 1 }}>
              {children}
            </Col>
          </Row>
        </Paper.Container>
      </div>
    </div>
  );
};

export default RegistrationForm;
