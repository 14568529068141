
import React from 'react';
import { FormGroup, Label, Input, FormFeedback } from 'reactstrap';
import classNames from 'classnames';
import Translations from '../hocs/TranslationsHelper';
import type { ReduxFormMeta } from './types';
import { connect } from 'react-redux';
import {Dropdown,Button} from './';
import { getBookInformation } from '../caligrafix/bookInfo';

const SchoolLevelBooksField = connect((state)=>{
    var books = state.caligrafix_books;
    return {
        caligrafix_books: state.caligrafix_books,
        user_books: state.user.caligrafix_books

    };


})(  ({caligrafix_books, user_books,className, log, disabled, translations, ...otherProps }) => {
    const { input, label, name, placeholder, type, meta } = otherProps;
    const { dirty, error, submitting, submitFailed, active }:ReduxFormMeta = meta;
  
    const showErrors = !active && (submitFailed || dirty) && error;
    const formColor = classNames({
      danger: showErrors
    });

    var value = input.value || [];

    var books = caligrafix_books.filter((b)=>!value.includes(b.id) && 
        (user_books.includes(b.slug) || b.active_in_schools));
 
    return (
      <FormGroup color={formColor}>
        {label && <Label for={name}>{label}</Label>}
        <FormGroup>
            {/* <Label>{translations.t('add_book')}</Label> */}
            <Dropdown
            options={books.map(b=>({
                label: getBookInformation(b.slug).label,
                value: b.id
            }))}
            placeholder={translations.t('add_book')}
           
            onChange={(v)=>{
                input.onChange([...value, v.value ]); 
            }}
            disabled={submitting}  
            noResultsText={false}
            // this prevents react-select to try to do any option filtering,
            // as we already handle the search
            
            searchable
            />
        </FormGroup>

        <div style={{padding: "8px"}}>
            <div className="course-inputs">
            {value.map((id, index ) => (
                <div className="course-inputs-group" key={index}>
                    <div className="course-inputs-input"> 
                        <div  >
                            <Label style={{color: "rgba(0, 0, 0, 0.4)"}} for={name}>
                                { getBookInformation(  caligrafix_books.find((b)=>b.id==id).slug).label  }
                        </Label> 
                        
                        </div>
                    </div>
                    <Button type="ball" onClickButton={() => input.onChange(value.filter((i2)=>i2!=id))} disabled={submitting}>
                        <span className="pleiq-icon-icon_minus" />
                    </Button>
                </div>
            ))} 
            </div>
        </div> 
        {showErrors && <FormFeedback>{translations.t(error)}</FormFeedback>}  
      </FormGroup>
    );
  });
export default Translations(SchoolLevelBooksField);