/* @flow */

import { responseHasError } from '../diplomatico/reducers/helper';
import type { Action } from '../diplomatico/types';
import mergeWithReplace from '../helpers/mergeWithReplace';
import { enableInitializing } from 'initializable-reducer';


const initialState = {};

function lessonPlans(state: Object = initialState, { type, payload }: Action) {
  switch (type) {
/*     case 'REQUEST_ACHIEVEMENT_CARDS':

      const { intelligence_id, language } = payload.request; 
      const newAchievementCard = {
        [intelligence_id]: {
          [language]:  []
        }
      }; 
      return mergeWithReplace({}, [state, newAchievementCard])
     */
    case 'RESPONSE_RATE_LESSON_PLAN':{

      if (responseHasError(payload)) {
        return state;
      }
      const { id,kind } = payload._meta.payload.request; 
      var cur_state = Object.assign({},state[kind] || {page:0, data: []} );
 
      cur_state.data = cur_state.data.map((e)=>{
        if(e.id===id)return Object.assign({},e,payload.body);
        else return e;
      })
      
      return Object.assign({}, state, {[kind]: cur_state })
    }
    case 'RESPONSE_LESSON_PLANS':
      if (responseHasError(payload)) {
        return state;
      }
      const { kind, restart, page } = payload._meta.payload.request;
      var cur_state = Object.assign({},state[kind] || {page:0, data: [],end:false} );
      if(page == 0){
        cur_state.data = [];
        cur_state.end = false;
      }
      else if(page!= cur_state.page+1)return state;
      if( payload.body.data.length==0)return Object.assign({},state,{[kind]:Object.assign({},cur_state,{end:true})});
      
      cur_state.data = [...cur_state.data, ...payload.body.data ];
      cur_state.page = page;
        
      return Object.assign({}, state, {[kind]: cur_state })
    default:
      return state;
  }
}

export {
  lessonPlans,
  initialState
};

export default enableInitializing(lessonPlans);
