/* @flow */

import type { Action } from '../diplomatico/types';

const initialState = {};

function loadingStatus(state: string = initialState, action: Action) {
  let { type, payload } = action;
  if(type.startsWith("REQUEST_")){ 
    var  id = type.substr(8);
    return Object.assign({}, state, {[id ]: (state[id] || 0) +1});    
  }else if(type.startsWith("RESPONSE_")){
    var  id =  type.substr(9);

    var newState = Object.assign({}, state);
    if(id in newState){ 
      newState[id]-=1;
      if(newState[id]<=0)
        delete newState[id];
    }
    return newState;
  }else if (type=="CLEAR_REQUESTS_"){
    return {}
  } else return state;
}

export {
  loadingStatus,
  initialState
};

export default loadingStatus;
