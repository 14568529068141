/**
 * @flow
 *
 * In order to keep diplomatico as an isolated
 * module from the application this action creators
 * allow to the app to listen for events that will typically
 * need a special handling. All the information actions has
 * a code to identify the event and _meta with description of the action
 * that cause the error
 *
 * codes:
 *
 * 001                 - make a request without internet connection
 * 002                 - request to an Unauthorized route
 * 003                 - requests to an endpoint that requires auth, but the user isn't logged in yet
 */

export const DIPLOMATICO_INFO = '@@diplomatico/INFO';

export function diplomaticoInfo({ code, info }: Object) {
  return {
    type: DIPLOMATICO_INFO,
    payload: {
      code,
      info
    }
  };
}
