
import React from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { compose, lifecycle, shouldUpdate,withState } from 'recompose';
import { connect } from 'react-redux';
import actions from '../../../actions'; 
import { print } from 'globalthis/implementation';
import { Collapser } from '../../components/FillHeightDown';
import TranslationsHelper from '../../hocs/TranslationsHelper';
import iconDelete from "../../../img/ui_icon_trash.svg";

const FilterItem = compose(
  withState('open', 'setOpen', false), 
) (({filter_str, filter, onFilterToggle, open,setOpen}) => { 
  var fvalues = filter.values; 
  var hasValues = filter.values.find(({id})=> filter_str.includes(id));
  return <Dropdown isOpen={open} toggle={()=>setOpen(!open)} className="ab-dropdown">
 
    <DropdownToggle caret className={`ab-filterbar-item-toggle${(hasValues)?" selected":""}`}>
      {
        filter.name
      }
    </DropdownToggle>
    <DropdownMenu className="ab-filterbar-item-menu">
      {
        fvalues.map(({id,name})=>(
          
          <div className="ab-filterbar-item-item" onClick={()=>{  
            onFilterToggle(id);
          }}>

            <label> {name}</label>  
            <input
                type="checkbox"
                checked={filter_str.includes(id)}  
            />      

          </div>
        ))
      } 
    </DropdownMenu>
  </Dropdown>
});

const FilterBarContainer = ({filter, lesson_plans,filters, onFilterChange,translations})=>{ 
  if(!lesson_plans || !filters || !Array.isArray(filters))
    return null;
  //const {values} = lesson_plans ;

  return <Collapser className='ab-filterbar-container' maxWidth={700} title={translations.t("ab_filters")} barClassName="ab-filterbar-bar">
  <div className='ab-filterbar'>
        {
            
            filters.map((f)=> {
            // var hasValues = f.values.find(({id})=> filter.includes(id));
              var fvalues = f.values;// (hasValues)?f.values: f.values.filter(({id})=> values.includes(id));
              if(fvalues.length==0)return null;

              return <FilterItem filter ={f} filter_str={filter} onFilterToggle={(vid)=>{

                let new_filter = "";
                for(let f2 of filters){
                    var value_filter = "";
                    for(let v2 of f2.values){
                        if((v2.id == vid) != (filter.includes(v2.id )) ){ 
                            if(value_filter!=""){
                                value_filter+='_';
                            }
                            value_filter+= v2.id;
                        }
                    }
                    if(value_filter!=""){ 
                        if(new_filter!=""){
                            new_filter+='|';
                        }
                        new_filter+= value_filter; 
                    }
                }
                onFilterChange(new_filter);
              }}/>;
              
              /*<div key={f.id}>
                <b>{f.name}</b>
                <ul>
                    {
                        fvalues.map(({id,name})=><li key={id}> 
                          <input
                              type="checkbox"
                              checked={filter.includes(id)} 
                              onChange={()=>{ 
                                  let new_filter = "";
                                  for(let f2 of filters){
                                      var value_filter = "";
                                      for(let v2 of f2.values){
                                          if((v2.id == id) != (filter.includes(v2.id )) ){ 
                                              if(value_filter!=""){
                                                  value_filter+='_';
                                              }
                                              value_filter+= v2.id;
                                          }
                                      }
                                      if(value_filter!=""){ 
                                          if(new_filter!=""){
                                              new_filter+='|';
                                          }
                                          new_filter+= value_filter; 
                                      }
                                  }
                                  onFilterChange(new_filter);
                              }}
                          />      
                          <label> {name}</label>  
                      </li>)
                    }
                </ul>
            
                  </div>*/
              } )
        }
        {
          (filter!="")?<button className="btn btn-secondary ab-filterbar-item-toggle button-clear" onClick={()=>onFilterChange("")}>
            
                   <img src={iconDelete}/>
                    <span>{translations.t("ab_clear_filter")} </span>
            
            
            </button>:null
        } 
    </div>

  </Collapser> 
}

export const ContainerLifecycle = {

    componentDidMount() {

      const {filter,dispatch} = this.props; 

      dispatch( actions.getLessonPlanFilters());
    }
  };
  
  const ContainerComposed = compose(
    lifecycle(ContainerLifecycle) 
  )(FilterBarContainer);


const FilterBarContainerConnected  = connect((state, {kind})=>{ 
  return {
    lesson_plans: state.lesson_plans[kind],
    filters: state.lesson_plan_filters
  }    
}, (dispatch)=>({dispatch}))(ContainerComposed);

export default TranslationsHelper( FilterBarContainerConnected);