/* @flow */

import React from 'react';
import '../../css/main.css';
import Translations from '../hocs/TranslationsHelper';

/**
 * Render 404 Not found screen
 * @method Dashboard
 * @param  {object}                    translations - injected by our Translations HoC to give translated strings
 */
const Dashboard = ({ translations }) => {
  return (
    <div id="404" className="layout-base">
      <h1>Ups! You don't suppose to be here.</h1>
    </div>
  );
};

export default Translations(Dashboard);
