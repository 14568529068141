/* @flow */

import React from 'react';
import { Input } from 'reactstrap';
import iconRadio from '../../img/icon_point.svg';

/**
 * A custom representation to fit design needs of a radio, this element will typically
 * live within a <FormGroup> reactstrap component along with its <Label>
 * check @link{http://reactstrap.github.io/components/form/}
 *
 * @method InputRadio
 *
 * @example
 * <FormGroup check>
 *   <Label check>
 *     <InputRadio />{' '}
 *     Whatever message you want
 *   </Label>
 * </FormGroup>
 */
const InputRadio = (props: Object) => {
  return (
    <div className="form-custom-radio">
      <Input type="radio" {...props}/>
      <div className="radiobutton mr-2">
        <img className="radiobutton-icon" src={iconRadio} alt="radio" />
      </div>
    </div>
  );
};

export default InputRadio;
