/* @flow */

import React from 'react';
import LaddaButton, { XL, ZOOM_IN } from 'react-ladda';
import classNames from 'classnames';

type Props = {
  loading: boolean,
  disabled: boolean,
  onClick?: () => mixed,
  className: mixed,
  children: React.Component<*>,
  dataColor?: string,
  dataSpinnerColor?: string
};

/**
 * this component displays a button to be used on forms,
 * when the loading prop is passed as true it shows an
 * spinner and gets disabled.
 *
 * @param  {boolean}    loading              - indicates if the button should be loading (and disabled) or not
 * @param  {boolean}    onClick              - callback that gets fired when the button gets clicked
 * @param  {string}     className            - class to be applied to the button
 * @param  {Component}  children             - a tree of elements within the component
 * @param  {String}     dataColor            - color for the button text
 * @param  {String}     dataSpinnerColor     - color for the spinner
 */
const SubmitButton = ({ loading,id, disabled, onClick, className, children, dataColor = '#eee', dataSpinnerColor = '#eee', type="submit" }: Props) => {
  const buttonClassName = classNames(className);
  return (
    <LaddaButton
      id={id}
      loading={loading}
      disabled={disabled}
      onClick={onClick}
      className={buttonClassName}
      type={type}
      data-color={dataColor}
      data-size={XL}
      data-style={ZOOM_IN}
      data-spinner-size={30}
      data-spinner-color={dataSpinnerColor}
      data-spinner-lines={12}
    >
      {children}
    </LaddaButton>
  );
};

export default SubmitButton;
