/* @flow */

import React from 'react';
import { Col, Row } from 'reactstrap';
import { Paper, InfoTooltip } from '../components/';
import {
  DataDisplayParentContainer, IntelligencesTopicContainer, IntelligencesProgressContainer,
  ActivityFeedContainer, HeaderContainer, PageTitleContainer
} from '../containers/';
import '../../css/main.css';
import Translations from '../hocs/TranslationsHelper';
import topScroll from '../hocs/topScroll';

/**
 * Render Dashboard screen
 * @method Dashboard
 * @param  {object}                    translations - injected by our Translations HoC to give translated strings
 */
const Dashboard = ({ translations }) => {
  topScroll();
  return (
    <div id="dashboard" className="layout-base">
      <PageTitleContainer pageTitle="title_parent_dashboard" />
      <HeaderContainer />
      <IntelligencesTopicContainer />
      <Paper.Container lead>
        <Row>
          <Col xs="12" lg="8">
            <Paper.Title>
              <h1>{ translations.t('current_progress') }</h1>
            </Paper.Title>
          </Col>
          <Col xs="12" lg="4" className="hidden-md-down">
            <Paper.Title>
              <h1>{ translations.t('recent_activity') }</h1>
            </Paper.Title>
          </Col>
          <Col xs="12" lg="8">
            <Paper.Content>
              <IntelligencesProgressContainer />
            </Paper.Content>
          </Col>
          <Col xs="12" className="hidden-lg-up">
            <hr />
            <Paper.Title>
              <h1>{ translations.t('recent_activity') }</h1>
            </Paper.Title>
          </Col>
          <Col xs="12" lg="4">
            <Paper.Content>
              <ActivityFeedContainer />
            </Paper.Content>
          </Col>
          <Col lg="12" className="hidden-md-down">
            <Paper.Title>
              <h1 className="mt-5">
                { translations.t('intelligences_progress_analysis') } {' '}
                <InfoTooltip message="tooltips_intelligences_progress_analysis" />
              </h1>
            </Paper.Title>
          </Col>
          <Col lg="12" className="hidden-md-down">
            <Paper.Content>
              <DataDisplayParentContainer />
            </Paper.Content>
          </Col>
        </Row>
      </Paper.Container>
    </div>
  );
};

export default Translations(Dashboard);
