import React, { useRef } from 'react';
import { Button } from './';

export const TextFileButton = ({ label, onFileReaded, className,disabled }) => {
  const inputRef = useRef();

  return <Button onClickButton={() => {
    inputRef.current.click();

  }} type="secondary" className={className} disabled={disabled} block>
    <span className="btn-icon pleiq-icon-icon_plus" />
    {label}

    <input ref={inputRef} hidden type="file" name="inputfile" id="inputfile" accept=".csv" onChange={(e) => {

      let fr = new FileReader();
      fr.onload = function () {
        onFileReaded(fr.result);
      };

      fr.readAsText(e.target.files[0]);
    }}></input>
  </Button>;
};
