/* @flow */

import React from 'react';
import { Form } from 'reactstrap';
import { compose, pure } from 'recompose';
import { reduxForm, Field } from 'redux-form';
import { Button, InputField, SubmitButton } from './';
import Translations from '../hocs/TranslationsHelper';
import type { ReduxFormFunction, TranslationsObject, ReduxFormFlag } from './types';
import { required } from './validations';

type Props = {
  handleSubmit: ReduxFormFunction,
  onDeleteClick: () => mixed,
  persisted: boolean,
  submitting: ReduxFormFlag,
  valid: ReduxFormFlag,
  dirty: ReduxFormFlag,
  translations: TranslationsObject
};

/**
 * component to display a form to edit/create a course info
 *
 * @method CourseDataForm
 * @param  {function}  handleSubmit     - injected by `redux-form`, handles form submition
 * @param  {boolean}   submitting       - injected by `redux-form`, indicates if the form is submitting
 * @param  {boolean}   valid            - injected by `redux-form`, indicates if the form is valid
 * @param  {boolean}   dirty            - injected by `redux-form`, indicates if the form have been edited
 * @param  {Object}    translations     - object containing app translations
 */
const CourseDataForm = ({ handleSubmit, onDeleteClick, submitting, persisted, valid, dirty, translations }: Props) => {
  const deleteButton = (
    <Button type="primary--grapefruit" onClickButton={onDeleteClick}>
      {translations.t('delete')}
    </Button>
  );

  return (
    <Form className="form" onSubmit={handleSubmit}>
      <Field
        name="course.name"
        placeholder="Some Level"
        label={translations.t('course_name')}
        component={InputField}
        validate={[ required ]}
      />
      <div className="form-actions mb-4">
        <SubmitButton loading={submitting} className="btn btn-primary--green" disabled={!dirty || !valid}>
          {translations.t('save')}
        </SubmitButton>
        {persisted && deleteButton}
      </div>
    </Form>
  );
};

export const FORM_NAME = 'courseDataForm';

const CourseDataFormComposed = compose(
  pure
)(CourseDataForm);

export default reduxForm({
  form: FORM_NAME,
  enableReinitialize: true
})(Translations(CourseDataFormComposed));
