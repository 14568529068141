
import React from 'react';
import { Field, reduxForm, startSubmit, stopSubmit } from "redux-form";
import { Form } from "reactstrap";
import { required, minLength, email } from '../components/validations';
import LaddaButton, { XL, ZOOM_IN } from 'react-ladda'; 
import Translations from '../hocs/TranslationsHelper' 

import classNames from 'classnames';
import { compose,withState, pure } from 'recompose'; 
import { Button, InputFieldSelect, SubmitButton,InputField, UserChildForm, Paper } from '../components';
import { connect } from 'react-redux';
import actions from '../../actions';
import { invalidateRetriever } from '../hocs/DataRetriever';
import TranslationsHelper from '../hocs/TranslationsHelper';

const lower = value => value && value.toLowerCase()
let EmailForm = ({error,   handleSubmit, persisted, submitting, valid, dirty, translations})=>{
    

    const formClassName = classNames({ 'has-danger': error }, 'form', "student-data-form");
    return <Form  className={formClassName} onSubmit={handleSubmit}>
                <Field
                className="text-center lowercased"
                type="text"
                name="email"
                label={translations.t('email_placeholder')}
                placeholder={translations.t('email_placeholder')}
                disabled={false}
                component={InputField}
                validate={[ required, email ]}
                normalize={lower}
                />
                <Field
                type="text"
                name="emailRetype"
                className="text-center lowercased"
                label={translations.t('email_placeholder_retype_user')}
                placeholder={translations.t('email_placeholder_retype_user')}
                component={InputField}
                validate={[ required, email]}
                disabled={false}
                normalize={lower}
                />
                <div className="form-actions" style={{marginTop:"32px"}}>
                <SubmitButton loading={submitting} className="btn btn-primary--green" disabled={!dirty || !valid}>
                {translations.t('next')}
                </SubmitButton>
                </div>
            </Form>
}
const EMAIL_FORM_NAME = 'studentRepresentantMail';
 

EmailForm =  reduxForm({
  form: EMAIL_FORM_NAME,
  enableReinitialize: true
})(Translations(EmailForm));



let RepresentativeForm = ({error,   handleSubmit, persisted, submitting, valid, dirty, translations})=>{
    

    const formClassName = classNames({ 'has-danger': error }, 'form', "student-data-form");
    return <Form  className={formClassName} onSubmit={handleSubmit}>
        <Field
          className="text-center lowercased"
          type="text"
          name="email"
          placeholder={translations.t('email_placeholder')}
          label={translations.t('email_placeholder')}
          disabled={true}
          component={InputField}
          validate={[ required, email ]}
          normalize={lower}
        />  
        <Field
          className="text-center"
          type="text"
          name="user_name"
          placeholder={translations.t('user_name')}
          label={translations.t('user_name')}
          disabled={false}
          component={InputField}
          validate={[ required ]}
        />
        <div className="form-actions" style={{marginTop:"32px"}}> 
        <SubmitButton loading={submitting} className="btn btn-primary--green"  >
          {translations.t((persisted)?'save':'add_representative')}
        </SubmitButton>
        </div>
    </Form>
}
export const REPRESENTATIVE_FORM_NAME = 'studentRepresentantData';
 

RepresentativeForm =  reduxForm({
  form: REPRESENTATIVE_FORM_NAME,
  enableReinitialize: true
})(Translations(RepresentativeForm));

const StudentRepresentantForm = ({translations,dispatch,id, closeModal, data, setData,student_id,  stage})=>{
    let form = null;
    if(data==null){
        form= <EmailForm onSubmit={(values)=>{
            dispatch(startSubmit(EMAIL_FORM_NAME));
            if(values.email!=values.emailRetype){
                
                dispatch(stopSubmit(EMAIL_FORM_NAME, {_error: "bad_email"}));
            }else{
                dispatch(actions.genericDataRequest('EMAIL_USER', '/school/users/check','POST', {
                    email: values.email
                },  ({status, body})=>{
                    if(status==422){
                        dispatch(stopSubmit(EMAIL_FORM_NAME, {_error:"email_taken"})); 
                    }else if(status < 400){ 
                        dispatch(stopSubmit(EMAIL_FORM_NAME )); 
                        setData(body)
                    }else if(status==404){ 
                        dispatch(stopSubmit(EMAIL_FORM_NAME )); 
                        setData({email: values.email});

                    }else{ 
                        dispatch(stopSubmit(EMAIL_FORM_NAME, {_error:"unknown_error"})); 
                    }
                    
                })) 

            }
        }}/>
    }else{
        form =  <RepresentativeForm initialValues={data} onSubmit={(values)=>{
            
            dispatch(actions.genericDataRequest('SRD', `/school/students/${student_id}/representatives`,'POST', {
                email: values.email,
                user_name: values.user_name
            },  ({status, body})=>{
                if(status < 400){ 
                    dispatch(stopSubmit(REPRESENTATIVE_FORM_NAME ));  
                    invalidateRetriever('STUDENT_REPRESENTANTS');
                    closeModal();
                } else{ 
                    dispatch(stopSubmit(REPRESENTATIVE_FORM_NAME, {_error:"unknown_error"})); 
                    
                }
                
            })) 
        }}/>;
    }


    return <div className="modal-edit-form">
        <h3>{translations.t("add_representative")}</h3>
              <Paper.Nav>
                <Paper.NavItem iconClass="pleiq-icon-icon_intelligence_intrapersonal" label={translations.t( "representative_data")} />
              </Paper.Nav> 
        {form}
    </div>;
}



export const StudentRepresentantFormContainer = 
TranslationsHelper(  connect(()=>{}, (dispatch)=>({dispatch}))(  
    compose( withState("data","setData", null) )
    (StudentRepresentantForm) 
));

const StudentRepresentantEditForm = ({translations,dispatch,  closeModal, data, setData,student_id,  stage})=>{
    let form =  <RepresentativeForm persisted initialValues={data} onSubmit={(values)=>{
            
        dispatch(actions.genericDataRequest('SRD', `/school/students/${student_id}/representatives/${data.user_id}`,'PUT', {
   
            user_name: values.user_name
        },  ({status, body})=>{
            if(status < 400){ 
                dispatch(stopSubmit(REPRESENTATIVE_FORM_NAME ));  
                invalidateRetriever('STUDENT_REPRESENTANTS');
                closeModal();
            } else{ 
                dispatch(stopSubmit(REPRESENTATIVE_FORM_NAME, {_error:"unknown_error"})); 
                
            }
            
        })) 
    }}/>; 


    return <div className="modal-edit-form">
        <h3>{translations.t("edit_representative")}</h3>

        <Paper.Nav>
                <Paper.NavItem iconClass="pleiq-icon-icon_intelligence_intrapersonal" label={translations.t( "representative_data")} />
              </Paper.Nav> 
        {form}
    </div>;
}

export const StudentRepresentantEditFormContainer = 
TranslationsHelper(  connect(()=>{}, (dispatch)=>({dispatch}))(   
    (StudentRepresentantEditForm) 
));