import { times } from 'lodash';


const ec_1_1 = [
  ["oa_1_1_1", "OAT 1 IA - Comunicar a los demás, emociones y sentimientos tales como: amor, miedo, alegría, ira, que le provocan diversas narraciones o situaciones observadas en forma directa o a través de TICs."],
  ["oa_1_1_2", "OAT 2 IA - Manifestar disposición y confianza para relacionarse con algunos adultos y pares que no son parte del grupo o curso."],
  ["oa_1_1_3", "OAT 3 IA - Reconocer emociones y sentimientos en otras personas, observadas en forma directa o a través de TICs."],
  ["oa_1_1_4", "OAT 4 IA - Expresar sus emociones y sentimientos autorregulándose en función de las necesidades propias, de los demás y las normas de funcionamiento grupal."],
  ["oa_1_1_5", "OAT 5 IA - Comunicar sus preferencias, opiniones, ideas, en diversas situaciones cotidianas y juegos."],
  ["oa_1_1_6",  "OAT 6 IA - Planificar proyectos y juegos, en función de sus ideas e intereses, proponiendo actividades, organizando los recursos, incorporando los ajustes necesarios e iniciándose en la apreciación de sus resultados."],
  ["oa_1_1_7",  "OAT 7 IA - Comunicar rasgos de su identidad de género, roles (nieta/o, vecino/a, entre otros), sentido de pertenencia y cualidades personales."],
  ["oa_1_1_8",  "OAT 8 IA - Comunicar sus características identitarias, fortalezas, habilidades y desafíos personales."],
  ["oa_1_1_9",  "OAT 9 IA - Cuidar su bienestar personal, llevando a cabo sus prácticas de higiene, alimentación y vestuario, con independencia y progresiva responsabilidad."],
  ["oa_1_1_10", "OAT 10 IA - Comunicar a otras personas desafíos alcanzados, identificando acciones que aportaron a su logro y definiendo nuevas metas."],
  ["oa_1_1_11", "OAT 11 IA - Distinguir parámetros establecidos para la regulación de alimentos, tales como: etiquetado de sellos, fechas de vencimiento, entre otros."],
  ["oa_1_1_12", "OAT 12 IA - Anticipar acciones y prever algunas situaciones o desafíos que se pueden presentar, en juegos, proyectos, sucesos que experimenta o que observa a través de TICs."],
  ["oa_1_1_13", "OAT 13 IA - Representar en juegos sociodramáticos, sus pensamientos y experiencias atribuyendo significados a objetos, personas y situaciones."],
];
const ec_1_2=[
  ["oa_1_2_1",  "OAT 1 CC - Participar en actividades y juegos colaborativos, planificando, acordando estrategias para un propósito común y asumiendo progresivamente responsabilidades en ellos."],
  ["oa_1_2_2",  "OAT 2 CC - Participar en actividades solidarias, que integran a las familias, la comunidad educativa y local."],
  ["oa_1_2_3",  "OAT 3 CC - Manifestar empatía y solidaridad frente a situaciones que vivencian sus pares, o que observa en textos o TICs, practicando acciones de escucha, apoyo y colaboración."],
  ["oa_1_2_4",  "OAT 4 CC - Apreciar el significado que tienen para las personas y las comunidades, diversas manifestaciones culturales que se desarrollan en su entorno."],
  ["oa_1_2_5",  "OAT 5 CC - Aplicar estrategias pacíficas frente a la resolución de conflictos cotidianos con otros niños y niñas."],
  ["oa_1_2_6",  "OAT 6 CC - Respetar normas y acuerdos creados colaborativamente con pares y adultos, para el bienestar del grupo."],
  ["oa_1_2_7",  "OAT 7 CC - Identificar objetos, comportamientos y situaciones de riesgo que pueden atentar contra su bienestar y seguridad, o la de los demás, proponiendo alternativas para enfrentarlas."],
  ["oa_1_2_8",  "OAT 8 CC - Comprender que algunas de sus acciones y decisiones respecto al desarrollo de juegos y proyectos colectivos, influyen en las de sus pares."],
  ["oa_1_2_9",  "OAT 9 CC - Reconocer, y progresivamente hacer respetar el derecho a expresarse libremente, a ser escuchado y a que su opinión sea tomada en cuenta."],
  ["oa_1_2_10", "OAT 10 CC - Reconocer progresivamente requerimientos esenciales de las prácticas de convivencia democrática, tales como: escucha de opiniones divergentes, el respeto por los demás, de los turnos, de los acuerdos de las mayorías."],
  ["oa_1_2_11", "OAT 11 CC - Apreciar la diversidad de las personas y sus formas de vida, tales como: singularidades fisonómicas, lingüísticas, religiosas, de género, entre otras."], 
];
const ec_1_3=[
  ["oa_1_3_1",  "OAT 1 CM - Manifestar iniciativa para resguardar el autocuidado de su cuerpo y su confortabilidad, en función de su propio bienestar."],
  ["oa_1_3_2",  "OAT 2 CM - Apreciar sus características corporales, manifestando interés y cuidado por su bienestar y apariencia personal."],
  ["oa_1_3_3",  "OAT 3 CM - Tomar conciencia de su cuerpo, de algunas de sus características internas (tales como: ritmo cardíaco, de respiración), de su esquema y progresivamente de su tono corporal y lateralidad, por medio de juegos."],
  ["oa_1_3_4",  "OAT 4 CM - Comunicar nuevas posibilidades de acción logradas a través de su cuerpo en situaciones cotidianas y de juego, empleando vocabulario preciso."],
  ["oa_1_3_5",  "OAT 5 CM - Comunicar el bienestar que le produce el movimiento, al ejercitar y recrear su cuerpo en forma habitual, con y sin implementos u obstáculos."],
  ["oa_1_3_6",  "OAT 6 CM - Coordinar con precisión y eficiencia sus habilidades psicomotrices finas en función de sus intereses de exploración y juego."],
  ["oa_1_3_7",  "OAT 7 CM - Resolver desafíos prácticos manteniendo control, equilibrio y coordinación al combinar diversos movimientos, posturas y desplazamientos tales como: lanzar y recibir, desplazarse en planos inclinados, seguir ritmos, en una variedad de juegos."],
  ["oa_1_3_8",  "OAT 8 CM - Coordinar sus habilidades psicomotoras practicando posturas y movimientos de fuerza, resistencia y tracción tales como: tirar la cuerda, transportar objetos, utilizar implementos, en situaciones cotidianas y de juego."],
  ["oa_1_3_9",  "OAT 9 CM - Utilizar categorías de ubicación espacial y temporal, tales como: adelante/atrás/al lado/entre, día/noche, hoy/mañana, antes/durante/después, en situaciones cotidianas y lúdicas."], 
];
const ec_2_1=[
  ["oa_2_1_1",  "OA 1 - Expresarse oralmente en forma clara y comprensible, empleando estructuras oracionales completas, conjugaciones verbales adecuadas y precisas con los tiempos, personas e intenciones comunicativas."],
  ["oa_2_1_2",  "OA 2 - Comprender textos orales como preguntas, explicaciones, relatos, instrucciones y algunos conceptos abstractos en distintas situaciones comunicativas, identificando la intencionalidad comunicativa de diversos interlocutores."],
  ["oa_2_1_3",  "OA 3 - Descubrir en contextos lúdicos, atributos fonológicos de palabras conocidas, tales como conteo de palabras, segmentación y conteo de sílabas, identificación de sonidos finales e iniciales."],
  ["oa_2_1_4",  "OA 4 - Comunicar oralmente temas de su interés, empleando un vocabulario variado e incorporando palabras nuevas y pertinentes a las distintas situaciones comunicativas e interlocutores."],
  ["oa_2_1_5",  "OA 5 - Manifestar interés por descubrir el contenido y algunos propósitos de diferentes textos escritos (manipulando, explorando, realizando descripciones y conjeturas) a través del contacto cotidiano con algunos de ellos, o del uso de TICs."],
  ["oa_2_1_6",  "OA 6 - Comprender contenidos explícitos de textos literarios y no literarios, a partir de la escucha atenta, describiendo información y realizando progresivamente inferencias y predicciones."],
  ["oa_2_1_7",  "OA 7 - Reconocer palabras que se encuentran en diversos soportes asociando algunos fonemas a sus correspondientes grafemas."],
  ["oa_2_1_8",  "OA 8 - Representar gráficamente algunos trazos, letras, signos, palabras significativas y mensajes simples legibles, utilizando diferentes recursos y soportes en situaciones auténticas."],
  ["oa_2_1_9",  "OA 9 - Comunicar mensajes simples en la lengua indígena pertinente a la comunidad donde habita."], 
  ["oa_2_1_10", "OA 10 - Reconocer algunas palabras o mensajes sencillos de lenguas maternas de sus pares, distintas al castellano."], 
];
const ec_2_2=[
  ["oa_2_2_1",  "OA 1 - Apreciar producciones artísticas de diversos contextos (en forma directa o a través de medios tecnológicos), describiendo y comparando algunas características visuales, musicales o escénicas (desplazamiento, ritmo, carácter expresivo, colorido, formas, diseño, entre otros)."],
  ["oa_2_2_2",  "OA 2 - Comunicar sus impresiones, emociones e ideas respecto de diversas obras de arte, producciones propias y de sus pares (artesanías, piezas musicales, obras plásticas y escénicas, entre otras)."],
  ["oa_2_2_3",  "OA 3 - Interpretar canciones y juegos musicales, utilizando de manera integrada diversos recursos tales como, la voz, el cuerpo, instrumentos musicales y objetos."],
  ["oa_2_2_4",  "OA 4 - Expresar corporalmente sensaciones, emociones e ideas a partir de la improvisación de escenas dramáticas, juegos teatrales, mímica y danza."],
  ["oa_2_2_5",  "OA 5 - Representar plásticamente emociones, ideas, experiencias e intereses, a través de líneas, formas, colores, texturas, con recursos y soportes en plano y volumen."],
  ["oa_2_2_6",  "OA 6 - Experimentar diversas combinaciones de expresión plástica, corporal y musical, comunicando las razones del proceso realizado."],
  ["oa_2_2_7",  "OA 7 - Representar a través del dibujo, sus ideas, intereses y experiencias, incorporando detalles a las figuras humanas y a objetos de su entorno, ubicándolos en parámetros básicos de organización espacial (arriba/abajo, dentro/fuera)."],  
];
const ec_3_1=[
  ["oa_3_1_1",  "OA 1 - Manifestar interés y asombro al ampliar información sobre cambios que ocurren en el entorno natural, a las personas, animales, plantas, lugares y cuerpos celestes, utilizando diversas fuentes y procedimientos."],
  ["oa_3_1_2",  "OA 2 - Formular conjeturas y predicciones acerca de las causas o consecuencias de fenómenos naturales que observa, a partir de sus conocimientos y experiencias previas."],
  ["oa_3_1_3",  "OA 3 - Reconocer la importancia del agua y la energía solar para la vida humana, los animales y las plantas, a partir de experiencias directas o TICs."],
  ["oa_3_1_4",  "OA 4 - Comunicar propiedades básicas de los objetos y elementos naturales que explora, tales como: transparencia/opacidad, flexibilidad/rigidez, rugosidad/lisura, relacionándolos con posibles usos."],
  ["oa_3_1_5",  "OA 5 - Explorar los cambios o efectos que se producen en los materiales al aplicarles fuerza, calor o agua."],
  ["oa_3_1_6",  "OA 6 - Establecer relaciones de semejanzas y diferencias de animales y plantas, a partir de algunas características (tamaño, color, textura y morfología), sus necesidades básicas (formas de alimentación y abrigo), y los lugares que habitan, al observarlos en forma directa, en libros ilustrados o en TICs."],
  ["oa_3_1_7",  "OA 7 - Describir semejanzas y diferencias respecto a características, necesidades básicas y cambios que ocurren en el proceso de crecimiento, en personas, animales y plantas."],
  ["oa_3_1_8",  "OA 8 - Practicar algunas acciones cotidianas, que contribuyen al cuidado de ambientes sostenibles, tales como manejo de desechos en paseos al aire libre, separación de residuos, utilizar envases o papeles, plantar flores o árboles."],
  ["oa_3_1_9",  "OA 9 - Comunicar sus observaciones, los instrumentos utilizados y los hallazgos obtenidos en experiencias de indagación en el entorno natural, mediante relatos, representaciones gráficas o fotografías."], 
  ["oa_3_1_10", "OA 10 - Formular conjeturas a partir de los cambios observados en mezclas y disoluciones, estableciendo relaciones de posible causalidad y comunicándolas a través de diferentes medios."], 
  ["oa_3_1_11", "OA 11 - Identificar las condiciones que caracterizan los ambientes saludables, tales como: aire y agua limpia, combustión natural, reciclaje, reutilización y reducción de basura, tomando conciencia progresiva de cómo estas contribuyen a su salud."],
  ["oa_3_1_12", "OA 12 - Comprender que la acción humana puede aportar al desarrollo de ambientes sostenibles y también al deterioro de estos."],
];
const ec_3_2=[
  ["oa_3_2_1",  "OA 1 - Comprender los roles que desarrollan miembros de su familia y de su comunidad, y su aporte para el bienestar común."],
  ["oa_3_2_2",  "OA 2 - Apreciar diversas formas de vida de comunidades, del país y del mundo, en el pasado y en el presente, tales como: viviendas, paisajes, alimentación, costumbres, identificando mediante diversas fuentes de documentación gráfica y audiovisual, sus características relevantes."],
  ["oa_3_2_3",  "OA 3 - Comparar características de diseño, funcionamiento, utilidad, precaución de uso e impacto en el entorno, de diferentes objetos tecnológicos."],
  ["oa_3_2_4",  "OA 4 - Formular interpretaciones respecto de las necesidades y situaciones que dieron origen a creaciones e inventos, tales como: refrigerador, radio, avión, naves espaciales, cámara fotográfica, entre otros."],
  ["oa_3_2_5",  "OA 5 - Comunicar algunos relatos sociales sobre hechos significativos del pasado de su comunidad y país, apoyándose en recursos tales como: fotografías, videos, utensilios u objetos representativos."],
  ["oa_3_2_6",  "OA 6 - Reconocer diversas acciones para el cuidado del patrimonio cultural material (construcciones, obras de carácter arqueológico, lugares) e inmaterial (tradiciones, celebraciones), de su comunidad local."],
  ["oa_3_2_7",  "OA 7 - Reconocer la importancia del servicio que prestan instituciones, organizaciones, lugares y obras de interés patrimonial, tales como: escuelas, transporte público, empresas, iglesias, museos, bibliotecas, entre otros."],
  ["oa_3_2_8",  "OA 8 - Conocer sobre la vida de algunas mujeres y hombres, que han realizado en el pasado y en el presente, aportes diversos en su comunidad, país, y el mundo, a través de relatos, o con apoyo de TICs."],
  ["oa_3_2_9",  "OA 9 - Ampliar sus estrategias de indagación utilizando diversas fuentes, instrumentos y tecnologías de la información y comunicación, que le permitan expandir su entorno."], 
  ["oa_3_2_10", "OA 10 - Comprender normas de protección y seguridad referidas a tránsito, incendios, inundaciones, sismos, y otras pertinentes a su contexto geográfico."], 
  ["oa_3_2_11", "OA 11 - Identificar lugares de su entorno a través de su representación geográfica, tales como: maquetas, fotografías aéreas, dibujos y planos."], 
];
const ec_3_3=[
  ["oa_3_3_1",  "OA 1 - Crear patrones sonoros, visuales, gestuales, corporales u otros, de dos o tres elementos."],
  ["oa_3_3_2",  "OA 2 - Experimentar con diversos objetos estableciendo relaciones al clasificar por dos o tres atributos a la vez (forma, color, tamaño, función, masa, materialidad, entre otros) y seriar por altura, ancho, longitud o capacidad para contener."],
  ["oa_3_3_3",  "OA 3 - Comunicar la posición de objetos y personas respecto de un punto u objeto de referencia, empleando conceptos de ubicación (dentro/fuera; encima/debajo/entre; al frente de/detrás de); distancia (cerca/lejos) y dirección (adelante/atrás/hacia el lado), en situaciones lúdicas."],
  ["oa_3_3_4",  "OA 4 - Emplear cuantificadores, tales como: “más que”, “menos que”, “igual que”, al comparar cantidades de objetos en situaciones cotidianas."],
  ["oa_3_3_5",  "OA 5 - Orientarse temporalmente en situaciones cotidianas, empleando nociones y relaciones de secuencia (antes/ahora/después/al mismo tiempo, día/noche), frecuencia (siempre/a veces/ nunca) y duración (larga/corta)."],
  ["oa_3_3_6",  "OA 6 - Emplear los números, para contar, identificar, cuantificar y comparar cantidades hasta el 20 e indicar orden o posición de algunos elementos en situaciones cotidianas o juegos."],
  ["oa_3_3_7",  "OA 7 - Representar números y cantidades hasta el 10, en forma concreta, pictórica y simbólica."],
  ["oa_3_3_8",  "OA 8 - Resolver problemas simples de manera concreta y pictórica agregando o quitando hasta 10 elementos, comunicando las acciones llevadas a cabo."],
  ["oa_3_3_9",  "OA 9 - Representar objetos desde arriba, del lado, abajo, a través de dibujos, fotografías o TICs, formulando conjeturas frente a sus descubrimientos."], 
  ["oa_3_3_10", "OA 10 - Identificar atributos de figuras 2D y 3D, tales como: forma, cantidad de lados, vértices, caras, que observa en forma directa o a través de TICs."], 
  ["oa_3_3_11", "OA 11 - Emplear medidas no estandarizadas, para determinar longitud de objetos, registrando datos, en diversas situaciones lúdicas o actividades cotidianas."],
  ["oa_3_3_12", "OA 12 - Comunicar el proceso desarrollado en la resolución de problemas concretos, identificando la pregunta, acciones y posibles respuestas."],
];



const cc_1_1= [
  ["oat2_1_1_1" , "OA 1 - Representar verbal y corporalmente diferentes emociones y sentimientos, en sus juegos."],
  ["oat2_1_1_2" , "OA 2 - Manifestar disposición y confianza al separarse de los adultos significativos."],
  ["oat2_1_1_3" , "OA 3 - Reconocer en sí mismo, en otras personas y en personajes de cuentos, emociones tales como: tristeza, miedo, alegría, pena y rabia."],
  ["oat2_1_1_4" , "OA 4 - Manifestar disposición para regular sus emociones y sentimientos, en función de las necesidades propias, de los demás y de algunos acuerdos para el funcionamiento grupal."],
  ["oat2_1_1_5" , "OA 5 - Manifestar sus preferencias cuando participa o cuando solicita participar, en diversas situaciones cotidianas y juegos."],
  ["oat2_1_1_6" , "OA 5 - Actuar con progresiva independencia, ampliando su repertorio de acciones, acorde a sus necesidades e intereses."],
  ["oat2_1_1_7" , "OA 7 - Comunicar algunos rasgos de su identidad, como su nombre, sus características corporales, género y otros."],
  ["oat2_1_1_8" , "OA 8 - Apreciar sus características identitarias, fortalezas y habilidades."],
  ["oat2_1_1_9" , "OA 9 - Manifestar progresiva independencia en sus prácticas de alimentación, vigilia y sueño, vestimenta, higiene corporal, bucal y evacuación. "],
  ["oat2_1_1_10", "OA 10 - Manifestar satisfacción y confianza por su autovalía, comunicando algunos desafíos alcanzados, tales como: saltar, hacer torres, sacar cáscara de huevos, entre otros."],
  ["oat2_1_1_11", "OA 11 - Identificar alimentos que se consumen en algunas celebraciones propias de su familia y comunidad."],
  ["oat2_1_1_12", "OA 12 - Representar sus pensamientos y experiencias, atribuyendo significados a objetos o elementos de su entorno, usando la imaginación en situaciones de juego."],
];
const cc_1_2= [
  ["oat2_1_2_1" , "OA 1 - Participar en actividades y juegos grupales con sus pares, conversando, intercambiando pertenencias, cooperando."],
  ["oat2_1_2_2" , "OA 2 - Disfrutar de instancias de interacción social con diversas personas de la comunidad."],
  ["oat2_1_2_3" , "OA 3 - Colaborar en situaciones cotidianas y de juego, proponiendo acciones simples frente a necesidades que presentan sus pares."],
  ["oat2_1_2_4" , "OA 4 - Colaborar en actividades, conmemoraciones o celebraciones culturales de su familia y comunidad."],
  ["oat2_1_2_5" , "OA 5 - Iniciarse en la resolución pacífica de conflictos, dialogando respecto de la situación, escuchando, opinando y proponiendo acciones para resolver."],
  ["oat2_1_2_6" , "OA 5 - Manifestar disposición para practicar acuerdos de convivencia básica que regulan situaciones cotidianas y juegos."],
  ["oat2_1_2_7" , "OA 7 - Identificar objetos, comportamientos y situaciones de riesgo que pueden atentar contra su seguridad, bienestar y el de los demás."],
  ["oat2_1_2_8" , "OA 8 - Reconocer acciones correctas e incorrectas para la convivencia armónica del grupo, que se presentan en diferentes situaciones cotidianas y juegos."],
  ["oat2_1_2_9" , "OA 9 - Manifestar interés por algunos de sus derechos, tales como: ser escuchados, tener un nombre, jugar, entre otros."],
  ["oat2_1_2_10", "OA 10 - Manifestar interés para interactuar con niños y niñas, reconociendo la diversidad de sus características y formas de vida (costumbres, fisonomía, lingüística, entre otras)."],
 
];

const cc_1_3= [
  ["oat2_1_3_1" , "OA 1 - Reconocer situaciones en que se siente cómodo corporalmente, manifestando al adulto su bienestar y su interés por mantener estas condiciones."],
  ["oat2_1_3_2" , "OA 2 - Reconocer las principales partes, características físicas de su cuerpo y sus funciones en situaciones cotidianas y de juego."],
  ["oat2_1_3_3" , "OA 3 - Experimentar diversas posibilidades de acción con su cuerpo, en situaciones cotidianas y de juego, identificando progresivamente el vocabulario asociado."],
  ["oat2_1_3_4" , "OA 4 - Reconocer el bienestar que le produce el movimiento libre en situaciones cotidianas y lúdicas, manifestando su interés por desarrollarlo en forma frecuente."],
  ["oat2_1_3_5" , "OA 5 - Perfeccionar su coordinación visomotriz fina, a través del uso de diversos objetos, juguetes y utensilios."],
  ["oat2_1_3_6" , "OA 5 - Adquirir control y equilibrio en movimientos, posturas y desplazamientos que realiza en diferentes direcciones y en variadas situaciones cotidianas y juegos, con y sin implementos."],
  ["oat2_1_3_7" , "OA 7 - Resolver desafíos prácticos en situaciones cotidianas y juegos, incorporando mayor precisión y coordinación en la realización de posturas, movimientos y desplazamientos, tales como: esquivar obstáculos o mantener equilibrio al subir escalas."],
  ["oat2_1_3_8" , "OA 8 - Utilizar categorías de ubicación espacial y temporal, tales como: adelante/atrás, arriba/abajo, adentro/afuera, antes/después, rápido/lento, en situaciones cotidianas y lúdicas."],
 
];


const cc_2_1= [
  ["oat2_2_1_1" , "OA 1 - Expresarse oralmente, empleando estructuras oracionales simples y respetando patrones gramaticales básicos, en distintas situaciones cotidianas y juegos."],
  ["oat2_2_1_2" , "OA 2 - Comprender mensajes simples como instrucciones explícitas, explicaciones y preguntas relativas a objetos, personas, acciones, tiempo y lugar, identificando la intencionalidad comunicativa de diversos interlocutores."],
  ["oat2_2_1_3" , "OA 3 - Identificar algunos atributos de los sonidos de diferentes fuentes sonoras como intensidad (fuerte/suave), velocidad (rápido/lento)."],
  ["oat2_2_1_4" , "OA 4 - Incorporar progresivamente nuevas palabras, al comunicar oralmente temas variados de su interés e información básica, en distintas situaciones cotidianas."],
  ["oat2_2_1_5" , "OA 5 - Manifestar interés por descubrir el contenido de textos de diferentes formatos, a través de la manipulación, la exploración, la escucha atenta y la formulación de preguntas."],
  ["oat2_2_1_6" , "OA 6 - Comprender a partir de la escucha atenta, contenidos explícitos de textos literarios y no literarios, reconociendo ideas centrales, señalando preferencias, realizando sencillas descripciones, preguntando sobre el contenido."],
  ["oat2_2_1_7" , "OA 7 - Reconocer progresivamente el significado de diversas imágenes, logos, símbolos de su entorno cotidiano, en diversos soportes (incluye uso de TICs)."],
  ["oat2_2_1_8" , "OA 8 - Producir sus propios signos gráficos en situaciones lúdicas."], 
];
const cc_2_2= [
  ["oat2_2_2_1" , "OA 1 - Manifestar interés por diversas producciones artísticas (arquitectura, modelado, piezas musicales, pintura, dibujos, títeres, obras de teatro, danzas, entre otras), describiendo algunas características."],
  ["oat2_2_2_2" , "OA 2 - Expresar sus preferencias, sensaciones y emociones relacionadas con diferentes recursos expresivos que se encuentran en sencillas obras visuales (colorido, formas), musicales (fuente, intensidad del sonido) o escénicas (desplazamiento, vestimenta, carácter expresivo)."],
  ["oat2_2_2_3" , "OA 3 - Interpretar canciones y juegos musicales, experimentando con diversos recursos tales como, la voz, el cuerpo, instrumentos musicales y objetos."],
  ["oat2_2_2_4" , "OA 4 - Expresar corporalmente sensaciones y emociones experimentando con mímica, juegos teatrales, rondas, bailes y danzas."],
  ["oat2_2_2_5" , "OA 5 - Expresar emociones, ideas y experiencias por medio de la plástica experimentando con recursos pictóricos, gráficos y de modelado."],
  ["oat2_2_2_6" , "OA 6 - Experimentar diversas posibilidades de expresión, combinando lenguajes artísticos en sus producciones."],
  ["oat2_2_2_7" , "OA 7 - Representar a través del dibujo, diversos elementos de su entorno, incorporando figuras cerradas, trazos intencionados y primeros esbozos de la figura humana."],
 
];

const cc_3_1= [
  ["oat2_3_1_1" , "OA 1 - Manifestar interés y asombro por diversos elementos, situaciones y fenómenos del entorno natural, explorando, observando, preguntando, describiendo, agrupando, entre otros."],
  ["oat2_3_1_2" , "OA 2 - Comunicar verbalmente características de elementos y paisajes de su entorno natural, tales como cuerpos celestes, cerros, desierto, flora; y de fenómenos como marejadas, sismos, tormentas, sequías."],
  ["oat2_3_1_3" , "OA 3 - Descubrir que el sol es fuente de luz y calor para el planeta, a través de experiencias directas o TICs."],
  ["oat2_3_1_4" , "OA 4 - Comunicar algunas propiedades básicas de los elementos naturales que explora, tales como: colores, texturas, tamaños, temperaturas entre otras."],
  ["oat2_3_1_5" , "OA 5 - Distinguir una variedad progresivamente más amplia de animales y plantas, respecto a sus características (tamaño, color, textura y morfología), sus necesidades básicas y los lugares que habitan, al observarlos en forma directa, en libros ilustrados o en TICs."],
  ["oat2_3_1_6" , "OA 6 - Colaborar en situaciones cotidianas, en acciones que contribuyen al desarrollo de ambientes sostenibles, tales como cerrar las llaves de agua, apagar aparatos eléctricos, entre otras."],
  ["oat2_3_1_7" , "OA 7 - Emplear instrumentos y herramientas de observación y recolección (lupas, frascos, recipientes, botellas, cucharas, embudos, pinzas, entre otros) en la exploración del entorno natural."],
  ["oat2_3_1_8" , "OA 8 - Experimentar mezclas y disoluciones con materiales cotidianos tales como: burbujas de jabón, agua salada, gelatina, describiendo los cambios observados."],
  ["oat2_3_1_9" , "OA 9 - Reconocer que el aire y el agua son elementos vitales para las personas, los animales y las plantas, y que estos elementos pueden encontrarse con o sin contaminación."],
 
];

const cc_3_2= [
  ["oat2_3_2_1" , "OA 1 - Describir actividades habituales de su comunidad, como ir de compras, jugar en la plaza, viajar en bus, entre otras, señalando su participación en ellas."],
  ["oat2_3_2_2" , "OA 2 - Describir características de las formas de vida de su comunidad (viviendas, paisajes, costumbres), a través de canciones, juegos, relatos y fotos familiares, entre otras."],
  ["oat2_3_2_3" , "OA 3 - Seleccionar utensilios domésticos y objetos tecnológicos que les permiten resolver problemas en contextos sociales auténticos."],
  ["oat2_3_2_4" , "OA 4 - Reconocer sucesos significativos de su historia personal y familiar, en diversas situaciones, tales como: conversaciones familiares, relatos de un agente comunitario, visitas a lugares, observación de fotografías, entre otros."],
  ["oat2_3_2_5" , "OA 5 - Identificar instituciones significativas de su entorno, describiendo actividades y rutinas representativas que en ellas se realizan."],
  ["oat2_3_2_6" , "OA 6 - Identificar algunas normas de protección y seguridad de su entorno cotidiano referidas a alimentación, tránsito y sismos, y otras pertinentes a su contexto geográfico."],
  ["oat2_3_2_7" , "OA 7 - Distinguir en paisajes de su localidad, elementos naturales (bosque, cerros, ríos), y culturales (caminos, edificios, puentes)."],
 
];
const cc_3_3= [
  ["oat2_3_3_1" , "OA 1 - Reproducir patrones sonoros, visuales, gestuales, corporales u otros, de dos o tres elementos."],
  ["oat2_3_3_2" , "OA 2 - Experimentar con diversos objetos, estableciendo relaciones al clasificar por dos atributos a la vez (forma, color, entre otros) y seriar por altura o longitud."],
  ["oat2_3_3_3" , "OA 3 - Describir la posición de objetos y personas, respecto de un punto u objeto de referencia, empleando conceptos de ubicación y distancia tales como: dentro/fuera; encima/debajo; cerca /lejos."],
  ["oat2_3_3_4" , "OA 4 - Orientarse temporalmente en situaciones cotidianas, mediante la utilización progresiva de algunas nociones y relaciones de secuencias, tales como: antes/después, día/noche, hoy/mañana."],
  ["oat2_3_3_5" , "OA 5 - Emplear cuantificadores, tales como: más/menos, mucho/poco, todo/ninguno, al comparar cantidades de objetos en situaciones cotidianas."],
  ["oat2_3_3_6" , "OA 6 - Emplear progresivamente los números, para contar, identificar, cuantificar y comparar cantidades, hasta el 10 e indicar orden o posición de algunos elementos en situaciones cotidianas o juegos."],
  ["oat2_3_3_7" , "OA 7 - Representar progresivamente, números y cantidades en forma concreta y pictórica hasta el 10."],
  ["oat2_3_3_8" , "OA 8 - Resolver progresivamente problemas simples, de manera concreta y pictórica, agregando o quitando hasta 5 elementos."],
  ["oat2_3_3_9" , "OA 9 - Descubrir atributos de figuras 3D, mediante la exploración de objetos presentes en su entorno."],
  ["oat2_3_3_10", "OA 10 - Identificar algunas acciones que se llevaron a cabo para resolver problemas."],
 
];


export const fieldDataCL=   [
    {
      "id": "education_level",
      "label": "Edad",
      "desc": "Elige una Edad",
      "fieldValues": [
        [
          "el_2",
          "2 años"
        ],
        [
          "el_3",
          "3 años"
        ],
        [
          "el_4",
          "4 años"
        ],
        [
          "el_5",
          "5 años"
        ],
        [
          "el_6",
          "6 años"
        ] 
      ]
    },

    {
        "id": "expertice_scope",
        "label": "Ambito de Experiencia",
        "desc": "Elige un Ambito",
        "fieldValues": [
          [
            "es_1",
            "Desarrollo Personal y Social"
          ],
          [
            "es_2",
            "Comunicación integral"
          ],
          [
            "es_3",
            "Interacción y comprensión del entorno"
          ] 
        ]
    },
    {
        "constraints": [
          "expertice_scope"
        ],
        "id": "education_core",
        "label": "Núcleo de Aprendizaje",
        "desc": "Elige un Núcleo",
        "fieldValues": {
          "es_1": [
            [
              "ec_1_1",
              "Identidad y autonomía"
            ],
            [
              "ec_1_2",
              "Convivencia y ciudadanía"
            ],
            [
              "ec_1_3",
              "Corporalidad y movimiento"
            ],
          ],
          "es_2": [
            [
              "ec_2_1",
              "Lenguaje verbal"
            ],
            [
              "ec_2_2",
              "Lenguajes artísticos"
            ] 
          ],
          "es_3": [
            [
              "ec_3_1",
              "Exploración del entorno natural"
            ],
            [
              "ec_3_2",
              "Comprensión del entorno sociocultural"
            ],
            [
              "ec_3_3",
              "Pensamiento matemático"
            ],
          ] 

        }
    },

    {
        "constraints": [
          "education_core",
          "education_level"
        ],
        "id": "education_target",
        "label": "Objetivo de Aprendizaje",
        "desc": "Elige un Objetivo",
        "fieldValues": {
          "ec_1_1-el_5": ec_1_1 ,
          "ec_1_2-el_5": ec_1_2,
          "ec_1_3-el_5": ec_1_3,
          "ec_2_1-el_5": ec_2_1,
          "ec_2_2-el_5": ec_2_2,
          "ec_3_1-el_5": ec_3_1,
          "ec_3_2-el_5": ec_3_2,
          "ec_3_3-el_5": ec_3_3,
          "ec_1_1-el_6": ec_1_1 ,
          "ec_1_2-el_6": ec_1_2,
          "ec_1_3-el_6": ec_1_3,
          "ec_2_1-el_6": ec_2_1,
          "ec_2_2-el_6": ec_2_2,
          "ec_3_1-el_6": ec_3_1,
          "ec_3_2-el_6": ec_3_2,
          "ec_3_3-el_6": ec_3_3,


          "ec_1_1-el_2": cc_1_1 ,
          "ec_1_2-el_2": cc_1_2,
          "ec_1_3-el_2": cc_1_3,
          "ec_2_1-el_2": cc_2_1,
          "ec_2_2-el_2": cc_2_2,
          "ec_3_1-el_2": cc_3_1,
          "ec_3_2-el_2": cc_3_2,
          "ec_3_3-el_2": cc_3_3,

          "ec_1_1-el_3": cc_1_1 ,
          "ec_1_2-el_3": cc_1_2,
          "ec_1_3-el_3": cc_1_3,
          "ec_2_1-el_3": cc_2_1,
          "ec_2_2-el_3": cc_2_2,
          "ec_3_1-el_3": cc_3_1,
          "ec_3_2-el_3": cc_3_2,
          "ec_3_3-el_3": cc_3_3,

          "ec_1_1-el_4": cc_1_1 ,
          "ec_1_2-el_4": cc_1_2,
          "ec_1_3-el_4": cc_1_3,
          "ec_2_1-el_4": cc_2_1,
          "ec_2_2-el_4": cc_2_2,
          "ec_3_1-el_4": cc_3_1,
          "ec_3_2-el_4": cc_3_2,
          "ec_3_3-el_4": cc_3_3,
        }
    },
    {
        "constraints": [
          "education_target"
        ],
        "id": "education_trans_target",
        "label": "Objetivo de Aprendizaje Transversal",
        "desc": "Elige un Objetivo",
        "multi": true,
        "fieldValues": {
          "oa_2_1_1": [ 
            ["oat_2_ia","OAT 2 IA - Manifestar disposición y confianza para relacionarse con algunos adultos y pares que no son parte del grupo o curso."],
            ["oat_4_ia","OAT 4 IA  - Expresar sus emociones y sentimientos autorregulándose en función de las necesidades propias, de los demás y las normas de funcionamiento grupal."],
            ["oat_5_ia","OAT 5 IA - Comunicar sus preferencias, opiniones, ideas, en diversas situaciones cotidianas y juegos."],
            ["oat_13_ia","OAT 13 IA - Representar en juegos sociodramáticos, sus pensamientos y experiencias atribuyendo significados a objetos, personas y situaciones."],
            ["oat_9_cc","OAT 9 CC - Reconocer, y progresivamente hacer respetar el derecho a expresarse libremente, a ser escuchado y a que su opinión sea tomada en cuenta."],
            ["oat_10_cc","OAT 10 CC - Reconocer progresivamente requerimientos esenciales de las prácticas de convivencia democrática, tales como: escucha de opiniones divergentes, el respeto por los demás, de los turnos, de los acuerdos de las mayorías."],
            ["oat_9_cm","OAT 9 CM - Utilizar categorías de ubicación espacial y temporal, tales como: adelante/atrás/al lado/entre, día/noche, hoy/mañana, antes/durante/después, en situaciones cotidianas y lúdicas."],
          ], 
          "oa_2_1_2": [ 
            ["oat_3_ia","OAT 3 IA - Reconocer emociones y sentimientos en otras personas, observadas en forma directa o a través de TICs."],
            ["oat_3_cc","OAT 3 CC - Manifestar empatía y solidaridad frente a situaciones que vivencian sus pares, o que observa en textos o TICs, practicando acciones de escucha, apoyo y colaboración."],
            ["oat_8_cc","OAT 8 CC - Comprender que algunas de sus acciones y decisiones respecto al desarrollo de juegos y proyectos colectivos, influyen en las de sus pares."],
            ["oat_7_cm","OAT 7 CM - Resolver desafíos prácticos manteniendo control, equilibrio y coordinación al combinar diversos movimientos, posturas y desplazamientos tales como: lanzar y recibir, desplazarse en planos inclinados, seguir ritmos, en una variedad de juegos."],
          ], 
          "oa_2_1_3": [ 
            ["oat_6_ia","OAT 6 IA - Planificar proyectos y juegos, en función de sus ideas e intereses, proponiendo actividades, organizando los recursos, incorporando los ajustes necesarios e iniciándose en la apreciación de sus resultados."],
            ["oat_10_ia","OAT 10 IA - Comunicar a otras personas desafíos alcanzados, identificando acciones que aportaron a su logro y definiendo nuevas metas."],
            ["oat_1_cc","OAT 1 CC - Participar en actividades y juegos colaborativos, planificando, acordando estrategias para un propósito común y asumiendo progresivamente responsabilidades en ellos."], 
          ], 
          "oa_2_1_4": [ 
            ["oat_5_ia","OAT 5 IA - Comunicar sus preferencias, opiniones, ideas, en diversas situaciones cotidianas y juegos."],
            ["oat_7_ia","OAT 7 IA - Comunicar rasgos de su identidad de género, roles (nieta/o, vecino/a, entre otros), sentido de pertenencia y cualidades personales."],
            ["oat_1_cc","OAT 1 CC - Participar en actividades y juegos colaborativos, planificando, acordando estrategias para un propósito común y asumiendo progresivamente responsabilidades en ellos."],
          ], 
          "oa_2_1_5": [ 
            ["oat_5_ia","OAT 5 IA - Comunicar sus preferencias, opiniones, ideas, en diversas situaciones cotidianas y juegos."],
            ["oat_6_ia","OAT 6 IA - Planificar proyectos y juegos, en función de sus ideas e intereses, proponiendo actividades, organizando los recursos, incorporando los ajustes necesarios e iniciándose en la apreciación de sus resultados."],
            ["oat_12_ia","OAT 12 IA - Anticipar acciones y prever algunas situaciones o desafíos que se pueden presentar, en juegos, proyectos, sucesos que experimenta o que observa a través de TICs."],
          ], 
          "oa_2_1_6": [ 
            ["oat_5_ia","OAT 5 IA - Comunicar sus preferencias, opiniones, ideas, en diversas situaciones cotidianas y juegos."],
            ["oat_10_ia","OAT 10 IA - Comunicar a otras personas desafíos alcanzados, identificando acciones que aportaron a su logro y definiendo nuevas metas."],
            ["oat_11_ia","OAT 11 IA - Distinguir parámetros establecidos para la regulación de alimentos, tales como: etiquetado de sellos, fechas de vencimiento, entre otros."],
            ["oat_8_cc","OAT 8 CC - Comprender que algunas de sus acciones y decisiones respecto al desarrollo de juegos y proyectos colectivos, influyen en las de sus pares."],
          ], 
          "oa_2_1_7": [
            ["oat_8_ia","OAT 8 IA - Comunicar sus características identitarias, fortalezas, habilidades y desafíos personales."],
            ["oat_1_cc","OAT 1 CC - Participar en actividades y juegos colaborativos, planificando, acordando estrategias para un propósito común y asumiendo progresivamente responsabilidades en ellos."],
            ["oat_4_cc","OAT 4 CC - Apreciar el significado que tienen para las personas y las comunidades, diversas manifestaciones culturales que se desarrollan en su entorno."],
            ["oat_8_cc","OAT 8 CC - Comprender que algunas de sus acciones y decisiones respecto al desarrollo de juegos y proyectos colectivos, influyen en las de sus pares."],
          ], 
          "oa_2_1_8": [
            ["oat_1_ia","OAT 1 IA - Comunicar a los demás, emociones y sentimientos tales como: amor, miedo, alegría, ira, que le provocan diversas narraciones o situaciones observadas en forma directa o a través de TICs."],
            ["oat_6_cm","OAT 6 CM - Coordinar con precisión y eficiencia sus habilidades psicomotrices finas en función de sus intereses de exploración y juego."],
          ], 
          "oa_2_1_9": [
            ["oat_7_ia","OAT 7 IA - Comunicar rasgos de su identidad de género, roles (nieta/o, vecino/a, entre otros), sentido de pertenencia y cualidades personales."],
            ["oat_4_cc","OAT 4 CC - Apreciar el significado que tienen para las personas y las comunidades, diversas manifestaciones culturales que se desarrollan en su entorno."],
            ["oat_11_cc","OAT 11 CC - Apreciar la diversidad de las personas y sus formas de vida, tales como: singularidades fisonómicas, lingüísticas, religiosas, de género, entre otras."],
          ], 
          "oa_2_1_10": [
            ["oat_1_cc","OAT 1 CC - Participar en actividades y juegos colaborativos, planificando, acordando estrategias para un propósito común y asumiendo progresivamente responsabilidades en ellos."],
            ["oat_4_cc","OAT 4 CC - Apreciar el significado que tienen para las personas y las comunidades, diversas manifestaciones culturales que se desarrollan en su entorno."],
            ["oat_11_cc","OAT 11 CC - Apreciar la diversidad de las personas y sus formas de vida, tales como: singularidades fisonómicas, lingüísticas, religiosas, de género, entre otras."],
          ], 

          "oa_2_2_1": [
            ["oat_1_ia","OAT 1 IA - Comunicar a los demás, emociones y sentimientos tales como: amor, miedo, alegría, ira, que le provocan diversas narraciones o situaciones observadas en forma directa o a través de TICs."],
            ["oat_5_ia","OAT 5 IA - Comunicar sus preferencias, opiniones, ideas, en diversas situaciones cotidianas y juegos."],
            ["oat_4_cc","OAT 4 CC - Apreciar el significado que tienen para las personas y las comunidades, diversas manifestaciones culturales que se desarrollan en su entorno."],
          ], 
          "oa_2_2_2": [
            ["oat_3_ia","OAT 3 IA - Reconocer emociones y sentimientos en otras personas, observadas en forma directa o a través de TICs."],
            ["oat_4_ia","OAT 4 IA - Expresar sus emociones y sentimientos autorregulándose en función de las necesidades propias, de los demás y las normas de funcionamiento grupal."],
            ["oat_5_ia","OAT 5 IA - Comunicar sus preferencias, opiniones, ideas, en diversas situaciones cotidianas y juegos."],
            ["oat_3_cc","OAT 3 CC - Manifestar empatía y solidaridad frente a situaciones que vivencian sus pares, o que observa en textos o TICs, practicando acciones de escucha, apoyo y colaboración."],
            ["oat_11_cc","OAT 11 CC - Apreciar la diversidad de las personas y sus formas de vida, tales como: singularidades fisonómicas, lingüísticas, religiosas, de género, entre otras."],
          ], 
          "oa_2_2_3": [
            ["oat_5_ia","OAT 5 IA - Comunicar sus preferencias, opiniones, ideas, en diversas situaciones cotidianas y juegos."],
            ["oat_8_ia","OAT 8 IA - Comunicar sus características identitarias, fortalezas, habilidades y desafíos personales."],
            ["oat_1_cc","OAT 1 CC - Participar en actividades y juegos colaborativos, planificando, acordando estrategias para un propósito común y asumiendo progresivamente responsabilidades en ellos."],
            ["oat_7_cm","OAT 7 CM - Resolver desafíos prácticos manteniendo control, equilibrio y coordinación al combinar diversos movimientos, posturas y desplazamientos tales como: lanzar y recibir, desplazarse en planos inclinados, seguir ritmos, en una variedad de juegos."],
          ], 
          "oa_2_2_4": [
            ["oat_1_ia","OAT 1 IA - Comunicar a los demás, emociones y sentimientos tales como: amor, miedo, alegría, ira, que le provocan diversas narraciones o situaciones observadas en forma directa o a través de TICs."],
            ["oat_3_ia","OAT 3 IA - Reconocer emociones y sentimientos en otras personas, observadas en forma directa o a través de TICs."],
            ["oat_13_ia","OAT 13 IA - Representar en juegos sociodramáticos, sus pensamientos y experiencias atribuyendo significados a objetos, personas y situaciones."],
            ["oat_5_cm","OAT 5 CM - Comunicar el bienestar que le produce el movimiento, al ejercitar y recrear su cuerpo en forma habitual, con y sin implementos u obstáculos."],
            ["oat_7_cm","OAT 7 CM - Resolver desafíos prácticos manteniendo control, equilibrio y coordinación al combinar diversos movimientos, posturas y desplazamientos tales como: lanzar y recibir, desplazarse en planos inclinados, seguir ritmos, en una variedad de juegos."],
          ], 
          "oa_2_2_5": [
            ["oat_1_ia","OAT 1 IA - Comunicar a los demás, emociones y sentimientos tales como: amor, miedo, alegría, ira, que le provocan diversas narraciones o situaciones observadas en forma directa o a través de TICs."],
            ["oat_5_ia","OAT 5 IA - Comunicar sus preferencias, opiniones, ideas, en diversas situaciones cotidianas y juegos."],
            ["oat_1_cc","OAT 1 CC - Participar en actividades y juegos colaborativos, planificando, acordando estrategias para un propósito común y asumiendo progresivamente responsabilidades en ellos."],
            ["oat_6_cm","OAT 6 CM - Coordinar con precisión y eficiencia sus habilidades psicomotrices finas en función de sus intereses de exploración y juego."],
          ], 
          "oa_2_2_6": [ 
            ["oat_4_ia","OAT 4 IA - Expresar sus emociones y sentimientos autorregulándose en función de las necesidades propias, de los demás y las normas de funcionamiento grupal."],
            ["oat_5_ia","OAT 5 IA - Comunicar sus preferencias, opiniones, ideas, en diversas situaciones cotidianas y juegos."],
            ["oat_6_ia","OAT 6 IA - Planificar proyectos y juegos, en función de sus ideas e intereses, proponiendo actividades, organizando los recursos, incorporando los ajustes necesarios e iniciándose en la apreciación de sus resultados."],
            ["oat_1_cc","OAT 1 CC - Participar en actividades y juegos colaborativos, planificando, acordando estrategias para un propósito común y asumiendo progresivamente responsabilidades en ellos."],
            ["oat_6_cm","OAT 6 CM - Coordinar con precisión y eficiencia sus habilidades psicomotrices finas en función de sus intereses de exploración y juego."],
          ], 
          "oa_2_2_7": [ 
            ["oat_5_ia","OAT 5 IA - Comunicar sus preferencias, opiniones, ideas, en diversas situaciones cotidianas y juegos."],
            ["oat_7_ia","OAT 7 IA - Comunicar rasgos de su identidad de género, roles (nieta/o, vecino/a, entre otros), sentido de pertenencia y cualidades personales."],
            ["oat_5_cc","OAT 5 CC - Aplicar estrategias pacíficas frente a la resolución de conflictos cotidianos con otros niños y niñas."],
            ["oat_6_cm","OAT 6 CM - Coordinar con precisión y eficiencia sus habilidades psicomotrices finas en función de sus intereses de exploración y juego."],
            ["oat_9_cm","OAT 9 CM - Utilizar categorías de ubicación espacial y temporal, tales como: adelante/atrás/al lado/entre, día/noche, hoy/mañana, antes/durante/después, en situaciones cotidianas y lúdicas."],
          ], 

          "oa_3_1_1": [ 
            ["oat_5_ia","OAT 5 IA - Comunicar sus preferencias, opiniones, ideas, en diversas situaciones cotidianas y juegos."],
            ["oat_6_ia","OAT 6 IA - Planificar proyectos y juegos, en función de sus ideas e intereses, proponiendo actividades, organizando los recursos, incorporando los ajustes necesarios e iniciándose en la apreciación de sus resultados."],
            ["oat_1_cc","OAT 1 CC - Participar en actividades y juegos colaborativos, planificando, acordando estrategias para un propósito común y asumiendo progresivamente responsabilidades en ellos."],
          ], 
          "oa_3_1_2": [ 
            ["oat_1_ia","OAT 1 IA - Comunicar a los demás, emociones y sentimientos tales como: amor, miedo, alegría, ira, que le provocan diversas narraciones o situaciones observadas en forma directa o a través de TICs."],
            ["oat_5_ia","OAT 5 IA - Comunicar sus preferencias, opiniones, ideas, en diversas situaciones cotidianas y juegos."],
            ["oat_7_ia","OAT 7 IA - Comunicar rasgos de su identidad de género, roles (nieta/o, vecino/a, entre otros), sentido de pertenencia y cualidades personales."],
            ["oat_12_ia","OAT 12 IA - Anticipar acciones y prever algunas situaciones o desafíos que se pueden presentar, en juegos, proyectos, sucesos que experimenta o que observa a través de TICs."],
            ["oat_1_cc","OAT 1 CC - Participar en actividades y juegos colaborativos, planificando, acordando estrategias para un propósito común y asumiendo progresivamente responsabilidades en ellos."],
            ["oat_7_cc","OAT 7 CC - Identificar objetos, comportamientos y situaciones de riesgo que pueden atentar contra su bienestar y seguridad, o la de los demás, proponiendo alternativas para enfrentarlas."],
            ["oat_6_cm","OAT 6 CM - Coordinar con precisión y eficiencia sus habilidades psicomotrices finas en función de sus intereses de exploración y juego."],
          ], 
          "oa_3_1_3": [  
            ["oat_5_ia","OAT 5 IA - Comunicar sus preferencias, opiniones, ideas, en diversas situaciones cotidianas y juegos."],
            ["oat_9_ia","OAT 9 IA - Cuidar su bienestar personal, llevando a cabo sus prácticas de higiene, alimentación y vestuario, con independencia y progresiva responsabilidad."],
            ["oat_11_ia","OAT 11 IA - Distinguir parámetros establecidos para la regulación de alimentos, tales como: etiquetado de sellos, fechas de vencimiento, entre otros."],
            ["oat_12_ia","OAT 12 IA - Anticipar acciones y prever algunas situaciones o desafíos que se pueden presentar, en juegos, proyectos, sucesos que experimenta o que observa a través de TICs."],
            ["oat_1_cc","OAT 1 CC - Participar en actividades y juegos colaborativos, planificando, acordando estrategias para un propósito común y asumiendo progresivamente responsabilidades en ellos."],
            ["oat_7_cc","OAT 7 CC - Identificar objetos, comportamientos y situaciones de riesgo que pueden atentar contra su bienestar y seguridad, o la de los demás, proponiendo alternativas para enfrentarlas."],
            ["oat_6_cm","OAT 6 CM - Coordinar con precisión y eficiencia sus habilidades psicomotrices finas en función de sus intereses de exploración y juego."],
          ], 
          "oa_3_1_4": [  
            ["oat_5_ia","OAT 5 IA - Comunicar sus preferencias, opiniones, ideas, en diversas situaciones cotidianas y juegos."],
            ["oat_12_ia","OAT 12 IA - Anticipar acciones y prever algunas situaciones o desafíos que se pueden presentar, en juegos, proyectos, sucesos que experimenta o que observa a través de TICs."],
            ["oat_13_ia","OAT 13 IA - Representar en juegos sociodramáticos, sus pensamientos y experiencias atribuyendo significados a objetos, personas y situaciones."],
            ["oat_1_cc","OAT 1 CC - Participar en actividades y juegos colaborativos, planificando, acordando estrategias para un propósito común y asumiendo progresivamente responsabilidades en ellos."],
            ["oat_6_cc","OAT 6 CC - Respetar normas y acuerdos creados colaborativamente con pares y adultos, para el bienestar del grupo."],
            ["oat_6_cm","OAT 6 CM - Coordinar con precisión y eficiencia sus habilidades psicomotrices finas en función de sus intereses de exploración y juego."],
          ], 
          "oa_3_1_5": [  
            ["oat_6_ia","OAT 6 IA - Planificar proyectos y juegos, en función de sus ideas e intereses, proponiendo actividades, organizando los recursos, incorporando los ajustes necesarios e iniciándose en la apreciación de sus resultados."],
            ["oat_12_ia","OAT 12 IA - Anticipar acciones y prever algunas situaciones o desafíos que se pueden presentar, en juegos, proyectos, sucesos que experimenta o que observa a través de TICs."],
          ], 
          "oa_3_1_6": [  
            ["oat_5_ia","OAT 5 IA - Comunicar sus preferencias, opiniones, ideas, en diversas situaciones cotidianas y juegos."],
            ["oat_12_ia","OAT 12 IA - Anticipar acciones y prever algunas situaciones o desafíos que se pueden presentar, en juegos, proyectos, sucesos que experimenta o que observa a través de TICs."],
            ["oat_1_cc","OAT 1 CC - Participar en actividades y juegos colaborativos, planificando, acordando estrategias para un propósito común y asumiendo progresivamente responsabilidades en ellos."],
            ["oat_10_cc","OAT 10 CC - Reconocer progresivamente requerimientos esenciales de las prácticas de convivencia democrática, tales como: escucha de opiniones divergentes, el respeto por los demás, de los turnos, de los acuerdos de las mayorías."],
            ["oat_11_cc","OAT 11 CC - Apreciar la diversidad de las personas y sus formas de vida, tales como: singularidades fisonómicas, lingüísticas, religiosas, de género, entre otras."],
          ], 
          "oa_3_1_7": [  
            ["oat_1_cc","OAT 1 CC - Participar en actividades y juegos colaborativos, planificando, acordando estrategias para un propósito común y asumiendo progresivamente responsabilidades en ellos."],
            ["oat_10_cc","OAT 10 CC - Reconocer progresivamente requerimientos esenciales de las prácticas de convivencia democrática, tales como: escucha de opiniones divergentes, el respeto por los demás, de los turnos, de los acuerdos de las mayorías."],
            ["oat_11_cc","OAT 11 CC - Apreciar la diversidad de las personas y sus formas de vida, tales como: singularidades fisonómicas, lingüísticas, religiosas, de género, entre otras."],
          ], 
          "oa_3_1_8": [  
            ["oat_6_ia","OAT 6 IA - Planificar proyectos y juegos, en función de sus ideas e intereses, proponiendo actividades, organizando los recursos, incorporando los ajustes necesarios e iniciándose en la apreciación de sus resultados."],
            ["oat_2_cc","OAT 2 CC - Participar en actividades solidarias, que integran a las familias, la comunidad educativa y local."],
            ["oat_6_cc","OAT 6 CC - Respetar normas y acuerdos creados colaborativamente con pares y adultos, para el bienestar del grupo."],
          ], 
          "oa_3_1_9": [  
            ["oat_5_ia","OAT 5 IA - Comunicar sus preferencias, opiniones, ideas, en diversas situaciones cotidianas y juegos."],
            ["oat_10_ia","OAT 10 IA - Comunicar a otras personas desafíos alcanzados, identificando acciones que aportaron a su logro y definiendo nuevas metas."],
            ["oat_12_ia","OAT 12 IA - Anticipar acciones y prever algunas situaciones o desafíos que se pueden presentar, en juegos, proyectos, sucesos que experimenta o que observa a través de TICs."],
            ["oat_1_cc","OAT 1 CC - Participar en actividades y juegos colaborativos, planificando, acordando estrategias para un propósito común y asumiendo progresivamente responsabilidades en ellos."],
          ], 
          "oa_3_1_10": [  
            ["oat_5_ia","OAT 5 IA - Comunicar sus preferencias, opiniones, ideas, en diversas situaciones cotidianas y juegos."],
            ["oat_12_ia","OAT 12 IA - Anticipar acciones y prever algunas situaciones o desafíos que se pueden presentar, en juegos, proyectos, sucesos que experimenta o que observa a través de TICs."],
            ["oat_1_cc","OAT 1 CC - Participar en actividades y juegos colaborativos, planificando, acordando estrategias para un propósito común y asumiendo progresivamente responsabilidades en ellos."],
            ["oat_6_cm","OAT 6 CM - Coordinar con precisión y eficiencia sus habilidades psicomotrices finas en función de sus intereses de exploración y juego."],
          ], 
          "oa_3_1_11": [  
            ["oat_5_ia","OAT 5 IA - Comunicar sus preferencias, opiniones, ideas, en diversas situaciones cotidianas y juegos."],
            ["oat_1_cc","OAT 1 CC - Participar en actividades y juegos colaborativos, planificando, acordando estrategias para un propósito común y asumiendo progresivamente responsabilidades en ellos."],
            ["oat_7_cc","OAT 7 CC - Identificar objetos, comportamientos y situaciones de riesgo que pueden atentar contra su bienestar y seguridad, o la de los demás, proponiendo alternativas para enfrentarlas."],
            ["oat_5_cm","OAT 5 CM - Comunicar el bienestar que le produce el movimiento, al ejercitar y recrear su cuerpo en forma habitual, con y sin implementos u obstáculos."],
          ], 
          "oa_3_1_12": [  
            ["oat_2_ia","OAT 2 IA - Manifestar disposición y confianza para relacionarse con algunos adultos y pares que no son parte del grupo o curso."],
            ["oat_5_ia","OAT 5 IA - Comunicar sus preferencias, opiniones, ideas, en diversas situaciones cotidianas y juegos."],
            ["oat_1_cc","OAT 1 CC - Participar en actividades y juegos colaborativos, planificando, acordando estrategias para un propósito común y asumiendo progresivamente responsabilidades en ellos."],
            ["oat_5_cm","OAT 5 CM - Comunicar el bienestar que le produce el movimiento, al ejercitar y recrear su cuerpo en forma habitual, con y sin implementos u obstáculos."],
            ["oat_6_cm","OAT 6 CM - Coordinar con precisión y eficiencia sus habilidades psicomotrices finas en función de sus intereses de exploración y juego."],
          ], 

          "oa_3_2_1": [
            ["oat_2_ia","OAT 2 IA - Manifestar disposición y confianza para relacionarse con algunos adultos y pares que no son parte del grupo o curso."],
            ["oat_7_ia","OAT 7 IA - Comunicar rasgos de su identidad de género, roles (nieta/o, vecino/a, entre otros), sentido de pertenencia y cualidades personales."],
            ["oat_13_ia","OAT 13 IA - Representar en juegos sociodramáticos, sus pensamientos y experiencias atribuyendo significados a objetos, personas y situaciones."],
            ["oat_6_ia","OAT 6 CM - Coordinar con precisión y eficiencia sus habilidades psicomotrices finas en función de sus intereses de exploración y juego."],
            ["oat_1_ia","OAT 1 CM - Manifestar iniciativa para resguardar el autocuidado de su cuerpo y su confortabilidad, en función de su propio bienestar."],
          ], 
          "oa_3_2_2": [ 
            ["oat_5_ia","OAT 5 IA - Comunicar sus preferencias, opiniones, ideas, en diversas situaciones cotidianas y juegos."],
            ["oat_12_ia","OAT 12 IA - Anticipar acciones y prever algunas situaciones o desafíos que se pueden presentar, en juegos, proyectos, sucesos que experimenta o que observa a través de TICs."],
            ["oat_11_cc","OAT 11 CC - Apreciar la diversidad de las personas y sus formas de vida, tales como: singularidades fisonómicas, lingüísticas, religiosas, de género, entre otras."],
          ], 
          "oa_3_2_3": [ 
            ["oat_5_ia","OAT 5 IA - Comunicar sus preferencias, opiniones, ideas, en diversas situaciones cotidianas y juegos."],
            ["oat_12_ia","OAT 12 IA - Anticipar acciones y prever algunas situaciones o desafíos que se pueden presentar, en juegos, proyectos, sucesos que experimenta o que observa a través de TICs."],
            ["oat_1_cc","OAT 1 CC - Participar en actividades y juegos colaborativos, planificando, acordando estrategias para un propósito común y asumiendo progresivamente responsabilidades en ellos."],
          ], 
          "oa_3_2_4": [ 
            ["oat_2_ia","OAT 2 IA - Manifestar disposición y confianza para relacionarse con algunos adultos y pares que no son parte del grupo o curso."],
            ["oat_5_ia","OAT 5 IA - Comunicar sus preferencias, opiniones, ideas, en diversas situaciones cotidianas y juegos."],
            ["oat_13_ia","OAT 13 IA - Representar en juegos sociodramáticos, sus pensamientos y experiencias atribuyendo significados a objetos, personas y situaciones."],
            ["oat_2_cm","OAT 2 CM - Apreciar sus características corporales, manifestando interés y cuidado por su bienestar y apariencia personal."],
          ], 
          "oa_3_2_5": [ 
            ["oat_3_cc","OAT 3 CC - Manifestar empatía y solidaridad frente a situaciones que vivencian sus pares, o que observa en textos o TICs, practicando acciones de escucha, apoyo y colaboración."],
            ["oat_4_cc","OAT 4 CC - Apreciar el significado que tienen para las personas y las comunidades, diversas manifestaciones culturales que se desarrollan en su entorno."],
            ["oat_10_cc","OAT 10 CC - Reconocer progresivamente requerimientos esenciales de las prácticas de convivencia democrática, tales como: escucha de opiniones divergentes, el respeto por los demás, de los turnos, de los acuerdos de las mayorías."],
            ["oat_4_cm","OAT 4 CM - Comunicar nuevas posibilidades de acción logradas a través de su cuerpo en situaciones cotidianas y de juego, empleando vocabulario preciso."],
          ], 
          "oa_3_2_6": [ 
            ["oat_2_ia","OAT 2 IA - Manifestar disposición y confianza para relacionarse con algunos adultos y pares que no son parte del grupo o curso."],
            ["oat_5_ia","OAT 5 IA - Comunicar sus preferencias, opiniones, ideas, en diversas situaciones cotidianas y juegos."],
            ["oat_4_cc","OAT 4 CC - Apreciar el significado que tienen para las personas y las comunidades, diversas manifestaciones culturales que se desarrollan en su entorno."],
            ["oat_6_cc","OAT 6 CC - Respetar normas y acuerdos creados colaborativamente con pares y adultos, para el bienestar del grupo."],
          ], 
          "oa_3_2_7": [ 
            ["oat_2_ia","OAT 2 IA - Manifestar disposición y confianza para relacionarse con algunos adultos y pares que no son parte del grupo o curso."],
            ["oat_1_cc","OAT 1 CC - Participar en actividades y juegos colaborativos, planificando, acordando estrategias para un propósito común y asumiendo progresivamente responsabilidades en ellos."],
            ["oat_3_cc","OAT 3 CC - Manifestar empatía y solidaridad frente a situaciones que vivencian sus pares, o que observa en textos o TICs, practicando acciones de escucha, apoyo y colaboración."],
          ], 
          "oa_3_2_8": [ 
            ["oat_3_ia","OAT 3 IA - Reconocer emociones y sentimientos en otras personas, observadas en forma directa o a través de TICs."],
            ["oat_5_ia","OAT 5 IA - Comunicar sus preferencias, opiniones, ideas, en diversas situaciones cotidianas y juegos."],
            ["oat_6_cm","OAT 6 CM - Coordinar con precisión y eficiencia sus habilidades psicomotrices finas en función de sus intereses de exploración y juego."],
          ], 
          "oa_3_2_9": [ 
            ["oat_2_ia","OAT 2 IA - Manifestar disposición y confianza para relacionarse con algunos adultos y pares que no son parte del grupo o curso."],
            ["oat_4_cc","OAT 4 CC - Apreciar el significado que tienen para las personas y las comunidades, diversas manifestaciones culturales que se desarrollan en su entorno."],
            ["oat_9_cc","OAT 9 CC - Reconocer, y progresivamente hacer respetar el derecho a expresarse libremente, a ser escuchado y a que su opinión sea tomada en cuenta."],
            ["oat_10_cc","OAT 10 CC - Reconocer progresivamente requerimientos esenciales de las prácticas de convivencia democrática, tales como: escucha de opiniones divergentes, el respeto por los demás, de los turnos, de los acuerdos de las mayorías."],
          ], 
          "oa_3_2_10": [ 
            ["oat_13_ia","OAT 13 IA - Representar en juegos sociodramáticos, sus pensamientos y experiencias atribuyendo significados a objetos, personas y situaciones."],
            ["oat_7_cc","OAT 7 CC - Identificar objetos, comportamientos y situaciones de riesgo que pueden atentar contra su bienestar y seguridad, o la de los demás, proponiendo alternativas para enfrentarlas."],
            ["oat_1_cm","OAT 1 CM - Manifestar iniciativa para resguardar el autocuidado de su cuerpo y su confortabilidad, en función de su propio bienestar."],
          ], 
          "oa_3_2_11": [ 
            ["oat_1_cc","OAT 1 CC - Participar en actividades y juegos colaborativos, planificando, acordando estrategias para un propósito común y asumiendo progresivamente responsabilidades en ellos."],
            ["oat_6_cm","OAT 6 CM - Coordinar con precisión y eficiencia sus habilidades psicomotrices finas en función de sus intereses de exploración y juego."],
            ["oat_9_cm","OAT 9 CM - Utilizar categorías de ubicación espacial y temporal, tales como: adelante/atrás/al lado/entre, día/noche, hoy/mañana, antes/durante/después, en situaciones cotidianas y lúdicas."],
          ], 

          "oa_3_3_1": [ 
            ["oat_5_ia","OAT 5 IA - Comunicar sus preferencias, opiniones, ideas, en diversas situaciones cotidianas y juegos."],
            ["oat_1_cc","OAT 1 CC - Participar en actividades y juegos colaborativos, planificando, acordando estrategias para un propósito común y asumiendo progresivamente responsabilidades en ellos."], 
          ], 
          "oa_3_3_2": [ 
            ["oat_2_ia","OAT 2 IA - Manifestar disposición y confianza para relacionarse con algunos adultos y pares que no son parte del grupo o curso."], 
            ["oat_5_ia","OAT 5 IA - Comunicar sus preferencias, opiniones, ideas, en diversas situaciones cotidianas y juegos."], 
            ["oat_1_cc","OAT 1 CC - Participar en actividades y juegos colaborativos, planificando, acordando estrategias para un propósito común y asumiendo progresivamente responsabilidades en ellos."], 
            ["oat_10_cc","OAT 10 CC - Reconocer progresivamente requerimientos esenciales de las prácticas de convivencia democrática, tales como: escucha de opiniones divergentes, el respeto por los demás, de los turnos, de los acuerdos de las mayorías."], 
            ["oat_6_cm","OAT 6 CM - Coordinar con precisión y eficiencia sus habilidades psicomotrices finas en función de sus intereses de exploración y juego."], 
          ], 
          "oa_3_3_3": [  
            ["oat_6_cc","OAT 6 CC - Respetar normas y acuerdos creados colaborativamente con pares y adultos, para el bienestar del grupo."], 
            ["oat_7_cm","OAT 7 CM - Resolver desafíos prácticos manteniendo control, equilibrio y coordinación al combinar diversos movimientos, posturas y desplazamientos tales como: lanzar y recibir, desplazarse en planos inclinados, seguir ritmos, en una variedad de juegos."], 
            ["oat_9_cm","OAT 9 CM - Utilizar categorías de ubicación espacial y temporal, tales como: adelante/atrás/al lado/entre, día/noche, hoy/mañana, antes/durante/después, en situaciones cotidianas y lúdicas."], 
          ], 
          "oa_3_3_4": [  
            ["oat_5_ia","OAT 5 IA - Comunicar sus preferencias, opiniones, ideas, en diversas situaciones cotidianas y juegos."],
            ["oat_1_cc","OAT 1 CC - Participar en actividades y juegos colaborativos, planificando, acordando estrategias para un propósito común y asumiendo progresivamente responsabilidades en ellos."], 
            ["oat_10_cc","OAT 10 CC - Reconocer progresivamente requerimientos esenciales de las prácticas de convivencia democrática, tales como: escucha de opiniones divergentes, el respeto por los demás, de los turnos, de los acuerdos de las mayorías."], 
            ["oat_6_cm","OAT 6 CM - Coordinar con precisión y eficiencia sus habilidades psicomotrices finas en función de sus intereses de exploración y juego."],  
          ], 
          "oa_3_3_5": [  
            ["oat_9_ia","OAT 9 IA - Cuidar su bienestar personal, llevando a cabo sus prácticas de higiene, alimentación y vestuario, con independencia y progresiva responsabilidad."], 
            ["oat_12_ia","OAT 12 IA - Anticipar acciones y prever algunas situaciones o desafíos que se pueden presentar, en juegos, proyectos, sucesos que experimenta o que observa a través de TICs."], 
            ["oat_1_cc","OAT 1 CC - Participar en actividades y juegos colaborativos, planificando, acordando estrategias para un propósito común y asumiendo progresivamente responsabilidades en ellos."], 
            ["oat_9_cc","OAT 9 CC - Reconocer, y progresivamente hacer respetar el derecho a expresarse libremente, a ser escuchado y a que su opinión sea tomada en cuenta."], 
            ["oat_4_cm","OAT 4 CM - Comunicar nuevas posibilidades de acción logradas a través de su cuerpo en situaciones cotidianas y de juego, empleando vocabulario preciso."], 
            ["oat_9_cm","OAT 9 CM - Utilizar categorías de ubicación espacial y temporal, tales como: adelante/atrás/al lado/entre, día/noche, hoy/mañana, antes/durante/después, en situaciones cotidianas y lúdicas."], 
          ], 
          "oa_3_3_6": [  
            ["oat_6_ia","OAT 6 IA - Planificar proyectos y juegos, en función de sus ideas e intereses, proponiendo actividades, organizando los recursos, incorporando los ajustes necesarios e iniciándose en la apreciación de sus resultados."], 
            ["oat_1_cc","OAT 1 CC - Participar en actividades y juegos colaborativos, planificando, acordando estrategias para un propósito común y asumiendo progresivamente responsabilidades en ellos."], 
            ["oat_3_cc","OAT 3 CC - Manifestar empatía y solidaridad frente a situaciones que vivencian sus pares, o que observa en textos o TICs, practicando acciones de escucha, apoyo y colaboración."], 
            ["oat_6_cc","OAT 6 CC - Respetar normas y acuerdos creados colaborativamente con pares y adultos, para el bienestar del grupo."], 
            ["oat_10_cc","OAT 10 CC - Reconocer progresivamente requerimientos esenciales de las prácticas de convivencia democrática, tales como: escucha de opiniones divergentes, el respeto por los demás, de los turnos, de los acuerdos de las mayorías."], 
            ["oat_3_cm","OAT 3 CM - Tomar conciencia de su cuerpo, de algunas de sus características internas (tales como: ritmo cardíaco, de respiración), de su esquema y progresivamente de su tono corporal y lateralidad, por medio de juegos."], 
            ["oat_6_cm","OAT 6 CM - Coordinar con precisión y eficiencia sus habilidades psicomotrices finas en función de sus intereses de exploración y juego."], 
            ["oat_7_cm","OAT 7 CM - Resolver desafíos prácticos manteniendo control, equilibrio y coordinación al combinar diversos movimientos, posturas y desplazamientos tales como: lanzar y recibir, desplazarse en planos inclinados, seguir ritmos, en una variedad de juegos."], 
            ["oat_8_cm","OAT 8 CM - Coordinar sus habilidades psicomotoras practicando posturas y movimientos de fuerza, resistencia y tracción tales como: tirar la cuerda, transportar objetos, utilizar implementos, en situaciones cotidianas y de juego."], 
            ["oat_9_cm","OAT 9 CM - Utilizar categorías de ubicación espacial y temporal, tales como: adelante/atrás/al lado/entre, día/noche, hoy/mañana, antes/durante/después, en situaciones cotidianas y lúdicas."], 
          ], 
          "oa_3_3_7": [ 
            ["oat_5_ia","OAT 5 IA - Comunicar sus preferencias, opiniones, ideas, en diversas situaciones cotidianas y juegos."], 
            ["oat_6_ia","OAT 6 IA - Planificar proyectos y juegos, en función de sus ideas e intereses, proponiendo actividades, organizando los recursos, incorporando los ajustes necesarios e iniciándose en la apreciación de sus resultados"], 
            ["oat_1_cc","OAT 1 CC - Participar en actividades y juegos colaborativos, planificando, acordando estrategias para un propósito común y asumiendo progresivamente responsabilidades en ellos."], 
            ["oat_6_cm","OAT 6 CM - Coordinar con precisión y eficiencia sus habilidades psicomotrices finas en función de sus intereses de exploración y juego."], 
            ["oat_10_cc","OAT 10 CC - Reconocer progresivamente requerimientos esenciales de las prácticas de convivencia democrática, tales como: escucha de opiniones divergentes, el respeto por los demás, de los turnos, de los acuerdos de las mayorías."], 
           ], 
          "oa_3_3_8": [ 
            ["oat_5_ia","OAT 5 IA - Comunicar sus preferencias, opiniones, ideas, en diversas situaciones cotidianas y juegos."], 
            ["oat_1_cc","OAT 1 CC - Participar en actividades y juegos colaborativos, planificando, acordando estrategias para un propósito común y asumiendo progresivamente responsabilidades en ellos."], 
            ["oat_6_cc","OAT 6 CC - Respetar normas y acuerdos creados colaborativamente con pares y adultos, para el bienestar del grupo."], 
            ["oat_10_cc","OAT 10 CC - Reconocer progresivamente requerimientos esenciales de las prácticas de convivencia democrática, tales como: escucha de opiniones divergentes, el respeto por los demás, de los turnos, de los acuerdos de las mayorías."], 
            ["oat_6_cm","OAT 6 CM - Coordinar con precisión y eficiencia sus habilidades psicomotrices finas en función de sus intereses de exploración y juego."], 
           ], 
          "oa_3_3_9": [ 
            ["oat_5_ia","OAT 5 IA - Comunicar sus preferencias, opiniones, ideas, en diversas situaciones cotidianas y juegos."], 
            ["oat_12_ia","OAT 12 IA - Anticipar acciones y prever algunas situaciones o desafíos que se pueden presentar, en juegos, proyectos, sucesos que experimenta o que observa a través de TICs."], 
            ["oat_6_cc","OAT 6 CC - Respetar normas y acuerdos creados colaborativamente con pares y adultos, para el bienestar del grupo."], 
            ["oat_9_cc","OAT 9 CC - Reconocer, y progresivamente hacer respetar el derecho a expresarse libremente, a ser escuchado y a que su opinión sea tomada en cuenta."], 
            ["oat_9_cm","OAT 9 CM - Utilizar categorías de ubicación espacial y temporal, tales como: adelante/atrás/al lado/entre, día/noche, hoy/mañana, antes/durante/después, en situaciones cotidianas y lúdicas."], 
          ], 
          "oa_3_3_10": [ 
            ["oat_5_ia","OAT 5 IA - Comunicar sus preferencias, opiniones, ideas, en diversas situaciones cotidianas y juegos."], 
            ["oat_1_cc","OAT 1 CC - Participar en actividades y juegos colaborativos, planificando, acordando estrategias para un propósito común y asumiendo progresivamente responsabilidades en ellos."], 
            ["oat_6_cc","OAT 6 CC - Respetar normas y acuerdos creados colaborativamente con pares y adultos, para el bienestar del grupo."], 
            ["oat_6_cm","OAT 6 CM - Coordinar con precisión y eficiencia sus habilidades psicomotrices finas en función de sus intereses de exploración y juego."], 
           ], 
          "oa_3_3_11": [  
            ["oat_6_ia","OAT 6 IA - Planificar proyectos y juegos, en función de sus ideas e intereses, proponiendo actividades, organizando los recursos, incorporando los ajustes necesarios e iniciándose en la apreciación de sus resultados."], 
            ["oat_1_cc","OAT 1 CC - Participar en actividades y juegos colaborativos, planificando, acordando estrategias para un propósito común y asumiendo progresivamente responsabilidades en ellos."], 
            ["oat_4_cm","OAT 4 CM - Comunicar nuevas posibilidades de acción logradas a través de su cuerpo en situaciones cotidianas y de juego, empleando vocabulario preciso."], 
            ["oat_6_cm","OAT 6 CM - Coordinar con precisión y eficiencia sus habilidades psicomotrices finas en función de sus intereses de exploración y juego."], 
          ], 
          "oa_3_3_12": [ 
            ["oat_5_ia","OAT 5 IA - Comunicar sus preferencias, opiniones, ideas, en diversas situaciones cotidianas y juegos."], 
            ["oat_12_ia","OAT 12 IA - Anticipar acciones y prever algunas situaciones o desafíos que se pueden presentar, en juegos, proyectos, sucesos que experimenta o que observa a través de TICs."], 
            ["oat_5_ia","OAT 5 IA - Comunicar sus preferencias, opiniones, ideas, en diversas situaciones cotidianas y juegos."], 
          ], 

        }
    },

    {
        "id": "activity_time",
        "label": "Tiempo de la Actividad",
        "desc": "Elige una duración",
        "fieldValues": [
          [
            "at_15",
            "15 min"
          ],
          [
            "at_30",
            "30 min"
          ],
          [
            "at_45",
            "45 min"
          ] 
        ]
    },  
    {
        "id": "complexity",
        "label": "Nivel de Complejidad",
        "desc": "Elige un Nivel",
        "fieldValues": [
          [
            "c_low",
            "Bajo"
          ],
          [
            "c_medium",
            "Medio"
          ],
          [
            "c_high",
            "Alto"
          ] 
        ]
    }

]

export default fieldDataCL;
