/* @flow */

import Raven from 'raven-js';

const interceptErrorActions = (action, store) => {
  const { type, payload } = action;
  switch (type) {
    case 'ERROR':
      if (!payload || payload.report) {
        let title = 'UnexpectedError Intercepted';
        const { actionPayload } = payload.data;
        if (actionPayload && actionPayload.code) {
          title = `Error-${actionPayload.code}`;
        }
        reportErrorWithState(title, { action, store });
      }
      break;
    default:
      break;
  }
};

const reportErrorWithState = (title, { action, store }) => {
  Raven.captureException(title, {
    extra: { action, state: store.getState() }
  });
};

const crashReporter = (store: Object) => (next: Function) => (action: Object) => {
  try {
    interceptErrorActions(action, store);
    return next(action);
  } catch (err) {
    reportErrorWithState(err, { action, store });
    throw err;
  }
};

export default crashReporter;
