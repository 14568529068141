/* @flow */

import React from 'react';
import { GeneralIntelligenceProgressContainer } from '../';
import actions from '../../../actions';
import { getActiveChild } from '../../../selectors/children';
import { getAllIntelligenceProgressByChild } from '../../../selectors/intelligenceProgress';
import { pure, compose, lifecycle, withState } from 'recompose';
import { connect } from 'react-redux'
import { Table, Nav, NavItem, Button } from 'reactstrap'; 
import DataRetriever, { invalidateRetriever, useData } from '../../hocs/DataRetriever';
import { NavLink, Link, withRouter } from 'react-router-dom';
import SchoolUserFormContainer from './SchoolUserFormContainer';
import { genericDataRequest } from '../../../actions/genericData';

import { push } from 'react-router-redux';
import { Modal, Paper } from '../../components';
import { Col, Row } from 'reactstrap';
import TranslationsHelper from '../../hocs/TranslationsHelper';


const CourseSelector = connect(({courses})=>({courses}), (dispatch)=>({dispatch}))(
  compose(
    withState("value","setValue", "none"), 
    lifecycle({
      componentDidMount( ){
        this.props.dispatch(actions.getAllCourses());
      }
    })
  )( 
  ({courses,value, setValue, onAdd})=>{ 
    if(!courses)return null;

    return <div> 
        <label htmlFor="cars">Agregar Cursos</label>
        <div>
        <select value={value} onChange={(e)=>{ 
          onAdd(e.target.value); 
        }} name="cars" id="cars" >
          <option value={"none"}>-Add Course-</option>
          {
            courses.map((c)=>(
              <option key={c.id} value={c.id}>{c.name}</option>
            ))
          } 
        </select>

        </div> 
      </div>
  })

);


let SchoolUserChildren = ({data,clickStudent,removeStudent,  loading})=>{
  if(!data)return null;
  if(loading ) return <span>loading</span> ;
  const { status, body, errors} = data;  
  if( status>=400)return <span>error</span>;

  return <Table style={{width:"400px",overflow:"scroll"}}>
  <tr>
    { 
      ["name", "course_name" ].map(
        (c)=>(

          <th key={c}>
             {c} 
          </th>

          ) 
      )


    } 
    <th></th>
  </tr>
  {
      body.map(({id, name, course_name})=>(
          <tr key={id} onClick={()=>clickStudent(id)}>
              <td> 
                {name}
              </td>
              <td> 
                {course_name}
              </td>  
              <td>
                  <button onClick={(e)=>{removeStudent(id); e.stopPropagation();}}>X</button>
              </td>
          </tr>
      ))
  }
</Table>
}


SchoolUserChildren = DataRetriever(SchoolUserChildren);



let SchoolUserCourses = ({dispatch, user_id,data,removeCourse,addCourse,  loading})=>{
  if(!data)return null;
  if(loading ) return <span>loading</span> ;
  const { status, body, errors} = data;  
  if( status>=400)return <span>error</span>;

  return <Table style={{width:"400px",overflow:"scroll"}}>
  <tr>
    { 
      ["name" ].map(
        (c)=>(

          <th key={c}>
             {c} 
          </th>

          ) 
      )


    } 
    <th></th>
  </tr>
  {
      body.map(({id, name })=>(
          <tr key={id}  >
              <td> 
                {name}
              </td> 
              <td>
                  <button onClick={(e)=>{removeCourse(id); e.stopPropagation();}}>X</button>
              </td>
          </tr>
      ))
  }
  <CourseSelector onAdd={addCourse}/>
</Table>
}

SchoolUserCourses = DataRetriever(SchoolUserCourses);


var SchoolUserData = ({translations, closeModal, user_id,  role   }) => {
   
  const {data  , loading} = useData({id: "schooluserdata_", url: `/school/users/${user_id}`});
 
  const { status, body, errors} = data || {}; 

  return (


  
        <Paper.Container>
          {status>=400 && <span>error</span> }
          {loading && <span>loading</span> } 

          {body && !loading && 
        
            <Row>
              <Col xs="12">
                <Paper.Title>
                  <h1>{ translations.t("edit_"+ role)}</h1>
                </Paper.Title>
              </Col> 
     
              <Col xs="12">
                <SchoolUserFormContainer role={role} closeModal={closeModal} data = {body}/>
              </Col> 
            </Row>
          }
        
        </Paper.Container>
    /*  <div>
          <h3>
            School User {body.id}
          </h3> 
          {
            (editing)? <SchoolUserFormContainer closeModal={()=>setEditing(false)} data = {body}/>
            : <div>
                <dl>
                  <dt>User Name</dt>
                  <dd>{body.user_name}</dd>
                  <dt>Email</dt>
                  <dd>{body.email}</dd>
                  <dt>Role</dt>
                  <dd>{body.role}</dd>
                  <dt>Language</dt>
                  <dd>{body.locale}</dd>
                </dl> 
                <Button onClick={()=>setEditing(true)}>Edit</Button>
              </div>
          }
            
          {
            (body.role=='representative')?(
              <div> 
                  <h4>Children</h4>
                  <SchoolUserChildren id="SCHOOL_USER_CHILDREN" dataUrl={`/school/users/${body.id}/children`} 
                                      clickStudent={(id)=>alert("not implemented")}
                                      removeStudent={(id)=>alert("also not impemented")}/>
                </div>
            ):null
          }
          {
            (body.role=='teacher')?(
              <div> 
                  <h4>Courses</h4>
                  <SchoolUserCourses id="SCHOOL_USER_COURSES"  dataUrl={`/school/users/${body.id}/courses`} 
                                      addCourse={(v)=>{
                                        dispatch(genericDataRequest("SCHOOL_USER_COURSES",`/school/users/${body.id}/courses`,'post',{course_id: v},
                                                  ()=>{
                                                    invalidateRetriever("SCHOOL_USER_COURSES")
                                                  }))
                                      }}
                                      removeCourse={(id)=>{
                                        dispatch(genericDataRequest("SCHOOL_USER_COURSES",`/school/users/${body.id}/courses/${id}`,'delete',null,
                                                  ()=>{
                                                    invalidateRetriever("SCHOOL_USER_COURSES")
                                                  }))
                                      }}/>

                </div>
            ):null
          }
      </div>*/
      );
};
  
 /*
const SchoolUserDataRetriever =   compose(withState("editing","setEditing", false))(
  DataRetriever(SchoolUserData)  );*/

const SchoolUserContainerConnected =  connect(()=>({}), (dispatch,{ role, page, column, direction})=>({
  closeModal: ()=> dispatch(push(  `/school/users?role=${role}&&page=${page}&&column=${column}&&direction=${direction}`)) ,
  redirectToUser: (id)=>dispatch(push(  `/school/users?role=${role}&&page=${page}&&column=${column}&&direction=${direction}&&editing=${id}`))
})) (  (props) =>{   
  const {user_id,closeModal ,role, translations,redirectToUser} = props;

  return     <Modal
            backdropClassName="overlay-backdrop"
            contentClassName="overlay-modal-content"
            active={user_id!==undefined} 
          >
            <div className="confirmation-modal modal-edit-form">
              <span className="modal-close pleiq-icon-icon_plus" onClick={()=>closeModal()} />
                  {(user_id&& user_id!=='NEW')
                  &&<SchoolUserData  {...props}/> }
              {
                (user_id && user_id==='NEW') &&  <Paper.Container>
                    <Row>
                      <Col xs="12">
                        <Paper.Title>
                          <h1>{ 
                            translations.t("new_user_"+ role)
                          }</h1>
                        <p style={{marginBottom: "1.5rem"}}>
                          { 
                            translations.t("we_will_send_email_new_user")
                          }
                        </p>
                        </Paper.Title>
                      </Col> 
                      <Col xs="12">
                        <SchoolUserFormContainer redirectToUser={redirectToUser} role={role} closeModal={closeModal} /> 
                      </Col>
                 
                    </Row>
                      
                </Paper.Container>

              }
            </div>

          </Modal>;
})

export default  TranslationsHelper(  SchoolUserContainerConnected) ;
