/* @flow */

import React from 'react';
import { Navbar, NavbarBrand, Collapse, DropdownItem, Nav, NavLink, NavItem } from 'reactstrap';
import BrandLogo from './BrandLogo';
import { HeaderToggler } from './Header';
import { withState, compose } from 'recompose';
import Translations from '../hocs/TranslationsHelper';
import type { RecomposeStateName, RecomposeStateUpdater, TranslationsObject } from './types';
import { Link } from 'react-router-dom';
import _ from 'lodash';

type Props = {
  isMenuOpen: RecomposeStateName,
  toggle: RecomposeStateUpdater,
  translations: TranslationsObject
};

/**
 * functional component that only has the resposability of holding the composition of its children
 * to create the application header.
 *
 * @method RegistrationHeader
 * @param  {Boolean}      isMenuOpen   - injected by `recompose` as an state to control wether or not the nav menu is visible (mobile)
 * @param  {function}     toggle       - injected by `recompose` as a stateUpdater to toggle the isMenuOpen state
 * @param  {Object}       translations - injected by Translations HoC to be able to render translated text
 *
 */
export const RegistrationHeader = ({ isMenuOpen, toggle, translations }: Props) => {
  return (
    <div className="navbar-wrapper navbar-registration">
      <Navbar className="navbar-toggleable-md" container={false}>
        <div className="navbar-mobile-heading">
          <NavbarBrand href="/">
            <BrandLogo />
          </NavbarBrand>
          <div className="navbar-mobile-middle-content hidden-md-up">
            {
              process.env.REACT_APP_LANDING_HOST ?
                <a className="navbar-button btn btn-outline-primary" href={translations.t('buy_url')} target="_blank" rel="noopener noreferrer">
                  { translations.t('buy') }
                </a>
                : null
            }
          </div>
          <HeaderToggler isMenuOpen={isMenuOpen} toggle={toggle} />
        </div>
        <Collapse isOpen={isMenuOpen} navbar>
          <DropdownItem divider />
          <HeaderLinks translations={translations} />
          <DropdownItem divider />
          <Nav className="hidden-md-up">
            <NavItem>
              <div className="nav-link">
                <Link to="/login">
                  { translations.t('login') }
                </Link>
              </div>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  );
};

const HeaderLinks = ({ translations }) => {
  return (
    <Nav className="ml-auto">
      {
        HEADER_LINKS(translations.locale()).map((link, index) => {
          if (_.isEmpty(link.href)) {
            return null
          }

          return (
            <NavItem key={index}>
              <NavLink href={link.href} target="_blank">{ translations.t(link.text) }</NavLink>
            </NavItem>
          );
        })
      }
      <NavItem className="centered hidden-sm-down">
        <Link className="navbar-button btn btn-outline-info" to="/login">
          { translations.t('login') }
        </Link>
      </NavItem>
      <NavItem className="centered hidden-sm-down">
        {
          process.env.REACT_APP_LANDING_HOST ?
            <a className="navbar-button btn btn-outline-primary" href={translations.t('buy_url')} target="_blank" rel="noopener noreferrer">
              { translations.t('buy') }
            </a>
            : null
        }
      </NavItem>
    </Nav>
  );
};

const HEADER_LINKS = (locale)=> ([
  {
    text: 'about',
    href: `${process.env.REACT_APP_LANDING_HOST}`
  }, 
  {
    text: 'schools',
    href: `${process.env.REACT_APP_LANDING_HOST}/${locale}/schools`
  }, {
    text: 'faq',
    href: `${process.env.REACT_APP_LANDING_HOST}/${locale}/faq`
  }, {
    text: 'blog',
    href: `${process.env.REACT_APP_LANDING_HOST}/es/blog` //not using ${locale} for now
  }
]);

const RegistrationHeaderComposed = compose(
  withState('isMenuOpen', 'toggle', false)
)(RegistrationHeader);

export default Translations(RegistrationHeaderComposed);
