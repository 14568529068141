/* @flow */

import React from 'react';

type Props = {
  children: ?React.Component<*>,
  label: string,
  title: string
};

/**
 * A simple square card in order to display a same pattern of data
 * if you are looking for enhance this component to use in another
 * context you may want to check http://reactstrap.github.io/components/card/
 *
 * @method DataDisplay
 * @param  {React.Component}     children   - a tree of elements within the component most likely a display-lead div with some text inside
 * @param  {string}              title      - the title of the card
 * @param  {string}              label      - the label show at the bottom of the card
 */
const DataDisplay = ({ children, title, label, narrow=false }: Props) => {
  return (
    <div className={(narrow)?"data-display data-display-narrow":"data-display"}>
      <label className={"data-display-title"}>{title}</label>
      {children}
      <label className="data-display-label">{label}</label>
    </div>
  );
};

export default DataDisplay;
