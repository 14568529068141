/* @flow */

import { enableInitializing } from 'initializable-reducer';
import { responseHasError } from '../diplomatico/reducers/helper';
import type { Action } from '../diplomatico/types';
import sortByName from '../helpers/sortByName';
import _ from 'lodash';

const initialState = [];

function students(state: Array<Object> = initialState, { type, payload }: Action) {
  switch (type) {
    case 'RESPONSE_ALL_STUDENTS':
      if (responseHasError(payload)) {
        return state;
      }

      return sortByName(payload.body.data);
    case 'RESPONSE_STUDENTS':
      if (responseHasError(payload)) {
        return state;
      }

      const filteredState = state.filter((student) => {
        return !_.find(payload.body, { id: student.id });
      });

      return sortByName([ ...filteredState, ...payload.body ]);
    case 'ADD_STUDENT':
      return sortByName([ ...state, payload.student ]);
    case 'UPDATE_STUDENT':
      const newState = state.map((student) => {
        if (student.id === payload.student.id) {
          return { ...student, ...payload.student };
        }
        return student;
      });

      return sortByName(newState);
    case 'REMOVE_STUDENTS_COURSE':
      return state.map((student) => {
        if (student.course_id === payload.course_id) {
          return { ...student, course_id: null, course_name: null };
        }
        return student;
      });
    case 'REMOVE_STUDENTS':
      return state.filter((student) => {
        return !_.find(payload.students, { id: student.id });
      });
    case 'DELETE_STUDENT':
      return state.filter(student => student.id !== payload.student.id);
    case 'REMOVE_STUDENTS_ASSOCIATION':
      return state.map((student) => {
        if (_.find(payload.students, { id: student.id })) {
          return { ...student, course_id: null, course_name: null };
        }
        return student;
      });
    default:
      return state;
  }
}

export {
  students,
  initialState
};

export default enableInitializing(students);
