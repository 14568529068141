/* @flow */

import React from 'react';
import { GeneralIntelligenceProgressContainer } from './';
import actions from '../../actions';
import { getActiveChild } from '../../selectors/children';
import { getAllIntelligenceProgressByChild } from '../../selectors/intelligenceProgress';
import { pure, compose, lifecycle } from 'recompose';
import { connect } from 'react-redux'
import { Table } from 'reactstrap';
import { deleteSchoolUserInvitation } from '../../actions/schoolUserInvitations';

/**
 * Container that receives the data to display all the progress
 * over each of the Intelligences
 * @method IntelligencesProgressContainer
 * @param  {array}               currentChildProgress - injected by Redux includes all intelligence_progress from the store for the current child
 * @param  {array}               intelligences - injected by Redux includes all intelligences from the store
 */
var SchoolUserInvitationsContainer = ({ school_user_invitations, deleteOne  }) => {
  return (
      <div>
          <h3>
            Invitaciones activas
          </h3>
          <Table>
              <tr>
                  <th>
                      Name
                  </th>
                  <th>
                      Email
                  </th>
                  <th>
                      Role
                  </th>
                  <th>
                      Date
                  </th>
                  <th></th>
              </tr>
              {
                  school_user_invitations.map(({id, user_name, role, created_at, email})=>(
                      <tr>
                          <td> 
                            {user_name}
                          </td>
                          <td> 
                            {email}
                          </td>
                          <td> 
                            {role}
                          </td>
                          <td> 
                            {created_at}
                          </td>
                          <td>
                              <button onClick={()=>deleteOne(id)}>X</button>
                          </td>
                      </tr>
                  ))
              }
          </Table>
      </div>
      );
};
 

export const SchoolUserInvitationsContainerLifecycle = {
 
  componentDidMount() {
    const { dispatch  } = this.props;  
    dispatch(actions.getSchoolUserInvitations());
  }
};

const SchoolUserInvitationsContainerComposed = compose(
  lifecycle(SchoolUserInvitationsContainerLifecycle) 
)(SchoolUserInvitationsContainer);

const mapStateToProps = ({ school_user_invitations }, ownProps) => {
 
  return {
    school_user_invitations
  }
};

const mapDispatchToProps = (dispatch) => ({ dispatch , deleteOne: (id)=> dispatch(deleteSchoolUserInvitation(id))});

const SchoolUserInvitationsContainerConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(SchoolUserInvitationsContainerComposed);

export default SchoolUserInvitationsContainerConnected;
