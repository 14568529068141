/* @flow */

import React, { useContext, useState } from 'react';
import { Col, Row  } from 'reactstrap';
import { Paper, InfoTooltip, Button } from '../../components';
import {
  DataDisplayParentContainer, IntelligencesTopicContainer, IntelligencesProgressContainer,
  ActivityFeedContainer, HeaderContainer, PageTitleContainer, SchoolDashboardNavContainer, SchoolDashboardHeadingContainer
} from '../../containers';
import '../../../css/main.css';
import Translations from '../../hocs/TranslationsHelper';
import TranslationsHelper from '../../hocs/TranslationsHelper';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'react-router-redux';
 
import resources from "../../../data/resources"
import { LIN_VIS_Read_Comprehensive_News } from '../../../locales/en';
import { Link } from 'react-router-dom';
import { navItemHome } from '../../components/SchoolDashboardNav';
import { ProgressReportTypeSelect } from '../../containers/progress-reports/ProgressReportTypeSelect';
import DataDropDown from '../../containers/progress-reports/DataDropDown';
import ProgressReportList from '../../containers/progress-reports/ProgressReportList';
import { useData } from '../../hocs/DataRetriever';
import SchoolProgressReportTypeDialog from '../../containers/progress-reports/SchoolProgressReportTypeDialog';
import RoleFilter from '../../containers/RoleFilter';
 


const reportsUrl = (kind, student_id, course_id) => {
  let url = "/school/progress_reports";  
  if( kind!= "student" && kind!= "course" ){
    return url;
  }else{
    url+= "?kind="+ kind;
    if(kind =="student"){
      if(student_id) url+= "&&student_id="+ student_id;
    }else{
      if(course_id) url+= "&&course_id="+ course_id; 
    }

  }
  return url;
}

const EntityProgressReport = ({course_id, student_id}) => {

}
/**
 * Render SchoolProgressReports screen
 * @method SchoolProgressReports
 * @param  {object}                    translations - injected by our Translations HoC to give translated strings
 */
const SchoolProgressReports = ({ translations , queryParams}) => {

  const [showDialog,setShowDialog] = useState(false);

  const user = useSelector(({user})=>user);
  const dispatch = useDispatch();
  const kind = queryParams['kind']  ;
  const student_id = queryParams['student_id'] ;
  const course_id = queryParams['course_id'] ; 
 
  const data_uri = (course_id)?("course_id="+course_id):("student_id="+student_id);

  const item_data = useData((course_id)? ({id: "school_courses_"+course_id, url: "/school/courses/"+course_id})
                                        : ((student_id)? ({id: "school_students_"+student_id, url: "/school/students/"+student_id}): undefined));
 

  const list_url = "/school/progress_reports?" + data_uri;
  const report_data = useData({id: "school_progress_report_records_", url: list_url});
               //{data  ,  loading}                                                       
               /*
  const student_data = useData(student_id &&
    {id: "school_students_"+student_id, url: "/school/students/"+student_id});
  const course_data = useData(course_id &&
    {id: "school_courses_"+course_id, url: "/school/courses/"+course_id});
    */
  console.log("RD", report_data);

  return (
    <div id="school-dashboard" className="school-dashboard layout-base">
      <PageTitleContainer pageTitle="progress_reports" />
      <HeaderContainer/>
      <SchoolDashboardHeadingContainer />
      <Paper.Container lead>
        <SchoolDashboardNavContainer items={[navItemHome,{ 
            name: 'progress_reports',
            translation: 'progress_reports',
            url:"/progress_reports"
            }]} currentTab="progress_reports" hideSearch />
        <Row> 
          <Col xs="12">
              <Paper.Title>
                <h1 >{translations.t("progress_reports" ) } {' '}
                  <InfoTooltip message="tooltips_school_progress_reports" />  
                </h1> 
              </Paper.Title>
          </Col>    
        
          <Col xs="12" >
            <p>{translations.t("select_progress_report_type")}</p>
          </Col> 
          <Col xs="12" >
            <ProgressReportTypeSelect selectedType={kind}
              onSelectType = {(new_kind)=>dispatch(push(reportsUrl(new_kind, student_id, course_id)))}/>
          </Col> 
            {  
                (kind)&&( 
                <Col xs="12" >
                  <p>{translations.t("select_progress_report_"+kind)}</p>
 
                </Col>) 
            }  
           
            {  
                (kind)&&( 
                <Col xs="12" >
                  <DataDropDown className="progress-report-data-dropdown" dataUrl={ (kind=="course")?"/school/courses":"/school/students" }
                    selectedItem={item_data.data&& item_data.data.body }
                    label={"pick_a_"+kind}
                    onItemSelect={({value})=>{  dispatch(push(reportsUrl(kind, (kind=="course")?null:value, (kind=="course")?value:null))); }}  
                    />
                </Col>) 
            }   
          <Col xs="12" >
            {
              item_data.data&& item_data.data.body && <div>  
                <hr/>
                    
                <p>{translations.t("progress_reports_for",{name: item_data.data.body.name})}</p>
                <RoleFilter roles={["teacher","coordinator"]}>
                  <div className="progress-report-courses-actions"> 
                    <Button type="secondary--blue" className="courses-actions-button" onClick={()=>setShowDialog(true)}  disabled={ user.premium_status=="none"|| (  user.premium_status!="premium" && user.used_features &&  user.used_features.includes("SCHOOL_PROGRESS_REPORTS_LA"))} >
                        <span className="courses-actions-icon pleiq-icon-icon_plus" />
                        {translations.t('create_new_progress_report')}
                    </Button>
                    <Button type="secondary--blue" disabled={!report_data.data || !report_data.data.body || report_data.data.status>=300 || report_data.data.body&&report_data.data.body.length == 0 || user.premium_status!="premium"} className="courses-actions-button" onClick={()=>{

                      window.open("/school/progress_reports/cmp?" +data_uri, "_blank") ;
                    }}> 
                        {translations.t('compare_progress_reports')}
                    </Button> 
                  </div> 
                </RoleFilter>
                <ProgressReportList rdata={report_data} student={ (student_id) && item_data.data.body} course={ (course_id) && item_data.data.body}/>
                <SchoolProgressReportTypeDialog showModal = {showDialog}  setShowModal={setShowDialog} onSelect={
                  (ty)=>{
                    window.open("/school/progress_reports/edit?template_id=" +ty + "&&" + data_uri, "_blank") ;
                     setShowDialog(false);
                  }
                    
                // (ty)=>dispatch(push("/school/progress_reports/edit?template_id=" +ty + "&&" + data_uri))
                  }/>
              </div>
            } 
          </Col>
        </Row> 
      </Paper.Container> 
    </div>
  );
};

export default Translations(SchoolProgressReports);
