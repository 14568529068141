/* @flow */

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { pure, compose, lifecycle } from 'recompose';
import { RadialProgressBar } from '../components';
import _ from 'lodash';
import actions from '../../actions';
import { getActiveChild } from '../../selectors/children';
import { getIntelligenceBySlug } from '../../selectors/intelligences';
import { getIntelligenceProgressById, getAllIntelligenceProgressByChild } from '../../selectors/intelligenceProgress';

const getProgress = (intelligenceProgress) => {
  const hasScore = _.has(intelligenceProgress, 'score');
  return parseInt(hasScore ? intelligenceProgress.score : 0, 10);
};

/*
* Container to display intelligence progress
*
* @method RadialProgressBarContainer
*/
const RadialProgressBarContainer = ({ currentIntelligenceProgress }) => {
  const value = getProgress(currentIntelligenceProgress);

  return (
    <RadialProgressBar className="has-progress-rail" size="lg" value={value} />
  );
};

export const RadialProgressBarContainerLifecycle = {
  componentDidUpdate(prevProps) {
    const { dispatch, child_id } = this.props;
    const lastChild = prevProps.child_id;

    if (lastChild !== child_id) {
      dispatch(actions.getIntelligenceProgress({ child_id }));
    }
  }
};

export const RadialProgressBarContainerComposed = compose(
  lifecycle(RadialProgressBarContainerLifecycle),
  pure
)(RadialProgressBarContainer);

const mapStateToProps = ({ children, intelligence_progress, intelligences }, ownProps) => {
  const { params } = ownProps.match;
  const { topic } = params;

  const intelligence = getIntelligenceBySlug({ intelligences }, topic);
  const currentChild = getActiveChild({ children });
  const currentChildProgress = getAllIntelligenceProgressByChild({ intelligence_progress }, currentChild.id);
  const currentIntelligenceProgress = getIntelligenceProgressById({ intelligence_progress: currentChildProgress }, intelligence.id);

  return {
    child_id: currentChild.id,
    currentIntelligenceProgress
  };
};

const mapDispatchToProps = (dispatch) => ({ dispatch });

const RadialProgressBarContainerConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(RadialProgressBarContainerComposed);

export default withRouter(RadialProgressBarContainerConnected);
