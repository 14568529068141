/* @flow */

import React from 'react';
import { Form  } from 'reactstrap';
import { compose, pure } from 'recompose';
import { Field, reduxForm } from 'redux-form';
import { Button, InputField, SubmitButton,Dropdown } from './';
import Translations from '../hocs/TranslationsHelper';
import type { Level, ReduxFormFunction, ReduxFormFlag } from './types';
import { required } from './validations';
import SchoolLevelBooksField from './SchoolLevelBooksField';
import LicenseFilter from '../containers/LicenseFilter';

type Props = {
  level?: Level,
  onDeleteClick: () => mixed,
  persisted: boolean,
  handleSubmit: ReduxFormFunction,
  submitting: ReduxFormFlag,
  valid: ReduxFormFlag,
  dirty: ReduxFormFlag,
  submitting: ReduxFormFlag,
  translations: Object
};

/**
 * component to display a form to edit/create a level info
 *
 * @method LevelDataForm
 * @param  {function}  handleSubmit     - injected by `redux-form`, handles form submition
 * @param  {boolean}   submitting       - injected by `redux-form`, indicates if the form is submitting
 * @param  {boolean}   valid            - injected by `redux-form`, indicates if the form is valid
 * @param  {boolean}   valid            - injected by `redux-form`, indicates if the form have been edited
 * @param  {Object}    translations     - object containing app translations
 */
const LevelDataForm = ({ handleSubmit, onDeleteClick, persisted, submitting, valid, dirty, translations }: Props) => {
  const deleteButton = (
    <Button type="primary--grapefruit" onClickButton={onDeleteClick}>
      {translations.t('delete')}
    </Button>
  );

  return (
    <Form className="form level-data-form" onSubmit={handleSubmit}>
      <Field
        name="level.name"
        placeholder={translations.t('name')}
        label={translations.t('level_name')}
        component={InputField}
        validate={[ required ]}
      />
 
      <LicenseFilter filter={["pleiq_cx", "pleiq_cx_plus"]}>  
        <Field
          name="level.caligrafix_book_ids"
          placeholder="Some Level"
          label={translations.t('level_books')}
          component={SchoolLevelBooksField} 
        />
      </LicenseFilter>
      <div className="form-actions">
        <SubmitButton loading={submitting} className="btn btn-primary--green" disabled={!dirty || !valid}>
          {translations.t('save')}
        </SubmitButton>
        {persisted && deleteButton}
      </div>
    </Form>
  );
};

const LevelDataFormComposed = compose(
  pure
)(LevelDataForm);

export const FORM_NAME = 'levelForm';

export default reduxForm({
  form: FORM_NAME,
  enableReinitialize: true
})(Translations(LevelDataFormComposed));
