/* @flow */

import React from 'react';
import { Field } from 'redux-form';
import { compose, pure } from 'recompose';
import { Button, InputField } from './';
import Translations from '../hocs/TranslationsHelper';
import { required } from './validations';
import type { TranslationsObject, ReduxFormFlag, ReduxFormFieldsArray } from './types';

type Props = {
  fields: ReduxFormFieldsArray,
  submitting: ReduxFormFlag,
  onRemoveButtonClick: () => mixed,
  translations: TranslationsObject
};

const CoursesFieldArray = ({ fields, meta, onRemoveButtonClick, translations }: Props) => {
  const { submitting } = meta;
  return (
    <div className="course-inputs">
      {fields.map((name, index, fields) => (
        <div className="course-inputs-group" key={index}>
          <div className="course-inputs-input">
            <Field
              className=""
              name={`${name}.name`}
              placeholder={translations.t('name')}
              component={InputField}
              validate={[ required ]}
            />
          </div>
          <Button type="ball" onClickButton={() => onRemoveButtonClick(fields.get(index), index)} disabled={submitting}>
            <span className="pleiq-icon-icon_minus" />
          </Button>
        </div>
      ))}
      <Button onClickButton={() => fields.push({})} type="secondary" className="course-inputs-add-button" disabled={submitting} block>
        <span className="btn-icon pleiq-icon-icon_plus" />
        {translations.t('add_new_course')}
      </Button>
    </div>
  );
};

const CoursesFieldArrayComposed = compose(
  pure
)(CoursesFieldArray);

export default Translations(CoursesFieldArrayComposed);
