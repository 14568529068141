import { Component, Children, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PubSub from '../models/PubSub';
import Translations from '../../locales/Translations';
import { useLocation, withRouter } from 'react-router';

import queryString from 'query-string';
export class TranslationsProvider extends Component {
  static childContextTypes = {
    translations: PropTypes.func.isRequired
  }

  getChildContext() {
    /*
      Everytime the props or state change we will update
      the translations by updating the stringHandler within the
      Translations class
     */
    Translations.loadTranslations(this.props.language);

    /*
      Send the "Translations" class as an immutable entity in the
      child context to be able to get the last locale within the children
     */
    return { translations: Translations };
  }

  componentDidUpdate(prevProps) {
    /*
      Update the elements with translations only if the language
      has actually changed.
     */
    if (this.props.language !== prevProps.language) {
      PubSub.trigger();
    }
  }

  render() {
    // `Children.only` enables us not to add a <div /> for nothing
    return Children.only(this.props.children);
  }
}

const mapStateToProps = (state, ownProps) => {
  const { location } = ownProps ;
  const { language } = state;
  const { lang } = queryString.parse( location.search);
  return {
    language: (lang || language)
  };
};
 
export default withRouter(  connect(
  mapStateToProps
)(TranslationsProvider) );
