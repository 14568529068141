import jsPDF from 'jspdf';
/**
 * screen for the school SchoolUniqueAccount
 *
 * @method SchoolUniqueAccount
 *
 */
export const genPdfMarkdown = async (md, title) => {

  const doc = new jsPDF();
  let first = 20;
  const SPACE_LEFT = 20;
  const FONT = 'arial';
  var width = doc.internal.pageSize.getWidth();
  var height = doc.internal.pageSize.getHeight();

  const _addPage = () => {
    doc.addPage();
    first = 20;
  };
  const _text = (text, size, line, bold = false) => {
    if (bold)
      doc.setFont(FONT, 'bold');
    if (size)
      doc.setFontSize(size);

    var splitText = doc.splitTextToSize(text, width - SPACE_LEFT * 2);
    if(first+ (splitText.length* line) >= height){
        _addPage();
    }
    for (let s of splitText) {

      doc.text(s, SPACE_LEFT, first);
      first += line;
    }
    if (bold)
      doc.setFont(FONT, 'normal');

  };
  const _writeH1 = (text) => {
    _text(text, 18, 9, true);
  };

  const _writeH2 = (text) => {
    _text(text, 16, 8, true);
  };
  const _writeH3 = (text) => {
    _text(text, 14, 7, true);
  };

  const _writeBold = (text) => {
    doc.setFont(FONT, 'bold');
    _text(text, null, 5);

    doc.setFont(FONT, 'normal');
  };

  const _writeItalic = (text) => {
    doc.setFont(FONT, 'italic');
    _text(text, null, 5);

    doc.setFont(FONT, 'normal');
  };

  const _writeText = (text) => {
    _text(text, 12, 5);
  };

  for (let part of md.split('\n')) {
    var start = part.search(/\S/);
    var r = part.substr(start);
    if (part === '---') {
      _addPage();
    }
    else if (part.startsWith('# ')) {
      _writeH1(part.replace('# ', ''));
    }
    else if (part.startsWith('## ')) {
      _writeH2(part.replace('## ', ''));
    }
    else if (part.startsWith('### ')) {
      _writeH3(part.replace('### ', ''));
    }
    else if (part.startsWith('__') && part.endsWith('__')) {
      _writeBold(part.replace(/__/gm, ''));
    }
    else if (part.startsWith('_') && part.endsWith('_')) {
      _writeItalic(part.replace(/_/gm, ''));
    }
    else {
      _writeText(part);
    }
  }
  await doc.save(title + ".pdf");

};
