/* @flow */

import React from 'react';
import { Paper, InfoTooltip, InputAddon } from '../components';
import { compose, lifecycle, shouldUpdate } from 'recompose';
import { connect } from 'react-redux';
import {
  SchoolDashboardNavContainer, SchoolDashboardHeadingContainer,
  HeaderContainer, SchoolGeneralIntelligenceProgressContainer,
  DataDisplayGeneralSchoolContainer,
  SchoolGeneralCoursesProgressContainer,
  PageTitleContainer
} from '../containers'; 
import { Col, Row, Modal } from 'reactstrap';
import Translations from '../hocs/TranslationsHelper';


import { PdfData } from '../../pdfer/Pdfable';
import { SchoolReportPdf } from '../pdfview/SchoolView';
import { withRouter } from 'react-router';
import LessonPlansContainer from '../containers/activity-bank/LessonPlansContainer';
import LessonPlanDataContainer from '../containers/activity-bank/LessonPlanDataContainer';
import FilterBarContainer from '../containers/activity-bank/FilterBar';
import { push } from 'react-router-redux';
import { SearchInput } from '../components';
import topScroll from '../hocs/topScroll';

/**
 * screen for the school Progress
 *
 * @method ActivityBank
 *
 */
const ActivityBank = ({ translations, match,location, dispatch }) => {

  topScroll();
  
  const { params } = match;
  const { kind } = params;
  
  const query= new URLSearchParams(location.search); 

  const search = query.get("search") ||"";
  const filter = query.get("filter")  || "";
  const current = query.get("current")  ;
  //<input type="text" value={search} onChange={(e)=>{dispatch(push(`/lesson_plans/${kind}?search=${encodeURIComponent(e.target.value)}&&filter=${encodeURIComponent(filter)}`))}} placeholder="search"/>  
              

  return (
    <div id="school-dashboard" className="school-dashboard layout-base">
      <PageTitleContainer pageTitle={"ab_"+kind+"_title"} />
      <HeaderContainer />
      <SchoolDashboardHeadingContainer /> 
      <Paper.Container lead>
  
        <Paper.Nav className="school-nav"> 
          {
            [ "planification", "orientation",].map((k)=>(

              <Paper.NavItem
                key={k}
                onClick={() => {dispatch(push(`/lesson_plans/${k}`))} }
                active={k === kind}   
                label={translations.t("ab_" + k+"_title")}
                hideLabelSm
              /> 

            ))

          } 
          <InputAddon 
            iconClass="pleiq-icon-icon_search"    
            placeholder={translations.t('ab_search')} 
            onChange={(e)=>{dispatch(push(`/lesson_plans/${kind}?search=${encodeURIComponent(e.target.value)}&&filter=${encodeURIComponent(filter)}`))}}
            value={search}
          /> 
        </Paper.Nav>
        <Row>
          <Col xs="12">
            <Paper.Title> 
                <h1>
                  { translations.t("ab_"+kind+"_title") }{' '}
                  <InfoTooltip message={"ab_"+kind+"_tooltip"} />
                </h1> 
            </Paper.Title>
          </Col> 
            <Paper.Content  className="expand-width"> 
              <div className="ab-header">

                <FilterBarContainer  kind={kind} filter ={filter} onFilterChange={(new_filter)=>{
                  
                  dispatch(push(`/lesson_plans/${kind}?search=${encodeURIComponent(search)}&&filter=${encodeURIComponent(new_filter)}`))
                }} />
              </div>
              <LessonPlansContainer search={search} filter ={filter} kind={kind} onSelect={(c)=>{
                dispatch(push(`/lesson_plans/${kind}?search=${encodeURIComponent(search)}&&filter=${encodeURIComponent(filter)}&&current=${c}`)) 
              }}/>
            </Paper.Content> 
        </Row>
      </Paper.Container>
      {
        (current)?<LessonPlanDataContainer kind={kind} id={current} onClose={()=>dispatch(push(`/lesson_plans/${kind}?search=${encodeURIComponent(search)}&&filter=${encodeURIComponent(filter)}`))}/> : null
      }
    </div>
  );
};

export default connect(()=>({}), (dispatch)=>({dispatch}))(Translations(withRouter(  
  
   ActivityBank 
)));
