/* @flow */

import _ from 'lodash';

export const getCourseStudents = ({ students }: Object, course_id: string) => (_.filter(students, { course_id: course_id }));

export const getAllStudentsButFromCourse = (state: Object, course: Object) => {
  const filteredStudents = _.filter(state.students, student => student.course_id !== course.id);
  return filteredStudents;
};

export const getStudentById = ({ students }, student_id) => (_.find(students, { id: student_id }));

/**
 * As the search for students has a different reducer, we might need to find
 * a student across both reducers, for example to show student progress,
 * we need a valid student id in the store.
 */
export const findStudentAcrossReducers = ({ students, students_search }, student_id) => {
  const studentsSearch = _.get(students_search, 'students', []);
  return getStudentById({ students }, student_id) || getStudentById({ students: studentsSearch }, student_id);
};
