/* @flow */

import React from 'react';
import { connect } from 'react-redux';
import { pure, compose, withHandlers } from 'recompose';
import { UserPasswordNewForm } from '../components';
import { startSubmit } from 'redux-form';
import { FORM_NAME } from '../components/UserPasswordNewForm';
import { requestUserResetPasswordEmail } from '../../actions/user';
import type { ReduxFunction, ReduxFormFunction, RecomposeHandler } from '../components/types';

type Props = {
  dispatch: ReduxFunction,
  onSubmit: RecomposeHandler,
  handleSubmit: ReduxFormFunction,
  queryParams?: Object
};

/**
 * Container that will handle the logic to allow a user to request a password reset email
 *
 * @method UserPasswordNewFormContainer
 *
 */
const UserPasswordNewFormContainer = ({ onSubmit, dispatch, queryParams }: Props) => {
  return <UserPasswordNewForm onSubmit={onSubmit} />;
};

export const UserPasswordNewFormContainerHandlers = {
  onSubmit: (props: Props) => ({ email }: Object) => {
    const { dispatch } = props;

    const payload = {
      email
    };

    dispatch(startSubmit(FORM_NAME));
    dispatch(requestUserResetPasswordEmail(payload));
  }
};

const UserPasswordNewFormContainerEnhancements = compose(
  withHandlers(UserPasswordNewFormContainerHandlers)
);

const mapDispatchToProps = (dispatch) => {
  return { dispatch };
};

const UserPasswordNewFormContainerComposed = compose(
  UserPasswordNewFormContainerEnhancements,
  pure
)(UserPasswordNewFormContainer);

const UserPasswordNewFormContainerConnected = connect(
  null,
  mapDispatchToProps
)(UserPasswordNewFormContainerComposed);

export default UserPasswordNewFormContainerConnected;
