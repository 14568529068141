/* @flow */

import React from 'react';
import { GeneralIntelligenceProgressContainer } from '../';
import actions from '../../../actions';
import { getActiveChild } from '../../../selectors/children';
import { getAllIntelligenceProgressByChild } from '../../../selectors/intelligenceProgress';
import { pure, compose, lifecycle, withState, shouldUpdate } from 'recompose';
import { connect, useDispatch } from 'react-redux'
import { Table, Nav, NavItem,  Row, Col } from 'reactstrap';
import { deleteSchoolUser } from '../../../actions/schoolUserInvitations';
import DataRetriever, { invalidateRetriever, useData } from '../../hocs/DataRetriever';
import { NavLink, Link } from 'react-router-dom';
import { Modal, Paper, InfoTooltip, Button } from '../../components';
import SchoolUserFormContainer from './SchoolUserFormContainer';
import { push } from 'react-router-redux';
import TranslationsHelper  from '../../hocs/TranslationsHelper';
import _ from 'lodash';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap'
import deepEqualsUpdate from '../../../helpers/deepEqualsUpdate';
import { getCourseById } from '../../../selectors/courses';
import SchoolUserContainer from "./SchoolUserContainer"
import classNames from 'classnames';
/**
 * Container that receives the data to display all the progress
 * over each of the Intelligences
 * @method IntelligencesProgressContainer
 * @param  {array}               currentChildProgress - injected by Redux includes all intelligence_progress from the store for the current child
 * @param  {array}               intelligences - injected by Redux includes all intelligences from the store
 */
function ColumHeader({id,column,direction}){
  return <th></th>
}


export const SchoolUsersNav = 
TranslationsHelper( 
  connect(()=>({}), (dispatch)=>({dispatch}))( 
    ({role, dispatch, translations})=> ( 
      <div className="scrollable intelligences-nav">
        <div className="container-scrollable-outer">
          <div className="container-scrollable-inner d-flex align-items-center  ">
            {
              ['coordinator', 'teacher', 'representative', 'observer'].map((slug) => {
                
                const active = slug === role ;
                const intelligenceClassName = classNames(
                  'intelligences-nav-item-collapse',
                  'd-inline-block',
                  { 'intelligences-nav-item--active': active }
                );

                return (
                  <div
                    key={slug} 
                    onClick={
                      ()=> dispatch(push(  `/school/users?role=${slug}` )) 
                    }
                    className={intelligenceClassName}>
                    { translations.t("role_"+ slug) }
                  </div>
                );
              }
              ) 
            }
          </div>
        </div>
      </div> )
  )
);

var SchoolUsersContainer = ({  role,page, column, direction ,translations}) => {
   
  const dispatch = useDispatch();

  const {data  , loading} = useData({id: "school_users_list", url: `/school/users?role=${role}&&page=${page}&&column=${column}&&direction=${direction}`});
   
  
  if(!data)return null;
  
  const {status,  body, errors} = data;
  if(!body)return null; 

  if(loading || !body) return <span>loading</span> ;
  if( status>=400)return <span>error</span>;
 // const {body} = payload;  
  let t_pages = 10;
  const fields = ["first_name", "email"];
  if(role=='representative')
    fields.push( "user_children");
  else if(role=='teacher')
    fields.push( "courses");
  //body.total_pages = 40;

  const page_min = Math.max(1, Math.min(  page-t_pages/2, body.total_pages-t_pages)); 
  return ( 
    <div>
          <Table className="expand" >
              <tr>
                <th></th>
                { 
                  fields.map(//,  "created_at"
                    (c)=>( 
                      <th key={c} className={'school-users-header-'+ c}>
                        <Link to={`/school/users?role=${role}&&page=${page}&&column=${c}&&direction=${(c==column)?((direction=="asc")?"desc":"asc"): direction}`}>
                          <span> {translations.t(c)}</span>

                          {(c==column)?((direction=="asc")?<span>&#9650;</span>:<span>&#9660;</span>):""} 
                        </Link>
                      </th>

                      ) 
                  )


                } 
                <th></th>
              </tr>
              {
                  body.data.map(({id, user_name,courses,children , role, created_at, email}, index)=>(
                      <tr key={id} className="collapse-header-base" >
                          <td>
                            {1+ index + (page-1)*8}.
                          </td>

                          <td> 
                            {user_name}
                          </td>
                          <td> 
                            {email}
                          </td> 
                          <td> 
                            {courses&& courses.map(({id,name},index)=><span key={id}>
                              {index>0 && ', '}{name}
                            </span>)}
                            {children&& children.map(({id,name}, index)=><span key={id}>
                              {index>0 && ', '}{name} 
                            </span>)}
                          </td> 
                         {
                         /*  <td> 
                            {created_at} 
                          </td>*/}
                          <td>

                              <Button type="edit" className="collapse-header-edit-button" onClickButton={
                                ()=>dispatch(push(  `/school/users?role=${role}&&page=${page}&&column=${column}&&direction=${direction}&&editing=${id}`))

                                }>
                                {translations.t('edit')}
                              </Button>
                              {/* <Button type="primary--grapefruit" onClick={(e)=>{onDestroy(id); e.stopPropagation();}}>X</Button> */}
                          </td>
                      </tr>
                  ))
              }
            </Table>
            {

                (body.total_pages>1) &&<Pagination aria-label="Page navigation example">
                  {/* <PaginationItem>
                    <PaginationLink first href={`/school/users?role=${role}&&page=${1}&&column=${column}&&direction=${ direction }`} />
                  </PaginationItem>
                  <PaginationItem disabled={ (page == 1) }>
                    <PaginationLink previous href={`/school/users?role=${role}&&page=${page-1}&&column=${column}&&direction=${ direction }`} />
                  </PaginationItem> */}
                  {

                    (page_min>1) &&   <PaginationItem  >
                          <PaginationLink href={`/school/users?role=${role}&&page=${1}&&column=${column}&&direction=${ direction }`}>
                            { 1}
                          </PaginationLink>
                        </PaginationItem> 
                  }
                  {

                    (page_min>1) &&  <PaginationItem className="page-divisor">...</PaginationItem>   
                  }

                {
                  _.range(Math.min(body.total_pages,t_pages)).map((i)=>(

                    <PaginationItem disabled={i+ page_min > body.total_pages} active={i+page_min ==  page}>
                      <PaginationLink href={`/school/users?role=${role}&&page=${i + page_min}&&column=${column}&&direction=${ direction }`}>
                        { (i+ page_min > body.total_pages)?".": (i + page_min)}
                      </PaginationLink>
                    </PaginationItem>
                  ))
                }
                {

                  (page_min + t_pages < body.total_pages) && <PaginationItem className="page-divisor" >...</PaginationItem>   
                }
                {

                  (page_min + t_pages < body.total_pages) && <PaginationItem active={page  ==  body.total_pages}>
                      <PaginationLink href={`/school/users?role=${role}&&page=${body.total_pages}&&column=${column}&&direction=${ direction }`}>
                        { body.total_pages}
                      </PaginationLink>
                    </PaginationItem>  
                }
                  {/* <PaginationItem>
                    <PaginationLink next href="#" />
                  </PaginationItem>
                  <PaginationItem>
                    <PaginationLink last href="#" />
                  </PaginationItem> */}
                </Pagination>
              
            }
          </div>
      );
};
export const SchoolUsersConnected =  TranslationsHelper(SchoolUsersContainer);
 /*
export const SchoolUsersConnected = connect((state, {role, page, column, direction})=>({
  id:"school_users",
  dataUrl: `/school/users?role=${role}&&page=${page}&&column=${column}&&direction=${direction}`,
}), (dispatch, {role, page, column, direction})=>({
  clickUser: (id)=>{
    dispatch(push(  `/school/users?role=${role}&&page=${page}&&column=${column}&&direction=${direction}&&editing=${id}`))
  }  
}))( TranslationsHelper(  DataRetriever(SchoolUsersContainer) ));
*/

export const SchoolUsersNewButton = TranslationsHelper(  connect(()=>({}), (dispatch, {role, page, column, direction})=>({onClick: ()=>
  dispatch(push(  `/school/users?role=${role}&&page=${page}&&column=${column}&&direction=${direction}&&editing=NEW`))}))
(
  ({role,onClick, translations})=>(

    <Button onClick={ onClick}> 
      <span className="courses-actions-icon pleiq-icon-icon_plus" />
      {translations.t("new_user_"+role)}
    </Button>

  )

));

let SchoolUsersContainerConnected  = ({courses,translations,dispatch, queryParams, showModal,setShowModal})=>{ 
  const role = queryParams['role'] || 'coordinator';
  const page = queryParams['page'] || 1;
  const sort_column = queryParams['column'] || 'created_at';
  const sort_dir = queryParams['direction'] || 'asc';
  const user_id = queryParams['editing']
  return    <Row> 
                <Col xs="12"> 
                  <div className="scrollable intelligences-nav">
                    <div className="container-scrollable-outer">
                      <div className="container-scrollable-inner d-flex align-items-center  ">
                        {
                          ['coordinator', 'teacher', 'representative', 'observer'].map((slug) => {
                            
                            const active = slug === role ;
                            const intelligenceClassName = classNames(
                              'intelligences-nav-item-collapse',
                              'd-inline-block',
                              { 'intelligences-nav-item--active': active }
                            );

                            return (
                              <div
                                key={slug} 
                                onClick={
                                  ()=> dispatch(push(  `/school/users?role=${slug}` ))
                                    
                                }
                                className={intelligenceClassName}>
                                { translations.t("role_"+ slug) }
                              </div>
                            );
                          }
                          ) 
                        }
                      </div>
                    </div>
                  </div>
                </Col>
      {/* < Nav className="admin-users-nav" >
        {
          ['coordinator', 'teacher', 'representative'].map(
            (r)=>(
              <NavItem className={ (role===r)?"active":""}>
                <NavLink to={`/school/users?role=${r}`}>{translations.t("role_"+ r)}</NavLink>
              </NavItem> 
            )
          )
        } 
      </Nav>      */}
        <Col xs="12"> 
        <Button onClick={()=>setShowModal(true)}>{translations.t("new_user_"+role)}</Button>
       </Col>
      <SchoolUsersContainer    page={page} column={sort_column} direction={sort_dir}
                                role={role} 
                                onDestroy={(id)=>{
                                  dispatch(actions.genericDataRequest('delete_school_user',`/school/users/${id}`,'delete', null, 
                                  (r)=>{
                                    invalidateRetriever("school_users")
                                  } ))
                                }}
                                clickUser={(id)=>{
                                  dispatch(push(  `/school/users?role=${role}&&page=${page}&&column=${sort_column}&&direction=${sort_dir}&&editing=${id}`))
                                }}
                                />
        {
          user_id && <SchoolUserContainer user_id={user_id} closeModal = {()=>{

                                  dispatch(push( `/school/users?role=${role}&&page=${page}&&column=${sort_column}&&direction=${sort_dir}`))
          }}/>
        }
        <Modal
            backdropClassName="overlay-backdrop"
            contentClassName="overlay-modal-content"
            active={showModal} 
            >
              <div className="confirmation-modal">
                <span className="modal-close pleiq-icon-icon_plus" onClick={()=>setShowModal(false)} />
                <Paper.Container>
                  <Row>
                    <Col xs="12">
                      <Paper.Title>
                        <h1>{ translations.t("new_"+ role)}</h1>
                      </Paper.Title>
                      <span>{ translations.t("we_will_send_email_new_user" )} </span>
                    </Col>
                    <Col xs="12">
                      <Paper.Nav>
                        <Paper.NavItem iconClass="pleiq-icon-icon_intelligence_intrapersonal" label={translations.t( role+"_data")} />
                      </Paper.Nav>
                      <SchoolUserFormContainer  role={role} closeModal={()=>setShowModal(false)} /> 
              
                    </Col>
                  </Row>
                    
                </Paper.Container>
              </div>
              
        </Modal>  
        </Row>
   
   
}
export const SchoolUsersContainerLifecycle = {
  componentWillMount() {
    this.props.dispatch( {type: "REQUEST_ALL_COURSES"}) ;
  }, 
};
SchoolUsersContainerConnected = compose(  
  withState("showModal", "setShowModal", false),
  lifecycle(SchoolUsersContainerLifecycle)
)(SchoolUsersContainerConnected);
SchoolUsersContainerConnected = connect(({courses})=>({courses}), (dispatch)=>({dispatch}))(SchoolUsersContainerConnected);

SchoolUsersContainerConnected = TranslationsHelper(SchoolUsersContainerConnected);

export default SchoolUsersContainerConnected ;
 