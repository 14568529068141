import React, { useState } from 'react';
import { Form, FormGroup, FormFeedback } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { SubmitButton } from '../../../components';
import TranslationsHelper from '../../../hocs/TranslationsHelper';
import classNames from 'classnames';
import { required } from '../../../components/validations';
import { times } from 'lodash';
import { coreMap, oaMap } from '../PlanificationValuesMap';
import { PlanificationField } from './PlanificationField';
export let PlanificationFormCL = ({ error, fields, handleSubmit, persisted, submitting, valid, dirty, change, translations }) => {


  const formClassName = classNames({ 'has-danger': error }, 'form', "student-data-form");

  const validCores = useState([]);
  const validTargets = useState([]);

  return (
    <Form className={formClassName} onSubmit={handleSubmit}>

      <div>
        {error &&
          <FormGroup>
            <FormFeedback>{translations.t(error)}</FormFeedback>
          </FormGroup>}

        <div className="d-flex flex-column">

          <Field
            name="education_level"
            placeholder={"Edad"}
            selectOptions={[
              { value: '3', label: "3 años" },
              { value: '4', label: "4 años" },
              { value: '5', label: "5 años" },
              { value: '6', label: "6 años" },
              { value: '7', label: "7 años" },
            ]}
            validate={[required]}
            desc="Elige una Edad"
            component={PlanificationField}
            disabled={submitting} />
          <Field
            name="expertice_scope"
            placeholder={"Ambito de Experiencia"}
            selectOptions={[
              { value: 'Desarrollo Personal y Social', label: 'Desarrollo Personal y Social' },
              { value: 'Comunicación integral', label: 'Comunicación integral' },
              { value: 'Interacción y comprensión del entorno', label: 'Interacción y comprensión del entorno' },
            ]}
            desc="Elige un Ambito"
            validate={[required]}
            onChange={(e, value) => {
              change("education_core", null);
              change("education_target", null);
              validCores[1](coreMap[value]);
              validTargets[1]([]);
              console.log(value);

            }}
            component={PlanificationField}
            disabled={submitting} />
          <Field
            name="education_core"
            placeholder={"Núcleo de Aprendizaje"}
            selectOptions={validCores[0].map((id) => (
              { value: id, label: id }
            ))}
            onChange={(e, value) => {
              change("education_target", null);
              validTargets[1](times(oaMap[value], (n) => ({ label: "OA" + (n + 1), value: '' + (n + 1) })));
              console.log(value);

            }}
            validate={[required]}
            component={PlanificationField}
            desc="Elige un Núcleo"
            disabled={submitting} />
          <Field
            name="education_target"
            placeholder={"Objetivo de Aprendizaje"}
            selectOptions={validTargets[0]}
            validate={[required]}
            component={PlanificationField}
            desc="Elige un Objetivo"
            disabled={submitting} />
          <Field
            name="activity_time"
            placeholder={"Tiempo de la Actividad"}
            selectOptions={[
              { value: '15', label: '15min' },
              { value: '30', label: '30min' },
              { value: '45', label: '45min' },
            ]}
            validate={[required]}
            component={PlanificationField}
            desc="Elige una duración"
            disabled={submitting} />
          <Field
            name="complexity"
            placeholder={"Nivel de Complejidad"}
            selectOptions={[
              { value: 'Baja', label: 'Bajo' },
              { value: 'Media', label: 'Medio' },
              { value: 'Alta', label: 'Alto' },
            ]}
            validate={[required]}
            component={PlanificationField}
            desc="Elige un Nivel"
            disabled={submitting} />
          <SubmitButton loading={submitting} className="btn-primary--green gpt-planification-button align-self-center" disabled={!valid}>

            <span className="pleiq-icon-icon_play" />
          </SubmitButton>
        </div>
        {persisted}
      </div>
    </Form>
  );
};
export const FORM_NAME_CL = 'schoolPlanificationGeneratorCL';
PlanificationFormCL = reduxForm({
  form: FORM_NAME_CL,
})(TranslationsHelper(PlanificationFormCL));
