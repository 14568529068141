/* @flow */

import React from 'react';
import { pure, compose, lifecycle } from 'recompose';
import actions from '../../actions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { SchoolTimelineProgressContainer } from './';
import moment from 'moment';
import Translations from '../hocs/TranslationsHelper';
import { getIntelligenceBySlug } from '../../selectors/intelligences';
import { getSchoolGeneralIntelligenceHistory } from '../../selectors/schoolProgress';
import { withPdf } from '../../pdfer/Pdfable';
import { SchoolTimelineProgressContainerTranslated } from './SchoolTimelineProgressContainer';

const SchoolGeneralTimelineProgressContainer = ({ data, intelligence_id, dispatch }: Props) => {
  return (
    <SchoolTimelineProgressContainer data={data} dispatchGetSchoolHistory={dispatchSchoolGeneralIntelligenceHistory} intelligence_id={intelligence_id} dispatch={dispatch} />
  );
};

const getDate = (option) => {
  const format = 'YYYY-MM-DD';

  switch (option) {
    case 'month':
      return moment().subtract(5, 'months').format(format);
    case 'week':
      return moment().subtract(3, 'weeks').format(format);
    case 'day':
      return moment().subtract(6, 'days').format(format);
    default:
      return moment().add(1, 'day').format(format);
  }
};

const dispatchSchoolGeneralIntelligenceHistory = ({ intelligence_id, dispatch, selectedOption }) => {
  const groupBy = selectedOption || 'day';
  const start_date = getDate(groupBy);
  const end_date = getDate();

  if (intelligence_id) {
    dispatch(actions.getSchoolGeneralIntelligenceHistory({
      intelligence_id,
      start_date,
      end_date,
      group_by: groupBy
    }));
  }
};

export const ContainerLifecycle = {
  componentDidUpdate(prevProps) {
    const lastParam = prevProps.match.params.slug;
    const nextParam = this.props.match.params.slug;

    if (lastParam !== nextParam) {
      dispatchSchoolGeneralIntelligenceHistory(this.props);
    }
  },
  componentDidMount() {
    dispatchSchoolGeneralIntelligenceHistory(this.props);
  }
};

const ContainerTranslated = Translations(SchoolGeneralTimelineProgressContainer);

const ContainerComposed = compose(
  lifecycle(ContainerLifecycle),
  pure
)(ContainerTranslated);

const mapStateToProps = ({ school_general_intelligence_history, intelligences }, ownProps) => {
  const { params } = ownProps.match;
  const { slug } = params;

  const intelligence = getIntelligenceBySlug({ intelligences }, slug);

  const data = getSchoolGeneralIntelligenceHistory({ history: school_general_intelligence_history }, intelligence.id);

  return {
    intelligence_id: intelligence.id,
    data
  };
};

const mapDispatchToProps = (dispatch) => ({ dispatch });

const ContainerConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(ContainerComposed);

export default withRouter(ContainerConnected);
  
export var SchoolGeneralTimelineProgressContainerPdf = connect(mapStateToProps ,mapDispatchToProps )(  withPdf ({path:"int.hist", filter: "challenges_report"
, pre:(props)=>{ 
  dispatchSchoolGeneralIntelligenceHistory({...props, "selectedOption":"month"}); 
  return true;
 // }
  //return false;
}, props:{newpage:true}  })( ({ data, intelligence_id, dispatch }: Props) => {
  return (
    <SchoolTimelineProgressContainerTranslated selectedOption="month" data={data} dispatchGetSchoolHistory={dispatchSchoolGeneralIntelligenceHistory} intelligence_id={intelligence_id} dispatch={dispatch} />
  );
})); 