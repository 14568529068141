/* @flow */

import {call, take,  fork, select, put, takeEvery, all } from 'redux-saga/effects';
import { workerRequest , workerResponseHandler} from '../diplomatico/workers';
import { stopSubmit, reset } from 'redux-form';
import { FORM_NAME as DIRECTOR_FORM_NAME } from '../js/components/DirectorIEForm';
import type { Action } from '../diplomatico/types';
import _ from 'lodash'; 
import { request } from '../diplomatico/actions/request';

/**
 * Worker to handle the login process
 */

export const workerRequestDirectorCreateOptions = (action) => ({
  requestParams: {
    endpoint: '/schools',
    method: 'post',
  },
  
  requestHandlers: {
    onSuccess: function* ({ action, response }: Object): any {
      // yield put(reset(STUDENT_COMMENT_FORM));
      // yield put(stopSubmit(STUDENT_COMMENT_FORM));
    },
    onError: function* (error: any): any {
      console.log(error,'error')
      // yield put(stopSubmit(STUDENT_COMMENT_FORM));
    }
  },

  buildRequestBody: (actionPayload: Object) => (action.payload)
});

export function* workerRequestDirectorCreate(action: Action): any {
  // let { student_id } = action.payload;
  console.log(action, '<-- action')
  let workerRequestGenerator = workerRequest(workerRequestDirectorCreateOptions(action));
  console.log(workerRequestGenerator, '<-- workerRequestGenerator')
  yield call(workerRequestGenerator, action);
  // return false;
} 

export function* watchDirectorRequest(): any {
  yield all([
    takeEvery('REQUEST_DIRECTOR_CREATE', workerRequestDirectorCreate),
  ]);
}
