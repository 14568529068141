/* @flow */

import React from 'react';
import { GeneralCaligrafixBookProgressContainer } from './';
import { pure, compose, lifecycle } from 'recompose';
import { getAllCaligrafixBookProgressByChild } from '../../selectors/caligrafixBookProgress';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import actions from '../../actions';
import { findStudentAcrossReducers } from '../../selectors/students';
import _ from 'lodash';
import { getCourseById } from '../../selectors/courses';
import { getLevelById } from '../../selectors/levels';
import { dataNeedsUpdate } from '../../helpers/intelligences';

/**
 * Container that receives the data to display all the progress
 * over each of the CaligrafixBooks
 * @method StudentCaligrafixBookProgressContainer
 * @param  {array}               currentStudentProgress - injected by Redux includes all caligrafix_book_progress from the store for the current child
 * @param  {array}               caligrafix_books - injected by Redux includes all caligrafix_books from the store
 */
export const StudentCaligrafixBookProgressContainer = ({user_books, currentStudentProgress, caligrafix_books }) => {

    //console.log("rendering caligrafix book progress student", currentStudentProgress, caligrafix_books);
  return (
    <GeneralCaligrafixBookProgressContainer user_books={user_books} currentProgress={currentStudentProgress} caligrafixBooks={caligrafix_books} />
  );
};

export const StudentCaligrafixBookProgressContainerLifecycle = {
  componentDidMount() {
    const { dispatch, student_id } = this.props;

    if (student_id) {
      dispatch(actions.getStudentCaligrafixBookProgress({ student_id }));
    }
  }
};

const StudentCaligrafixBookProgressContainerComposed = compose(
  lifecycle(StudentCaligrafixBookProgressContainerLifecycle),
  pure
)(StudentCaligrafixBookProgressContainer);

const mapStateToProps = (state, ownProps) => {
  const { user,  student_caligrafix_book_progress  } = state;
  const { params } = ownProps.match;
  const { student_id } = params;

  const student = findStudentAcrossReducers(state, student_id);
  const currentStudentProgress = getAllCaligrafixBookProgressByChild({ caligrafix_book_progress: student_caligrafix_book_progress }, student_id);

  let course = getCourseById(state, _.get(student, 'course_id'));
  let level = getLevelById(state, _.get(course, 'level_id'));
  let  caligrafix_book_ids  =  _.get(level,"caligrafix_book_ids") || [];

  let caligrafix_books = state.caligrafix_books;
  if(level && caligrafix_book_ids.length>0){
    caligrafix_books = state.caligrafix_books.filter(b=>  caligrafix_book_ids .includes(b.id));
  }

  return {
    student_id: _.get(student, 'id'),
    currentStudentProgress,
    caligrafix_books, 
    user_books: user.caligrafix_books 
  }
};

const mapDispatchToProps = (dispatch) => ({ dispatch });

const CaligrafixBookProgressContainerConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(StudentCaligrafixBookProgressContainerComposed);

export const StudentGeneralCaligrafixBooksStatic = ({student_id})=>{
  return <CaligrafixBookProgressContainerConnected match={{params: {student_id}}}/>;
}
export default withRouter(CaligrafixBookProgressContainerConnected);

/*
let esr = 0;

export const StudentGeneralCaligrafixBooksPdf = connect(({}, props)=>{return {...props, esr: esr++};},mapDispatchToProps)( withPdf({pre:({dispatch,student_id})=>{ 
  dispatch(actions.getStudentCaligrafixBookProgress({ student_id }));
  return true;
}}, ({student_id})=>{
  return <CaligrafixBookProgressContainerConnected match={{params: {student_id}}}/>;
}) );*/