import React from 'react';
import { Form,FormFeedback,FormGroup } from 'reactstrap';
import { compose, pure, withState } from 'recompose';
import { reduxForm, Field } from 'redux-form';
import { Button, InputFieldSelect, SubmitButton, UserChildForm } from './';
import Translations from '../hocs/TranslationsHelper';
import type { Student, ReduxFormFunction, TranslationsObject, ReduxFormFlag } from './types';
import classNames from 'classnames';
import iconBlue from "../../img/ui_icon_comment_small.svg";
import iconEdit from "../../img/ui_icon_pencil.svg";
import iconDelete from "../../img/ui_icon_trash.svg";
import RoleFilter from '../containers/RoleFilter';

const StudentCommentsHeader = ({onCreateNew}) => {
    return  <div>
        <h1>Comments: </h1>
    </div>; 
}

let StudentComment = ({content,date, canEdit,user, isStatic,editing, setEditing, text,  setText,   onEdit,  onDelete, translations})=>{
 
    const save = (e)=>{ 
        setEditing(false);
        onEdit(text); 
    }
    return <div className={"student-comment"+ ((isStatic)?" statik":"")}>
        <div className={"student-comment-box"+ ((isStatic)?" statik":"")}>
            <div className="student-comment-box-icon">
                <img src={iconBlue}/>
            </div> 
            <div className="student-comment-box-data">
                <div className="student-comment-box-data-content">
                    
                    {(editing)?(
                         <form onSubmit={(e)=>{ e.preventDefault(); save();}}  > 

                            <textarea type="text" value={text} rows={5} onKeyDown={(e)=>{
                                if(e.keyCode===13){ 
                                    e.preventDefault(); save();
                                } 
                            }} onChange={(e)=>setText(e.target.value)} ref={(ta)=>{
                                if(ta)
                                    ta.focus();
                            }}/>
                            <div className= "student-comment-actions down">
                                <button type="submit" className="left">{translations.t("save")}</button>
                                <button onClick={(e)=>{e.preventDefault();setEditing(false);}} className="right">{translations.t("cancel")}</button>
                            </div>
                        </form>
                    
                    ) :content}
                </div>
                <div className="student-comment-box-data-date">
                    {(new Date(date)).toLocaleDateString()} - {user}
                </div>
            </div>
        </div>{
            (!canEdit || editing || isStatic)?null
            :( 
                <div className="student-comment-actions"> 
                    <button className="left" onClick={(e)=>{
                        setText(content);
                        setEditing(true);
                    }}> 
                        <img src={iconEdit}/>
                        <span>
                            {translations.t("edit")}
                        </span>
                    </button> 
                    <button className="right" onClick={onDelete}> 
                        <img src={iconDelete}/>
                        <span>{translations.t("delete")}</span>
                    </button> 
                </div> 
            )
        } 
    </div>;
} 
StudentComment = compose(withState("editing", "setEditing", false),
withState("text", "setText", ""))(StudentComment);
StudentComment = Translations(StudentComment);



 
const StudentCommentsList = ({comments,user,  onDelete, onEdit, isStatic})=>{
    return  (<div ref={(tg)=>{
        if(tg)
            tg.scrollTop = tg.scrollHeight;
    }}>
            {
                (comments!==undefined)?(comments.map(({id,user_name, user_id, content,created_at})=>(
                    <StudentComment canEdit={ (user.role =='coordinator') || (user.role=='teacher' && user.id == user_id) } key={id} user={user_name} isStatic={isStatic} content={content} date={created_at} onDelete={()=>onDelete(id)} onEdit={(nt)=>onEdit(id, nt)}/>
                ))): null
            }
    </div>) ;
}



export default StudentCommentsList;

