import React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { withRouter } from 'react-router-dom';
import { findStudentAcrossReducers } from '../../selectors/students';
import { lifecycle, compose, pure } from 'recompose';
import Translations from '../hocs/TranslationsHelper';
import { addAlert } from '../../actions/alerts';
import _ from 'lodash';

/**
 * @class StudentProgressNavContainer
 * @param {string}      name               - Name of the student
 * @param {Object}      translations       - Translations object injected from the Translations HoC
 */
const StudentProgressNameContainer = ({ name, translations }) => <h1>{translations.t('student_progress_name', { name })}</h1>;

export const ContainerLifecycle = {
  componentDidMount() {
    const { name, dispatch } = this.props;

    if (!name) {
      dispatch(push('/school/courses'));
      dispatch(addAlert({ message: 'student_not_found_message', type: 'error' }));
    }
  }
};

const ContainerComposed = compose(
  lifecycle(ContainerLifecycle),
  pure
)(Translations(StudentProgressNameContainer));

const mapStateToProps = (state, ownProps) => {
  const { params } = ownProps.match;
  const { student_id } = params;

  const student = findStudentAcrossReducers(state, student_id);

  return {
    name: _.get(student, 'name')
  }
};

const ContainerConnected = connect(
  mapStateToProps
)(ContainerComposed);

export default withRouter(ContainerConnected);
