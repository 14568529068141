/* @flow */

import React from 'react';
import { Field } from 'redux-form';
import { required, minLength } from './validations';
import { Button, InputField } from './';
import ProfileAvatar from './ProfileAvatar';
import { formValues } from 'redux-form';
import type { Child, TranslationsObject } from './types';
import { MIN_CHILDREN } from './ChildrenController';
import Translations from '../hocs/TranslationsHelper';
import _ from 'lodash';

type InputProfileItemProps = {
  fieldName: string,
  color: string,
  onAvatarClick: () => mixed,
  translations: TranslationsObject
};

const InputProfileItem = Translations(({ fieldName, color, onAvatarClick, translations }: InputProfileItemProps) => {
  return (
    <div className="form-input-profile">
      <Field
        type="text"
        name={fieldName}
        placeholder={translations.t('name')}
        component={InputField}
        validate={[ required, minLength(2) ]}
      />
      <ProfileAvatar color={color} onClick={onAvatarClick} />
    </div>
  );
});

type InputProfileGroupProps = {
  fieldName: string,
  color: string,
  onAvatarClick: () => mixed,
  onRemoveButtonClick: () => mixed,
  childIndex: number,
  children: Array<Child>
};

function getComputedColor(children, student, childIndex) {
  const child = student || children[childIndex];
  const computedColor = _.get(child, 'color');
  return computedColor;
}

/**
 * @method InputProfileGroup
 * @param  {string}    fieldName               - name of the field
 * @param  {number}    childIndex              - index of the child that is being rendered
 * @param  {function}  onAvatarClick           - callback that gets fired when the avatar gets clicked
 * @param  {function}  onRemoveButtonClick     - callback that gets fired when the minus button gets clicked
 * @param  {Array}     children                - an array of the children form the form, injected by redux-form using formValues. needed to render the avatar with the right color
 */
const InputProfileGroup = ({ fieldName, submitting, childToDelete, childIndex, selectedColor, studentMode, onAvatarClick, onRemoveButtonClick, children, student }: InputProfileGroupProps) => {
  const computedColor = getComputedColor(children, student, childIndex);
  const child = student || children[childIndex];

  const removeChildButton = (
    <Button type="ball" onClickButton={() => onRemoveButtonClick(childToDelete, childIndex)} disabled={submitting}>
      <span className="pleiq-icon-icon_minus" />
    </Button>
  );

  /* To show the remove button we check for these rules
   * * We always show it when in studentMode
   * * We always show it for non persisted children
   * * We always show it if the persisted children count > 1
   * * We don't show it for persisted child when the persisted children count is less or equal than MIN_CHILDREN
   */
  const showRemoveButton = (child, children, studentMode) => {
    if (_.isNil(child) || studentMode) {
      return true;
    }

    // if no children are available, this means this is the only one
    if (_.isEmpty(children)) {
      return false;
    }

    const persisted = !!child.id;
    const persistedChildrenCount = _.sumBy(children, (child) => +!!child.id);

    if (persistedChildrenCount <= MIN_CHILDREN && persisted) {
      return false;
    }

    return true
  }

  return (
    <div className="profile-holder">
      <InputProfileItem fieldName={fieldName} color={computedColor || selectedColor} onAvatarClick={onAvatarClick} />
      {showRemoveButton(child, children, studentMode) && removeChildButton}
    </div>
  );
};

const InputProfile = {
  // we need to access the children color on this component to render the avatar
  Group: formValues('children', 'student')(InputProfileGroup),
  Item: InputProfileItem
};

export default InputProfile;
