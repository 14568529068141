/* @flow */

import React, { useState, useEffect, useRef } from 'react'; 
import { Col, Row, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Form, FormGroup, FormFeedback,Label } from 'reactstrap'; 
import { Field, reduxForm, stopSubmit, initialize } from 'redux-form';
import { Modal, Paper, InfoTooltip, Button, InputFieldSelect, SubmitButton, ConfirmationModal } from '../../components';
import {
  SchoolDashboardNavContainer, SchoolDashboardHeadingContainer, HeaderContainer,
  PageTitleContainer
} from '../../containers';

import TranslationsHelper, { useTranslations } from '../../hocs/TranslationsHelper';
import topScroll from '../../hocs/topScroll'; 
import { useApi } from '../../../api_request';
import imageBook from '../../../img/activity_bank/ui_visual_indicator_book.png';
import { useDispatch, useSelector } from 'react-redux';
import ReactMarkdown from 'react-markdown'; 
import downloadIcon from "../../../img/activity_bank/icon_download_transparent.svg";
 
import { SimpleToolTip } from '../../containers/activity-bank/SimpleToolTip'; 
import { genPdfMarkdown } from './genPdfMarkdown';
import { FilterBar } from '../../components/FilterBar'; 
import { allFiltersCL } from './PlanificationValuesMap'; 
import { fieldDatas } from './fieldDatas';
/**
 * screen for the school SchoolUniqueAccount
 *
 * @method SchoolUniqueAccount
 *
 */
  
const colorMaps = {
  "MX": {
    "ef_1": "lin",
    "ef_2": "mat",
    "ef_3": "nat",
    "ef_4": "kin",
  } ,

  "CL_EN": {
    "es_1": "lin",
    "es_2": "mat",
    "es_3": "nat" 
  } ,

  "CL": {
    "es_1": "lin",
    "es_2": "mat",
    "es_3": "nat" 
  } ,
  "CL_EB": {
    "es_1": "lin",
    "es_2": "mat",
    "es_3": "nat" 
  } ,

  "US_FL": {
    "dom_1": "lin",
    "dom_2": "mat",
    "dom_3": "kin",
    "dom_4": "nat",
    "dom_5": "mus",
    "dom_6": "vis",
    "dom_7": "inter",
    "dom_8": "intra"  

  }
}

const PlanificationDisplay = ({p, onClose, onDelete})=>{

  const api = useApi(); 
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState(null);
  const translations = useTranslations();
  
  useEffect(()=>{
    (async()=>{
      try{ 
         console.log("LOADING:::");
      setLoading(true);

      const response = await api.get("/ai/generated_planifications/"+ p.id);

      console.log(response);
      if(response.status >=400)
        throw "failed";
      
      setData(response.data); 

    }catch(e){

      console.error(e);

      setError(true);
    }finally{
      setLoading(false);
    }})();

  }, [p])
  return  <div className="ab-pdf"> 

    <div className="ab-pdf"> 
      <div className="ab-pdf-back" onClick={(e)=>{e.stopPropagation();e.preventDefault();onClose();}}></div>
      <div className="rcr">
        <div className="ab-pdf-rect-plan">
          <div className="ab-pdf-rect-plan-header"> 
                <a className="ab-pdf-rect-plan-header-dbutton" id={"header-dbutton" } onClick={(e)=>{e.stopPropagation(); genPdfMarkdown(p.content, p.title);/*TODO:save*/}}>
                  <img src={downloadIcon}/> 
                </a>
                <div className="ab-pdf-rect-plan-header-nav">

                   

                </div>
                <button onClick={onClose} className="ab-pdf-rect-plan-header-x">X</button>
          </div>
          <div className="ab-pdf-rect-plan-content">
          {
            (loading)&& <span>{translations.t("ab_loading")}</span>
          }
         {
           (data) &&  <ReactMarkdown className= "gpt-chat-md" >{data.content}</ReactMarkdown>
         }
          </div>
          <div className="ab-pdf-rect-plan-buttons"> 
            <Button type="primary--grapefruit" onClick={onDelete}  >{translations.t("pg_p_delete_button")}</Button>
          </div>
        </div>           
      </div>

  </div>
</div>;

}

const PlanificationCard = ({title,colorMap, content,id,tags, onClick})=>{

  const planRef = useRef();

  const genPdf = ()=>{
    genPdfMarkdown(content, title);
  }
  var color = 'lin';
  for(let [key,value] of Object.entries(colorMap)){
    if(tags.includes(key)){
      color = value;
      break;
    }  }
  return <Col className="ab-card" >
                    
      <div className={`ab-card-graphic c-${color}`} onClick={ onClick  }  >
        <img className="ab-card-graphic-image" src={imageBook}/>
        <a className="ab-card-graphic-pdf-button"  id={"ab-pdf-button-"+id }  onClick={(e)=>{genPdf();e.stopPropagation()}}  >
          <img src={downloadIcon}/>
          <SimpleToolTip text="Descargar" target={"ab-pdf-button-"+id } />
        </a>
      </div>

      <label className="ab-card-title"> 
        {title}
      </label>  
      <div hidden><div  ref={planRef}><ReactMarkdown className= "gpt-chat-md" >{content}</ReactMarkdown></div></div>
      
      {
        /*
      <div className="ab-card-data">
        <label className="ab-card-data-title"> 
          {name}
        </label> 
      </div>*/
      }
  </Col> ;

}
export const GeneratedPlanifications = ({queryParams }) => {
 
  topScroll();
   const translations = useTranslations();
   const [loading, setLoading] = useState(false);
   const [error, setError] = useState(false);
   const [planifications, setPlanifications] = useState([]);
   const [cur, setCur] = useState(null);
   const [toDelete, setToDelete] = useState(false);
  const[ filter, setFilter] = useState({});

  const user = useSelector(({user})=> user) 
  const api = useApi(); 
  
  const location = queryParams.location || user.location;
 function tryDownload(){
    
    (async()=>{
      try{ 
      setLoading(true);

      const response = await api.get("/ai/generated_planifications");

      console.log(response);
      if(response.status >=400)
        throw "failed";
      
      setPlanifications(response.data); 

    }catch(e){

      console.error(e);

      setError(true);
    }finally{
      setLoading(false);
    }})();
  }

  useEffect(tryDownload, [])

/*
  let filter_cores = [];

  if(filter &&  filter['expertice_scope']  ){
    for(let f of filter['expertice_scope']){
      filter_cores.push(...(coreMap[f ] .map((id)=>( 
        { value:id, label: id } 
       ))) )
    }
  }
  let filter_oa = [];
  let maxOa = 0;
  if(filter &&  filter['education_core']  ){
    for(let f of filter['education_core']){
      maxOa = Math.max(maxOa, oaMap[f]  )
    }
  }
  
  const filters = [
    {
      id: "education_level",
      label:"Nivel Educativo",
      options: [
        { value: 3, label: "3 años" },  
        { value: 4, label: "4 años" },  
        { value: 5, label: "5 años" },  
        { value: 6, label: "6 años" },  
        { value: 7, label: "7 años" },    
      ]
    },
    {
      id: "expertice_scope",
      label:"Ambito de Experiencia",
      options: [
        { value: 'Desarrollo Personal y Social', label: 'Desarrollo Personal y Social' },  
        { value: 'Comunicación integral', label: 'Comunicación integral' },  
        { value: 'Interacción y comprensión del entorno', label: 'Interacción y comprensión del entorno' },  
      ]
    },
    {
      id: "education_core",
      label:"Núcleo de Aprendizaje",
      options: filter_cores
    },
    {
      id: "education_target",
      label:"Objetivo de Aprendizaje",
      options:filter_oa
    },
    {
      id: "activity_time",
      label:"Tiempo de la Actividad",
      options:[
        { value: 15, label: '15min' },    
        { value: 30, label: '30min' },   
        { value: 45, label: '45min' },            
      ]
    },
    {
      id: "complexity",
      label:"Nivel de Complejidad",
      options:[
        { value: 'Baja', label: 'Bajo' },    
        { value: 'Media', label: 'Medio' },   
        { value: 'Alta', label: 'Alto' },            
      ]
    }

  ];*/
  let filteredPlanifications = planifications;
  console.log(filter, filteredPlanifications);

  
  const usedFilterValues = new Set();
 
  for(let p of filteredPlanifications){ 
    for( let t of p.tags){
      usedFilterValues.add(t);
    }
  }

  for(let k of Object.keys(filter)){
    if(filter[k].length>0)
      filteredPlanifications = filteredPlanifications.filter( (p)=> {
        let included = false;
        for(let f of filter[k]){
          if(p.tags.includes(f)){
            included = true;
            break;
          }
        }
        return included;
      }); 
  }

  const colorMap = colorMaps[ location];
  
  const filters = fieldDatas[location].map(({id, label, fieldValues})=>{
    let options = [];
    let usedValues = new Set();
    (Array.isArray(fieldValues)? fieldValues: Object.values(fieldValues).flat(1)).filter(([v,l])=>usedFilterValues.has(v))
    .forEach(([value,label])=>{

      if(!usedValues.has(value)){
        usedValues.add(value);
        options.push({label, value});

      }
    })
    return {
      id,
      label,
      options 
    }
  });

  console.log("FILTERS: ", filters);
//
  return (
     <div id="school-dashboard" className="school-dashboard layout-base">
      <PageTitleContainer pageTitle={translations.t("school_planifications_library_title")} />

      {
          cur &&<PlanificationDisplay p={cur} onClose={()=>setCur(null)} onDelete={()=>{
            setToDelete(true);

          }}/>
      }
      <HeaderContainer />
      <SchoolDashboardHeadingContainer />
      <Paper.Container lead>  
        <div>
          <Row>
          <Col xs="12">
          <br/>
            <div className="d-flex">

              <Paper.Title className="flex-fill">
                  <h1 className="school-home-title">{translations.t("school_planifications_library_title")}</h1>  
                </Paper.Title> 
 
            </div>
          </Col>    
          </Row>
  
          <Row>
          <Col xs="12">
              <p>
              {translations.t("school_planifications_library_desc")}
                
              </p>
          </Col>
          </Row> 
            <Paper.Content  className="expand-width"> 
            </Paper.Content>
            <div className="ab-header">

              <FilterBar  filters ={filters} values={filter} onFilterChange={(new_filter)=>{
                 console.log(new_filter)
                 setFilter(new_filter);
              }} />
              </div>
            <Row className="cols-4  justify-content-md-center"  >
               {loading && <span>{translations.t("ab_loading")}</span>}

               {filteredPlanifications.map((p)=>(
                 <PlanificationCard key={p.id} onClick={()=>{
                    setCur(p);
                    console.log("cur selected")
                 }} colorMap ={colorMap} {...p} />
               ))} 
          </Row>
        </div>
      </Paper.Container>  
      <ConfirmationModal active={toDelete} title={translations.t("pg_p_delete_title")} message={translations.t("pg_p_delete_desc")}
        onConfirm={async ()=>{
          
            const response = await api.delete("/ai/generated_planifications/" + cur.id);

            console.log(response);
            if(response.status >=400)
              throw "failed";
            setCur(null);
            setToDelete(false);
            await tryDownload();
        }} onCancel={()=>{
          setToDelete(false);
        }}/>
    </div>
  );
};

export default  GeneratedPlanifications ;
