export function pdfPrintingChange(printing, props) {
    return {
      type: 'PDF_PRINTING_STATUS_CHANGE',
      printing,
      props
    };
  }
  export function pdfPrintingProgressChange(progress ) {
    return {
      type: 'PDF_PRINTING_PROGRESS_CHANGE',
      progress 
    };
  }
  