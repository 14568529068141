/* @flow */

import React, { useContext, useState } from 'react';
import { Col, Row  } from 'reactstrap';
import { Paper, InfoTooltip, Button } from '../../components';
import {
  DataDisplayParentContainer, IntelligencesTopicContainer, IntelligencesProgressContainer,
  ActivityFeedContainer, HeaderContainer, PageTitleContainer, SchoolDashboardNavContainer, SchoolDashboardHeadingContainer
} from '../../containers';
import '../../../css/main.css';
import Translations from '../../hocs/TranslationsHelper';
import TranslationsHelper from '../../hocs/TranslationsHelper';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'react-router-redux';
 
import resources from "../../../data/resources"
import { LIN_VIS_Read_Comprehensive_News } from '../../../locales/en';
import { Link } from 'react-router-dom';
import { navItemHome } from '../../components/SchoolDashboardNav';
import { ProgressReportTypeSelect } from '../../containers/progress-reports/ProgressReportTypeSelect';
import DataDropDown from '../../containers/progress-reports/DataDropDown';
import ProgressReportList from '../../containers/progress-reports/ProgressReportList';
import { useData } from '../../hocs/DataRetriever';
import SchoolProgressReportTypeDialog from '../../containers/progress-reports/SchoolProgressReportTypeDialog';
import ProgressReportForm, { ProgressReportEditForm, ProgressReportCmpForm } from '../../containers/progress-reports/ProgressReportForm';
 


const reportsUrl = (kind, student_id, course_id) => {
  let url = "/school/progress_reports";  
  if( kind!= "student" && kind!= "course" ){
    return url;
  }else{
    url+= "?kind="+ kind;
    if(kind =="student"){
      if(student_id) url+= "&&student_id="+ student_id;
    }else{
      if(course_id) url+= "&&course_id="+ course_id; 
    }

  }
  return url;
}
/**
 * Render SchoolProgressReportCmp screen
 * @method SchoolProgressReportCmp
 * @param  {object}                    translations - injected by our Translations HoC to give translated strings
 */
const SchoolProgressReportCmp = ({ translations , queryParams}) => {

  const [showDialog,setShowDialog] = useState(false);

  const user = useSelector(({user})=>user);
  const dispatch = useDispatch(); 
  const student_id = queryParams['student_id'] ;
  const course_id = queryParams['course_id'] ; 
 
 
  return (
    <div id="school-dashboard" className="school-dashboard layout-base">
    <PageTitleContainer pageTitle="progress_reports" />
    <HeaderContainer />
    <SchoolDashboardHeadingContainer />
    <Paper.Container lead>
      <SchoolDashboardNavContainer items={[navItemHome,{ 
          name: 'progress_reports',
          translation: 'progress_reports',
      }]} currentTab="progress_reports" hideSearch />
 
      <ProgressReportCmpForm  student_id={student_id} course_id={course_id}/>
         
    </Paper.Container> 
  </div>
  );
};

export default Translations(SchoolProgressReportCmp);
