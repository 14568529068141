/* @flow */

import type { Action } from '../diplomatico/types';
import { enableInitializing } from 'initializable-reducer';
import _ from 'lodash';

const initialState = [];

function alerts(state: Array<Object> = initialState, { type, payload }: Action) {
  switch (type) {
    case 'ALERT_ADD':
      if (_.findIndex(state, payload) >= 0) {
        return state;
      }

      return [...state, payload];
    case 'ALERT_DISMISS':
      let targetAlert = state[payload.index];

      if (!targetAlert.dismissible) {
        return state;
      }

      return [
        ...state.slice(0, payload.index),
        ...state.slice(payload.index + 1)
      ];
    default:
      return state;
  }
}

export {
  alerts,
  initialState
};

export default enableInitializing(alerts);