/* @flow */

import React from 'react';
import { Row, Col } from 'reactstrap';
import { RegistrationForm } from './';
import Button from './Button';
import { Link } from 'react-router-dom';
import type { TranslationsObject } from '../components/types';
import Translations from '../hocs/TranslationsHelper';
import _ from 'lodash';

type EmailConfirmationProps = {
  onClick: Function,
  translations: TranslationsObject
};

/**
 * Component to display step 1 sign up form social login (facebook, gmail)
 *
 * @method OAuth
 *
 */
const OAuth = ({ translations }) => {
  return (
    <RegistrationForm title={translations.t('oauth_form_title')} subtitle={translations.t('oauth_form_subtitle')}>
      <Button type="facebook" className="btn btn-block">
        Facebook
      </Button>
      <Button type="gmail" className="btn btn-block">
        Gmail
      </Button>
      <p className="text-center registration-form-footer-text">
        <a className="registration-form-anchor">
          {translations.t('oauth_form_with_email')}
        </a>
      </p>
    </RegistrationForm>
  );
};

/**
 * Component to display step 5 sign up form
 *
 * @method EmailConfirmation
 * @param  {Function}  onClick  - handles click on the resend email confirmation button
 *
 */
const EmailConfirmation = ({ onClick, translations }: EmailConfirmationProps) => {
  return (
    <RegistrationForm title={translations.t('email_confirmation_form_title')} subtitle={translations.t('email_confirmation_form_subtitle')} backButton>
      <Row>
        <Col>
          <Button type="secondary" onClick={onClick} className="btn-block">
            <span className="pleiq-icon-icon_reload registration-form-reload-icon" />
            {translations.t('email_confirmation_form_resend_email_button')}
          </Button>
        </Col>
        <Col>
          <Button type="primary" className="registration-form-next-button btn-block">
            {translations.t('continue')}
            <span className="registration-form-next-icon pleiq-icon-icon_arrow_right" />
          </Button>
        </Col>
      </Row>
    </RegistrationForm>
  );
};

/**
 * Component to display step 6 sign up form
 *
 * @method Congratulations
 *
 */
const Congratulations = ({ translations, onClick = _.noop }) => {
  return (
    <RegistrationForm title={translations.t('congratulations_form_title')} subtitle={translations.t('congratulations_form_subtitle')}>
      <Button type="primary" className="registration-form-next-button btn-block" onClick={onClick}>
        {translations.t('continue')}
        <span className="registration-form-next-icon pleiq-icon-icon_arrow_right" />
      </Button>
    </RegistrationForm>
  );
};

/**
 * Component to display step 6 sign up form
 *
 * @method AccountConfirmed
 *
 */
const AccountConfirmed = ({ translations }) => {
  return (
    <RegistrationForm title={translations.t('account_confirmed_form_title')} subtitle={translations.t('account_confirmed_form_subtitle')}>
      <Link className="registration-form-next-button btn-block btn btn-primary" to="/login">
        {translations.t('continue')}
        <span className="registration-form-next-icon pleiq-icon-icon_arrow_right" />
      </Link>
    </RegistrationForm>
  );
};

const SignUpForm = {
  Congratulations: Translations(Congratulations),
  AccountConfirmed: Translations(AccountConfirmed),
  EmailConfirmation: Translations(EmailConfirmation),
  OAuth: Translations(OAuth)
};

export default SignUpForm;
