/* @flow */

import type { Action } from '../diplomatico/types';
import { enableInitializing } from 'initializable-reducer';
import { responseHasError } from '../diplomatico/reducers/helper';

const initialState = {};

function uniqueAccount(state: Object = initialState, { type, payload }: Action) {
  switch (type) {
    case 'RESPONSE_UNIQUE_ACCOUNT':
      if (responseHasError(payload)) {
        return state;
      }

      return {
        ...state,
        email: payload.body.email,
        total_time: payload.body.total_time
      };
    case 'EDIT_UNIQUE_ACCOUNT_PASSWORD':
      return {
        ...state,
        edit_mode: true
      };
    case 'EXIT_UNIQUE_ACCOUNT_PASSWORD_EDIT':
      return {
        ...state,
        edit_mode: false
      };
    default:
      return state;
  }
}

export {
  uniqueAccount,
  initialState
};

export default enableInitializing(uniqueAccount);
