import React, {   useState } from 'react';
import { Form, FormGroup, Label, FormFeedback } from 'reactstrap';
import { Field, stopSubmit, reduxForm, initialize } from 'redux-form';
import { InputField, InputFieldSelect, SubmitButton, Paper } from '../../components';
import { useData, invalidateRetriever } from '../../hocs/DataRetriever';
import { useApi } from '../../../api_request';
import TranslationsHelper, { useTranslations } from '../../hocs/TranslationsHelper'; 
import { required, minLength } from '../../components/validations';
import { languages } from '../../components/UserInfoForm';
import { useDispatch, useSelector } from 'react-redux';
import { SchoolLogoField, validateLogoField } from '../SchoolLogoField';
import axios from 'axios';
import actions from '../../../actions';
import classNames from 'classnames';
const FORM_NAME = "SCHOOL_DATA_FORM";
let SchoolDataFormComponent = ({  error,  handleSubmit, submitting, dirty, valid,logo_url, translations, initialValues })=>{
    
    const formClassName = classNames({ 'has-danger': error }, 'form user-info-form form-limited-inputs');
    return <Form className={formClassName} onSubmit={handleSubmit}>
                  {
        error &&
        <FormGroup>
          <FormFeedback>{translations.t(error)}</FormFeedback>
        </FormGroup>
      }
    <Field 
        name="new_img"
        component={SchoolLogoField}
        validate={[validateLogoField]}
        logo_url={ logo_url}/>
    <Field
      type="text"
      name="name"
      placeholder={translations.t('school_name')}
      label={translations.t('school_name')}
      disabled={submitting}
      component={InputField}
      validate={[ required, minLength(2) ]}
    />    
    <FormGroup>
      <Label for="locale">{translations.t('language')}</Label>
      <Field
        name="locale"
        placeholder={translations.t('language')}
        selectOptions={languages(translations)}
        component={InputFieldSelect}
        disabled={submitting}
      />
    </FormGroup>
    <FormGroup>
      <Label for="language2">{translations.t('language2')}</Label>
      <Field
        name="locale2"
        placeholder={translations.t('language2')}
        selectOptions={languages(translations)}
        component={InputFieldSelect}
        disabled={submitting}
      />
    </FormGroup>
    <SubmitButton className="btn btn-primary--green" loading={submitting} disabled={!dirty || !valid}>
      {translations.t('save')}
    </SubmitButton>
  </Form>

}
SchoolDataFormComponent = TranslationsHelper(SchoolDataFormComponent);
SchoolDataFormComponent = reduxForm({
    form: FORM_NAME,
    enableReinitialize: true
})(SchoolDataFormComponent);

export const SchoolDataForm = ( )=>{
    const {logo_url   } = useSelector(({user})=>user);

    
    const data =  useData({id: "school_data_124T", url: 
    "/school/data" });
    const dispatch = useDispatch();
    const api = useApi();
    const translations = useTranslations();
    if(!data.data|| !data.data.body)return null;

    return <div>
      <Paper.Nav>
        <Paper.NavItem iconClass="pleiq-icon-icon_settings_user" label={ translations.t("school_data") } />
      </Paper.Nav>
      
        <SchoolDataFormComponent logo_url = {logo_url} initialValues={data.data.body} onSubmit={  async (values)=>{
            try{
                if(values.new_img){
                    const rpost = await api.post('/school/logo',null); 

                    if(rpost.status!=200  ){
                        throw "server_error";
                    }

                    const {formData,url, host, logo_url }= rpost.data;
                    const data = new FormData()
                    Object.entries(formData).forEach(([key,value])=>{
                        data.append(key, value);
                        
                   
                    });
                    data.append('file', values.new_img);
                    const response = await axios.post(url, data, {
                        headers: { 
                            "Content-type": "multipart/form-data"
                        }                 
                    });
                     
                    if(response.status >=400)
                      throw "upload_failed";

                    dispatch(actions.updateUserInfo({
                        body: {
                            logo_url: logo_url+ "?" + new Date().getTime()
                        }
                    }));
 
                    //await getPresignedPost();
                    //await uploadImageToS3();
                    delete values.new_img;
                }

                const sres = await api.put("/school/data", values);
                if(sres.status>=400)
                  throw "server_error";

                dispatch(actions.updateUserInfo({
                    body: {
                        name: values.name
                    }
                })); 
                invalidateRetriever("school_data_124T");
                dispatch(stopSubmit(FORM_NAME));
                dispatch(initialize(FORM_NAME, {...values }));
            }catch(e){

                dispatch(stopSubmit(FORM_NAME, { _error: 'error' }));
            }
        }}/>   
    </div>
    
} 

export default SchoolDataForm;