import { times } from 'lodash';
 
 

export const fieldDataCL_EB=   [
    {
      "id": "education_level",
      "label": "Edad",
      "desc": "Elige una Edad",
      "fieldValues": [ 
        [
          "el_6",
          "6 años"
        ] ,
        [
          "el_7",
          "7 años"
        ] ,
        [
          "el_8",
          "8 años"
        ] ,
        [
          "el_9",
          "9 años"
        ] ,
        [
          "el_10",
          "10 años"
        ] ,
      ]
    },

    {
        "id": "education_basic_level",
        "label": "Nivel Básico",
        "desc": "Elige un Nivel",
        "fieldValues": [
          [
            "ebl_1",
            "Primero Básico"
          ],  
          [
            "ebl_2",
            "Segundo Básico"
          ],  
          [
            "ebl_3",
            "Tercero Básico"
          ],  
          [
            "ebl_4",
            "Cuarto Básico"
          ],  
          [
            "ebl_5",
            "Quinto Básico"
          ],  
          [
            "ebl_6",
            "Sexto Básico"
          ],   
        ]
    }, 

    {
        "id": "education_axis",
        "label": "Eje",
        "desc": "Elige un Eje",
        "fieldValues": [
          [
            "eax_1",
            "Lectura"
          ], 
          [
            "eax_2",
            "Escritura"
          ], 
          [
            "eax_3",
            "Comunicación oral"
          ], 
        ]
    },

    {
        "constraints": [
          "education_axis",
          "education_basic_level"
        ],
        "id": "education_target",
        "label": "Objetivo de Aprendizaje",
        "desc": "Elige un Objetivo",
        "fieldValues": {
          "eax_1-ebl_1": [
            [
              "oa_1_1_1",
              "OA1 - Reconocer que los textos escritos transmiten mensajes y que son escritos por alguien para cumplir un propósito."
            ],
            [
              "oa_1_1_2",
              "OA2 C - Reconocer que las palabras son unidades de significado separadas por espacios en el texto escrito."
            ],
            [
              "oa_1_1_3",
              "OA3 - Identificar los sonidos que componen las palabras (conciencia fonológica), reconociendo, separando y combinando sus fonemas y sílabas."
            ],
            [
              "oa_1_1_4",
              "OA4 - Leer palabras aisladas y en contexto, aplicando su conocimiento de la correspondencia letra-sonido en diferentes combinaciones: sílaba directa, indirecta o compleja, y dígrafos rr-ll-ch-qu."
            ],
            [
              "oa_1_1_5",
              "OA5 - Leer textos breves en voz alta para adquirir fluidez:\n• pronunciando cada palabra con precisión, aunque se autocorrijan en algunas ocasiones\n• respetando el punto seguido y el punto aparte\n• leyendo palabra a palabra"
            ],
            [
              "oa_1_1_6",
              "OA6 C - Comprender textos, aplicando estrategias de comprensión lectora; por ejemplo: • relacionar la información del texto con sus experiencias y conocimientos • visualizar lo que describe el texto"
            ],
            [
              "oa_1_1_7",
              "OA7 C - Leer independientemente y familiarizarse con un amplio repertorio de literatura para aumentar su conocimiento del mundo y desarrollar su imaginación; por ejemplo: • poemas • historias breves • textos con una estructura predecible"
            ],
            [
              "oa_1_1_8",
              "OA8 - Demostrar comprensión de narraciones que aborden temas que les sean familiares:\n"+
              "• extrayendo información explícita e implícita\n"+
              "• respondiendo preguntas simples, oralmente o por escrito, sobre los textos (qué, quién, dónde, cuándo, por qué)\n"+
              "• recreando personajes a través de distintas expresiones artísticas, como títeres, dramatizaciones, dibujos o esculturas\n"+
              "• describiendo con sus palabras las ilustraciones del texto y relacionándolas con la historia\n"+
              "• estableciendo relaciones entre el texto y sus propias experiencias\n"+
              "• emitiendo una opinión sobre un aspecto de la lectura"
            ],
            [
              "oa_1_1_9" ,
              "OA9 - Leer habitualmente y disfrutar los mejores poemas de autor y de la tradición oral adecuados a su edad."
            ],
            [
              "oa_1_1_10",
              "OA10 - Leer independientemente y comprender textos no literarios escritos con oraciones simples (cartas, notas, instrucciones y artículos informativos) para entretenerse y ampliar su conocimiento del mundo:\n"+
              "• extrayendo información explícita e implícita\n"+
              "• formulando una opinión sobre algún aspecto de la lectura"
            ],
            [
              "oa_1_1_11",
              "OA11 - Desarrollar el gusto por la lectura, explorando libros y sus ilustraciones."
            ],
            [
              "oa_1_1_12",
              "OA12 C - Asistir habitualmente a la biblioteca para elegir, escuchar, leer y explorar textos de su interés."
            ],
            
          ] , 
          "eax_2-ebl_1":[

            [
              "oa_2_1_1",
              "OA13 - Experimentar con la escritura para comunicar hechos, ideas y sentimientos, entre otros."
            ],

            [
              "oa_2_1_2",
              "OA14 C - Escribir oraciones completas para transmitir mensajes."
            ],

            [
              "oa_2_1_3",
              "OA15 C - Escribir con letra clara, separando las palabras con un espacio, para que pueda ser leída por otros con facilidad."
            ],

            [
              "oa_2_1_4",
              "OA16 C - Incorporar de manera pertinente en la escritura el vocabulario nuevo extraído de textos escuchados o leídos."
            ],
          ],
          "eax_3-ebl_1":[

            [
              "oa_3_1_1",
              "OA17 C - Comprender y disfrutar versiones completas de obras de la literatura, narradas o leídas por un adulto, como:\n"+
              "• cuentos folclóricos y de autor\n"+ 
              "• poemas • fábulas • leyendas"
            ],
            [
              "oa_3_1_2",
              "OA18 - Comprender textos orales (explicaciones, instrucciones, relatos, anécdotas, etc.) para obtener información y desarrollar su curiosidad por el mundo:\n"+
              "• estableciendo conexiones con sus propias experiencias\n"+
              "• visualizando lo que se describe en el texto\n"+
              "• formulando preguntas para obtener información adicional y aclarar dudas\n"+
              "• respondiendo preguntas abiertas\n"+
              "• formulando una opinión sobre lo escuchado"
            ],
            [
              "oa_3_1_3",
              "OA19 C - Desarrollar la curiosidad por las palabras o expresiones que desconocen y adquirir el hábito de averiguar su significado."
            ],
            [
              "oa_3_1_4",
              "OA20 C - Disfrutar de la experiencia de asistir a obras de teatro infantiles o representaciones para ampliar sus posibilidades de expresión, desarrollar su creatividad y familiarizarse con el género."
            ],
            [
              "oa_3_1_5",
              "OA21 - Participar activamente en conversaciones grupales sobre textos leídos o escuchados en clases o temas de su interés:\n"+
              "• expresando sus ideas u opiniones\n"+
              "• demostrando interés ante lo escuchado\n"+
              "• respetando turnos"
            ],
            [
              "oa_3_1_6",
              "OA22 C - Interactuar de acuerdo con las convenciones sociales en diferentes situaciones: \n"+
              "• presentarse a sí mismo y a otros \n"+
              "• saludar\n"+
              "• preguntar\n"+
              "• expresar opiniones, sentimientos e ideas\n"+
              "• situaciones que requieren el uso de fórmulas de cortesía, como por favor, gracias, perdón, permiso"
            ],
            [
              "oa_3_1_7",
              "OA23 - Expresarse de manera coherente y articulada sobre temas de su interés:\n"+
              "• presentando información o narrando un evento relacionado con el tema\n"+
              "• incorporando frases descriptivas que ilustren lo dicho\n"+
              "• utilizando un vocabulario variado\n"+
              "• pronunciando adecuadamente y usando un volumen audible\n"+
              "• manteniendo una postura adecuada"
            ],
            [
              "oa_3_1_8",
              "OA24 C - Incorporar de manera pertinente en sus intervenciones orales el vocabulario nuevo extraído de textos escuchados o leídos."
            ],
            [
              "oa_3_1_9",
              "OA25 - Desempeñar diferentes roles para desarrollar su lenguaje y autoestima, y aprender a trabajar en equipo."
            ],
            [
              "oa_3_1_10",
              "OA26 C - Recitar con entonación y expresión poemas, rimas, canciones, trabalenguas y adivinanzas para fortalecer la confianza en sí mismos, aumentar el vocabulario y desarrollar su capacidad expresiva."
            ],

          ],
          "eax_1-ebl_2":[


            [
              "oa_1_2_1",
              "OA 1 - Leer textos significativos que incluyan palabras con hiatos y diptongos, con grupos consonánticos y con combinación ce-ci, que-qui, ge-gi, gue-gui, güe-güi."
            ],

            [
              "oa_1_2_2",
              "OA2 - Leer en voz alta para adquirir fluidez:\n"+
              "• pronunciando cada palabra con precisión, aunque se autocorrijan en contadas ocasiones\n"+
              "• respetando el punto seguido y el punto aparte\n"+
              "• sin detenerse en cada palabra"
            ],

            [
              "oa_1_2_3",
              "OA3 C - Comprender textos, aplicando estrategias de comprensión lectora; por ejemplo: \n"+
              "• relacionar la información del texto con sus experiencias y conocimientos\n"+
              "• visualizar lo que describe el texto \n"+
              "• hacer preguntas mientras se lee"
            ],

            [
              "oa_1_2_4",
              "OA4 C - Leer independientemente y familiarizarse con un amplio repertorio de literatura para aumentar su conocimiento del mundo y desarrollar su imaginación; por ejemplo:\n"+
              "• poemas\n"+ 
              "• cuentos folclóricos y de autor\n"+ 
              "• fábulas\n"+ 
              "• leyendas\n"+ 
              "• otros"
            ],

            [
              "oa_1_2_5",
              "OA5 -Demostrar comprensión de las narraciones leídas:\n"+
              "• extrayendo información explícita e implícita\n"+
              "• reconstruyendo la secuencia de las acciones en la historia\n"+
              "• identificando y describiendo las características físicas y sentimientos de los distintos personajes\n"+
              "• recreando, a través de distintas expresiones (dibujos, modelos tridimensionales u otras), el ambiente en el que ocurre la acción\n"+
              "• estableciendo relaciones entre el texto y sus propias experiencias\n"+
              "• emitiendo una opinión sobre un aspecto de la lectura"
            ],

            [
              "oa_1_2_6",
              "OA6 - Leer habitualmente y disfrutar los mejores poemas de autor y de la tradición oral adecuados a su edad."
            ],

            [
              "oa_1_2_7",
              "OA7 - Leer independientemente y comprender textos no literarios (cartas, notas, instrucciones y artículos informativos) para entretenerse y ampliar su conocimiento del mundo:\n"+
              "• extrayendo información explícita e implícita\n"+
              "• comprendiendo la información que aportan las ilustraciones y los símbolos a un texto\n"+
              "• formulando una opinión sobre algún aspecto de la lectura"
            ],

            [
              "oa_1_2_8",
              "OA8 - Desarrollar el gusto por la lectura, leyendo habitualmente diversos textos."
            ],

            [
              "oa_1_2_9",
              "OA9 C - Asistir habitualmente a la biblioteca para encontrar información y elegir libros, cuidando el material en favor del uso común."
            ],

            [
              "oa_1_2_10",
              "OA10 - Buscar información sobre un tema en una fuente dada por el docente (página de internet, sección del diario, capítulo de un libro, etc.), para llevar a cabo una investigación."
            ],

            [
              "oa_1_2_11",
              "OA11 C - Desarrollar la curiosidad por las palabras o expresiones que desconocen y adquirir el hábito de averiguar su significado."
            ],
          ],
          "eax_2-ebl_2":[

            [
              "oa_2_2_1",
              "OA12 - Escribir frecuentemente, para desarrollar la creatividad y expresar sus ideas, textos como poemas, diarios de vida, anécdotas, cartas, recados, etc."
            ],

            [
              "oa_2_2_2",
              "OA13 C - Escribir creativamente narraciones (experiencias personales, relatos de hechos, cuentos, etc.) que tengan inicio, desarrollo y desenlace."
            ],

            [
              "oa_2_2_3",
              "OA14 C - Escribir artículos informativos para comunicar información sobre un tema."
            ],

            [
              "oa_2_2_4",
              "OA15 C - Escribir con letra clara, separando las palabras con un espacio, para que pueda ser leída por otros con facilidad."
            ],

            [
              "oa_2_2_5",
              "OA16 - Planificar la escritura, generando ideas a partir de:\n"+
              "• observación de imágenes\n"+
              "• conversaciones con sus pares o el docente sobre experiencias personales y otros temas"
            ],

            [
              "oa_2_2_6",
              "OA17 - Escribir, revisar y editar sus textos para satisfacer un propósito y transmitir sus ideas con claridad.Durante este proceso:\n"+
              "• organizan las ideas en oraciones que comienzan con mayúscula y terminan con punto\n"+
              "• utilizan un vocabulario variado\n"+
              "• mejoran la redacción del texto a partir de sugerencias de los pares y el docente\n"+
              "• corrigen la concordancia de género y número, la ortografía y la presentación"
            ],

            [
              "oa_2_2_7",
              "OA18 C - Incorporar de manera pertinente en la escritura el vocabulario nuevo extraído de textos escuchados o leídos."
            ],

            [
              "oa_2_2_8",
              "OA19 C - Comprender la función de los artículos, sustantivos y adjetivos en textos orales y escritos, y reemplazarlos o combinarlos de diversas maneras para enriquecer o precisar sus producciones."
            ],

            [
              "oa_2_2_9",
              "OA20 C - Identificar el género y el número de las palabras para asegurar la concordancia en sus escritos."
            ],
            [
              "oa_2_2_10",
              "OA21 C - Escribir correctamente para facilitar la comprensión por parte del lector, usando de manera apropiada:\n"+
              "• combinaciones ce-ci, que-qui, ge-gi, gue-gui, güe-güi\n"+
              "• r-rr-nr \n"+
              "• mayúsculas al iniciar una oración y al escribir sustantivos propios\n"+
              "• punto al finalizar una oración\n"+
              "• signos de interrogación y exclamación al inicio y al final de preguntas y exclamaciones"
            ],

          ],
          "eax_3-ebl_2":[

            [
              "oa_3_2_1",
              "OA22 C - Comprender y disfrutar versiones completas de obras de la literatura, narradas o leídas por un adulto, como:\n"+
              "• cuentos folclóricos y de autor \n"+
              "• poemas\n"+
              "• fábulas\n"+
              "• leyendas"
            ],

            [
              "oa_3_2_2",
              "OA23 - Comprender textos orales (explicaciones, instrucciones, relatos, anécdotas, etc.) para obtener información y desarrollar su curiosidad por el mundo:\n"+
              "• estableciendo conexiones con sus propias experiencias\n"+
              "• identificando el propósito\n"+
              "• formulando preguntas para obtener información adicional y aclarar dudas\n"+
              "• respondiendo preguntas sobre información explícita e implícita\n"+
              "• formulando una opinión sobre lo escuchado"
            ],

            [
              "oa_3_2_3",
              "OA24 C - Disfrutar de la experiencia de asistir a obras de teatro infantiles o representaciones para ampliar sus posibilidades de expresión, desarrollar su creatividad y familiarizarse con el género."
            ],

            [
              "oa_3_2_4",
              "OA25 - Participar activamente en conversaciones grupales sobre textos leídos o escuchados en clases o temas de su interés:\n"+
              "• manteniendo el foco de la conversación\n"+
              "• expresando sus ideas u opiniones\n"+
              "• formulando preguntas para aclarar dudas\n"+
              "• demostrando interés ante lo escuchado\n"+
              "• mostrando empatía frente a situaciones expresadas por otros\n"+
              "• respetando turnos"
            ],

            [
              "oa_3_2_5",
              "OA26 C - Interactuar de acuerdo con las convenciones sociales en diferentes situaciones:\n"+
              "• presentarse a sí mismo y a otros\n"+
              "• saludar\n"+
              "• preguntar\n"+
              "• expresar opiniones, sentimientos e ideas\n"+ 
              "• situaciones que requieren el uso de fórmulas de cortesía, como por favor, gracias, perdón, permiso."
            ],

            [
              "oa_3_2_6",
              "OA27 - Expresarse de manera coherente y articulada sobre temas de su interés:\n"+
              "• presentando información o narrando un evento relacionado con el tema\n"+
              "• incorporando frases descriptivas que ilustren lo dicho\n"+
              "• utilizando un vocabulario variado\n"+
              "• pronunciando adecuadamente y usando un volumen audible\n"+
              "• manteniendo una postura adecuada"
            ],

            [
              "oa_3_2_7",
              "OA28 C - Incorporar de manera pertinente en sus intervenciones orales el vocabulario nuevo extraído de textos escuchados o leídos."
            ],

            [
              "oa_3_2_8",
              "OA29 - Desempeñar diferentes roles para desarrollar su lenguaje y autoestima, y aprender a trabajar en equipo."
            ],

            [
              "oa_3_2_9",
              "OA30 C - Recitar con entonación y expresión poemas, rimas, canciones, trabalenguas y adivinanzas para fortalecer la confianza en sí mismos, aumentar el vocabulario y desarrollar su capacidad expresiva."
            ],

          ],


          "eax_1-ebl_3":[

            [
              "oa_1_3_1",
              "OA1 - Leer en voz alta de manera fluida variados textos apropiados a su edad:\n"+
              "• pronunciando cada palabra con precisión\n"+
              "• respetando la coma, el punto y los signos de exclamación e interrogación\n"+
              "• leyendo con velocidad adecuada para el nivel"
            ],

            [
              "oa_1_3_2",
              "OA2 C - Comprender textos, aplicando estrategias de comprensión lectora; por ejemplo: \n"+
              "• relacionar la información del texto con sus experiencias y conocimientos \n"+
              "• releer lo que no fue comprendido \n"+
              "• visualizar lo que describe el texto \n"+
              "• recapitular \n"+
              "• formular preguntas sobre lo leído y responderlas\n"+
              "• subrayar información relevante en un texto."
            ],

            [
              "oa_1_3_3",
              "OA3 C - Leer y familiarizarse con un amplio repertorio de literatura para aumentar su conocimiento del mundo y desarrollar su imaginación; por ejemplo:\n"+
              "• poemas \n"+
              "• cuentos folclóricos y de autor\n"+
              "• fábulas • leyendas • mitos • novelas\n"+
              "• historietas • otros."
            ],

            [
              "oa_1_3_4",
              "OA4 - Profundizar su comprensión de las narraciones leídas:\n"+
              "• extrayendo información explícita e implícita\n"+
              "• reconstruyendo la secuencia de las acciones en la historia\n"+
              "• describiendo a los personajes\n"+
              "• describiendo el ambiente en que ocurre la acción\n"+
              "• expresando opiniones fundamentadas sobre hechos y situaciones del texto\n"+
              "• emitiendo una opinión sobre los personajes"
            ],

            [
              "oa_1_3_5",
              "OA5 -Comprender poemas adecuados al nivel e interpretar el lenguaje figurado presente en ellos."
            ],

            [
              "oa_1_3_6",
              "OA6 - Leer independientemente y comprender textos no literarios (cartas, biografías, relatos históricos, instrucciones, libros y artículos informativos, noticias, etc.) para ampliar su conocimiento del mundo y formarse una opinión:\n"+
              "• extrayendo información explícita e implícita\n"+
              "• utilizando los organizadores de textos expositivos (títulos, subtítulos, índice y glosario) para encontrar información específica\n"+
              "• comprendiendo la información que aportan las ilustraciones, símbolos y pictogramas a un texto\n"+
              "• formulando una opinión sobre algún aspecto de la lectura\n"+
              "• fundamentando su opinión con información del texto o sus conocimientos previos"
            ],

            [
              "oa_1_3_7",
              "OA7 - Desarrollar el gusto por la lectura, leyendo habitualmente diversos textos."
            ],

            [
              "oa_1_3_8",
              "OA8 C - Asistir habitualmente a la biblioteca para satisfacer diversos propósitos (encontrar información, elegir libros, estudiar o trabajar), cuidando el material en favor del uso común."
            ],

            [
              "oa_1_3_9",
              "OA9 - Buscar información sobre un tema en libros, internet, diarios, revistas, enciclopedias, atlas, etc., para llevar a cabo una investigación."
            ],

            [
              "oa_1_3_10",
              "OA10 C - Determinar el significado de palabras desconocidas, usando claves contextuales o el conocimiento de raíces (morfemas de base), prefijos y sufijos."
            ],

            [
              "oa_1_3_11",
              "OA11 C - Determinar el significado de palabras desconocidas, usando el orden alfabético para encontrarlas en un diccionario infantil o ilustrado."
            ],

          ],
          "eax_2-ebl_3":[

            [
              "oa_2_3_1",
              "OA12 - Escribir frecuentemente, para desarrollar la creatividad y expresar sus ideas, textos como poemas, diarios de vida, cuentos, anécdotas, cartas, comentarios sobre sus lecturas, etc."
            ],

            [
              "oa_2_3_2",
              "OA13 C - Escribir creativamente narraciones (experiencias personales, relatos de hechos, cuentos, etc.) que incluyan: • una secuencia lógica de eventos • inicio, desarrollo y desenlace • conectores adecuados"
            ],

            [
              "oa_2_3_3",
              "OA14 C - Escribir artículos informativos para comunicar información sobre un tema: \n"+
              "• organizando las ideas en párrafos \n"+
              "• desarrollando las ideas mediante información que explica el tema"
            ],

            [
              "oa_2_3_4",
              "OA15 C - Escribir cartas, instrucciones, afiches, reportes de una experiencia, entre otros, para lograr diferentes propósitos:\n"+
              " • usando un formato adecuado. \n"+
              "• transmitiendo el mensaje con claridad"
            ],

            [
              "oa_2_3_5",
              "OA16 C - Escribir con letra clara para que pueda ser leída por otros con facilidad."
            ],

            [
              "OA17 - Planificar la escritura:\n"+
              "• estableciendo propósito y destinatario\n"+
              "• generando ideas a partir de conversaciones, investigaciones, lluvia de ideas u otra estrategia"
            ],

            [
              "oa_2_3_7",
              "OA18 - Escribir, revisar y editar sus textos para satisfacer un propósito y transmitir sus ideas con claridad. Durante este proceso:\n"+
              "• organizan las ideas en párrafos separados con punto aparte\n"+
              "• utilizan conectores apropiados\n"+
              "• utilizan un vocabulario variado\n"+
              "• mejoran la redacción del texto a partir de sugerencias de los pares y el docente\n"+
              "• corrigen la ortografía y la presentación"
            ],

            [
              "oa_2_3_8",
              "OA19 C - Incorporar de manera pertinente en la escritura el vocabulario nuevo extraído de textos escuchados o leídos."
            ],

            [
              "oa_2_3_9",
              "OA20 C - Comprender la función de los artículos, sustantivos y adjetivos en textos orales y escritos, y reemplazarlos o combinarlos de diversas maneras para enriquecer o precisar sus producciones."
            ],

            [
              "oa_2_3_10",
              "OA21 C - Comprender la función de los pronombres en textos orales y escritos, y usarlos para ampliar las posibilidades de referirse a un sustantivo en sus producciones."
            ],

            [
              "oa_2_3_11",
              "OA22 C - Escribir correctamente para facilitar la comprensión por parte del lector, aplicando lo aprendido en años anteriores y usando de manera apropiada: \n"+
              "• mayúsculas al iniciar una oración y al escribir sustantivos propios \n"+
              "• punto al finalizar una oración y punto aparte al finalizar un párrafo \n"+
              "• plurales de palabras terminadas en z \n"+
              "• palabras con ge-gi, je-ji \n"+
              "• palabras terminadas en cito-cita • coma en enumeración."
            ],

          ],
          "eax_3-ebl_3":[

            [
              "oa_3_3_1",
              "OA23 C - Comprender y disfrutar versiones completas de obras de la literatura, narradas o leídas por un adulto, como: \n"+
              "• cuentos folclóricos y de autor \n"+
              "• poemas \n"+
              "• fábulas \n"+
              "• mitos y leyendas."
            ],

            [
              "oa_3_3_2",
              "OA24 - Comprender textos orales (explicaciones, instrucciones, noticias, documentales, películas, relatos, anécdotas, etc.) para obtener información y desarrollar su curiosidad por el mundo:\n"+
              "• estableciendo conexiones con sus propias experiencias\n"+
              "• identificando el propósito\n"+
              "• formulando preguntas para obtener información adicional, aclarar dudas y profundizar la comprensión\n"+
              "• estableciendo relaciones entre distintos textos\n"+
              "• respondiendo preguntas sobre información explícita e implícita\n"+
              "• formulando una opinión sobre lo escuchado"
            ],

            [
              "oa_3_3_3",
              "OA25 C - Disfrutar de la experiencia de asistir a obras de teatro infantiles o representaciones para ampliar sus posibilidades de expresión, desarrollar su creatividad y familiarizarse con el género."
            ],

            [
              "oa_3_3_4",
              "OA26 - Participar activamente en conversaciones grupales sobre textos leídos o escuchados en clases o temas de su interés:\n"+
              "• manteniendo el foco de la conversación\n"+
              "• expresando sus ideas u opiniones\n"+
              "• formulando preguntas para aclarar dudas\n"+
              "• demostrando interés ante lo escuchado\n"+
              "• mostrando empatía frente a situaciones expresadas por otros\n"+
              "• respetando turnos"
            ],

            [
              "oa_3_3_5",
              "OA27 C - Interactuar de acuerdo con las convenciones sociales en diferentes situaciones: \n"+
              "• presentarse a sí mismo y a otros \n"+
              "• saludar \n"+
              "• preguntar \n"+
              "• expresar opiniones, sentimientos e ideas \n"+
              "• situaciones que requieren el uso de fórmulas de cortesía, como por favor, gracias, perdón, permiso"
            ],

            [
              "oa_3_3_6",
              "OA28 - Expresarse de manera coherente y articulada sobre temas de su interés:\n"+
              "• organizando las ideas en introducción y desarrollo\n"+
              "• incorporando descripciones y ejemplos que ilustren las ideas\n"+
              "• utilizando un vocabulario variado\n"+
              "• reemplazando los pronombres por construcciones sintácticas que expliciten o describan al referente\n"+
              "• usando gestos y posturas acordes a la situación\n"+
              "• usando material de apoyo (power point, papelógrafo, objetos, etc.) si es pertinente"
            ],

            [
              "oa_3_3_7",
              "OA29 C - Incorporar de manera pertinente en sus intervenciones orales el vocabulario nuevo extraído de textos escuchados o leídos."
            ],

            [
              "oa_3_3_8",
              "OA30 - Caracterizar distintos personajes para desarrollar su lenguaje y autoestima, y aprender a trabajar en equipo."
            ],

            [
              "oa_3_3_9",
              "OA31 C - Recitar poemas con entonación y expresión para fortalecer la confianza en sí mismos, aumentar el vocabulario y desarrollar su capacidad expresiva."
            ],

          ],


          "eax_1-ebl_4":[

            [
              "oa_1_4_1",
              "OA 1 - Leer en voz alta de manera fluida variados textos apropiados a su edad:\n"+
              "• pronunciando las palabras con precisión\n"+
              "• respetando los signos de puntuación\n"+
              "• leyendo con entonación adecuada\n"+
              "• leyendo con velocidad adecuada para el nivel"
            ],
            [
              "oa_1_4_2",
              "OA2 C - Comprender textos, aplicando estrategias de comprensión lectora; por ejemplo: \n"+
              "• relacionar la información del texto con sus experiencias y conocimientos \n"+
              "• releer lo que no fue comprendido \n"+
              "• visualizar lo que describe el texto \n"+
              "• recapitular \n"+
              "• formular preguntas sobre lo leído y responderlas \n"+
              "• subrayar información relevante en un texto"
            ],
            [
              "oa_1_4_3",
              "OA3 C - Leer y familiarizarse con un amplio repertorio de literatura para aumentar su conocimiento del mundo y desarrollar su imaginación; por ejemplo: \n"+
              "• poemas \n"+
              "• cuentos folclóricos y de autor\n"+
              "• fábulas • leyendas\n"+
              "• mitos \n"+
              "• novelas \n"+
              "• historietas\n"+
              "• otros"
            ],
            [
              "oa_1_4_4",
              "OA4 - Profundizar su comprensión de las narraciones leídas:\n"+
              "• extrayendo información explícita e implícita\n"+
              "• determinando las consecuencias de hechos o acciones\n"+
              "• describiendo y comparando a los personajes\n"+
              "• describiendo los diferentes ambientes que aparecen en un texto\n"+
              "• reconociendo el problema y la solución en una narración\n"+
              "• expresando opiniones fundamentadas sobre actitudes y acciones de los personajes\n"+
              "• comparando diferentes textos escritos por un mismo autor"
            ],
            [
              "oa_1_4_5",
              "OA5 - Comprender poemas adecuados al nivel e interpretar el lenguaje figurado presente en ellos."
            ],
            [
              "oa_1_4_6",
              "OA6 - Leer independientemente y comprender textos no literarios (cartas, biografías, relatos históricos, instrucciones, libros y artículos informativos, noticias, etc.) para ampliar su conocimiento del mundo y formarse una opinión:\n"+
              "• extrayendo información explícita e implícita\n"+
              "• utilizando los organizadores de textos expositivos (títulos, subtítulos, índice y glosario) para encontrar información específica\n"+
              "• comprendiendo la información entregada por textos discontinuos, como imágenes, gráficos, tablas, mapas o diagramas\n"+
              "• interpretando expresiones en lenguaje figurado\n"+
              "• comparando información\n"+
              "• respondiendo preguntas como ¿por qué sucede?, ¿cuál es la consecuencia de?, ¿qué sucedería si…?\n"+
              "• formulando una opinión sobre algún aspecto de la lectura\n"+
              "• fundamentando su opinión con información del texto o sus conocimientos previos"
            ],
            [
              "oa_1_4_7",
              "OA7 - Desarrollar el gusto por la lectura, leyendo habitualmente diversos textos."
            ],
            [
              "oa_1_4_8",
              "OA8 C - Asistir habitualmente a la biblioteca para satisfacer diversos propósitos (encontrar información, elegir libros, estudiar, trabajar o investigar), cuidando el material en favor del uso común."
            ],
            [
              "oa_1_4_9",
              "OA9 - Buscar y clasificar información sobre un tema en internet, libros, diarios, revistas, enciclopedias, atlas, etc., para llevar a cabo una investigación."
            ],
            [
              "oa_1_4_10",
              "OA10 C - Aplicar estrategias para determinar el significado de palabras nuevas:\n"+
              "• claves del texto (para determinar qué acepción es pertinente según el contexto) \n"+
              "• raíces y afijos \n"+
              "• preguntar a otro \n"+
              "• diccionarios, enciclopedias e internet."
            ],

          ],
          "eax_2-ebl_4":[

            [
              "oa_2_4_1",
              "OA11 - Escribir frecuentemente, para desarrollar la creatividad y expresar sus ideas, textos como poemas, diarios de vida, cuentos, anécdotas, cartas, comentarios sobre sus lecturas, noticias, etc."
            ],
            [
              "oa_2_4_2",
              "OA12 C - Escribir creativamente narraciones (experiencias personales, relatos de hechos, cuentos, etc.) que incluyan: \n"+
              "• una secuencia lógica de eventos\n"+
              "• inicio, desarrollo y desenlace \n"+
              "• conectores adecuados \n"+
              "• descripciones \n"+
              "• un lenguaje expresivo para desarrollar la acción."
            ],
            [
              "oa_2_4_3",
              "OA13 C - Escribir artículos informativos para comunicar información sobre un tema: \n"+
              "• presentando el tema en una oración \n"+
              "• desarrollando una idea central por párrafo \n"+
              "• utilizando sus propias palabras."
            ],
            [
              "oa_2_4_4",
              "OA14 C - Escribir cartas, instrucciones, afiches, reportes de una experiencia o noticias, entre otros, para lograr diferentes propósitos:\n"+
              "• usando un formato adecuado \n"+
              "• transmitiendo el mensaje con claridad."
            ],
            [
              "oa_2_4_5",
              "OA15 C - Escribir con letra clara para que pueda ser leída por otros con facilidad."
            ],
            [
              "oa_2_4_6",
              "OA16 - Planificar la escritura:\n"+
              "• estableciendo propósito y destinatario\n"+
              "• generando ideas a partir de conversaciones, investigaciones, lluvia de ideas u otra estrategia"
            ],
            [
              "oa_2_4_7",
              "OA17 - Escribir, revisar y editar sus textos para satisfacer un propósito y transmitir sus ideas con claridad. Durante este proceso:\n"+
              "• organizan las ideas en párrafos separados con punto aparte\n"+
              "• utilizan conectores apropiados\n"+
              "• emplean un vocabulario preciso y variado\n"+
              "• adecuan el registro al propósito del texto y al destinatario\n"+
              "• mejoran la redacción del texto a partir de sugerencias de los pares y el docente\n"+
              "• corrigen la ortografía y la presentación"
            ],
            [
              "oa_2_4_8",
              "OA18 C - Incorporar de manera pertinente en la escritura el vocabulario nuevo extraído de textos escuchados o leídos."
            ],
            [
              "oa_2_4_9",
              "OA19 C - Comprender la función de los adverbios en textos orales y escritos, y reemplazarlos o combinarlos para enriquecer o precisar sus producciones."
            ],
            [
              "oa_2_4_10",
              "OA20 C - Comprender la función de los verbos en textos orales y escritos, y usarlos manteniendo la concordancia con el sujeto."
            ],
            [
              "oa_2_4_11",
              "OA21 - Escribir correctamente para facilitar la comprensión por parte del lector, aplicando todas las reglas de ortografía literal y puntual aprendidas en años anteriores, además de: \n"+
              "• palabras con b-v \n"+
              "• palabras con h de uso frecuente \n"+
              "• escritura de ay, hay, ahí\n"+
              "• acentuación de palabras agudas, graves, esdrújulas y sobreesdrújulas."
            ], 

          ],
          "eax_3-ebl_4":[ 
            [
              "oa_3_4_1",
              "OA22 C - Comprender y disfrutar versiones completas de obras de la literatura, narradas o leídas por un adulto, como: \n"+
              "• cuentos folclóricos y de autor \n"+
              "• poemas \n"+
              "• mitos y leyendas\n"+
              "• capítulos de novelas."
            ],
            [
              "oa_3_4_2",
              "OA23 - Comprender textos orales (explicaciones, instrucciones, noticias, documentales, películas, testimonios, relatos, etc.) para obtener información y desarrollar su curiosidad por el mundo:\n"+
              "• estableciendo conexiones con sus propias experiencias\n"+
              "• identificando el propósito\n"+
              "• formulando preguntas para obtener información adicional, aclarar dudas y profundizar la comprensión\n"+
              "• estableciendo relaciones entre distintos textos\n"+
              "• respondiendo preguntas sobre información explícita e implícita\n"+
              "• formulando una opinión sobre lo escuchado"
            ],
            [
              "oa_3_4_3",
              "OA24 C - Disfrutar de la experiencia de asistir a obras de teatro infantiles o representaciones para ampliar sus posibilidades de expresión, desarrollar su creatividad y familiarizarse con el género."
            ],
            [
              "oa_3_4_4",
              "OA25 - Participar activamente en conversaciones grupales sobre textos leídos o escuchados en clases o temas de su interés:\n"+
              "• manteniendo el foco de la conversación\n"+
              "• expresando sus ideas u opiniones y fundamentándolas\n"+
              "• formulando preguntas para aclarar dudas y verificar la comprensión\n"+
              "• demostrando interés ante lo escuchado\n"+
              "• mostrando empatía frente a situaciones expresadas por otros\n"+
              "• respetando turnos"
            ],
            [
              "oa_3_4_5",
              "OA26 C - Interactuar de acuerdo con las convenciones sociales en diferentes situaciones: \n"+
              "• presentarse a sí mismo y a otros \n"+
              "• saludar\n"+
              "• preguntar \n"+
              "• expresar opiniones, sentimientos e ideas \n"+
              "• situaciones que requieren el uso de fórmulas de cortesía, como por favor, gracias, perdón, permiso."
            ],
            [
              "oa_3_4_6",
              "OA27 - Expresarse de manera coherente y articulada sobre temas de su interés:\n"+
              "• organizando las ideas en introducción, desarrollo y cierre\n"+
              "• incorporando descripciones y ejemplos que ilustren las ideas\n"+
              "• utilizando un vocabulario variado\n"+
              "• reemplazando los pronombres y algunos adverbios por construcciones sintácticas que expliciten o describan al referente\n"+
              "• usando gestos y posturas acordes a la situación\n"+
              "• usando material de apoyo (power point, papelógrafo, objetos, etc.) si es pertinente"
            ],
            [
              "oa_3_4_7",
              "OA28 C - Incorporar de manera pertinente en sus intervenciones orales el vocabulario nuevo extraído de textos escuchados o leídos."
            ],
            [
              "oa_3_4_8",
              "OA29 - Caracterizar distintos personajes para desarrollar su lenguaje y autoestima, y aprender a trabajar en equipo."
            ],
            [
              "oa_3_4_9",
              "OA30 C - Recitar poemas con entonación y expresión para fortalecer la confianza en sí mismos, aumentar el vocabulario y desarrollar su capacidad expresiva."
            ],
          ],



          "eax_1-ebl_5":[

            [
              "oa_1_5_1",
              "OA1 - Leer de manera fluida textos variados apropiados a su edad:\n"+
              "• pronunciando las palabras con precisión\n"+
              "• respetando la prosodia indicada por todos los signos de puntuación\n"+
              "• decodificando de manera automática la mayoría de las palabras del texto"
            ],
            [
              "oa_1_5_2",
              "OA2 C - Comprender textos, aplicando estrategias de comprensión lectora; por ejemplo: • relacionar la información del texto con sus experiencias y conocimientos • releer lo que no fue comprendido • formular preguntas sobre lo leído y responderlas • identificar las ideas más importantes de acuerdo con el propósito del lector • organizar la información en esquemas o mapas conceptuales."
            ],
            [
              "oa_1_5_3",
              "OA3 - Leer y familiarizarse con un amplio repertorio de literatura para aumentar su conocimiento del mundo, desarrollar su imaginación y reconocer su valor social y cultural; por ejemplo:\n"+
              "• poemas\n"+
              "• cuentos folclóricos y de autor\n"+
              "• fábulas\n"+
              "• leyendas\n"+
              "• mitos\n"+
              "• novelas\n"+
              "• historietas\n"+
              "• otros"
            ],
            [
              "oa_1_5_4",
              "OA4 - Analizar aspectos relevantes de narraciones leídas para profundizar su comprensión:\n"+
              "• interpretando el lenguaje figurado presente en el texto\n"+
              "• expresando opiniones sobre las actitudes y acciones de los personajes y fundamentándolas con ejemplos del texto\n"+
              "• determinando las consecuencias de hechos o acciones\n"+
              "• describiendo el ambiente y las costumbres representadas en el texto\n"+
              "• explicando las características físicas y sicológicas de los personajes que son relevantes para el desarrollo de la historia\n"+
              "• comparando textos de autores diferentes y justificando su preferencia por alguno"
            ],
            [
              "oa_1_5_5",
              "OA5 - Analizar aspectos relevantes de diversos poemas para profundizar su comprensión:\n"+
              "• explicando cómo el lenguaje poético que emplea el autor apela a los sentidos, sugiere estados de ánimo y crea imágenes en el lector\n"+
              "• identificando personificaciones y comparaciones y explicando su significado dentro del poema\n"+
              "• distinguiendo los elementos formales de la poesía (rima asonante y consonante, verso y estrofa)"
            ],
            [
              "oa_1_5_6",
              "OA6 - Leer independientemente y comprender textos no literarios (cartas, biografías, relatos históricos, libros y artículos informativos, noticias, etc.) para ampliar su conocimiento del mundo y formarse una opinión:\n"+
              "• extrayendo información explícita e implícita\n"+
              "• haciendo inferencias a partir de la información del texto y de sus experiencias y conocimientos\n"+
              "• relacionando la información de imágenes, gráficos, tablas, mapas o diagramas, con el texto en el cual están insertos\n"+
              "• interpretando expresiones en lenguaje figurado\n"+
              "• comparando información\n"+
              "• formulando una opinión sobre algún aspecto de la lectura\n"+
              "• fundamentando su opinión con información del texto o sus conocimientos previos"
            ],
            [
              "oa_1_5_7",
              "OA7 - Evaluar críticamente la información presente en textos de diversa procedencia:\n"+
              "• determinando quién es el emisor, cuál es su propósito y a quién dirige el mensaje\n"+
              "• evaluando si un texto entrega suficiente información para responder una determinada pregunta o cumplir un propósito"
            ],
            [
              "oa_1_5_8",
              "OA8 C - Sintetizar y registrar las ideas principales de textos leídos para satisfacer propósitos como estudiar, hacer una investigación, recordar detalles, etc."
            ],
            [
              "oa_1_5_9",
              "OA9 - Desarrollar el gusto por la lectura, leyendo habitualmente diversos textos."
            ],
            [
              "oa_1_5_10",
              "OA10 C  - Asistir habitualmente a la biblioteca para satisfacer diversos propósitos (seleccionar textos, investigar sobre un tema, informarse sobre actualidad, etc.), adecuando su comportamiento y cuidando el material para permitir el trabajo y la lectura de los demás."
            ],
            [
              "oa_1_5_11",
              "OA11 - Buscar y seleccionar la información más relevante sobre un tema en internet, libros, diarios, revistas, enciclopedias, atlas, etc., para llevar a cabo una investigación."
            ],
            [
              "oa_1_5_12",
              "OA12 C - Aplicar estrategias para determinar el significado de palabras nuevas: \n"+
              "• claves del texto (para determinar qué acepción es pertinente según el contexto) \n"+
              "• raíces y afijos \n"+
              "• preguntar a otro \n"+
              "• diccionarios, enciclopedias e internet."
            ],

          ],
          "eax_2-ebl_5":[

            [
              "oa_2_5_1",
              "OA13 C - Escribir frecuentemente, para desarrollar la creatividad y expresar sus ideas, textos como poemas, diarios de vida, cuentos, anécdotas, cartas, blogs, etc."
            ],
            [
              "oa_2_5_2",
              "OA14 - Escribir creativamente narraciones (relatos de experiencias personales, noticias, cuentos, etc.) que:\n"+
              "• tengan una estructura clara\n"+
              "• utilicen conectores adecuados\n"+
              "• incluyan descripciones y diálogo (si es pertinente) para desarrollar la trama, los personajes y el ambiente"
            ],
            [
              "oa_2_5_3",
              "OA15 - Escribir artículos informativos para comunicar información sobre un tema:\n"+
              "• presentando el tema en una oración\n"+
              "• desarrollando una idea central por párrafo\n"+
              "• agregando las fuentes utilizadas"
            ],
            [
              "oa_2_5_4",
              "OA16 C - Escribir frecuentemente para compartir impresiones sobre sus lecturas, desarrollando un tema relevante del texto leído y fundamentando sus comentarios con ejemplos."
            ],
            [
              "oa_2_5_5",
              "OA17 - Planificar sus textos:\n"+
              "• estableciendo propósito y destinatario\n"+
              "• generando ideas a partir de sus conocimientos e investigación\n"+
              "• organizando las ideas que compondrán su escrito"
            ],
            [
              "oa_2_5_6",
              "OA18 - Escribir, revisar y editar sus textos para satisfacer un propósito y transmitir sus ideas con claridad. Durante este proceso:\n"+
              "• desarrollan las ideas agregando información\n"+
              "• emplean un vocabulario preciso y variado, y un registro adecuado\n"+
              "• releen a medida que escriben\n"+
              "• aseguran la coherencia y agregan conectores\n"+
              "• editan, en forma independiente, aspectos de ortografía y presentación\n"+
              "• utilizan las herramientas del procesador de textos para buscar sinónimos, corregir ortografía y gramática, y dar formato (cuando escriben en computador)"
            ],
            [
              "oa_2_5_7",
              "OA19 C - Incorporar de manera pertinente en la escritura el vocabulario nuevo extraído de textos escuchados o leídos."
            ],
            [
              "oa_2_5_8",
              "OA20 C - Distinguir matices entre sinónimos al leer, hablar y escribir para ampliar su comprensión y capacidad expresiva."
            ],
            [
              "oa_2_5_9",
              "OA21 C - Conjugar correctamente los verbos regulares al utilizarlos en sus producciones escritas."
            ],
            [
              "oa_2_5_10",
              "OA22 - Escribir correctamente para facilitar la comprensión por parte del lector, aplicando las reglas ortográficas aprendidas en años anteriores, además de: \n"+
              "• uso de c-s-z \n"+
              "• raya para indicar diálogo \n"+
              "• acento diacrítico y dierético \n"+
              "• coma en frases explicativas."
            ],

          ],
          "eax_3-ebl_5":[ 
            [
              "oa_3_5_1",
              "OA23 C - Comprender y disfrutar versiones completas de obras de la literatura, narradas o leídas por un adulto, como: • cuentos folclóricos y de autor • poemas • mitos y leyendas • capítulos de novelas."
            ],
            [
              "oa_3_5_2",
              "OA24 - Comprender textos orales (explicaciones, instrucciones, noticias, documentales, entrevistas,\n"+
              "testimonios, relatos, etc.) para obtener información y desarrollar su curiosidad por el mundo:\n"+
              "• relacionando las ideas escuchadas con sus experiencias personales y sus conocimientos previos\n"+
              "• extrayendo y registrando la información relevante\n"+
              "• formulando preguntas al profesor o a los compañeros para comprender o elaborar una idea, o aclarar el significado de una palabra\n"+
              "• comparando información dentro del texto o con otros textos\n"+
              "• formulando y fundamentando una opinión sobre lo escuchado"
            ], 
            [
              "oa_3_5_3",
              "OA25 C - Apreciar obras de teatro, películas o representaciones: \n"+
              "• discutiendo aspectos relevantes de la historia \n"+
              "• describiendo a los personajes según su manera de hablar y de comportarse."
            ], 
            [
              "oa_3_5_4",
              "OA26 - Dialogar para compartir y desarrollar ideas y buscar acuerdos:\n"+
              "• manteniendo el foco en un tema\n"+
              "• aceptando sugerencias\n"+
              "• haciendo comentarios en los momentos adecuados\n"+
              "• mostrando acuerdo o desacuerdo con respeto\n"+
              "• fundamentando su postura"
            ], 
            [
              "oa_3_5_5",
              "OA27 C - Interactuar de acuerdo con las convenciones sociales en diferentes situaciones:\n"+
              "• presentarse a sí mismo y a otros \n"+
              "• saludar \n"+
              "• preguntar \n"+
              "• expresar opiniones, sentimientos e ideas \n"+
              "• otras situaciones que requieran el uso de fórmulas de cortesía, como por favor, gracias, perdón, permiso."
            ], 
            [
              "oa_3_5_6",
              "OA28 - Expresarse de manera clara y efectiva en exposiciones orales para comunicar temas de su interés:\n"+
              "• presentando las ideas de manera coherente y cohesiva\n"+
              "• fundamentando sus planteamientos con ejemplos y datos\n"+
              "• organizando las ideas en introducción, desarrollo y cierre\n"+
              "• utilizando un vocabulario variado y preciso y un registro formal, adecuado a la situación comunicativa\n"+
              "• reemplazando algunas construcciones sintácticas familiares por otras más variadas\n"+
              "• conjugando correctamente los verbos\n"+
              "• pronunciando claramente y usando un volumen audible, entonación, pausas y énfasis adecuados\n"+
              "• usando gestos y posturas acordes a la situación\n"+
              "• usando material de apoyo (power point, papelógrafo, objetos, etc.) de manera efectiva"
            ], 
            [
              "oa_3_5_7",
              "OA29 C - Incorporar de manera pertinente en sus intervenciones orales el vocabulario nuevo extraído de textos escuchados o leídos."
            ], 
            [
              "oa_3_5_8",
              "OA30 C - Producir textos orales planificados de diverso tipo para desarrollar su capacidad expresiva: • poemas • narraciones (contar una historia, describir una actividad, relatar noticias, testimonios, etc.) • dramatizaciones."
            ], 

          ],



          "eax_1-ebl_6":[

            [
              "oa_1_6_1",
              "OA1 - Leer de manera fluida textos variados apropiados a su edad:\n"+
              "• pronunciando las palabras con precisión\n"+
              "• respetando la prosodia indicada por todos los signos de puntuación\n"+
              "• decodificando de manera automática la mayoría de las palabras del texto"
            ],

            [
              "oa_1_6_2",
              "OA2 C - Comprender textos, aplicando estrategias de comprensión lectora; por ejemplo: \n"+
              "• relacionar la información del texto con sus experiencias y conocimientos \n"+
              "• releer lo que no fue comprendido \n"+
              "• formular preguntas sobre lo leído y responderlas \n"+
              "• organizar la información en esquemas o mapas conceptuales \n"+
              "• resumir"
            ],

            [
              "oa_1_6_3",
              "OA3 - Leer y familiarizarse con un amplio repertorio de literatura para aumentar su conocimiento del mundo, desarrollar su imaginación y reconocer su valor social y cultural; por ejemplo:\n"+
              "• poemas\n"+
              "• cuentos folclóricos y de autor\n"+
              "• fábulas\n"+
              "• leyendas\n"+
              "• mitos"
            ],

            [
              "oa_1_6_4",
              "OA4 - Analizar aspectos relevantes de las narraciones leídas para profundizar su comprensión:\n"+
              "• identificando las acciones principales del relato y explicando cómo influyen en el desarrollo de la historia\n"+
              "• explicando las actitudes y reacciones de los personajes de acuerdo con sus motivaciones y las situaciones que viven\n"+
              "• describiendo el ambiente y las costumbres representadas en el texto y explicando su influencia en las acciones del relato\n"+
              "• relacionando el relato, si es pertinente, con la época y el lugar en que se ambienta\n"+
              "• interpretando el lenguaje figurado presente en el texto\n"+
              "• expresando opiniones sobre las actitudes y acciones de los personajes y fundamentándolas con ejemplos del texto\n"+
              "• llegando a conclusiones sustentadas en la información del texto\n"+
              "• comparando textos de autores diferentes y justificando su preferencia por alguno"
            ],

            [
              "oa_1_6_5",
              "OA5 - Analizar aspectos relevantes de diversos poemas para profundizar su comprensión:\n"+
              "• explicando cómo el lenguaje poético que emplea el autor apela a los sentidos, sugiere estados de ánimo y crea imágenes en el lector\n"+
              "• identificando personificaciones, comparaciones e hipérboles y explicando su significado dentro del poema\n"+
              "• analizando cómo los efectos sonoros (aliteración y onomatopeya) utilizados por el poeta refuerzan lo dicho"
            ],

            [
              "oa_1_6_6",
              "OA6 - Leer independientemente y comprender textos no literarios (cartas, biografías, relatos históricos, libros y artículos informativos, noticias, etc.) para ampliar su conocimiento del mundo y formarse una opinión:\n"+
              "• extrayendo información explícita e implícita\n"+
              "• haciendo inferencias a partir de la información del texto y de sus experiencias y conocimientos\n"+
              "• relacionando la información de imágenes, gráficos, tablas, mapas o diagramas, con el texto en el cual están insertos\n"+
              "• interpretando expresiones en lenguaje figurado\n"+
              "• comparando información entre dos textos del mismo tema\n"+
              "• formulando una opinión sobre algún aspecto de la lectura\n"+
              "• fundamentando su opinión con información del texto o sus conocimientos previos"
            ],

            [
              "oa_1_6_7",
              "OA7 - Evaluar críticamente la información presente en textos de diversa procedencia:\n"+
              "• determinando quién es el emisor, cuál es su propósito y a quién dirige el mensaje\n"+
              "• evaluando si un texto entrega suficiente información para responder una determinada pregunta o cumplir un propósito\n"+
              "• comparando la información que se entrega sobre una misma noticia en distintas fuentes"
            ],

            [
              "oa_1_6_8",
              "OA8 C - Sintetizar, registrar y ordenar las ideas principales de textos leídos para satisfacer propósitos como estudiar, hacer una investigación, recordar detalles, etc."
            ],

            [
              "oa_1_6_9",
              "OA9 - Desarrollar el gusto por la lectura, leyendo habitualmente diversos textos."
            ],

            [
              "oa_1_6_10",
              "OA10 C - Asistir habitualmente a la biblioteca para satisfacer diversos propósitos (seleccionar textos, investigar sobre un tema, informarse sobre actualidad, etc.), adecuando su comportamiento y cuidando el material para permitir el trabajo y la lectura de los demás."
            ],

            [
              "oa_1_6_11",
              "OA11 - Buscar y comparar información sobre un tema, utilizando fuentes como internet, enciclopedias, libros, prensa, etc., para llevar a cabo una investigación."
            ],

            [
              "oa_1_6_12",
              "OA12 C - Aplicar estrategias para determinar el significado de palabras nuevas: \n"+
              "• claves contextuales \n"+
              "• raíces y afijos \n"+
              "• preguntar a otro \n"+
              "• diccionarios, enciclopedias e internet."
            ],

          ],
          "eax_2-ebl_6":[

            [
              "oa_2_6_1",
              "OA13 C - Escribir frecuentemente, para desarrollar la creatividad y expresar sus ideas, textos como poemas, diarios de vida, cuentos, anécdotas, cartas, blogs, etc."
            ],
            [
              "oa_2_6_2",
              "OA14 - Escribir creativamente narraciones (relatos de experiencias personales, noticias, cuentos, etc.) que:\n"+
              "• tengan una estructura clara\n"+
              "• utilicen conectores adecuados\n"+
              "• tengan coherencia en sus oraciones\n"+
              "• incluyan descripciones y diálogo (si es pertinente) que desarrollen la trama, los personajes y el ambiente"
            ],
            [
              "oa_2_6_3",
              "OA15 - Escribir artículos informativos para comunicar información sobre un tema:\n"+
              "• organizando el texto en una estructura clara\n"+
              "• desarrollando una idea central por párrafo\n"+
              "• agregando las fuentes utilizadas"
            ],
            [
              "oa_2_6_4",
              "OA16 C - Escribir frecuentemente para compartir impresiones sobre sus lecturas, desarrollando un tema relevante del texto leído y fundamentando sus comentarios con ejemplos."
            ],
            [
              "oa_2_6_5",
              "OA17 - Planificar sus textos:\n"+
              "• estableciendo propósito y destinatario\n"+
              "• generando ideas a partir de sus conocimientos e investigación\n"+
              "• organizando las ideas que compondrán su escrito"
            ],
            [
              "oa_2_6_6",
              "OA18 - Escribir, revisar y editar sus textos para satisfacer un propósito y transmitir sus ideas con claridad. Durante este proceso:\n"+
              "• agregan ejemplos, datos y justificaciones para profundizar las ideas\n"+
              "• emplean un vocabulario preciso y variado, y un registro adecuado\n"+
              "• releen a medida que escriben\n"+
              "• aseguran la coherencia y agregan conectores\n"+
              "• editan, en forma independiente, aspectos de ortografía y presentación\n"+
              "• utilizan las herramientas del procesador de textos para buscar sinónimos, corregir ortografía y gramática, y dar formato (cuando escriben en computador)"
            ],
            [
              "oa_2_6_7",
              "OA19 C - Incorporar de manera pertinente en la escritura el vocabulario nuevo extraído de textos escuchados o leídos."
            ],
            [
              "oa_2_6_8",
              "OA20 - Ampliar su capacidad expresiva, utilizando los recursos que ofrece el lenguaje para expresar un mismo mensaje de diversas maneras; por ejemplo: \n"+
              "• sinónimos, hipónimos e hiperónimos \n"+
              "• locuciones \n"+
              "• comparaciones \n"+
              "• otros."
            ],
            [
              "oa_2_6_9",
              "OA21 C - Utilizar correctamente los participios irregulares (por ejemplo, roto, abierto, dicho, escrito, muerto, puesto, vuelto) en sus producciones escritas."
            ],
            [
              "oa_2_6_10",
              "OA22 C - Escribir correctamente para facilitar la comprensión por parte del lector, aplicando todas las reglas de ortografía literal, acentual y puntual aprendidas en años anteriores, además de: \n"+
              "• escritura de los verbos haber, tener e ir, en los tiempos más utilizados \n"+
              "• coma en frases explicativas \n"+
              "• coma en presencia de conectores que la requieren \n"+
              "• acentuación de pronombres interrogativos y exclamativos."
            ],

          ],
          "eax_3-ebl_6":[

            [
              "oa_3_6_1",
              "OA23 C - Comprender y disfrutar versiones completas de obras de la literatura, narradas o leídas por un adulto, como: \n"+
              "• cuentos folclóricos y de autor\n"+
              "• poemas \n"+
              "• mitos y leyendas \n"+
              "• capítulos de novelas."
            ],
            [
              "oa_3_6_2",
              "OA24 - Comprender textos orales (explicaciones, instrucciones, noticias, documentales, entrevistas, testimonios, relatos, reportajes, etc.) para obtener información y desarrollar su curiosidad por el mundo:\n"+
              "• relacionando las ideas escuchadas con sus experiencias personales y sus conocimientos previos\n"+
              "• extrayendo y registrando la información relevante\n"+
              "• formulando preguntas al profesor o a los compañeros para comprender o elaborar una idea, o aclarar el significado de una palabra\n"+
              "• comparando información dentro del texto o con otros textos\n"+
              "• formulando y fundamentando una opinión sobre lo escuchado\n"+
              "• identificando diferentes puntos de vista"
            ],
            [
              "oa_3_6_3",
              "OA25 C - Evaluar críticamente mensajes publicitarios: \n"+
              "• identificando al emisor \n"+
              "• explicando cuál es la intención del emisor \n"+
              "• identificando a quién está dirigido el mensaje (a niñas, a usuarios del metro, a adultos mayores, etc.) y fundamentando cómo llegaron a esa conclusión."
            ],
            [
              "oa_3_6_4",
              "OA26 C - Apreciar obras de teatro, películas o representaciones: \n"+
              "• discutiendo aspectos relevantes de la historia \n"+
              "• describiendo cómo los actores cambian sus tonos de voz y su gestualidad para expresar diversas emociones \n"+
              "• identificando algunos recursos que buscan provocar un efecto en la audiencia (efectos de sonido, música, efectos de iluminación, etc.)."
            ],
            [
              "oa_3_6_5",
              "OA27 - Dialogar para compartir y desarrollar ideas y buscar acuerdos:\n"+
              "• manteniendo el foco en un tema\n"+
              "• complementando las ideas de otro y ofreciendo sugerencias\n"+
              "• aceptando sugerencias\n"+
              "• haciendo comentarios en los momentos adecuados\n"+
              "• mostrando acuerdo o desacuerdo con respeto\n"+
              "• fundamentando su postura"
            ],
            [
              "oa_3_6_6",
              "OA28 C - Interactuar de acuerdo con las convenciones sociales en diferentes situaciones: \n"+
              "• presentarse a sí mismo y a otros \n"+
              "• saludar \n"+
              "• preguntar \n"+
              "• expresar opiniones, sentimientos e ideas\n"+
              "• otras situaciones que requieran el uso de fórmulas de cortesía, como por favor, gracias, perdón, permiso."
            ],
            [
              "oa_3_6_7",
              "OA29 - Expresarse de manera clara y efectiva en exposiciones orales para comunicar temas de su interés:\n"+
              "• presentando las ideas de manera coherente y cohesiva\n"+
              "• fundamentando sus planteamientos con ejemplos y datos\n"+
              "• organizando las ideas en introducción, desarrollo y cierre\n"+
              "• usando elementos de cohesión para relacionar cada parte de la exposición\n"+
              "• utilizando un vocabulario variado y preciso y un registro formal adecuado a la situación comunicativa\n"+
              "• reemplazando algunas construcciones sintácticas familiares por otras más variadas\n"+
              "• conjugando correctamente los verbos\n"+
              "• utilizando correctamente los participios irregulares\n"+
              "• pronunciando claramente y usando un volumen audible, entonación, pausas y énfasis adecuados\n"+
              "• usando gestos y posturas acordes a la situación\n"+
              "• usando material de apoyo (power point, papelógrafo, objetos, etc.) de manera efectiva\n"+
              "• exponiendo sin leer de un texto escrito"
            ],
            [
              "oa_3_6_8",
              "OA30 C - Incorporar de manera pertinente en sus intervenciones orales el vocabulario nuevo extraído de textos escuchados o leídos."
            ],
            [
              "oa_3_6_9",
              "OA31 C - Producir textos orales espontáneos o planificados de diverso tipo para desarrollar su capacidad expresiva: \n"+
              "• poemas \n"+
              "• narraciones (contar una historia, describir una actividad, relatar noticias, testimonios, etc.) \n"+
              "• dramatizaciones."
            ],

          ],

        }
    },
    { 
        "id": "education_trans_target",
        "label": "Objetivo de Aprendizaje Transversal",
        "desc": "Elige un Objetivo",
        "multi": true,
        "fieldValues": [ 
            [
              "oat_1",
              "Favorecer el desarrollo físico personal y el autocuidado, en el contexto de la valoración de la vida y el propio cuerpo, mediante hábitos de higiene, prevención de riesgos y hábitos de vida saludable."
            ],
            [
              "oat_2",
              "Practicar actividad física adecuada a sus intereses y aptitudes."
            ],
            [
              "oat_3",
              "Adquirir un sentido positivo ante la vida, una sana autoestima y confianza en sí mismo, basada en el conocimiento personal, tanto de sus potencialidades como de sus limitaciones."
            ],
            [
              "oat_4",
              "Comprender y apreciar la importancia que tienen las dimensiones afectiva, espiritual, ética y social para un sano desarrollo sexual."
            ],
            [
              "oat_5",
              "Exponer ideas, opiniones, convicciones, sentimientos y experiencias de manera coherente y fundamentada, haciendo uso de diversas y variadas formas de expresión."
            ],
            [
              "oat_6",
              "Resolver problemas de manera reflexiva en el ámbito escolar, familiar y social, tanto utilizando modelos y rutinas como aplicando de manera creativa conceptos y criterios."
            ],
            [
              "oat_7",
              "Valorar la vida en sociedad como una dimensión esencial del crecimiento de la persona, y actuar de acuerdo con valores y normas de convivencia cívica, pacífica y democrática, conociendo sus derechos y responsabilidades, y asumiendo compromisos consigo mismo y con los otros."
            ],
            [
              "oat_8",
              "Valorar el compromiso en las relaciones entre las personas y al acordar contratos: en la amistad en el amor, en el matrimonio, en el trabajo y al emprender proyectos."
            ],
            [
              "oat_9",
              "Participar solidaria y responsablemente en las actividades y proyectos de la familia, del establecimiento y de la comunidad."
            ],
            [
              "oat_10",
              "Conocer y valorar la historia y sus actores, las tradiciones, los símbolos, el patrimonio territorial y cultural de la nación, en el contexto de un mundo crecientemente globalizado e interdependiente."
            ], 
            [
              "oat_11",
              "Reconocer y respetar la igualdad de derechos entre hombres y mujeres y apreciar la importancia de desarrollar relaciones que potencien su participación equitativa en la vida económica familiar, social y cultural."
            ],
            [
              "oat_12",
              "Proteger el entorno natural y sus recursos como contexto de desarrollo humano."
            ],
            [
              "oat_13",
              "Ejercer de modo responsable grados crecientes de libertad y autonomía personal, de acuerdo a valores como la justicia, la verdad, la solidaridad y la honestidad, el respeto, el bien común y la generosidad."
            ],
            [
              "oat_14",
              "Conocer, respetar y defender la igualdad de derechos esenciales de todas las personas, sin distinción de sexo, edad, condición física, etnia, religión o situación económica, y actuar en concordancia con el principio ético que reconoce que todos los “seres humanos nacen libres e iguales en dignidad y derechos y, dotados de razón y conciencia, deben comportarse fraternalmente los unos con los otros” (Declaración Universal de Derechos Humanos, Artículo 1°)."
            ],
            [
              "oat_15",
              "Valorar el carácter único de cada ser humano y, por lo tanto, la diversidad que se manifiesta entre las personas, y desarrollar la capacidad de empatía con los otros."
            ],
            [
              "oat_16",
              "Reconocer y respetar la diversidad cultural, religiosa y étnica y las ideas y creencias distintas de las propias en los espacios escolares, familiares y comunitarios, reconociendo el diálogo como fuente de crecimiento, superación de diferencias y acercamiento a la verdad."
            ],
            [
              "oat_17",
              "Practicar la iniciativa personal, la creatividad y el espíritu emprendedor en los ámbitos personal, escolar y comunitario."
            ],
            [
              "oat_18",
              "Trabajar en equipo de manera responsable, construyendo relaciones basadas en la confianza mutua."
            ],
            [
              "oat_19",
              "Comprender y valorar la perseverancia, el rigor y el cumplimiento, por un lado, y la flexibilidad, la originalidad, la aceptación de consejos y críticas y el asumir riesgos, por el otro, como aspectos fundamentales en el desarrollo y la consumación exitosa de tareas y trabajos."
            ],
            [
              "oat_20",
              "Reconocer la importancia del trabajo –manual e intelectual– como forma de desarrollo personal, familiar, social y de contribución al bien común, valorando la dignidad esencial de todo trabajo y el valor eminente de la persona que lo realiza."
            ],
            [
              "oat_21",
              "Utilizar TIC que resuelvan las necesidades de información, comunicación, expresión y creación dentro del entorno educativo y social inmediato."
            ]
        ],  
    },

    {
        "id": "activity_time",
        "label": "Tiempo de la Actividad",
        "desc": "Elige una duración",
        "fieldValues": [
          [
            "at_15",
            "15 min"
          ],
          [
            "at_30",
            "30 min"
          ],
          [
            "at_45",
            "45 min"
          ] 
        ]
    },  
    {
        "id": "complexity",
        "label": "Nivel de Complejidad",
        "desc": "Elige un Nivel",
        "fieldValues": [
          [
            "c_low",
            "Bajo"
          ],
          [
            "c_medium",
            "Medio"
          ],
          [
            "c_high",
            "Alto"
          ] 
        ]
    }

]

export default fieldDataCL_EB;
