/**
 * This file is intended for functions that transform what the
 * user inputs in redux-form Field's and convert that into the
 * output we expec in the store or other component.
 * redux-form provides a normalize prop to handle these cases,
 * so in this file we'll define normlizer functions as needed.
 * More info here:
 * http://redux-form.com/6.8.0/examples/normalizing/
 */

export const toBoolean = (value: mixed) => Boolean(value);
