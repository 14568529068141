/* @flow */

import React from 'react';
import { Paper, StoreNavigation, StoreBuyCoins } from '../components';
import { StoreSliderFeaturedBundles, StoreSliderBundles, StoreCardContainer, HeaderContainer } from '../containers';
import { pure, compose, withState, withHandlers } from 'recompose';
import _ from 'lodash';

/**
 * Define the type of content to be displayed which will be a react element
 * or a set of experiences and bundles defined by a data Array.
 * @method StoreContent
 * @param  {[]Object|React.Element}     content - sections of the store to be displayed or a simple react element to render
 * @param  {string}                     content.type - wether the set is experiences or bundles of experiences
 * @param  {[]string}                   content.data - set of identifier for bundles of experiences
 */
const StoreContent = ({ content }) => {
  return (
    <div className="store-content">
      { _.isArray(content)
        ? content.map(({ type, data }, key) => {
            return (
              (type === 'bundles')
              ? <StoreSliderBundles key={key} bundles={data} />
              : <StoreCardContainer key={key} experiences={data} />
            );
          })
        : content
      }
    </div>
  );
};

/**
 * Display the store and inject what to do to change its content
 * @method Store
 * @param  {RecomposeHandler}         setStoreContent - define how to change the content of teh store
 * @param  {[]Object|React.Element}   content         - sections of the store to be displayed or a simple react element to render
 */
const Store = ({ setStoreContent, content }) => {
  return (
    <div id="store" className="layout-base store">
      <HeaderContainer />
      <StoreSliderFeaturedBundles />
      <Paper.Container className="paper-store" floating>
        <StoreNavigation setStoreContent={setStoreContent} />
        <StoreContent content={content} />
      </Paper.Container>
    </div>
  );
};

export const defaultStoreContent = [
  {
    type: 'experiences',
    data: ['planet', 'colour', 'organ', 'xylophone', 'volcano', 'flower', 'home', 'planet']
  },
  {
    type: 'bundles',
    data: ['rocket', 'game']
  },
  {
    type: 'experiences',
    data: ['planet', 'airplane', 'yeti', 'zeppelin']
  }
];

export const StoreHandlers = {
  setStoreContent: props => (group: { id: string }) => {
    const groupId = group.id;

    if (groupId === 'store-content-buy') {
      props.setContent(<StoreBuyCoins />);
      return;
    }

    props.setContent(defaultStoreContent);
  }
};

const StoreEnhancements = compose(
  withState('content', 'setContent', defaultStoreContent),
  withHandlers(StoreHandlers)
);

const StoreComposed = compose(
  StoreEnhancements,
  pure
)(Store);

export default StoreComposed;
