/* @flow */

import React from 'react';
import { FormGroup, Label, FormFeedback } from 'reactstrap';
import classNames from 'classnames';
import Translations from '../hocs/TranslationsHelper';
import type { ReduxFormMeta } from './types';
import Dropdown from './Dropdown';

/**
 * this component is supposed to be used with the Field component
 * from redux-form, passing this as the component prop.
 * This component can be used to render any text based input.
 *
 * @example
 * <Field
 *   className="text-center"
 *   name={`${name}.age`}
 *   placeholder={translations.t('pick_a_course')}
 *   selectOptions={selectOptions}
 *   searchable={searchable}
 *   component={lect}
 *   validate={[ required ]}
 * />
 */
const InputFieldSelect = ({ className, formGroupClassName, searchable, selectOptions, translations, disabled, ...otherProps }) => {
  const { input, label, name, placeholder, meta } = otherProps;
  const { touched, dirty, error, valid, submitting }: ReduxFormMeta = meta;
  const showErrors = (touched || dirty) && error;
  const formColor = classNames({
    danger: showErrors,
    success: valid && (touched || dirty) && !error
  });

  return (
    <FormGroup color={formColor} className={formGroupClassName}>
      { label && <Label for={name}>{label}</Label> }
      <Dropdown
        className={className}
        value={input.value}
        onChange={(option) => input.onChange(option.value)}
        options={selectOptions}
        placeholder={placeholder}
        searchable={searchable}
        disabled={submitting || disabled}
      />
      {showErrors && <FormFeedback>{translations.t(error)}</FormFeedback>}
    </FormGroup>
  );
};

export default Translations(InputFieldSelect);
