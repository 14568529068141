/* @flow */

import _ from 'lodash';

type Collection = Array<{ id?: string }>;

/**
 * this function takes a oldCollection and an updated version of it,
 * and then returns the new items and the edited ones (the ones that
 * have changed) in an object.
 *
 * @method getNewAndUpdated
 * @param  {Array<Object>} oldCollection     - old version of the collection
 * @param  {Array<Object>} newCollection     - new version of the collection
 * @return {Array<Object>}
 */
export const getNewAndUpdated = (oldCollection: Collection, newCollection: Collection) => {
  const newValues = newCollection.filter(item => !item.id);

  const updatedValues = newCollection.filter((item) => {
    const otherItem = oldCollection.find((currentItem) => currentItem.id === item.id);

    if (!otherItem) {
      return false;
    }

    if (!_.isEqual(item, otherItem)) {
      return true;
    }

    return false;
  });

  return {
    newValues,
    updatedValues
  };
};

export default getNewAndUpdated;
