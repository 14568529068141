/* @flow */

import { call, cancel, fork, put, race, takeEvery, take } from 'redux-saga/effects';
import request from '../request';
import RequestOptions from '../RequestOptions';
import actions from '../actions/';
import type { Action } from '../types';

export function* workerRequest(action: Action): any {
  const { responseActionType , requestHeaders} = action.payload;

  let payload = RequestOptions.buildPayload(action);
  /*
    Define a request as reportable by default so if it
    fails will be reported to sentry, typically forms
    request will set this to false
    Also define it as authorizable by default, as most
    requests need the Authorization header
   */
  let options = {
    reportable: true,
    authorizable: true,
    method: 'get',
    ...action.payload.request
  };

  let headers = yield call(RequestOptions.getHeaders, options.authorizable);

  if (!headers) { 
    yield put(actions.diplomaticoInfo({ code: '003', info: { _meta: action } }));
    yield put(actions.responseError(payload, responseActionType));
    return;
  }

  if(requestHeaders)
    Object.keys(requestHeaders).forEach((key)=>{
      headers.append(key, requestHeaders[key]);
    });

  let requestParams = {
    _meta: action,
    payload,
    headers,
    options,
    responseActionType
  };

  const currentRequest = yield fork(request, requestParams);

  const { response } = yield race({
    response: take(requestParams.responseActionType),
    timeout: take('REQUEST_TIMEOUT')
  });

  if (response) {
    // finish this saga
    return;
  }

  yield cancel(currentRequest);
}

export function* watchRequest(): any {
  yield [
    takeEvery('REQUEST', workerRequest)
  ];
}
