/* @flow */

import React from 'react';
import classNames from 'classnames';

type Props = {
  image: string,
  title: string,
  className: string
};

const BundleExperience = ({ image, title, className }: Props) => {
  const imgClass = classNames('img store-bundle-image', className);

  return (
    <div className="store-bundle">
      <img className={imgClass} src={image} alt={title} />
    </div>
  );
};

export default BundleExperience;
