/* @flow */

import React from 'react';
import { Form } from 'reactstrap';
import { compose, withState, withHandlers, pure } from 'recompose';
import { CodeInstructions, InputField, RegistrationForm } from './';
import type {
  RecomposeStateName, RecomposeHandler, RecomposeStateUpdater, ReduxFormErrorGlobal,
  ReduxFormFlag, TranslationsObject
} from './types';
import { Field, reduxForm } from 'redux-form';
import { required, pleiqCode } from './validations';
import Translations from '../hocs/TranslationsHelper';
import classNames from 'classnames';
import RegistrationSubmitButton from './RegistrationSubmitButton';

type Props = {
  handleSubmit: () => mixed,
  showInstructions: RecomposeStateName,
  setShowInstructions: RecomposeStateUpdater,
  toggleInstructions: RecomposeHandler,
  translations: TranslationsObject,
  error: ReduxFormErrorGlobal,
  submitting: ReduxFormFlag
};

/**
 * Component to display step 3 sign up form,
 *
 * @method PleIQCodeForm
 * @param  {function}  handleSubmit        - this callback gets injected by redux-form to handle form submit
 * @param  {boolean}   showInstructions    - indicates if instructions modal should be visible
 * @param  {Function}  toggleInstructions  - toggles instructions modal visibility
 *
 */
const PleIQCodeForm = ({ showInstructions, toggleInstructions, handleSubmit, error, submitting, translations }: Props) => {
  const formClassName = classNames({ 'has-danger': error }, 'form');
  const disabled = submitting;
  return (
    <RegistrationForm title={translations.t('pleiq_code_form_title')} subtitle={translations.t('pleiq_code_form_subtitle')}>
      <Form id="pleiq-code-form" className={formClassName} onSubmit={handleSubmit}>
        <Field
          className="text-center"
          type="text"
          name="pleiq_code"
          placeholder="000-000-000"
          disabled={disabled}
          component={InputField} 
          normalize= {(value, previousValue, allValues, previousAllValues) => {
            let v = value.toUpperCase().replace(/[^A-Z0-9]/g,"")
            let s = "";
            for(var i=0;i< v.length;i+=3){
              s+=((i>0)?"-":"") +  v.substr(i, 3);
            }
            return s.substr(0,11);
          }} 
          validate={[ required, pleiqCode ]}
        />
        <p onClick={toggleInstructions} className="text-center registration-form-footer-text">
          {translations.t('where_my_code')} <span className="registration-form-open-modal-icon pleiq-icon-icon_plus" />
        </p>
        <div className="registration-form-button-wrapper">
          <RegistrationSubmitButton loading={submitting}>
            {translations.t('continue')}
          </RegistrationSubmitButton>
          <span className="registration-form-next-icon pleiq-icon-icon_arrow_right" />
        </div>
      </Form>
      <CodeInstructions active={showInstructions} toggle={toggleInstructions} />
    </RegistrationForm>
  );
};

export const PleIQCodeFormHandlers = {
  toggleInstructions: ({ setShowInstructions, showInstructions }: Object) => () => setShowInstructions(!showInstructions)
};

export const FORM_NAME = 'pleiqCodeForm';

const PleIQCodeFormComposed = compose(
  withState('showInstructions', 'setShowInstructions', false),
  withHandlers(PleIQCodeFormHandlers),
  pure
)(PleIQCodeForm);

export default reduxForm({
  form: FORM_NAME
})(Translations(PleIQCodeFormComposed));
