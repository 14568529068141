/* @flow */

import React from 'react';
import classNames from 'classnames';
import { compose, withHandlers, withState, pure } from 'recompose';
import { SearchInput, Paper } from './';
import Translations from '../hocs/TranslationsHelper';
import { Intelligences } from '../models';
//import {useState} from 'react'
import { PdfPrinterContainer } from './PdfPrinter';

import cxicon from "../../img/icon_book_caligrafix_platform.svg";
import cxicon2 from "../../img/icon_book_caligrafix_platform_blue.svg";

type Props = {
  currentTab: string,
  expanded: boolean,
  onTabClick: () => mixed,
  onSearchChange: () => mixed,
  closeSearchHandler: () => mixed,
  expandSearchHandler: () => mixed,
  translations: Object
};

export const navItemHome =  {
  name: 'home',
  translation: 'home',
  iconClass: 'pleiq-icon-icon_school_home',
  url: '/home',
  license: ['pleiq_school','pleiq_cx_plus','pleiq_cx']
};

export const navItems = [navItemHome,{
  name: 'caligrafix',
  translation: 'caligrafix',
  iconSrc: cxicon,
  iconSrcBlue: cxicon2,
  url: '/caligrafix',
  license: ['pleiq_cx_plus','pleiq_cx'],
  roles: ['coordinator', 'observer', 'teacher']
},{
  name: 'progress',
  translation: 'intelligences',
  iconClass: 'pleiq-icon-icon_school_progress',
  url: '/progress',
  license: ['pleiq_cx_plus'],
  roles: ['coordinator', 'observer', 'teacher']
},{
  name: 'progress',
  translation: 'progress',
  iconClass: 'pleiq-icon-icon_school_progress',
  url: '/progress',
  license: ['pleiq_school'],
  roles: ['coordinator', 'observer', 'teacher']
}, {
  name: 'courses',
  translation: 'courses',
  iconClass: 'pleiq-icon-icon_school_courses',
  url: '/courses'
}, {
  name: 'challenges',
  translation: 'challenges',
  iconClass: 'pleiq-icon-icon_school_courses-copy',
  url: `/challenges/${Intelligences.DEFAULT_SLUG}`,
  roles: ['coordinator', 'observer', 'teacher']
}, {
  name: 'profiles',
  translation: 'profiles',
  iconClass: 'pleiq-icon-icon_school_unique_account',
  url: '/users',
  roles: ['coordinator']
}];

const SchoolDashboardNav = ({items, role,hideSearch,  licenseType, currentTab, expanded, onTabClick, pdfTarget, pdfFilter,pdfProps,   onSearchChange, closeSearchHandler, expandSearchHandler, keyPressHandler, searchValue, translations }: Props) => {
  // this hides the Paper.NavItem's when the search is expanded (only on mobile and tablet)
  const navItemClassname = classNames({ 'hidden-md-down': expanded });
  return (
    <Paper.Nav className="school-nav">
      {
        (items||navItems) .map(({ roles, name, translation, iconClass,iconSrcBlue,iconSrc, url,license }) => (
          ((license==undefined || license.indexOf(licenseType)!=-1)
            && (roles==undefined ||  roles.indexOf(role)!=-1))?
          <Paper.NavItem
            key={name}
            onClick={() => onTabClick(url)}
            active={name === currentTab}
            iconClass={iconClass}
            iconSrc={(name==currentTab)?iconSrcBlue:iconSrc}
            className={navItemClassname}
            label={translations.t(translation)}
            hideLabelSm
          />:null
        ))
      }
      {
        (pdfTarget)? (
          <PdfPrinterContainer target={pdfTarget} expanded={expanded} filter={pdfFilter} pdfProps={pdfProps}/>
        ): null
      }
      {
       hideSearch? <div className="inner-addon addon-left expanded"/> : <SearchInput
        expanded={expanded}
        onChange={onSearchChange}
        onExpandClick={expandSearchHandler}
        onCloseClick={closeSearchHandler}
        onKeyPress={keyPressHandler}
        value={searchValue}
      /> }
    </Paper.Nav>
  );
};

export const SchoolDashboardNavHandlers = {
  expandSearchHandler: ({ setExpandedSearch, onSearchClick, expanded, searchValue }) => (toggle) => {
    setExpandedSearch(true);

    if (expanded) {
      onSearchClick(searchValue);
    }
  },
  closeSearchHandler: ({ setExpandedSearch }) => (event) => setExpandedSearch(false),
  onSearchChange: ({ setSearchValue }) => (event) => setSearchValue(event.target.value)
};

const SchoolDashboardNavComposed = compose(
  withState('expanded', 'setExpandedSearch', false),
  withState('searchValue', 'setSearchValue', ''),
  withHandlers(SchoolDashboardNavHandlers),
  pure
)(SchoolDashboardNav);

export default Translations(SchoolDashboardNavComposed);
