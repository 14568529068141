/* @flow */

import { put, takeEvery, all } from 'redux-saga/effects';
import { reportError } from '../actions/error';
import { addAlert } from '../actions/alerts';
import { logout } from '../actions/user';
import { push } from 'react-router-redux';
import { DIPLOMATICO_INFO } from '../diplomatico/actions/information';
import type { Action } from '../diplomatico/types';

function isResponseAction({ type, payload }: Action) {
  return (type.match('RESPONSE_') && true) || false;
}

export function filterTimeoutErrors({ type, payload }: Action) {
  return (isResponseAction({ type, payload }) && (payload.timeout || false));
}

export function filterResponseUnexpectedErrors({ type, payload }: Action) {
  return (isResponseAction({ type, payload }) && (payload.status >= 500));
}

export function* workerDiplomaticoReporter({ type, payload }: Action): any {
  let alert = { dismissible: true, message: 'connection_trouble_message', type: 'error' };
  const { _meta, status } = payload.info;
  let sessionCapacityExceeded;
  let logoutResponse;

  if (payload.code !== '001') {
    // avoid to report errors that we have controlled
    logoutResponse = _meta.payload.responseActionType === 'RESPONSE_USER_LOGOUT';
    sessionCapacityExceeded = _meta.payload.responseActionType === 'RESPONSE_USER_LOGIN';
  }

  switch (payload.code) {
    // No internet
    case '001':
      alert.message = 'no_internet_connection_message';
      yield put(addAlert(alert));
      break;
    // Unauthorized
    case '002':
      if (logoutResponse || sessionCapacityExceeded) {
        /*
          1. A logout response doesn't need to trigger another side effect
          2. Session capacity exceeded is trigger without active session and thus doesn't need side effect
         */
        return;
      }

      // emphasis that the effects within this "if" will only take part for 401 responses
      if (status === 401) {
        yield put(logout());
        return;
      }

      break;
    // Needs auth but doesn't have it
    case '003':
      if (logoutResponse || sessionCapacityExceeded) {
        return;
      }
     // console.log("error detected",{ type, payload });
    //  throw "stop here!";

      yield put({type: 'CLEAR_USER'});
      yield put(push('/login'));
      yield put({ type: 'REPORT_SENTRY_ERROR', payload: { action: { type, payload } } });
      break;
    default:
      break;
  }
}

/**
 * This worker will handle errors by displaying an alert with a
 * generic error message, and then report the error to sentry
 */
export function* workerErrorsReporter({ type, payload }: Action): any { 
  
  const alert = { dismissible: true, message: (payload.status==503)?'server_unavailable_message': 'connection_trouble_message', type: 'error' };
  yield put(addAlert(alert));
  yield put({ type: 'REPORT_SENTRY_ERROR', payload: { action: { type, payload } } });
}

/**
 * This worker will report an error with the passed
 * action data to Sentry
 */
export function* workerSentryErrorsReporter({ type, payload }: Object): any {
  let action = payload;
  let data = { actionPayload: action.payload, actionType: action.type };
  yield put(reportError(data));
}

export function* watchErrors(): any {
  yield all([
    takeEvery(filterResponseUnexpectedErrors, workerErrorsReporter),
    takeEvery(filterTimeoutErrors, workerErrorsReporter),
    
    takeEvery(DIPLOMATICO_INFO, workerDiplomaticoReporter),
    takeEvery('REPORT_SENTRY_ERROR', workerSentryErrorsReporter)
  ]);
}
