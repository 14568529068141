/* @flow */
 
import {  put,call, takeEvery, all } from 'redux-saga/effects';
import { workerRequest } from '../diplomatico/workers';
import type { Action } from '../diplomatico/types';
import { FORM_NAME as STUDENT_COMMENT_FORM } from '../js/components/StudentCommentForm';

import { stopSubmit, reset } from 'redux-form';
/**
 * Worker to handle the request of Student Commentss
 */
export const workerRequestStudentCommentsOptions = (student_id: string) => ({
  requestParams: {
    endpoint: `/school/students/${student_id}/child_comments`,
    method: 'get',
    student_id
  }
});

export function* workerRequestStudentComments(action: Action): any {
  let { student_id } = action.payload;
  let workerRequestGenerator = workerRequest(workerRequestStudentCommentsOptions(student_id));

  yield call(workerRequestGenerator, action);
} 


/**
 * Worker to handle the request the creation of Student Comment 
 */
export const workerRequestStudentCommentCreateOptions = (student_id: string, content) => ({
  requestParams: {
    endpoint: `/school/students/${student_id}/child_comments`,
    method: 'post',
    student_id
  },
  
  requestHandlers: {
    onSuccess: function* ({ action, response }: Object): any {
      yield put(reset(STUDENT_COMMENT_FORM));
      yield put(stopSubmit(STUDENT_COMMENT_FORM));
    },
    onError: function* ({ action, response }: Object): any {
      yield put(stopSubmit(STUDENT_COMMENT_FORM));
    }
  },

  buildRequestBody: (actionPayload: Object) => ({content: actionPayload.content})
});

export function* workerRequestStudentCommentCreate(action: Action): any {
  let { student_id } = action.payload;
  let workerRequestGenerator = workerRequest(workerRequestStudentCommentCreateOptions(student_id));

  yield call(workerRequestGenerator, action);
} 

/**
 * Worker to handle the deletion of Student Comments
 */
export const workerRequestStudentCommentDeleteOptions = (student_id: string, comment_id:string) => ({
  requestParams: {
    endpoint: `/school/students/${student_id}/child_comments/${comment_id}` ,
    method: 'delete',
    student_id
  }
});

export function* workerRequestStudentCommentDelete(action: Action): any {
  let { student_id , comment_id} = action.payload;
  let workerRequestGenerator = workerRequest(workerRequestStudentCommentDeleteOptions(student_id,comment_id)); 
  yield call(workerRequestGenerator, action);
} 


/**
 * Worker to handle the update of Student Comments
 */
export const workerRequestStudentCommentUpdateOptions = (student_id: string, comment_id:string ) => ({
  requestParams: {
    endpoint: `/school/students/${student_id}/child_comments/${comment_id}` ,
    method: 'put',
    student_id
  },
  buildRequestBody: (actionPayload: Object) => ({content: actionPayload.content})
});

export function* workerRequestStudentCommentUpdate(action: Action): any {
  let { student_id , comment_id} = action.payload;
  let workerRequestGenerator = workerRequest(workerRequestStudentCommentUpdateOptions(student_id,comment_id)); 
  yield call(workerRequestGenerator, action);
} 
export function* watchStudentComments(): any {
  yield all([
    takeEvery('REQUEST_STUDENT_COMMENTS', workerRequestStudentComments) ,
    takeEvery('REQUEST_STUDENT_COMMENT_CREATE', workerRequestStudentCommentCreate) ,
    takeEvery('REQUEST_STUDENT_COMMENT_DELETE', workerRequestStudentCommentDelete) ,
    takeEvery('REQUEST_STUDENT_COMMENT_UPDATE', workerRequestStudentCommentUpdate) 
  ]);
}
