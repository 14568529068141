/* @flow */

import React from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { pure, compose, withHandlers } from 'recompose';

type Props = {
  children: React.Component<*>,
  active: boolean,
  onClick: Function
}

/**
 * Component to render the nav pills.
 *
 * We're using recompose to create a handler passing the correct option,
 * this way we're avoiding having to create a function within the render.
 */
const NavItemPillWrapper = ({ children, active, onClick }: Props) => {
  return (
    <NavItem>
      <NavLink onClick={onClick} active={active}>{children}</NavLink>
    </NavItem>
  );
};

export const NavigationPillsHandlers = {
  onClick: props => () => {
    props.onItemClick(props.label);
  }
};

const navPillEnhacements = compose(
  withHandlers(NavigationPillsHandlers)
);

export const NavItemPill = compose(navPillEnhacements, pure)(NavItemPillWrapper);

/**
 * A wrapper component for the nav pills, it just serves as a container to add
 * the Pills prop.
 */
export const NavigationPills = ({ children }) => {
  return (
    <Nav pills>
      {children}
    </Nav>
  );
};

