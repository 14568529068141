/* @flow */

import { responseHasError } from '../diplomatico/reducers/helper';
import type { Action } from '../diplomatico/types';
import { enableInitializing } from 'initializable-reducer';

const initialState = [];

function schoolGeneralCaligrafixBookProgress(state: Array<Object> = initialState, { type, payload }: Action) {
  switch (type) {
    case 'RESPONSE_SCHOOL_GENERAL_CALIGRAFIX_BOOK_PROGRESS':
      if (responseHasError(payload)) {
        return state;
      }

      /*
       * We only need to return the array we get from the backend in this case
       * as it will always be the current state of the caligrafixBook progress
       * for schools.
       */
      return payload.body;
    default:
      return state;
  }
}

export {
  schoolGeneralCaligrafixBookProgress,
  initialState
};

export default enableInitializing(schoolGeneralCaligrafixBookProgress);
