
import { enableInitializing } from 'initializable-reducer';
export function pdf(state={printing:false, props:{}}, { type, printing,progress, props }) {
    switch (type) {
      case 'PDF_PRINTING_STATUS_CHANGE':  
        return  Object.assign({}, state,{printing, props});
      case 'PDF_PRINTING_PROGRESS_CHANGE':  
        return   Object.assign({}, state,{progress}); 
      default:
        return state;
    }
}
export default enableInitializing(pdf);