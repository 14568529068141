/* @flow */

export function showHtmlContentModal(payload: Object) {
  return {
    type: 'SHOW_HTML_CONTENT_MODAL',
    payload
  };
}

export function hideHtmlContentModal() {
  return {
    type: 'HIDE_HTML_CONTENT_MODAL'
  };
}
