/* @flow */

import { responseHasError } from '../diplomatico/reducers/helper';
import type { Action } from '../diplomatico/types';
import { enableInitializing } from 'initializable-reducer';
import _ from 'lodash';

const initialState = {};

function studentCaligrafixBookHistory(state: Object = initialState, { type, payload }: Action) {
  switch (type) {
    case 'RESPONSE_STUDENT_CALIGRAFIX_BOOK_HISTORY':
      if (responseHasError(payload)) {
        return state;
      }

      const { student_id, caligrafix_book_id, group_by } = payload._meta.payload.request;

      const newHistory = {
        [student_id]: {
          [caligrafix_book_id]: {
            [group_by]: payload.body
          }
        }
      };

      return _.merge({}, state, newHistory);
    default:
      return state;
  }
}

export {
  studentCaligrafixBookHistory,
  initialState
};

export default enableInitializing(studentCaligrafixBookHistory);
