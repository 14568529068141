/* @flow */

import React from 'react';
import { Form, FormGroup } from 'reactstrap';
import { compose, pure } from 'recompose';
import { Field, reduxForm } from 'redux-form';
import { Button, InputField, SubmitButton } from './';
import Translations from '../hocs/TranslationsHelper';
import type { ReduxFormFunction, TranslationsObject } from './types';
import { minLength, required, matchPasswords } from './validations';
import Paper from './Paper';

type Props = {
  editMode: boolean,
  submitting: boolean,
  dirty: boolean,
  onClickEditButton: () => mixed,
  onCancelButtonClick: () => mixed,
  handleSubmit: ReduxFormFunction,
  translations: TranslationsObject
};

/**
 * component to render the Unique Account form from schools page
 *
 * @method UniqueAccountForm
 * @param  {Object}    translations            - object containing app translations, it gets injected by the Translations HoC
 *
 */
class UniqueAccountForm extends React.Component {
  render() {
    const { editMode, onClickEditButton, onCancelButtonClick, handleSubmit, submitting, dirty, translations }: Props = this.props;

    const editModeButton = (
      <FormGroup>
        <Button className="unique-account-form-button" type="secondary" onClickButton={onClickEditButton}>
          <span className="btn-icon-lg pleiq-icon-icon_reload" />
          {translations.t('update_password')}
        </Button>
      </FormGroup>
    );
    const saveModeButtons = (
      <div className="unique-account-form-actions">
        <SubmitButton loading={submitting} className="btn btn-primary--green" disabled={!dirty}>
          {translations.t('save')}
        </SubmitButton>
        <Button type="primary--grapefruit" onClickButton={onCancelButtonClick}>
          {translations.t('cancel')}
        </Button>
      </div>
    );

    return (
      <Form className="form unique-account-form form-limited-inputs" onSubmit={handleSubmit}>
        <Paper.Title>
          <h1>
            {translations.t('unique_account_form_title')} 
          </h1> 
        </Paper.Title>
        {/* <h2 className="unique-account-form-title mb-3">{translations.t('unique_account_form_title')}</h2>
         */}
        <Field
          type="text"
          name="email"
          label={translations.t('your_email')}
          placeholder={translations.t('your_email')}
          component={InputField}
          disabled
        />
        <Field
          type="password"
          name="password"
          label={translations.t('password')}
          placeholder="••••••••"
          component={InputField}
          validate={[required, minLength(8)]}
          disabled={!editMode || submitting}
        />
        <Field
          type="password"
          name="password_confirmation"
          label={translations.t('password_confirmation')}
          placeholder="••••••••"
          component={InputField}
          validate={[ required, minLength(8) ]}
          disabled={!editMode || submitting}
        />
        {editMode ? saveModeButtons : editModeButton}
      </Form>
    );
  }
}

const UniqueAccountFormComposed = compose(
  pure
)(UniqueAccountForm);

export const FORM_NAME = 'uniqueAccountForm';

export default reduxForm({
  form: FORM_NAME,
  enableReinitialize: true,
  validate: matchPasswords
})(Translations(UniqueAccountFormComposed));
