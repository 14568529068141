/* @flow */

import React from 'react';
import { connect } from 'react-redux';
import { startSubmit } from 'redux-form';
import { compose, pure, withHandlers } from 'recompose';
import { FORM_NAME } from '../components/StudentCommentForm';
import { StudentCommentForm } from '../components/';
import type { ReduxFunction } from '../components/types';
import { mapCoursesForSelect } from '../../selectors/courses';
import { apiValidColorValues, mapColorStringToHex } from '../components/ColorSelect';
import { showConfirmationModal } from '../../actions/confirmationModal'; 
import actions from '../../actions';

type Props = {
  dispatch: ReduxFunction,
  student_id: string,
  initialValues: Object
};

const StudentCommentFormContainer = (props: Props) => {
  return <StudentCommentForm initialValues={{content:""}} onSubmit={ props.onSubmit} />;
};

export const StudentCommentFormContainerHandlers = {
  onSubmit: ({ dispatch, student_id, initialValues }: Props) => (values) => { 
   // console.log(values);
    
    dispatch(startSubmit(FORM_NAME)); 
    dispatch(actions.createStudentComment({student_id, content: values.content})); 
  }
};

const StudentCommentFormContainerComposed = compose(
  withHandlers(StudentCommentFormContainerHandlers),
  pure
)(StudentCommentFormContainer);
/*
const mapStateToProps = (state, ownProps) => ({
  initialValues: { content:"" } 
});*/

const mapDispatchToProps = (dispatch) => ({ dispatch });

export default connect(
 /* mapStateToProps*/null,
  mapDispatchToProps
)(StudentCommentFormContainerComposed);
