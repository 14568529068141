
export const mockLevels= [
    {id:"0",name:"Balancin"},
    {id:"1",name:"Nivel 1"},
    {id:"2",name:"Nivel 2"}
];

export const mockBooks = [
    {id:"0",name:"Balancin", slug: "bn3", level_id:"0", color:"#ff865c"},
    {id:"1",name:"Lógica y Números 1", slug: "lnn1", level_id:"1", color:"#16cc90"},
    {id:"2",name:"Lógica y Números 2", slug: "lnn2", level_id:"2", color:"#d36cff"},
    {id:"3",name:"Trazos y Letras 1", slug: "tln1", level_id:"1", color:"#f1c300"},
    {id:"4",name:"Trazos y Letras 2", slug: "tln2", level_id:"2", color:"#64ccf3"} 
];
 