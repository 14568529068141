/* @flow */

import { put, take, takeEvery, all } from 'redux-saga/effects';
import { initializeReducers } from 'initializable-reducer';
import type { Action } from '../diplomatico/types';
import { getUniqueAccount } from '../actions/uniqueAccount';
import { pdfPrintingChange, pdfPrintingProgressChange } from '../actions/pdf';

export function* workerApplicationInit(action: Action): any {
  const { account_type } = action.payload;

  yield put({ type: 'REQUEST_INTELLIGENCES' });
  yield put({ type: 'REQUEST_CALIGRAFIX_BOOKS' });

  yield take('RESPONSE_INTELLIGENCES'); 
  yield take('RESPONSE_CALIGRAFIX_BOOKS');

  if (account_type === 'Parent') {
    yield put({ type: 'REQUEST_CHILDREN' });

    yield take('RESPONSE_CHILDREN');
  }

  if (account_type === 'School') {
    yield put(getUniqueAccount());

    yield take('RESPONSE_UNIQUE_ACCOUNT');
  }
}


export function* workerApplicationReinit(action: Action): any {
  const {loadBooks, loadIntelligences, onFinish,account_type } = action.payload;

  yield put({ type: 'REQUEST_INTELLIGENCES' });
  yield put({ type: 'REQUEST_CALIGRAFIX_BOOKS' });
  
  //yield put(pdfPrintingChange(true, {})); 

  yield take('RESPONSE_INTELLIGENCES'); 
 // yield put(pdfPrintingProgressChange(50));
 
  yield take('RESPONSE_CALIGRAFIX_BOOKS');

  if (account_type === 'Parent') {
    yield put({ type: 'REQUEST_CHILDREN' });

    yield take('RESPONSE_CHILDREN');
  }
  //yield put(pdfPrintingProgressChange(100));
//  yield put(pdfPrintingChange(false, {})); 
}
export function* workerReducerInitializer(action: Action): any {
  yield put(initializeReducers());
}

export function* watchApplicationActions(): any {
  yield all([
    takeEvery('APPLICATION_INIT', workerApplicationInit),
    takeEvery('APPLICATION_REINIT', workerApplicationReinit),
    takeEvery('LOGOUT', workerReducerInitializer)
  ]);
}
