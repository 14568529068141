/**
 * @flow
 *
 * translations for English make sure to keep all the keys
 * sorted alphabetically in order to easily search for words
 */
const en = {
  /*
    app translations
    these are sorted alphabetically
   */
  GENERAL: 'General',
  INTER: 'Interpersonal',
  INTRA: 'Intrapersonal',
  KIN: 'Kinesthetic',
  LIN: 'Linguistic',
  MAT: 'Logical',
  MUS: 'Musical',
  NAT: 'Naturalistic',
  VIS: 'Visual',
  about: 'Home',
  account_confirmed_form_subtitle: 'Your account has been successfully confirmed.',
  account_confirmed_form_title: 'Account confirmed',
  account_settings: 'Account settings',
  account_settings_profiles: 'Profiles',
  activities: 'Activities',
  add_existing_course: 'Add existing course',
  add_existing_student: 'Add existing student',
  add_new_course: 'Add new course',
  add_new_profile: 'Add new profile',
  add_new_student: 'Add new student',
  age: 'Age',
  all: 'All',
  all_fields_are_required: 'All fields are required',
  against_course: 'Student VS Course',
  back_to_courses: 'Back to courses',
  best_progress: 'Greater progress',
  best_worked: 'Most worked',
  blog: 'Blog',
  buy: 'Store',
  buy_now: 'Buy now',
  cancel: 'Cancel',
  cant_create_session: 'You have an open session already, log out before log in again.',
  challenge: 'Skill',
  challenges: 'Skills',
  children_addition_form_subtitle: 'Define child profile',
  children_addition_form_title: 'Create new PleIQ account',
  configuration: 'Configuration',
  confirm: 'Confirm',
  congratulations_form_subtitle: 'Your account has been successfully created.',
  congratulations_form_title: 'Congratulations!',
  continue: 'Continue',
  course: 'Course',
  course_data: 'Course info',
  course_name: 'Course name',
  course_students: 'Course students',
  courses: 'Courses',
  courses_progress_in_time: 'Courses progress over time',
  courses_section_placeholder: 'You can organize your students by creating levels and courses.',
  create_child_profile: 'Create child profile',
  create_course: 'Create course',
  create_level: 'Create level',
  create_new_level: 'Create new level',
  create_student: 'Create student',
  current_profile: 'Current profile',
  current_progress: 'Current progress',
  delete: 'Delete',
  delete_child_confirmation_message: 'If you confirm this action, the child\'s data will be deleted permanently',
  delete_child_confirmation_title: 'Are you sure?',
  delete_course_confirmation_message: 'If you confirm this action, this course data will be deleted permanently',
  delete_course_confirmation_title: 'Are you sure?',
  delete_level_confirmation_message: 'If you confirm this action, this level data will be deleted permanently',
  delete_level_confirmation_title: 'Are you sure?',
  delete_student_confirmation_message: 'If you confirm this action, this student data will be deleted permanently',
  delete_student_confirmation_title: 'Are you sure?',
  edit: 'Edit',
  edit_course: 'Edit curso',
  edit_course_students: "Edit course's students",
  edit_level: 'Edit level',
  edit_level_courses: 'Edit level courses',
  edit_student: 'Edit student',
  effort: 'Effort',
  effort_vs_performance: 'Effort VS Performance',
  email: 'Email',
  email_confirmation_form_resend_email_button: 'Resent confirmation email',
  email_confirmation_form_subtitle: 'Confirm your account by email',
  email_confirmation_form_title: 'Create new PleIQ account',
  email_placeholder: 'Email',
  email_placeholder_retype: 'Confirm your email',
  email_placeholder_retype_school_user: 'Confirm the email',
  email_retype_alert: 'Both emails must match',
  english: 'English',
  expired: 'Token expired, please request a new one',
  explore: 'Explore',
  faq: 'FAQ',
  first_name: 'Name',
  forgot_password: 'Forgot your password?',
  forum: 'Forum',
  how_find_my_code: 'How do I find my code?',
  how_find_my_code_instructions: 'Find your 9-character code within the PleIQ/Caligrafix product you own.',
  how_to_improve: 'How to improve?',
  intelligence_screen_achievements_label: 'Progress per skill',
  intelligence_screen_current_progress_label: 'Current progress',
  intelligence_screen_timeline_label: 'Progress over time',
  intelligences_progress_analysis: 'Intelligence progress analysis',
  interpersonal: 'Interpersonal',
  intrapersonal: 'Intrapersonal',
  kinesthetic: 'Kinesthetic',
  language: 'First language',
  language2: 'Secondary language',
  last_name: 'Last name',
  less_progress: 'Lower progress',
  less_worked: 'Less worked',
  level: 'Level',
  level_courses: 'Level courses',
  level_data: 'Level info',
  level_name: 'Level name',
  levels_courses_and_students: 'Levels, courses and students',
  linguistics: 'Linguistics',
  load_more: 'Load more',
  logic: 'Logic',
  login: 'Log in',
  logout: 'Log out',
  music: 'Music',
  name: 'Name',
  nature: 'Nature',
  new: 'New',
  new_record: 'New record',
  no_account_yet: "You don't have an account yet? Sign up here",
  no_activity_yet: 'No activity yet',
  no_children_yet: 'No children profile',
  not_pleiq_yet: "You don’t have a PleIQ/Caligrafix product yet?",
  oauth_form_subtitle: 'Sign up with:',
  oauth_form_title: 'Create a new PleIQ account',
  oauth_form_with_email: 'Sign up with email address',
  parent_settings_details: 'Personal details',
  parent_sign_up_form_subtitle: 'Enter your details',
  parent_sign_up_form_title: 'Create a new PleIQ account',
  password: 'Password',
  password_confirmation: 'Password confirmation',
  password_reset_successfully: 'Your password has been reset successfully',
  password_reset_token: 'Token',
  password_succesfully_updated: 'Your password has been updated successfully',
  passwords_should_match: 'Passwords should match',
  performance: 'Performance',
  pick_a_course: 'Search course by name',
  pick_a_student: 'Search student by name',
  play_and_comeback: 'Go play and come back later',
  pleiq_code: 'PleIQ code',
  pleiq_code_form_subtitle: 'Add the PleIQ code',
  pleiq_code_form_title: 'Create a new PleIQ account',
  popular: 'Popular',
  profiles: 'Profiles',
  progress: 'Progress',
  recent_activity: 'Recent activity',
  reports: 'Reports',
  reports_preferences_weekly: 'Weekly progress reports for child(ren)',
  receive_marketing_emails: 'Get news about PleIQ',
  terms_and_conditions: 'By pressing the Continue button, you accept our <a href="//pleiq.com/en/terms">terms and conditions</a>.',
  required: 'This input is required',
  reset_password_by_email: 'Reset password by email',
  reset_password_email_sent: 'You will receive an email with instructions on how to reset your password in a few minutes.',
  save: 'Save',
  school_current_general_progress: 'Current school progress',
  school_current_progress_by_course: 'Current progress by course',
  school_progress_by_intelligence_title: 'Skills worked by intelligence',
  school_settings_details: 'School details',
  schools: 'Schools',
  search: 'Search',
  search_student: 'Search student',
  settings: 'Settings',
  shop: 'Store',
  signin_form_subtitle: 'Introduce your credentials',
  signin_form_title: 'Log in',
  director_form_tab1_title_1: 'Educational Institution Data',
  director_form_tab1_title_2: 'Educational resources',
  director_form_tab1_title_3: 'Data First Administrator Educational Institution',
  director_form_tab1_title_4: 'Educational Institution created successfully',
  director_form_tab1_title_5: 'Educational Institution created successfully',
  director_form_placeholder_name: 'Name',
  director_form_placeholder_email: 'Email',
  director_form_placeholder_email_confirm: 'Confirm email',
  director_form_placeholder_confirm: 'example@pleiq.com',
  director_form_placeholder_confirm_confirm: 'example@pleiq.com',
  director_form_placeholder_served: 'Name of the Administrator + PleIQ',
  director_form_location: 'Location',
  director_form_placeholder_location: 'Choose a Location',
  director_form_language: 'Main language',
  director_form_placeholder_language: 'Choose a language',
  director_form_pleiq: 'Interactive cubes PleIQ',
  director_form_caligrafix: 'Interactive notebooks Caligrafix',
  director_form_yes: 'Yes',
  director_form_no: 'No',
  title_administrador_institucion_educativa: 'Administrator of educational institution',
  director_form_name: 'Name',
  director_form_email: 'Email',
  director_form_confirm: 'Confirm',
  director_form_served: 'Attended by',
  director_check_title1: 'Preschool line',
  director_check_cal1: 'Calligraphy 2nd Basic Script',
  director_check_cal2: 'Calligraphy 1st Basic 2nd Semester Script',
  director_check_cal3: 'Calligraphy 1st Basic 1st Semester Script',
  director_check_cal4: 'Journey to Reading',
  director_check_cal5: 'Calligraphy 2nd Basic',
  director_check_cal6: 'Calligraphy 1st Basic 2nd Semester',
  director_categoria_1: 'Preschool Line',
  director_categoria_2: 'Speech Therapy Line',
  director_categoria_3: 'Calligraphy Line',
  director_categoria_4: 'Calligraphy Script Line',
  director_categoria_5: 'Others',
  director_categoria_1_1: 'Strokes and Letters 1',
  director_categoria_1_2: 'Strokes and Letters 2',
  director_categoria_1_3: 'Logic and Numbers 1',
  director_categoria_1_4: 'Logic and Numbers 2',
  director_categoria_1_5: 'Balancin',
  director_categoria_2_1: 'Playing with Sounds 1',
  director_categoria_2_2: 'Playing with Sounds 2',
  director_categoria_2_3: 'Playing with Sounds 3',
  director_categoria_3_1: 'Calligraphy 1st Basic 1st Semester',
  director_categoria_3_2: 'Calligraphy 1st Basic 2nd Semester',
  director_categoria_3_3: 'Calligraphy 2nd Basic',
  director_categoria_4_1: 'Calligraphy 1st Basic 1st Semester Script',
  director_categoria_4_2: 'Calligraphy 1st Basic 2nd Semester Script',
  director_categoria_4_3: 'Calligraphy 2nd Basic Script',
  director_categoria_5_1: 'Journey to Reading',
  director_form_tab1_title: 'Data First Administrator Educational Institution',

  download_title: 'Download App for iOS & Android',
  download_subtitle: 'The PleIQ mobile app works on devices with iOS and Android operating systems for both tablets and smartphones.',
  download_content_title: 'Transform the Education: Download the App',
  download_content_subtitle: 'Download now',
  download_list_1: 'Improve multiple intelligences.',
  download_list_2: 'VR without lenses',
  download_list_3: 'More than 40 interactive experiences',
  title_administrador_institucion_educativa: 'Data First Administrator Educational Institution',
  spanish: 'Spanish',
  store: 'Store',
  student: 'Student',
  student_data: 'Student info',
  student_name: 'Student name',
  student_not_found_message: 'The student requested was not found',
  student_progress_name: 'Progress of %{name}',
  students: 'Students',
  students_search_not_found: 'No students found.',
  students_search_title: 'Search for students',
  tooltips_current_school_courses_average_progress: 'Comparison of general course progress for each level.',
  tooltips_current_school_courses_progress: 'Comparison of course progress for each intelligence.',
  tooltips_current_school_progress: 'Overall current progress accumulated by each intelligence.',
  tooltips_intelligence_screen_achievements_label: 'Progress according to individual skills.',
  tooltips_intelligences_progress_analysis: 'Key indicators of progress according to time and effort.',
  tooltips_student_vs_course_progress: 'Students vs Course',
  treasures: 'Treasures',
  unassigned: 'Unnasigned',
  unique_account: 'Unique Account',
  unique_account_config_step_1: 'Set your Unique Account credentials.',
  unique_account_config_step_2: 'Share the account credentials with your teachers.',
  unique_account_config_step_3: 'Use your credentials to log into your devices.',
  unique_account_form_title: 'Unique Account details',
  unique_account_info_paragraph_1: 'With the Unique Account, you can set up all the devices on your school using PleIQ without worrying about creating accounts for each device.',
  unique_account_info_paragraph_2: 'Set PleIQ in your school by following these 3 steps:',
  unique_account_info_title: 'What is Unique Account?',
  unique_account_update_warning_paragraph: 'If you proceed to change this info, all your devices using PleIQ with this account will be desynchronized and you\'ll need to log in manually on them.',
  unique_account_update_warning_title: 'Warning!',
  update_password: 'Update password',
  user_password_new_form_subtitle: 'You will receive an email with instructions to reset your password',
  user_password_new_form_title: 'Reset password instructions',
  user_password_reset_form_title: 'Type your new password',
  visual: 'Visual',
  where_my_code: 'Where do I find my code?',
  years_old: '%{smart_count} year old |||| %{smart_count} years old',
  your_email: 'Email',
  your_last_name: 'Last name',
  your_name: 'Name',

  /*
    error messages translations
    these are sorted by the code at the end, instead of alhabetically
   */
  auth_required_message: 'You should be logged in before doing that action.',
  connection_trouble_message: 'There is a problem trying to reach the server. <a href=".">Please try again.</a>',
  server_unavailable_message: 'Hello! We are currently making improvements to offer you a better service. Come back in a few moments!',
  invalid_credentials: 'Invalid email or password',
  invalid: 'The given information is not valid', 
  invalid_code: 'The code entered does not exist, check that it is well written',
  in_use: 'The code entered has already been used in an existing account',
  not_expired: 'You already have an active license corresponding to this code',
  no_internet_connection_message: `You don't have internet connection, please check your connection and <a href=".">try again.</a>`,
  pleiq_code_format: 'Code format must be: XXX-XXX-XXX',
  should_be_at_least_2: 'Use at least 2 characters',
  should_be_at_least_8: 'Use at least 8 characters',
  taken: 'The given value has been already taken by another user',
  unauthorized_account_role: 'The credentials provided cannot be used to access to this application',
  valid_email: 'Invalid email',
  reset_password_token_invalid: '<p>The link used has expired for security reasons after previous use or for 24 hours without using it since it was sent.</p><p>Set your account password by requesting a new link <a href="/users/password/new">click here.</a></p>',
  reset_password_param_missing: 'Invalid parameters, please contact support@pleiq.com if you believe this is an error.',
  session_expired: 'Your session has expired, please log in again.',
  license_expired: 'The license for this account has expired, contact your provider.',
  email_doesnt_match: 'Both emails must match',//'The email confirmation does not match.', 
  server_problem: '¡Oh! There was an error.',
  server_problem_retry: 'Try again.',

  /*
   * Translations for line chart
   */
  day: 'Daily',
  empty_key: '',
  month: 'Monthly',
  no_data: 'No Data',
  week: 'Weekly',
  week_date: 'Week',

  /*
   * Urls that change depending on language
   */
   buy_url: process.env.REACT_APP_BUY_URL_EN,
   activities_url: `${process.env.REACT_APP_ACTIVITIES_URL}/en`,

   /*
    * Screen titles translations
    */
   title_parent_dashboard: 'Progress',
   title_parent_intelligence: 'Intelligence Progress',
   title_registration_account_confirmed: 'Account confirmed',
   title_registration_activation: 'Activation',
   title_registration_children: 'Add children',
   title_registration_confirm_email: 'Confirm email',
   title_registration_congratulations: 'Congratulations',
   title_registration_reset_password: 'Reset password',
   title_registration_signin: 'Login',
   title_registration_signup: 'Sign up',
   title_school_challenges: 'Intelligence Progress',
   title_school_courses: 'Courses',
   title_school_progress: 'General Progress',
   title_school_search_students: 'Search students',
   title_school_unique_account: 'Unique Account',
   title_student_intelligence: 'Student Intelligence Progress',
   title_student_progress: 'Student Progress',
   title_user_settings: 'Account Settings',

   /**
    * Challenges
    */
  best_time: 'New Record',
  challenge_completed: 'Challenge Completed',
  LIN_VIS_1_Cards: 'Matching letters to pictures',
  LIN_VIS_2_Space: 'Recognize letters in space',
  MAT_NAT_1_Animals: 'Numbers & Counting',
  MAT_VIS_1_Balloons: 'Identify colors and shapes',
  NAT_KIN_1_Recycle: 'Recycle waste',
  MUS_LIN_1_Xylophone: 'Recognize musical notes',
  KIN_LIN_1_Uppercase: 'Trace uppercase letters',
  KIN_LIN_2_Lowercase: 'Trace lowercase letters',
  KIN_MAT_1_Numbers: 'Trace numbers',
  KIN_MAT_2_Shapes: 'Trace geometric shapes',
  VIS_NAT_1_Draw: 'Coloring shapes',
  INTRA_LIN_1_Emotions: 'Identify own emotions',
  INTER_LIN_Magic_Words: 'Recognize magic words',
  KIN_LIN_Cursive_Letters_Lowercase: 'Lowercase cursive letters', 
  KIN_LIN_Cursive_Letters_Uppercase:'Uppercase cursive letters', 
  MAT_NAT_1_Cells:'Sum of cells',
  LIN_VIS_1_Alphabet_Spiral: 'Spiral of letters',
  MUS_VIS_1_Notes_Runner:'Runner musical notes',


    
  MAT_VIS_1_Sequence: 'Sequence of numbers'	, 
        
  MAT_VIS_1_Rings: 'Quantity completion',		 
    
  LIN_VIS_1_Monster_Letters:'Phonetics of letters',		 


  LIN_MAT_Count_Syllables_Filling_1: "Identifying the number of syllables",
LIN_MAT_Count_Syllables_Filling_2: "Identifying the number of syllables 2",
LIN_VIS_Coloring_Syllables_1: "Coloring by number of syllables",
LIN_VIS_Coloring_Syllables_2: "Coloring by number of syllables 2",
LIN_VIS_Coloring_Syllables_3: "Coloring by number of syllables 3",
LIN_MAT_Count_Syllables_1: "Counting the syllables 1",
LIN_MAT_Count_Syllables_2: "Counting the syllables 2",
LIN_MAT_Count_Syllables_3: "Counting the syllables 3",
LIN_VIS_Initial_Syllable_1: "Initial syllable",
LIN_VIS_Initial_Syllable_2: "Initial syllable 2",
LIN_VIS_Medial_Syllable_1: "Medial Syllable",
LIN_VIS_Medial_Syllable_2: "Medial syllable 2",
LIN_VIS_Final_Syllable_1: "Final syllable",
LIN_VIS_Manipulation_Sylabic_1: "Syllable manipulation",
LIN_VIS_Manipulation_Sylabic_2: "Syllable manipulation 2",
LIN_KIN_Vocal_Initial_1: "Initial vowel",
LIN_KIN_Vocal_Initial_2: "Initial vowel 2",
LIN_VIS_Vowel_Initial_Relation_1: "Initial vowel pairs",
LIN_VIS_Vowel_Initial_Relation_2: "Initial vowel pairs 2",
LIN_VIS_Vowel_Final_Relation_1: "Recognize the final vowel",
LIN_VIS_Vowel_Sequence_1: "Vowel sequence",
LIN_VIS_Vowel_Sequence_Cards_1: "Final vowel sound",
LIN_VIS_Vowel_Sequence_Cards_2: "Vowel sequence",
LIN_VIS_Coloring_Consonantal_1: "Coloring by consonant initial sound",
LIN_MAT_Syllable_Kitchen_1: "Syllable kitchen 1",
LIN_VIS_Capture_Final_Syllable_1: "Final syllable in space",
LIN_VIS_Objects_Syllables_1: "Sum of syllables and objects 1",
LIN_VIS_Objects_Syllables_2: "Sum of syllables and objects 2",
LIN_VIS_Objects_Syllables_3: "Sum of syllables and objects 3",
LIN_VIS_Coloring_Final_Syllable_1: "Coloring by vowel ending sound",
LIN_VIS_Form_New_Words_1: "Form new words 1",
LIN_VIS_Memorice_Initial_Syllable_1: "Memorize the initial syllable",
LIN_VIS_Vocal_Medial_Final_Filling_1: "Medial and final vowel sound",
LIN_VIS_Sound_Consonantal_Cards_1: "Initial consonant sound",
LIN_VIS_Initial_Consonant_Timer_1: "Initial consonant sound in space",
LIN_VIS_Information_Integration_Cards_1: "Information integration",
LIN_VIS_Syllable_Medial_Relation_1: "Medial syllable pairs",
LIN_VIS_Grid_Medial_Consonantal_1: "Medial consonant sound P and M",
LIN_VIS_Grid_Final_Consonantal_1: "Final consonant sound N, L and S",
LIN_VIS_Grid_Initial_Consonantal_1: "Initial consonant sound F, G, B, R and V",
LIN_VIS_Capture_Final_Syllable_2: "Initial vowel in space",
LIN_VIS_Final_Consonant_Sound_1: "Final consonant sound R",
LIN_VIS_Medial_Consonant_Exp_1: "Medial Consonant Sound M",
LIN_VIS_Manipulation_Integration_Syllabic_1: "Syllabic manipulation 2",
LIN_VIS_Integration_Syllables_Space_1: "Integration of syllables in space",
LIN_VIS_Same_Final_Syllable_1: "GUS Same final syllable",
LIN_VIS_Vowel_Sequence_Cards_3: "Vowel sequence 2",
LIN_VIS_Consonant_Sequence_Cards_1: "Initial consonant sound 1",
LIN_VIS_Consonant_Sequence_Cards_2: "Medial consonant",
LIN_VIS_Phonemic_Synthesis_1: "Phonemic synthesis 1",
LIN_VIS_Phonemic_Synthesis_Cards_1: "Phonemic synthesis 2",
LIN_VIS_Phonemic_Analysis_1: "Phonemic analysis",
LIN_VIS_Phonemic_Integration_1: "Phonemic integration 1",
LIN_VIS_Phonemic_Integration_2: "Phonemic integration 2",
LIN_VIS_Build_Words_Syllables_1: "Build words using syllables",
VIS_KIN_1_Master_Pieces: "Coloring Masterpieces",
NAT_VIS_1_Ecosystem: "Ecosystems of the Earth",
INTRA_VIS_1_Creation_Emotions: "Creation of Emotions",
INTER_LIN_1_Inclusion_Values: "Inclusion values",
LIN_MAT_Count_Syllables_Filling_1: "Identifying the number of syllables",
LIN_KIN_Vocal_Initial_1: "Initial vowel",
LIN_VIS_Vowel_Sequence_Cards_2: "Vowel sequence",
LIN_VIS_Memorice_Initial_Syllable_1: "Memorize the initial syllable" ,
MAT_VIS_1_Sum_Sets:"Sum of sets 0 to 5",
MAT_VIS_2_Sum_Sets: "Sum of sets 6 to 10",
MAT_VIS_3_Sum_Sets: "Sum of sets 11 to 20",	
MAT_VIS_1_Subtraction_Sets:"Subtraction of sets 0 to 5",
MAT_VIS_2_Subtraction_Sets:"Subtraction of sets 6 to 10",
MAT_VIS_3_Subtraction_Sets:"Subtraction of sets 11 to 20",
MAT_VIS_Comparing_Weights_1:"Comparison of weights",
KIN_VIS_Pattern_Trace_1:"Mixed fundamental terms",
INTER_KIN_Lead_Flock_1:"Lead the flock",
INTRA_VIS_Emotion_Machine_1:"Emotion classifier",
VIS_KIN_Identify_Patterns_1:"Pattern recognition",
NAT_KIN_Recycle_Ocean_1:"Ocean rescue",
MUS_VIS_Musical_Instruments_1:"Musical instruments",
NAT_VIS_Sources_Energy_1:"Energy sources",



KIN_VIS_Modeling_Clay_1:"Create plasticine objects",
VIS_KIN_Identify_Volumes_1:"Identify volumes",
VIS_MAT_Color_Mixer_1:"Mix primary colors",
VIS_MAT_Color_Mixer_2:"Mix secondary colors",
VIS_NAT_3D_Coloring_1:"Color in 3D",
VIS_KIN_Master_Pieces_Artel_1:"Color in 2D",
VIS_KIN_Chromatic_Circle_1:"Sort the colors", 
VIS_NAT_Stickers_Histories_1:"Create your story",
VIS_MAT_Step_Drawing_1:"Draw step by step", 

KIN_LIN_Cursive_Letters_Pair_Vocals:"Uppercase and lowercase cursive vowel tracing",
LIN_VIS_Recognize_Words_1:"Words that start with vowels",
KIN_LIN_Cursive_Letters_Pair_Consonants:"Stroke uppercase and lowercase cursive letters 1",
LIN_VIS_Select_Image_Article_1:"Relationship of the article with the word according to gender",
LIN_VIS_Identify_Syllable_Word_1:"Identify initial syllable of words beginning with M",
LIN_VIS_Recognize_Words_3:"Match the sound of the syllable with the image",
LIN_VIS_Select_Image_Article_2:"Match definite articles with a word according to gender",
LIN_KIN_Discover_Words_Start_Letter_2:"Read words that start with letter D",
LIN_VIS_Recognize_Words_2:"Read words containing ca, co, cu",
LIN_KIN_Discover_Words_Start_Letter_1:"Identify words that begin with the letter T",
LIN_VIS_Select_Words_Rr_1:"Read words with r and rr recognizing their phoneme",
LIN_VIS_Form_Words_Contain_1:"Form words that lead H from syllables",
LIN_VIS_Order_Words_Sentence_1:"Order words to form sentences",
LIN_VIS_Select_Words_J_1:"Read words containing J",
LIN_VIS_Recognize_Words_4:"Identify syllabic initial sound ga, go, gu",
LIN_VIS_Recognize_Words_5:"Identify final syllabic sound ga, go, gu",
LIN_VIS_Recognize_Words_6:"Recognize syllabic sounds ga, go, gu, gue, gui, ge, gi",
LIN_VIS_Form_Words_Contain_2:"Form words that have letter b",
LIN_VIS_Identify_Article_1:"Match article with word according to gender",
LIN_VIS_Read_Words_F_1:"Read words that have F and classify them according to the number of syllables",
LIN_VIS_Recognize_Strong_Soft_R_1:"Recognize words that have strong r and soft r",
Kin_Lin_Cursive_Letters_Pair_Consonants_2:"Stroke uppercase and lowercase cursive letters 2",
Lin_Mat_Count_Syllables_Filling_3:"Identify number of syllables",
Lin_Vis_Underline_Word_1:"Identify words that take ll",
Lin_Kin_Read_Relation_1:"Read and match words singular plural",
Lin_Vis_Read_Words_1:"Read words with Ñ and their relationship with image",
Lin_Kin_Classify_Words_Syllables_1:"Classify words with V according to the number of syllables",
Lin_Kin_Read_Relation_Words_1:"Match the word with the drawing that represents it",
Lin_Vis_2_Space_Words_K:"Find words starting with K",
Lin_Vis_Form_Words_Contain_4:"Form words that have X from syllables",
Lin_Kin_Classify_Words_Syllables_2:"Classify words with Y according to number of syllables",
Lin_Kin_Discover_Words_Start_Letter_3:"Read words consonant cluster pr",
Lin_Vis_Form_Words_Contain_3:"Form words that take pl with syllables",
Lin_Kin_Words_Relation_Br_1:"Words consonant cluster br",
Lin_Vis_Completed_Words_Bl_1:"Complete words from the consonant cluster bl",
Lin_Vis_Order_Words_Sentence_2:"Order words to form sentences",
Lin_Kin_Discover_Words_Start_Letter_4:"Read words from the consonant cluster fr",
Lin_Vis_Consonantal_Groups_Read_Gr:"Read words consonant cluster gr",
Lin_Kin_Words_Rhyme_Relation_1:"recognize words that rhyme",
Lin_Vis_Consonantal_Groups_Read_Gr:"Read words consonant cluster gr",
Lin_Vis_Underline_Word_1:"Identify words that take ll",
Lin_Vis_Read_Description_Word_1:"Read and match the correct word description",
LIN_KIN_Discover_Words_Start_Letter_5:"Read words that are written with consonant clusters pl pr",
LIN_VIS_Select_Element_Group_1:"Distinguishing words from consonant clusters bl br",
LIN_VIS_Select_Element_Group_2:"Distinguish words that are written with cl cr",
LIN_KIN_Listening_Relation_Words_1:"Distinguish words consonant clusters gl gr",
LIN_VIS_2_Space_Words_Dr:"Recognize compound words consonant cluster dr",
LIN_KIN_Discover_Words_Start_Letter_6:"Read words that are written with ce ci",
LIN_VIS_Form_Words_Contain:"Form words with a certain number of syllables",
LIN_VIS_Complete_Word_1:"Distinguish words with güe, güi, gue, gui",
LIN_VIS_Complete_Word_2:"Distinguish words with the syllable ge, gi",
LIN_VIS_Found_Words_Qui_1:"Distinguish words with what or who",
LIN_VIS_Consonantal_Groups_Find_Fr_Fl:"Distinguishing words consonant clusters fr fl",

LIN_KIN_Read_Relation_Words_2:      "Match the word with the drawing that represents it",
LIN_VIS_Body_Parts_1:               "Body parts",
LIN_MAT_Count_Syllables_Filling_4 : "Identify number of syllables",
LIN_KIN_Machine_Words_1                      : "Distinguish words consonant clusters tl and tr",
LIN_VIS_Identify_Article_2                   : "Complete sentences with definite article that is coherent",
LIN_VIS_Identify_Article_3                   : "Select the appropriate item",
LIN_VIS_Recognize_Words_Number_Syllables_1   : "Recognize words according to the number of syllables",
LIN_VIS_Space_Letters_Hand_Written_1         : "Recognize handwritten letters of the alphabet",
LIN_VIS_Recognize_Common_Proper_Nouns_1      : "Recognize common and proper nouns",
LIN_VIS_identify_Personal_Pronouns_1         : "Read short texts that use personal pronouns",
LIN_VIS_Identify_Words_Start_Capital_Letter_1: "Recognize capitalized words",


LIN_KIN_Discover_Words_Start_Letter_7: "Identify words that begin with the letter T",
LIN_KIN_Kitchen_Words_Diphthongs_1:    "Read words with diphthong",
LIN_VIS_Read_Words_Fl_1              : "Read words consonant cluster fl",   
LIN_VIS_Identify_Antonyms_1          : "Match two words with opposite meanings",       
LIN_VIS_Interrog_Phrases_1           : "Underline interrogative and exclamatory sentences",       
LIN_VIS_Recognize_Strong_Soft_R_2    : "Distinguish words with r-rr",             
LIN_VIS_Identify_Verb_Tenses_1       : "Correctly use present, past and future",           
LIN_VIS_Identify_Words_Homonyms_1    : "Identify the meaning of a homonymous word",             
LIN_VIS_Identify_Diminutive_Words_1  : "Identify diminutive of given words",               
LIN_VIS_Identify_Augmentative_Words_1: "Identify augmentatives",  
LIN_VIS_Words_Rhyme_1:"Recognize words that rhyme",
LIN_VIS_Recognize_Verbs_1:"Associate image with actions.",
LIN_VIS_Identify_Words_Homophones_1:"Distinguish homophones",
LIN_VIS_Understand_Story_1:"Understand a story",
LIN_VIS_Complete_Word_3:"Recognize qualifying adjectives",
LIN_VIS_Use_Gender_Words_1:"Use gender words correctly",
LIN_VIS_Identifies_Synonyms_1:"Recognize synonyms",
LIN_VIS_Words_Singular_Plural_1:"Distinguish when singular and plural words are used",
LIN_VIS_Complete_Word_CR_1:"Complete sentences with the missing word",


LIN_VIS_Read_Words_CL_1:"Complete words consonant cluster cl.",
LIN_VIS_Read_Words_GL_1:"Read words that have the consonant cluster gl",
LIN_VIS_Read_Words_DR_1:"Read and understand sentences with words consonant cluster dr",
LIN_VIS_Comprises_Legends_1:"understand legends",
LIN_VIS_Understands_Fable_1:"understand fables",
LIN_VIS_Read_Comprehensive_News:"Comprehensive reading of news",
LIN_VIS_Read_Comprehensive_Recipes:"Comprehensively read a recipe",


  report_for_student: 'Report for student ',
  report_for_course: 'Report for course ',
  intelligence: 'Intelligence'

  ,
  license_count_exceded: "You have reached the maximum number of licenses in use, contact your supplier.",

  intelligences: "Intelligences",
  /* Caligrafix */
  book_less_used: "Less used notebook",
  book_most_used: "Most used notebook",
  total_use: "Total of hours worked",
  activities_completed : "Completed activities",
  caligrafix_current_general_progress: "Interactive notebook progress",
  caligrafix_progress_analysis: "Analysis of use",
  caligrafix: "Caligrafix",
  all_notebooks: "In all notebooks",
  tooltips_current_caligrafix_courses_progress: 'Comparison of course progress for each interactive notebook.',
  tooltips_current_caligrafix_progress: 'Overall current progress accumulated by each interactive notebook.',

  intelligences_progress: "Intelligences progress",
  total_time: "Total time learning with PleIQ app", 
  
  no_data: 'There is no data',

  comments_for: 'Anecdotal and Pedagogical Log for',
  student_comments: 'Anecdotal and Pedagogical Log',
  student_comments_short: 'Log',
  add_comment: "Edit",
  empty_comment: "Write your comment here",


  unassigned_courses: "Unassigned courses",
  ab_planification_title  : "Lesson plans",
  ab_orientation_title    : "Orientations",
  ab_orientation_tooltip  : "Suggested lesson plans for families",
  ab_planification_tooltip: "Suggested lesson plans for teachers",
  ab_search               : "Enter your search",
  ab_no_results           : "No results",
  ab_global_score         : "Global Assessment",
  ab_stars_tooltip        : "Qualification",
  ab_download_tooltip     : "Download in .PDF",
  ab_filters: "Filter",
  ab_clear_filter: "Clear filter",
  ab_loading: "Loading...",

  unassigned_students:"Unassigned profiles" ,


  student_representants: "Representatives",
  add_representative: "Add Representative",
  user_name: "Name",
  next: "Next",

  role_coordinator: "Coordinator",
  role_teacher: "Teacher",
  role_representative: "Representative",
  users: "Users",
  new_user: "New User",
  created_at: "Created At",

 
  title_registration_deletes: "Account Deletion",
  delete_account_form_title: "Account Deletion",
  delete_account_form_subtitle: "You can delete your account if you wish. If you change your mind later, you will not be able to recover it and the previously activated products cannot be reused, your data will be deleted from our platform.",
  reason: "Reason",
  account_delete_confirmation: "I have read and understand the implications of deleting my account.",
  delete_account: "Delete account", 
  delete_account_deleted: "Your account has been successfully deleted.",

  user_delete_not_parent: "School accounts cannot be deleted in this way, they must be requested by the institution.",


  role_teacher: "Educator",
  role_coordinator: "Administrator",
  role_representative: "Representative",
  role_observer: "Observer",
  new_user_teacher: "Create new Educator",
  new_user_coordinator: "Create new Administrator",
  new_user_representative: "Create new Representative",
  new_user_observer: "Create new Observer",
  user_children: "Students",
  we_will_send_email_new_user: "We will send an invitation email to the new user.",
  teacher_data: "Educator data",
  coordinator_data: "Administrator data",
  representative_data: "Representative data",
  observer_data: "Observer data",
  create: "Create",
  email_placeholder_retype_user:"Email confirmation",
  edit_teacher: "Edit Educator",
  edit_coordinator: "Edit Administrator",
  edit_representative: "Edit Representative",
  edit_observer: "Edit Observer",

  add_courses: "Add course",
  add_children: "Add student",

  student_representants: "Representatives",
  user_name: "Name",
  add_representative: "Add Representative",
  tooltips_profiles: "Manage the various profiles on the platform.",
  remove_representative_confirmation_title: "Are you sure?",
  remove_representative_confirmation_message: "If you confirm this action, the representative will be removed from this profile.",



  my_account: "My account",
  my_account_description: "Check your account settings on the platform.",
  my_books: "Interactive notebooks",
  my_books_description: "Check the progress of the school in the Caligrafix resources.",
  intelligences_description: "Check out the school's overall progress in multiple intelligences.",
  my_courses: "My courses",
  my_courses_description: "Review the student groups assigned to you.",
  progress_reports: "Progress reports",
  progress_reports_description: "Prepare your personalized progress reports.",
  curricular_coverage: "Curriculum coverage",
  curricular_coverage_description: "Review the covered learning objectives of the students.",
  activity_assignment: "Assignment of activities",
  activity_assignment_description: "Assign your students educational activities to do.",
  planifications_and_orientations: "Planning and orientation",
  planifications_and_orientations_description: "Pedagogical content and location of the pedagogical objectives associated with the content.",
  resources: "Resources",
  resources_description:"Discover the educational resources that you can download.",
  video_tutorials: "Video tutorials",
  video_tutorials_description: "Our collection of videos on everything related to the PleIQ ecosystem.",
  pleiq_learning: "Academy",
  pleiq_learning_description: "Access the preparation courses on PleIQ School and get certified in managing the platform.",
  educators_4_0: "Educators 4.0",
  educators_4_0_description: "Share experiences with other educators in our Facebook community",
  profiles_description: "Manage the profiles of the users who use the platform.",

  download: "Download",
  home: "Home",
  
  school_home_hello: "Hi $user!",
  school_home_subtitle: "What are we doing today?", 
  school: "School",
  learning: "Learning",
  administration: "Management",
  comming_soon: "Coming soon",
  premium_benefits: "Know the advantages of being Premium",
  premium: "Premium",

  resource_cube_lin: "Linguistic Cube",
  resource_cube_lin_desc: "PDF of the first cube of the set of 8 cubes to print.",
  resource_cube_lin_url: "https://pleiq.com/trial/pleiq_cube_templates_english.pdf",
  resource_pleiq_cube: "PleIQ Digital",
  resource_pleiq_cube_desc: "PDF of the set of 8 cubes to print.",
  resource_pleiq_cube_url: "https://pleiq.com/digital/pleiq_digital_templates_english.pdf",
  resource_didactic_guide: "Didactic Guide",
  resource_guide_latam_desc: "PDF of the LATAM version didactic guide.",
  resource_guide_latam_url: "https://pleiqpro.s3.amazonaws.com/Assets/PDF/didactic_guide_latam.pdf",
  resource_guide_chile_desc: "PDF of the didactic guide version Chile.",
  resource_guide_chile_url: "https://pleiqpro.s3.amazonaws.com/Assets/PDF/didactic_guide_chile.pdf",
  resource_cx_books: "Caligrafix + PleIQ interactive notebooks",
  resource_cx_cal_desc: "PDF Interactive Booklet Line Calligraphy (6 to 8 years),",
  resource_cx_cal_url: "https://pleiq.com/downloads/caligrafix_pleiq_interactive_book_cal.pdf",
  resource_cx_jcs_desc: "PDF Interactive Booklet Speech Therapy Line (4 to 6 years).",
  resource_cx_jcs_url: "https://pleiq.com/downloads/caligrafix_pleiq_interactive_book_jcs.pdf",
  resource_cx_ibook_desc: "PDF Interactive Booklet Preschool Line (3 to 5 years).",
  resource_cx_ibook_url: "https://pleiq.com/downloads/caligrafix_pleiq_interactive_book.pdf",
  
  tooltips_school_resources: "Discover the educational resources that you can download.",

  KIN_LIN_Script_Letters_Pair_Vocals: 	    "Uppercase and lowercase script vowel tracing",
  KIN_LIN_Script_Letters_Pair_Consonants:	  "Stroke uppercase and lowercase scriptletters 1",
  KIN_LIN_Script_Letters_Pair_Consonants_2:	"Stroke uppercase and lowercase script letters 2",

  video_tutorials_title              : "Video tutorials",
  video_tutorials_duration           : "Duration",
  video_tutorials_general_title      : "General",
  video_tutorials_books_title        : "Interactive notebooks",
  video_tutorials_cubes_title        : "Interactive cubes",
  video_tutorials_pleiq_school_title : "PleIQ School Platform",
  video_tutorials_pleiq_app_title    : "PleIQ App",
  level_books                        : "Interactive Notebooks",
  pick_a_book                        : "Search notebook by name",
  edit_level_books                   : "Edit interactive notebooks",
  add_book                           : "Add interactive notebook",
  progress_reports_for               : "%{name} progress reports",
  select_progress_report_type        : "Choose the type of progress report you want to see",
  select_progress_report_student     : "Select the student to consult the progress reports",
  select_progress_report_course      : "Select the course to consult the progress reports",
  created_by                         : "Created by",
  view                               : "See",
  date                               : "Date",
  create_new_progress_report         : "Create new report",
  by_course                          : "By course",
  by_student                         : "Per student",
  qualitative_report                 : "Qualitative report",
  domain                             : "Domain",
  teacher                            : "Teacher",
  progress_report_for                : "%{name} progress report",
  progress_report_explanation        : "The achievement domains that are automatically added to the report are defined by the PleIQ App markers and activities located in the interactive educational resources that students have worked on, where:",
  progress_report_legend             : "Not Observed = N Started = I In Process = P Acquired = A",
  progress_reports_premium_notice    : "In Premium mode you can generate unlimited reports and keep them in a history to consult them whenever you want, as well as compare them to see the evolution of your progress.",
  progress_report_reminder           : "Remember that you can select the domain box to modify or add its value.",
  compare_progress_reports           : "Compare reports",
  progress_report_saved              : "Progress report",
  progress_report_saved_message      : "The report has been successfully saved",
  _ok                                : "Understood",
  report                             : "Report",
  progress_report_type_select        : "Select the type of report to create",
  school_data                        : "school data",
  school_logo                        : "Logo",
  school_logo_msg                    : "Only images in PNG format are supported.",
  upload_image                       : "Upload Image",
  school_name                        : "Name",
  image_is_not_png                   : "The format must be PNG",
  image_is_too_big                   : "The image is very large", 
  delete_user_confirmation_title     :"Delete Confirmation",
  delete_user_confirmation_message   :"Are you sure to delete this profile?",
  next                               :"Next",
  tooltips_school_progress_reports   :"Generate progress reports based on learning objectives.",
  school_pro_promo_title:                       "Join Premium Schools",
  school_pro_promo_subtitle:                    "Go premium and take full advantage of all the features of the platform",
  school_pro_promo_free:                        "free version",
  school_pro_promo_premium:                     "premium version",
  school_pro_promo_interactive_resources:       "Using the interactive resources with the PleIQ application",
  school_pro_promo_progress_analysis:           "Progress monitoring and analysis",
  school_pro_promo_general_progress:            "Global progress report",
  school_pro_promo_planifications:              "Planning and orientations",
  school_pro_promo_progress_reports:            "Progress reports section",
  school_pro_promo_progress_reports_download:   "Unlimited saving, downloading and editing of progress reports",
  school_pro_promo_progress_reports_cmp:        "Progress Report Comparison",
  school_pro_promo_task_assignment:             "Activity Assignment (coming soon)",
  school_pro_promo_curricular_coverage:         "Curricular coverage reports (coming soon)",
  school_pro_promo_unlimited_profiles:          "Unlimited user profiles",
  school_pro_promo_unlimited_registry:          "Unlimited data histories",
  school_pro_promo_disclamer:                   "*Features available only during the 90-day trial period or by using the amounts available to try in the free version.",
  school_pro_promo_footer:                      "Write to us at <b>plataforma@caligrafix.com</b> and request more information to obtain the Premium version.",
  
  "school_pro_promo_planification_generator": "My Educative AI Assistant",


  school_home_cant_use_pro:      "The trial period for this premium feature has ended. Contact your consultant to purchase the premium mode or contact us at hola@pleiq.com.",
  school_home_cant_use_disabled: "This functionality is planned to be enabled soon.",


  LIN_VIS_Associate_Cognates_1    : "Remember and associate cognates of means of transport",
  LIN_KIN_Relation_3D_Figures_1   : "Remember and associate cognates of three-dimensional figures",
  LIN_KIN_Read_Understand_Select_1: "Read and understand",
  LIN_VIS_Apply_Cognates_1        : "Apply cognates in study",
  LIN_VIS_Face_Parts_1            : "Distinguish and remember vocabulary of the parts of the face",
  LIN_VIS_Recognize_Words_Rhyme_1 : "Recognize words that rhyme",
  LIN_VIS_Fluently_Read_Words_1   : "Fluently read high-frequency words",
  LIN_VIS_Vocabulary_Professions_1: "Distinguish and remember vocabulary of professions and trades",
  LIN_VIS_2D_Cognates_1           : "Remember and associate cognates of two-dimensional figures",
  LIN_VIS_Recognize_Synonym_1     : "Develop semantic awareness",
  LIN_VIS_Associate_Articles_1    : "Associate and write certain articles",
  LIN_VIS_Order_Words_1           : "Read, understand and write sentences",
  LIN_VIS_Riddle_1                : "Understanding texts: riddles",
  LIN_VIS_Read_TongueTwisters_1   : "Read and practice tongue twisters",

  LIN_VIS_Stories_FSC_2               : "Preguntas Lectura vocalizada #2",
  LIN_VIS_Stories_FSC_3               : "Preguntas Lectura vocalizada #3",
  KIN_VIS_Pattern_Trace_1             : "Trazos fundamentales mixtos",
  INTRA_VIS_Emotion_Machine_1         : "Clasificador de emociones",
  LIN_VIS_Dobble_1                    : "Dobble",
  LIN_VIS_Coloring_Syllables_4        : "Colorear por cantidad de sílabas",
  LIN_VIS_Position_Objects_1          : "Arriba y Abajo",
  INTER_VIS_Values_Positive_Negative_1: "Valores positivos y negativos", 
  LIN_VIS_Information_Integration_Cards_FSC_MX : "Integración de la información",
  LIN_KIN_Discover_Words_Start_Letter_8        : "Sopa de Letras",
  LIN_VIS_Stories_FSC_1                        : "Preguntas Lectura vocalizada #1",
  VIS_KIN_1_Free_Painting                      : "Pintura Libre",
  LIN_VIS_2_Space_Words_FSC_1                  : "Letras en el espacio",
  VIS_NAT_3D_Coloring_Zeppelin                 : "Colorear en 3D",

  planification_generator: "My Educative AI Assistant", 
  planification_generator_description: "Educative tools assisted by Artificial Intelligence.",
  planification_generator_disclamer: "This is an experimental tool that uses Artificial Intelligence. By using it, you acknowledge that it is your responsibility as a teacher to validate the pedagogical relevance of the generated content. ",

  school_planifications_intro:"What are we going to plan today?",
  school_planifications_library_title:"Lesson Plan Library",
  school_planifications_ai_desc:"Lesson Plan Generator Assisted by Artificial Intelligence (AI)",
  pg_gen_1: "Very well!, let's create a lesson plan with that pedagogical configuration",
  pg_gen_2: "Perfect! we are creating a lesson plan proposal with the characteristics you gave us.",
  pg_gen_3: "Great, we'll create a lesson plan proposal for you.", 
  pg_hello_1:"Hello, let's plan a class together.",
  pg_hello_2:"Hello, welcome to our lesson plan generator.",
  pg_hello_3:"Hello, let's plan a class. You can write or speak directly by pressing the microphone.",

  pg_chat_placeholder: "Write your message here", 
  pg_p_save: "Save",
  pg_p_download: "Download",
  pg_p_copy: "Copy",
  pg_p_make_similar: "Make a similar one",
  school_planifications_library_desc: "You can find your saved lesson plans here",
  pg_p_delete_title:"Delete lesson plan",
  pg_p_delete_desc:"Are you sure about eliminating this lesson plan? This action is not reversible",
  pg_p_delete_button:"Delete",

  title_download_app: "Download the App",


  LIN_VIS_Identifies_Beneficial_Harmful_Elements  : "Identify words that represent beneficial and harmful elements for the environment.",
  LIN_KIN_Tracing_Upper_Practice_1                : "Tracing linked capital letters from A to F.",
  LIN_KIN_Tracing_Lower_Practice_1                : "Tracing linked lowercase letters from A to F.",
  LIN_KIN_Tracing_Upper_Practice_2                : "Tracing linked capital letters from G to L.",
  LIN_KIN_Tracing_Lower_Practice_2                : "Tracing lowercase letters linked from G to L.",
  LIN_KIN_Tracing_Upper_Practice_3                : "Tracing linked capital letters from M to P.",
  LIN_KIN_Tracing_Lower_Practice_3                : "Tracing linked lowercase letters from M to P.",
  LIN_KIN_Tracing_Upper_Practice_4                : "Tracing linked capital letters from Q to V.",
  LIN_KIN_Tracing_Lower_Practice_4                : "Tracing lowercase letters linked from Q to V.",
  LIN_KIN_Tracing_Upper_Practice_5                : "Tracing linked capital letters from W to Z and digraphs (ch, ll, rr).",
  LIN_KIN_Tracing_Lower_Practice_5                : "Tracing linked lowercase letters from W to Z and digraphs (ch, ll, rr).",
  LIN_VIS_Association_Initial_Letter_Word_3       : "Baccalaureate",
  LIN_VIS_Read_Texts_Fluently                     : "Read texts fluently.",
  LIN_VIS_Sort_Alpha                              : "Sort alphabetically.",
  LIN_VIS_Article_Definite_Indefinite             : "Complete sentences with definite and indefinite articles.",
  LIN_VIS_Write_Nouns_Commons_Proper              : "Proper and common nouns.",
  LIN_VIS_Nouns_Plural_Singular                   : "Count nouns in plural and singular.",
  LIN_VIS_Noun_Gender                             : "Use the noun according to gender.",
  LIN_VIS_Relate_Nouns_Individual_Colectives      : "Relate individual and collective nouns.",
  LIN_VIS_Use_Qualifying_Adjectives_Correctly     : "Select qualifying adjectives according to the context.",
  LIN_VIS_Select_Demonstrative_Adjectives         : "Apply demonstrative adjectives according to the context.",
  LIN_VIS_Replace_Subject_Personal_Pronoun        : "Replace subject with personal pronoun.",
  LIN_VIS_Recognizes_Words_Same_Syllable          : "Recognize words that contain the same syllable.",
  LIN_VIS_Consonantal_Groups_Find_Ge_Gi           : "Distinguish words with the Ge-Gi consonant groups.",
  LIN_VIS_Apply_Plural_Words_3                    : "Use plural words.",
  LIN_KIN_Tracing_Upper_Practice_Script_1   : "Tracing capital script letters from A to F.",
  LIN_KIN_Tracing_Lower_Practice_Script_1   : "Tracing lowercase script letters from A to F.",
  LIN_KIN_Tracing_Upper_Practice_Script_2   : "Tracing capital script letters from G to L.",
  LIN_KIN_Tracing_Lower_Practice_Script_2   : "Tracing lowercase script letters from G to L.",
  LIN_KIN_Tracing_Upper_Practice_Script_3   : "Tracing capital script letters from M to P.",
  LIN_KIN_Tracing_Lower_Practice_Script_3   : "Tracing lowercase script letters from M to P.",
  LIN_KIN_Tracing_Upper_Practice_Script_4   : "Tracing capital script letters from Q to V.",
  LIN_KIN_Tracing_Lower_Practice_Script_4   : "Tracing lowercase script letters from Q to V.",
  LIN_KIN_Tracing_Upper_Practice_Script_5   : "Tracing uppercase script letters from W to Z and digraphs (ch, ll, rr).",
  LIN_KIN_Tracing_Lower_Practice_Script_5   : "Tracing lowercase script letters from W to Z and digraphs (ch, ll, rr).",
  LIN_VIS_Qualifying_Adjective_1            : "Association of initial letter with the word",
  LIN_VIS_Recognize_Diphthong_Words         : "Identify words that contain diphthong",
  LIN_VIS_Identify_Hiatus_Words             : "Identify the hiatus in the word.",
  LIN_VIS_Identify_Tonic_Atonic_Syllable    : "Identify the stressed and unstressed syllable in words.",
  LIN_VIS_Identify_Serious_Words            : "Identify and write serious words.",
  LIN_VIS_Identify_Esdrujulas_Words         : "Identify and write esdrújulas words.",
  LIN_VIS_Identify_Sobresdrújulas_Words     : "Identify and write over-esdrújula words",
  LIN_VIS_Apply_Rules_NV_DV_BV_Venir        : "Correctly apply the rules NV - DV - BV - Come.",
  LIN_VIS_Correct_Use_AHA                   : "Correctly apply the use of there, there, ay",
  LIN_VIS_Write_Nouns_4                     : "Write nouns applying the spelling rules.",
  LIN_VIS_Association_Initial_Letter_Word_4 : "Use the appropriate adjectives according to the context.",
  LIN_VIS_Identify_Verb_Time                : "Identify the verb tenses in a text.",
  LIN_VIS_Identify_Conjunctions             : "Identify the Conjunctions",
  LIN_VIS_Identify_Subject_Predicate        : "Identify the subject and predicate in a sentence.",

  LIN_VIS_Consonantal_Groups_Find_Gue_Gui : "Recognize words that contain the sounds GUE and GUI.",
  LIN_VIS_Conjugation_Verb                : "Verb conjugation.",
  LIN_VIS_Consonant_Cluster_3             : "Recognizes words that are written with the consonant group mp and mb.",
  LIN_VIS_Identifies_Diminutives_3        : "Identify the diminutives.",
  LIN_VIS_Write_Correct_Use_B             : "Correctly apply the letter b.",
  LIN_VIS_Write_Correct_Use_H             : "Correctly apply the letter h.",


  digital_paper: "Digital Paper Library",
  digital_paper_description: "Consult the Caligrafix notebooks in their digital version.",
  
  LIN_VIS_Relationship_Verbs         : "Relationship of verbs with image.",
  LIN_VIS_Demonstrative_Pronouns     : "Demonstrative pronouns.",
  LIN_VIS_Order_Syllables_Accordingly: "Order the syllables and create words using the syllables ge-gi-je-ji.",
  LIN_VIS_Figurative_Language_3      : "Interpret stanzas in figurative language.",
  LIN_VIS_Apply_Articles_4           : "Apply the articles correctly in a sentence.",
  LIN_VIS_Personal_Pronouns_4        : "Correctly replace nouns with pronouns.",
  LIN_VIS_Recognize_Nouns            : "Recognize nouns from demonstrative pronouns.",
  
  LIN_VIS_Classify_Words_Accordingly_3: "Classify words according to the syllables CE - CI.",

  LIN_KIN_Accent_Sharp_Words               : "Select and write sharp words.",
  LIN_VIS_Identify_Demonstrative_Adjectives: "Identify demonstrative adjectives in a text.",
  LIN_VIS_Adverds_Text_Oral_Written        : "Understand the function of adverbs in oral and written texts.",
  LIN_VIS_Use_Connectors_Sentence          : "Use the connectors according to the sentence.",
  LIN_VIS_Recognize_Prefixes_Suffixes      : "Recognize prefixes and suffixes.",
  LIN_KIN_Distinguish_Word_Family          : "Distinguish word family.",

  LIN_VIS_Recognize_Strong_Soft_3               : "Identify words that have güe or güi.",
  LIN_VIS_Words_Consonant_Groups                : "Distinguish words with nf and nv consonant groups.",
  LIN_KIN_Identify_Word_Families_3              : "Identify word families.",
  LIN_VIS_Apply_Prefix_3                        : "Apply the prefix in a sentence.",
  LIN_VIS_Suitable_Connectors_3                 : "Use the appropriate connectors.",
  LIN_VIS_Reading_Comprehension_Story_3         : "Reading comprehension of a legend.",
  LIN_VIS_Write_Letter_3                        : "Write a letter.",
  LIN_VIS_Identify_Prepositions                 : "Identify the prepositions.",
  LIN_VIS_Reading_Comprehension_Sayings_Proverbs: "Reading comprehension (figurative language: sayings and proverbs).",


  LIN_VIS_Reading_Comprehension_Legend_3         : "Reading comprehension of a story.",
  LIN_VIS_Reading_Comprehension_Non_literary     : "Includes a non-literary text.",
  LIN_VIS_Apply_Suffix_3                         : "Apply the appropriate suffix or not.",
  LIN_VIS_Understands_Texts_Non_literary_Comic   : "It includes a text based on comics.",
  LIN_KIN_Machine_Recognize_Parts_News           : "Recognize the parts of a news story.",
  LIN_VIS_Reading_Comprehension_News_3           : "Understands news texts.",

  LIN_VIS_Understands_Texts_Poem_P1   : "Poem reading self-assessment.",
  LIN_VIS_Understands_Texts_Interview : "It includes a text based on an interview.",
  LIN_VIS_Understand_Words_Spelling   : "Understand the origin of words and their importance in spelling.",
  LIN_VIS_Understands_Texts_Poem_P2   : "It includes a text based on the poem.",

  LIN_VIS_Reading_Comprehension_Poem_3  : "Interpret figurative language in a poem.",
  VIS_NAT_3D_Coloring_Chiloe            : "Chiloé Church.",
  LIN_VIS_Reading_Comprehension_Myth_P2 : "Reading comprehension (Myth).",
  LIN_VIS_Read_Comics_Comprehensively   : "Read the images and texts of a comic comprehensively.",
  MUS_VIS_Runner_Notes_Recipe           : "Identify the instructional texts (Recipe).",
  LIN_VIS_Informative_Marker_Biography  : "The biography 2.",
  LIN_VIS_Understands_Texts_News_P2     : "The news.",
  LIN_VIS_Understand_Text_Story_P1      : "Self-assessment includes a text based on the story.",
  LIN_VIS_Understand_Text_Story_P2      : "It includes a text based on the story.",
  LIN_VIS_Reading_Comprehension_Fable_P1: "Reading comprehension self-assessment (Fable).",
  LIN_VIS_Reading_Comprehension_Fable   : "Reading comprehension (Fable).",
  LIN_VIS_Reading_Comprehension_Myth_P1 : "Reading comprehension self-assessment (Myth).",
  LIN_VIS_The_Biography_P1              : "Self-assessment biography.",
  LIN_VIS_The_Biography_P2              : "The biography.",
  LIN_VIS_Understands_Texts_News_P1     : "Self-evaluation of the news.",
  LIN_VIS_Identify_Semantic_Fields      : "Identify the semantic fields.",
  LIN_VIS_Understand_Text_Poster        : "It includes a text based on a poster.",
  VIS_NAT_3D_Coloring_Beto_Rocket       : "Beto jetpack.",

  KIN_LIN_Letters_Script_Upper_TLN2S       : "Consonant tracing in uppercase script.",
  KIN_LIN_Letters_Script_Lower_TLN2S       : "Consonant tracing in lowercase script.",
  LIN_VIS_Identifying_Syllabic_Segmentation: "Identifying syllabic segmentation.",
  LIN_VIS_Same_Final_Syllable_2            : "Same Final Syllable.",
  LIN_VIS_Vocals_Script_Upper              : "Tracing the vowels in uppercase script.",
  LIN_VIS_Vocals_Script_Lower              : "Tracing vowels in lowercase script.",
  LIN_VIS_Letters_Script_Upper             : "Consonant tracing in uppercase script.",
  LIN_VIS_Letters_Script_Lower             : "Consonant tracing in lowercase script.",
  MAT_VIS_Identify_Location_Concepts       : "Identify location concepts: Above, below, inside, outside.",
  MAT_VIS_Perception_Object_Position       : "Perception of an object according to position.",
  MAT_KIN_Identify_Directions_Birds        : "Identify directionality: left-right, up-down.",
  MAT_VIS_Orientation_Before_After_1       : "Temporal orientations using notions and relationships of sequences (before-after) 1.",
  MAT_VIS_Orientation_Before_After_2       : "Orient yourself temporally using notions and sequence relationships (before-after) 2.",
  MAT_VIS_Orientation_Before_After_3       : "Orient yourself temporally using notions and sequence relationships (before-after) 3.",
  MAT_VIS_Classify_By_Attributes_Size      : "Classify by two or three attributes at a time (Size).",
  MAT_VIS_Classify_By_Attributes_Weight    : "Classify by two or three attributes at a time Weight (light-heavy).",
  MAT_VIS_Quantify_Compare_Quantities_1    : "Use numbers to count, identify, quantify and compare quantities 1.",
  MAT_VIS_Quantify_Compare_Quantities_2    : "Use numbers to count, identify, quantify and compare quantities 2.",
  MAT_VIS_Quantify_Compare_Form_1          : "Use numbers to count, identify, quantify and compare quantities.",
  MAT_VIS_Serialization_Quantity_1         : "Classify by serialization by quantity and numbers.",
  MAT_VIS_Sides_Vertices_Figures           : "Basic properties of geometric figures; number of sides and vertices.",
  MAT_VIS_Identify_Figures_3D              : "Identify attributes of 3D geometric bodies.",
  MAT_VIS_Create_Patterns_Elements_2       : "Create patterns of two or three elements 2.",


  LIN_VIS_Memorice_Initial_Syllable_2 : "Initial Syllable Memorize 2.",
  LIN_VIS_Manipulation_Sylabic_3      : "Form words with Syllabic Manipulation 2.",
  MAT_VIS_Attributes_Figures_2D_3D_1  : "Attributes of 2D and 3D figures 1.",
  MAT_VIS_Create_Patterns_Elements_1  : "Create patterns of two or three elements 1.", 
  MAT_VIS_Count_To_39                 : "Counting up to 39.",
  MAT_VIS_Number_Sequence_49          : "Number sequence up to 49.",

  MAT_VIS_Time_Orientation_Clock: "Orient yourself in time using a clock.",
  MAT_VIS_Tens_Composition_Tower: "Composition of 10; introduce the ten.",
  MAT_VIS_Monster_Numbers       : "Use the numbers of quantities from 11 to 19.",
  MAT_KIN_Tracing_Numbers_9     : "Tracing the numbers from 0 to 9.",
  MAT_VIS_Number_Sequence_Maze_1: "Number sequence up to 29.",
  MAT_VIS_Number_Sequence_69    : "Use the number sequence up to 69.",
  MAT_VIS_Count_Tens_10         : "Counting tens 10.",
  MAT_VIS_Count_Five_By_Five    : "Let's count by 5's.",

  VIS_LIN_3D_Coloring_Rocket_Lnn3             : "Increased creativity marker (geometric bodies)",
  MAT_VIS_Quantity_Relationship_92            : "Number-quantity relationship up to 92.",
  MAT_VIS_Additive_Composition_Decomposition  : "Additive composition and decomposition.",
  MAT_VIS_Subtraction_Until_20                : "Subtraction up to 20.",
  MAT_VIS_Measuring_Objects_1                 : "Measuring objects.",
  LIN_VIS_Join_Words_Rhyme_1                  : "Recognize words that rhyme.",
  LIN_VIS_Initial_Vocal_Couples_1             : "Initial Vowel Pairs.",

  LIN_VIS_Ocean_Stars_To_10          : "Rescuing starfish from the ocean up to 10",
  MAT_VIS_Identify_Number_Rings_1_9  : "Using numbers for quantities from 11 to 19.",
  MAT_VIS_Composition_Additive_1     : "Additive composition and decomposition.",
  MAT_VIS_Use_Numbers_1_To_29        : "Using numbers for quantities from 1 to 29.",
  MAT_VIS_Completing_Graphics        : "Completing graphs.",

  upload_csv_file: "Upload .CSV"
};
//module.exports = en;

export default en; 