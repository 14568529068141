/* @flow */

import { call, takeEvery, all } from 'redux-saga/effects';
import { workerRequest } from '../diplomatico/workers';
import type { Action } from '../diplomatico/types';

export const workerRequestSchoolCaligrafixBookProgressOptions = {
  requestParams: {
    endpoint: `/school/caligrafix_book_scores/current`,
    method: 'get'
  }
};

export const workerRequestSchoolCaligrafixBookProgress = workerRequest(workerRequestSchoolCaligrafixBookProgressOptions);

export const workerRequestSchoolGeneralCoursesCaligrafixBookProgressOptions = (level_id: string) => ({
  requestParams: {
    endpoint: `/school/levels/${level_id}/caligrafix_book_scores/current`,
    method: 'get',
    level_id
  }
});

export function* workerRequestSchoolGeneralCoursesCaligrafixBookProgress(action: Action): any {
  let { level_id } = action.payload;
  let workerRequestGenerator = workerRequest(workerRequestSchoolGeneralCoursesCaligrafixBookProgressOptions(level_id));

  yield call(workerRequestGenerator, action);
}

export const workerRequestSchoolGeneralCaligrafixBookHistoryOptions = ({ caligrafix_book_id, group_by, start_date, end_date }) => ({
  requestParams: {
    endpoint: `/school/caligrafix_book_scores/history?caligrafix_book_id=${caligrafix_book_id}&start_date=${start_date}&end_date=${end_date}&group_by=${group_by}`,
    method: 'get',
    caligrafix_book_id,
    group_by
  }
});

export function* workerRequestSchoolGeneralCaligrafixBookHistory(action: Action): any {
  let workerRequestGenerator = workerRequest(workerRequestSchoolGeneralCaligrafixBookHistoryOptions(action.payload));

  yield call(workerRequestGenerator, action);
}
 

export function* watchSchoolCaligrafixBookProgressRequest(): any {
  yield all([
    takeEvery('REQUEST_SCHOOL_GENERAL_CALIGRAFIX_BOOK_PROGRESS', workerRequestSchoolCaligrafixBookProgress),
    takeEvery('REQUEST_SCHOOL_GENERAL_COURSES_CALIGRAFIX_BOOK_PROGRESS', workerRequestSchoolGeneralCoursesCaligrafixBookProgress),
    takeEvery('REQUEST_SCHOOL_GENERAL_CALIGRAFIX_BOOK_HISTORY', workerRequestSchoolGeneralCaligrafixBookHistory)
  ]);
}
