/* @flow */

import React from 'react';
import { connect } from 'react-redux';
import { startSubmit } from 'redux-form';
import { compose, pure, withHandlers } from 'recompose';
import { FORM_NAME } from '../components/CourseDataForm';
import { CourseDataForm } from '../components/';
import type { ReduxFunction } from '../components/types';
import { showConfirmationModal } from '../../actions/confirmationModal';

type Props = {
  dispatch: ReduxFunction,
  initialValues: Object
};

const CourseDataFormContainer = (props: Props) => {
  return <CourseDataForm {...props} />;
};

export const CourseDataFormContainerHandlers = {
  onSubmit: ({ dispatch }: Props) => (values) => {
    const { course } = values;

    dispatch(startSubmit(FORM_NAME));

    // if course id is truthy, that means we should update it
    if (course.id) {
      dispatch({ type: 'REQUEST_COURSE_UPDATE', payload: { course } });
      return;
    }

    // otherwise, we create the course
    dispatch({ type: 'REQUEST_COURSE_CREATE', payload: { course } });
  },
  onDeleteClick: ({ dispatch, initialValues }: Props) => () => {
    const { course } = initialValues;
    const actions = [
      startSubmit(FORM_NAME),
      { type: 'REQUEST_COURSE_DELETE', payload: { course } }
    ];

    dispatch(showConfirmationModal({
      title: 'delete_course_confirmation_title',
      message: 'delete_course_confirmation_message',
      actions
    }));
  }
};

const CourseDataFormContainerComposed = compose(
  withHandlers(CourseDataFormContainerHandlers),
  pure
)(CourseDataFormContainer);

const mapStateToProps = (state, ownProps) => ({
  initialValues: { course: ownProps.course },
  persisted: !!(ownProps.course && ownProps.course.id)
});

const mapDispatchToProps = (dispatch) => ({ dispatch });

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CourseDataFormContainerComposed);
