/* @flow */

import type { Action } from '../diplomatico/types';

const initialState = 'es';

function language(state: string = initialState, action: Action) {
  let { type, payload } = action;

  switch (type) {
    case 'CHANGE_LANGUAGE':
      return payload.language;
    case 'UPDATE_USER_INFO':
      const { user_attributes } = payload;

      if (!user_attributes) {
        return state;
      } else {
        return user_attributes.preferences.locale || 'es';
      }
    case 'LOGGED_IN':
      const user = payload.body.user
      const locale = user.preferences.locale || 'es';

      return locale;
    default:
      return state;
  }
}

export {
  language,
  initialState
};

export default language;
