/* @flow */

import React from 'react';
import {  UniqueAccountSection } from '../components';
import { Col, Row } from 'reactstrap';
import { Modal, Paper, InfoTooltip, Button } from '../components';
import {
  SchoolDashboardNavContainer, SchoolDashboardHeadingContainer, HeaderContainer,
  PageTitleContainer
} from '../containers';
import SchoolUserContainerConnected from '../containers/school-admin/SchoolUserContainer';
import SchoolUsersContainerConnected, { SchoolUsersNav, SchoolUsersConnected, SchoolUsersNewButton } from '../containers/school-admin/SchoolUsersContainer';
import TranslationsHelper from '../hocs/TranslationsHelper';
import topScroll from '../hocs/topScroll';

/**
 * screen for the school SchoolUniqueAccount
 *
 * @method SchoolUniqueAccount
 *
 */
const SchoolUniqueAccount = ({queryParams,translations}) => {

  topScroll();
  
  const role = queryParams['role'] || 'coordinator';
  const page = queryParams['page'] || 1;
  const sort_column = queryParams['column'] || 'created_at';
  const sort_dir = queryParams['direction'] || 'asc';
  const user_id = queryParams['editing'];
  return (
    <div id="school-dashboard" className="school-dashboard layout-base">
      <PageTitleContainer pageTitle="profiles" />
      <HeaderContainer />
      <SchoolDashboardHeadingContainer />
      <Paper.Container lead>
        <SchoolDashboardNavContainer currentTab="profiles" />
        <Row> 
          <Col xs="12">
              <Paper.Title>
                <h1>{translations.t("profiles" )} <InfoTooltip message="tooltips_profiles" /> </h1> 
              </Paper.Title>
          </Col>   
          <Col xs="12"> 
            <SchoolUsersNav role={role}/>
          </Col> 
          <Col xs="12" >
            <div className="courses-actions">
              <SchoolUsersNewButton page={page} column={sort_column} direction={sort_dir}
                                  role={role}/>
            </div> 
          </Col>
          <Col xs="12"> 
            <SchoolUsersConnected  page={page} column={sort_column} direction={sort_dir}
                                role={role}/>
          </Col>
        </Row>
        <br/>
        {/* <Row>
          <Col xs="12"> 
            <UniqueAccountSection />
          </Col>

        </Row>  */}
        
      </Paper.Container>
      <SchoolUserContainerConnected page={page} column={sort_column} direction={sort_dir}
                                role={role} user_id={user_id}/>
    </div>
  );
};

export default TranslationsHelper(  SchoolUniqueAccount);
