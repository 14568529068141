/* @flow */

import React from 'react';
import footerBanner from '../../img/ui_banner_footer_01.png';

/**
 * A container component for the forms of the sign in / up section.
 *
 * @method RegistrationFooter
 *
 */
const RegistrationFooter = () => {
  return (
    <footer className="registration-footer">
      <img className="registration-footer-image img-fluid" src={footerBanner} alt="Footer Banner" />
    </footer>
  );
};

export default RegistrationFooter;
