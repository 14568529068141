/* @flow */

import rocketBundleImage from '../../img/banners/ui_store_experience_bundle_01.png';
import gameBundleImage from '../../img/banners/ui_store_experience_bundle_02.png';
import featured1 from '../../img/banners/ui_store_experience_banner_01.png';
import featured2 from '../../img/banners/ui_store_experience_banner_02.png';
import featured3 from '../../img/banners/ui_store_experience_banner_03.png';

/**
 * Model to hold the data about every bundle experience on the application
 * ideally this should live within the application state and pull the information
 * from the server, making this class a way only to fill the initial information
 * and adding fallbacks to show the expected content
 */
class ExperiencesBundle {
  static rocket = {
    image: rocketBundleImage
  };

  static game = {
    image: gameBundleImage
  };

  static featured = [
    {
      image: featured1
    },
    {
      image: featured2
    },
    {
      image: featured3
    }
  ];
}

export default ExperiencesBundle;
