/* @flow */

import React from 'react';
import { Button as BootstrapButton } from 'reactstrap';
import classNames from 'classnames';
import type { ButtonType } from './types';

type Props = {
  onClickButton?: Function,
  type: ButtonType,
  children?: React.Component<*>,
  className?: string,
  outline?: boolean,
  submit?: boolean
};


/**
 * A custom wrapper for the BootstrapButton to render the application
 * button, the idea is to be able to easily integrate custom rules to our buttons
 * in a single file.
 *
 * @method Button
 * @param  {function}             onClickButton   - a function to be call on click
 * @param  {React.Components}     children        - a tree of elements within the component
 * @param  {string}               type            - a valid string that represent the button type
 * @param  {boolean}              outline         - define if outline modificator is present to add classes like .btn-outline-primary
 * @param  {boolean}              submit          - define if button type HTML prop should be submit or not
 */
const Button = ({ onClickButton, children, type, className, outline, submit, ...otherProps }: Props) => {
  let buttonClass = classNames(className);

  return (
    <BootstrapButton
      type={submit ? 'submit' : 'button'}
      className={buttonClass}

      color={type}
      onClick={onClickButton}
      outline={outline}
      {...otherProps}>
      {children}
    </BootstrapButton>
  );
};

export default Button;
