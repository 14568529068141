/* @flow */

import React from 'react';
import { Field } from 'redux-form';
import { compose, pure } from 'recompose';
import { Button, InputField } from './';
import Translations from '../hocs/TranslationsHelper';
import { required } from './validations';
import type { TranslationsObject, ReduxFormFlag, ReduxFormFieldsArray } from './types';
import { FormGroup, Label } from 'reactstrap';
import { getBookInformation } from '../caligrafix/bookInfo';

type Props = {
  fields: ReduxFormFieldsArray,
  submitting: ReduxFormFlag,
  onRemoveButtonClick: () => mixed,
  translations: TranslationsObject
};

const BookField = ({ className, log, disabled, translations, ...otherProps }) => {
  const { input, label, name, placeholder, type, meta } = otherProps;
  const { dirty, error, submitting, submitFailed, active }: ReduxFormMeta = meta;

  const showErrors = !active && (submitFailed || dirty) && error;
 
  return (
    <FormGroup  >
        <Label style={{color: "rgba(0, 0, 0, 0.4)"}} for={name}>{ getBookInformation( input.value.slug).label  }</Label> 
      
    </FormGroup>
  );
};
const LevelBooksFieldArray = ({ fields, meta, onRemoveButtonClick, translations }: Props) => {
  const { submitting } = meta;
  return (
    <div className="course-inputs">
      {fields.map((name, index, fields) => (
        <div className="course-inputs-group" key={index}>
          <div className="course-inputs-input">
            <Field
              className=""
              name={`${name}`} 
              component={BookField} 
            />
          </div>
          <Button type="ball" onClickButton={() => onRemoveButtonClick(fields.get(index), index)} disabled={submitting}>
            <span className="pleiq-icon-icon_minus" />
          </Button>
        </div>
      ))} 
    </div>
  );
};

const LevelBooksFieldArrayComposed = compose(
  pure
)(LevelBooksFieldArray);

export default Translations(LevelBooksFieldArrayComposed);
