/* @flow */

import React from 'react';
import { GeneralIntelligenceProgressContainer } from './';
import { pure, compose, lifecycle } from 'recompose';
import { connect } from 'react-redux'

/**
 * Container that receives the data to display all the progress
 * over each of the Intelligences
 * @method SchoolGeneralIntelligenceProgressContainer
 * @param  {array}               school_general_intelligence_progress - injected by Redux includes all intelligence_progress from the store for the current child
 * @param  {array}               intelligences - injected by Redux includes all intelligences from the store
 */
export const SchoolGeneralIntelligenceProgressContainer = ({ school_general_intelligence_progress, intelligences }) => {
  return (
    <GeneralIntelligenceProgressContainer currentProgress={school_general_intelligence_progress} intelligences={intelligences} />
  );
};

export const SchoolGeneralIntelligenceProgressContainerLifecycle = {
  componentDidMount() {
    const { dispatch } = this.props;

    dispatch({ type: "REQUEST_SCHOOL_GENERAL_INTELLIGENCE_PROGRESS" });
  }
};

const SchoolGeneralIntelligenceProgressContainerComposed = compose(
  lifecycle(SchoolGeneralIntelligenceProgressContainerLifecycle),
  pure
)(SchoolGeneralIntelligenceProgressContainer);

const mapStateToProps = ({ school_general_intelligence_progress, intelligences }, ownProps) => {
  return {
    school_general_intelligence_progress,
    intelligences
  }
};

const mapDispatchToProps = (dispatch) => ({ dispatch });

const IntelligenceProgressContainerConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(SchoolGeneralIntelligenceProgressContainerComposed);

export default IntelligenceProgressContainerConnected;
