/* @flow */

import React from 'react';
import actions from '../../actions';
import { Dropdown } from '../components';
import { compose, lifecycle, withState, withHandlers, shouldUpdate } from 'recompose';
import { connect } from 'react-redux';
import _ from 'lodash';
import deepEqualsUpdate from '../../helpers/deepEqualsUpdate';
import { getSchoolProgressByCourse } from '../../selectors/schoolProgress';
import { Col, Row } from 'reactstrap';
import { SchoolCoursesProgressContainer, CoursesCompareChartContainer } from './';
 
/**
 * Container that receives data from the store and passes down the required data
 * @method SchoolGeneralCoursesProgressContainer
 */
const SchoolGeneralCoursesProgressContainer = ({ currentLevelId, onChangeLevel, levels, school_general_courses_progress }) => {
  //console.log("school progress", school_general_courses_progress);

  const progress = getCurrentLevelProgress(currentLevelId, school_general_courses_progress);
  const levelOptions = getLevelOptions(levels);
  //const level = _.find(levels, (l)=>(l.id===currentLevelId) );
 // console.log("copro", progress);
  return (
    <div>
        <Row>
          <Col xs="6" lg="4">
            <Dropdown
              value={currentLevelId} 
              onChange={onChangeLevel}
              options={levelOptions}
              noResultsText={false} 
            />
          </Col>
        </Row> 
        <SchoolCoursesProgressContainer progress={progress} /> 
        <CoursesCompareChartContainer levelId={currentLevelId} />

    </div>
  );
}; 


const getCurrentLevelProgress = (currentLevelId, school_general_courses_progress) => {
  return getSchoolProgressByCourse({ progress: school_general_courses_progress }, currentLevelId);
};

export const ContainerHandlers = {
  initialLevel: ({ levels }) => {
    return _.isEmpty(levels) ? '' : _.head(levels).id;
  },
  onChangeLevel: ({ dispatch, updateLevel }) => (nextLevel) => { 
    dispatch(actions.getSchoolGeneralCoursesProgress({ level_id: nextLevel.value }));
    updateLevel(nextLevel.value);
  }
};

export const ContainerLifecycle = {
  componentDidMount() {
    const { dispatch, currentLevelId } = this.props;

    dispatch({ type: 'REQUEST_LEVELS' });
    dispatch({ type: 'REQUEST_ALL_COURSES' });

    if (currentLevelId) {
      dispatch(actions.getSchoolGeneralCoursesProgress({ level_id: currentLevelId }));
    }
  },
  componentDidUpdate(prevProps: Object) {
    const { levels, currentLevelId, updateLevel, dispatch, school_general_courses_progress } = this.props;
    const level = _.head(levels);

    // prevent error whenever the school doesn't have any levels
    if (!level && !currentLevelId) {
      return;
    }

    // Levels request arrived
    if (!currentLevelId) {
      updateLevel(level.id);
    } else if (_.isEmpty(school_general_courses_progress)) {
      dispatch(actions.getSchoolGeneralCoursesProgress({ level_id: currentLevelId }));
    }
  }
};

const SchoolGeneralCoursesProgressContainerComposed = compose(
  withState('currentLevelId', 'updateLevel', ContainerHandlers.initialLevel),
  withHandlers(ContainerHandlers),
  lifecycle(ContainerLifecycle),
  shouldUpdate(deepEqualsUpdate)
)(SchoolGeneralCoursesProgressContainer);//(SchoolGeneralCoursesProgressContainerWithPdf); //

const getLevelOptions = (levels) => (_.map(levels, (level) => ({
  value: level.id, label: level.name
})));

const mapStateToProps = ({ school_general_courses_progress, levels,pdf }, ownProps) => {

  return {
    levels,
    school_general_courses_progress,
    pdf
  };
};

const mapDispatchToProps = (dispatch) => ({ dispatch });

const SchoolGeneralCoursesProgressContainerConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(SchoolGeneralCoursesProgressContainerComposed);

export default SchoolGeneralCoursesProgressContainerConnected;
