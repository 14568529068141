/* @flow */

import { enableInitializing } from 'initializable-reducer';
import type { Action } from '../diplomatico/types';

const initialState = {};

function schoolLevelsCollapseStructure(state: Object = initialState, { type, payload }: Action) {
  switch (type) {
    case 'SCHOOL_LEVELS_COLLAPSE_TOGGLE_ITEM':
      return {
        ...state,
        [payload.id]: !state[payload.id]
      };
    default:
      return state;
  }
}

export {
  schoolLevelsCollapseStructure,
  initialState
};

export default enableInitializing(schoolLevelsCollapseStructure);
