/* @flow */

import React from 'react';
import actions from '../../actions';
import { Dropdown, IntelligenceProgressBarChart } from '../components';
import { compose, lifecycle, withState, withHandlers, shouldUpdate } from 'recompose';
import { connect } from 'react-redux';
import _ from 'lodash';
import deepEqualsUpdate from '../../helpers/deepEqualsUpdate';
import { getSchoolProgressByCourse } from '../../selectors/schoolProgress';
import { Col, Row } from 'reactstrap';
import { SchoolCoursesProgressContainer, CoursesCompareChartContainer } from '.';
import { mockBooks, mockLevels } from '../mock/cx';
import Translations from '../hocs/TranslationsHelper';
 /*
 {
     [courseId]:{
         [bookId]: score
     }
 }
 
 */

const mockResponse2= ()=>{
  let ret = {};
  mockBooks.forEach(({id})=>{
    ret[id] = Math.random()*100;
  } );
  return ret;
  
} 
const mockResponse = mockResponse2();
 


const getBooksByLevel = (levelId, books)=>{
    return books.filter((b)=>(b.level_id==levelId));
}
/**
 * Container that receives data from the store and passes down the required data
 * @method CaligrafixGeneralCoursesProgressContainer
 */
const CaligrafixGeneralCoursesProgressContainer = ({translations, currentLevelId, onChangeLevel, courses, levels, books, current_progress }) => {
  // getCurrentLevelProgress(currentLevelId, current_progress);
  const levelOptions = getLevelOptions(levels);
  //const level = _.find(levels, (l)=>(l.id===currentLevelId) );

  current_progress = {};
  courses.forEach(({id}) => {
      current_progress[id] = mockResponse2();
  });
  const progress = current_progress;
  var booksInLevel = books; //getBooksByLevel(currentLevelId, books);
 
  const getData = (book) => courses.map(({id, name})=>{
    var prog = _.get(progress,id, {});
    return {
      id,
      name, 
      value: _.get(prog,book.id, 0)
    };
  });

/*
        <Row>
          <Col xs="6" lg="4">
            <Dropdown
              value={currentLevelId} 
              onChange={onChangeLevel}
              options={levelOptions}
              noResultsText={false} 
            />
          </Col>
        </Row>  */
  return (
    <div>
        <Row className="progress-bar-chart-row">
          {
            booksInLevel.map((book) => (
              <Col xs="12" md="6" key={book.slug}>
                <IntelligenceProgressBarChart
                  animated = {false}
                  title={book.name}
                  data={getData(book)}
                  color={book.color} />
              </Col>
            ))
          }
        </Row>
    </div>
  );
}; 
export const ContainerHandlers = {
  initialLevel: ({ levels }) => {
    return _.isEmpty(levels) ? '' : _.head(levels).id;
  },
  onChangeLevel: ({ dispatch, updateLevel }) => (nextLevel) => { 
    dispatch(actions.getSchoolGeneralCoursesProgress({ level_id: nextLevel.value }));
    updateLevel(nextLevel.value);
  }
};

export const ContainerLifecycle = {
  componentDidMount() {
    const { dispatch, currentLevelId } = this.props;

    dispatch({ type: 'REQUEST_LEVELS' });
    dispatch({ type: 'REQUEST_ALL_COURSES' }); 
    if (currentLevelId) {
      dispatch(actions.getSchoolGeneralCoursesProgress({ level_id: currentLevelId }));
    }
  },
  componentDidUpdate(prevProps: Object) {
    const { levels, currentLevelId, updateLevel, dispatch, school_general_courses_progress } = this.props;
    const level = _.head(levels);

    // prevent error whenever the school doesn't have any levels
    if (!level && !currentLevelId) {
      return;
    }

    // Levels request arrived
    if (!currentLevelId) {
      updateLevel(level.id);
    } else if (_.isEmpty(school_general_courses_progress)) {
      dispatch(actions.getSchoolGeneralCoursesProgress({ level_id: currentLevelId }));
    }
  }
};

const CaligrafixGeneralCoursesProgressContainerComposed = Translations( compose(
  withState('currentLevelId', 'updateLevel', ContainerHandlers.initialLevel),
  withHandlers(ContainerHandlers),
  lifecycle(ContainerLifecycle),
  shouldUpdate(deepEqualsUpdate)
)(CaligrafixGeneralCoursesProgressContainer) );//(SchoolGeneralCoursesProgressContainerWithPdf); //

const getLevelOptions = (levels) => (_.map(levels, (level) => ({
  value: level.id, label: level.name
})));

const mapStateToProps = ({ courses ,pdf }, ownProps) => {

  return {
    levels: mockLevels, 
    courses,
    books: mockBooks,
    pdf
  };
};

const mapDispatchToProps = (dispatch) => ({ dispatch });

const CaligrafixGeneralCoursesProgressContainerConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(CaligrafixGeneralCoursesProgressContainerComposed);

export default CaligrafixGeneralCoursesProgressContainerConnected;
