/* @flow */

import React from 'react';
import { CardProgress } from '../components/';
import { compose, withHandlers } from 'recompose';
import _ from 'lodash';
import { connect } from 'react-redux';
import actions from '../../actions';
import { PdfData } from '../../pdfer/Pdfable';
import LoadingStatus from './LoadingStatus';

import Translations from '../hocs/TranslationsHelper';
const getCardProgressContainerData = ({ achievementCards, achievementCardScores }) => {
  const data = _.map(achievementCards, (card) => {
    const achievementCardScore = _.find(achievementCardScores, {
      achievement_card_id: card.id
    }) || { score: 0 };

    return {
      id: card.id,
      title: card.name,
      excerpt: card.excerpt,
      description: card.description,
      value: parseInt(achievementCardScore.score, 10),
      tags: card.education_standards.map(({slug})=>slug),
      standards: card.education_standards
    };
  });

  return data;
};

const CardProgressContainer = ({ achievementCards, achievementCardScores, onClick }: Props) => {
  const data = getCardProgressContainerData({ achievementCards, achievementCardScores });

  return (
    <div className="container-card-progress">
      <LoadingStatus request="ACHIEVEMENT_CARDS"/>
      { data.map((props, key) => <CardProgress key={key} {...props} onClick={onClick} />) }
    </div>
  );
};

export const ContainerHandlers = {
  onClick: ({ dispatch }) => ({ title, html }) => {
    dispatch(actions.showHtmlContentModal({ title, html }));
  }
};

const ContainerComposed = compose(
  withHandlers(ContainerHandlers)
)(CardProgressContainer);

const mapDispatchToProps = (dispatch) => ({ dispatch });

const ContainerConnected = connect(
  null,
  mapDispatchToProps
)(ContainerComposed);

export default ContainerConnected;



export const CardProgressContainerPdf = Translations( ({ id,  achievementCards, achievementCardScores ,translations }: Props) => {
  const data = getCardProgressContainerData({ achievementCards, achievementCardScores });
  var cards = data.map((props, key) => <CardProgress key={key} {...props}  />);
  var maxs =  16;
  var splits = [];
  var i=0;
  while(i < cards.length){
    var n = Math.min(cards.length, i + maxs) -i; 

    splits.push(
      <PdfData key={splits.length} path={"card-prog-"+id+"-"+splits.length} pdf={{newpage:true}}>
        
        <h5 className="mt-5 mb-4">
            {translations.t('intelligence_screen_achievements_label')} {' '} 
        </h5>
      <div className="container-card-progress">{cards.slice(i, i+n)}</div>
    </PdfData>);

    i+=n;

  }// className="container-card-progress"
  return (
    <div>
      {splits }
    </div>
  ); // data.map((props, key) => <CardProgress key={key} {...props} onClick={onClick} />) }
}); 