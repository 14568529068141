/* @flow */

import React from 'react';
import { Form, FormGroup, FormFeedback } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import Translations from '../hocs/TranslationsHelper';
import { RegistrationForm, InputField ,InputFieldCheckbox} from './';
import type { ReduxFormFunction, ReduxFormErrorGlobal, ReduxFormFlag, TranslationsObject } from '../components/types';
import { required, email, minLength } from './validations';
import classNames from 'classnames';
import RegistrationSubmitButton from './RegistrationSubmitButton';
import { Link } from 'react-router-dom';
import { toBoolean } from './normalizers';

type Props = {
  handleSubmit: ReduxFormFunction,
  translations: TranslationsObject,
  error: ReduxFormErrorGlobal,
  submitting: ReduxFormFlag,
  valid: ReduxFormFlag
};

/**
 * Component to display step 1 of the login form
 *
 * @method DeleteAccountForm
 * @param  {function}  handleSubmit     - this callback gets injected by redux-form to handle form submit
 * @param  {Object}    translations     - inject by the Translations HoC to give access to locale text
 */
const DeleteAccountForm = ({ handleSubmit, translations, error, submitting, valid, ...otherProps }: Props) => {
  const formClassName = classNames({ 'has-danger': error }, 'form');
  const disabled = submitting;
  return (
    <RegistrationForm title={translations.t('delete_account_form_title')} subtitle={translations.t('delete_account_form_subtitle')}>
      <Form id="signin-form" className={formClassName} onSubmit={handleSubmit}>
        {
          error &&
          <FormGroup>
            <FormFeedback>{translations.t(error)}</FormFeedback>
          </FormGroup>
        }
        <Field
          className="text-center lowercased"
          type="text"
          name="email"
          placeholder={translations.t('email_placeholder')}
          disabled={disabled}
          component={InputField}
          validate={[ required ]}
        />
        <Field
          className="text-center"
          type="password"
          name="password"
          placeholder={translations.t('password')}
          disabled={disabled}
          component={InputField}
          validate={[ required]}
        /> 
        <Field
          className="text-center"
          type="text"
          name="reason"
          placeholder={translations.t('reason')}
          disabled={disabled}
          component={InputField} 
        /> 
        <Field
          className="parent-sign-up-form-newsletter text-center text-confirmation"
          type="checkbox"
          name="confirmation"
          label={translations.t('account_delete_confirmation')}
          disabled={submitting}
          component={InputFieldCheckbox}
          normalize={toBoolean}
          validate={[(a)=>!a]}
        />
        <div className="registration-form-button-wrapper">
          <RegistrationSubmitButton loading={submitting} disabled={!valid}>
            {translations.t('delete_account')}
          </RegistrationSubmitButton>
          <span className="registration-form-next-icon pleiq-icon-icon_arrow_right" />
        </div>  
      </Form>
    </RegistrationForm>
  );
};

export const DeletedAccountComponent = Translations(  ({   translations  } ) => {
   
  return (
    <RegistrationForm title={translations.t('delete_account_form_title')} subtitle={translations.t('delete_account_deleted')}></RegistrationForm>
  )
})

export const FORM_NAME = 'deleteAccountForm';

export default reduxForm({
  form: FORM_NAME
})(Translations(DeleteAccountForm));
