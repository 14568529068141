/* @flow */

import { call, takeEvery, all } from 'redux-saga/effects';
import { workerRequest } from '../diplomatico/workers';
import type { Action } from '../diplomatico/types';

/**
 * Worker to handle the request of Intelligence History
 */
export const workerRequestIntelligenceHistoryOptions = ({ child_id, intelligence_id, group_by, start_date, end_date }) => ({
  requestParams: {
    endpoint: `/children/${child_id}/intelligence_scores/history?intelligence_id=${intelligence_id}&start_date=${start_date}&end_date=${end_date}&group_by=${group_by}`,
    method: 'get',
    child_id,
    intelligence_id,
    group_by
  }
});

export function* workerRequestIntelligenceHistory(action: Action): any {
  let workerRequestGenerator = workerRequest(workerRequestIntelligenceHistoryOptions(action.payload));

  yield call(workerRequestGenerator, action);
}

export function* watchIntelligenceHistoryRequest(): any {
  yield all([
    takeEvery('REQUEST_INTELLIGENCE_HISTORY', workerRequestIntelligenceHistory)
  ]);
}
