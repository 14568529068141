/* @flow */

import React from 'react';
import { Form,FormFeedback,FormGroup } from 'reactstrap';
import { compose, pure } from 'recompose';
import { reduxForm, Field } from 'redux-form';
import { required } from './validations';
import { Button, InputFieldSelect, SubmitButton, UserChildForm } from './';
import Translations from '../hocs/TranslationsHelper';
import type { Student, ReduxFormFunction, TranslationsObject, ReduxFormFlag } from './types';
import classNames from 'classnames';

type Props = {
  student?: Student,
  submitting: ReduxFormFlag,
  onDeleteClick: () => mixed,
  persisted: boolean,
  coursesOptions: Array<Object>,
  valid: ReduxFormFlag,
  dirty: ReduxFormFlag,
  handleSubmit: ReduxFormFunction,
  translations: TranslationsObject
};

/**
 * component to display a form to edit/create a student info
 *
 * @method StudentDataForm
 * @param  {Array<Object>}  coursesOptions     - options to be displayed on the courses select
 * @param  {function}       handleSubmit       - injected by `redux-form`, handles form submition
 * @param  {boolean}        submitting         - injected by `redux-form`, indicates if the form is submitting
 * @param  {boolean}        valid              - injected by `redux-form`, indicates if the form is valid
 * @param  {boolean}        dirty              - injected by `redux-form`, indicates if the form have been edited
 * @param  {Object}         translations       - object containing app translations
 */
const StudentDataForm = ({error, coursesOptions, handleSubmit, onDeleteClick, persisted, submitting, valid, dirty, translations }: Props) => {
  const deleteButton = (
    <Button type="primary--grapefruit" onClickButton={onDeleteClick}>
      {translations.t('delete')}
    </Button>
  );
  const formClassName = classNames({ 'has-danger': error }, 'form', "student-data-form");

  return (
    <Form className={formClassName} onSubmit={handleSubmit}>
      <UserChildForm name="student" />
      <Field
        className="student-data-form-course-select"
        formGroupClassName="form-group-select"
        name="student.course_id"
        label={translations.t('course')}
        placeholder={translations.t('course')}
        selectOptions={coursesOptions}
        component={InputFieldSelect}
        searchable={true}
        validate={[ required ]}
      />
      <div className="student-data-form-actions">
        { error &&
            <FormGroup>
              <FormFeedback>{translations.t(error)}</FormFeedback>
            </FormGroup>}

        <SubmitButton loading={submitting} className="btn btn-primary--green" disabled={!dirty || !valid}>
          {translations.t('save')}
        </SubmitButton>
        {persisted && deleteButton}
      </div>
    </Form>
  );
};

export const FORM_NAME = 'studentDataForm';

const StudentDataFormComposed = compose(
  pure
)(StudentDataForm);

export default reduxForm({
  form: FORM_NAME,
  enableReinitialize: true
})(Translations(StudentDataFormComposed));
