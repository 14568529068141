/* @flow */

import { put, select, call, take, takeEvery, all } from 'redux-saga/effects';
import { workerRequest } from '../diplomatico/workers';
import { stopSubmit } from 'redux-form';
import { FORM_NAME as SCHOOL_COURSES_FORM } from '../js/components/SchoolCoursesForm';
import { FORM_NAME as SCHOOL_STUDENTS_FORM } from '../js/components/SchoolStudentsForm';
import { FORM_NAME as COURSE_DATA_FORM } from '../js/components/CourseDataForm';
import type { Action } from '../diplomatico/types';
import { setModalCourse, closeEditCourseModal, updateCourse, addCourse, deleteCourse } from '../actions/courses';
import { setModalLevel, updateLevel } from '../actions/levels';
import { getLevelById } from '../selectors/levels';
import { removeStudentsCourse, removeStudents, getStudents } from '../actions/students';

/**
 * Worker to get all the courses
 */
export const workerRequestAllCoursesOptions = {
  requestParams: {
    endpoint: `/school/courses`,
    method: 'get'
  }
};

export const workerRequestAllCourses = workerRequest(workerRequestAllCoursesOptions);

/**
 * Worker to request courses with a search param
 */
export const workerRequestSearchCoursesOptions = (query: string, page: number) => ({
  requestParams: {
    endpoint: `/school/courses?q=${query}&page=${page}`,
    method: 'get',
    page
  }
});

export function* workerRequestSearchCourses(action: Action): any {
  let { query, page, clean } = action.payload;
  let workerRequestGenerator = workerRequest(workerRequestSearchCoursesOptions(query, page));
  let actionToSend = {
    type: clean ? 'REQUEST_SEARCH_COURSES_CLEAN' : action.type,
    payload: action.payload
  };

  yield call(workerRequestGenerator, actionToSend);
}

/**
 * Worker to get the courses for current school
 */
export const workerRequestCoursesOptions = (levelId: string) => ({
  requestParams: {
    endpoint: ((levelId)? `/school/levels/${levelId}/courses` : '/school/courses/unassigned'),
    method: 'get'
  }
});

export function* workerRequestsCourses({ type, payload }: Action): any {
  const { level } = payload;
  const workerRequestGenerator = workerRequest(workerRequestCoursesOptions((level||{id:null}).id));
  yield call(workerRequestGenerator, { type, payload });
}

export const workerRequestCourseCreateOptions = {
  requestHandlers: {
    onSuccess: function* ({ action, response }: Object): any {
      const course = response.payload.body;
      yield put(addCourse(course));
      const level = yield select(getLevelById, course.level_id);
      yield put(updateLevel({ ...level, courses_count: level.courses_count + 1 }));
      yield put(stopSubmit(SCHOOL_COURSES_FORM));
    },
    onError: function* ({ action, response }: Object): any {
      yield put(stopSubmit(SCHOOL_COURSES_FORM));
    }
  },
  requestParams: {
    endpoint: `/school/courses`,
    method: 'post'
  },
  buildRequestBody: (actionPayload: Object) => actionPayload.course
};

export const workerRequestCourseCreate = workerRequest(workerRequestCourseCreateOptions);

/**
 * Worker to change a course's associated level
 */
export const workerRequestCourseUpdateOptions = (courseId: string) => ({
  requestHandlers: {
    onSuccess: function* ({ action, response }: Object): any {
      const course = response.payload.body;
      yield put(updateCourse(course));
      yield put(setModalCourse(course));
      yield put(stopSubmit(COURSE_DATA_FORM));
    },
    onError: function* ({ action, response }: Object): any {
      yield put(stopSubmit(COURSE_DATA_FORM));
    }
  },
  requestParams: {
    endpoint: `/school/courses/${courseId}`,
    method: 'put'
  },
  buildRequestBody: (actionPayload: Object) => actionPayload.course
});

export function* workerRequestCourseUpdate({ type, payload }: Action): any {
  const { course } = payload;
  const workerRequestGenerator = workerRequest(workerRequestCourseUpdateOptions(course.id));
  yield call(workerRequestGenerator, { type, payload });
}

/**
 * Worker to change a course's associated level
 */
export const workerRequestCourseUpdateLevelOptions = (courseId: string) => ({
  requestHandlers: {
    onSuccess: function* ({ action, response }: Object): any {
      const course = response.payload.body;
      const { original_level_id } = action.payload;
      const level = {
        ...action.payload.level,
        courses_count: action.payload.level.courses_count + 1
      };
      const originalLevel = yield select(getLevelById, original_level_id);
      yield put(updateCourse(course));
      yield put(updateLevel(level));
      yield put(updateLevel({ ...originalLevel, courses_count: originalLevel.courses_count - 1 }));
      yield put(setModalLevel(level));
      yield put(stopSubmit(SCHOOL_COURSES_FORM));
    },
    onError: function* ({ action, response }: Object): any {
      yield put(stopSubmit(SCHOOL_COURSES_FORM));
    }
  },
  requestParams: {
    endpoint: `/school/courses/${courseId}`,
    method: 'put'
  },
  buildRequestBody: (actionPayload: Object) => actionPayload.course
});

export function* workerRequestCourseUpdateLevel({ type, payload }: Action): any {
  const { course } = payload;
  const workerRequestGenerator = workerRequest(workerRequestCourseUpdateLevelOptions(course.id));
  yield call(workerRequestGenerator, { type, payload });
}

/**
 * Worker to delete a course
 */
export const workerRequestCourseDeleteOptions = (courseId: string) => ({
  requestHandlers: {
    onSuccess: function* ({ action, response }: Object): any {
      const { course } = action.payload;
      yield put(deleteCourse(course));
      const level = yield select(getLevelById, course.level_id);

      if (level) {
        yield put(updateLevel({ ...level, courses_count: level.courses_count - 1 }));
      }

      yield put(removeStudentsCourse(course));
      yield put(stopSubmit(COURSE_DATA_FORM));
      yield put(closeEditCourseModal());
    },
    onError: function* ({ action, response }: Object): any {
      yield put(stopSubmit(COURSE_DATA_FORM));
      yield put(closeEditCourseModal());
    }
  },
  requestParams: {
    endpoint: `/school/courses/${courseId}`,
    method: 'delete'
  },
  // no payload needed for this request
  buildRequestBody: (actionPayload: Object) => ({})
});

export function* workerRequestCourseDelete({ type, payload }: Action): any {
  const { course } = payload;
  const workerRequestGenerator = workerRequest(workerRequestCourseDeleteOptions(course.id));
  yield call(workerRequestGenerator, { type, payload });
}

export const workerRequestUpdateCourseStudentsOptions = (courseId: string) => ({
  requestHandlers: {
    onSuccess: function* ({ action, response }: Object): any {
      const { removedStudents, otherCourseStudents, newStudentsCount } = action.payload;
      const course = response.payload.body;
      yield put(removeStudents(removedStudents));

      if (newStudentsCount > 0) {
        yield put(getStudents(course));
        yield take('RESPONSE_STUDENTS');
      }

      yield put({ type: 'UPDATE_COURSE_STUDENTS_COUNT', payload: { students: otherCourseStudents } });
      yield put(updateCourse(course));
      yield put(stopSubmit(SCHOOL_STUDENTS_FORM));
      yield put(getStudents(null));
    },
    onError: function* ({ action, response }: Object): any { 
      yield put(stopSubmit(SCHOOL_STUDENTS_FORM, {_error: "license_count_exceded"}));
    }
  },
  requestParams: {
    endpoint: `/school/courses/${courseId}`,
    method: 'put'
  },
  buildRequestBody: (actionPayload: Object) => actionPayload
});

/**
 * Triggers the update of a level courses
 */
export function* workerRequestUpdateCourseStudents({ type, payload }: Action): any {
  const { course_id } = payload;
  const workerRequestGenerator = workerRequest(workerRequestUpdateCourseStudentsOptions(course_id));
  yield call(workerRequestGenerator, { type, payload });
}

export function* watchCoursesRequest(): any {
  yield all([
    takeEvery('REQUEST_ALL_COURSES', workerRequestAllCourses),
    takeEvery('REQUEST_COURSES', workerRequestsCourses),
    takeEvery('REQUEST_SEARCH_COURSES', workerRequestSearchCourses),
    takeEvery('REQUEST_COURSE_CREATE', workerRequestCourseCreate),
    takeEvery('REQUEST_COURSE_UPDATE', workerRequestCourseUpdate),
    takeEvery('REQUEST_COURSE_UPDATE_LEVEL', workerRequestCourseUpdateLevel),
    takeEvery('REQUEST_COURSE_DELETE', workerRequestCourseDelete),
    takeEvery('REQUEST_UPDATE_COURSE_STUDENTS', workerRequestUpdateCourseStudents)
  ]);
}
