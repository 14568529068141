/* @flow */

import React from 'react';
import { CaligrafixBookProgressAnalisisContainer } from './';
import { pure, compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getAllCaligrafixBookProgressByChild } from '../../selectors/caligrafixBookProgress';

/**
 * Container that receives the data to display the estadistics
 * of the user to be shown within simple cards
 * @method DataDisplayContainer
 *
 * @param  {array}         currentStudentProgress - injected by Redux includes all caligrafix_book_progress from the store for the current child
 * @param  {array}         caligrafix_books - injected by Redux includes all caligrafix_books from the store
 */
export const DataDisplayCaligrafixStudentContainer = ({user_books, currentStudentProgress, caligrafix_books }) => {
  return (
    <CaligrafixBookProgressAnalisisContainer user_books={user_books} currentProgress={currentStudentProgress} caligrafix_books={caligrafix_books} />
  );
};

const ContainerComposed = compose(
  pure
)(DataDisplayCaligrafixStudentContainer);

const mapStateToProps = ({user,  student_caligrafix_book_progress, caligrafix_books }, ownProps) => {
  const { params } = ownProps.match;
  const { student_id } = params;

  const currentStudentProgress = getAllCaligrafixBookProgressByChild({ caligrafix_book_progress: student_caligrafix_book_progress }, student_id);

  return {
    currentStudentProgress,
    caligrafix_books,
    user_books: user.caligrafix_books
  }
};

const ContainerConnected = connect(
  mapStateToProps
)(ContainerComposed);

export default withRouter(ContainerConnected);

const mapStateToProps2 = ({ user, student_caligrafix_book_progress, caligrafix_books }, ownProps) => { 
  const { student_id } = ownProps;

  const currentStudentProgress = getAllCaligrafixBookProgressByChild({ caligrafix_book_progress: student_caligrafix_book_progress }, student_id);

  return {
    currentStudentProgress,
    caligrafix_books,
    user_books: user.caligrafix_books
  }
};


export const DataDisplayCaligrafixStudentPdf = connect(mapStateToProps2)(ContainerComposed);
