/* @flow */

import type { Action } from '../diplomatico/types';

const initialState = {};

function requestErrors(state: string = initialState, action: Action) {
  let { type, payload } = action;
  if(type.startsWith("REQUEST_")){ 
    var  id = type.substr(8);
    var newState = Object.assign({}, state);
    delete newState[id];
    return newState;  
  }else if(type.startsWith("RESPONSE_")){ 
    var  id =  type.substr(9);

    if(payload.timeout|| payload.status >= 500){ 
        return Object.assign({}, state, {[id ]: payload});    
    }else{
        var newState = Object.assign({}, state);
        delete newState[id];
        return newState;

    } 
  }else if (type=="CLEAR_REQUESTS_"){
    return {}
  } else return state;
}

export {
    requestErrors,
  initialState
};

export default requestErrors;
