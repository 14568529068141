/* @flow */

import React from 'react';
import { connect } from 'react-redux';
import { pure, compose, withHandlers, lifecycle, defaultProps } from 'recompose';
import { DeleteAccountForm } from '../components';
import actions from '../../actions';
import { startSubmit, change } from 'redux-form';
import { FORM_NAME } from '../components/DeleteAccountForm';
import type { ReduxFunction, ReduxFormFunction, RecomposeHandler } from '../components/types';
import _ from 'lodash';

type Props = {
  dispatch: ReduxFunction,
  onSubmit: RecomposeHandler,
  handleSubmit: ReduxFormFunction,
  queryParams?: Object
};

const DeleteAccountFormContainer = ({ onSubmit, ...otherProps }: Props) => {
  return <DeleteAccountForm onSubmit={onSubmit} />;
};

export const DeleteAccountFormContainerHandlers = {
  onSubmit: (props: Props) => ({ email, password }: Object) => {
    const { dispatch } = props;
    dispatch(startSubmit(FORM_NAME));
    dispatch(actions.deleteAccount({ email, password }));
  }
};

const DeleteAccountFormContainerEnhancements = compose(
  withHandlers(DeleteAccountFormContainerHandlers),
  defaultProps({
    queryParams: {}
  })
);

const mapDispatchToProps = (dispatch) => {
  return { dispatch };
};

const DeleteAccountFormContainerLifecycle = {
  componentWillMount() {
    const queryParams = this.props.queryParams;

    if (queryParams.locale && _.includes(['es', 'en'], queryParams.locale)) {
      this.props.dispatch(actions.changeLanguage({ language: queryParams.locale }));
      this.props.dispatch(change(FORM_NAME, 'locale', queryParams.locale || 'es'));
    }
  }
};

const DeleteAccountFormContainerComposed = compose(
  DeleteAccountFormContainerEnhancements,
  lifecycle(DeleteAccountFormContainerLifecycle),
  pure
)(DeleteAccountFormContainer);

const DeleteAccountFormContainerConnected = connect(
  null,
  mapDispatchToProps
)(DeleteAccountFormContainerComposed);

export { DeleteAccountFormContainerConnected as DeleteAccountFormContainer }
export default DeleteAccountFormContainerConnected;
