/* @flow */

import React from 'react';
import LaddaButton, { XL, ZOOM_IN } from 'react-ladda';

type Props = {
  loading: boolean,
  disabled: boolean,
  children?: React.Component<*>
};

/**
 * button used on the registration forms
 *
 * @method RegistrationSubmitButton
 * @param  {function}  loading      - boolean that indicates if the button should be loading
 * @param  {function}  disabled     - boolean that indicates if the button should be disabled
 * @param  {Object}    children     - a tree of elements within the component
 */
const RegistrationSubmitButton = ({ loading, disabled, children }: Props) => {
  return (
    <LaddaButton
      loading={loading}
      disabled={disabled}
      className="btn btn-primary registration-form-next-button btn-block"
      type="submit"
      data-color="#eee"
      data-size={XL}
      data-style={ZOOM_IN}
      data-spinner-size={30}
      data-spinner-color="#ddd"
      data-spinner-lines={12}
    >
      {children}
    </LaddaButton>
  );
}

export default RegistrationSubmitButton;
