/* @flow */

import React, { useContext, useState } from 'react';
import { Col, Row  } from 'reactstrap';
import { Paper, InfoTooltip, Button } from '../components/';
import {
  DataDisplayParentContainer, IntelligencesTopicContainer, IntelligencesProgressContainer,
  ActivityFeedContainer, HeaderContainer, PageTitleContainer, SchoolDashboardNavContainer, SchoolDashboardHeadingContainer
} from '../containers/';
import '../../css/main.css';
import Translations from '../hocs/TranslationsHelper';
import TranslationsHelper from '../hocs/TranslationsHelper';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'react-router-redux';
 
import resources from "../../data/resources"
import { LIN_VIS_Read_Comprehensive_News } from '../../locales/en';
import { Link } from 'react-router-dom';
import { navItemHome } from '../components/SchoolDashboardNav';
import topScroll from '../hocs/topScroll';
 
/**
 * Render SchoolResources screen
 * @method SchoolResources
 * @param  {object}                    translations - injected by our Translations HoC to give translated strings
 */
const SchoolResources = ({ translations }) => {
  const user = useSelector(({user})=>user);
  topScroll();
  return (
    <div id="school-dashboard" className="school-dashboard layout-base">
    <PageTitleContainer pageTitle="resources" />
    <HeaderContainer />
    <SchoolDashboardHeadingContainer />
    <Paper.Container lead>
      <SchoolDashboardNavContainer items={[navItemHome,{ 
          name: 'resources',
          translation: 'resources',
      }]} currentTab="resources" hideSearch />
      <Row> 
        <Col xs="12">
            <Paper.Title>
              <h1 >{translations.t("resources" ) } {' '}
                <InfoTooltip message="tooltips_school_resources" />
              </h1> 
              
            </Paper.Title>
        </Col>    
        {
          resources.map(({id,items,shadow, licenses})=>{
            
            if(!licenses.includes(user.license_type) ||
                !translations.has("resource_"+id)){
              return null;
            }else return <Col xs="12">
              <div>
                <p className='school-home-section-title'>{translations.t("resource_"+id)}</p>
                <div className='school-home-links'>
                   {
                     (items || [id]). map((cid)=>(
                        <div className="school-resources-resource">
                          <div className="school-resources-resource-icon">
                            <img src={`/resources/${cid}.png`} alt={"icon"} className={
                              shadow&& "w-border"
                            }/>
                          </div>
                          <span>{translations.t("resource_"+ cid+"_desc")}</span>
                          <Button
                          type="primary--blue"    
                            onClick={
                              ()=>window.open(translations.t("resource_"+ cid+"_url"), '_blank').focus()}
                            > 
                            {translations.t("download")}
                            </Button>
                        </div>
                     ))
                   }
                </div>
              </div>
            </Col>
          })

        }
        <Col xs="12" >
           
        </Col>
       
      </Row> 
    </Paper.Container> 
  </div>
  );
};

export default Translations(SchoolResources);
