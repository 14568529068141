/* @flow */

import React from 'react';
import { compose, pure, withHandlers, withState } from 'recompose';
import ColorSelect from './ColorSelect';
import InputTextWithCircle from './InputTextWithCircle';
import type { Child, RecomposeStateName, RecomposeHandler, RecomposeStateUpdater } from './types';

type Props = {
  expanded: RecomposeStateName,
  onUpdateChild: () => mixed,
  selectedColor: string,
  setExpanded: RecomposeStateUpdater,
  toggleExpanded: RecomposeHandler,
  updateChildColor: () => mixed,
  child: Child,
  submitting: boolean
};

/**
 * A component to display a normal text input, with a color select input
 *
 * @method InputTextWithColor
 * @param  {boolean}        expanded          - injected by `recompose` as an state, indicates what child should render
 * @param  {function}       setExpanded       - injected by `recompose` as an state updater, to update expanded state
 * @param  {Object}         child             - currently selected child
 * @param  {Function}       toggleExpanded    - injected by `recompose` as a handler, it calls setExpanded
 *
 */
const InputTextWithColor = ({ expanded, selectedColor, toggleExpanded, onUpdateChild, updateChildColor, submitting, child }: Props) => {
  return expanded
    ? <ColorSelect onClick={updateChildColor} selected={selectedColor} />
    : <InputTextWithCircle submitting={submitting} color={selectedColor} onClick={toggleExpanded} onUpdateChild={onUpdateChild} child={child} />;
};

export const InputTextWithColorHandlers = {
  toggleExpanded: ({ setExpanded }: Props) => () => setExpanded(expanded => !expanded),
  updateChildColor: ({ setExpanded, onUpdateChild }: Props) => (childInfo: Object) => {
    setExpanded(false);
    onUpdateChild(childInfo);
  }
};

const InputTextWithColorComposed = compose(
  withState('expanded', 'setExpanded', false),
  withHandlers(InputTextWithColorHandlers),
  pure
)(InputTextWithColor);

export default InputTextWithColorComposed;
