/* @flow */

import { responseHasError } from '../diplomatico/reducers/helper';
import { enableInitializing } from 'initializable-reducer';
import type { Action } from '../diplomatico/types';
import {APP_VERSION_STR} from '../js/hocs/VersionHandler'
const initialState = {
  is_logged_in: false
};

function user(state: Object = initialState, { type, payload }: Action) {
  switch (type) {
    case 'SET_PLEIQ_CODE':
      if (responseHasError(payload)) {
        return state;
      }

      return {
        ...state,
        pleiq_code: payload.pleiq_code
      };
    case 'RESPONSE_PARENT_ACTIVATE':
      if (responseHasError(payload)) {
        return state;
      }

      return {
        ...state,
        ...payload.body
      };
    case 'LOGGED_IN':
      if (payload.status >= 400) {
        return state;
      } 
      return {
        ...payload.body.user,
        apprender_sid: payload.apprenderSid,
        is_logged_in: true,
        app_version: APP_VERSION_STR
      };

    case 'CLEAR_USER': 
      return {
        ...state,
        is_logged_in: false
      };
    case 'UPDATE_USER_INFO':
      return {
        ...state,
        ...payload.body,
      };
    case 'ADD_CHILD':
      return {
        ...state,
        children_count: state.children_count + 1
      };
    case 'DELETE_CHILD':
      return {
        ...state,
        children_count: state.children_count - 1
      }
    case 'RESPONSE_CHILDREN':
      const children = payload.body;
      return {
        ...state,
        children_count: children.length
      };
    default:
      return state;
  }
}

export {
  user,
  initialState
};

export default enableInitializing(user);
