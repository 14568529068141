/* @flow */

import React from 'react';
import { IntelligenceProgressAnalisisContainer } from './';
import { pure,lifecycle,  compose } from 'recompose';
import { connect } from 'react-redux'
import { getUniqueAccount, editPassword, exitEditMode } from '../../actions/uniqueAccount';

type Props = {
  translations: Object
};

/**
 * Container that receives the data to display the estadistics
 * of the user to be shown within simple cards
 * @method DataDisplayContainer
 *
 * @param  {array}         school_general_intelligence_progress - injected by Redux includes all intelligence_progress from the store for the current child
 * @param  {array}         intelligences - injected by Redux includes all intelligences from the store
 */
export const DataDisplayGeneralSchoolContainer = ({ school_general_intelligence_progress, intelligences,totalTime }: Props) => {
  return (
    <IntelligenceProgressAnalisisContainer totalTime={totalTime} currentProgress={school_general_intelligence_progress} intelligences={intelligences} />
  );
};

export const DataDisplayGeneralSchoolContainerLifecycle = {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(getUniqueAccount());
  }
};

const DataDisplayGeneralSchoolContainerComposed = compose(
  pure,
  lifecycle(DataDisplayGeneralSchoolContainerLifecycle) 
)(DataDisplayGeneralSchoolContainer);

const mapStateToProps = ({ school_general_intelligence_progress, intelligences, school_total_time, unique_account }, ownProps) => {
 
  return {
    totalTime: unique_account.total_time , 
    school_general_intelligence_progress,
    school_total_time,
    intelligences
  }
};

const mapDispatchToProps = (dispatch) => ({ dispatch });

const DataDisplayGeneralSchoolContainerConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(DataDisplayGeneralSchoolContainerComposed);

export default DataDisplayGeneralSchoolContainerConnected;
