/* @flow */

import { enableInitializing } from 'initializable-reducer';
import { responseHasError } from '../diplomatico/reducers/helper';
import type { Action } from '../diplomatico/types';

const initialState = {};

function coursesProgressOverTime(state: Object = initialState, { type, payload }: Action) {
  switch (type) {
    case 'RESPONSE_COURSES_PROGRESS_OVER_TIME':
      if (responseHasError(payload)) {
        return state;
      }

      const { level_id } = payload._meta.payload.request;

      return {
        ...state,
        [level_id]: payload.body
      };
    default:
      return state;
  }
}

export {
  coursesProgressOverTime,
  initialState
};

export default enableInitializing(coursesProgressOverTime);
