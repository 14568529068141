
import React from 'react';
import { GeneralCaligrafixBookProgressContainer } from './';
import { pure, compose, lifecycle, renderComponent } from 'recompose';
import { getAllCaligrafixBookProgressByChild } from '../../selectors/caligrafixBookProgress';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import actions from '../../actions';
import { findStudentAcrossReducers, getStudentById } from '../../selectors/students';
import _ from 'lodash';
import StudentCommentsList from '../components/StudentCommentList';
import StudentCommentFormContainer from './StudentCommentFormContainer';
import { Modal, Paper, Button, InputField } from '../components';
import { Col, Row } from 'reactstrap'; 
import Translations from '../hocs/TranslationsHelper';
import iconHeader from "../../img/ui_icon_comment_section.svg"
import { closeStudentCommentsModal, showCommentsStudent } from '../../actions/students';
import RoleFilter from './RoleFilter';



const StudentCommentsListContainer = ({ student_id, user,student, comments, translations,  dispatch, isStatic}) => {
   
  return ( 
      <div>
        { 
        }
        <StudentCommentsList comments={comments}
          user={user} 
          isStatic ={isStatic}
          onDelete={
              (comment_id)=>dispatch(actions.deleteStudentComment({student_id,comment_id}))
          } 
          onEdit={
              (comment_id, content)=> dispatch(actions.updateStudentComment({student_id, comment_id, content}))
          }
          />  

      </div>
  );
}; 
export const StudentCommentsListContainerLifecycle = {
  componentDidMount() {
    const { dispatch, student_id } = this.props;  
   // console.log("DID MOUNT", this.props);
    if (student_id) {
      dispatch(actions.getStudentComments({ student_id }));
    }
  }
};

const StudentCommentsListContainerComposed = compose(
  lifecycle(StudentCommentsListContainerLifecycle),
  pure
)(StudentCommentsListContainer);



const mapStateToProps2 = (state , ownProps) => {  
    const {  student_comments,user} = state;

    const student_id = ownProps.student_id;// getStudentById(state, student_id);
    const comments =  student_comments[student_id];
    const student = getStudentById(state,student_id);
    return {
        comments: (comments|| []).slice().reverse(), 
        student_id ,
        student,
        user
    }
};
  
const mapDispatchToProps2 = (dispatch) => ({ dispatch } );
  
export const StudentCommentsListContainerConnected = connect(
    mapStateToProps2,
    mapDispatchToProps2
  )(Translations( StudentCommentsListContainerComposed));
   


class StudentCommentListContainer extends React.Component{
   
  constructor(props){super(props);}

  componentDidMount(){ 
    const { dispatch, student_id } = this.props;   
    if (student_id) {
      dispatch(actions.getStudentComments({ student_id }));
    } 
  }
  componentDidUpdate(prevProps){
    const { dispatch, student_id } = this.props;   
    if(this.divr){
      this.divr.scrollTop = this.divr.scrollHeight;
    }
    if(prevProps.student_id !== student_id &&  student_id){
      dispatch(actions.getStudentComments({ student_id }));
    }
  }

  render(){
    const {active, translations, student_id,studentName,  user, comments, closeModal,  dispatch} = this.props;


    return (
      <Modal
      className="comments-modal"
      backdropClassName="overlay-backdrop"
      contentClassName="overlay-modal-content"
      active={active}
      toggle={()=>{}}> 
      
      <div className="modal-comments">
        <div className="modal-comments-header">
          <div className="modal-comments-header-icon">
            <img src={iconHeader}/>
          </div>
          <div className="modal-comments-header-title"> 
            <span>{translations.t("student_comments")}</span>    
            <span className="modal-comments-header-title-student">{studentName}</span>   
          </div>
          <span  className="modal-comment-close pleiq-icon-icon_plus" onClick={closeModal} />
          
        </div>
  

        <div className="modal-comments-content" ref={(tg)=>{this.divr = tg;}}>  
          <StudentCommentsList user={user} comments={comments} 
            onDelete={
                (comment_id)=>dispatch(actions.deleteStudentComment({student_id,comment_id}))
            } 
            onEdit={
                (comment_id, content)=> dispatch(actions.updateStudentComment({student_id, comment_id, content}))
            }
            />  
        </div>
        <RoleFilter roles={['teacher', 'coordinator']}>

          <div className="modal-comments-control">{
            (active)?
            <StudentCommentFormContainer student_id={student_id}/>      
            :null
          }
          </div>
        </RoleFilter>
      </div>
    
      </Modal>  
    );
  }
}

StudentCommentListContainer = Translations(StudentCommentListContainer);
 

const StudentCommentListContainerComposed = compose(  
  pure
)(StudentCommentListContainer);



const mapStateToProps = ({ studentCommentsModal,student_comments,user} , ownProps) => { 
 //   const { params } = ownProps.match;
   // const { student_id } = params;
  
    //console.log("state: ", studentCommentsModal);

    if(!studentCommentsModal.active || !studentCommentsModal.student)
      return {active:false};
    const student = studentCommentsModal.student; 
    const comments=  student_comments[student.id]; 
    return { 
        studentName: student.name, 
        student_id: student.id,
        user,
        active: studentCommentsModal.active,
        comments: (comments || []).slice().reverse()
    }
};
  
const mapDispatchToProps = (dispatch) => ({ dispatch, 
  closeModal: ()=>{
    dispatch(closeStudentCommentsModal())
  } });
  
const StudentCommentListContainerConnected = connect(
    mapStateToProps,
    mapDispatchToProps
  )(StudentCommentListContainerComposed);
  
export default StudentCommentListContainerConnected;// withRouter(StudentCommentListContainerConnected);