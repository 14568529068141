/* @flow */

import React from 'react';
import { IntelligenceProgressAnalisisContainer } from './';
import { pure, compose } from 'recompose';
import { connect } from 'react-redux'
import { getActiveChild } from '../../selectors/children';
import { getAllIntelligenceProgressByChild } from '../../selectors/intelligenceProgress';

type Props = {
  translations: Object
};

/**
 * Container that receives the data to display the estadistics
 * of the user to be shown within simple cards
 * @method DataDisplayContainer
 *
 * @param  {array}         currentChildProgress - injected by Redux includes all intelligence_progress from the store for the current child
 * @param  {array}         intelligences - injected by Redux includes all intelligences from the store
 */
export const DataDisplayParentContainer = ({ currentChildProgress, intelligences }: Props) => {
  return (
    <IntelligenceProgressAnalisisContainer currentProgress={currentChildProgress} intelligences={intelligences} />
  );
};

const DataDisplayParentContainerComposed = compose(
  pure
)(DataDisplayParentContainer);

const mapStateToProps = ({ intelligence_progress, intelligences, children }, ownProps) => {
  const currentChild = getActiveChild({ children });
  const currentChildProgress = getAllIntelligenceProgressByChild({ intelligence_progress }, currentChild.id);

  return {
    currentChildProgress,
    intelligences
  }
};

const mapDispatchToProps = (dispatch) => ({ dispatch });

const DataDisplayParentContainerConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(DataDisplayParentContainerComposed);

export default DataDisplayParentContainerConnected;
