import React from 'react'
import Translations from '../hocs/TranslationsHelper';
import appStore from '../../img/app_store.png';
import playStore from '../../img/play_store.png';
import video from '../../img/video_app.mp4';
import screens from '../../img/appdemo.png';
import icon from '../../img/iconapp.png';
import bg from '../../img/bg_adorno.png'

import logo from '../../img/logo_white.svg';
const DownloadAppContainer = ({translations}) => {
  return (
    <div className="download-app"> 
      <div className='banner' style={{backgroundImage: `url(${bg})`}}>

        <div className="download-app-logo"> 
          <img className="logo" src={logo} alt="PleIQ" /> 
        </div>
        <div className='banner__content'>
          <h2 className='banner__title'>{translations.t('download_title')}</h2>
          <p className='banner__description'>{translations.t('download_subtitle')}</p>
          <div className='banner__buttons'>
            <a
              href='https://itunes.apple.com/us/app/pleiq-juguete-inteligente-con-realidad-aumentada/id1263511462?l=es&ls=1&mt=8'
              target='_blank'
              id="appStoreBanner"
            >
              <img src={appStore} width={195}/>
            </a>
            <a
              href='https://play.google.com/store/apps/details?id=com.pleiq.pleiq'
              target='_blank'
              id="playStoreBanner"
            >
              <img src={playStore} width={195}/>
            </a>
          </div>
        </div>
        <div className='banner__video'>
          <video autoPlay loop playsInline src={video} width={560}>

          </video>
        {/* <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/pNTvOJpgZ14?si=SIKY6a-OdrlXPMup"
          title="YouTube video player" frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen></iframe> */}
        </div>
      </div>
      <div className='contentDownload'>
        <h3 className='contentDownload__title'>{translations.t('download_content_title')}</h3>
        <div className='contentDownload__body'>
          <div className='contentDownload__body--images'>
            <img src={icon} />
            <img src={screens} />
          </div>
          <div className='contentDownload__body--text'>
            <h3 className='contentDownload__body--title'>{translations.t('download_content_subtitle')}</h3>
            <ul>
              <li>{translations.t('download_list_1')}</li>
              <li>{translations.t('download_list_2')}</li>
              <li>{translations.t('download_list_3')}</li>
            </ul>
            <div className='contentDownload__buttons'>
              <a
                href='https://itunes.apple.com/us/app/pleiq-juguete-inteligente-con-realidad-aumentada/id1263511462?l=es&ls=1&mt=8'
                target='_blank'
                id="appStoreContent"
              >
                <img src={appStore} width={195}/>
              </a>
              <a
                href='https://play.google.com/store/apps/details?id=com.pleiq.pleiq'
                target='_blank'
                id="playStoreContent"
              >
                <img src={playStore} width={195}/>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default (Translations(DownloadAppContainer));