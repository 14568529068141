/* @flow */

import React from 'react';
import { ScatterChart } from '../components';
import { pure, compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getAllIntelligenceProgressByChild } from '../../selectors/intelligenceProgress';
import { studentEffortVsPerformanceToScatterChart } from '../../presenters/scatterChart';

/**
 * Container that receives the data to display statistics
 * of the student vs a course in a RadarChart
 * @method StudentEffortVsPerformanceGraphContainer
 *
 * @param  {array}         currentStudentProgress - injected by Redux includes all intelligence_progress from the store for the current child
 * @param  {array}         currentCourseProgress - injected by Redux includes all intelligence_progress from the store for the current course
 */
export const StudentEffortVsPerformanceGraphContainer = ({ currentStudentProgress, intelligences, animated }) => {

  const data = studentEffortVsPerformanceToScatterChart(currentStudentProgress, intelligences);

  return (
    <ScatterChart data={data} animated={animated} />
  );
};

const ContainerComposed = compose(
  pure
)(StudentEffortVsPerformanceGraphContainer);

const mapStateToProps = ({ student_intelligence_progress, intelligences, pdf }, ownProps) => {
  const { params } = ownProps.match;
  const { student_id } = params;

  const currentStudentProgress = getAllIntelligenceProgressByChild({ intelligence_progress: student_intelligence_progress }, student_id);

  return {
    currentStudentProgress,
    intelligences,
    animated: !pdf.printing
  }
};

const ContainerConnected = connect(
  mapStateToProps
)(ContainerComposed);

const UnpureContainerConnected = connect(
  mapStateToProps
)(StudentEffortVsPerformanceGraphContainer);

export const StudentEffortVsPerformanceGraphStatic = ({student_id}) => {
  return <UnpureContainerConnected match={{params:{student_id}}}/>
}

export default withRouter(ContainerConnected);
