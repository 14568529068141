/* @flow */

import React from 'react';
import { connect } from 'react-redux';
import { compose, pure, withHandlers } from 'recompose';
import { ConfirmationModal } from '../components/';
import { hideConfirmationModal } from '../../actions/confirmationModal';
import type { RecomposeHandler, ReduxFunction } from '../components/types';
import type { Action } from '../../diplomatico/types';

type Props = {
  active: boolean,
  title: string,
  message: string,
  actions: Array<Action>,
  cancelActions: Array<Action>,
  onConfirm: RecomposeHandler,
  onCancel: RecomposeHandler,
  dispatch: ReduxFunction
};

const ConfirmationModalContainer = (props: Props) => {
  return <ConfirmationModal {...props} />;
};

export const ConfirmationModalContainerHandlers = {
  onConfirm: ({ actions, dispatch }: Props) => () => {
    actions.forEach((action) => dispatch(action));
    dispatch(hideConfirmationModal());
  },
  onCancel: ({ cancelActions, dispatch }: Props) => () => {
    if (cancelActions) {
      cancelActions.forEach((action) => dispatch(action));
    }
    dispatch(hideConfirmationModal());
  }
};

const ConfirmationModalContainerComposed = compose(
  withHandlers(ConfirmationModalContainerHandlers),
  pure
)(ConfirmationModalContainer);

const mapStateToProps = ({ confirmationModal }) => ({
  active: confirmationModal.active,
  title: confirmationModal.title,
  message: confirmationModal.message,
  actions: confirmationModal.actions,
  kind: confirmationModal.kind,
  cancelActions: confirmationModal.cancelActions
});

const mapDispatchToProps = (dispatch) => ({ dispatch });

export default connect( mapStateToProps, mapDispatchToProps )(ConfirmationModalContainerComposed);
