/* @flow */

import React, { useEffect, useState } from 'react';
import { Form, FormGroup, FormFeedback, Label, ButtonDropdown, Input } from 'reactstrap';
import { Button, InputFieldCheckbox, inputFieldRadio, InputFieldSelect, SubmitButton } from '../components'
import { Field, reduxForm } from 'redux-form';
import Translations from '../hocs/TranslationsHelper';
import { RegistrationForm, InputField } from '.';
import type { ReduxFormFunction, ReduxFormErrorGlobal, ReduxFormFlag, TranslationsObject } from './types';
import { required, email, minLength } from './validations';
import classNames from 'classnames';
import challenge_completed_icon from '../../img/checkmark_image.png';
import RegistrationSubmitButton from './RegistrationSubmitButton';
import logo from '../../img/logo.svg';
import { toBoolean } from './normalizers';
import axios from "axios";
import { useApi } from '../../api_request';
import { languages } from './UserInfoForm';
import { Async } from 'react-select';
const _API_PATH = process.env.REACT_APP_API_URL;

type Props = {
  handleSubmit: ReduxFormFunction,
  translations: TranslationsObject,
  error: ReduxFormErrorGlobal,
  submitting: ReduxFormFlag,
  valid: ReduxFormFlag
};

/**
 * Component to display step 1 of the login form
 *
 * @method DirectorIEForm
 * @param  {function}  handleSubmit     - this callback gets injected by redux-form to handle form submit
 * @param  {Object}    translations     - inject by the Translations HoC to give access to locale text
 */
const DirectorIEForm = ({ 
  // handleSubmit,
  translations,
  error,
  submitting,
  valid,
  ...otherProps 
}: Props) => {
  const [steps, setSteps] = useState(1);
  const [endStep, setEndStep] = useState(4);
  const [validResource, setValidResource] = useState(false);
  const [caligrafixRadio, setCaligrafixRadio] = useState('');
  const [pleiqRadio, setPleiqRadio] = useState('');
  const [location, setLocation] = useState('');
  const [disabledButton, setDisabledButton] = useState(true);
  const [formData, setFormData] = useState({
  });
  const formClassName = classNames({ 'has-danger': error }, 'form');
  const disabled = submitting;
  const objImage = {
    'src': logo,
    'alt': "PleIQ",
    'width': 100
  }
  const api = useApi(); 

  useEffect(() => {
    const fetchData = async () => {
      try{ 
        const response = await axios.get(`${_API_PATH}/locations`, {
          headers: { 
              "Authorization": "XXXXX"
          }                 
        });
        const dataOptions = response.data.map((location) => {
          return {
            value: location.name,
            label: location.name
          }
        })
        setLocation(dataOptions);
      }catch(e){
        console.error(e);
        // setError(true);
      }
    };

    fetchData();
  }, []);

  const handleSteps = () => {
    setSteps(() => steps + 1)
    validResource ? setEndStep(4) : setEndStep(3)
    setTimeout( () => {
      document.querySelectorAll('.invalid-feedback').forEach(el => el.style.display = 'none');
    })
  }

  const onBackButtonClick = () => {
    setSteps(steps - 1);
  }

  

  const chageResources = (e, type) => {
    setFormData({ ...formData, [type]: Boolean(parseInt(e.target.value)) })
    e.target.value === '1'
      ? setCaligrafixRadio('caligrafix_yes')
      : setCaligrafixRadio('caligrafix_no');
    e.target.value === '1'
      ? setPleiqRadio('pleiq_yes')
      : setPleiqRadio('pleiq_no');
    caligrafixRadio === 'caligrafix_yes' || pleiqRadio === 'pleiq_yes'
      ? setValidResource(true)
      : setValidResource(false);
  }

  const handleSubmit = async (e, props: Props) => {
    e.preventDefault()
    console.log(formData, 'handle submit')
    try {
      const response = await axios.post(
          `${_API_PATH}/schools`,
          formData,  // El cuerpo de la solicitud se pasa directamente aquí
          {
              headers: {
                  "Authorization": "XXXXX",
                  'Content-Type': 'application/json',
              },
          }
      );

      console.log(response, '<--- response');
    } catch (error) {
        console.error('Error de red:', error);
    }
  }
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleLocationChange = (type, e) => {
    let resultString = "";
    let i = 0;
    e = Object.values(e)
    while (e[i] !== undefined) {
      resultString = resultString + Object.values(e[i]).join('');
      i++;
    }
    if(e.length == i) setFormData({ ...formData, [type]: resultString });
  }

  const handleCheckboxChange = (e, type) => {
    let item = e.target.getAttribute('name')
    setFormData({ ...formData, ['caligrafix_book_slugs']: [item] })
  }

  useEffect( () => {
    console.log(formData, 'change ok')
  }, [formData])

  return (
    <RegistrationForm
      title={
        validResource 
          ? translations.t(`director_form_tab1_title_${steps === 3 ? steps - 1 : steps}`) 
          : translations.t(`director_form_tab1_title_${steps}`)}
      image={objImage}
      classnames='w-800'
      onBackButtonClick={onBackButtonClick}
      backButton={steps > 1 ? true : false}
    >
      <Form id="director-form" className={formClassName} onSubmit={handleSubmit}>
        <div style={steps === 1 ? { display: 'block' } : { display: 'none' }}>
          <Label>{translations.t('director_form_name')}</Label>
          <Field
            className="text-left lowercased"
            type="text"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            placeholder={translations.t('director_form_name')}
            disabled={disabled}
            component={InputField}
            validate={[ required ]}
          />
          <Label>{translations.t('director_form_location')}</Label>
          <Field
            className="text-left lowercased mb-3"
            name="location"
            value={formData.location}
            onChange={handleLocationChange.bind(this, 'location')}
            placeholder={translations.t('director_form_location')}
            disabled={disabled}
            component={InputFieldSelect}
            selectOptions={location}
            validate={[ required ]}
          />

          <FormGroup>
            <Label for="locale">{translations.t('language')}</Label>
            <Field
              name="locale"
              value={formData.locale}
              onChange={handleLocationChange.bind(this, 'locale')}
              placeholder={translations.t('language')}
              selectOptions={languages(translations)}
              component={InputFieldSelect}
              disabled={submitting}
            />
          </FormGroup>
          <FormGroup>
            <Label for="language2">{translations.t('language2')}</Label>
            <Field
              name="locale2"
              value={formData.locale2}
              onChange={handleLocationChange.bind(this, 'locale2')}
              placeholder={translations.t('language2')}
              selectOptions={languages(translations)}
              component={InputFieldSelect}
              disabled={submitting}
            />
          </FormGroup>
        </div>
        {
          steps === 2 && (
            <div>
              <div>
                <Label>{translations.t('director_form_pleiq')}</Label>
                <div className='d-flex'>
                  <Field
                    className="mr-2"
                    type="radio"
                    name="use_pleiq"
                    label={translations.t('director_form_yes')}
                    component={inputFieldRadio}
                    value={1}
                    onChange={(e)=>{
                      chageResources(e, 'use_pleiq')
                    }}
                    validate={[ required ]}
                  />
                  <Field
                    className="mr-2"
                    type="radio"
                    name="use_pleiq"
                    label={translations.t('director_form_no')}
                    component={inputFieldRadio}
                    value={0}
                    onChange={(e)=>{
                      chageResources(e, 'use_pleiq')
                    }}
                    validate={[ required ]}
                  />
                </div>

                <Label>{translations.t('director_form_caligrafix')}</Label>
                <div className='d-flex'>
                  <Field
                    className="mr-2"
                    type="radio"
                    name="use_cx"
                    label={translations.t('director_form_yes')}
                    component={inputFieldRadio}
                    value={1}
                    onChange={(e)=>{
                      chageResources(e, 'use_cx')
                    }}
                    validate={[ required ]}
                  />
                  <Field
                    className="mr-2"
                    type="radio"
                    name="use_cx"
                    label={translations.t('director_form_no')}
                    component={inputFieldRadio}
                    value={0}
                    onChange={(e)=>{
                      chageResources(e, 'use_cx')
                    }}
                    validate={[ required ]}
                  />
                </div>
              </div>

            </div>
          )
        }
        { validResource 
          ? (
            steps === 3 && (
              <div>
                <Label className='labels_checks'>{translations.t('director_categoria_1')}</Label>
                <div className='d-flex container_checks flex-wrap'>
                  <Field
                    className="mr-2"
                    type="checkbox"
                    name="tln1"
                    value="tln1"
                    onChange={(e) => handleCheckboxChange(e, 'tln1')}
                    label={translations.t('director_categoria_1_1')}
                    component={InputFieldCheckbox}
                    normalize={toBoolean}
                    class="flex-1"
                  />
                  <Field
                    className="mr-2"
                    type="checkbox"
                    name="categoria_1_2"
                    value="tln2"
                    label={translations.t('director_categoria_1_2')}
                    component={InputFieldCheckbox}
                    normalize={toBoolean}
                    class="flex-1"
                  />
                  <Field
                    className="mr-2"
                    type="checkbox"
                    name="categoria_1_3"
                    value="lnn1"
                    label={translations.t('director_categoria_1_3')}
                    component={InputFieldCheckbox}
                    normalize={toBoolean}
                    class="flex-1"
                  />
                  <Field
                    className="mr-2"
                    type="checkbox"
                    name="categoria_1_4"
                    value="lnn2"
                    label={translations.t('director_categoria_1_4')}
                    component={InputFieldCheckbox}
                    normalize={toBoolean}
                    class="flex-1"
                  />
                  <Field
                    className="mr-2"
                    type="checkbox"
                    name="categoria_1_5"
                    value="bn3"
                    label={translations.t('director_categoria_1_5')}
                    component={InputFieldCheckbox}
                    normalize={toBoolean}
                    class="flex-1"
                  />
                </div>
                <Label className='labels_checks'>{translations.t('director_categoria_2')}</Label>
                <div className='d-flex container_checks flex-wrap'>
                  <Field
                    className="mr-2"
                    type="checkbox"
                    value="jcs1"
                    name="categoria_2_1"
                    label={translations.t('director_categoria_2_1')}
                    component={InputFieldCheckbox}
                    normalize={toBoolean}
                  />
                  <Field
                    className="mr-2"
                    type="checkbox"
                    value="jcs2"
                    name="categoria_2_2"
                    label={translations.t('director_categoria_2_2')}
                    component={InputFieldCheckbox}
                    normalize={toBoolean}
                    class="flex-1"
                  />
                  <Field
                    className="mr-2"
                    type="checkbox"
                    value="jcs3"
                    name="categoria_2_1"
                    label={translations.t('director_categoria_2_3')}
                    component={InputFieldCheckbox}
                    normalize={toBoolean}
                  /> 
                </div>
  
                <Label className='labels_checks'>{translations.t('director_categoria_3')}</Label>
                <div className='d-flex container_checks flex-wrap'>
                  <Field
                    className="mr-2"
                    type="checkbox"
                    value="cal11"
                    name="categoria_3_1"
                    label={translations.t('director_categoria_3_1')}
                    component={InputFieldCheckbox}
                    normalize={toBoolean}
                  />
                  <Field
                    className="mr-2"
                    type="checkbox"
                    value="cal12"
                    name="categoria_3_2"
                    label={translations.t('director_categoria_3_2')}
                    component={InputFieldCheckbox}
                    normalize={toBoolean}
                  />
                  <Field
                    className="mr-2"
                    type="checkbox"
                    value="cal20"
                    name="categoria_3_3"
                    label={translations.t('director_categoria_3_3')}
                    component={InputFieldCheckbox}
                    normalize={toBoolean}
                  />
                </div>
  
                <Label className='labels_checks'>{translations.t('director_categoria_4')}</Label>
                <div className='d-flex container_checks flex-wrap'>
                  <Field
                    className="mr-2"
                    type="checkbox"
                    value="cal11s"
                    name="categoria_4_1"
                    label={translations.t('director_categoria_4_1')}
                    component={InputFieldCheckbox}
                    normalize={toBoolean}
                  />
                  <Field
                    className="mr-2"
                    type="checkbox"
                    value="cal12s"
                    name="categoria_4_2"
                    label={translations.t('director_categoria_4_2')}
                    component={InputFieldCheckbox}
                    normalize={toBoolean}
                  />
                  <Field
                    className="mr-2"
                    type="checkbox"
                    value="cal20s"
                    name="categoria_4_3"
                    label={translations.t('director_categoria_4_3')}
                    component={InputFieldCheckbox}
                    normalize={toBoolean}
                  />
                </div>
  
                <Label className='labels_checks'>{translations.t('director_categoria_5')}</Label>
                <div className='d-flex container_checks flex-wrap'>
                  <Field
                    className="mr-2"
                    type="checkbox"
                    value="cal11s"
                    name="categoria_5_1"
                    label={translations.t('director_categoria_5_1')}
                    component={InputFieldCheckbox}
                    normalize={toBoolean}
                  />
                </div>
              </div>
            )
          ) :""
        }
        {
          steps === endStep ? (
            <div>
              <Label>{translations.t('director_form_name')}</Label>
              <Field
                className="text-left lowercased"
                type="text"
                name="admin_name"
                value={formData.admin_name}
                onChange={handleInputChange}
                placeholder={translations.t('director_form_placeholder_name')}
                disabled={disabled}
                component={InputField}
                validate={[ required ]}
              />
              <Label>{translations.t('director_form_email')}</Label>
              <Field
                className="text-left lowercased"
                type="email"
                name="admin_email"
                value={formData.admin_email}
                onChange={handleInputChange}
                placeholder={translations.t('director_form_placeholder_email')}
                disabled={disabled}
                component={InputField}
                validate={[ required ]}
              />
              <Label>{translations.t('director_form_confirm')}</Label>
              <Field
                className="text-left lowercased"
                type="email"
                name="confirm_email"
                value={formData.email}
                onChange={handleInputChange}
                placeholder={translations.t('director_form_placeholder_email_confirm')}
                disabled={disabled}
                component={InputField}
                validate={[ required ]}
              />
              <Label>{translations.t('director_form_served')}</Label>
              <Field
                className="text-left"
                type="text"
                name="consultant_name"
                value={formData.consultant_name}
                onChange={handleInputChange}
                placeholder={translations.t('director_form_placeholder_served')}
                disabled={disabled}
                component={InputField}
                validate={[ required]}
              />
            </div>
          ) : ""
        }
        
        <div style={steps === endStep + 1 ? { display: 'block' } : { display: 'none' }}>
          <figure className='text-center'>
            <img src={challenge_completed_icon} width="150"/>
          </figure>
          <p className='text-center'>Se ha enviado un email al primer administrador de la institución educativa</p>
        </div>
        {
          steps < endStep ? (
            <div className="registration-form-button-wrapper mt-5">
              <Button
                type='button'
                className="btn btn-primary btn-block"
                onClick={handleSteps}
                disabled={!valid}
              >{translations.t('continue')}</Button>
              <span className="registration-form-next-icon pleiq-icon-icon_arrow_right" />
            </div>
          ) : (
            <div className="registration-form-button-wrapper">
              <SubmitButton
                loading={submitting}
                className="btn btn-primary--green"
                disabled={!valid}>
                {translations.t('next')}
              </SubmitButton>
              <span className="registration-form-next-icon pleiq-icon-icon_arrow_right" />
            </div>
          )
        }
      </Form>
    </RegistrationForm>
  );
};

export const FORM_NAME = 'directorIEForm';

export default reduxForm({
  form: FORM_NAME
})(Translations(DirectorIEForm));
