/* @flow */

import React from 'react';
import { connect } from 'react-redux'; 
import _ from 'lodash';
import Translations from '../hocs/TranslationsHelper';

/**
 * This component sets the title of the screen
 * It uses [react-helmet](https://github.com/nfl/react-helmet)
 *
 * We generate titles based on the account type and appending the screen title
 *
 * @param {string} pageTitle        - The current page string to complete the title
 */
const LoadingStatusContainer = ({loading }) => { 
  if(loading ){
    return <div className="lds-dual-ring"></div>
  }else return null;
};  
const mapStateToProps = ({ loading_status }, {request}) => {
  var req =  request;
  if(!Array.isArray(request)){
    req = [request];
  }
  return {
    loading: req.some((id)=> loading_status[id])
  };
};

const LoadingStatus = connect(
  mapStateToProps
)(Translations(LoadingStatusContainer));

export default LoadingStatus;
