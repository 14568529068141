
import React from 'react';
import { Field, reduxForm, startSubmit, stopSubmit } from "redux-form";
import { Form, FormGroup, Label, FormFeedback, Row, Col } from "reactstrap";
import { required, minLength, email } from '../../components/validations';
import LaddaButton, { XL, ZOOM_IN } from 'react-ladda'; 
import Translations from '../../hocs/TranslationsHelper' 

import classNames from 'classnames';
import { compose,withState, pure } from 'recompose'; 
import { Button, InputFieldSelect, SubmitButton,InputField, UserChildForm, Paper } from '../../components';
import { connect } from 'react-redux';
import actions from '../../../actions';
import { invalidateRetriever } from '../../hocs/DataRetriever';
import { languages } from '../../components/UserInfoForm';
import EntityArrayField from './EntityArrayField';
import { showConfirmationModal } from '../../../actions/confirmationModal';

const lower = value => value && value.toLowerCase()
let SchoolUserForm = ({error, initialValues, role,  handleSubmit,onDelete, persisted, submitting, valid, dirty, translations})=>{
    

    const formClassName = classNames({ 'has-danger': error }, 'form' );
    return <Form  className={formClassName} onSubmit={handleSubmit}>
         
        { error &&
            
        <FormGroup>
              <FormFeedback>{translations.t(error)}</FormFeedback>
            </FormGroup>}
 
              <Paper.Nav>
                <Paper.NavItem iconClass="pleiq-icon-icon_intelligence_intrapersonal" label={translations.t(role+"_data")} />
              </Paper.Nav> 
              
               {/* <FormGroup>
          <Label for="locale">{translations.t('language')}</Label> 
            <Field
              name="locale"
              className="text-center"
              placeholder={translations.t('language')}
              selectOptions={languages(translations)}
              component={InputFieldSelect}
              disabled={submitting}
            /> 
        </FormGroup>*/}
 
        <Field 
          type="text"
          name="user_name"
          placeholder={translations.t('name')}
          label={translations.t('name')}
          disabled={false}
          component={InputField}
          validate={[ required ]}
        />
        {/* <Field
          name="role"
          placeholder={translations.t('role')}
          selectOptions={[
            { value: 'representative', label: translations.t('role_representative') }, 
            { value: 'coordinator', label: translations.t('role_coordinator') },
            { value: 'teacher', label: translations.t('role_teacher') }, 
          ] }
          component={InputFieldSelect}
          disabled={submitting}
        /> */}
        <Field
          className=" lowercased "
          type="text"
          name="email"
          label={translations.t('email')}
          placeholder={translations.t('email_placeholder')}
          disabled={false}
          component={InputField}
          validate={[ required, email ]}
          normalize={lower}
        />
       {
        (!initialValues)&& <Field
          type="text"
          name="emailRetype"
          className=" lowercased "
          label={translations.t('email_placeholder_retype_user')}
          placeholder={translations.t('email_placeholder_retype_user')}
          component={InputField}
          validate={[ required, email]}
          disabled={false}
          normalize={lower}
        />

        } 
 
       {
         (role==='teacher')&&<div>
            <Paper.Nav>
              <Paper.NavItem iconClass="pleiq-icon-icon_course" label={translations.t("courses")} />
            </Paper.Nav>
            <EntityArrayField name="courses" searchUrl="/school/courses" mapName={({name, level_name})=>`${level_name&& translations.t('level')} ${level_name}/${translations.t('course')} ${name}` } />
            </div>
        }
        {
          (role==='representative')&&<div>
              <Paper.Nav>
                <Paper.NavItem iconClass="pleiq-icon-icon_intelligence_intrapersonal" label={translations.t("students")} />
              </Paper.Nav>
              <EntityArrayField name="children" searchUrl="/school/students"/>
          </div>
         }
         <div className="form-actions" style={{marginTop:"32px"}}> 
          <SubmitButton loading={submitting} className="btn btn-primary--green" disabled={!dirty || !valid}>
            {translations.t((initialValues)?'save':'create')}
          </SubmitButton>
            {persisted && <Button type="primary--grapefruit" onClickButton={onDelete }>
            {translations.t('delete')}
          </Button>}
         </div>
    </Form>
}
const USER_FORM_NAME = 'schoolUser';
 

SchoolUserForm =  reduxForm({
  form: USER_FORM_NAME,
  enableReinitialize: true
})(Translations(SchoolUserForm));


  
let SchoolUserFormContainer = ({dispatch, role, closeModal, redirectToUser,  data, setData,  stage})=>{
    
    return <SchoolUserForm initialValues={data} role={role} persisted ={data} 
            onDelete={()=>{
              dispatch(showConfirmationModal({
                title: 'delete_user_confirmation_title',
                message: 'delete_user_confirmation_message',
                actions: [
                  startSubmit(USER_FORM_NAME), 
                  actions.genericDataRequest('SCHOOL_USER_FORM', '/school/users/' + data.id,'DELETE', null,  ({status,errors, body})=>{
                    if(status < 400){ 
                      dispatch(stopSubmit(USER_FORM_NAME )); 
                      invalidateRetriever("school_users_list")
                      closeModal(); 
                    }else  {  
                      dispatch(stopSubmit(USER_FORM_NAME, {_error: "invalid value"})); 
                    }  
                  })  
                ]
              }))
            }}
            onSubmit={(values)=>{
            dispatch(startSubmit(USER_FORM_NAME));
            if(!data && values.email!=values.emailRetype){ 
              dispatch(stopSubmit(USER_FORM_NAME, {email:"email_retype_alert"}));
            }else{
                const rbody = {
                  user_name: values.user_name,
                  email: values.email
                }
                if(role==='teacher'       )rbody.course_ids = values.courses&&values.courses. map(({id})=>id);
                if(role==='representative')rbody.child_ids  = values.children&&values.children.map(({id})=>id);

                if(data)
                  dispatch(
                    actions.genericDataRequest('SCHOOL_USER_FORM', '/school/users/' + data.id,'PUT', rbody,  ({status,errors, body})=>{
                      if(status < 400){ 
                          dispatch(stopSubmit(USER_FORM_NAME )); 
                          invalidateRetriever("school_users_list");
                      }else  {  
                        console.log("iv", status,errors, body);
                          dispatch(stopSubmit(USER_FORM_NAME, {_error: "invalid value"})); 
                      }  
                  })); 
                else
                  dispatch(actions.genericDataRequest('SCHOOL_USER_FORM', '/school/users','POST',{...rbody, role},  ({status,errors, body})=>{
                    if(status < 400){ 
                      dispatch(stopSubmit(USER_FORM_NAME )); 
                      invalidateRetriever("school_users_list"); 
                      closeModal();//redirectToUser(body.id); 
                    }else  {  
                      console.log("iv", status,errors, body);
                      dispatch(stopSubmit(USER_FORM_NAME, errors )); 
                    }   
                  })); 

            }
        }}/> 
}


SchoolUserFormContainer = 
connect(()=>{}, (dispatch)=>({dispatch}))( 
 
   SchoolUserFormContainer 

);
export default SchoolUserFormContainer;

