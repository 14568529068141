import React from 'react';
import {connect} from 'react-redux'
/**
 * Root will allow to hold the application
 * components along with the Providers and aside elements
 * needed for the app to work waiting for the store
 * to persist before showing something.
 */
function PdfPrintingScreen({ loading, progress }) {
  if ( loading) {
    return (<div className="pdf-loader-overlay">
      <div className="pdf-loader">
        <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        <span>{progress.toFixed()}%</span>
      </div>
    </div>);
  }
  else {
    return null;
  }
}


export default connect(({pdf})=>({loading: pdf.printing, progress: pdf.progress}))(PdfPrintingScreen);
