/* @flow */

import { responseHasError } from '../diplomatico/reducers/helper';
import { getActiveChild } from '../selectors/children';
import { enableInitializing } from 'initializable-reducer';
import type { Child } from '../js/components/types';
import type { Action } from '../diplomatico/types';
import _ from 'lodash';

const initialState = [];

const _setActiveChild = ({ children, payload }: Object) => {
  let child = _.find(children, (child) => child.id === payload.id);

  if (child) {
    child.active = true;
  }

  return children;
};

function children(state: Array<Child> = initialState, { type, payload }: Action) {
  let data;
  let responseBody;

  switch (type) {
    case 'RESPONSE_CHILDREN':
      responseBody = payload.body;

      if (responseHasError(payload) || _.isEmpty(responseBody)) {
        return state;
      }

      data = [].concat(responseBody);
      _.first(data).active = true;

      return data;
    case 'SET_ACTIVE_CHILD':
      data = [].concat(state);
      let activeChild = getActiveChild({ children: data });

      if (activeChild) {
        activeChild.active = false;
      }

      _setActiveChild({ children: data, payload });

      return data;
    case 'DELETE_CHILD':
      const deleteChildrenIndex = _.findIndex(state, child => child.id === payload.id);
      const childToDelete = state[deleteChildrenIndex];

      let newState = [
        ...state.slice(0, deleteChildrenIndex),
        ...state.slice(deleteChildrenIndex + 1)
      ];

      // if deleting the active child, set the first one to active
      if (childToDelete.active) {
        _.first(newState).active = true;
      }

      return newState;
    case 'ADD_CHILD':
      return [
        ...state,
        payload.child
      ];
    case 'UPDATE_CHILD':
      const updatedChild = payload.child;
      const newChildren = state.map((child) => {
        if (child.id === updatedChild.id) {
          return { ...child, ...updatedChild };
        }
        return child;
      });

      return newChildren;
    default:
      return state;
  }
}

export {
  children,
  initialState
};

export default enableInitializing(children);
