/* @flow */

import React from 'react';
import { connect } from 'react-redux';
import { compose, withHandlers, pure } from 'recompose';
//import {push} from 'react-router-redux'
import { CourseCollapseItem } from '../components';
import type { RecomposeHandler, Course, ReduxFunction } from '../components/types';
import { getCourseStudents } from '../../selectors/students';
import { editCourse } from '../../actions/courses';
import { getStudents } from '../../actions/students';
import { pdfContext } from '../../pdfer/Pdfable';
import TranslationsHelper from '../hocs/TranslationsHelper';

type Props = {
  course: Course,
  dispatch: ReduxFunction,
  onEditButtonClick: RecomposeHandler,
  onHeaderClick: RecomposeHandler
};

/**
 * container to render CourseCollapseItem and dispatch an action that
 * shows a modal on button click, to edit a course
 *
 * @method CourseCollapseItemContainer
 * @param  {Object}    course                  - this object represents a course
 * @param  {function}  onEditButtonClick     - injected by `recompose` as a handler, to dispatch an action that will trigger a course edition modal
 * @param  {function}  onHeaderClick         - injected by `recompose` as a handler, to dispatch an action that will fetch the course students
 *
 */
const CourseCollapseItemContainer = ({ course, students,...otherProps  }: Props) => {
  const { id, name, students_count } = course || {id:null, students_count: students.length  };
  if(course ==null && students.length==0)return null;

  return (
    <CourseCollapseItem
      title={name}
      count={students_count}
      students={students}
      courseId={id}
      {...otherProps}
    />
  );
};

export const CourseCollapseItemContainerHandlers = {
  onPdfButtonClick: ({ dispatch, course }: Props) => () =>{  

    pdfContext.print("pleiq_report_course_"+course.name , "course_report",{course_id:course.id, course_name: course.name, level_id:course.level_id});// dispatch(push('pdf/course/'+ course.id));
  },
  onEditButtonClick: ({ dispatch, course }: Props) => () => {
    dispatch(editCourse(course));
  },
  onHeaderClick: ({ dispatch, course }: Props) => () => {
    dispatch(getStudents(course)); 
  }
};

const CourseCollapseItemContainerComposed = compose(
  withHandlers(CourseCollapseItemContainerHandlers) 
)(CourseCollapseItemContainer);

const mapStateToProps = (state, ownProps) =>  {
 
  return {students: getCourseStudents(state, (ownProps.course || {id:null}).id),
  all: ownProps.all};
} 

const mapDispatchToProps = (dispatch) => ({ dispatch });

const CourseCollapseItemContainerConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(CourseCollapseItemContainerComposed);

export default  CourseCollapseItemContainerConnected ;
