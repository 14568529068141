import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Input } from 'reactstrap';
import classNames from 'classnames';
export const PlanificationField = ({ className, formGroupClassName, desc, searchable, selectOptions, translations, disabled,hidden,  ...otherProps }) => {
  const { input, label, name, placeholder, meta } = otherProps;
  const { touched, dirty, error, valid, submitting }: ReduxFormMeta = meta;
  const showErrors = (touched || dirty) && error;
  const formColor = classNames({
    danger: showErrors,
    success: valid && (touched || dirty) && !error
  });
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const currentLabel = input.value && _.find(selectOptions, ({ value }) => value == input.value)?.label;

  return (
    <Dropdown hidden={hidden} disabled={submitting || disabled} isOpen={dropdownOpen} toggle={() => setDropdownOpen(!dropdownOpen)} direction="end">
      <DropdownToggle disabled={submitting} className={"gpt-planification-field" + ((currentLabel) ? " gpt-planification-field-selected" : "") + ((dropdownOpen) ? " gpt-planification-field-open" : "")}>
        {currentLabel || placeholder}
        {(currentLabel) ? (<span className="pleiq-icon-icon_checkmark" />) : (<span className="pleiq-icon-icon_play" />)}

      </DropdownToggle>
      <DropdownMenu className="gpt-planification-field-menu">
        <DropdownItem header>{desc}</DropdownItem>
        {selectOptions.map(({ value, label }) => (
          <DropdownItem key={value} onClick={() => input.onChange(value)}>{label}</DropdownItem>))}
      </DropdownMenu>
    </Dropdown>
  );
};

export const PlanificationFieldMulti = ({ className, formGroupClassName, desc, searchable, selectOptions, translations, disabled,hidden, ...otherProps }) => {
  const { input, label, name, placeholder, meta } = otherProps;
  const { touched, dirty, error, valid, submitting }: ReduxFormMeta = meta;
  const showErrors = (touched || dirty) && error;
  const formColor = classNames({
    danger: showErrors,
    success: valid && (touched || dirty) && !error
  });
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const currentLabel = input.value && input.value.map(v=> _.find(selectOptions, ({ value }) => value == v)?.label).join(",");

  return (
    <Dropdown hidden={hidden} disabled={submitting || disabled} isOpen={dropdownOpen} toggle={() => setDropdownOpen(!dropdownOpen)} direction="end">
      <DropdownToggle disabled={submitting} className={"gpt-planification-field" + ((currentLabel) ? " gpt-planification-field-selected" : "") + ((dropdownOpen) ? " gpt-planification-field-open" : "")}>
        {currentLabel || placeholder}
        {(currentLabel) ? (<span className="pleiq-icon-icon_checkmark" />) : (<span className="pleiq-icon-icon_play" />)}

      </DropdownToggle>
      <DropdownMenu className="gpt-planification-field-menu">
        <DropdownItem header>{desc}</DropdownItem>
        {
          selectOptions.map((a) => {
            const v = a.value;
            const label = a.label;
            const isIn = input.value &&  input.value.includes(v); 
            const toggle = (e)=>{
              e.preventDefault();
              e.stopPropagation();
              
              if(isIn)input.onChange(input.value.filter((v2)=>v2!=v));
              else input.onChange([...(input.value || []), v]);
            }
            return (<button key={v} className="dropdown-item" role="menuitem" onClick={toggle} ><span><input checked={isIn} type="checkbox" onClick={toggle} />{label}</span></button>) 
          }) 
        }
      </DropdownMenu>
    </Dropdown>
  );
};
