/* @flow */

import React from 'react';
import { Label, Form, FormGroup } from 'reactstrap';
import { FieldArray, reduxForm } from 'redux-form';
import {  Dropdown, SubmitButton } from './';
import Translations from '../hocs/TranslationsHelper';
import type {  TranslationsObject } from './types';
import LevelBooksFieldArray from './LevelBooksFieldArray';
import { getBookInformation } from '../caligrafix/bookInfo';

export function buildSelectOptions(books,  translations: TranslationsObject) {
  return books.map((book) => {
      return {
      value: book,
      label: `${getBookInformation( book.slug).label} `
    };
  });
}

const SchoolLevelBooksForm = ({ value , changeLevelBook, onRemoveLevelBook, onSearchBlur, searchLoading, setSearch, otherBooks, submitting, valid, dirty, handleSubmit, translations }) => {
  const selectOptions = buildSelectOptions(otherBooks, translations);
 

  return (
    <Form className="form school-courses-form" onSubmit={handleSubmit}>
      <FormGroup>
        <Label>{translations.t('add_book')}</Label>
        <Dropdown
          options={selectOptions}
          placeholder={translations.t('pick_a_book')}
          onChange={changeLevelBook}
          onBlur={onSearchBlur}
          disabled={submitting}
          onInputChange={setSearch}
          isLoading={searchLoading}
          noResultsText={false}
         // this prevents react-select to try to do any option filtering,
         // as we already handle the search
          filterOption={() => true}
          searchable
        />
      </FormGroup>
      <Label>{translations.t('edit_level_books')}</Label>
      <div style={{padding: "8px"}}>
        <FieldArray name="levelBooks" component={LevelBooksFieldArray} translations={translations} onRemoveButtonClick={onRemoveLevelBook} submitting={submitting} />
      
      </div>
      <SubmitButton loading={submitting} className="btn btn-primary--green" disabled={!valid || !dirty}>
        {translations.t('save')}
      </SubmitButton>
    </Form>
  );
};

export const FORM_NAME = 'schoolLevelBooksForm';

export default reduxForm({
  form: FORM_NAME,
  enableReinitialize: true
})(Translations(SchoolLevelBooksForm));
