/* @flow */

import React from 'react';
import { connect } from 'react-redux';
import { compose, pure, withHandlers } from 'recompose';
import { startSubmit } from 'redux-form';
import  SchoolUserInvitationForm   from '../components/SchoolUserInvitationForm';
import { FORM_NAME } from '../components/SchoolUserInvitationForm';
import { addSchoolUserInvitation } from '../../actions/schoolUserInvitations'; 
import type { RecomposeHandler, ReduxFunction } from '../components/types';
import { Paper } from '../components/';
import Translations from '../hocs/TranslationsHelper'

type Props = {
  data: Object,
  onSubmit: RecomposeHandler,
  dispatch: ReduxFunction
};

/**
 * @method SchoolUserInvitationFormContainer
 * @param  {Object}  data
 */
const SchoolUserInvitationFormContainer = ({ data, onSubmit, onClickButton, translations }: Props) => {
 
  return (
    <div> 
      <SchoolUserInvitationForm onSubmit={onSubmit}  />
    </div>
  );
};
 
export const SchoolUserInvitationFormContainerHandlers = {
  onSubmit: ({ dispatch, data }: Props) => (values: Object) => {
    const payload = values ; 
    dispatch(startSubmit(FORM_NAME));
    dispatch(addSchoolUserInvitation(payload));
  }    
};  

const SchoolUserInvitationFormContainerComposed = compose(
  withHandlers(SchoolUserInvitationFormContainerHandlers),
  Translations, 
)(SchoolUserInvitationFormContainer);

const mapStateToProps = ({ user, language }) => {
 

  return {
    data: { 
      account_type: user.account_type, 
    }
  };
};

const mapDispatchToProps = (dispatch) => ({ dispatch });

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SchoolUserInvitationFormContainerComposed);
