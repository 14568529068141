/* @flow */

import React from 'react';
import { RadarChart, Radar, PolarGrid, Legend, PolarAngleAxis, PolarRadiusAxis, Text, ResponsiveContainer } from 'recharts';
import { shouldUpdate, compose } from 'recompose';
import classNames from 'classnames';
import deepEqualsUpdate from '../../helpers/deepEqualsUpdate';
import _ from 'lodash';
//import { Intelligences } from '../models/';
import Translations from '../hocs/TranslationsHelper';

import icon_INTER from  "../../img/int/icon_intelligence_interpersonal.svg"
import icon_INTRA from  "../../img/int/icon_intelligence_intrapersonal.svg"
import icon_KIN from  "../../img/int/icon_intelligence_kinesthetic.svg"
import icon_MAT from  "../../img/int/icon_intelligence_logical.svg"
import icon_MUS from  "../../img/int/icon_intelligence_musical.svg"
import icon_NAT from  "../../img/int/icon_intelligence_naturalistic.svg"
import icon_VIS from  "../../img/int/icon_intelligence_visual.svg"
import icon_LIN from  "../../img/int/icon_intelligence_linguistic.svg"

const iconsSrc = {
  LIN:  icon_LIN,
  MAT: icon_MAT,
  NAT: icon_NAT,
  VIS:icon_VIS ,
  MUS:icon_MUS  ,
  KIN:icon_KIN ,
  INTER: icon_INTER,
  INTRA:icon_INTRA  
}; 

/**
 * A component that renders data into a RadarChart component from Recharts
 *
 * This component is out of coverage because of: https://github.com/recharts/recharts/issues/765
 *
 * @method RadarChartWrapper
 *
 */
const RadarChartWrapper = ({ animated, color, student, data = [], className, translations, ...otherProps }) => {
  const isEmpty = _.isEmpty(data);
  let studentName = translations.t('student');

  if (_.isObject(student)) {
    studentName = student.name;
  }

  const containerClass = classNames(className, 'radarchart-container', { 'radarchart-container--loading': isEmpty });

  return (
    <ResponsiveContainer height={450}>
      <RadarChart className={containerClass} innerRadius="2.5%" outerRadius="80%" data={data}>
        <Radar isAnimationActive = {animated} name={studentName} legendType="circle" dataKey="A" stroke="#8884d8" fill="#8884d8" fillOpacity={0.6} />
        <Radar isAnimationActive = {animated} name={translations.t('course')} legendType="circle" dataKey="B" stroke="#82ca9d" fill="#82ca9d" fillOpacity={0.6} />
        <PolarGrid />
        <Legend />
        <PolarAngleAxis dataKey="subject" tick={renderAngleLabels} />
        <PolarRadiusAxis angle={90} domain={[0, 100]} tick={renderRadiusLabels} />
      </RadarChart>
    </ResponsiveContainer>
  );
};

 

const renderAngleLabels = ({ x, y, index, payload }) => {
  const slug = payload.value;
  //const iconClass = Intelligences.getIntelligenceProperty(slug, 'ICON');

  const pa = iconPositionTable[slug];

  const W = 24;
  const ag = Math.PI*2*index/8.0;
  const D = 12;

//<image xlinkHref={iconsSrc[slug]} x={x-W/2 + Math.sin(ag)*D} y={y-W/2  - Math.cos(ag)*D} width={W} height={W}/>
  return (
    <g transform={"translate("+(x-W/2 + Math.sin(ag)*D + pa.x) + ","+(y-W/2  - Math.cos(ag)*D + pa.y)+")"}>
      <image xlinkHref={iconsSrc[slug]}  width={W} height={W}/> 
    </g>
 
    /*
    <text x={x + Math.sin(ag)*D} y={y - Math.cos(ag)*D} className={'pleiq-icon-2x ' } textAnchor="middle" alignmentBaseline="middle">
      { index}

    </text><text x={x} y={y} className={'pleiq-icon-2x ' } textAnchor="middle" alignmentBaseline="middle">
      { iconsText[iconClass] }

    </text>*/
  /* <foreignObject x={x + postionAdjustments.x} y={y + postionAdjustments.y}>
      <span className={'pleiq-icon-2x ' + iconClass} />
    </foreignObject>*/
  );
};

// Adjustments to the position of the icons in the RadarChart
const iconPositionTable = {
  LIN: {
    x: 0,
    y: 0
  },
  MAT: {
    x: 0,
    y:0
  },
  NAT: {
    x: 0,
    y: 0
  },
  VIS: {
    x: 0,
    y: 0
  },
  MUS: {
    x: 0,
    y: -5
  },
  KIN: {
    x: 0,
    y: 0
  },
  INTER: {
    x: 0,
    y: 0
  },
  INTRA: {
    x: 0,
    y: 0
  }
};
/*
const iconPositionTable = {
  LIN: {
    x: 0,
    y: -12
  },
  MAT: {
    x: 0,
    y: -18
  },
  NAT: {
    x: -11,
    y: -24
  },
  VIS: {
    x: -18,
    y: -18
  },
  MUS: {
    x: -26,
    y: -12
  },
  KIN: {
    x: -18,
    y: 0
  },
  INTER: {
    x: -12,
    y: 0
  },
  INTRA: {
    x: 0,
    y: 0
  }
};*/

const renderRadiusLabels = (props) => {
  let { payload, ...otherProps } = props;

  // magic numbers to put the numbers below the line
  // instead of the default above the line
  otherProps.y = otherProps.y + 16;
  otherProps.x = otherProps.x + 1;
  otherProps.fill = "#333333";

  return (
    <Text
      {...otherProps}
      className="recharts-polar-radius-axis-tick-value"
      opacity={0.5}
    >
      {payload.value}
    </Text>
  );
};


const RadarChartWrapperComposed = compose(
  shouldUpdate(deepEqualsUpdate)
)(RadarChartWrapper);

export default Translations(RadarChartWrapperComposed);
