/* @flow */

import React from 'react';
import { Col, Row } from 'reactstrap';
import { Paper } from './';
import type { Course, TranslationsObject } from './types';
import Translations from '../hocs/TranslationsHelper';
import { CourseDataFormContainer, SchoolStudentsFormContainer } from '../containers/';
import RoleFilter from '../containers/RoleFilter';

type Props = {
  course?: Course,
  closeModal: () => mixed,
  translations: TranslationsObject
};

/**
 * component to display a form to edit/create a course info
 *
 * @method CourseEditForm
 * @param  {Object}         course             - optional parameter, used to fill the name input if passed in
 * @param  {function}       closeModal       - callback that gets fired when the close button gets clicked
 * @param  {Object}         translations       - object containing app translations
 *
 */
const CourseEditForm = ({ course, closeModal, translations }: Props) => {
  const titleTranslation = typeof course === 'undefined' ? 'create_course' : 'edit_course';
  const studentsSection = (
    <Col xs="12">
      <Paper.Nav>
        <Paper.NavItem iconClass="pleiq-icon-icon_intelligence_intrapersonal" label={translations.t('course_students')} />
      </Paper.Nav>
      <SchoolStudentsFormContainer course={course} />
    </Col>
  );

  return (
    <div className="modal-edit-form">
      <span className="modal-close pleiq-icon-icon_plus" onClick={closeModal} />
      <Paper.Container>
        <Row>
          <Col xs="12">
            <Paper.Title>
              <h1>{translations.t(titleTranslation)}</h1>
            </Paper.Title>
          </Col>
          <RoleFilter roles={['coordinator']}>

            <Col xs="12">
              <Paper.Nav>
                <Paper.NavItem iconClass="pleiq-icon-icon_course" label={translations.t('course_data')} />
              </Paper.Nav>
              <CourseDataFormContainer course={course} />
            </Col>
          </RoleFilter>
          {course && studentsSection}
        </Row>
      </Paper.Container>
    </div>
  );
};

export default Translations(CourseEditForm);
