/* @flow */

import _ from 'lodash';

export const getAchievementCardsByIntelligence = ({ achievement_cards }, intelligence_id, language) => 
(_.get(achievement_cards, [intelligence_id, language], []).filter((v)=>v.visible)

);

export const getAchievementCardScores = ({ achievement_card_scores }, child_id, intelligence_id) => (_.get(achievement_card_scores, [child_id, intelligence_id], []));
