/* @flow */

import React from 'react';
import { connect } from 'react-redux';
import { pure, compose, withHandlers, withState } from 'recompose';
import { dismissAlert } from '../../actions/alerts';
import { Alert, Modal } from '../components';
import Translations from '../hocs/TranslationsHelper';
import _ from 'lodash';
import type {
  RecomposeHandler, ReduxFunction, ReduxState, RecomposeStateUpdater,
  RecomposeStateName, TranslationsObject
} from '../components/types';

type Props = {
  onClose: RecomposeHandler,
  dispatch: ReduxFunction,
  alerts: ReduxState,
  isModalOpen: RecomposeStateName,
  toggleModal: RecomposeStateUpdater,
  translations: TranslationsObject
};

const AlertContainer = ({ onClose, alerts, translations }: Props) => {
  if (_.isEmpty(alerts)) {
    return null;
  }

  return (
    <Modal
      backdropClassName="overlay-backdrop"
      contentClassName="overlay-modal-content"
      active={!_.isEmpty(alerts)}
      toggle={() => onClose()}>
      {
        alerts.map(({ message, type, dismissible }, key) => {
          return (
            <Alert key={key} onClose={() => onClose(key)} type={type} dismissible={dismissible}>
              <div dangerouslySetInnerHTML={{ __html: translations.t(message) }}></div>
            </Alert>);
        })
      }
    </Modal>
  );
};

export const AlertContainerHandlers = {
  onClose: ({ dispatch, alerts, toggleModal }: Props) => (key: number = 0) => {
    if (alerts.length === 1) {
      toggleModal(false);
    }

    dispatch(dismissAlert(key));
  }
};

const AlertContainerEnhancements = compose(
  withState('isModalOpen', 'toggleModal', true),
  withHandlers(AlertContainerHandlers)
);

const AlertContainerComposed = compose(
  AlertContainerEnhancements,
  pure
)(AlertContainer);

const mapDispatchToProps = (dispatch) => ({ dispatch });

const mapStateToProps = ({ alerts }) => {
  return {
    alerts
  };
}

const AlertContainerConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(AlertContainerComposed);

export default Translations(AlertContainerConnected);
