/* @flow */

import React from 'react';
import { replace, push } from 'react-router-redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withHandlers, pure, compose, lifecycle } from 'recompose';
import { Topic } from '../components/';
import type { ReduxFunction, RecomposeHandler } from '../components/types';
import classNames from 'classnames';
import _ from 'lodash';

type Props = {
  types: Array<string>,
  activeTopic: string,
  dispatch: ReduxFunction,
  goToTopicRoute: RecomposeHandler
};

/**
 * Container to display each button topic
 *
 * @method IntelligencesTopicContainer
 */
const IntelligencesTopicContainer = ({ activeTopic, types, goToTopicRoute }: Props) => {
  const containerHeaderBannerClass = classNames('container-header-banner', `bg-${activeTopic}`);

  return (
    <div className={containerHeaderBannerClass}>
      <div className="scrollable scrollable-topics">
        <div className="container-scrollable-outer">
          <div className="container-scrollable-inner d-flex align-items-center justify-content-between">
            {
              types.map((type, key) => {
                let className = classNames({ active: (type === activeTopic) });
                return <Topic className={className} key={key} type={type} onClickTopic={goToTopicRoute} />;
              })
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export const IntelligencesTopicContainerLifecycle = {
  componentWillMount() {
    const { types, activeTopic } = this.props;

    if (types.indexOf(activeTopic) >= 0) {
      return;
    }

    this.props.redirectToDefault();
  }
};

export const IntelligencesTopicContainerHandlers = {
  redirectToDefault: ({ dispatch }: Props) => () => {
    dispatch(replace('/dashboard/all'));
  },
  goToTopicRoute: ({ dispatch }: Props) => (type: string) => {
    type = type.toLowerCase();
    dispatch(push(`/dashboard/${type}`));
  }
};

const IntelligencesTopicContainerComposed = compose(
  withHandlers(IntelligencesTopicContainerHandlers),
  lifecycle(IntelligencesTopicContainerLifecycle),
  pure
)(IntelligencesTopicContainer);

const mapStateToProps = ({ intelligences }, ownProps) => {
  const { params } = ownProps.match;
  const { topic } = params;

  let activeTopic = (topic && topic.toUpperCase()) || 'all';
  let intelligencesSlugs = _.map(intelligences, 'slug');
  let types = ['all', ...intelligencesSlugs];

  return {
    activeTopic,
    types
  };
}

const mapDispatchToProps = (dispatch) => ({ dispatch });

const IntelligencesTopicContainerConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(IntelligencesTopicContainerComposed);

export default withRouter(IntelligencesTopicContainerConnected);
