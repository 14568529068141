/* @flow */

import React from 'react';
import { Paper, InfoTooltip } from '../components';
import { Col, Row } from 'reactstrap';
import {
  SchoolDashboardNavContainer, SchoolDashboardHeadingContainer,
  HeaderContainer, SchoolGeneralIntelligenceProgressContainer,
  DataDisplayGeneralSchoolContainer,
  SchoolGeneralCoursesProgressContainer,
  PageTitleContainer,
  DataDisplayGeneralCaligrafixSchoolContainer,
  SchoolGeneralCaligrafixCoursesProgressContainer
} from '../containers';
import Translations from '../hocs/TranslationsHelper';


import { PdfData } from '../../pdfer/Pdfable';
import { SchoolReportPdf } from '../pdfview/SchoolView';
import { GeneralCaligrafixProgress } from '../components/GeneralCaligrafixProgress';
import CaligrafixProgressAnalisisContainer from '../containers/CaligrafixProgressAnalisisContainer';
import { mockBooks } from '../mock/cx';
import CaligrafixGeneralCoursesProgressContainer from '../containers/CaligrafixGeneralCoursesProgressContainer';
import SchoolGeneralCaligrafixBookProgressContainer from '../containers/SchoolGeneralCaligrafixBookProgressContainer';
import topScroll from '../hocs/topScroll';

/**
 * screen for the school Progress
 *
 * @method SchoolProgress
 *
 */

const mockProgress = [
  {book_id:"0", score:50},
  {book_id:"1", score:60},
  {book_id:"2", score:70},
  {book_id:"3", score:20},
  {book_id:"4", score:30} 
];


const CaligrafixProgress = ({ translations }) => { 
  topScroll();
  
  return (
    <div id="school-dashboard" className="school-dashboard layout-base">
      <PageTitleContainer pageTitle="title_school_progress" />
      <HeaderContainer />
      <SchoolDashboardHeadingContainer /> 
        <Paper.Container lead>
          <SchoolDashboardNavContainer currentTab="caligrafix" pdfFilter="caligrafix_report" pdfTarget={({user})=>("pleiq_report_caligrafix_"+ user.name)}/>
              
          <Row>
            <Col xs="12">
              <Paper.Title>
                <PdfData path={"school_progress._intelligences"}>
                  <h1>
                    { translations.t('caligrafix_current_general_progress') }{' '}
                    <InfoTooltip message="tooltips_current_caligrafix_progress" />
                  </h1>
                </PdfData>

              </Paper.Title>
            </Col>
            <Col xs="12">
              <Paper.Content>
                <PdfData path={"school_progress.intelligences"}>
                  <SchoolGeneralCaligrafixBookProgressContainer />
                </PdfData>
              </Paper.Content>
            </Col>

            <Col xs="12">
              <Paper.Title>
                <PdfData path={"school_progress._intelligence_analysis"}>
                  <h1 className="mt-5">
                    { translations.t('caligrafix_progress_analysis') } {' '}
                    <InfoTooltip message="tooltips_intelligences_progress_analysis" />
                  </h1>
                </PdfData>
              </Paper.Title>
            </Col>
            <Col xs="12">
              <Paper.Content>
                <PdfData path={"school_progress.intelligence_analysis"}  >
                  <DataDisplayGeneralCaligrafixSchoolContainer/>
                </PdfData>
              </Paper.Content>
            </Col> 

            <Col xs="12">
              <Paper.Title> 
                  <PdfData path={"school_progress.courses_title"}  > 
                    <h1 className="mt-5">
                      { translations.t('school_current_progress_by_course') }{' '}
                      <InfoTooltip message="tooltips_current_caligrafix_courses_progress" />
                    </h1> 
                  </PdfData>
              </Paper.Title>
            </Col>
            <Col xs="12">
              <div id = "paper-contentss" >
                <Paper.Content>
                  <PdfData path={"school_progress.courses_progress"}  >
                      <SchoolGeneralCaligrafixCoursesProgressContainer/>
                  </PdfData>
                </Paper.Content>
              </div>
            </Col>
          </Row>
        </Paper.Container> 
      <SchoolReportPdf/>
    </div>
  );
};

export default Translations(CaligrafixProgress);
