/* @flow */

import React from 'react';
//import { FormGroup } from './FormGroup';
import { FormGroup,Label, Input, FormFeedback } from 'reactstrap';
import classNames from 'classnames';
import Translations from '../hocs/TranslationsHelper';
import type { ReduxFormMeta } from './types';

/**
 * this component is supposed to be used with the Field component
 * from redux-form, passing this as the component prop.
 * This component can be used to render any text based input.
 *
 * @example
 * <Field
 *   type="text"
 *   name="some_name"
 *   placeholder="some placeholder"
 *   label="some label"
 *   disabled={submitting}
 *   component={InputField}
 *   validate={[ required, minLength(2) ]}
 * />
 */
const InputField = ({ className, log, disabled, translations, ...otherProps }) => {
  const { input, label, name, placeholder, type, meta } = otherProps;
  const { dirty, error, submitting, submitFailed, active }: ReduxFormMeta = meta;

  const showErrors = !active && (submitFailed || dirty) && error;
  const formColor = classNames({
    "has-danger": showErrors
  });

  return (
    <FormGroup className={formColor}>
      {label && <Label for={name}>{label}</Label>}
      <Input {...input} className={className} type={type} disabled={disabled || submitting} invalid={showErrors} placeholder={placeholder} />
      {showErrors && <FormFeedback>{translations.t(error)}</FormFeedback>}
    </FormGroup>
  );
};

export default Translations(InputField);
