/* @flow */

import React from 'react';
import { compose, lifecycle, shouldUpdate } from 'recompose';
import { getIntelligenceBySlug } from '../../selectors/intelligences';
import { findStudentAcrossReducers } from '../../selectors/students';
import { getAchievementCardsByIntelligence, getAchievementCardScores } from '../../selectors/achievementCards';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import actions from '../../actions';
import deepEqualsUpdate from '../../helpers/deepEqualsUpdate';
import { CardProgressContainer } from './';
import _ from 'lodash';

/**
 * Container to wrap a collection of StudentAchievementCardProgressContainer elements
 * @method StudentAchievementCardProgressContainer
 */
const StudentAchievementCardProgressContainer = ({ achievementCards, achievementCardScores }) => {
  return (
    <CardProgressContainer achievementCards={achievementCards} achievementCardScores={achievementCardScores} />
  );
};

const dispatchGetData = ({ intelligence_id, student_id, language, dispatch }) => {
  if (intelligence_id && student_id) {
    dispatch(actions.getAchievementCards({
      intelligence_id,
      language
    }));

    dispatch(actions.getStudentAchievementCardsProgress({
      intelligence_id,
      student_id
    }));
  }
};

export const ContainerLifecycle = {
  componentDidUpdate(prevProps) {
    const languageChanged = prevProps.language !== this.props.language;
    const intelligenceChanged = prevProps.intelligence_id !== this.props.intelligence_id;

    if (languageChanged || intelligenceChanged) {
      dispatchGetData(this.props);
    }
  },
  componentDidMount() {
    dispatchGetData(this.props);
  }
};

const ContainerComposed = compose(
  lifecycle(ContainerLifecycle),
  shouldUpdate(deepEqualsUpdate)
)(StudentAchievementCardProgressContainer);

const mapStateToProps = (state, ownProps) => {
  const { student_achievement_cards_progress, language } = state;
  const { slug, student_id } = ownProps.match.params;

  const currentLanguage = language || 'es';
  const intelligence = getIntelligenceBySlug(state, slug);

  const student = findStudentAcrossReducers(state, student_id);
  const achievementCards = getAchievementCardsByIntelligence(state, intelligence.id, currentLanguage);
  const achievementCardScores = getAchievementCardScores({ achievement_card_scores: student_achievement_cards_progress }, student_id, intelligence.id);

  return {
    intelligence_id: intelligence.id,
    student_id: _.get(student, 'id'),
    language,
    achievementCards,
    achievementCardScores
  };
};

const mapDispatchToProps = (dispatch) => ({ dispatch });

const ContainerConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(ContainerComposed);

export default withRouter(ContainerConnected);
