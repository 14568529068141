/* @flow */

import { call, put, cancelled, race } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import actions from './actions/';
import RequestOptions from './RequestOptions';
import { isNavigatorOnline } from './util';
import _ from 'lodash';

export function fetchFromApi(requestData: Object, endpoint: string = '') {
  const url = RequestOptions.buildFinalURL(endpoint);
  return window.fetch(url, requestData).then(response => response, (e) =>{
    console.error("error",e);
    return (
    { status: 555, errors: e }
    )
  });
}

export function* handleResponse({ response, responseActionType, options, _meta }: Object): any {
  let responseBody = {};

  try {
    /*
      if the server return an empty response the response.json()
      will fail and cause the application to fail, in which case
      we will keep responseBody as empty object and preserve the status
      check: https://github.com/github/fetch/issues/268
     */
    responseBody = yield response.json();
  } catch (e) {
  }

  /*
    in case that the responseBody is an object literal
    we will avoid to put the status and errors information twice
    on the final response payload
   */
  let body = _.isArray(responseBody)
    ? responseBody
    : _.omit(responseBody, ['errors', 'status'])

  let payload = {
    body,
    _meta,
    errors: responseBody.errors,
    status: response.status,
    reportable: options.reportable
  };

  // (Unauthorized)
  if (response.status === 401) {
    yield put(actions.diplomaticoInfo({ code: '002', info: payload }));
  }

  if (response.status >= 400) {
    yield put(actions.responseError(payload, responseActionType));
    return;
  }

  yield put(actions.responseSuccess(payload, responseActionType));
}

export function* request({ payload, headers, options, responseActionType, _meta }: Object): any {
  try {
    let requestData = {
      method: options.method,
      headers: headers,
    };

    if (!_.isEmpty(payload)) {
      requestData.body = JSON.stringify(payload);
    }

    const waitTime = RequestOptions.TIMEOUT_THRESHOLD;

    if (!isNavigatorOnline()) {
      yield put(actions.diplomaticoInfo({ code: '001', info: { _meta } }));
      return;
    }

    const { response, timeout } = yield race({
      response: call(fetchFromApi, requestData, options.endpoint),
      timeout: call(delay, waitTime)
    });

    if (timeout) {
      throw new Error('TIMEOUT');
    }

    yield call(handleResponse, { response, responseActionType, options, _meta });
  } catch (e) {
    yield put(actions.requestTimeout({ error: e }, responseActionType));
  } finally {
    if (yield cancelled()) {
      yield put(actions.requestCancelled());
    }
  }
}

export default request;
