/* @flow */

import React from 'react';
import { connect } from 'react-redux';
import { compose, lifecycle, pure, withHandlers, withState } from 'recompose';
import { startSubmit, arrayPush, arrayRemove } from 'redux-form';
import { SchoolCoursesForm } from '../components/';
import { FORM_NAME } from '../components/SchoolLevelBooksForm'; 
import type { Level, ReduxFunction } from '../components/types';
import getNewAndUpdated from '../../helpers/getNewAndUpdated'; 
import { getCourses } from '../../actions/courses';
import prepareForNullify from '../../helpers/prepareForNullify';
import _ from 'lodash';
import SchoolLevelBooksForm from '../components/SchoolLevelBooksForm';
import { getBookInformation } from '../caligrafix/bookInfo';

type Props = {
  level: Level,
  dispatch: ReduxFunction,
  initialValues: Object
};

const SchoolCoursesFormContainer = (props: Props) => {
  return <SchoolLevelBooksForm {...props} />;
};
 
/**
 * This function will dispatch an action to search for courses, it's debounced
 * so it doesn't get called too often
 *
 * @method dispatchCoursesSearch
 * @param  {function}  dispatch     - dispatch from redux
 * @param  {string}    query        - to search courses
 */
 

export const SchoolLevelBooksFormContainerHandlers = {
  onSubmit: ({ dispatch, initialValues, level }: Props) => (values: Object) => { 
    const newLevelBooks= values.levelBooks.map(({id})=> id);   
     
    const payload = {
      level: {
        caligrafix_book_ids: newLevelBooks
      }, 
      level_id: level.id
    };

    dispatch(startSubmit(FORM_NAME));
    dispatch({ type: 'REQUEST_UPDATE_LEVEL_COURSES', payload });
  },
  changeLevelBook: ({ dispatch, level }: Props) => (selectOption: Object) => {
    const book = selectOption.value;
    dispatch(arrayPush(FORM_NAME, 'levelBooks', book));
  },
  onRemoveLevelBook: ({ dispatch, level }: Props) => (book: Object, index: mixed) => {
    const persisted = book.id;
    dispatch(arrayRemove(FORM_NAME, 'levelBooks', index)); 
  },
  onSearchBlur: ({ dispatch }: Props) => () => {
    //dispatch({ type: 'INIT_COURSES_SEARCH' });
  },
  handleSearch: ({ dispatch }: Props) => (value: string) => {
    if (value) {
   //   dispatchCoursesSearch(dispatch, value);
    }

    // we return the value as react-select needs it to update the input with it
    return value;
  }
};

export const SchoolLevelBooksFormContainerLifecycle = {
  componentDidMount() {
    const { dispatch, level } = this.props;
    // ensure that the search is cleaned up when this component gets mounted
    //dispatch({ type: 'INIT_COURSES_SEARCH' });
    // also ensure we have the courses for this level
    dispatch( { type: 'REQUEST_CALIGRAFIX_BOOKS' });//(level));
  }
};

const SchoolCoursesFormContainerComposed = compose( 
  withHandlers(SchoolLevelBooksFormContainerHandlers),
  lifecycle(SchoolLevelBooksFormContainerLifecycle),
  pure
)(SchoolCoursesFormContainer);

const mapStateToProps = (state, ownProps) => {

  if(! ownProps.level.caligrafix_book_ids)
    return  {
      otherBooks: state.caligrafix_books,
      //searchLoading: state.courses_search.loading,
      initialValues: {
        levelBooks: [] 
      }
    };

  const otherBooks =  state.caligrafix_books.filter( ({id, slug,active_in_schools}) => ownProps.level.caligrafix_book_ids.indexOf(id)==-1
  
                && getBookInformation(slug).label.toLowerCase().includes(ownProps.search)
                
                &&  ( active_in_schools || state.user.caligrafix_books.includes( slug) )  );
  ; //getAllCoursesButFromLevel({ courses: searchCourses }, ownProps.level);
  const levelBooks = state.caligrafix_books.filter( ({id}) => ownProps.level.caligrafix_book_ids.indexOf(id)!=-1 );
  
  //ownProps.level.caligrafix_book_ids; 

  return {
    otherBooks: otherBooks,
    //searchLoading: state.courses_search.loading,
    initialValues: {
      levelBooks: levelBooks 
    }
  };
};

const mapDispatchToProps = (dispatch) => ({ dispatch });

export default compose(
  withState('search', 'setSearch', ""),
  )( connect(
  mapStateToProps,
  mapDispatchToProps
)(SchoolCoursesFormContainerComposed));
