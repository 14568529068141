/* @flow */

import React, { useContext, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { Paper, InfoTooltip } from '../components/';
import {
  DataDisplayParentContainer, IntelligencesTopicContainer, IntelligencesProgressContainer,
  ActivityFeedContainer, HeaderContainer, PageTitleContainer, SchoolDashboardNavContainer, SchoolDashboardHeadingContainer
} from '../containers/';
import '../../css/main.css';
import Translations from '../hocs/TranslationsHelper';
import TranslationsHelper from '../hocs/TranslationsHelper';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'react-router-redux';
import { SchoolRoleFilter } from '../components/SchoolRoleFilter';
import topScroll from '../hocs/topScroll';

import icon_activity_asignment from '../../img/home/icon_activity_asignment.png';
import icon_curricular_coverage from '../../img/home/icon_curricular_coverage.png';
import icon_intelligences from '../../img/home/icon_intelligences.png';
import icon_interactive_notebooks from '../../img/home/icon_interactive_notebooks.png';
import icon_my_courses from '../../img/home/icon_my_courses.png';
import icon_planifications from '../../img/home/icon_planifications.png';
import icon_pleiq_learning from '../../img/home/icon_pleiq_learning.png';
import icon_progress_reports from '../../img/home/icon_progress_reports.png';
import icon_resources from '../../img/home/icon_resources.png';
import icon_videotutorials from '../../img/home/icon_videotutorials.png';
import icon_educators from '../../img/home/icon_educators.png';
import icon_account from '../../img/home/icon_account.png';
import icon_profiles from '../../img/home/icon_profiles.png';
import icon_activity_generator from '../../img/home/icon_activity_generator.png';
import icon_digital_paper from '../../img/home/icon_digital_paper.png';

import LicenseFilter from '../containers/LicenseFilter';
import { showConfirmationModal } from '../../actions/confirmationModal';


const SectionColor = React.createContext("purple");
const SchoolSection =TranslationsHelper( ({translations,title, color ,children})=>{

  return <div>
    <p className='school-home-section-title'>{translations.t(title)}</p>
    <div className='school-home-links'>
      <SectionColor.Provider value={color}>
        {children}
      </SectionColor.Provider>
    </div>
  </div>
})
const SchoolLink = TranslationsHelper(  ({name,to,link,  translations,roles, icon,  pro})=>{
  const sectionColor = useContext(SectionColor);
  const dispatch = useDispatch();
   const premium_status = useSelector(({user})=>(user.premium_status ));
   const isPremium = premium_status=="premium";
   
  const disabled = (!to && !link);
  const canUse = !disabled && !( premium_status=="none" && pro );
  
  //( premium_status=="none" && pro );
 const color = (!canUse)?"gray":sectionColor;

  return <SchoolRoleFilter roles={roles}> 
    <div className={"school-home-link school-home-"+color} onClick={
      (e)=>{
        if(canUse)
          if(to)
            dispatch(push(to));
          else
            window.open(link, '_blank').focus();
        else if(pro && !disabled){
          dispatch(showConfirmationModal({
                                title: '',
                                message: 'school_home_cant_use_pro', 
                                actions: [  
                                ]
                              })); 
        
        }else if(disabled){
          dispatch(showConfirmationModal({
                                title: '',
                                message: 'school_home_cant_use_disabled', 
                                actions: [  
                                ]
                              })); 
          
        }
      }
    }>
      <img className="school-home-link-icon" src={icon}/>
      <p className="school-home-link-title-down">{translations.t(name)}</p>
        {pro && isPremium&& !disabled && <span className="school-home-link-pro"/>}
        {pro  && !isPremium && !disabled && <div className={"school-home-link-promo"}>

          <div className="anti-arrow-line"/>
          <div className="anti-arrow-fill"/>
          <div className="anti-arrow-top"> 
            <span>{translations.t("premium")}  </span>
            <span className="school-home-link-promo-crown"/>
          </div>
        </div>
        }
        {  disabled && <div className="school-home-link-disabled">
  
            <span>{translations.t("comming_soon")}  </span>  
        </div>
        }
        <div className={`school-home-link-over school-home-${color}-hl`}>
          <p className="school-home-link-title">{translations.t(name)}</p>
          <p>{translations.t(name+"_description")}</p>
        </div>
    </div>
  </SchoolRoleFilter>;
});
/**
 * Render SchoolHome screen
 * @method SchoolHome
 * @param  {object}                    translations - injected by our Translations HoC to give translated strings
 */
const SchoolHome = ({ translations }) => {
  topScroll();

  const user = useSelector(({user})=>user);
  console.log("USSSSSERRRR:",user)
  return (
    <div id="school-dashboard" className="school-dashboard layout-base">
    <PageTitleContainer pageTitle="home" />
    <HeaderContainer />
    <SchoolDashboardHeadingContainer />
    <Paper.Container lead>
      <SchoolDashboardNavContainer currentTab="home" />
      <Row> 
        <Col xs="12">
            <Paper.Title>
              <h1 className="school-home-title">{translations.t("school_home_hello" ).replace("$user", user.user_name)} </h1> 
              <span className="school-home-subtitle">{translations.t("school_home_subtitle" )}</span>
            </Paper.Title>
        </Col>    
        <Col xs="12" >
          <SchoolSection title="school" color="purple">
            <SchoolLink icon={icon_my_courses} name="my_courses" to="/school/courses"/>
            <SchoolLink icon={icon_interactive_notebooks}  name="my_books"  to="/school/caligrafix"  roles={['coordinator','teacher','observer']} />
            <SchoolLink icon={icon_intelligences}  name="intelligences" to="/school/progress"  roles={['coordinator','teacher','observer']} />
            <SchoolLink icon={icon_planifications} name="planifications_and_orientations" to="/lesson_plans/planification"  roles={['coordinator','teacher','observer']} />
            <SchoolLink icon={icon_progress_reports}  name="progress_reports"  pro  to="/school/progress_reports" />

            {(['CL','MX','CL_EN',"US_FL"].includes(user.location))&& <SchoolLink icon={icon_activity_generator} pro name="planification_generator"   link="/school/gptx/selection" roles={['coordinator','teacher']} /> }
            
            <SchoolLink icon={icon_resources} name="resources" to="/school/resources"/>
            <LicenseFilter filter={["pleiq_cx", "pleiq_cx_plus"]}>  
              <SchoolLink icon={icon_digital_paper} name="digital_paper" to="/school/digital_paper"/>
            </LicenseFilter>
            <SchoolLink icon={icon_curricular_coverage} name="curricular_coverage"  pro  roles={['coordinator','teacher','observer']} />
            <SchoolLink icon={icon_activity_asignment} name="activity_assignment"  pro  roles={['coordinator','teacher','observer']} />



          </SchoolSection>
        </Col>
        <Col xs="12"> 

          <SchoolSection title="learning" color="blue">
            <SchoolLink icon={icon_videotutorials}  name="video_tutorials" to="/video_tutorials/general"/>
            <SchoolLink icon={icon_pleiq_learning}  name="pleiq_learning" link="https://learning.pleiq.com/"   />
            <SchoolLink icon={icon_educators}name="educators_4_0" link="https://www.facebook.com/groups/8579247315434446"  roles={['coordinator','teacher','observer']}  /> 
          </SchoolSection>
        </Col>
        <Col xs="12"> 

          <SchoolSection title="administration" color="red">
            <SchoolLink icon={icon_account} name="my_account" to="/settings"/>
            <SchoolLink icon={icon_profiles} name="profiles" to="/school/users" roles={['coordinator']}/>  
          </SchoolSection>
        </Col>
      </Row> 
    </Paper.Container> 
  </div>
  );
};

export default Translations(SchoolHome);
