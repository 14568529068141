/* @flow */

import React from 'react';
import { Form,FormFeedback,FormGroup } from 'reactstrap';
import { compose, pure } from 'recompose';
import { reduxForm, Field } from 'redux-form';
import { Button, InputFieldSelect, SubmitButton, UserChildForm } from './';
import Translations from '../hocs/TranslationsHelper';
import type { Student, ReduxFormFunction, TranslationsObject, ReduxFormFlag } from './types';
import classNames from 'classnames';
import InputField from './InputField';
import { required } from './validations';
import { Input } from 'glamorous';
import buttonIcon from "../../img/ui_icon_comment_send.svg";

type Props = {
  student?: Student,
  submitting: ReduxFormFlag,
  onDeleteClick: () => mixed,
  persisted: boolean,
  valid: ReduxFormFlag,
  dirty: ReduxFormFlag,
  handleSubmit: ReduxFormFunction,
  translations: TranslationsObject
};

/**
 * component to display a form to edit/create a student info
 *
 * @method StudentDataForm
 * @param  {Array<Object>}  coursesOptions     - options to be displayed on the courses select
 * @param  {function}       handleSubmit       - injected by `redux-form`, handles form submition
 * @param  {boolean}        submitting         - injected by `redux-form`, indicates if the form is submitting
 * @param  {boolean}        valid              - injected by `redux-form`, indicates if the form is valid
 * @param  {boolean}        dirty              - injected by `redux-form`, indicates if the form have been edited
 * @param  {Object}         translations       - object containing app translations
 */

/*
const StudentCommentField = (props)=>{
  const {value, onChange} = props.input;
  <Input {...input} className={className} type={type} disabled={disabled || submitting} state={formColor} placeholder={placeholder} />
}
*/
const StudentCommentField = (props) =>{
  const { input, label, name, placeholder, type, meta,disabled } = props;
  const { dirty, error, submitting, submitFailed, active } = meta;
  const showErrors = !active && (submitFailed || dirty) && error;
  const formColor = classNames({
    danger: showErrors
  });


  return <Input className="form-input-comment-box-input" {...input} type={type} disabled={disabled || submitting} state={formColor} placeholder={placeholder} />
}
const StudentCommentForm = ({error,  handleSubmit,  persisted, submitting, valid, dirty, translations }: Props) => {
 

  const formClassName = classNames({ 'has-danger': error }, 'form', "student-data-form");

  return (
    <Form className={formClassName} onSubmit={handleSubmit}>
      
      <div className="student-comment-form-actions">
        { error &&
            <FormGroup>
              <FormFeedback>{translations.t(error)}</FormFeedback>
            </FormGroup>}

        <div className="form-input-comment-box">

            <Field
              name="content"
              placeholder={translations.t('empty_comment')} 
              component={StudentCommentField}
              className="form-input-comment-field"
              validate={[ required ]}
            /> 
            <SubmitButton loading={submitting} className="btn-primary--green form-input-comment-box-button" disabled={!dirty || !valid}>
              <img className="form-input-comment-button-image" src={buttonIcon} />
            </SubmitButton> 
        </div>
        {persisted}
      </div>
    </Form>
  );
};

export const FORM_NAME = 'studentNewCommentForm';

const StudentCommentFormComposed = compose(
  pure
)(StudentCommentForm);

export default reduxForm({
  form: FORM_NAME,
  enableReinitialize: true
})(Translations(StudentCommentFormComposed));
