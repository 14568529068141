
export const resources = [

    {
        id: "cube_lin",
        licenses: ["pleiq_school", "pleiq_cx_plus"]         
    },
    {
        id: "pleiq_cube",
        licenses: ["pleiq_school", "pleiq_cx_plus"]         
    },
    {
        id: "didactic_guide",
        licenses: ["pleiq_school", "pleiq_cx_plus", "pleiq_cx"],
        items:["guide_latam","guide_chile"],
        shadow: true
    },
    {
        id: "cx_books",
        licenses: ["pleiq_cx_plus", "pleiq_cx"],
        items: [
            "cx_cal",
            "cx_jcs",
            "cx_ibook" 
        ],
        shadow: true
    }
]
export default resources;