/* @flow */

import React from 'react';
import { ScatterChart, Scatter, XAxis, YAxis,ZAxis, CartesianGrid, Tooltip, ResponsiveContainer, Label } from 'recharts';
import { shouldUpdate, compose } from 'recompose';
import classNames from 'classnames';
import deepEqualsUpdate from '../../helpers/deepEqualsUpdate';
import _ from 'lodash';
import Translations from '../hocs/TranslationsHelper';

/*
const ToolTipContent = ({props})=>{
  return <div>
    {
      JSON.stringify(props)
    }

  </div>
}
*/
 

/**
 * A component that renders data into a RadarChart component from Recharts
 *
 * This component is out of coverage because of: https://github.com/recharts/recharts/issues/765
 *
 * @method ScatterChartWrapper
 *
 */

 
const ScatterChartWrapper = ({ color, data = [], className, translations,animated, ...otherProps }) => {
  const isEmpty = _.isEmpty(data);

  const containerClass = classNames(className, 'scatterchart-container', { 'scatterchart-container--loading': isEmpty });
  let xAxisDomain = [0, 'auto'];

  // If no data, set the domain to render 4 hours
  if (_.isEmpty(data)) {
    xAxisDomain = [0, 4];
  }
//content={(props)=>(<span>{JSON.stringify(props)}</span>)}
  return (
    <ResponsiveContainer height={450}>
      <ScatterChart className={containerClass} margin={{ top: 35, right: 30, bottom: 35, left: 10 }}>
        <ZAxis dataKey="v"  name={translations.t('intelligence') } domain={[0, 100]}  opacity={0.6}>
          <Label angle={-90} fill="#989898">{translations.t('intelligence').toUpperCase()}</Label>
        </ZAxis>
        <XAxis dataKey="x" allowDecimals={false} type="number" domain={xAxisDomain}
          tickFormatter={xAxisTickFormatter} name={translations.t('effort')} unit="h" opacity={0.6}>
          <Label position="bottom" fill="#989898">{translations.t('effort').toUpperCase()}</Label>
        </XAxis>
        <YAxis  dataKey="y" type="number" name={translations.t('performance')} domain={[0, 100]} unit="%" opacity={0.6}>
          <Label position="insideLeft" angle={-90} fill="#989898">{ translations.t('performance').toUpperCase()}</Label>
        </YAxis>
        <CartesianGrid />
        <Tooltip cursor={{ strokeDasharray: '3 3' }} itemSorter={(a,b)=>{ return a.dataKey;}} />
        {data.map((dataPoint) => {
          return (
            <Scatter key={dataPoint.subject} 
            isAnimationActive={animated} name={dataPoint.subject} data={[dataPoint]} stroke={dataPoint.color} fill={dataPoint.color} strokeWidth={5} />
          );
        })}
      </ScatterChart>
    </ResponsiveContainer>
  );
};

const xAxisTickFormatter = (tick) => {
  return `${tick}h`;
};

const ScatterChartWrapperComposed = compose(
  shouldUpdate(deepEqualsUpdate)
)(ScatterChartWrapper);

export default Translations(ScatterChartWrapperComposed);
