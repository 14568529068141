
import React from 'react';
import { compose, lifecycle, shouldUpdate } from 'recompose';
import { connect } from 'react-redux';
import actions from '../../../actions';
import imageBook from '../../../img/activity_bank/ui_visual_indicator_book.png';
import starImg from '../../../img/activity_bank/star_99.png';
import starGrayImg from '../../../img/activity_bank/star_99_b.png';
import imageCube from '../../../img/activity_bank/ui_visual_indicator_cube.png';
import downloadIcon from "../../../img/activity_bank/icon_download_transparent.svg";
import { times } from 'lodash';
import { rateLessonPlan } from '../../../actions/lessonPlans';
import TranslationsHelper from '../../hocs/TranslationsHelper';
import { throttle } from "lodash";
import LoadingStatus from "../LoadingStatus";
import { SimpleToolTip } from './SimpleToolTip';

import _ from 'lodash';

  
const imageMap = {
  "cube": imageCube,
  "book": imageBook
}

export class MottoDataNotifier extends React.Component {

  constructor(props) {
      super(props)
      this.state = {need: false}
  }
  componentDidMount () {
    this.updateHeight()
    window.addEventListener("resize", throttle(this.updateHeight,500)  )
    window.addEventListener("scroll",  throttle(this.updateHeight,500)  )
  }

  componentWillUnmount () {
    window.removeEventListener("resize", throttle(this.updateHeight,500) )
    window.addEventListener("scroll", throttle( this.updateHeight,500) )
  }

  updateHeight = () => {
    if(!this.wrapper)return;
    var need =  window.innerHeight > this.wrapper.getBoundingClientRect().bottom;
    this.setState({need});
    if(need && ! this.props.loading){
      this.props.onMoreDataNeeded();
    } 
  }

  render() {
      return (
        <div  ref={(ref) => this.wrapper = ref} className={this.props.className}   > 
        {
       // this.state.need?<h2 style={{position:"fixed", top:"590px"}}>"i need data!"</h2>: null
        }
        {
            this.props.children
        }
        </div>
      )
  }

}  
 
const LessonPlansContainer = ({lesson_plans, onSelect,lesson_plans_end, dispatch,kind,lesson_plans_page, loading, translations, search, filter})=>{
 
  if(!lesson_plans)
  return <div>...</div>
  const {data, values} = lesson_plans ;
 

  return <MottoDataNotifier className="ab-cards-scroll" loading = {loading} onMoreDataNeeded={(e)=>{ 
    if(!lesson_plans_end)dispatch(actions.getLessonPlans({kind, search, filter,page: lesson_plans_page+1 }));
  }}>
    <div className="ab-cards-container">
      {
          data.map(({id, name, image_uri, pdf_uri, color, user_score, global_score, votes})=> <div key={id} className="ab-card" >
            <div className={`ab-card-graphic c-${color}`} onClick={()=>{ onSelect(id);}}  >
              <img className="ab-card-graphic-image" src={imageMap[image_uri]}/>
              <a className="ab-card-graphic-pdf-button"  id={"ab-pdf-button-"+id }  href={pdf_uri}  target="_blank" download onClick={(e)=>{e.stopPropagation()}}>
                <img src={downloadIcon}/>
                <SimpleToolTip text={translations.t("ab_download_tooltip")} target={"ab-pdf-button-"+id } />
              </a>
            </div>
            
            <label className="ab-card-title"> 
              {name}
            </label>
            <div className="ab-card-stars" id={"ab-star-"+id }>  
                <SimpleToolTip text={translations.t("ab_stars_tooltip")} target={"ab-star-"+id } />
              { [0,1,2,3,4].map((i)=>
              <div className="ab-star" id={"ab-star-"+id+"-"+i} onClick={()=>dispatch(rateLessonPlan(id,(i+1)/5.0,kind))}>
                {
                //<button onClick={()=>dispatch(rateLessonPlan(id,(i+1)/5.0))}>{i+1}</button>
                }
                <img className="ab-star-star" src={starImg}/>
                <img className="ab-star-bar" src={starGrayImg} style={{opacity: (1.0 -Math.min(1,Math.max(0,5*(user_score || 0)- i )))}}/> 
              </div>)
              }
            </div>
            <label className="ab-card-stats"> 
              {translations.t("ab_global_score")} {global_score*5} / 5
            </label>
            {
               /*
            <div className="ab-card-data">
              <label className="ab-card-data-title"> 
                {name}
              </label> 
            </div>*/
            }
          </div> )
      } 
    </div>
    {
      (!data.length )?<label className="ab-no-results">{translations.t("ab_no_results")}</label>:null
    }
    <LoadingStatus request="LESSON_PLANS"/>
  </MottoDataNotifier>
  
}

const request_update = _.debounce((dispatch,action)=>dispatch(action), 300)

export const ContainerLifecycle = {
    componentDidUpdate(prevProps) {
      const languageChanged = prevProps.language !== this.props.language;
      const {kind, search, filter,dispatch} = this.props;
      const filterChanged = prevProps.kind !==  kind || prevProps.filter!=  filter  || prevProps.search!=  search;

      if (languageChanged || filterChanged) {
 
        request_update(dispatch,actions.getLessonPlans({kind, search, filter,page:0, restart:true}));
      }
    },
    componentDidMount() {

      const {kind, search, filter,dispatch} = this.props; 

      request_update(dispatch, actions.getLessonPlans({kind, search, filter,page:0, restart:true}));
    }
  };
  
  const ContainerComposed = compose(
    lifecycle(ContainerLifecycle) 
  )(LessonPlansContainer);


const LessonPlansContainerConnected  = connect((state, {kind})=>{
  return {
    lesson_plans: state.lesson_plans[kind],
    lesson_plans_page : (state.lesson_plans[kind] || {}).page,
    lesson_plans_end : (state.lesson_plans[kind] || {}).end,
    loading: state.loading_status["LESSON_PLANS"]
  }    
}, (dispatch)=>({dispatch}))(ContainerComposed);

export default TranslationsHelper(LessonPlansContainerConnected) ;