/* @flow */

import React from 'react';
import { Form, FormGroup, FormFeedback } from 'reactstrap';
import { InputField, RegistrationForm } from './';
import { Field, reduxForm } from 'redux-form';
import { required, minLength } from './validations';
import Translations from '../hocs/TranslationsHelper';
import type { ReduxFormFunction, ReduxFormErrorGlobal, ReduxFormFlag, TranslationsObject } from '../components/types';
import classNames from 'classnames';
import RegistrationSubmitButton from './RegistrationSubmitButton';

type Props = {
  handleSubmit: ReduxFormFunction,
  translations: TranslationsObject,
  error: ReduxFormErrorGlobal,
  submitting: ReduxFormFlag,
  queryParams?: Object,
  onBackButtonClick: () => mixed
};

/**
 * component to display the sign up form for a parent
 *
 * @method UserPasswordResetForm
 * @param  {boolean}  handleSubmit - injected by 'redux-form', supposed to handle submit
 *
 */
const UserPasswordResetForm = ({ handleSubmit, translations, error, submitting, onBackButtonClick, ...otherProps }: Props) => {
  const formClassName = classNames({ 'has-danger': error }, 'form');
  const disabled = submitting;

  return (
    <RegistrationForm
      title={translations.t('user_password_reset_form_title')}>
      <Form id="user-password-reset-form" className={formClassName} onSubmit={handleSubmit}>
        {
          error &&
          <FormGroup>
            <FormFeedback>{translations.t(error)}</FormFeedback>
          </FormGroup>
        }
        <Field
          className="text-center"
          type="password"
          name="password"
          placeholder={translations.t('password')}
          disabled={disabled}
          component={InputField}
          validate={[required, minLength(8)]}
        />
        <Field
          className="text-center"
          type="password"
          name="passwordRetype"
          placeholder={translations.t('password_confirmation')}
          disabled={disabled}
          component={InputField}
          validate={[required ]}
        />
        <Field
          className="text-center"
          type="hidden"
          name="password_reset_token"
          placeholder={translations.t('password_reset_token')}
          disabled={disabled}
          component={InputField}
          validate={[required]}
        />
        <div className="registration-form-button-wrapper">
          <RegistrationSubmitButton loading={submitting}>
            {translations.t('continue')}
          </RegistrationSubmitButton>
          <span className="registration-form-next-icon pleiq-icon-icon_arrow_right" />
        </div>
      </Form>
    </RegistrationForm>
  );
};

export const FORM_NAME = 'userResetPasswordForm';

export default reduxForm({
  form: FORM_NAME
})(Translations(UserPasswordResetForm));
