/* @flow */

import React from 'react';
import { connect } from 'react-redux';
import { pure, compose, withHandlers, lifecycle, defaultProps } from 'recompose';
import { DirectorIEForm } from '../components';
import actions from '../../actions';
import { startSubmit, change } from 'redux-form';
import { FORM_NAME } from '../components/DirectorIEForm';
import type { ReduxFunction, ReduxFormFunction, RecomposeHandler } from '../components/types';
import _ from 'lodash';

type Props = {
  dispatch: ReduxFunction,
  onSubmit: RecomposeHandler,
  // handleSubmit: ReduxFormFunction,
  queryParams?: Object
};

const DirectorIEFormContainer = ({ onSubmit, ...otherProps }: Props) => {
  return <DirectorIEForm onSubmit={onSubmit} />;
};

export const DirectorIEFormContainerHandlers = {
  // onSubmit: (props: Props) => ({
  //   name,
  //   location,
  //   locale,
  //   locale2,
  //   use_pleiq,
  //   use_cx,
  //   admin_name,
  //   admin_email,
  //   consultant_name,
  //   caligrafix_book_slugs,
  // }: Object) => {
  //   use_cx = Boolean(parseInt(use_cx))
  //   use_pleiq = Boolean(parseInt(use_pleiq))
  //   console.log(props, 'props')
  //   const { dispatch } = props;
  //   // dispatch(startSubmit(FORM_NAME));
  //   // dispatch(actions.createDirector({
  //   //   name,
  //   //   location,
  //   //   locale,
  //   //   locale2,
  //   //   use_pleiq,
  //   //   use_cx,
  //   //   admin_name,
  //   //   admin_email,
  //   //   consultant_name,
  //   //   caligrafix_book_slugs: ['tln1', 'tln2'],
  //   // }));
  // }
};

const DirectorIEFormContainerEnhancements = compose(
  withHandlers(DirectorIEFormContainerHandlers),
  defaultProps({
    queryParams: {}
  })
);

const mapDispatchToProps = (dispatch) => {
  return { dispatch };
};

const DirectorIEFormContainerLifecycle = {
  componentWillMount() {
    const queryParams = this.props.queryParams;

    if (queryParams.locale && _.includes(['es', 'en'], queryParams.locale)) {
      this.props.dispatch(actions.changeLanguage({ language: queryParams.locale }));
      this.props.dispatch(change(FORM_NAME, 'locale', queryParams.locale || 'es'));
    }
  }
};

const DirectorIEFormContainerComposed = compose(
  DirectorIEFormContainerEnhancements,
  lifecycle(DirectorIEFormContainerLifecycle),
  pure
)(DirectorIEFormContainer);

const DirectorIEFormContainerConnected = connect(
  null,
  mapDispatchToProps
)(DirectorIEFormContainerComposed);

export { DirectorIEFormContainerConnected as DirectorIEFormContainer }
export default DirectorIEFormContainerConnected;
