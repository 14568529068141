
import React, { useRef, useState, useEffect } from 'react';
import { useData } from '../hocs/DataRetriever';
import { useSelector, useDispatch } from 'react-redux';
import actions from '../../actions';
import axios from "axios";
import classNames from 'classnames'; 
import { Button } from '../components';
import { useTranslations } from '../hocs/TranslationsHelper';
import { Label, FormFeedback } from 'reactstrap';
const MAX_IMAGE_FILE_SIZE = 1024*1024*2;

export const validateLogoField = (value)=>{ 
    if(! value)return;
    if(value.type != 'image/png')return "image_is_not_png";
    if(value.size > MAX_IMAGE_FILE_SIZE)return "image_is_too_big";
    
}
export const SchoolLogoField =  ({ logo_url,className, formGroupClassName, searchable, selectOptions,   disabled, ...otherProps }) => {
    const { input, label, name, placeholder, meta } = otherProps;
    const { touched, dirty, error, valid, submitting }: ReduxFormMeta = meta;
    const showErrors = (touched || dirty) && error;
    const formColor = classNames({
        danger: showErrors,
        success: valid && (touched || dirty) && !error
    });

    const fsRef = useRef();
    const [imageLoaded,setImageLoaded] = useState(false);
    useEffect(()=>setImageLoaded(false), [logo_url])

    const translations = useTranslations();
    const imgSrc = (input.value)? (URL.createObjectURL(input.value)) : logo_url;

    return ( <div className={"school-logo-container " +formColor }>
                <div className="school-logo-left">
                    <Label>{translations.t("school_logo")}</Label>
                    <Button type="primary" onClickButton={()=>fsRef.current.click()}>{translations.t("upload_image")}</Button>
                    <p>{translations.t("school_logo_msg")}</p>
                </div>
                <div className="school-logo-right">
                    <img className="school-logo-img" src={imgSrc} onLoad={()=>setImageLoaded(true)}  /> 
                    {
                       !imageLoaded &&  <div className="school-logo-spinner">
                               <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        
                        </div>
                    }
      {showErrors && <FormFeedback>{translations.t(error)}</FormFeedback>}
                </div>
            {
            //} <form ref = {formRef} method="post" enctype="multipart/form-data"> 
            }
            <input ref={fsRef} type="file" style={{display: "none"}} accept=".png" onChange={(e)=>{ input.onChange(e.target.files[0]);setImageLoaded(false); }} />
            {
                //</form>
            } 
            </div>);

      /*  <textarea
            rows="8"
            className={className}
            value={input.value}
            onChange={(value) => {
                input.onChange(value); 
            
            }} 
            placeholder={placeholder}
            searchable={searchable}
            disabled={submitting || disabled}
        /> ); */
    
}          
  