
import React, { useContext, useState } from 'react';
import { useLocation } from 'react-router';
import { Button } from '../../components';
import TranslationsHelper from '../../hocs/TranslationsHelper';


const types = [
                {type:'student',
                icon: 'pleiq-icon-icon_intelligence_intrapersonal'},
                {type:'course',
                icon: 'pleiq-icon-icon_course'} 
];

export const ProgressReportTypeSelect = TranslationsHelper(
    ({ 
    selectedType,
    onSelectType,
    translations
})=>
{ 
    return <div className="progress-report-type">
        {
            types.map(({type,icon})=>(

                <Button type="select--reports" className={`progress-report-type-button${(type==selectedType)?' selec':''}`} onClick={()=>onSelectType(type)}>
                    <span className={`progress-report-type-button-icon ${icon} progress-report-type-button-icon-${type}`} />
                    {translations.t('by_' + type)}
                </Button>
            ))
        } 
    </div>
}   
)