/* @flow */

import React from 'react';
import { connect } from 'react-redux';
import { compose, withHandlers, pure } from 'recompose';
import { LevelCollapseItem } from '../components';
import type { RecomposeHandler, Level, Course, ReduxFunction } from '../components/types';
import { editLevel } from '../../actions/levels';
import { getCourses } from '../../actions/courses';
import { getLevelCourses } from '../../selectors/courses';

type Props = {
  level: Level,
  courses: Array<Course>,
  onEditButtonClick: RecomposeHandler,
  onHeaderClick: RecomposeHandler,
  dispatch: ReduxFunction
};

/**
 * container to render LevelCollapseItem and dispatch an action that
 * shows a modal on button click to edit a level.
 *
 * @method LevelCollapseItemContainer
 * @param  {Object}    level                 - this object should represents a level
 * @param  {Array}     courses               - array of the courses that belong to this levels
 * @param  {function}  onEditButtonClick     - injected by `recompose` as a handler, to dispatch an action that will trigger a level edition modal
 * @param  {function}  onHeaderClick         - injected by `recompose` as a handler, to dispatch an action that will fetch the level courses
 *
 */
const LevelCollapseItemContainer = ({ level, courses, ...otherProps }: Props) => {
  const { id, name, courses_count } = level;
  if(id==null && courses.length==0)return null;
  return (
    <LevelCollapseItem
      title={name}
      count={courses_count || courses.length}
      courses={courses}
      levelId={id}
      {...otherProps}
    />
  );
};

export const LevelCollapseItemContainerHandlers = {
  onEditButtonClick: ({ dispatch, level }: Props) => () => {
    dispatch(editLevel(level));
  },
  onHeaderClick: ({ dispatch, level }: Props) => () => {
    dispatch(getCourses(level));
  }
};

const LevelCollapseItemContainerComposed = compose(
  withHandlers(LevelCollapseItemContainerHandlers) 
)(LevelCollapseItemContainer);

const mapStateToProps = (state, ownProps) => {

  const levelId = (ownProps.level)?ownProps.level.id : null;

  return ({
    courses: getLevelCourses(state, levelId),
    all: ownProps.all
  });
};

const mapDispatchToProps = (dispatch) => ({ dispatch });

const LevelCollapseItemContainerConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(LevelCollapseItemContainerComposed);

export default LevelCollapseItemContainerConnected;
