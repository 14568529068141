/* @flow */

import React from 'react';
import Button from './Button';
import classNames from 'classnames';
import { compose, withHandlers, pure } from 'recompose';
import Translations from '../hocs/TranslationsHelper';
import type { RecomposeHandler } from './types';
import RoleFilter from '../containers/RoleFilter';

type Props = {
  title: string,
  icon: string,
  count: string,
  expanded: boolean,
  onHeaderClick: () => mixed,
  onEditButtonClick: () => mixed,
  onButtonClick: RecomposeHandler,
  translations: Object
};

/**
 * component to display the header of a CollapseGroup
 *
 * @method CollapseHeader
 * @param  {string}    title                - title for the header
 * @param  {string}    icon                 - icon class to display, should be one of the keys on the `$icons` map at `src/styles/_settings--icons.scss`
 * @param  {string}    count                - count of how many courses or students
 * @param  {boolean}   expanded             - to change the direction of the icon arrow
 * @param  {function}  onHeaderClick        - callback for when the whole header gets clicked
 * @param  {function}  onEditButtonClick    - callback for the edit button
 * @param  {function}  onButtonClick        - injected by `recompose` as a handler, it calls onEditButtonClick
 * @param  {object}    translations         - an object containing translations
 *
 */
const CollapseHeader = ({ title,editRoles,useButtons, icon, count, expanded, onHeaderClick, onButtonClick, onPdfButtonClick, translations }: Props) => {
  const iconClassName = classNames('collapse-header-icon', `pleiq-icon-${icon}`);
  const iconArrowClassName = classNames(
    'collapse-header-arrow',
    `pleiq-icon-icon_arrow_mini_${expanded ? 'down' : 'right'}`
  );
  const headerClassName = classNames(
    'collapse-header',
    { 'collapse-header--expanded': expanded }
  );
  return (
    <div className={headerClassName} onClick={onHeaderClick}>
      <div className="collapse-header-left">
        <h4 className="collapse-header-title">
          <span className={iconClassName} /> {title}
        </h4>
      </div>
      <div className="collapse-header-right">
        {(useButtons&& onPdfButtonClick)?<Button type="edit" className="collapse-header-edit-button" onClickButton={(e)=>{
          e.stopPropagation();
          onPdfButtonClick()
        }}>
           PDF 
        </Button>:null
        }{(useButtons)?
          <RoleFilter roles={editRoles}>
            <Button type="edit" className="collapse-header-edit-button" onClickButton={onButtonClick}>
              {translations.t('edit')}
            </Button>
          </RoleFilter>:null
        }
        <p className="collapse-header-count">
          {count} <span className={iconArrowClassName} />
        </p>
      </div>
    </div>
  );
};

export const CollapseHeaderHandlers = {
  onButtonClick: ({ onEditButtonClick }: Props) => (event: Object) => {
    // this prevents `onHeaderClick` (the click on the whole header) from triggering
    event.stopPropagation();
    onEditButtonClick();
  } 
};

const CollapseHeaderEnchanced = compose(
  withHandlers(CollapseHeaderHandlers),
  pure
)(CollapseHeader);

export default Translations(CollapseHeaderEnchanced);
