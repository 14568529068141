/* @flow */

export function getIntelligenceProgress({ child_id }) {
  return {
    type: 'REQUEST_INTELLIGENCE_PROGRESS',
    payload: {
      child_id
    }
  };
}
