/* @flow */

import React from 'react';
import { Paper } from '../../components';
import {
  SchoolDashboardNavContainer, SchoolDashboardHeadingContainer, HeaderContainer,
  CoursesSectionContainer, PageTitleContainer
} from '../../containers';
import { StudentPdfSelection, StudentCommentsPdfSelection, StudentCaligrafixPdfSelection } from '../../pdfview/StudentView';
import { CourseSelection } from '../../pdfview/CourseView';

import { HierarchyReportPdf } from '../../pdfview/Hierarchy';
import LicenseFilter from '../../containers/LicenseFilter';
import SchoolUserInvitationsContainer from "../../containers/SchoolUserInvitationsContainer"
import SchoolUserInvitationFormContainer from "../../containers/SchoolUserInvitationFormContainer"
import SchoolUsersContainerConnected from '../../containers/school-admin/SchoolUsersContainer';
import { Col, Row } from 'reactstrap';
import SchoolUserChildrenSelectorContainer from '../../containers/school-admin/SchoolUserChildrenSelectorContainer';
import SchoolAdminNav, {adminNavItems} from "./SchoolAdminNav"
import SchoolUserContainerConnected from '../../containers/school-admin/SchoolUserContainer';
/**
 * screen for the school courses
 *
 * @method SchoolProgress
 *
 */  
const SchoolUser  = ({queryParams}) => {
  return (
    <div id="school-dashboard" className="school-dashboard layout-base">
      <PageTitleContainer pageTitle="title_school_courses" />
      <HeaderContainer />
      <SchoolDashboardHeadingContainer />
      <Paper.Container lead>
        <SchoolDashboardNavContainer currentTab="courses" pdfTarget="pleiq_report_courses" pdfFilter="school_tree" />
 
       {

       }
 
        <Row>
          <Col>
            <SchoolUserContainerConnected/>
          </Col>
        </Row>
        
      </Paper.Container>

   
    </div>
  );
};

export default SchoolUser ;
