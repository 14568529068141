/* @flow */

import React from 'react';
import { Plans } from '../models';
import { Card, CardHeader, CardFooter } from 'reactstrap';
import Button from './Button';

type Props = {
  plan: $Keys<typeof Plans.TYPES>
};

const CardCubicoin = ({ plan }: Props) => {
  const data = Plans.getPlan(plan);

  return (
    <Card className="card-cubicoin">
      <CardHeader>
        <img src={data.icon} alt={`plan-icon-${plan}`} />
      </CardHeader>
      <CardFooter>
        <p className="amount"><span className="pleiq-icon-icon_cubicoin_outline" />{' '}{data.cubicoins}</p>
        <small className="pricing">{`$${data.price}`}</small>
        <Button className="btn-block" type="primary">
          Elegir
          <span className="pleiq-icon-icon_arrow_right btn-icon-right" />
        </Button>
      </CardFooter>
    </Card>
  );
};

export default CardCubicoin;
