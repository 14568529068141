/* @flow */

import React from 'react';
import { Row, Col } from 'reactstrap';
import { Button } from './';
import Translations from '../hocs/TranslationsHelper';

/**
 * component to render the courses, using a collapse-like hierarchy based UI
 * and also some buttons for some actions
 *
 * @method CoursesSectionPlaceholder
 *
 */
const CoursesSectionPlaceholder = ({ addLevelClick, translations }) => {
  return (
    <div className="courses-section-placeholder">
      <Row className="justify-content-center">
        <Col xs="12" md="8">
          <span className="courses-section-placeholder-icon pleiq-icon-icon_course text-center" />
          <h2 className="courses-section-placeholder-title text-center">
            {translations.t('levels_courses_and_students')}
          </h2>
          <p className="courses-section-placeholder-text text-center">
            {translations.t('courses_section_placeholder')}
          </p>
          <Button type="secondary--blue" className="courses-section-placeholder-button" onClick={addLevelClick} block >
            <span className="courses-section-placeholder-button-icon pleiq-icon-icon_plus" />
            {translations.t('create_new_level')}
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default Translations(CoursesSectionPlaceholder);
