/* @flow */

import { call, put, take } from 'redux-saga/effects';
import { request } from './actions/request';
import _ from 'lodash';

const workerResponseHandlerDefaults = {
  onError: _.noop,
  onTimeout: _.noop,
  onSuccess: _.noop
};

export function* workerResponseHandler(options: Object): any {
  options = { ...workerResponseHandlerDefaults, ...options };

  let { response, action, onError, onTimeout, onSuccess } = options;
  let { payload = {} } = response;

  if (payload.timeout) {
    yield call(onTimeout, { response, action });
    return;
  }

  if (payload.errors || (payload.status >= 400)) {
    yield call(onError, { response, action });
    return;
  }

  yield call(onSuccess, { response, action });
}

export function workerRequest({ buildRequestBody, requestParams, requestHandlers }: Object): any {
  return function* workerRequestGenerator(action) {
    const actionPayload = action.payload;
    let payload = {
      request: requestParams,
    };

    // Request with GET/HEAD method cannot have body
    if (requestParams.method.toLowerCase() !== 'get' && requestParams.method.toLowerCase() !== 'delete') {
      payload.body = buildRequestBody({ ...actionPayload });
    }

    const requestAction = request({ type: action.type, payload });
    const { responseActionType } = requestAction.payload;

    yield put(requestAction);

    const response = yield take(responseActionType); 
    yield call(workerResponseHandler, { response, action, ...requestHandlers });
  };
}
export function workerRequestIntegratedLogin({ buildRequestBody, requestParams, requestHandlers }: Object): any {
  return function* workerRequestGenerator(action) {
    const actionPayload = action.payload;
    let payload = {
      request: requestParams,
    };

    // Request with GET/HEAD method cannot have body
    if (requestParams.method.toLowerCase() !== 'get' && requestParams.method.toLowerCase() !== 'delete') {
      payload.body = buildRequestBody({ ...actionPayload });
    }

    const requestAction = request({ type: action.type, payload });
    const { responseActionType } = requestAction.payload;

    yield put(requestAction);

    const response = yield take(responseActionType);

    yield call(workerResponseHandler, { response, action, ...requestHandlers });
  };
}