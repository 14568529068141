import React from 'react';
import { Row, Col } from 'reactstrap';
import Modal from './Modal';
import Paper from './Paper';
import codeInstructions from '../../img/ui_package_code_indicator_02.png';
import Translations from '../hocs/TranslationsHelper';
import type { TranslationsObject } from './types';

type Props = {
  active: boolean,
  toggle: () => mixed,
  translations: TranslationsObject
};

/**
 * Component to display Pleiq code instructions modal
 *
 * @method CodeInstructions
 * @param  {boolean}              active       - to indicate if the modal should be visible
 * @param  {function}             toggle       - callback injected by a parent component to toggle modal visibility
 * @param  {TranslationsObject}   translations - injected by the Translations HoC to allow locale text
 */
const CodeInstructions = ({ active, toggle, translations }: Props) => {
  return (
    <Modal backdropClassName="overlay-backdrop" contentClassName="overlay-modal-content" active={active} toggle={toggle}>
      <div className="code-instructions">
        <span onClick={toggle} className="code-instructions-close-icon pleiq-icon-icon_arrow_left" />
        <Paper.Container>
          <section className="code-instructions-content">
            <Paper.Title>
              <h1 className="code-instructions-title text-center">
                {translations.t('how_find_my_code')}
              </h1>
            </Paper.Title>
            <p className="code-instructions-text text-center">
              {translations.t('how_find_my_code_instructions')}
            </p>
            <img className="img-fluid code-instructions-image" src={codeInstructions} alt="code_instructions" />
            <p className="code-instructions-text text-center">
              {translations.t('not_pleiq_yet')}
            </p>
            <Row>
              <Col xs={{ size: 10, offset: 1 }}>
                <a className="btn btn-success registration-form-next-button btn-block" target="_blank" rel="noopener noreferrer" href={translations.t('buy_url')}>
                  {translations.t('buy_now')}
                  <span className="registration-form-next-icon pleiq-icon-icon_arrow_right" />
                </a>
              </Col>
            </Row>
          </section>
        </Paper.Container>
      </div>
    </Modal>
  );
};

export default Translations(CodeInstructions);
