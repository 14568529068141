/* @flow */

import React from 'react';
import { TimelineProgress, LineChart } from '../components';
import { withState, withHandlers, pure, compose } from 'recompose';
import moment from 'moment';
import _ from 'lodash';
import Translations from '../hocs/TranslationsHelper';
import classNames from 'classnames';
import { getDefaultChartDomain, getFormatedDate } from '../../helpers/defaultChartDomain';

/*
 * This function filters the data to get the data for the current option.
 * It creates an array with the format the LineChart component expects.
 */
const getCurrentOptionData = (data, selectedOption, translations) => {
  // make sure moment language match with the one within the state
  moment.locale(translations.locale());

  const currentData = getCurrentData(data, selectedOption);

  /* Map data to the expected array format */
  const mappedData = _.map(currentData, (value, date) => {
    return { name: getFormatedDate(date, selectedOption, translations), value: parseInt(value, 10), date };
  });

  if (_.isEmpty(mappedData)) {
    return getDefaultChartDomain(selectedOption, translations);
  }

  /* sort the array by date as Javascript objects don't have an explicit order */
  return _.sortBy(mappedData, ['date']);
};

const getCurrentData = (data, selectedOption) => (_.get(data, [selectedOption], null));

const SchoolTimelineProgressContainer = ({ data, translations, selectedOption, onClickOption,  noborder, language }: Props) => {

  const formattedData = getCurrentOptionData(data, selectedOption, translations, language);
  const className = classNames('timeline-progress-container', { 'borderless': noborder });
  const loading = !getCurrentData(data, selectedOption);

  return (
    <div className={className}>
      <TimelineProgress selectedOption={selectedOption} onItemSelected={onClickOption}>
        <LineChart data={formattedData} yAxis loading={loading} />
      </TimelineProgress>
    </div>
  );
};

export const SchoolTimelineProgressContainerHandlers = {
  onClickOption: (props: Object) => (selectedOption: 'month' | 'week' | 'day') => {
    props.dispatchGetSchoolHistory({
      ...props,
      selectedOption
    });

    props.onClickOption(selectedOption);
  }
};

const SchoolTimelineProgressContainerEnhancements = compose(
  withState('selectedOption', 'onClickOption', 'day'),
  withHandlers(SchoolTimelineProgressContainerHandlers)
);

export const SchoolTimelineProgressContainerTranslated = Translations(SchoolTimelineProgressContainer); 

const SchoolTimelineProgressContainerComposed = compose(
  SchoolTimelineProgressContainerEnhancements,
  pure
)(SchoolTimelineProgressContainerTranslated);

export default SchoolTimelineProgressContainerComposed;

