/* @flow */

import { enableInitializing } from 'initializable-reducer';
import { responseHasError } from '../diplomatico/reducers/helper';
import type { Action } from '../diplomatico/types';

const initialState = {
  loading: false,
  courses: []
};

function coursesSearch(state: Object = initialState, { type, payload }: Action) {
  switch (type) {
    case 'INIT_COURSES_SEARCH':
      return {
        ...initialState,
        loading: false
      };
    case 'START_COURSES_SEARCH':
      return {
        ...state,
        loading: true
      };
    case 'RESPONSE_SEARCH_COURSES':
      if (responseHasError(payload)) {
        return state;
      }

      const data = payload.body;

      return {
        loading: false,
        courses: [
          ...state.courses,
          ...data
        ]
      };
    case 'RESPONSE_SEARCH_COURSES_CLEAN':
      return {
        ...initialState,
        courses: payload.body
      };
    case 'UPDATE_COURSE':
      const courses = state.courses.map((course) => {
        if (course.id === payload.course.id) {
          return { ...course, ...payload.course };
        }
        return course;
      });

      return {
        ...state,
        courses
      };
    default:
      return state;
  }
}

export {
  coursesSearch,
  initialState
};

export default enableInitializing(coursesSearch);
