/* @flow */

import React from 'react';
import actions from '../../actions';
import { Dropdown, IntelligenceProgressBarChart } from '../components';
import { compose, lifecycle, withState, withHandlers, shouldUpdate } from 'recompose';
import { connect } from 'react-redux';
import _ from 'lodash';
import deepEqualsUpdate from '../../helpers/deepEqualsUpdate';
import { getSchoolProgressByCourse } from '../../selectors/schoolProgress';
import { Col, Row } from 'reactstrap';
import { SchoolCoursesProgressContainer, CoursesCompareChartContainer } from './';
import { getCoursesProgressByCaligrafixBook } from '../../selectors/courses';
import CaligrafixBook from '../models/CaligrafixBook';
 
/**
 * Container that receives data from the store and passes down the required data
 * @method SchoolGeneralCaligrafixCoursesProgressContainer
 */
const SchoolGeneralCaligrafixCoursesProgressContainer = ({caligrafix_books, user_books, courses,levels, school_general_courses_progress }) => { 

  //const progress = getCurrentLevelProgress(currentLevelId, school_general_courses_progress);
  //const levelOptions = getLevelOptions(levels);
  let animated = true;

  let allProgress = {};
  
  caligrafix_books.forEach(({id})=>{
    allProgress[id]={};
  })
  levels.forEach((level)=>{
    const progress = getCurrentLevelProgress(level.id, school_general_courses_progress);
    caligrafix_books.forEach(({id})=>{
      Object.assign(allProgress[id], _.get(progress,id,{}));
    })
    //console.log("level course progredddd", progress, level.id, school_general_courses_progress);
    //allProgress = Object.assign({},allProgress, progress);
    
  });

  //console.log("COURSE PROGRESS:", allProgress);
  
  const coursesProgress = getCoursesProgressByCaligrafixBook({ user_books, courses:courses.filter((course)=>(course.level_id!==null)), caligrafix_books, progress:allProgress });
 // console.log("COURSE PROGRESS:",allProgress, coursesProgress);
  //const level = _.find(levels, (l)=>(l.id===currentLevelId) );
  //console.log("copro", progress);
  return (
    <div>
    <Row className="progress-bar-chart-row">
      {
        coursesProgress.map((item) => (
          <Col xs="12" md="6" key={item.slug}>
            <IntelligenceProgressBarChart
              animated = {animated}
              title={CaligrafixBook.getCaligrafixBookTitle(item.slug)}
              data={item.data}
              color={item.color} />
          </Col>
        ))
      }
    </Row>

    </div>
  );
}; 


const getCurrentLevelProgress = (currentLevelId, school_general_courses_progress) => {
  return getSchoolProgressByCourse({ progress: school_general_courses_progress }, currentLevelId);
};
/*
export const ContainerHandlers = {
  initialLevel: ({ levels }) => {
    return _.isEmpty(levels) ? '' : _.head(levels).id;
  },
  onChangeLevel: ({ dispatch, updateLevel }) => (nextLevel) => { 
    dispatch(actions.getSchoolGeneralCaligrafixCoursesProgress({ level_id: nextLevel.value }));
    updateLevel(nextLevel.value);
  }
};
*/
export const ContainerLifecycle = {
  componentDidMount() {
    const { dispatch, levels } = this.props;

    dispatch({ type: 'REQUEST_LEVELS' });
    dispatch({ type: 'REQUEST_ALL_COURSES' });
    //console.log("LEVELES", levels);

    levels.forEach(({id})=>{
        dispatch(actions.getSchoolGeneralCoursesCaligrafixBookProgress({ level_id: id })); 
    }); 
  },
  componentDidUpdate(prevProps: Object) { 
    const { dispatch, levels ,school_general_courses_progress} = this.props;
    if(levels.length > 0 && _.isEmpty(school_general_courses_progress)){ 
      levels.forEach(({id})=>{
          dispatch(actions.getSchoolGeneralCoursesCaligrafixBookProgress({ level_id: id })); 
      }); 
    }
  }
};

const SchoolGeneralCaligrafixCoursesProgressContainerComposed = compose(
 // withState('currentLevelId', 'updateLevel', ContainerHandlers.initialLevel),
  //withHandlers(ContainerHandlers),
  lifecycle(ContainerLifecycle),
  shouldUpdate(deepEqualsUpdate)
)(SchoolGeneralCaligrafixCoursesProgressContainer);//(SchoolGeneralCaligrafixCoursesProgressContainerWithPdf); //
/*
const getLevelOptions = (levels) => (_.map(levels, (level) => ({
  value: level.id, label: level.name
})));*/

const mapStateToProps = ({ user, school_general_courses_caligrafix_book_progress, caligrafix_books,levels,courses, pdf }, ownProps) => {

  return {
    levels,
    courses,//: ,
    user_books: user.caligrafix_books,
    school_general_courses_progress: school_general_courses_caligrafix_book_progress,
    caligrafix_books,
    
    pdf
  };
};

const mapDispatchToProps = (dispatch) => ({ dispatch });

const SchoolGeneralCaligrafixCoursesProgressContainerConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(SchoolGeneralCaligrafixCoursesProgressContainerComposed);

export default SchoolGeneralCaligrafixCoursesProgressContainerConnected;
