/* @flow */

import React from 'react';
import { DataDisplay } from '../components/';
import classNames from 'classnames';
import Translations from '../hocs/TranslationsHelper';
import { pure, compose } from 'recompose';
import _ from 'lodash';
import { getIntelligenceProgressById } from '../../selectors/intelligenceProgress';
import { formatUsageTime } from './IntelligenceProgressAnalisisContainer';

/**
 * Component that receives the data to display the stats
 * of the user to be shown within simple cards
 * @method IntelligenceProgressAnalisisContainer
 *
 * @param  {object}        translations - injected by our Translations HoC to give translated strings
 * @param  {array}         currentProgress - injected by Redux includes all intelligence_progress from the store for the current child
 * @param  {array}         intelligences - injected by Redux includes all intelligences from the store
 &
 */
export const CaligrafixProgressAnalisisContainer = ({ translations, currentProgress, intelligences, totalTime }: Props) => {
  const entries = [
    {label: "Trazos y letras 1", type: "book_less_used", value:"20h"},
    {label: "Trazos y letras 2", type: "book_more_used", value:"120h"},
    {label: translations.t("all_notebooks"), type: "total_use", value:"1120h"},
    {label:  translations.t("all_notebooks"), type: "activities_completed", value:"65120"},
    
  ]//getDataDisplayInformation({ translations, currentProgress, intelligences });

  const displayLeadClass = classNames('display-lead');

  return (
    <div className="displays-column">
      {
        (totalTime!==undefined)?
        <DataDisplay  title={translations.t("total_time")} narrow>
          <div className={displayLeadClass}>
            <p>{formatUsageTime(totalTime)}</p>
          </div>
        </DataDisplay>:undefined
      }
    <div className="displays-row">
      {
        entries.map((entry, key) => {
          const { label, type, value } = entry;
          const title = translations.t(type);
          return (
            <DataDisplay key={key} label={label} title={title}>
              <div className={displayLeadClass}>
                <p>{value}</p>
              </div>
            </DataDisplay>
          );
        })
      }
    </div>
      
      </div>
  );
};

// Converts duration in seconds to duration in hours
// If duration is less than 1 hour converts duration to minutes
export const formatDuration = (duration) => {
  const secondsInHours = 3600;
  const parsedDuration = duration >= secondsInHours ? parseInt(duration / (60 * 60), 10) : parseInt(duration / 60, 10);

  return duration >= secondsInHours ? `${parsedDuration}h` : `${parsedDuration}min`;
};


/*
export const getDataDisplayInformation = ({ translations, currentProgress, intelligences }: Object) => {
  const defaultValue = {
    duration: 0,
    progress: 0,
    slug: 'no_data'
  };

  const intelligencesWithProgress = _.map(intelligences, (intelligence) => {
    const currentIntelligenceProgress = getIntelligenceProgressById({ intelligence_progress: currentProgress }, intelligence.id);
    const progress = _.has(currentIntelligenceProgress, 'score') ? parseInt(currentIntelligenceProgress.score, 10) : 0;
    const duration = _.has(currentIntelligenceProgress, 'duration') ? currentIntelligenceProgress.duration : 0;

    return {
      slug: intelligence.slug,
      duration,
      progress
    };
  });

  let maxWorked = _.maxBy(intelligencesWithProgress, (int) => int.duration);
  let minWorked = _.minBy(intelligencesWithProgress, (int) => int.duration);
  let maxProgress = _.maxBy(intelligencesWithProgress, (int) => int.progress);
  let minProgress = _.minBy(intelligencesWithProgress, (int) => int.progress);

  // check if there is any record different from zero
  const withoutData = _.isEmpty(intelligencesWithProgress.find((int) => (int.duration !== 0 && int.progress !== 0)));

  if (withoutData) {
    maxWorked = defaultValue;
    minWorked = defaultValue;
    maxProgress = defaultValue;
    minProgress = defaultValue;
  }

  return [
    { intelligence: translations.t(maxWorked.slug), type: 'best_worked', value: formatIntelligenceDuration(maxWorked.duration) },
    { intelligence: translations.t(minWorked.slug), type: 'less_worked', value: formatIntelligenceDuration(minWorked.duration) },
    { intelligence: translations.t(maxProgress.slug), type: 'best_progress', value: `${maxProgress.progress}%` },
    { intelligence: translations.t(minProgress.slug), type: 'less_progress', value: `${minProgress.progress}%` }
  ];
};*/

export default compose(
  pure
)(Translations(CaligrafixProgressAnalisisContainer));
