/* @flow */

import React from 'react';
import { Col, Row } from 'reactstrap';
import { Paper, InfoTooltip } from '../components';
import {
  IntelligencesTopicContainer, IntelligenceTimelineProgressContainer,
  ParentAchievementCardProgressContainer, HeaderContainer, RadialProgressBarContainer,
  PageTitleContainer
} from '../containers';
import { pure, compose } from 'recompose';
import classNames from 'classnames';
import Translations from '../hocs/TranslationsHelper';
import topScroll from '../hocs/topScroll';

const Intelligence = ({ translations }) => {
  topScroll();
  const paperContentClass = classNames('intelligence-timeline');

  return (
    <div id="dashboard" className="layout-base">
      <PageTitleContainer pageTitle="title_parent_intelligence" />
      <HeaderContainer />
      <IntelligencesTopicContainer />
      <Paper.Container lead>
        <Row>
          <Col xs="12" sm="8" lg="9">
            <Paper.Title>
              <h1>{translations.t('intelligence_screen_timeline_label')}</h1>
            </Paper.Title>
          </Col>
          <Col xs="12" sm="4" lg="3" className="hidden-sm-down nowrap-text-container">
            <Paper.Title>
              <h1 className="nowrap-text">{translations.t('intelligence_screen_current_progress_label')}</h1>
            </Paper.Title>
          </Col>
          <Col xs="12" sm="8" lg="9">
            <Paper.Content className={paperContentClass}>
              <IntelligenceTimelineProgressContainer />
            </Paper.Content>
          </Col>
          <Col xs="12" className="hidden-sm-up">
            <hr />
            <Paper.Title>
              <h1 className="mt-5">{translations.t('intelligence_screen_current_progress_label')}</h1>
            </Paper.Title>
          </Col>
          <Col xs="12" sm="4" lg="3">
            <div className="intelligence-pie-chart">
              <div className="pie-progress-graph">
                <RadialProgressBarContainer />
                <div className="mt-4">
                  {/*<a className="btn btn-transparent" target="_blank" rel="noopener noreferrer" href={translations.t('activities_url')}>{translations.t('how_to_improve')}</a>*/}
                </div>
              </div>
            </div>
          </Col>
          <Col xs="12">
            <Paper.Title>
              <h1 className="mt-5">
                {translations.t('intelligence_screen_achievements_label')} {' '}
                <InfoTooltip message="tooltips_intelligence_screen_achievements_label" />
              </h1>
            </Paper.Title>
          </Col>
          <Col xs="12">
            <ParentAchievementCardProgressContainer />
          </Col>
        </Row>
      </Paper.Container>
    </div>
  );
};
/*
const IntelligenceComposed = compose(
  pure
)(Intelligence);*/

export default Translations(Intelligence);
