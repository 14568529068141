 

import React from 'react';
import {connect} from 'react-redux'  
import {   withPdfHandlers,   PdfOnlyData, withPdfHandlers2 } from "../../pdfer/Pdfable";  
import {   LevelCollapseListContainer } from "../containers"; 
import { Row, Col } from 'reactstrap';
import { getLevelCourses } from '../../selectors/courses';
import { getAllCourses } from '../../actions/courses';
import { getStudents } from '../../actions/students';
/*
var ChallengesReportDiv =  ( {intelligences, translations}) =>(
    <div> 
            
        { 
            intelligences.map( 
                (intelligence) =>(
                    <div>  
                        <PdfOnlyData path={"school.intelligence_"+intelligence.slug}>
                            <h2>name: {intelligence.name}</h2> 
                        </PdfOnlyData>
                        <SchoolGeneralAchievementCardProgressContainerPdf path={"school.intelligence_data_"+intelligence.slug} match={{params:{slug: intelligence.slug}}}/>
                    </div>
                )
            ) 
        } 
    </div>
)*/

    var HierarchyReportDiv =  ( {schoolName/*, translations*/}) =>(
    <div>  
        <PdfOnlyData   filter="school_tree" path="school_tree_title" >
            <h2>  {schoolName}</h2>  
        </PdfOnlyData> 
        <Row> 
            <Col xs="12"> 
                <LevelCollapseListContainer all={true}/>  
            </Col>
        </Row>
    </div>
)  
const mapStateToProps  = (state )=>{  
    var {user} = state; 
    var {levels} = state ;
    var {courses} = state ;

    return {
        schoolName: user.name ,
        levels  ,
        courses,
        pre_done: state.activeRequests ===0
    };
}
const mapDispatchToProps  = (dispatch) => ({ dispatch });
export var HierarchyReportPdf  = connect(mapStateToProps ,mapDispatchToProps )( withPdfHandlers2 (
    {path:"school.school_tree", 
    filter: "school_tree",
    pre: ({courses,dispatch}) =>{   
        //dispatch(getAllCourses()); 
        courses.forEach((course) => { 
            dispatch(getStudents(course));
        });
        return true;
    }
})(HierarchyReportDiv)) ;