/* @flow */

import React from 'react';
import { replace, push } from 'react-router-redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withHandlers, compose, lifecycle } from 'recompose';
import { IntelligencesNav } from '../components';
import _ from 'lodash';

/**
 * screen for the school SchoolChallenges
 *
 * @method IntelligencesNavContainer
 * @param {string} redirectRouteBase - the base route to redirect on Click
 * @param {string} defaultRoute      - route to redirect when parameter is unexpected
 */
const IntelligencesNavContainer = ({ slugs, onClick, activeSlug, ...otherProps }) => {
  return (
    <div className="intelligences-nav-container">
      <IntelligencesNav slugs={slugs} onClickIntelligence={onClick} selected={activeSlug} {...otherProps} />
    </div>
  );
};

export const ContainerLifecycle = {
  componentWillMount() {
    const { activeSlug, slugs, redirectToDefault } = this.props;

    if (!_.includes(slugs, activeSlug)) {
      redirectToDefault();
    }
  }
}

export const ContainerHandlers = {
  onClick: ({ dispatch, redirectRouteBase }) => (slug) => {
    slug = slug.toLowerCase();
    dispatch(push(`${redirectRouteBase}/${slug}`));
  },
  redirectToDefault: ({ dispatch, defaultRoute }) => () => {
    dispatch(replace(defaultRoute));
  }
};

const IntelligencesNavContainerComposed = compose(
  withHandlers(ContainerHandlers),
  lifecycle(ContainerLifecycle)
)(IntelligencesNavContainer);

const mapStateToProps = ({ intelligences }, ownProps) => {
  const { params } = ownProps.match;
  const { slug } = params;

  const activeSlug = (slug && slug.toUpperCase()) || 'general';
  const slugs = _.map(intelligences, 'slug');

  return {
    activeSlug,
    slugs
  }
};

const mapDispatchToProps = (dispatch) => ({ dispatch });

const IntelligencesNavContainerConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(IntelligencesNavContainerComposed);

export default withRouter(IntelligencesNavContainerConnected);
