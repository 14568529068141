/* @flow */

import React from 'react';
import { LevelCollapseItemContainer, CourseCollapseItemContainer } from '../containers';
import  { PdfOnlyData } from '../../pdfer/Pdfable';

import type { Level } from './types';

type Props = {
  levels: Array<Level>
};

/**
 * component to display a Level with the courses inside a collapse
 *
 * @method LevelCollapseList
 * @param  {Array<Level>}  levels     - contains all the levels to be rendered
 *
 */
const LevelCollapseList = ({ levels, all }: Props) => {
  return (
    <div className="course-collapse-list">
      {levels.map((level: Level) => {
        return (
          (all)?
            <PdfOnlyData path={"level-"+level.id} pdf={{newpage:true}} filter="school_tree">
              <LevelCollapseItemContainer
                key={level.id}
                level={level}
                all={all}
              />

            </PdfOnlyData>
          :<LevelCollapseItemContainer
            key={level.id}
            level={level}
            all={all}
          />
        );
      })}
      <LevelCollapseItemContainer 
            level={{id:null  }}
            all={all}
          /> 
        <CourseCollapseItemContainer 
          course={null}
          all={all}
        />
    </div>
  );
};

   /*   <CourseCollapseItemContainer 
            course={{id:null}}
            all={all}
          />*/
export default LevelCollapseList;
