/* @flow */

import React from 'react';
import { connect } from 'react-redux';
import { compose, pure, withHandlers } from 'recompose';
import { CoursesSectionPlaceholder } from '../components/';
import { createLevel } from '../../actions/levels';
import type { ReduxFunction, RecomposeHandler } from '../components/types';

type Props = {
  dispatch: ReduxFunction,
  addLevelClick: RecomposeHandler
};

/**
 * This compoennt will render CoursesSectionPlaceholder and pass an action to
 * it to create a level
 *
 * @method CoursesSectionPlaceholderContainer
 * @param {function} addLevelClick     - function that opens a level edit modal when called
 */
const CoursesSectionPlaceholderContainer = (props: Props) => {
  return <CoursesSectionPlaceholder {...props} />;
};

export const CoursesSectionPlaceholderContainerHandlers = {
  addLevelClick: ({ dispatch }: Props) => () => {
    dispatch(createLevel());
  }
};

const CoursesSectionPlaceholderContainerComposed = compose(
  withHandlers(CoursesSectionPlaceholderContainerHandlers),
  pure
)(CoursesSectionPlaceholderContainer);

const mapDispatchToProps = (dispatch) => ({ dispatch });

export default connect(
  null,
  mapDispatchToProps
)(CoursesSectionPlaceholderContainerComposed);
