/* @flow */

export function getSchoolUsers() {
  return {
    type: 'REQUEST_ALL_SCHOOL_USERS'
  };
} 

export function signUpSchoolUser( user: Object) {
  return {
    type: 'REQUEST_SCHOOL_USER_SIGNUP',
    payload: {
     user
    }
  };
}

export function updateSchoolUser(school_user: Object) {
  return {
    type: 'UPDATE_SCHOOL_USER',
    payload: {
      school_user
    }
  };
}

export function deleteSchoolUser(school_user: Object) {
  return {
    type: 'DELETE_SCHOOL_USER',
    payload: {
      school_user: {
        ...school_user,
        course_id: school_user.course_id || 'unassigned'
      }
    }
  };
}

export function removeSchoolUsers(school_users: Object) {
  return {
    type: 'REMOVE_SCHOOL_USERS',
    payload: {
      school_users
    }
  };
}

export function removeSchoolUsersCourse(course: Object) {
  return {
    type: 'REMOVE_SCHOOL_USERS_COURSE',
    payload: {
      course_id: course.id
    }
  };
}

export function removeSchoolUsersAssociation(school_users: Array<Object>) {
  return {
    type: 'REMOVE_SCHOOL_USERS_ASSOCIATION',
    payload: {
      school_users
    }
  };
}

export function editSchoolUser(school_user: Object) {
  return {
    type: 'SHOW_SCHOOL_USER_EDIT_MODAL',
    payload: {
      school_user
    }
  };
}
export function showCommentsSchoolUser(school_user: Object) {
  return {
    type: 'SHOW_SCHOOL_USER_COMMENTS_MODAL',
    payload: {
      school_user
    }
  };
}

export function createSchoolUser() {
  return {
    type: 'SHOW_SCHOOL_USER_EDIT_MODAL'
  };
}

export function closeEditSchoolUserModal() {
  return {
    type: 'HIDE_SCHOOL_USER_EDIT_MODAL'
  };
}
export function closeSchoolUserCommentsModal() {
  return {
    type: 'HIDE_SCHOOL_USER_COMMENTS_MODAL'
  };
}

export function setModalSchoolUser(school_user: Object) {
  return {
    type: 'SET_MODAL_SCHOOL_USER',
    payload: {
      school_user
    }
  };
}
export function setModalSchoolUserComments(school_user: Object) {
  return {
    type: 'SET_MODAL_SCHOOL_USER_COMMENTS',
    payload: {
      school_user
    }
  };
}

export function school_usersSearch({ query, page, clean = false }: Object) {
  return {
    type: 'REQUEST_SEARCH_SCHOOL_USERS',
    payload: {
      query,
      page: page + 1,
      clean
    }
  };
}
