/* @flow */

export function getSchoolUserInvitations() {
    return {
      type: 'REQUEST_SCHOOL_USER_INVITATIONS'
    };
  }
  
  export function addSchoolUserInvitation(schoolUserInvitation: Object) {
    return {
      type: 'REQUEST_SCHOOL_USER_INVITATION_CREATE',
      payload: 
        schoolUserInvitation
      
    };
  }
  
  
  export function deleteSchoolUserInvitation(id: Object) {
    return {
      type: 'REQUEST_SCHOOL_USER_INVITATION_DELETE',
      payload: {
        id
      }
    };
  }
   
  