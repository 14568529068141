/* @flow */

export function getSchoolGeneralCoursesCaligrafixBookProgress({ level_id }: Object) {
    return {
      type: 'REQUEST_SCHOOL_GENERAL_COURSES_CALIGRAFIX_BOOK_PROGRESS',
      payload: {
        level_id
      }
    };
  }
  