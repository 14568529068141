/* @flow */

import React from 'react';
import { RadarChart } from '../components';
import { pure, compose, lifecycle } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getAllIntelligenceProgressByChild } from '../../selectors/intelligenceProgress';
import { getCourseById, getCourseProgressByIntelligence } from '../../selectors/courses';
import { getSchoolProgressByCourse } from '../../selectors/schoolProgress';
import { getSchoolGeneralCoursesProgress } from '../../actions/schoolGeneralCoursesProgress';
import { studentVsCourseProgressToRadarChart } from '../../presenters/radarChart';
import { findStudentAcrossReducers } from '../../selectors/students';

/**
 * Container that receives the data to display statistics
 * of the student vs a course in a RadarChart
 * @method DataDisplayContainer
 *
 * @param  {array}         currentStudentProgress - injected by Redux includes all intelligence_progress from the store for the current child
 * @param  {array}         currentCourseProgress - injected by Redux includes all intelligence_progress from the store for the current course
 */
export const StudentVsCourseGraphContainer = ({ animated , currentStudent, currentCourse, currentStudentProgress, currentCourseProgress }) => {
  const data = studentVsCourseProgressToRadarChart(currentCourseProgress, currentStudentProgress);

  return (
    <RadarChart animated = {animated} data={data} student={currentStudent} />
  );
};

export const ContainerLifecycle = {
  componentDidMount() {
    const { dispatch } = this.props;

    if (this.props.currentCourse && this.props.currentCourse.level_id) {
      dispatch(getSchoolGeneralCoursesProgress({ level_id: this.props.currentCourse.level_id }));
    }
  }
}

const ContainerComposed = compose(
  lifecycle(ContainerLifecycle),
  pure
)(StudentVsCourseGraphContainer);

const mapStateToProps = (state, ownProps) => {
  const { params } = ownProps.match;
  const { student_id } = params;
  const { school_general_courses_progress, courses, student_intelligence_progress, intelligences, pdf } = state;
  let currentStudent = null;
  let currentCourse = null;
  let currentStudentProgress = [];
  let currentLevelProgress = {};
  let currentCourseProgress = [];

  currentStudent = findStudentAcrossReducers(state, student_id);

  if (currentStudent) {
    currentCourse = getCourseById({ courses }, currentStudent.course_id);

    if (currentCourse) {
      currentStudentProgress = getAllIntelligenceProgressByChild({ intelligence_progress: student_intelligence_progress }, student_id);
      currentLevelProgress = getSchoolProgressByCourse({ progress: school_general_courses_progress }, currentCourse.level_id);
      currentCourseProgress = getCourseProgressByIntelligence({ courses, intelligences, progress: currentLevelProgress, course_id: currentCourse.id })
    }
  }

  return {
    animated: !pdf.printing,
    currentStudent,
    currentCourse,
    currentStudentProgress,
    currentCourseProgress
  }
};

const ContainerConnected = connect(
  mapStateToProps
)(ContainerComposed);

export const StudentVsCourseGraphStatic = ({student_id}) => {
  return <ContainerConnected match={{params:{student_id}}}/>
}

export default withRouter(ContainerConnected);
