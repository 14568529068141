/* @flow */

import React from 'react';
import { Input } from 'reactstrap';
import iconCheckmark from '../../img/icon_checkmark.svg';

/**
 * A custom representation to fit design needs of a checkbox, this element will typically
 * live within a <FormGroup> reactstrap component along with its <Label>
 * check @link{http://reactstrap.github.io/components/form/}
 *
 * @method InputCheckBox
 *
 * @example
 * <FormGroup check>
 *   <Label check>
 *     <InputCheckBox />{' '}
 *     Whatever message you want
 *   </Label>
 * </FormGroup>
 */
const InputCheckBox = (props: Object) => {
  return (
    <div className="form-custom-checkbox">
      <Input type="checkbox" {...props} />
      <div className="checkmark">
        <img className="checkmark-icon" src={iconCheckmark} alt="checkmark" />
      </div>
    </div>
  );
};

export default InputCheckBox;
