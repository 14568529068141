/* @flow */

import { takeEvery, all } from 'redux-saga/effects';
import { workerRequest } from '../diplomatico/workers';

/**
 * Worker to handle the request of Caligrafix Books
 */
export const workerRequestCaligrafixBooksOptions = {
  requestParams: {
    endpoint: '/caligrafix_books',
    method: 'get'
  }
};

export const workerRequestCaligrafixBooks = workerRequest(workerRequestCaligrafixBooksOptions);

export function* watchCaligrafixBooksRequest(): any {
  yield all([
    takeEvery('REQUEST_CALIGRAFIX_BOOKS', workerRequestCaligrafixBooks)
  ]);
}
