
const initialState = { 
};
function genericData(state: Object = initialState, { type,  payload }: Action) {

  if(type.startsWith('RESPONSE_GENERIC_DATA_')){
    const id = type.substr('RESPONSE_GENERIC_DATA_'.length);
    return Object.assign({}, state, {[id]: {payload,loading:false} } );
  }else if(type.startsWith('REQUEST_GENERIC_DATA_')){
    const id = type.substr('REQUEST_GENERIC_DATA_'.length);
    return Object.assign({}, state, {[id]: { loading:true } } );
  }else return state; 
}
export default genericData;
  