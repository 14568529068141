/* @flow */

export function getAllStudents() {
  return {
    type: 'REQUEST_ALL_STUDENTS'
  };
}

export function getStudents(course: Object) {
  return {
    type: 'REQUEST_STUDENTS',
    payload: {
      course
    }
  };
}

export function addStudent(student: Object) {
  return {
    type: 'ADD_STUDENT',
    payload: {
      student
    }
  };
}

export function updateStudent(student: Object) {
  return {
    type: 'UPDATE_STUDENT',
    payload: {
      student
    }
  };
}

export function deleteStudent(student: Object) {
  return {
    type: 'DELETE_STUDENT',
    payload: {
      student: {
        ...student,
        course_id: student.course_id || 'unassigned'
      }
    }
  };
}

export function removeStudents(students: Object) {
  return {
    type: 'REMOVE_STUDENTS',
    payload: {
      students
    }
  };
}

export function removeStudentsCourse(course: Object) {
  return {
    type: 'REMOVE_STUDENTS_COURSE',
    payload: {
      course_id: course.id
    }
  };
}

export function removeStudentsAssociation(students: Array<Object>) {
  return {
    type: 'REMOVE_STUDENTS_ASSOCIATION',
    payload: {
      students
    }
  };
}

export function editStudent(student: Object) {
  return {
    type: 'SHOW_STUDENT_EDIT_MODAL',
    payload: {
      student
    }
  };
}
export function showCommentsStudent(student: Object) {
  return {
    type: 'SHOW_STUDENT_COMMENTS_MODAL',
    payload: {
      student
    }
  };
}

export function createStudent() {
  return {
    type: 'SHOW_STUDENT_EDIT_MODAL'
  };
}

export function closeEditStudentModal() {
  return {
    type: 'HIDE_STUDENT_EDIT_MODAL'
  };
}
export function closeStudentCommentsModal() {
  return {
    type: 'HIDE_STUDENT_COMMENTS_MODAL'
  };
}

export function setModalStudent(student: Object) {
  return {
    type: 'SET_MODAL_STUDENT',
    payload: {
      student
    }
  };
}
export function setModalStudentComments(student: Object) {
  return {
    type: 'SET_MODAL_STUDENT_COMMENTS',
    payload: {
      student
    }
  };
}

export function studentsSearch({ query, page, clean = false }: Object) {
  return {
    type: 'REQUEST_SEARCH_STUDENTS',
    payload: {
      query,
      page: page + 1,
      clean
    }
  };
}
