/**
 * This file is intended to gather all the functions
 * that serve as a validator of a Field component of redux-form
 * whenever a function return 'undefined' it means that there is
 * no error and it can continue. More info here:
 * http://redux-form.com/6.8.0/examples/fieldLevelValidation/
 */

export const required = (value) => value ? undefined : 'required';

export const email = (value) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
  .test(value) ? undefined : 'valid_email';

export const minLength = (min) => (value) =>
  (value && (value.length < min)) ? `should_be_at_least_${min}` : undefined;

export const minValue = (min) => (value) =>
  (value && (value < min)) ? `min_value_is_${min}` : undefined;

export const pleiqCode = (value) => /^(([A-Z0-9]{3}-){2})([A-Z0-9]{3})$/
  .test(value) ? undefined : 'pleiq_code_format';

export const isNumber = (value) => /^\d+$/.test(value) ? undefined : 'should_be_number';

export const matchPasswords = ({ password, password_confirmation }) => {
  const errors = {};
  if (password !== password_confirmation) {
    errors['password'] = 'passwords_should_match';
    errors['password_confirmation'] = 'passwords_should_match';
  }
  return errors;
};
