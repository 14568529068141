/* @flow */

export function getCaligrafixBookHistory({ child_id, caligrafix_book_id, start_date, end_date, group_by }: Object) {
    return {
      type: 'REQUEST_CALIGRAFIX_BOOK_HISTORY',
      payload: {
        child_id,
        caligrafix_book_id,
        start_date,
        end_date,
        group_by
      }
    };
  }
  