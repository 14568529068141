/* @flow */

import React from 'react';
import classNames from 'classnames';
import { InputAddon } from './';
import Translations from '../hocs/TranslationsHelper';

type Props = {
  className?: string,
  expanded?: boolean,
  value: string,
  onChange: () => mixed,
  onExpandClick: () => mixed,
  onCloseClick: () => mixed
};

/**
 * A custom input component with an addon within the input, and also can be
 * expanded/closed on mobile and tablet
 *
 * @method SearchInput
 * @param  {string}    className         - classes to use for the input container
 * @param  {boolean}   expanded          - if true, it adds the expanded class to the input
 * @param  {string}    value             - the value to display within the input
 * @param  {Function}  onChange          - callback called each time input changes
 * @param  {Function}  onExpandClick     - callback called when the input shoudld expand
 * @param  {Function}  onCloseClick      - callback called when the input shoudld close
 * @param  {Function}  onKeyPress        - callback called when a key was pressed in the input
 */
export const SearchInput = ({ expanded, className, value, onChange, onExpandClick, onCloseClick, onKeyPress, translations }: Props) => {
  const inputClassname = classNames({ 'expanded': expanded }, className);
  return (
    <InputAddon
      className={inputClassname}
      iconClass="pleiq-icon-icon_search"
      onChange={onChange}
      value={value}
      placeholder={translations.t('search_student')}
      onExpandClick={onExpandClick}
      onCloseClick={onCloseClick}
      onKeyPress={onKeyPress}
    />
  );
};

export default Translations(SearchInput);
