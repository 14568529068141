/* @flow */

import React from 'react';
import { Tooltip } from 'reactstrap';
import Translations from '../hocs/TranslationsHelper';
import uuid from 'uuid';

type Props = {
  message: String
};

const customTetherOptions = {
  constraints: [{
    to: 'window',
    pin: true
  }]
};

/**
 * A custom tooltip component to render usage tips in the application.
 *
 * @class InfoTooltip
 * @param {string} message - Message to be shown in tooltip
 */
class InfoTooltip extends React.Component {
  constructor(props: Props) {
    super(props);
    this.state = {
      id: 'tooltip-' + uuid.v4(),
      tooltipOpen: false
    }
  }

  toggle = () => {
    this.setState({ tooltipOpen: !this.state.tooltipOpen });
  }

  render() {
    const { translations, message } = this.props;

    return (
      <span>
        <span id={this.state.id} className="pleiq-icon-icon_info icon-information" />
        <Tooltip placement="right" isOpen={this.state.tooltipOpen} target={this.state.id} toggle={this.toggle} tether={customTetherOptions}>
          {translations.t(message)}
        </Tooltip>
      </span>
    )
  }
}

export default Translations(InfoTooltip);
