/* @flow */

import { call, takeEvery, all } from 'redux-saga/effects';
import { workerRequest } from '../diplomatico/workers';
import type { Action } from '../diplomatico/types';

export const workerRequestSchoolIntelligenceProgressOptions = {
  requestParams: {
    endpoint: `/school/intelligence_scores/current`,
    method: 'get'
  }
};

export const workerRequestSchoolIntelligenceProgress = workerRequest(workerRequestSchoolIntelligenceProgressOptions);

export const workerRequestSchoolGeneralCoursesProgressOptions = (level_id: string) => ({
  requestParams: {
    endpoint: `/school/levels/${level_id}/intelligence_scores/current`,
    method: 'get',
    level_id
  }
});

export function* workerRequestSchoolGeneralCoursesProgress(action: Action): any {
  let { level_id } = action.payload;
  let workerRequestGenerator = workerRequest(workerRequestSchoolGeneralCoursesProgressOptions(level_id));

  yield call(workerRequestGenerator, action);
}

export const workerRequestSchoolGeneralIntelligenceHistoryOptions = ({ intelligence_id, group_by, start_date, end_date }) => ({
  requestParams: {
    endpoint: `/school/intelligence_scores/history?intelligence_id=${intelligence_id}&start_date=${start_date}&end_date=${end_date}&group_by=${group_by}`,
    method: 'get',
    intelligence_id,
    group_by
  }
});

export function* workerRequestSchoolGeneralIntelligenceHistory(action: Action): any {
  let workerRequestGenerator = workerRequest(workerRequestSchoolGeneralIntelligenceHistoryOptions(action.payload));

  yield call(workerRequestGenerator, action);
}

export const workerRequestSchoolGeneralAchievementCardsProgressOptions = ({ intelligence_id }) => ({
  requestParams: {
    endpoint: `/school/achievement_card_scores/current?intelligence_id=${intelligence_id}`,
    method: 'get',
    intelligence_id
  }
});

export function* workerRequestSchoolGeneralAchievementCardsProgress(action: Action): any {
  let workerRequestGenerator = workerRequest(workerRequestSchoolGeneralAchievementCardsProgressOptions(action.payload));

  yield call(workerRequestGenerator, action);
}

export function* watchSchoolIntelligenceProgressRequest(): any {
  yield all([
    takeEvery('REQUEST_SCHOOL_GENERAL_INTELLIGENCE_PROGRESS', workerRequestSchoolIntelligenceProgress),
    takeEvery('REQUEST_SCHOOL_GENERAL_COURSES_PROGRESS', workerRequestSchoolGeneralCoursesProgress),
    takeEvery('REQUEST_SCHOOL_GENERAL_INTELLIGENCE_HISTORY', workerRequestSchoolGeneralIntelligenceHistory),
    takeEvery('REQUEST_SCHOOL_GENERAL_ACHIEVEMENT_CARDS_PROGRESS', workerRequestSchoolGeneralAchievementCardsProgress)
  ]);
}
