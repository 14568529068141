/* @flow */

import React from 'react';
import { CaligrafixBookProgressAnalisisContainer } from './';
import { pure, lifecycle, compose } from 'recompose';
import { connect } from 'react-redux'
import { getUniqueAccount, editPassword, exitEditMode } from '../../actions/uniqueAccount';

type Props = {
  translations: Object
};

/**
 * Container that receives the data to display the estadistics
 * of the user to be shown within simple cards
 * @method DataDisplayContainer
 *
 * @param  {array}         school_general_caligrafix_book_progress - injected by Redux includes all caligrafix_book_progress from the store for the current child
 * @param  {array}         caligrafix_books - injected by Redux includes all caligrafix_books from the store
 */
export const DataDisplayGeneralCaligrafixSchoolContainer = ({user_books, school_general_caligrafix_book_progress, caligrafix_books,totalTime }: Props) => {
  return (
    <CaligrafixBookProgressAnalisisContainer user_books={user_books} totalTime={totalTime} currentProgress={school_general_caligrafix_book_progress} caligrafix_books={caligrafix_books} />
  );
};

export const DataDisplayGeneralCaligrafixSchoolContainerLifecycle = {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(getUniqueAccount());
  }
};

const DataDisplayGeneralCaligrafixSchoolContainerComposed = compose(
  pure,
  lifecycle(DataDisplayGeneralCaligrafixSchoolContainerLifecycle) 
)(DataDisplayGeneralCaligrafixSchoolContainer);

const mapStateToProps = ({user,  school_general_caligrafix_book_progress, caligrafix_books, unique_account }, ownProps) => {
  return {
    totalTime: unique_account.total_time , 
    school_general_caligrafix_book_progress,
    caligrafix_books,
    user_books: user.caligrafix_books
  }
};

const mapDispatchToProps = (dispatch) => ({ dispatch });

const DataDisplayGeneralCaligrafixSchoolContainerConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(DataDisplayGeneralCaligrafixSchoolContainerComposed);

export default DataDisplayGeneralCaligrafixSchoolContainerConnected;
