/* @flow */

export function getIntelligenceHistory({ child_id, intelligence_id, start_date, end_date, group_by }: Object) {
  return {
    type: 'REQUEST_INTELLIGENCE_HISTORY',
    payload: {
      child_id,
      intelligence_id,
      start_date,
      end_date,
      group_by
    }
  };
}
