/* @flow */

import React from 'react';
import { Nav, NavItem } from 'reactstrap';
import { withState, compose, withHandlers } from 'recompose';
import { Link } from 'react-router-dom';
import type { RecomposeStateUpdater } from './types';
import Translations from '../hocs/TranslationsHelper';

type Props = {
  activeIndex: number,
  onClickNavItem: Function,
  setActiveIndex: RecomposeStateUpdater,
  translations: Object,
  user: Object
};

const PremiumPromoHeader = Translations(  ({user, translations})=> {
  if(user.premium_status=="premium") 
    return <span className="premium-promo-header">{  translations.t( "premium")  } <span className="premium-promo-header-crown"/></span>
  else
    return <a href="/school/pro" className="premium-promo-header">{ translations.t( "premium_benefits")}  <span className="premium-promo-header-crown"/></a>

});
/**
 * render the navigation within the header of the application
 * check https://reactstrap.github.io/components/navs/
 * to know more about the core components used here
 *
 * @method HeaderNav
 * @param  {number}     activeIndex    - injected by `recompose` as an state to indicate the index of the active link to update its style
 * @param  {function}   onClickNavItem - injected by `recompose` as handler to move the indicator for the active link
 * @param  {function}   setActiveIndex - injected by `recompose` as an state handler to define the activeIndex
 * @param  {object}     translations   - injected by our Translations HoC to give translated strings
 * @param  {object}     user           - injected by our HeaderContainer to have reference of user
 */
export const HeaderNav = ({ activeIndex, onClickNavItem, translations, user }: Props) => {
  return (
    <Nav>
      {
        headerLinks.map((link, key) => {
          return (!link.onlySchool || user.account_type === "School")?( link.external ?
            <ExternalLink key={key} onClickNavItem={onClickNavItem} translations={translations} link={link} /> :
            <InternalLink key={key} onClickNavItem={onClickNavItem} translations={translations} link={link} />): null;
        })
      }
      {
        /*
      (user.account_type === "School" && translations.locale()==='es') ?<InternalLink onClickNavItem={onClickNavItem} translations={translations} link={{text:"video_tutorials_title",href:'/video_tutorials/general'}} /> :null 
      */
      
      }
     { (user.account_type === "School")&&
        <PremiumPromoHeader {...{user}}/>
      }
    </Nav>
  );
};
//      {(user.account_type === "School") ? <ExternalLink key="activities" onClickNavItem={onClickNavItem} translations={translations} link={{ text: 'activities', href: translations.t('activities_url')}} /> : null}
export const InternalLink = ({ onClickNavItem, translations, link }) => (
  <NavItem onClick={onClickNavItem}>
    <Link className="nav-link" to={link.href}>
      { translations.t(link.text) }
    </Link>
  </NavItem>
);

export const ExternalLink = ({ onClickNavItem, translations, link }) => (
  <NavItem>
    <a className="nav-link" href={link.href} target="_blank" rel="noopener noreferrer">
      { translations.t(link.text) }
    </a>
  </NavItem>
);

export const HeaderNavHandlers = {
  onClickNavItem: (props: Props) => ({ eventKey }: Object) => {
    props.setActiveIndex(eventKey);
  }
};

const HeaderNavEnhancements = compose(
  withState('activeIndex', 'setActiveIndex', 0),
  withHandlers(HeaderNavHandlers)
);

const headerLinks = [
  /*{
    text: 'progress',
    href: '/dashboard/all',
    external: false
  } , 
  {
    text: 'ab_planification_title',
    href: '/lesson_plans/planification',
    onlySchool: true,
    external: false
  },
  {
    text: 'ab_orientation_title',
    href: '/lesson_plans/orientation',
    onlySchool: true,
    external: false
  } */
];

const HeaderNavComposed = compose(
  HeaderNavEnhancements
)(HeaderNav);

export default Translations(HeaderNavComposed);
