/* @flow */

import React from 'react';
import { Form, FormGroup, Label } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { Button, SubmitButton, InputField, InputFieldCheckbox } from '../components/';
import { required, minLength, email } from './validations';
import { toBoolean } from './normalizers';
import Translations from '../hocs/TranslationsHelper';
import InputFieldSelect from './InputFieldSelect';
import type { ReduxFormFunction, ReduxFormFlag } from './types';
import { compose } from 'recompose';
import { ParentComponent } from '../components/ParentComponent';
import { VALID_LOCALES } from '../../locales/locale';

type Props = {
  onButtonClick: () => mixed,
  handleSubmit: ReduxFormFunction,
  submitting: ReduxFormFlag,
  dirty: ReduxFormFlag,
  valid: ReduxFormFlag,
  translations: Object
};

export const languageNames = {
  'es': 'Español',
  'en': 'English',
  'pt': 'Portugues'
}
export const languages =(translations)=> (
  VALID_LOCALES.map((lang)=>({
    value: lang, label: languageNames[lang]
  }))/*[
  { value: 'es', label: translations.t('spanish') }, 
  { value: 'en', label: translations.t('english') },
  { value: 'es', label: translations.t('spanish') }, 
]*/
);
/**
 * this component will render a form with the user personal info
 *
 * @method UserInfoForm
 * @param  {function}  onClickButton      - callback that gets fired when the password reset buttons gets clicket
 * @param  {function}  updateUserInfo     - callback that gets called on inputs onBlur to update user info
 * @param  {boolean}   submitting         - indicates if the form is currently submitting
 * @param  {Object}    translations       - object containing app translations, it gets injected by the Translations HoC
 */
const UserInfoForm = ({ onClickButton, handleSubmit, submitting, dirty, valid, translations, initialValues }: Props) => {
  const email_disabled = initialValues.account_type == "School" ? true : false;
  return (
    <Form className="form user-info-form form-limited-inputs" onSubmit={handleSubmit}>
      <Field
        type="text"
        name="first_name"
        placeholder={translations.t('your_name')}
        label={translations.t('your_name')}
        disabled={submitting}
        component={InputField}
        validate={[ required, minLength(2) ]}
      />
      <ParentField
        type="text"
        name="last_name"
        placeholder={translations.t('your_last_name')}
        label={translations.t('your_last_name')}
        disabled={submitting}
        component={InputField}
        validate={[ required, minLength(2) ]}
        account_type={initialValues.account_type}
      />
      <Field
        type="email"
        name="email"
        className="lowercased"
        placeholder={translations.t('your_email')}
        label="Email"
        component={InputField}
        validate={[ required, email]}
        disabled={email_disabled}
      />
      <FormGroup>
        <Label for="userPassword">{translations.t('password')}</Label>
        <Button type="secondary" onClickButton={onClickButton} className="form-button" >
          <span className="btn-icon-lg pleiq-icon-icon_reload" />
          {translations.t('reset_password_by_email')}
        </Button>
      </FormGroup>
      <ParentField
        type="text"
        name="pleiq_code"
        placeholder={translations.t('pleiq_code')}
        label={translations.t('pleiq_code')}
        disabled
        component={InputField}
        account_type={initialValues.account_type}
      />
      <FormGroup>
        <Label for="reports">{translations.t('language')}</Label>
        <Field
          name="preferences.locale"
          placeholder={translations.t('language')}
          selectOptions={languages(translations)}
          component={InputFieldSelect}
          disabled={submitting}
        />
      </FormGroup>
      <FormGroup>
        <Label for="reports">{translations.t('language2')}</Label>
        <Field
          name="preferences.locale2"
          placeholder={translations.t('language2')}
          selectOptions={languages(translations)}
          component={InputFieldSelect}
          disabled={submitting}
        />
      </FormGroup>
      <ReportsGroupComposed translations={translations} account_type={initialValues.account_type} submitting={submitting} />
      <SubmitButton className="btn btn-primary--green" loading={submitting} disabled={!dirty || !valid}>
        {translations.t('save')}
      </SubmitButton>
    </Form>
  );
};

const ParentField = compose(
  ParentComponent
)(Field);

const ReportsGroup = ({ translations, submitting }) => (
  <FormGroup>
    <Label for="reports">{translations.t('reports')}</Label>
    <Field
      type="checkbox"
      name="preferences.receive_weekly_reports"
      label={translations.t('reports_preferences_weekly')}
      disabled={submitting}
      component={InputFieldCheckbox}
      normalize={toBoolean}
    />
  </FormGroup>
);

const ReportsGroupComposed = compose(
  ParentComponent
)(ReportsGroup);

export const FORM_NAME = 'userInfoForm';

export default reduxForm({
  form: FORM_NAME
})(Translations(UserInfoForm));
