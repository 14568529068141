/* @flow */

import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { CoursesSection } from '../components/';
import { getLevels } from '../../actions/levels';

type Props = {
  hasLevels: boolean
};

/**
 * this component will render the list of levels, or a placeholder with
 * a button to create a level if there are not any levels
 *
 * @method CoursesSectionContainer
 * @param {Boolean} hasLevels     - indicated if the current school has any level
 */
const CoursesSectionContainer = ({ hasLevels }: Props) => {
  const dispatch = useDispatch();
  useEffect(()=>{
    dispatch(getLevels());
    
  },[])
  return <CoursesSection hasLevels={hasLevels} />;
};

const mapStateToProps = ({ levels }) => ({
  hasLevels: levels.length > 0
});

export default connect(
  mapStateToProps
)(CoursesSectionContainer);
