/* @flow */

import React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { compose, withHandlers, pure } from 'recompose';
import { StudentInfoItem } from '../components';
import type { RecomposeHandler, Student } from '../components/types';
import { editStudent, showCommentsStudent } from '../../actions/students';
import _ from 'lodash';
import { pdfContext } from '../../pdfer/Pdfable';

type Props = {
  student: Student,
  number: number,
  onEditButtonClick: RecomposeHandler,
  onStudentClick: RecomposeHandler
};

/**
 * container to render StudentInfoItem and dispatch an action that
 * shows a modal on button click
 *
 * @method StudentInfoItemContainer
 * @param  {Object}    student                  - this object represents a Student
 * @param  {function}  onEditButtonClick        - this should dispatch an action that shows a modal to edit a student
 * @param  {Boolean}   showCourseName           - this modifies the list adding the course name
 *
 */
const StudentInfoItemContainer = ({ onEditButtonClick,all,onCommentsClick, onStudentClick,onPdfButtonClick, student, showCourseName, number }: Props) => {
  const { name, child_comments_count,  course_name } = student;

  const courseLabel = `${name} - ${course_name}`;
  const label = showCourseName && !_.isEmpty(course_name) ? courseLabel : name;

  return (
    <StudentInfoItem
      useButtons ={!all}
      name={`${number}.  ${label}`}
      comments_count= {child_comments_count}
      onStudentClick={onStudentClick}
      onCommentsClick={onCommentsClick}
      onEditButtonClick={onEditButtonClick}
      onPdfButtonClick={onPdfButtonClick}
    />
  );
};

export const StudentInfoItemContainerHandlers = {
  onStudentClick: ({ dispatch, student }) => () => {
    dispatch(push(`/school/student/${student.id}/general`));
  },
  onEditButtonClick: ({ dispatch, student, ...otherProps }: Props) => () => {
    dispatch(editStudent(student));
  },
  onCommentsClick: ({ dispatch, student, ...otherProps }: Props) => () => {
    dispatch(showCommentsStudent(student));
  },
  onPdfButtonClick: ({ dispatch, student, ...otherProps }: Props) => () => {
    pdfContext.print('pleiq_report_student_'+ student.name  , "single_student", {"student_id": student.id});
  }
};

const StudentInfoItemContainerComposed = compose(
  withHandlers(StudentInfoItemContainerHandlers),
  pure
)(StudentInfoItemContainer);

const mapDispatchToProps = (dispatch) => ({ dispatch });

const StudentInfoItemContainerConnected = connect(
  ({pdf}, {all})=>({all}),
  mapDispatchToProps
)(StudentInfoItemContainerComposed);

export default StudentInfoItemContainerConnected;
