/* @flow */

import { enableInitializing } from 'initializable-reducer';

const initialState = {
  active: false
};

function confirmationModal(state: Object = initialState, { type, payload }: Object) {
  switch (type) {
    case 'SHOW_CONFIRMATION_MODAL':
      return {
        active: true,
        ...payload
      };
    case 'HIDE_CONFIRMATION_MODAL':
      return {
        active: false
      };
    default:
      return state;
  }
}

export {
  confirmationModal,
  initialState
};

export default enableInitializing(confirmationModal);
