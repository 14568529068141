/* @flow */

import { responseHasError } from '../diplomatico/reducers/helper';
import type { Action } from '../diplomatico/types';
import mergeWithReplace from '../helpers/mergeWithReplace';
import { enableInitializing } from 'initializable-reducer';

const initialState = {};

function achievementCardScores(state: Object = initialState, { type, payload }: Action) {
  switch (type) {
    case 'RESPONSE_ACHIEVEMENT_CARD_SCORES':
      if (responseHasError(payload)) {
        return state;
      }

      const { intelligence_id, child_id } = payload._meta.payload.request;

      const newAchievementCardScore = {
        [child_id]: {
          [intelligence_id]:  payload.body
        }
      };

      return mergeWithReplace({}, [state, newAchievementCardScore]);
    default:
      return state;
  }
}

export {
  achievementCardScores,
  initialState
};

export default enableInitializing(achievementCardScores);
