/* @flow */

import { call, takeEvery, all, select } from 'redux-saga/effects';
import { workerRequest } from '../diplomatico/workers';
import type { Action } from '../diplomatico/types';
import { takeLatest } from 'redux-saga';

/**
 * Worker to handle the request of Achievement Cards
 */
 export const workerRequestLessonPlansOptions = ({filter, search, page,kind ,restart}) => ({
   requestParams: {
     endpoint: `/lesson_plans?filter=${filter}&&search=${encodeURIComponent(search)}&&page=${page+1}&&kind=${kind}`,
     method: 'get',
     search,filter, page,kind,restart
   }
 });

export function* workerRequestLessonPlans(action: Action): any {
  //let currentPage = yield select()

  let workerRequestGenerator = workerRequest(workerRequestLessonPlansOptions(action.payload));

  yield call(workerRequestGenerator, action);
}

export const workerRequestLessonPlanFiltersOptions = ( ) => ({
  requestParams: {
    endpoint: `/lesson_plans/filters`,
    method: 'get' 
  }
});

export function* workerRequestLessonPlanFilters(action: Action): any {
 //let currentPage = yield select()

 let workerRequestGenerator = workerRequest(workerRequestLessonPlanFiltersOptions(action.payload));

 yield call(workerRequestGenerator, action);
}


export const workerRequestRateLessonPlanOptions = ({id,kind} ) => ({
  requestParams: {
    endpoint: `/lesson_plans/rate`,
    method: 'post' ,
    id,kind
  }, 
  buildRequestBody: ({id, score}: Object) => ({id, score})
});
export function* workerRequestRateLessonPlan(action: Action): any {
 //let currentPage = yield select()

 let workerRequestGenerator = workerRequest(workerRequestRateLessonPlanOptions(action.payload));

 yield call(workerRequestGenerator, action);
}

export function* watchLessonPlansRequest(): any {
  yield all([
    takeLatest('REQUEST_LESSON_PLANS', workerRequestLessonPlans),
    takeEvery('REQUEST_LESSON_PLAN_FILTERS', workerRequestLessonPlanFilters),
    takeEvery('REQUEST_RATE_LESSON_PLAN', workerRequestRateLessonPlan)
  ]);
}
