/* @flow */

import React from 'react';
import classNames from 'classnames';
import { Input } from 'reactstrap';

type Props = {
  className?: string,
  iconClass?: string,
  value: string,
  onChange: () => mixed,
  onExpandClick: () => mixed,
  onCloseClick: () => mixed
};

/**
 * A custom input component with an addon within the input, and also can be
 * expanded/closed on mobile and tablet
 *
 * @method InputAddon
 * @param  {string}    className         - classes to use for the input container
 * @param  {string}    iconClass         - icon to be used for the addon
 * @param  {string}    value             - the value to display within the input
 * @param  {string}    placeholder       - the placeholder to be display within the input
 * @param  {Function}  onChange          - callback called each time input changes
 * @param  {Function}  onExpandClick     - callback called when the input shoudld expand
 * @param  {Function}  onCloseClick      - callback called when the input shoudld close
 * @param  {Function}  onKeyPress        - callback called when a key was pressed in the input
 */
export const InputAddon = ({ expanded, className, iconClass, value, onChange, onExpandClick, onCloseClick, placeholder, ...otherProps }: Props) => {
  const inputClass = classNames('inner-addon addon-left', className);
  const iconClasses = classNames('inner-addon-icon', iconClass);
  return (
    <div className={inputClass}>
      <span className={iconClasses} onClick={onExpandClick} />
      <span className="inner-addon-close-icon pleiq-icon-icon_arrow_left" onClick={onCloseClick} />
      <Input className="inner-addon-input" value={value} onChange={onChange} placeholder={placeholder} onFocus={onExpandClick} {...otherProps} />
    </div>
  );
};

export default InputAddon;
