/* @flow */

export function getUniqueAccount() {
  return {
    type: 'REQUEST_UNIQUE_ACCOUNT'
  };
}

export function editPassword() {
  return {
    type: 'EDIT_UNIQUE_ACCOUNT_PASSWORD'
  };
}

export function exitEditMode() {
  return {
    type: 'EXIT_UNIQUE_ACCOUNT_PASSWORD_EDIT'
  };
}
