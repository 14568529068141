/* @flow */

import React from 'react';
import { connect } from 'react-redux';
import { SchoolDashboardHeading } from '../components';

type Props = {
  schoolName: string
};

/**
 * this component should get the current school name from
 * the store, and pass it down to SchoolDashboardHeading.
 *
 * @method SchoolDashboardHeadingContainer
 * @param  {string}  schoolName     - the currently selected tab
 *
 */
const SchoolDashboardHeadingContainer = ({ schoolName }: Props) => {
  return <SchoolDashboardHeading schoolName={schoolName} />;
};

const mapStateToProps = ({ user }) => {
  return {
    schoolName: user.name
  }
};

const SchoolDashboardHeadingContainerConnected = connect(
  mapStateToProps
)(SchoolDashboardHeadingContainer);

export default SchoolDashboardHeadingContainerConnected;
