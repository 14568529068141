export function responseSuccess(payload, responseActionType) {
  return {
    type: responseActionType,
    payload
  };
}

export function responseError(payload, responseActionType) {
  const { status } = payload;

  /*
    as there are some endpoints which errors are only illustrated
    by its status we need to ensure to pass a error in order to be
    able to display a feedback properly depending on the status
   */
  let errors = payload.errors || { api: [status] };

  return {
    type: responseActionType,
    payload: {
      errors,
      ...payload
    }
  };
}
