
import React, { useContext, useState } from 'react';
import { useLocation } from 'react-router';
import {  Paper,Modal, Button } from '../../components';
import TranslationsHelper from '../../hocs/TranslationsHelper'; 
import { useData } from '../../hocs/DataRetriever';
import { List, ListGroup, ListGroupItem } from 'reactstrap';


export const SchoolProgressReportTypeDialog = TranslationsHelper(  ({
    showModal,
    setShowModal,
    onSelect,
    translations
})=>{

  const url = "/progress_reports";
  const {data  ,status, loading} = useData({id: url, url});
  
  const[selectedType,setSeletedType] = useState(null); 
    return  <Modal
        backdropClassName="overlay-backdrop"
        contentClassName="overlay-modal-content"
        active={showModal}
        toggle={()=>setShowModal(false)}
        
      >
      <div className="confirmation-modal">
        <span className="modal-close pleiq-icon-icon_plus" onClick={()=>setShowModal(false)} />
        <Paper.Container>
          <div className="confirmation-modal-content"> 
              <h4>{translations.t("progress_report_type_select")} </h4>
             {
               data && data.body&& data.status<300 && <ul className="progress-report-template-list">
                 {
                   data.body.map(({id,title})=>(
                     <li className={((id==selectedType)?"selected":"")} onClick={()=> setSeletedType(id)} key={id} >{title}</li>
                   ))
                 }
               </ul>
             }
             <div className="text-right">

              <Button onClick = {()=>onSelect(selectedType)} disabled={selectedType==null}  type="primary" className="align-right">
                  {translations.t("create")}
              </Button>
             </div>
          </div>
        </Paper.Container>
      </div>
    </Modal>;
});

export default SchoolProgressReportTypeDialog;