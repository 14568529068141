/* @flow */

import React from 'react';
import { Col, Row } from 'reactstrap';
import { Paper } from './';
import { StudentDataFormContainer } from '../containers';
import type { Student, TranslationsObject } from './types';
import Translations from '../hocs/TranslationsHelper';

type Props = {
  student?: Student,
  closeModal: () => mixed,
  translations: TranslationsObject
};

/**
 * component to displat StudentDataForm in a modal
 *
 * @method StudentEditForm
 * @param  {Object}         course           - optional parameter, used to fill the name input if passed in
 * @param  {function}       closeModal       - callback that gets fired when the close button gets clicked
 * @param  {Object}         translations     - object containing app translations
 *
 */
const StudentEditForm = ({ student, closeModal, translations }: Props) => {
  const titleTranslation = (student && student.id) ? 'edit_student' : 'create_student';

  return (
    <div className="modal-edit-form">
      <span className="modal-close pleiq-icon-icon_plus" onClick={closeModal} />
      <Paper.Container>
        <Row>
          <Col xs="12">
            <Paper.Title>
              <h1>{translations.t(titleTranslation)}</h1>
            </Paper.Title>
          </Col>
          <Col xs="12">
            <Paper.Nav>
              <Paper.NavItem iconClass="pleiq-icon-icon_intelligence_intrapersonal" label={translations.t('student_data')} />
            </Paper.Nav>
            <StudentDataFormContainer student={student} />
          </Col>
        </Row>
      </Paper.Container>
    </div>
  );
};

export default Translations(StudentEditForm);
