/* @flow */

import { enableInitializing } from 'initializable-reducer';
import { responseHasError } from '../diplomatico/reducers/helper';
import type { Action } from '../diplomatico/types';
import _ from 'lodash';

const initialState = {
  loading: false,
  currentPage: 0,
  hasNextPage: false,
  students: []
};

function studentsSearch(state: Object = initialState, { type, payload }: Action) {
  switch (type) {
    case 'CLEAN_STUDENTS_SEARCH':
      return {
        ...initialState
      };
    case 'INIT_STUDENTS_SEARCH':
      return {
        ...initialState,
        loading: true
      };
    case 'START_STUDENTS_SEARCH':
      return {
        ...state,
        loading: true
      };
    case 'RESPONSE_SEARCH_STUDENTS':
      if (responseHasError(payload)) {
        return state;
      }

      const { page } = payload._meta.payload.request;
      const { data, is_last_page } = payload.body;

      const hasNextPage = !is_last_page && !_.isEmpty(data);

      return {
        loading: false,
        currentPage: page,
        hasNextPage,
        students: [
          ...state.students,
          ...data
        ]
      };
    case 'RESPONSE_SEARCH_STUDENTS_CLEAN':
      return {
        ...initialState,
        students: payload.body.data
      };
    case 'UPDATE_STUDENT':
      const students = state.students.map((student) => {
        if (student.id === payload.student.id) {
          return { ...student, ...payload.student };
        }
        return student;
      });

      return {
        ...state,
        students
      };
    default:
      return state;
  }
}

export {
  studentsSearch,
  initialState
};

export default enableInitializing(studentsSearch);
