/* @flow */

import React from 'react';
import { connect } from 'react-redux';
import { shouldUpdate, compose, lifecycle, withHandlers } from 'recompose';
import { Feed } from '../components/';
import actions from '../../actions/';
import { getActiveChild } from '../../selectors/children';
import { getActivityByChild } from '../../selectors/activity';
import { ActivityFeed } from '../models/';
import type { FeedGroupItemProps, FeedEntry, ReduxState, ReduxFunction } from '../components/types';
import moment from 'moment';
import _ from 'lodash';

type Props = {
  activeChild: ReduxState,
  activity: ReduxState,
  dispatch: ReduxFunction,
  language: string
};

/**
 * Container that receives the data to display the recent activity
 * of the user and injects it into a Feed component
 * @method ActivityFeedContainer
 */
export const ActivityFeedContainer = ({ activity, language }: Props) => {
  // make sure moment language match with the one within the state
  moment.locale(language);
  // cap a maximum of seven entries and parse them
  let entries = parseEntries(activity.slice(0, 7));

  return (
    <Feed.Group empty={_.isEmpty(entries)}>
      {entries.map((entry, key) => <Feed.GroupItem data={entry} key={key} />)}
    </Feed.Group>
  );
};

const parseEntries = (entries: Array<FeedEntry>): Array<FeedGroupItemProps> => {
  return entries.map((entry) => {
    // bring icon and heading to use spread operator and append to the object
    let feedType = ActivityFeed.TYPES[`${entry.kind}`.toUpperCase()];
    let subheading = entry.description;
    let time = moment(entry.created_at).fromNow();

    return { ...feedType, subheading, time, id: entry.id };
  });
};

export const ActivityFeedContainerHandlers = {
  requestActivity: (props: Props) => (prevProps: Props) => {
    const { dispatch, activeChild } = props;

    if (!_.isEmpty(activeChild) && !_.isEqual(props.activeChild, prevProps.activeChild)) {
      dispatch(actions.requestActivity(activeChild));
    }
  }
}

export const ActivityFeedContainerLifecycle = {
  componentWillMount() {
    this.props.requestActivity({});
  },
  componentDidUpdate(prevProps: Props) {
    this.props.requestActivity(prevProps);
  }
};

export const shouldComponentUpdate = (props: Props, nextProps: Props) => {
  return !_.isEqual(props, nextProps);
};

const ActivityFeedContainerComposed = compose(
  withHandlers(ActivityFeedContainerHandlers),
  shouldUpdate(shouldComponentUpdate),
  lifecycle(ActivityFeedContainerLifecycle)
)(ActivityFeedContainer);

const mapStateToProps = ({ activity, children, language }, ownProps) => {
  let activeChild = getActiveChild({ children });
  let activityData = getActivityByChild({ activity }, activeChild);

  return {
    activeChild,
    language,
    activity: activityData
  };
}

const mapDispatchToProps = (dispatch) => ({ dispatch });

const ActivityFeedContainerConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivityFeedContainerComposed);

export default ActivityFeedContainerConnected;
