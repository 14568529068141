/* @flow */

import React from 'react';
import { Form } from 'reactstrap';
import { SubmitButton, UserChildFormArray } from '../components/';
import { FieldArray, reduxForm } from 'redux-form';
import Translations from '../hocs/TranslationsHelper';

const UserChildrenForm = ({ onRemoveChild, submitting, valid, dirty,  handleSubmit, translations,maxChildren }) => {
  return (
    <Form className="form user-children-form" onSubmit={handleSubmit}>
      <FieldArray name="children" component={UserChildFormArray} onRemoveChild={onRemoveChild} maxChildren={maxChildren} />
      <SubmitButton loading={submitting} className="btn btn-primary--green" disabled={!dirty || !valid}>
        {translations.t('save')}
      </SubmitButton>
    </Form>
  );
};

export const FORM_NAME = 'userChildrenForm';

export default reduxForm({
  form: FORM_NAME
})(Translations(UserChildrenForm));
