/* @flow */

import React from 'react';
import { BarChart, XAxis, YAxis, CartesianGrid, Tooltip, Bar, ResponsiveContainer } from 'recharts';
import { shouldUpdate, compose } from 'recompose';
import classNames from 'classnames';
import deepEqualsUpdate from '../../helpers/deepEqualsUpdate';
import _ from 'lodash';

const xAxisStyles = {
  fill: '#989898',
  fontSize: '0.75rem',
  fontWeight: 'normal'
};

/**
 * A component that renders data into a BarChart component from Recharts
 *
 * This component is out of coverage because of: https://github.com/recharts/recharts/issues/765
 *
 * @method BarChartWrapper
 *
 */
const BarChartWrapper = ({ color, className, animated, ...otherProps }) => {
  const isEmpty = _.isEmpty(otherProps.data);

  const containerClass = classNames(className, 'barchart-container', { 'barchart-container--loading': isEmpty });

  return (
    <ResponsiveContainer>
      <BarChart className={containerClass} {...otherProps}
        margin={{top: 10, right: 10, left: -30, bottom: 0}}>
        <XAxis dataKey="name" axisLine={false} tick={xAxisStyles} tickLine={false} padding={{ left: 20, right: 20 }} />
        <YAxis tick={{ fontSize: '0.625rem' }} tickLine={false} axisLine={false} interval={0} tickCount={6} domain={[0, 100]} />
        <CartesianGrid vertical={false} />
        <Tooltip separator="" formatter={formatter} cursor={{fill: 'black', fillOpacity: 0.1}} />
        <Bar dataKey="value" fill={color} isAnimationActive={animated} />
      </BarChart>
    </ResponsiveContainer>
  );
};

const formatter = (value) => {
  const roundedValue = Math.round(value * 100) / 100;

  return `${roundedValue}%`;
};

const BarChartWrapperComposed = compose(
  shouldUpdate(deepEqualsUpdate)
)(BarChartWrapper);

export default BarChartWrapperComposed;
