/* @flow */
import React from 'react';
import { Modal } from 'reactstrap';

type Props = {
  active: boolean,
  toggle: Function,
  children?: React.Component<*>,
  backdropClassName?: string,
  contentClassName?: string
};

/**
 * A wrapper for the Modal component in Reactstrap that allows us to
 * use its backdrop overlay. The idea is to reuse the overlay styles
 * and remove the modal content styles in this case.
 *
 * @method ModalWrapper
 * @param  {React.Components}     children           - a tree of elements within the component
 * @param  {boolean}              active             - sets the state of the modal to be opened or closed
 * @param  {function}             toggle             - a function to be called when the backdrop gets clicked
 * @param  {string}               backdropClassName  - class to apply to the backdrop of the modal
 * @param  {string}               contentClassName   - class to apply to the content of the modal
 *
 */
const ModalWrapper = ({ children, active, ...otherProps }: Props) => {
  return (
    <Modal
      isOpen={active}
      {...otherProps}>
      {children}
    </Modal>
  );
};

export default ModalWrapper;
