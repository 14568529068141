/* @flow */

import React from 'react';
import { connect } from 'react-redux';
import { pure, compose, withHandlers } from 'recompose';
import { setActiveChild } from '../../actions/children';
import { ProfileList } from '../components';
import { ParentComponent } from '../components/ParentComponent';
import { DropdownItem } from 'reactstrap';
import type {
  Child, ReduxState, ReduxFunction, RecomposeHandler
} from '../components/types';

type Props = {
  children: ReduxState,
  className?: string,
  dispatch: ReduxFunction,
  onClickChildProfile: RecomposeHandler
};

const ProfileListContainer = (props: Props) => {
  return (
    <div>
      <ProfileList {...props} />
      <DropdownItem divider />
    </div>
  );
};

export const ProfileListContainerHandlers = {
  onClickChildProfile: ({ dispatch }: Props) => (child: Child) => {
    dispatch(setActiveChild(child));
  }
};

const ProfileListContainerComposed = compose(
  withHandlers(ProfileListContainerHandlers),
  ParentComponent,
  pure
)(ProfileListContainer);

const mapDispatchToProps = (dispatch) => ({ dispatch });

const mapStateToProps = ({ children, user }) => {
  const { account_type } = user;

  return {
    children,
    account_type
  };
}

const ProfileListContainerConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileListContainerComposed);

export default ProfileListContainerConnected;
