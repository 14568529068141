/* @flow */

import React from 'react';
import { Navbar, Collapse, NavbarToggler, DropdownItem, Nav, NavLink, NavItem } from 'reactstrap';
import { connect } from 'react-redux';
import { AvatarContainer, ProfileListContainer } from '../containers';
import { logout } from '../../actions/user';
import BrandLogo from './BrandLogo';
import HeaderNav from './HeaderNav';
import classNames from 'classnames';
import { withState, pure, compose } from 'recompose';
import Translations from '../hocs/TranslationsHelper';
import type { RecomposeStateName, RecomposeStateUpdater } from './types';
import { Link } from 'react-router-dom';
//import { PdfData } from '../../pdfer/Pdfable';

type Props = {
  isMenuOpen: RecomposeStateName,
  toggle: RecomposeStateUpdater
};

/**
 * functional component that only has the resposability of holding the composition of its children
 * to create the application header.
 *
 * @method Header
 * @param  {Boolean}      isMenuOpen - injected by `recompose` as an state to control wether or not the nav menu is visible (mobile)
 * @param  {function}     toggle     - injected by `recompose` as a stateUpdater to toggle the isMenuOpen state
 * @param  {function}     user       - injected by our HeaderContainer to have reference of user
 *
 */
export const Header = ({ isMenuOpen, toggle, user }: Props) => {
  return (
    <div className="navbar-wrapper navbar-session">
      <Navbar className="navbar-toggleable-md" container={false}>
        <div className="navbar-mobile-heading">
          <Link to="/">
            <BrandLogo /> 
          </Link>
          <HeaderToggler isMenuOpen={isMenuOpen} toggle={toggle} />
        </div>
        <Collapse isOpen={isMenuOpen} navbar>
          <DropdownItem divider />
          <HeaderNav user={user}/>
          <DropdownItem divider />
          <AvatarContainer />
          <ProfileListContainer className="hidden-sm-up" />
          <CollapseExtraOptions />
        </Collapse>
      </Navbar>
    </div>
  );
};

export const HeaderToggler = ({ isMenuOpen, toggle }: Props) => {
  let navTogglerClass = classNames({
    expanded: isMenuOpen
  });

  return (
    <NavbarToggler right onClick={() => toggle(!isMenuOpen)} className={navTogglerClass}>
      <div className="navbar-trigger">
        <div className="navbar-trigger-stripe"></div>
        <div className="navbar-trigger-stripe"></div>
      </div>
    </NavbarToggler>
  );
};

let CollapseExtraOptions = ({ onClickNavItem, translations, dispatch }) => {
  return (
    <Nav className="collapse-extra-options hidden-sm-up">
      <NavLink href="/settings">
        <span className="pleiq-icon pleiq-icon-icon_settings" />
        {translations.t('settings')}
      </NavLink>
      <NavItem className="nav-link" onClick={() => dispatch(logout())}>
        <span className="pleiq-icon pleiq-icon-icon_logout" />
        {translations.t('logout')}
      </NavItem>
    </Nav>
  );
};

const mapDispatchToProps = (dispatch) => ({ dispatch });

CollapseExtraOptions = connect(null, mapDispatchToProps)(Translations(CollapseExtraOptions));

const headerEnhancements = compose(
  withState('isMenuOpen', 'toggle', false),
);

const HeaderComposed = compose(
  headerEnhancements,
  pure
)(Header);

export default HeaderComposed;
