/* @flow */

import React from 'react';
import { Form, FormGroup, FormFeedback,Label } from 'reactstrap'; 
import { Field, reduxForm } from 'redux-form';
import { required, minLength, email } from './validations';
import Translations from '../hocs/TranslationsHelper';
import type { ReduxFormFunction, ReduxFormErrorGlobal, ReduxFormFlag, TranslationsObject } from '../components/types';
import classNames from 'classnames';
import RegistrationSubmitButton from './RegistrationSubmitButton';
import { toBoolean } from './normalizers';

import { Button, SubmitButton, InputField, InputFieldCheckbox } from '../components/';   
import InputFieldSelect from './InputFieldSelect';
import { languages } from './UserInfoForm';
  
const lower = value => value && value.toLowerCase()
const SchoolUserInvitationForm = ({error,  handleSubmit,  persisted, submitting, valid, dirty, translations } ) => {
 

  const formClassName = classNames({ 'has-danger': error }, 'form', "student-data-form");

  return (
    <Form className={formClassName} onSubmit={handleSubmit}>
      
      <div >
        { error &&
            <FormGroup>
              <FormFeedback>{translations.t(error)}</FormFeedback>
            </FormGroup>}

        <div >

              <FormGroup>
              <Label for="reports">{translations.t('language')}</Label>
              <Field
                name="locale"
                placeholder={translations.t('language')}
                selectOptions={languages(translations)}
                component={InputFieldSelect}
                disabled={submitting}
              />
            </FormGroup>
            <Field
              className="text-center"
              type="text"
              name="user_name"
              placeholder={translations.t('user_name')} 
              component={InputField}
              validate={[ required ]}
            />

            <Field
              className="text-center lowercased"
              type="text"
              name="email"
              placeholder={translations.t('email_placeholder')} 
              component={InputField}
              validate={[ required, email ]}
              normalize={lower}
            />
            <Field
              name="role"
              placeholder={translations.t('role')}
              selectOptions={[
                { value: 'representant', label: translations.t('role_representant') }, 
                { value: 'coordinator', label: translations.t('role_coordinator') },
                { value: 'teacher', label: translations.t('role_teacher') }, 
              ] }
              component={InputFieldSelect}
              disabled={submitting}
            />
            <SubmitButton loading={submitting} className="btn-primary--green " disabled={!dirty || !valid}>
               Crear
            </SubmitButton> 
        </div>
        {persisted}
      </div>
    </Form>
  );
};

export const FORM_NAME = 'schoolUserInvitationForm';

 

export default reduxForm({
  form: FORM_NAME,
  enableReinitialize: true
})(Translations(SchoolUserInvitationForm));
