/* @flow */

import achievement_card_scores from './achievementCardScores';
import achievement_cards from './achievementCards';
import lesson_plans from './lessonPlans';
import lesson_plan_filters from './lessonPlanFilters';
import activity from './activity';
import alerts from './alerts';
import authorization from '../diplomatico/reducers/authorization';
import children from './children';
import confirmationModal from './confirmationModal';
import courseEditModal from './courseEditModal';
import courses_progress_over_time from './coursesProgressOverTime';
import courses_search from './coursesSearch';
import courses from './courses';
import html_content_modal from './htmlContentModal';
import intelligence_history from './intelligenceHistory';
import intelligence_progress from './intelligenceProgress';
import intelligences from './intelligences';
import language from './language';
import levelEditModal from './levelEditModal';
import levels from './levels';
import school_levels_collapse_structure from './schoolLevelsCollapseStructure';
import school_general_achievement_cards_progress from './schoolGeneralAchievementCardsProgress';
import school_general_courses_progress from './schoolGeneralCoursesProgress';
import school_general_intelligence_history from './schoolGeneralIntelligenceHistory';
import school_general_intelligence_progress from './schoolGeneralIntelligenceProgress';
import student_achievement_cards_progress from './studentAchievementCardsProgress';
import student_intelligence_history from './studentIntelligenceHistory';
import student_intelligence_progress from './studentIntelligenceProgress';
import studentEditModal from './studentEditModal';
import students from './students';
import students_search from './studentsSearch';
import unique_account from './uniqueAccount';
import user from './user';
import pdf from './pdf'
import activeRequests from './activeRequests'

import caligrafix_book_history from './caligrafixBookHistory';
import caligrafix_book_progress from './caligrafixBookProgress';
import caligrafix_books from './caligrafixBooks';
import school_general_courses_caligrafix_book_progress from './schoolGeneralCoursesCaligrafixBookProgress';
import school_general_caligrafix_book_history from './schoolGeneralCaligrafixBookHistory';
import school_general_caligrafix_book_progress from './schoolGeneralCaligrafixBookProgress';
import student_caligrafix_book_history from './studentCaligrafixBookHistory';
import student_caligrafix_book_progress from './studentCaligrafixBookProgress';

import studentCommentsModal from './studentCommentsModal';
import student_comments from './studentComments';
import loading_status from './loadingStatus';
import school_user_invitations from './schoolUserInvitations';

import request_errors from './requestErrors'
import generic_data from './genericData'

const reducers = {
  achievement_card_scores,
  achievement_cards,
  activity,
  alerts,
  authorization,
  children,
  confirmationModal,
  courseEditModal,
  courses_progress_over_time,
  courses,
  courses_search,
  html_content_modal,
  intelligence_history,
  intelligence_progress,
  intelligences,
  language,
  levelEditModal,
  levels,
  school_levels_collapse_structure,
  school_general_achievement_cards_progress,
  school_general_courses_progress,
  school_general_intelligence_history,
  school_general_intelligence_progress,
  student_achievement_cards_progress,
  student_intelligence_history,
  student_intelligence_progress,
  studentEditModal,
  students_search,
  students,
  unique_account,
  user,
  pdf,
  activeRequests,
  school_user_invitations,

  caligrafix_book_history,
  caligrafix_book_progress,
  caligrafix_books,
  school_general_courses_caligrafix_book_progress,
  school_general_caligrafix_book_history,
  school_general_caligrafix_book_progress,
  student_caligrafix_book_history,
  student_caligrafix_book_progress,

  studentCommentsModal,
  student_comments,
  loading_status,
  request_errors,

  lesson_plans,
  lesson_plan_filters,
  generic_data,
};

export default reducers;
