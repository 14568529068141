/* @flow */

import React from 'react';
import { StoreCard } from '../components/';
import { Experiences } from '../models/';

type Props = {
  experiences: Array<$Keys<typeof Experiences>>
};

const StoreCardContainer = ({ experiences }: Props) => {
  return (
    <div className="container-store-card">
      {
        experiences.map((experience, key) => {
          const data = Experiences[experience] || {};
          const { image, title, cost, props } = data;

          return Experiences[experience]
            ? <StoreCard key={key} image={image} title={title} cost={cost} {...props} />
            : null;
        })
      }
    </div>
  );
};

export default StoreCardContainer;
