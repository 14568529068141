/* @flow */

import { enableInitializing } from 'initializable-reducer';
import { responseHasError } from '../diplomatico/reducers/helper';
import type { Action } from '../diplomatico/types';
import sortByName from '../helpers/sortByName';

const initialState = [];

function levels(state: Array<Object> = initialState, { type, payload }: Action) {
  switch (type) {
    case 'RESPONSE_LEVELS':
      if (responseHasError(payload)) {
        return state;
      }

      return sortByName(payload.body);
    case 'UPDATE_LEVEL_COURSES_COUNT':
      return state.map((level) => {
        const levelCourses = payload.courses.filter(course => course.level_id === level.id);
        return {
          ...level,
          courses_count: level.courses_count - levelCourses.length
        };
      });
    case 'ADD_LEVEL':
      return sortByName([ ...state, payload.level ]);
    case 'UPDATE_LEVEL':
      const newState = state.map((level) => {
        if (level.id === payload.level.id) {
          return { ...level, ...payload.level };
        }
        return level;
      });

      return sortByName(newState);
    case 'DELETE_LEVEL':
      return state.filter(level => level.id !== payload.level.id);
    default:
      return state;
  }
}

export {
  levels,
  initialState
};

export default enableInitializing(levels);
