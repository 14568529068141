/* @flow */

import React from 'react';
import { Form, FormFeedback } from 'reactstrap';
import { reduxForm } from 'redux-form';
import ChildrenController from './ChildrenController';
import RegistrationSubmitButton from './RegistrationSubmitButton';
import ChildForm from './ChildForm';
import RegistrationForm from './RegistrationForm';
import Translations from '../hocs/TranslationsHelper';
import classNames from 'classnames';
import type { ReduxFormFunction, ReduxFormErrorGlobal, ReduxFormFlag, TranslationsObject } from '../components/types';

const renderForms = (children, onUpdateChild, activeIndex, submitting) => children.map((child, index) => {
  return activeIndex === index
    ? (
      <ChildForm key={child.reference}
        child={child}
        onUpdateChild={onUpdateChild}
        activeIndex={activeIndex}
        submitting={submitting}
      />
    )
    : '';
});

type Props = {
  onCreateChild: () => mixed,
  onDeleteChild: () => mixed,
  onUpdateChild: () => mixed,
  onSelectChild: () => mixed,
  handleSubmit: ReduxFormFunction,
  translations: TranslationsObject,
  error: ReduxFormErrorGlobal,
  submitting: ReduxFormFlag,
  valid: ReduxFormFlag,
  onBackButtonClick: () => mixed
};

/**
 * Component intended to wrap the elements that allows
 * the parent to CRUD children
 * @method ChildrenForm
 * @param  {Function}     onCreateChild - a callback so the parent can add a child to its children set
 * @param  {Function}     onDeleteChild - a callback so the parent can delete a child whitin its children set
 * @param  {Function}     onUpdateChild - a callback so the parent can update a child whitin its children set
 * @param  {Function}     onSelectChild - a callback to update the child that the user is currently viewing
 * @param  {Function}     onSubmit      - a callback to define what to do in order to submit the data to the server
 */
const ChildrenForm = ({ onCreateChild, onDeleteChild, onUpdateChild, onSelectChild, handleSubmit,maxChildren, ...otherProps }: Props) => {
  const { children, activeIndex, error, submitting, valid, translations } = otherProps;
  const formClassName = classNames({ 'has-danger': error }, 'form');

  return (
    <RegistrationForm title={translations.t('children_addition_form_title')} subtitle={translations.t('children_addition_form_subtitle')}>
      <Form onSubmit={handleSubmit} className={formClassName} id="children-addition-form">
        <ChildrenController
          maxChildren={maxChildren}
          children={children}
          activeIndex={activeIndex}
          onCreateChild={onCreateChild}
          onSelectChild={onSelectChild}
          onDeleteChild={onDeleteChild}
        />
        {renderForms(children, onUpdateChild, activeIndex, submitting)}
        {error && <FormFeedback>{translations.t(error)}</FormFeedback>}
        <div className="registration-form-button-wrapper">
          <RegistrationSubmitButton loading={submitting} disabled={!valid}>
            {translations.t('continue')}
          </RegistrationSubmitButton>
          <span className="registration-form-next-icon pleiq-icon-icon_arrow_right" />
        </div>
      </Form>
    </RegistrationForm>
  );
};

export const FORM_NAME = 'childrenForm';

export default reduxForm({
  form: FORM_NAME
})(Translations(ChildrenForm));
