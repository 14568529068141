/* @flow */

import React from 'react';
import { connect } from 'react-redux';
import { pure, compose, withHandlers, defaultProps, lifecycle } from 'recompose';
import { ParentSignUpForm } from '../components';
import { startSubmit, stopSubmit } from 'redux-form';
import { goBack } from 'react-router-redux';
import { FORM_NAME } from '../components/ParentSignUpForm';
import actions from '../../actions';
// import Translations from '../hocs/TranslationsHelper';
import type { ReduxFunction, ReduxFormFunction, RecomposeHandler, TranslationsObject } from '../components/types';
import _ from 'lodash';
import { VALID_LOCALES } from '../../locales/locale';

type Props = {
  translations: TranslationsObject,
  dispatch: ReduxFunction,
  onSubmit: RecomposeHandler,
  handleSubmit: ReduxFormFunction,
  onBackButtonClick?: RecomposeHandler,
  queryParams?: Object
};

/**
 * Container that will handle the logic to allow a guest to
 * create a user through the SignUpForm(s), instead using branches
 * from recompose we will manually check the right component
 * to render in order to have better control over the injected props
 *
 * @method ParentSignUpFormContainer
 *
 */
const ParentSignUpFormContainer = ({ onSubmit, onBackButtonClick }: Props) => {
  return <ParentSignUpForm onSubmit={onSubmit} onBackButtonClick={onBackButtonClick} />;
};

export const ParentSignUpFormContainerHandlers = {
  onSubmit: (props: Props) => ({ firstName, lastName, email, emailRetype, password,passwordRetype, preferences }: Object) => {
    const { dispatch } = props;
    // const translations = props.translations;
    const queryParams = props.queryParams || {};
    let locale = 'es';

    if (_.includes(VALID_LOCALES, queryParams.locale)) { //TODO: include PT
      locale = queryParams.locale;
    }

    /*
    if(email !== emailRetype) {
      alert(locale == "es" ? "Ambos correos deben ser iguales" : "Both emails must match");
      return;
    }
    */

    const defaultPreferences = {
      receive_marketing_emails: false,
      receive_weekly_reports: true,
      locale: locale
    };

    const payload = {
      user: {
        email: email,
        first_name: firstName,
        last_name: lastName,
        password_confirmation: passwordRetype,
        password: password,
        preferences: _.defaults(preferences, defaultPreferences)
      }
    };

    dispatch(startSubmit(FORM_NAME));
    var errors = {}
    if(email !== emailRetype) {
      Object.assign(errors, {"emailRetype":["email_doesnt_match"]  }); 
    }
    if(password !== passwordRetype) {
      Object.assign(errors, {"passwordRetype":["passwords_should_match"] }); 
    }

    if(email !== emailRetype || password !== passwordRetype) {
   // alert(locale == "es" ? "Ambos correos deben ser iguales" : "Both emails must match");
      dispatch(stopSubmit(FORM_NAME,errors));
      //return;
    }else{ 
      dispatch({ type: 'REQUEST_USER_SIGNUP', payload }); 
    }
  },
  onBackButtonClick: ({ dispatch }: Props) => () => {
    dispatch(goBack());
  }
};

const ParentSignUpFormContainerEnhancements = compose(
  withHandlers(ParentSignUpFormContainerHandlers),
  defaultProps({
    queryParams: {}
  })
);

const mapDispatchToProps = (dispatch) => {
  return { dispatch };
};

const ParentSignUpFormContainerLifecycle = {
  componentWillMount() {
    const queryParams = this.props.queryParams;

    if (queryParams.locale && _.includes(VALID_LOCALES, queryParams.locale)) {
      this.props.dispatch(actions.changeLanguage({ language: queryParams.locale }));
    }
  }
};

const ParentSignUpFormContainerComposed = compose(
  ParentSignUpFormContainerEnhancements,
  lifecycle(ParentSignUpFormContainerLifecycle),
  pure
)(ParentSignUpFormContainer);

const ParentSignUpFormContainerConnected = connect(
  null,
  mapDispatchToProps
)(ParentSignUpFormContainerComposed);

export { ParentSignUpFormContainerConnected as ParentSignUpFormContainer };
export default ParentSignUpFormContainerConnected;
