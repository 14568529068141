/* @flow */

export const dataNeedsUpdate = (prevProps, props) => {
  const lastParam = prevProps.match.params.topic;
  const nextParam = props.match.params.topic;
  const lastChild = prevProps.child_id;
  const currentChild = props.child_id;

  return lastParam !== nextParam || lastChild !== currentChild;
};
