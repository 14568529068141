/* @flow */

import { call, put, take, takeEvery, all } from 'redux-saga/effects';
import { stopSubmit } from 'redux-form';
import { FORM_NAME as LEVEL_DATA_FORM } from '../js/components/LevelDataForm';
import { FORM_NAME as SCHOOL_COURSES_FORM } from '../js/components/SchoolCoursesForm';
import { workerRequest } from '../diplomatico/workers';
import type { Action } from '../diplomatico/types';
import { updateLevel, deleteLevel, closeEditLevelModal, addLevel, setModalLevel } from '../actions/levels';
import { getCourses, removeCoursesLevel, removeCourses } from '../actions/courses';

/**
 * Worker to get the levels for current school
 */
export const workerRequestLevelsOptions = {
  requestParams: {
    endpoint: `/school/levels`,
    method: 'get'
  }
};

export const workerRequestLevels = workerRequest(workerRequestLevelsOptions);

/**
 * Worker to create a level for current school
 */
export const workerRequestLevelCreateOptions = {
  requestHandlers: {
    onSuccess: function* ({ action, response }: Object): any {
      const level = response.payload.body;
      yield put(addLevel(level));
      yield put(setModalLevel(level));
      yield put(stopSubmit(LEVEL_DATA_FORM));
    },
    onError: function* ({ action, response }: Object): any {
      yield put(stopSubmit(LEVEL_DATA_FORM));
    }
  },
  requestParams: {
    endpoint: '/school/levels',
    method: 'post'
  },
  buildRequestBody: (actionPayload: Object) => actionPayload 
};

export const workerRequestLevelCreate = workerRequest(workerRequestLevelCreateOptions);

/**
 * Worker to update an specific level for current school
 */
export const workerRequestLevelUpdateOptions = (levelId: string) => ({
  requestHandlers: {
    onSuccess: function* ({ action, response }: Object): any {
      const level = response.payload.body;
      yield put(updateLevel(level));
      yield put(setModalLevel(level));
      yield put(stopSubmit(LEVEL_DATA_FORM));
    },
    onError: function* ({ action, response }: Object): any {
      yield put(stopSubmit(LEVEL_DATA_FORM));
    }
  },
  requestParams: {
    endpoint: `/school/levels/${levelId}`,
    method: 'put'
  },
  buildRequestBody: (actionPayload: Object) => actionPayload 
});

export function* workerRequestLevelUpdate({ type, payload }: Action): any {
  const { level } = payload;
  const workerRequestGenerator = workerRequest(workerRequestLevelUpdateOptions(level.id));
  yield call(workerRequestGenerator, { type, payload });
}

/**
 * Worker to delete an specific level for current school
 */
export const workerRequestLevelDeleteOptions = (levelId: string) => ({
  requestHandlers: {
    onSuccess: function* ({ action, response }: Object): any {
      const { level } = action.payload;
      yield put(deleteLevel(level));
      yield put(removeCoursesLevel(level));
      yield put(stopSubmit(LEVEL_DATA_FORM));
      yield put(closeEditLevelModal());
    },
    onError: function* ({ action, response }: Object): any {
      yield put(stopSubmit(LEVEL_DATA_FORM));
      yield put(closeEditLevelModal());
    }
  },
  requestParams: {
    endpoint: `/school/levels/${levelId}`,
    method: 'delete'
  },
  // no payload needed for this request
  buildRequestBody: (actionPayload: Object) => ({})
});

/**
 * Triggers the removal of an specific level
 */
export function* workerRequestLevelDelete({ type, payload }: Action): any {
  const { level } = payload;
  const workerRequestGenerator = workerRequest(workerRequestLevelDeleteOptions(level.id));
  yield call(workerRequestGenerator, { type, payload });
}

export const workerRequestUpdateLevelCoursesOptions = (levelId: string) => ({
  requestHandlers: {
    onSuccess: function* ({ action, response }: Object): any {
      const { removedCourses, newCoursesCount, otherLevelCourses } = action.payload;
      const level = response.payload.body;
      yield put(removeCourses(removedCourses));

      if (newCoursesCount > 0) {
        yield put(getCourses(level));
        yield take('RESPONSE_COURSES');
      }

      yield put({ type: 'UPDATE_LEVEL_COURSES_COUNT', payload: { courses: otherLevelCourses } });
      yield put(updateLevel(level));
      yield put(getCourses(null));
      yield put(stopSubmit(SCHOOL_COURSES_FORM));
    },
    onError: function* ({ action, response }: Object): any {
      yield put(stopSubmit(SCHOOL_COURSES_FORM));
    }
  },
  requestParams: {
    endpoint: `/school/levels/${levelId}`,
    method: 'put'
  },
  buildRequestBody: (actionPayload: Object) => actionPayload
});

/**
 * Triggers the update of a level courses
 */
export function* workerRequestUpdateLevelCourses({ type, payload }: Action): any {
  const { level_id } = payload;
  const workerRequestGenerator = workerRequest(workerRequestUpdateLevelCoursesOptions(level_id));
  yield call(workerRequestGenerator, { type, payload });
}

export function* watchLevelsRequest(): any {
  yield all([
    takeEvery('REQUEST_LEVELS', workerRequestLevels),
    takeEvery('REQUEST_LEVEL_CREATE', workerRequestLevelCreate),
    takeEvery('REQUEST_LEVEL_UPDATE', workerRequestLevelUpdate),
    takeEvery('REQUEST_LEVEL_DELETE', workerRequestLevelDelete),
    takeEvery('REQUEST_UPDATE_LEVEL_COURSES', workerRequestUpdateLevelCourses)
  ]);
}
