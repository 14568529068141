import { useEffect, useState } from "react"
import ActionCable from 'action-cable-react-jwt'
import { useSelector } from "react-redux";
const _WS_PATH = process.env.REACT_APP_API_URL.replace("http", "ws");

export const useCable = (channel, onMsg)=>{

    const authorization = useSelector(({authorization})=> authorization);
    const [chan, setChan] = useState(null);
    useEffect(()=>{

        const consumer = ActionCable.createConsumer(_WS_PATH+"/cable", authorization.token);
        let onReceive = null;
        const subs = consumer.subscriptions.create({channel:channel}, {
            connected: () => {
                console.log("connected to channel");

            },
            disconnected: () => {
                console.log("disconnected to channel");},
            received: (data) => { 
              console.log("received data from channel: ",data) 
              if(onReceive){
                onReceive( (data));
              }
              if(onMsg){
                onMsg( data);
              }
            }
        });
        console.log("SUBS: ", subs, consumer);
        setChan({
            send:   (request)=>{  
                    return new Promise((resolve ) => {
                        subs.send( request );
                         
                    }) 
            },
            
            wait:   ( )=>{  
                return new Promise((resolve, reject ) => { 
                    onReceive = (data)=>{
                        onReceive= null;
                        if(data.type=='error'){
                            reject(new Error(data.msg));
                        }else{
                            resolve(data);
                            
                        }
                    } 
                }) 
            }
        })
        return ()=>{
            
            subs.unsubscribe();
            consumer.disconnect();
        }
    }, [channel]); 
    return chan;

}