import Moment from 'moment';
import { extendMoment } from 'moment-range';

const moment = extendMoment(Moment);

export const getFormatedDate = (date, option, translations) => {
  const momentDate = moment(date);
  const weekTranslation = translations.t('week_date');

  switch(option) {
    case 'month':
      return momentDate.format('MMM').toUpperCase();
    case 'week':
      return momentDate.format(`[${weekTranslation}] ww`);
    case 'day':
      return momentDate.format('ddd').toUpperCase();
    default:
      break;
  }
};

const getChartDomain = (dates, option, translations) => {
  return dates.map((date) => {
    return {
      name: getFormatedDate(date, option, translations),
      value: 0
    };
  });
};

/**
 * This function creates a default domain for line charts across the app.
 *
 * It renders 0 by default for all data points.
 */
export const getDefaultChartDomain = (option, translations) => {
  const interval = option === 'month' ? 6 : option === 'week' ? 4 : 7;
  const range = moment.range(moment().subtract(interval - 1, option), moment());

  const dates = Array.from(range.by(option));

  return getChartDomain(dates, option, translations);
};
