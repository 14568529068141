/* @flow */

import { call, put, take, takeEvery, all } from 'redux-saga/effects';
import { stopSubmit } from 'redux-form'; 
import { FORM_NAME as SCHOOL_USER_INVITATION_FORM } from '../js/components/SchoolUserInvitationForm';
import { workerRequest } from '../diplomatico/workers';
import type { Action } from '../diplomatico/types'; 
import { getCourses, removeCoursesSchoolUserInvitation, removeCourses } from '../actions/courses';
import { getSchoolUserInvitations } from '../actions/schoolUserInvitations';
import { genericDataResponse } from '../actions/genericData';

/**
 * Worker to get the school_user_invitations for current school
 */
export const workerRequestGenericDataOptions = (url , method, callback) => { 
   

  return{ 
    requestParams: {
      endpoint: url,
      method  
    },
    requestHandlers: {
      onSuccess: function* ({ action, response }: Object): any { 
       if(callback)callback(response.payload );
       // yield put(genericDataResponse(action.id, response.payload));
         
      },
      onError: function* ({ action, response }: Object): any {
        //console.log("RESPGD ERROR: ",  response.payload, id); 
        if(callback)callback(response.payload);
        //yield put(genericDataResponse(action.id, response.payload));
      }
    },
    // no payload needed for this request
    buildRequestBody: (actionPayload: Object) => (actionPayload.body)
  }};

  export function* workerRequestGenericData({ type, payload , cb}: Action): any { 
  
    const { url, method } = payload;
    const workerRequestGenerator = workerRequest(workerRequestGenericDataOptions( url,method,  cb));
    yield call(workerRequestGenerator, { type, payload  });
  }
 

export function* watchGenericDataRequests (): any {
  yield all([
    takeEvery((a)=>a.type.startsWith('REQUEST_GENERIC_DATA_'), workerRequestGenericData)
  ]); 
}
       