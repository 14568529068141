import _ from 'lodash';


/*
 * This method expected the data in the format of the following selectors
 * 1. courseProgress from getCourseProgressByIntelligence
 * 2. studentProgress from getAllIntelligenceProgressByChild
 *
 * Return array structure looks like this:
 * [{ subject: 'LIN', A: 80, B: 100 }, { subject: 'MUS', A: 80, B: 90 }]
 *
 * @return [Array] Data to be rendered in RadarChart component
 */
export const studentVsCourseProgressToRadarChart = (courseProgress, studentProgress) => {
  return courseProgress.map((intelligenceProgress) => {

    const studentIntelligenceScore = _.find(studentProgress, { intelligence_id: intelligenceProgress.id });

    let courseScore, studentScore = 0;

    if (!_.isEmpty(intelligenceProgress.data)) {
      courseScore = intelligenceProgress.data[0].value;
    }

    if (studentIntelligenceScore) {
      studentScore = studentIntelligenceScore.score;
    }

    

    return {
      subject: intelligenceProgress.slug,
      A: studentScore,
      B: courseScore
    }
  });
};
