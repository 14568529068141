export function getLessonPlans({ filter , search, restart, kind,page }: Object) {
    return {
      type: 'REQUEST_LESSON_PLANS',
      payload: {
        filter ,search,restart,page,kind
      }
    };
  }
  
  export function getLessonPlanFilters( ) {
    return {
      type: 'REQUEST_LESSON_PLAN_FILTERS',
      payload: { 
      }
    };
  }
  export function rateLessonPlan(id, score ,kind ) {
    return {
      type: 'REQUEST_RATE_LESSON_PLAN',
      payload: { 
        id,score,kind
      }
    };
  }