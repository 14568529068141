/* @flow */

import React from 'react';
import { Progress } from 'reactstrap';
import { Intelligences } from '../models/';
import classNames from 'classnames';
import Translations from '../hocs/TranslationsHelper';
import { compose, pure } from 'recompose';

type Props = {
  type: $Keys<typeof Intelligences.TYPES>,
  value: number,
  translations: Object
};

const getTopicInformation = ({ type }) => {
  return Intelligences.getIntelligenceInformation(type);
};



export function getSzClassFromValue(value){
  if(value < 5)
    return "sz-0";
  else if(value < 8)
    return "sz-1";
  else if(value < 16)
      return "sz-2";
  else if(value < 22)
      return "sz-3";
  else if(value < 50) 
      return "sz-4"; 
  else return "";
}
/**
 * Component to display a composition of the intelligence icon vs a progress bar
 * attached to that topic particular
 *
 * @method TopicProgress
 * @param  {Intelligences.TYPES}       type  - a valid intelligence type to render
 * @param  {number}                    value - the percentage of progress made represented by a number from 0 to 100
 * @param  {object}                    translations - injected by our Translations HoC to give translated strings
 */
const TopicProgress = ({ type, value, translations }: Props) => {
  const { icon, label } = getTopicInformation({ type });
  const iconWrapperClass = classNames('icon-wrapper');

  if (value > 100 || value < 0) {
    throw new Error(`[TopicProgress] value: ${value} should be > 0 and < 100`);
  }
 
  
  return (
    <div className="topic-progress">
      <div className={iconWrapperClass}>
        {icon}
      </div>
      <div className="info-wrapper">
        <label>{ translations.t(label) }</label>
        <Progress title={`${value}%`} value={value} color={label}>
          <span className={"progress-bar-indicator " + getSzClassFromValue(value) }>{`${value}%`}</span>
        </Progress>
      </div>
    </div>
  );// + ((value < 5)?" mini":"") 
};

const TopicProgressTranslated = Translations(TopicProgress);

export const TopicProgressComposed = compose (
  pure
)(TopicProgressTranslated);

export default TopicProgressComposed;
