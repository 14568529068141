/* @flow */

import React from 'react';
import { Label, Form, FormGroup } from 'reactstrap';
import { FieldArray, reduxForm } from 'redux-form';
import { CoursesFieldArray, Dropdown, SubmitButton } from './';
import Translations from '../hocs/TranslationsHelper';
import type { Course, TranslationsObject } from './types';

export function buildSelectOptions(courses: Array<Course>, translations: TranslationsObject) {
  return courses.map((course) => {
    const levelPart = course.level_name ? `${translations.t('level')} ${course.level_name}` : `${translations.t('unassigned')}`;
    return {
      value: course,
      label: `${translations.t('course')} ${course.name} - ${levelPart}`
    };
  });
}

const SchoolCoursesForm = ({ changeCourseLevel, onRemoveCourse, onSearchBlur, searchLoading, handleSearch, otherCourses, submitting, valid, dirty, handleSubmit, translations }) => {
  const selectOptions = buildSelectOptions(otherCourses, translations);

  return (
    <Form className="form school-courses-form" onSubmit={handleSubmit}>
      <FormGroup>
        <Label>{translations.t('add_existing_course')}</Label>
        <Dropdown
          options={selectOptions}
          placeholder={translations.t('pick_a_course')}
          onChange={changeCourseLevel}
          onBlur={onSearchBlur}
          disabled={submitting}
          onInputChange={handleSearch}
          isLoading={searchLoading}
          noResultsText={false}
         // this prevents react-select to try to do any option filtering,
         // as we already handle the search
          filterOption={() => true}
          searchable
        />
      </FormGroup>
      <Label>{translations.t('edit_level_courses')}</Label>
      <FieldArray name="levelCourses" component={CoursesFieldArray} translations={translations} onRemoveButtonClick={onRemoveCourse} submitting={submitting} />
      <SubmitButton loading={submitting} className="btn btn-primary--green" disabled={!valid || !dirty}>
        {translations.t('save')}
      </SubmitButton>
    </Form>
  );
};

export const FORM_NAME = 'schoolCoursesForm';

export default reduxForm({
  form: FORM_NAME,
  enableReinitialize: true
})(Translations(SchoolCoursesForm));
