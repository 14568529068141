/* @flow */

import React from 'react';
import { default as Slick } from 'react-slick';
import classNames from 'classnames';

/**
 * A wrapper component with configuration for the slider to use in the app.
 * It uses {@link https://github.com/akiran/react-slick react-slick} to render children props.
 *
 * @method Slider
 *
 * @example
 *
 * In version 0.14.8 of react-slick it is required that images get wrapped with a div element.
 * This is an issue within react-slick and creating a custom component for slides wrapping them with a div won't work.
 * Progress on this problem can be followed [in this issue]{@link https://github.com/akiran/react-slick/issues/555}.
 *
 * [This is another issue related to the same problem within tests]{@link https://github.com/akiran/react-slick/issues/712}
 *
 * <Slider>
 *  <div>
 *    <img className="slider-image" src={img1} />
 *  </div>
 *  <div>
 *    <img className="slider-image" src={img2} />
 *  </div>
 * </Slider>
 */
const Slider = ({ className, children, settings }) => {
  settings = settings || [];
  const sliderClass = classNames('slider', className);

  return (
    <Slick
      className={sliderClass}
      adaptiveHeight={true}
      infinite={true}
      arrows={false}
      responsive={settings}>
      {children}
    </Slick>
  );
};

export default Slider;
