/* @flow */

import React from 'react';
import InputAddon from './InputAddon';
import Paper from './Paper';
import { pure, compose, withProps, withState, withHandlers } from 'recompose';
import cubicoinIcon from '../../img/icon_coin.png';
import classNames from 'classnames';
import type { RecomposeStateName, RecomposeHandler } from './types';

type Props = {
  groups: Array<Object>,
  onClickNavigationItem: RecomposeHandler,
  activeNavigationItem: RecomposeStateName,
  onChangeInputAddonText: RecomposeHandler
};

/**
 * Render the navigation on the store to handle the content to be displayed
 * @method StoreNavigation
 * @param  {RecomposeHandler}        onClickNavigationItem  - injected by `recompose` as a handler to define what to do after click an Item
 * @param  {[]Object}                groups                 - different groups of content that after click will define the content to be displayed
 * @param  {RecomposeHandler}        activeNavigationItem   - injected by `recompose` as an state to control if the group is active or not
 * @param  {RecomposeHandler}        onChangeInputAddonText - injected by `recompose` as a handler to define what to do after type a search criteria
 */
const StoreNavigation = ({ onClickNavigationItem, groups, activeNavigationItem, onChangeInputAddonText }: Props) => {
  return (
    <Paper.Nav>
      {
        groups.map((group, key) => {
          const className = classNames({ active: (key === activeNavigationItem) }, group.className);
          return (
            <Paper.NavItem
              {...group}
              className={className}
              onClick={() => onClickNavigationItem({ eventKey: key, groups: groups })}
              key={key}
            />
          );
        })
      }
      <InputAddon iconClass="pleiq-icon-icon_search" onChange={(text) => onChangeInputAddonText(text)} />
    </Paper.Nav>
  );
};

export const StoreNavigationHandlers = {
  onClickNavigationItem: (props: Object) => ({ eventKey, groups }: Object) => {
    /*
      setStoreContent is a prop that need to be injected in order to know what to do
      after click a navigation item to update the content see @link{Store} component code
     */
    props.setStoreContent(groups[eventKey]);
    props.setActiveNavigationItem(eventKey);
  },
  onChangeInputAddonText: (text: string) => {
    return text;
  }
};

const StoreNavigationEnhancements = compose(
  withState('activeNavigationItem', 'setActiveNavigationItem', 0),
  withHandlers(StoreNavigationHandlers)
);

const StoreNavigationProps = compose(
  withProps(() => {
    return {
      groups: [
        { id: 'store-content-popular', iconClass: 'pleiq-icon-icon_store_popular', label: 'Popular' },
        { id: 'store-content-explore', iconClass: 'pleiq-icon-icon_store_explore', label: 'Explora' },
        { id: 'store-content-treasures', iconClass: 'pleiq-icon-icon_store_treasures', label: 'Tesoros' },
        { id: 'store-content-buy', iconSrc: cubicoinIcon, label: 120, className: 'coins' }
      ]
    };
  })
);

export const StoreNavigationWithoutProps = compose(
  StoreNavigationEnhancements,
  pure
)(StoreNavigation);

const StoreNavigationComposed = compose(
  StoreNavigationEnhancements,
  StoreNavigationProps,
  pure
)(StoreNavigation);

export default StoreNavigationComposed;
