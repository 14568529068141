/* @flow */

import React from 'react';
import { connect } from 'react-redux';
import { startSubmit } from 'redux-form';
import { compose, pure, withHandlers } from 'recompose';
import { FORM_NAME } from '../components/LevelDataForm';
import { LevelDataForm } from '../components/';
import type { ReduxFunction } from '../components/types';
import { showConfirmationModal } from '../../actions/confirmationModal';

type Props = {
  dispatch: ReduxFunction,
  initialValues: Object
};

const LevelDataFormContainer = (props: Props) => {
  return <LevelDataForm {...props} />;
};

export const LevelDataFormContainerHandlers = {
  onSubmit: ({ dispatch }: Props) => (values) => {
    const { level } = values;

    dispatch(startSubmit(FORM_NAME));

    // if level id is truthy, that means we should update it
    if (level.id) {
      dispatch({ type: 'REQUEST_LEVEL_UPDATE', payload: { level } });
      return;
    }

    // otherwise, we create the level
    dispatch({ type: 'REQUEST_LEVEL_CREATE', payload: { level } });
  },
  onDeleteClick: ({ dispatch, initialValues }: Props) => () => {
    const { level } = initialValues;
    const actions = [
      startSubmit(FORM_NAME),
      { type: 'REQUEST_LEVEL_DELETE', payload: { level } }
    ];

    dispatch(showConfirmationModal({
      title: 'delete_level_confirmation_title',
      message: 'delete_level_confirmation_message',
      actions
    }));
  } 
};

const LevelDataFormContainerComposed = compose(
  withHandlers(LevelDataFormContainerHandlers),
  pure
)(LevelDataFormContainer);

const mapStateToProps = (state, ownProps) => ({
  initialValues: { level: ownProps.level },
  persisted: !!(ownProps.level && ownProps.level.id)
});

const mapDispatchToProps = (dispatch) => ({ dispatch });

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LevelDataFormContainerComposed);
