/* @flow */

import React from 'react';
import { Slider, BundleExperience } from '../components/';
import { ExperiencesBundle } from '../models/';
import _ from 'lodash';

type Props = {
  bundles: Array<string>
};

const StoreSliderBundles = ({ bundles }: Props) => {
  let bundlesData = getBundlesData(bundles);

  return !_.isEmpty(bundlesData)
    ? (
      <div className="section-bundle-separator">
        <Slider className="slider-bundles" settings={bundleSliderSettings}>
          {
            bundlesData.map(({ image }, key) => {
              return (<div key={key}><BundleExperience image={image} /></div>);
            })
          }
        </Slider>
      </div>
    ) : null;
};

const getBundlesData = (bundles) => {
  let bundlesData = bundles.map((bundle) => {
    return ExperiencesBundle[bundle];
  });

  return _.without(bundlesData, undefined);
};

const bundleSliderSettings = [
  {
    breakpoint: 576,
    settings: {
      infinite: false,
      variableWidth: true,
      slidesToScroll: 1,
      slidesToShow: 1
    }
  },
  {
    breakpoint: 7680,
    settings: {
      infinite: false,
      slidesToScroll: 2,
      slidesToShow: 2
    }
  }
];

export default StoreSliderBundles;
