export function genericDataRequest(id, url,method, body ,cb) {
    return {
      type: 'REQUEST_GENERIC_DATA_'+id, 
      cb,
      payload: {
          method,
          url,
          body
      }
    };
} export function genericDataResponse(id, payload) {
  return {
    type: 'RES_GENERIC_DATA',
    id,
    payload
  };
} 