/* @flow */

import React from 'react';
import classNames from 'classnames';
import { compose, pure } from 'recompose';
import { Circle } from 'rc-progress';

type Props = {
  className?: string,
  size?: 'lg',
  value: number
};

const getStrokeColor = (value) => {
  const strokeColors = {
    critic: '#FF5A66',
    low: '#FCC035',
    good: '#678CFF',
    excellent: '#00CD93'
  };

  switch (true) {
    case (value < 30):
      return strokeColors.critic;
    case (value < 60):
      return strokeColors.low;
    case (value < 85):
      return strokeColors.good;
    default:
      return strokeColors.excellent;
  }
};

/**
 * A simple radial progress bar made with css that accepts
 * only integer numbers to be represented as a percentage
 *
 * @method RadialProgressBar
 * @param  {number}          value     - integer from 0 to 100
 * @param  {string}          [size]    - optional suffix to be used to make size changes [lg]
 * @param  {string}          className - string to be used to insert more classes to this component
 *
 * @example
 * <RadialProgressBar value={34} />
 */
const RadialProgressBar = ({ value, size, className }: Props) => {
  let sizeSuffix = size ? `-${size}` : '';
  let radialProgressClassName = classNames(`radial-progress${sizeSuffix}`, className);

  return (
    <div className="radial-progress-container">
      <Circle
        className={radialProgressClassName}
        percent={parseInt(value, 10)}
        strokeWidth={(size === 'lg') ? 10 : 7}
        trailWidth={(size === 'lg') ? 5 : 7}
        strokeColor={getStrokeColor(value)}
      />
      <span className="percentage">{`${value}%`}</span>
    </div>
  );
};

const RadialProgressBarComposed = compose(
  pure
)(RadialProgressBar);

export default RadialProgressBarComposed;
