import fieldDataMx from './fileld-data/fieldDataMx';
import fieldDataCLEN from './fileld-data/fieldDataCLEN';
import fieldDataCL2 from './fileld-data/fieldDataCL2';
import fieldDataUSFL from './fileld-data/fieldDataUSFL';
import fieldDataCL_EB from './fileld-data/fieldDataCL2_EB';
export const fieldDatas = {
  'MX': fieldDataMx,
  'CL_EN': fieldDataCLEN,
  'CL': fieldDataCL2,
  'US_FL': fieldDataUSFL,
  "CL_EB": fieldDataCL_EB
};
