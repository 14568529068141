

import imgBn3 from '../../img/cx/cx_cover_bn3.jpg'
import imgLnn1 from '../../img/cx/cx_cover_lnn1.jpg'
import imgLnn2 from '../../img/cx/cx_cover_lnn2.jpg'
import imgTln1 from '../../img/cx/cx_cover_tln1.jpg'
import imgTln2 from '../../img/cx/cx_cover_tln2.jpg'
import imgJcs1 from '../../img/cx/cx_cover_jcs1.jpg'
import imgJcs2 from '../../img/cx/cx_cover_jcs2.jpg'
import imgJcs3 from '../../img/cx/cx_cover_jcs3.jpg'
import imgCal11 from '../../img/cx/cx_cover_c11.jpeg'
import imgCal12 from '../../img/cx/cx_cover_c12.jpeg'
import imgCal20 from '../../img/cx/cx_cover_c20.jpeg'
import imgCal11s from '../../img/cx/cx_cover_cal11s.jpeg'
import imgCal12s from '../../img/cx/cx_cover_cal12s.jpeg'
import imgCal20s from '../../img/cx/cx_cover_cal20s.jpeg'
import imgVal1 from '../../img/cx/rsz_cx_cover_val1.jpeg'
import imgFsc45 from '../../img/cx/cx_cover_fsc45.jpeg'
import imgFsc56 from '../../img/cx/cx_cover_fsc56.jpeg'

import imgC30 from '../../img/cx/cx_cover_c30.jpg'
import imgC30s from '../../img/cx/cx_cover_c30s.jpg' 
import imgC40 from '../../img/cx/cx_cover_c40.jpg'
import imgC40s from '../../img/cx/cx_cover_c40s.jpg' 

 
import imgTln1s from '../../img/cx/cx_cover_tln1s.jpg'
import imgTln3s from '../../img/cx/cx_cover_tln3s.jpg' 
import imgTln2s from '../../img/cx/cx_cover_tln2s.jpg'
import imgLnn3 from '../../img/cx/cx_cover_lnn3.jpg' 

export const bookInfo = { 

    cal11:  {
        imgSrc: imgCal11,
        label: "Caligrafía 1º Básico 1er Semestre",
        color: "#a6d282"
    },
    cal12:  {
        imgSrc: imgCal12,
        label: "Caligrafía 1º Básico 2do Semestre",
        color: "#16c4af"
    },
    cal20:  {
        imgSrc: imgCal20,
        label: "Caligrafía 2º Básico",
        color: "#78dbdf"
    },
    bn3:  {
        imgSrc: imgBn3,
        label: "Balancin",
        color: "#ff865c"
    },
    lnn1:  {
        imgSrc: imgLnn1,
        label: "Lógica y Números 1",
        color:"#16cc90"
    },
    lnn2:  {
        imgSrc: imgLnn2,
        label: "Lógica y Números 2", color:"#d36cff"
    },
    tln1:  {
        imgSrc: imgTln1,
        label: "Trazos y Letras 1", color:"#f1c300"
    },
    tln2:  {
        imgSrc: imgTln2,
        label: "Trazos y Letras 2", color:"#64ccf3"
    } ,
    jcs1:  {
        imgSrc: imgJcs1,
        label: "Jugando con los Sonidos 1", color:"#3E76D0"
    }  ,
    jcs2:  {
        imgSrc: imgJcs2,
        label: "Jugando con los Sonidos 2", color:"#D9B20D"
    }  ,
    jcs3:  {
        imgSrc: imgJcs3,
        label: "Jugando con los Sonidos 3", color:"#F7602C"
    } ,
    val1: {

        imgSrc: imgVal1,
        label: "Viaje a la Lectura"
    }, 
    cal11s:  {
        imgSrc: imgCal11s,
        label: "Caligrafía 1º Básico 1er Semestre Script",
        color: "#a6d282"
    },
    cal12s:  {
        imgSrc: imgCal12s,
        label: "Caligrafía 1º Básico 2do Semestre Script",
        color: "#16c4af"
    },
    cal20s:  {
        imgSrc: imgCal20s,
        label: "Caligrafía 2º Básico Script",
        color: "#78dbdf"
    },
    fsc45:  {
        imgSrc: imgFsc45,
        label: "¡AprenDiviértete! Buscando a bigotes.",
        color: "#78dbdf"
    },
    fsc56:  {
        imgSrc: imgFsc56,
        label: "¡AprenDiviértete! Explorando con amigos.",
        color: "#78dbdf"
    },
    cal30: {
        imgSrc: imgC30,
        label: "Caligrafía 3ro Básico",
        color: "#78dbdf"

    },
    cal30s: {
        imgSrc: imgC30s,
        label: "Caligrafía 3ro Básico Script",
        color: "#ff865c"

    },
    cal40: {
        imgSrc: imgC40,
        label: "Caligrafía 4to Básico",
        color: "#16cc90"

    },
    cal40s: {
        imgSrc: imgC40s,
        label: "Caligrafía 4to Básico Script",
        color: "#16c4af"

    },
 
    lnn3: {
        imgSrc: imgLnn3,
        label: "Lógica y Números 3",
        color: "#78dbdf"

    },
    tln3s: {
        imgSrc: imgTln3s,
        label: "Trazos y Letras 3 Script",
        color: "#ff865c"

    },
    tln2s: {
        imgSrc: imgTln2s,
        label: "Trazos y Letras 2 Script",
        color: "#16cc90"

    },
    tln1s: {
        imgSrc: imgTln1s,
        label: "Trazos y Letras 1 Script",
        color: "#16c4af"

    },

    
//  cx_cover_bn3.jpg  cx_cover_lnn1.jpg  cx_cover_lnn2.jpg  cx_cover_tln1.jpg  cx_cover_tln2.jpg
}
export const getBookInformation = (  slug ) => {
 

  return bookInfo[slug] || {imgSrc: null, label:slug};
};
