import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { logout } from "../../actions/user";

export const APP_VERSION_STR = "1.0.0";

export const VersionHandler = ()=>{
    const {app_version, is_logged_in} = useSelector(({user})=> user  );
    const dispatch = useDispatch();
    useEffect(()=>{

        if(app_version!= APP_VERSION_STR && is_logged_in){
            console.warn("VERSION CHANGE DETECTED, loging out");
            dispatch(logout());
        }

    })
}