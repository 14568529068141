import React from 'react';
import { connect, useSelector } from 'react-redux';

export const RoleFilter = ({ roles, children})=>{
    const role = useSelector(({user})=> user.role) 
    if(roles && roles.indexOf(role)==-1) 
        return null;
    return <React.Fragment>
        {children} 
    </React.Fragment> ;
    
}

export default RoleFilter; //Container = connect(({user})=> ({role: user.role}))(RoleFilter);
