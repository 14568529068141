/* @flow */

import React from 'react';
import { TopicProgress } from '../components/';
import { getIntelligenceProgressById } from '../../selectors/intelligenceProgress';
import { pure, compose } from 'recompose';
import  LoadingStatus from "../containers/LoadingStatus"
import _ from 'lodash';

/**
 * Component that receives the data to display all the progress
 * over each of the Intelligences in a TopicProgress Component
 * @method GeneralIntelligenceProgressContainer
 * @param  {array}               currentProgress - injected by Redux includes all intelligence_progress from the store for the current child
 * @param  {array}               intelligences - injected by Redux includes all intelligences from the store
 */
export const GeneralIntelligenceProgressContainer = ({ currentProgress, intelligences }) => {
  const currentIntelligenceProgress = getIntelligencesProgress({ currentProgress, intelligences });

  return (
    <div>
      <LoadingStatus request={["INTELLIGENCES","INTELLIGENCE_PROGRESS"]}/>
      {currentIntelligenceProgress.map((int) => <TopicProgress key={int.id} type={int.type} value={int.value} />)}
    </div>
  );
};

export const getIntelligencesProgress = ({ currentProgress, intelligences }) => {
  return _.map(intelligences, (intelligence) => {
    const currentIntelligenceProgress = getIntelligenceProgressById({ intelligence_progress: currentProgress }, intelligence.id);
    const progress = _.get(currentIntelligenceProgress, 'score') || 0;

    return {
      id: intelligence.id,
      type: intelligence.slug,
      value: parseInt(progress, 10)
    };
  });
};

const GeneralIntelligenceProgressContainerComposed = compose(
  pure
)(GeneralIntelligenceProgressContainer);

export default GeneralIntelligenceProgressContainerComposed;
