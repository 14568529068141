/* @flow */

import { responseHasError } from './helper';
import type { Action } from '../types';
import { enableInitializing } from 'initializable-reducer';

const initialState = {};

function authorization(state: Object = initialState, { payload, type }: Action) {
  let responseBody;

  switch (type) {
    case 'LOGGED_IN':
      responseBody = payload.body;

      if (responseHasError(payload)) {
        return state;
      }

      return {
        token: responseBody.jwt
      };
    default:
      return state;
  }
}

export {
  authorization,
  initialState
}

export default enableInitializing(authorization)
