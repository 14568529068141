/* @flow */

import React from 'react';
import { pure, compose, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import { logout } from '../../actions/user';
import { Avatar } from '../components';
import type { RecomposeHandler, ReduxFunction, ReduxState } from '../components/types';

type Props = {
  dispatch: ReduxFunction,
  user: ReduxState,
  onClickDropdownItem: RecomposeHandler
};

/**
 * Component that will wrap the Avatar functional component
 * in order to inject all the dynamic data and special callbacks
 *
 * @method AvatarContainer
 * @param  {function}        onClickDropdownItem - callback to be executed whenever an option of the Avatar dropdown gets clicked
 */
const AvatarContainer = ({ onClickDropdownItem, user }: Props) => {
  return <Avatar onClickDropdownItem={onClickDropdownItem} user={user} />;
};

export const AvatarContainerHandlers = {
  onClickDropdownItem: (props: Object) => (event: Object) => {
    let { action } = event.target.dataset;

    switch (action) {
      case 'logout':
        props.dispatch(logout());
        break;
      default:
        return;
    }
  }
};

const AvatarContainerEnhancements = compose(
  withHandlers(AvatarContainerHandlers)
);

const mapDispatchToProps = (dispatch) => {
  return { dispatch };
};

const mapStateToProps = ({ user }) => {
  return {
    user
  };
};

const AvatarContainerComposed = compose(
  AvatarContainerEnhancements,
  pure
)(AvatarContainer);

const AvatarContainerConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(AvatarContainerComposed);

export default AvatarContainerConnected;
