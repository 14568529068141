/* @flow */

import Raven from 'raven-js';
import createRavenMiddleware from 'raven-for-redux';
import { createStore, applyMiddleware, combineReducers } from 'redux';
import { persistStore, autoRehydrate } from 'redux-persist';
import { reducer as reduxFormReducer } from 'redux-form';
import { routerMiddleware, routerReducer } from 'react-router-redux'
import { composeWithDevTools } from 'redux-devtools-extension';
import createHistory from 'history/createBrowserHistory';
import rootSaga from './sagas';
import reducers from './reducers';
import localForage from 'localforage'
import crashReporter from './crashReporter';
import createSagaMiddleware from 'redux-saga';
import { pdfContext } from './pdfer/Pdfable';
import { pdfPrintingChange, pdfPrintingProgressChange } from './actions/pdf';

if(process.env.REACT_APP_SENTRY_DSN)
  Raven.config(process.env.REACT_APP_SENTRY_DSN).install();

// enable redux dev tools
const composeEnhancers = composeWithDevTools({});

// define middlewares
export const history = createHistory();
const historyMiddleware = routerMiddleware(history);
const ravenMiddleware = createRavenMiddleware(Raven);
const sagaMiddleware = createSagaMiddleware();
 
// gather all middlewares order matters
const middlewares = [
  //stateCheckMiddleware,
  ravenMiddleware,
  crashReporter,
  historyMiddleware,
  sagaMiddleware 

];

// define reducers combineReducers
const combinedReducers = combineReducers({
  ...reducers,
  routerReducer,
  form: reduxFormReducer
});

export function setupStore(onStorePersisted: () => mixed) {
  const store = composeEnhancers(
    applyMiddleware(...middlewares),
    autoRehydrate()
  )(createStore)(combinedReducers);

  const persistOptions = {
    storage: localForage,
    blacklist: [
      'alerts',
      'form',
      'school_levels_collapse_structure'
    ]
  };

  persistStore(store, persistOptions, () => {
    sagaMiddleware.run(rootSaga);
    store.dispatch(pdfPrintingChange(false, {}));
    store.dispatch({type:"CLEAR_REQUESTS_"});
    //recover books

    var {user, caligrafix_books,intelligences} = store.getState(); 
    /*if(caligrafix_books.length==0){
      store.dispatch({type:"REQUEST_CALIGRAFIX_BOOKS"}); 
    }
    if(intelligences.length==0){
      store.dispatch({type:"REQUEST_INTELLIGENCES"}); 
    }*/

    if(user.is_logged_in)
      store.dispatch({type:"APPLICATION_REINIT" ,payload:{account_type: user.account_type, loadBooks:true, loadIntelligences:true}}); 

    onStorePersisted();
  });

  pdfContext.state.subscribe((printing)=>{
    store.dispatch(pdfPrintingChange(printing, pdfContext.props));
  });

  pdfContext.progress.subscribe((progress)=>{
    store.dispatch(pdfPrintingProgressChange(progress ));
  });
  
  return store;
}

export default setupStore;
