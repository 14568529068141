/* @flow */

import { call, takeEvery, select, all } from 'redux-saga/effects';
import { getActiveChild } from '../selectors/children';
import { workerRequest } from '../diplomatico/workers';
import type { Action } from '../diplomatico/types';

/**
 * Worker to handle the Activity Feed request
 */
export function* workerRequestActivityOptions(action: Action): Object {
  let child = yield select(getActiveChild);

  return ({
    requestParams: {
      endpoint: `/children/${child.id}/activity_feed`,
      method: 'get'
    }
  });
};

export function* workerRequestActivity(action: Action): any {
  let options = yield call(workerRequestActivityOptions, action.payload);

  let workerRequestGenerator = yield call(workerRequest, options);

  yield call(workerRequestGenerator, action);
}

export function* watchActivityRequest(): any {
  yield all([
    takeEvery('REQUEST_ACTIVITY', workerRequestActivity)
  ]);
}
