/* @flow */

import React from 'react';

const StoreCardFree = () => (
  <div className="store-card-free-cost">
    FREE
  </div>
);

const StoreCardWithCost = ({ cost }) => (
  <div className="store-card-cost">
    <span className="pleiq-icon-icon_cubicoin_outline"></span> {cost}
  </div>
);

const StoreCardBadge = () => (
  <div className="card-badge card-badge-absolute card-badge-new">
    ¡Nuevo!
  </div>
);

type Props = {
  image: string,
  title: string,
  cost: number,
  newItem: boolean
};

/**
 * A card component to display cost of an item in the Store
 *
 * @method StoreCard
 * @param  {string}          image        - url of the image to display in the card
 * @param  {string}          title        - title for the item displayed below the image
 * @param  {number}          cost         - cost of the item (if the cost is 0, FREE will be displayed)
 * @param  {boolean}         newItem      - boolean to show an indicator of the item being new
 *
 */
const StoreCard = ({ image, title, cost, newItem }: Props) => {
  const isFree = !cost || cost === 0;

  return (
    <div className="store-card">
      <div className="store-card-image">
        <img className="img store-card-image--placeholder" src={image} alt={title} />
      </div>
      <div className="store-card-title">{title}</div>
      { isFree ? <StoreCardFree /> : <StoreCardWithCost cost={cost} /> }
      { newItem ? <StoreCardBadge /> : null }
    </div>
  );
};

export default StoreCard;
