/* @flow */

import { put, select, call, takeEvery, all } from 'redux-saga/effects';
import { stopSubmit } from 'redux-form';
import { workerRequest } from '../diplomatico/workers';
import type { Action } from '../diplomatico/types';
import { FORM_NAME as USER_DATA_FORM } from '../js/components/SchoolUserSignUpForm';
//import { FORM_NAME as SCHOOL_USERS_FORM } from '../js/components/SchoolUsersForm';
import { addUser,  setModalUser } from '../actions/schoolUsers';
import { setModalCourse, updateCourse } from '../actions/courses';
import { getCourseById } from '../selectors/courses';

/**
 * Worker to get all the users
 */
export const workerRequestAllUsersOptions = {
  requestParams: {
    endpoint: `/school/users`,
    method: 'get'
  }
};

export const workerRequestAllUsers = workerRequest(workerRequestAllUsersOptions);

/**
 * Worker to request users with a search param
 */
export const workerRequestSearchUsersOptions = (query: string, page: number) => ({
  requestParams: {
    endpoint: `/school/users?q=${query}&page=${page}`,
    method: 'get',
    page
  }
});

export function* workerRequestSearchUsers(action: Action): any {
  let { query, page, clean } = action.payload;
  let workerRequestGenerator = workerRequest(workerRequestSearchUsersOptions(query, page));
  let actionToSend = {
    type: clean ? 'REQUEST_SEARCH_USERS_CLEAN' : action.type,
    payload: action.payload
  };

  yield call(workerRequestGenerator, actionToSend);
}

/**
 * Worker to get the users for current school
 */
const workerRequestUsersOptions = (courseId: number) => ({
  requestParams: {
    endpoint:  ((courseId==null)?'/school/users/unassigned' : `/school/courses/${courseId}/users`),
    method: 'get'
  }
});

export function* workerRequestsUsers({ type, payload }: Action): any {
  const { course } = payload;
  const workerRequestGenerator = workerRequest(workerRequestUsersOptions((course ||{id: null}).id));
  yield call(workerRequestGenerator, { type, payload });
}

/**
 * Worker to create a user for current school
 */
export const workerRequestUserSignupOptions = {
  requestHandlers: {
    onSuccess: function* ({ action, response }: Object): any { 
      yield put(stopSubmit(USER_DATA_FORM));
    },
    onError: function* ({ action, response }: Object): any {  
      yield put(stopSubmit(USER_DATA_FORM, {_error: "unknown_error"}));
    }
  },
  requestParams: {
    endpoint: `/school/users/signup`,
    method: 'post',
    reportable: false,
    authorizable: false
  },
  buildRequestBody: (actionPayload: Object) => actionPayload.user
};

export const workerRequestUserSignup = workerRequest(workerRequestUserSignupOptions);

/**
 * Worker to update a specific user for current school
 */
export const workerRequestUserUpdateOptions = (userId: string) => ({
  requestHandlers: {
    onSuccess: function* ({ action, response }: Object): any {
     /* TODO: const { original_course_id } = action.payload;
      const user = {
        ...action.payload.user,
        ...response.payload.body
      };
      const course_id = user.course_id;

      if (course_id) {
        const course = yield select(getCourseById, course_id);
        yield put(updateCourse({ ...course, users_count: course.users_count + 1 }));
      }

      if (original_course_id) {
        const originalCourse = yield select(getCourseById, original_course_id);
        yield put(updateCourse({ ...originalCourse, users_count: originalCourse.users_count - 1 }));
      }

      yield put(updateUser(user));
      yield put(setModalUser(user));
      yield put(stopSubmit(USER_DATA_FORM));*/
    },
    onError: function* ({ action, response }: Object): any {
      yield put(stopSubmit(USER_DATA_FORM));
    }
  },
  requestParams: {
    endpoint: `/school/users/${userId}`,
    method: 'put'
  },
  buildRequestBody: (actionPayload: Object) => actionPayload.user
});

export function* workerRequestUserUpdate({ type, payload }: Action): any {
  const { user } = payload;
  const workerRequestGenerator = workerRequest(workerRequestUserUpdateOptions(user.id));
  yield call(workerRequestGenerator, { type, payload });
}

 
/**
 * Worker to delete a user
 */
export const workerRequestUserDeleteOptions = (userId: string) => ({
  requestHandlers: {
    onSuccess: function* ({ action, response }: Object): any {
      /*TODO: const { user } = action.payload;
      yield put(deleteUser(user));
      const course = yield select(getCourseById, user.course_id);

      if (course) {
        yield put(updateCourse({ ...course, users_count: course.users_count - 1 }));
      }

      yield put(stopSubmit(USER_DATA_FORM));
      yield put(closeEditUserModal());*/
    },
    onError: function* ({ action, response }: Object): any {
      /*TODO: 
      yield put(stopSubmit(USER_DATA_FORM));
      yield put(closeEditUserModal());*/
    }
  },
  requestParams: {
    endpoint: `/school/users/${userId}`,
    method: 'delete'
  },
  buildRequestBody: (actionPayload: Object) => ({})
});

export function* workerRequestUserDelete({ type, payload }: Action): any {
  const { user } = payload;
  const workerRequestGenerator = workerRequest(workerRequestUserDeleteOptions(user.id));
  yield call(workerRequestGenerator, { type, payload });
}

export function* watchSchoolUsersRequest(): any {
  yield all([
    takeEvery('REQUEST_ALL_SCHOOL_USERS', workerRequestAllUsers),
    takeEvery('REQUEST_SCHOOL_USERS', workerRequestsUsers),
    takeEvery('REQUEST_SEARCH_SCHOOL_USERS', workerRequestSearchUsers),
    takeEvery('REQUEST_SCHOOL_USER_SIGNUP', workerRequestUserSignup),
    takeEvery('REQUEST_SCHOOL_USER_UPDATE', workerRequestUserUpdate), 
    takeEvery('REQUEST_SCHOOL_USER_DELETE', workerRequestUserDelete)
  ]);
}
