/* @flow */

import React from 'react';
import { Paper, InfoTooltip, Button } from '../components';
import { Col, Row  } from 'reactstrap';
import {
  HeaderContainer, SchoolDashboardHeadingContainer,
  SchoolDashboardNavContainer, StudentProgressNavContainer,
  StudentIntelligenceProgressContainer, DataDisplayStudentContainer,
  StudentProgressNameContainer, PageTitleContainer, StudentVsCourseGraphContainer,
  StudentEffortVsPerformanceGraphContainer,
  StudentCaligrafixBookProgressContainer,
  DataDisplayCaligrafixStudentContainer
} from '../containers';
import Translations from '../hocs/TranslationsHelper';
import { Link } from 'react-router-dom';

import { StudentPdfSelection, StudentCommentsPdfSelection, StudentCaligrafixPdfSelection } from '../pdfview/StudentView';
import { findStudentAcrossReducers } from '../../selectors/students';
import { StudentCaligrafixProgressContainer } from '../containers/StudentCaligrafixProgressContainer';
import LicenseFilter from '../containers/LicenseFilter';
import StudentCommentsList from '../components/StudentCommentList';
import StudentCommentListContainer from '../containers/StudentCommentListContainer';
import SchoolStudentDashboardComments from '../containers/SchoolStudentDashboardComments';
import StudentRepresentantsContainer from '../containers/StudentRepresentantsContainer';
import StudentRepresentantFormContainer from '../containers/StudentRepresentantFormContainer';
import RoleFilter from '../containers/RoleFilter';
import topScroll from '../hocs/topScroll';
 
/**
 * screen for the school SchoolChallenges
 *
 * @method SchoolStudentDashboard
 *
 */
const SchoolStudentDashboard = ({ translations }) => {
  topScroll();
  return (
    <div id="school-dashboard" className="school-dashboard layout-base">
      <PageTitleContainer pageTitle="title_student_progress" />
      <HeaderContainer />
      <SchoolDashboardHeadingContainer />
      <Paper.Container lead>
        <SchoolDashboardNavContainer currentTab="courses" pdfTarget={
          (state)=>('pleiq_report_student_'+ findStudentAcrossReducers(state,state.match.params.student_id).name  )
        } pdfFilter="single_student" pdfProps={({match})=>({student_id: match.params.student_id}) }/>
 
          <Row>
            <Col xs="12">
              <div className="mb-3">
                <Link to="/school/courses" className="back-link">
                  <span className="pleiq-icon pleiq-icon-icon_arrow_left vcentered" />{' '}
                  <span className="back-link-text vcentered">{translations.t('back_to_courses')}</span>
                </Link>
              </div>
            </Col>
            <Col xs="12">
              <Paper.Title>
                <StudentProgressNameContainer />
              </Paper.Title>
            </Col>
            <Col xs="12">
              <LicenseFilter filter={["pleiq_school", "pleiq_cx_plus"]}> 
                <StudentProgressNavContainer />
              </LicenseFilter>
            </Col>

            <LicenseFilter filter={["pleiq_cx", "pleiq_cx_plus"]}>  
              <Col xs="12">
                <LicenseFilter filter={[ "pleiq_cx_plus"]}> 
                  <Paper.Title>
                    <h1>
                    { translations.t('tooltips_current_caligrafix_progress') }{' '} 
                    <InfoTooltip message="caligrafix_current_general_progress" />
                    </h1>  
                  </Paper.Title>
                </LicenseFilter>
                <Paper.Content>
                  <StudentCaligrafixBookProgressContainer />
                </Paper.Content>
              </Col> 
              <Col xs="12"> 
                  <Paper.Title>
                    <h1 className="mt-5">
                      { translations.t('caligrafix_progress_analysis') } {' '}
                      <InfoTooltip message="tooltips_intelligences_progress_analysis" />
                    </h1>
                  </Paper.Title>
                  <Paper.Content>
                    <DataDisplayCaligrafixStudentContainer />
                  </Paper.Content> 
              </Col> 


            </LicenseFilter>
            
            <LicenseFilter filter={["pleiq_school", "pleiq_cx_plus"]}> 
              <Col xs="12">
                <LicenseFilter filter={[ "pleiq_cx_plus"]}> 
                  <Paper.Title>
                    <h1 className="mt-5">
                      { translations.t('intelligences_progress') } {' '} 
                    <InfoTooltip message="intelligences_progress" />
                    </h1>
                  </Paper.Title>
                </LicenseFilter>
                <Paper.Content>
                  <StudentIntelligenceProgressContainer />
                </Paper.Content>
              </Col>
            </LicenseFilter>
          </Row>

          <LicenseFilter filter={["pleiq_school", "pleiq_cx_plus"]}> 
          <Row>
            <Col xs="12">
              <LicenseFilter filter={["pleiq_school", "pleiq_cx_plus"]}> 
                <Paper.Title>
                  <h1 className="mt-5">
                    { translations.t('intelligences_progress_analysis') } {' '}
                    <InfoTooltip message="tooltips_intelligences_progress_analysis" />
                  </h1>
                </Paper.Title>
                <Paper.Content>
                  <DataDisplayStudentContainer />
                </Paper.Content>
              </LicenseFilter>
            </Col>
          </Row>
          <Row>
            <RoleFilter roles={["coordinator", "teacher","observer"]}>
              <Col>
                <Paper.Title>
                  <h1 className="mt-5">
                    { translations.t('against_course') } {' '}
                    <InfoTooltip message="tooltips_intelligences_progress_analysis" />
                  </h1>
                </Paper.Title>
                <Paper.Content>
                  <StudentVsCourseGraphContainer />
                </Paper.Content>
              </Col> 
            </RoleFilter>
            <Col>
              <Paper.Title>
                <h1 className="mt-5">
                  { translations.t('effort_vs_performance') } {' '}
                  <InfoTooltip message="tooltips_intelligences_progress_analysis" />
                </h1>
              </Paper.Title>
              <Paper.Content>
                <StudentEffortVsPerformanceGraphContainer />
              </Paper.Content>
            </Col> 
          </Row>
          <Row>
            <Col>
              <Paper.Title>
                <h1 className="mt-5">
                  { translations.t('student_representants') } {' '}
                  <InfoTooltip message="tooltips_student_representants" />
                </h1>
              </Paper.Title>
              <Paper.Content>
                <RoleFilter roles={["coordinator", "teacher", "user"]}>
                  <StudentRepresentantsContainer/> 
                </RoleFilter >
              </Paper.Content>
            </Col>
          </Row>
          {
            /*
          <Row>
            <Col>
              <Paper.Title>
                <h1 className="mt-5">
                  { translations.t('effort_vs_performance') } {' '}
                  <InfoTooltip message="tooltips_intelligences_progress_analysis" />
                </h1>
              </Paper.Title>
              <Paper.Content>
                <StudentRepresentantFormContainer/>
              </Paper.Content>
            </Col>
          </Row>
            */
        }
        </LicenseFilter>
 
        <SchoolStudentDashboardComments/> 

        </Paper.Container>
        <LicenseFilter filter={["pleiq_cx", "pleiq_cx_plus"]}>  
          <StudentCaligrafixPdfSelection path="student"/>
        </LicenseFilter>
        <LicenseFilter filter={["pleiq_school", "pleiq_cx_plus"]}>  
          <StudentPdfSelection path="student_int"/>
        </LicenseFilter>
        <StudentCommentsPdfSelection path="comments"/>

   
    </div>
  );
};  

export default Translations(SchoolStudentDashboard);  
