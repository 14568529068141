
import { useLocation } from 'react-router';
import { useEffect } from "react" ;

 
export const topScroll = ( deps )=>{
    const {pathname} = useLocation();
    useEffect(()=>{
        document.documentElement.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant", // Optional if you want to skip the scrolling animation
          });
    }, deps || [pathname]);
}

export default topScroll;