/* @flow */

import React from 'react';
import { Paper } from '../components';
import { Col, Row } from 'reactstrap';
import {
  SchoolDashboardNavContainer, SchoolDashboardHeadingContainer, HeaderContainer,
  SchoolSearchStudentContainer, PageTitleContainer
} from '../containers';
import Translations from '../hocs/TranslationsHelper';
import topScroll from '../hocs/topScroll';

/**
 * @method SchoolSearchStudent
 *
 */
const SchoolSearchStudent = ({ translations }) => {
  topScroll();
  return (
    <div id="school-dashboard" className="school-dashboard layout-base">
      <PageTitleContainer pageTitle="title_school_search_students" />
      <HeaderContainer />
      <SchoolDashboardHeadingContainer />
      <Paper.Container lead>
        <SchoolDashboardNavContainer currentTab="courses" />
        <Row>
          <Col xs="12">
            <Paper.Title>
              <h1>{translations.t('students_search_title')}</h1>
            </Paper.Title>
          </Col>
          <Col xs="12">
            <SchoolSearchStudentContainer />
          </Col>
        </Row>
      </Paper.Container>
    </div>
  );
};

export default Translations(SchoolSearchStudent);
