/* @flow */

import React from 'react';
import { connect } from 'react-redux' 
import { Row, Col } from 'reactstrap';
import { getCoursesProgressByIntelligence } from '../../selectors/courses';
import { IntelligenceProgressBarChart } from '../components';
import Translations from '../hocs/TranslationsHelper';
import { getSchoolProgressByCourse } from '../../selectors/schoolProgress';
import { withPdf } from '../../pdfer/Pdfable';
import actions from '../../actions';

/**
 * Container that receives data from the store and passes down the required data
 * @method SchoolCoursesProgressContainer
 */

export const SchoolCoursesProgressContainer = ({ courses, animated, intelligences, progress, translations }) => {
  const coursesProgress = getCoursesProgressByIntelligence({ courses, intelligences, progress });

  return (
    <Row className="progress-bar-chart-row">
      {
        coursesProgress.map((item) => (
          <Col xs="12" md="6" key={item.slug}>
            <IntelligenceProgressBarChart
              animated = {animated}
              title={translations.t(item.slug)}
              data={item.data}
              color={item.color} />
          </Col>
        ))
      }
    </Row>
  )
};

const mapStateToProps = ({ courses, intelligences , pdf}, ownProps) => {
  return {
    courses,
    intelligences,
    animated: !pdf.printing
  };
};

const mapDispatchToProps = (dispatch) => ({ dispatch });

const SchoolCoursesProgressContainerConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(Translations(SchoolCoursesProgressContainer));

export default SchoolCoursesProgressContainerConnected;

let esr = 0;

const mapStateToProps2 = ({ courses, intelligences ,school_general_courses_progress, pdf}, {level_id}) => {
  var levelId = level_id;//pdf.props.level_id; 
  return {
    level_id: levelId,
    courses,
    intelligences,
    ensureudt: esr++, 
    animated: false,
    progress: getSchoolProgressByCourse({ progress: school_general_courses_progress }, levelId)
  };
};

const mapDispatchToProps2 = (dispatch) => ({ dispatch });


export const SchoolCoursesProgressPdf = connect(mapStateToProps2, mapDispatchToProps2)(
    withPdf({props:{newpage:false},filter:"course_report",pre: ({level_id,progress, courses,intelligences,dispatch})=>{
    //const coursesProgress = getCoursesProgressByIntelligence({ courses, intelligences, progress });

    dispatch(actions.getSchoolGeneralCoursesProgress({ level_id: level_id }));

    return true;
} })(Translations(SchoolCoursesProgressContainer)));
