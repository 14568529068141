

import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector  } from 'react-redux';
import { compose, pure, withHandlers, withState, lifecycle } from 'recompose';


import actions from '../../actions';
import RequestOptions from '../../diplomatico/RequestOptions';
import { select } from 'redux-saga/effects';
import { getAuthorization } from '../../diplomatico/selectors/authorization';

const retrievers = {};
const dataListeners =  {};
export function invalidateRetriever(id){

    if(id in retrievers){
        retrievers[id].update();
    }
    if(id in dataListeners){
        for(let d of dataListeners[id])
            d();
    }
}
const addListener= (id, cb)=>{
    if(!(id in dataListeners))
        dataListeners[id] = [];
    dataListeners[id].push(cb);
    return ()=>removeListener(cb);
}

const removeListener = (id, cb)=>{
    if(id in dataListeners){
        const idx = dataListeners[id].indexOf(cb);
        if(idx!=-1)
            dataListeners[id].splice(idx,1);
    }
}

export async function genericDataRequest(authorization, params ){
    const url = RequestOptions.buildFinalURL(params.url); 
    let headers = new window.Headers({
        'Content-Type': 'application/json'
      });
    headers.append('Authorization', `Bearer ${authorization.token}`);
} 

export const useData = (handle)=>{

    const id = handle&&handle.id;
    const url = handle&&handle.url;

    const dispatch = useDispatch();
    //const[data, setData] = useState({body: null});
    //const[loading, setLoading] = useState(false); 
    const {payload, loading} = useSelector(({generic_data})=>(generic_data[id] || {loading: handle ,payload: { }}));

    useEffect(()=>{ 
     //   setLoading(true); handle
 
         
        if(handle)
            dispatch(  actions.genericDataRequest(id, url,'get' ,undefined));
        /*, (data, idc)=>{
            console.assert(idc===id); 
            setData(data)
            setLoading(false);
        })); */
        return addListener(id, ()=>dispatch(  actions.genericDataRequest(id, url,'get' ,undefined)));
    }, [url,id])
    let data = payload;
    if(data && data._meta&& data._meta.payload.request.endpoint!=url)
        data = null;
    if(!data)data = {};

    return {data , loading};

} 
const DataRetriever = (Component)=> {
    
    
    const DataRetriever = ( params  ) =>{ 
        return <Component {...params}/>;

    }
    


    const DataRetrieverLifecycle= {
        update(){
            const { dispatch ,  dataUrl, id,setData, setLoading} = this.props;   
            setLoading(true);
            dispatch(  actions.genericDataRequest(id, dataUrl,'get' ,undefined, (data, idc)=>{
                console.assert(idc===id); 
                setData(data)
                setLoading(false);
            })); 
        },
        componentDidUpdate(prevProps) {
          const lastParam = prevProps.dataUrl;
          const nextParam = this.props.dataUrl;
          if (lastParam !== nextParam) {
            this.update();
          }
        },
        componentDidMount() { 
            this.update();
            retrievers[this.props.id] = this;
        }  ,

        componentWillUnmount(){
            delete retrievers[this.props.id] ;

        }
    }
    const DataRetrieverComposed = compose( 
        withState("loading", "setLoading", false),
        withState("data", "setData", null),
        lifecycle(DataRetrieverLifecycle)  
    )(DataRetriever);

    const mapStateToProps = ({ authorization },{id}) => { 
        return { 
            authorization
        }
    };

    const mapDispatchToProps = (dispatch) => ({ dispatch });

    return connect(mapStateToProps, mapDispatchToProps)(DataRetrieverComposed);

}
export default DataRetriever;