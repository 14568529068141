/* @flow */

import React from 'react';
import { connect } from 'react-redux';
import { compose, withHandlers, pure } from 'recompose';
import { CoursesActions } from '../components';
import type { RecomposeHandler, ReduxFunction } from '../components/types';
import { createLevel } from '../../actions/levels';
import { editStudent } from '../../actions/students';
import { apiValidColorNames } from '../components/ColorSelect';

type Props = {
  addLevelClick: RecomposeHandler,
  addChildClick: RecomposeHandler,
  dispatch: ReduxFunction
};

/**
 * container to render CoursesActions and dispatches actions to show
 * creation modals for levels and children
 *
 * @method CoursesActionsContainer
 * @param  {function}  addLevelClick     - injected by `recompose` as a handler, to dispatch an action that will trigger a level creation modal
 * @param  {function}  addChildClick     - injected by `recompose` as a handler, to dispatch an action that will trigger a child creation modal
 *
 */
const CoursesActionsContainer = ({ addLevelClick, addChildClick }: Props) => {
  return (
    <CoursesActions
      addLevelClick={addLevelClick}
      addChildClick={addChildClick}
    />
  );
};

export const CoursesActionsContainerHandlers = {
  addLevelClick: ({ dispatch }: Props) => () => {
    dispatch(createLevel());
  },
  addChildClick: ({ dispatch }: Props) => () => {
    dispatch(editStudent({ color: apiValidColorNames[0] }));
  }
};

const CoursesActionsContainerComposed = compose(
  withHandlers(CoursesActionsContainerHandlers),
  pure
)(CoursesActionsContainer);

const mapDispatchToProps = (dispatch) => ({ dispatch });

const CoursesActionsContainerConnected = connect(
  null,
  mapDispatchToProps
)(CoursesActionsContainerComposed);

export default CoursesActionsContainerConnected;
