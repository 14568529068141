/* @flow */

import React from 'react';
import { Paper } from '../components';
import {
  SchoolDashboardNavContainer, SchoolDashboardHeadingContainer, HeaderContainer,
  CoursesSectionContainer, PageTitleContainer
} from '../containers';
import { StudentPdfSelection, StudentCommentsPdfSelection, StudentCaligrafixPdfSelection } from '../pdfview/StudentView';
import { CourseSelection } from '../pdfview/CourseView';

import { HierarchyReportPdf } from '../pdfview/Hierarchy';
import LicenseFilter from '../containers/LicenseFilter';
import topScroll from '../hocs/topScroll';
import { useTranslations } from '../hocs/TranslationsHelper';

/**
 * screen for the school courses
 *
 * @method SchoolProgress
 *
 */  

 const promoItems = [
    {id: "interactive_resources", pro: false},
    {id: "progress_analysis", pro: false},
    {id: "general_progress", pro: false},
    {id: "planifications", pro: false},
    {id: "progress_reports", pro: true},
    {id: "progress_reports_download", pro: true},
    {id: "progress_reports_cmp", pro: true},
    {id: "task_assignment", pro: true},
    {id: "curricular_coverage", pro: true},
    {id: "unlimited_profiles", pro: true},
    {id: "unlimited_registry", pro: true}, 
    {id: "planification_generator", pro: true} 

 ]
 const Checked = ({pro})=>(
   
    <div className="school-pro-promo-check">
        {
          (pro)?(

            <span className="school-pro-promo-check-pro">*</span>
          ):(

            <span className="school-pro-promo-check-free">{
              !pro && (<b>&#10003;</b>) 
            }</span>
          )

        }
    </div>)
const SchoolPremiumPromo = () => { 
  topScroll();
  const translations = useTranslations();

  return (
    <div id="school-dashboard" className="school-dashboard layout-base">
      <PageTitleContainer pageTitle="premium_benefits" />
      <HeaderContainer />  
      <div className="school-pro-promo-header">
          <div className="school-pro-promo-header-bg"></div>
          <h3 className="school-pro-promo-header-title">{translations.t("school_pro_promo_title")}</h3>
          <p className="school-pro-promo-header-sub">{translations.t("school_pro_promo_subtitle")}</p>
      </div>
      <div className="school-pro-promo-container">
        <div className="school-pro-promo-content">
            <table className="school-pro-promo-table">
                <tr>
                    <th></th>
                    <th className="school-pro-promo-table-free">{translations.t("school_pro_promo_free")}</th>
                    <th className="school-pro-promo-table-pro">{translations.t("school_pro_promo_premium")}<span className="premium-promo-header-crown"/></th>
                </tr>
                {
                    promoItems.map(({id,pro})=>(

                      <tr>
                          <td className="school-pro-promo-table-item">&#8226; {translations.t("school_pro_promo_"+id)}</td>
                          <td className="school-pro-promo-table-free"> 
                            <Checked pro={pro}/> 
                          </td>
                          <td className="school-pro-promo-table-pro"><Checked/></td>
                      </tr>

                    ))
                }
                <tr></tr>
            </table>
          <p className="school-pro-promo-disclamer">{translations.t("school_pro_promo_disclamer")}</p>

          <p className="school-pro-promo-footer"
            
            dangerouslySetInnerHTML={{ __html: translations.t("school_pro_promo_footer")}}
          /> 
        </div>
      </div>
    </div>
  );
};

export default SchoolPremiumPromo;
