/* @flow */

import React from 'react';
import { compose, lifecycle, shouldUpdate, withHandlers, withState } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Translations from '../hocs/TranslationsHelper';
import { CoursesCompareChart } from '../components';
import { courseProgressComparisonToLineChart } from '../../presenters/lineChart';
import deepEqualsUpdate from '../../helpers/deepEqualsUpdate';
import type { ReduxFunction } from '../components/types';
import { getLevelCourses } from '../../selectors/courses';
import _ from 'lodash';
import { withPdf } from '../../pdfer/Pdfable';

type Props = {
  dispatch: ReduxFunction,
  levelId: string,
  coursesProgressOverTime: Object,
  courses: Array<Object>,
  language: string,
  selectedOptions: string,
  translations: Object
};

/**
 * Container that receives the data to display statistics
 * of the student vs a course in a RadarChart
 * @method CoursesCompareChartContainer
 *
 * @param  {array}         currentStudentProgress - injected by Redux includes all intelligence_progress from the store for the current child
 * @param  {array}         currentCourseProgress - injected by Redux includes all intelligence_progress from the store for the current course
 */
export const CoursesCompareChartContainer = ({ coursesProgressOverTime, courses, language, selectedOption, onClickOption, translations, ...otherProps }: Props) => {
  const { data, linesData } = courseProgressComparisonToLineChart(coursesProgressOverTime, courses, language, selectedOption, translations);
  const loading = _.isEmpty(data);

  return (
    <CoursesCompareChart data={data} linesData={linesData} loading={loading} selectedOption={selectedOption} onClickOption={onClickOption} />
  );
};

export const CoursesCompareChartContainerHandlers = {
  fetchCoursesProgressOverTime: ({ dispatch, levelId, selectedOption }: Props) => () => {
    if (!levelId) {
      return;
    }

    const payload = {
      level: { id: levelId },
      group_by: selectedOption
    };
    dispatch({ type: 'REQUEST_COURSES_PROGRESS_OVER_TIME', payload });
  },
  onClickOption: (props: Object) => (selectedOption: 'month' | 'week' | 'day') => {
    props.onClickOption(selectedOption);
  }
};

export const CoursesCompareChartContainerLifecycle = {
  componentDidMount() {
    this.props.fetchCoursesProgressOverTime();
  },
  componentDidUpdate(prevProps) {
    const lastParam = prevProps.selectedOption;
    const nextParam = this.props.selectedOption;
    if (lastParam !== nextParam) {
      this.props.fetchCoursesProgressOverTime();
    }
  }
};

const ContainerTranslated = Translations(CoursesCompareChartContainer);

const ContainerComposed = compose(
  withState('selectedOption', 'onClickOption', 'day'),
  withHandlers(CoursesCompareChartContainerHandlers),
  shouldUpdate(deepEqualsUpdate),
  lifecycle(CoursesCompareChartContainerLifecycle)
)(ContainerTranslated);

const mapStateToProps = (state, ownProps) => {
  return {
    coursesProgressOverTime: state.courses_progress_over_time[ownProps.levelId],
    courses: getLevelCourses(state, ownProps.levelId),
    language: state.language
  };
};

const ContainerConnected = connect(
  mapStateToProps
)(ContainerComposed);

export default withRouter(ContainerConnected);


//pdf view  
const mapStateToProps2 = (state, {level_id,selectedOption}) => { 
  var levelId =level_id; 
  return {
    level_id: levelId,
    coursesProgressOverTime: state.courses_progress_over_time[levelId],
    courses: getLevelCourses(state, levelId),
    language: state.language,
    selectedOption: selectedOption
  };
};

const mapDispatchToProps = (dispatch) => { 
  return { 
    dispatch
  };
};


export const CoursesCompareChartPdf = connect(mapStateToProps2, mapDispatchToProps)(withPdf({props:{newpage:true}, path:"course.compare_chart", filter:"course_report", pre: ({dispatch, level_id,/*selectedOption,*/coursesProgressOverTime})=>{
 
  if(!coursesProgressOverTime){

    var selectedOption= 'month'; 
    const payload = {
      level: { id: level_id },
      group_by: selectedOption
    };
    dispatch({ type: 'REQUEST_COURSES_PROGRESS_OVER_TIME', payload });
    return true;
  }
  return false;
}})(ContainerTranslated));