/* @flow */

import React from 'react';
import Button from './Button';
import { compose, withHandlers, pure } from 'recompose';
import Translations from '../hocs/TranslationsHelper';
import type { RecomposeHandler } from '../components/types';
import commentsIcon from '../../img/ui_icon_comment_small.svg'
import RoleFilter from '../containers/RoleFilter';
type Props = {
  name: string,
  onEditButtonClick: () => mixed,
  onStudentClick: () => mixed,
  editButtonClickHandler: RecomposeHandler,
  translations: Object
};

/**
 * component to display some info for a student, and an edit button
 *
 * @method StudentInfoItem
 * @param  {string}    name                       - title for the header
 * @param  {function}  onEditButtonClick          - callback for the edit button
 * @param  {function}  onStudentClick             - callback for the edit button
 * @param  {function}  editButtonClickHandler     - injected by `recompose` as a handler, it calls onEditButtonClick
 * @param  {function}  onStudentClick              - an object containing translations
 *
 */
const StudentInfoItem = ({ name, comments_count, useButtons=true, onCommentsClick, onStudentClick, editButtonClickHandler, onPdfButtonClick, translations }: Props) => {
  return (
    <div className="student-info-item" onClick={onStudentClick}>
      <div className="student-info-item-left">
        <p className="student-info-item-name">
          <span className="student-info-item-icon pleiq-icon-icon_intelligence_intrapersonal" /> {name}
        </p>
      </div>
      <div className="student-info-item-right">
        
        {useButtons?(
          <div className="student-info-item-comments student-info-item-edit-button">
            
            {
              (comments_count>0)?[
                <img src={commentsIcon} key="icon"/>,
                <span key="count">{comments_count}</span>  
              ]:null
            }
            <Button type="edit" onClickButton={(e)=>{
              e.stopPropagation();
              onCommentsClick();
            }}>
              {
               translations.t('student_comments_short')
              }  
            
            </Button>
          </div>
        )
        :null
        }
        {
          (onPdfButtonClick && useButtons)?

            <Button type="edit" className="student-info-item-edit-button" onClickButton={(e)=>{
              e.stopPropagation();
              onPdfButtonClick();

            }}>
            PDF
          </Button>
          :null
        }
        {useButtons?
            <RoleFilter roles={['teacher','coordinator']}>
                    
              <Button type="edit" className="student-info-item-edit-button" onClickButton={editButtonClickHandler}>
                {translations.t('edit')}
              </Button>
            </RoleFilter>
        :null
        }
      </div>
    </div>
  );
};

export const StudentInfoItemHandlers = {
  editButtonClickHandler: ({ onEditButtonClick }: Props) => (event: Object) => {
    // this prevents `onStudentClick` from triggering
    event.stopPropagation();
    onEditButtonClick();
  }
};

const StudentInfoItemComposed = compose(
  withHandlers(StudentInfoItemHandlers),
  pure
)(StudentInfoItem);

export default Translations(StudentInfoItemComposed);
