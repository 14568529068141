/* @flow */

import { put, select, call, takeEvery, all } from 'redux-saga/effects';
import { stopSubmit } from 'redux-form';
import { workerRequest } from '../diplomatico/workers';
import type { Action } from '../diplomatico/types';
import { FORM_NAME as STUDENT_DATA_FORM } from '../js/components/StudentDataForm';
import { FORM_NAME as SCHOOL_STUDENTS_FORM } from '../js/components/SchoolStudentsForm';
import { addStudent, closeEditStudentModal, deleteStudent, updateStudent, setModalStudent } from '../actions/students';
import { setModalCourse, updateCourse } from '../actions/courses';
import { getCourseById } from '../selectors/courses';

/**
 * Worker to get all the students
 */
export const workerRequestAllStudentsOptions = {
  requestParams: {
    endpoint: `/school/students`,
    method: 'get'
  }
};

export const workerRequestAllStudents = workerRequest(workerRequestAllStudentsOptions);

/**
 * Worker to request students with a search param
 */
export const workerRequestSearchStudentsOptions = (query: string, page: number) => ({
  requestParams: {
    endpoint: `/school/students?q=${query}&page=${page}`,
    method: 'get',
    page
  }
});

export function* workerRequestSearchStudents(action: Action): any {
  let { query, page, clean } = action.payload;
  let workerRequestGenerator = workerRequest(workerRequestSearchStudentsOptions(query, page));
  let actionToSend = {
    type: clean ? 'REQUEST_SEARCH_STUDENTS_CLEAN' : action.type,
    payload: action.payload
  };

  yield call(workerRequestGenerator, actionToSend);
}

/**
 * Worker to get the students for current school
 */
const workerRequestStudentsOptions = (courseId: number) => ({
  requestParams: {
    endpoint:  ((courseId==null)?'/school/students/unassigned' : `/school/courses/${courseId}/students`),
    method: 'get'
  }
});

export function* workerRequestsStudents({ type, payload }: Action): any {
  const { course } = payload;
  const workerRequestGenerator = workerRequest(workerRequestStudentsOptions((course ||{id: null}).id));
  yield call(workerRequestGenerator, { type, payload });
}

/**
 * Worker to create a student for current school
 */
export const workerRequestStudentCreateOptions = {
  requestHandlers: {
    onSuccess: function* ({ action, response }: Object): any {
      const { course_id } = action.payload.student;
      const student = response.payload.body;

      if (course_id) {
        student.course_id = course_id;
        const course = yield select(getCourseById, course_id);
        yield put(updateCourse({ ...course, students_count: course.students_count + 1 }));
      }

      yield put(addStudent(student));
      yield put(setModalStudent(student));
      yield put(stopSubmit(STUDENT_DATA_FORM));
    },
    onError: function* ({ action, response }: Object): any {  
      yield put(stopSubmit(STUDENT_DATA_FORM, {_error: "license_count_exceded"}));
    }
  },
  requestParams: {
    endpoint: `/school/students`,
    method: 'post'
  },
  buildRequestBody: (actionPayload: Object) => actionPayload.student
};

export const workerRequestStudentCreate = workerRequest(workerRequestStudentCreateOptions);

/**
 * Worker to update a specific student for current school
 */
export const workerRequestStudentUpdateOptions = (studentId: string) => ({
  requestHandlers: {
    onSuccess: function* ({ action, response }: Object): any {
      const { original_course_id } = action.payload;
      const student = {
        ...action.payload.student,
        ...response.payload.body
      };
      const course_id = student.course_id;

      if (course_id) {
        const course = yield select(getCourseById, course_id);
        yield put(updateCourse({ ...course, students_count: course.students_count + 1 }));
      }

      if (original_course_id) {
        const originalCourse = yield select(getCourseById, original_course_id);
        yield put(updateCourse({ ...originalCourse, students_count: originalCourse.students_count - 1 }));
      }

      yield put(updateStudent(student));
      yield put(setModalStudent(student));
      yield put(stopSubmit(STUDENT_DATA_FORM));
    },
    onError: function* ({ action, response }: Object): any {
      yield put(stopSubmit(STUDENT_DATA_FORM));
    }
  },
  requestParams: {
    endpoint: `/school/students/${studentId}`,
    method: 'put'
  },
  buildRequestBody: (actionPayload: Object) => actionPayload.student
});

export function* workerRequestStudentUpdate({ type, payload }: Action): any {
  const { student } = payload;
  const workerRequestGenerator = workerRequest(workerRequestStudentUpdateOptions(student.id));
  yield call(workerRequestGenerator, { type, payload });
}

/**
 * Worker to change a course's associated level
 */
export const workerRequestStudentUpdateCourseOptions = (studentId: string) => ({
  requestHandlers: {
    onSuccess: function* ({ action, response }: Object): any {
      const { original_course_id } = action.payload;
      const course = {
        ...action.payload.course,
        students_count: action.payload.course.students_count + 1
      };
      const student = {
        ...response.payload.body,
        course_id: course.id
      };

      if (original_course_id) {
        const originalCourse = yield select(getCourseById, original_course_id);
        yield put(updateCourse({ ...originalCourse, students_count: originalCourse.students_count - 1 }));
      }

      yield put(updateCourse(course));
      yield put(setModalCourse(course));
      yield put(updateStudent({ ...student, course_id: course.id }));
      yield put(stopSubmit(SCHOOL_STUDENTS_FORM));
    },
    onError: function* ({ action, response }: Object): any {
      yield put(stopSubmit(SCHOOL_STUDENTS_FORM));
    }
  },
  requestParams: {
    endpoint: `/school/students/${studentId}`,
    method: 'put'
  },
  buildRequestBody: (actionPayload: Object) => actionPayload.student
});

export function* workerRequestStudentUpdateCourse({ type, payload }: Action): any {
  const { student } = payload;
  const workerRequestGenerator = workerRequest(workerRequestStudentUpdateCourseOptions(student.id));
  yield call(workerRequestGenerator, { type, payload });
}

/**
 * Worker to delete a student
 */
export const workerRequestStudentDeleteOptions = (studentId: string) => ({
  requestHandlers: {
    onSuccess: function* ({ action, response }: Object): any {
      const { student } = action.payload;
      yield put(deleteStudent(student));
      const course = yield select(getCourseById, student.course_id);

      if (course) {
        yield put(updateCourse({ ...course, students_count: course.students_count - 1 }));
      }

      yield put(stopSubmit(STUDENT_DATA_FORM));
      yield put(closeEditStudentModal());
    },
    onError: function* ({ action, response }: Object): any {
      yield put(stopSubmit(STUDENT_DATA_FORM));
      yield put(closeEditStudentModal());
    }
  },
  requestParams: {
    endpoint: `/school/students/${studentId}`,
    method: 'delete'
  },
  buildRequestBody: (actionPayload: Object) => ({})
});

export function* workerRequestStudentDelete({ type, payload }: Action): any {
  const { student } = payload;
  const workerRequestGenerator = workerRequest(workerRequestStudentDeleteOptions(student.id));
  yield call(workerRequestGenerator, { type, payload });
}

export function* watchStudentsRequest(): any {
  yield all([
    takeEvery('REQUEST_ALL_STUDENTS', workerRequestAllStudents),
    takeEvery('REQUEST_STUDENTS', workerRequestsStudents),
    takeEvery('REQUEST_SEARCH_STUDENTS', workerRequestSearchStudents),
    takeEvery('REQUEST_STUDENT_CREATE', workerRequestStudentCreate),
    takeEvery('REQUEST_STUDENT_UPDATE', workerRequestStudentUpdate),
    takeEvery('REQUEST_STUDENT_UPDATE_COURSE', workerRequestStudentUpdateCourse),
    takeEvery('REQUEST_STUDENT_DELETE', workerRequestStudentDelete)
  ]);
}
