import React, { useContext, useState } from 'react';
import { useData } from '../../hocs/DataRetriever';
import { Link } from 'react-router-dom';
import { formatProgressReportDate, formatProgressReportName } from './common';
import TranslationsHelper from '../../hocs/TranslationsHelper';


export const ProgressReportList = TranslationsHelper(  ({rdata,student, course, translations})=>{
   // const url = "/school/progress_reports?" + ((student)?("student_id="+student.id):("course_id="+course.id)) ;
    const {data  ,  loading} =rdata ;// useData({id: "school_progress_report_records", url});
 
    return <table className="progress-report-list"> 
        <tr className="">
            <th>{/*icon*/}</th>
            <th>{translations.t("date")}</th>
            <th>{translations.t("name")}</th>
            <th>{translations.t("created_by")}</th> 
            <th>{/*link*/}</th>
        </tr> 
        {
            data&& data.status<300 &&data.body && data.body.map(
                ({id, created_at, course, student, level, user_name}, index)=>  {
                    const formatedDate = formatProgressReportDate(created_at);
                    return <tr className={(index>0) && "progress-report-list-row"}>
                        <td><span class="pleiq-icon-icon_page"></span></td>
                        <td>{formatedDate}</td>
                        <td>{formatProgressReportName(student, course, level,created_at)}</td>
                        <td>{user_name}</td>
                        <td className="progress-report-list-button-container"> {<Link to={"/school/progress_reports/edit?record_id="+id} target="_blank" className="progress-report-list-button btn btn-edit">{translations.t("view")}</Link>}</td>
                    </tr>  
                }
                   // 
                 
            )
        } </table>;

});
export default ProgressReportList;