/* @flow */

import { enableInitializing } from 'initializable-reducer';
import { responseHasError } from '../diplomatico/reducers/helper';
import type { Action } from '../diplomatico/types';
import sortByName from '../helpers/sortByName';
import _ from 'lodash';

const initialState = [];

function courses(state: Array<Object> = initialState, { type, payload }: Action) {
  switch (type) {
    case 'RESPONSE_ALL_COURSES':
      if (responseHasError(payload)) {
        return state;
      }

      return sortByName(payload.body);
    case 'RESPONSE_COURSES':
      if (responseHasError(payload)) {
        return state;
      }

      const filteredState = state.filter((course) => {
        return !_.find(payload.body, { id: course.id });
      });

      return sortByName([ ...filteredState, ...payload.body ]);
    case 'ADD_COURSE':
      return sortByName([ ...state, payload.course ]);
    case 'UPDATE_COURSE':
      const newState = state.map((course) => {
        if (course.id === payload.course.id) {
          return { ...course, ...payload.course };
        }
        return course;
      });
      return sortByName(newState);
    case 'UPDATE_COURSE_STUDENTS_COUNT':
      return state.map((course) => {
        const courseStudents = payload.students.filter(student => student.course_id === course.id);
        return {
          ...course,
          students_count: course.students_count - courseStudents.length
        };
      });
    case 'REMOVE_COURSES':
      return state.filter((course) => {
        return !_.find(payload.courses, { id: course.id });
      });
    case 'DELETE_COURSE':
      return state.filter(course => course.id !== payload.course.id);
    case 'REMOVE_COURSES_LEVEL':
      return state.map((course) => {
        if (course.level_id === payload.level_id) {
          return { ...course, level_id: null, level_name: null };
        }
        return course;
      });
    case 'REMOVE_COURSES_ASSOCIATION':
      return state.map((course) => {
        if (_.find(payload.courses, { id: course.id })) {
          return { ...course, level_id: null, level_name: null };
        }
        return course;
      });
    default:
      return state;
  }
}

export {
  courses,
  initialState
};

export default enableInitializing(courses);
