/* @flow */

import React from 'react';
import { GeneralCaligrafixBookProgressContainer } from './';
import { pure, compose, lifecycle } from 'recompose';
import { connect } from 'react-redux'

/**
 * Container that receives the data to display all the progress
 * over each of the CaligrafixBooks
 * @method SchoolGeneralCaligrafixBookProgressContainer
 * @param  {array}               school_general_caligrafix_book_progress - injected by Redux includes all caligrafix_book_progress from the store for the current child
 * @param  {array}               caligrafix_books - injected by Redux includes all caligrafix_books from the store
 */
export const SchoolGeneralCaligrafixBookProgressContainer = ({user_books, school_general_caligrafix_book_progress, caligrafix_books }) => {
  return (
    <GeneralCaligrafixBookProgressContainer user_books={user_books} currentProgress={school_general_caligrafix_book_progress} caligrafixBooks={caligrafix_books} />
  );
};

export const SchoolGeneralCaligrafixBookProgressContainerLifecycle = {
  componentDidMount() {
    const { dispatch } = this.props;

    dispatch({ type: "REQUEST_SCHOOL_GENERAL_CALIGRAFIX_BOOK_PROGRESS" });
  }
};

const SchoolGeneralCaligrafixBookProgressContainerComposed = compose(
  lifecycle(SchoolGeneralCaligrafixBookProgressContainerLifecycle),
  pure
)(SchoolGeneralCaligrafixBookProgressContainer);

const mapStateToProps = ({user, school_general_caligrafix_book_progress, caligrafix_books }, ownProps) => {
 
  return {
    user_books: user.caligrafix_books,
    school_general_caligrafix_book_progress,
    caligrafix_books
  }
};

const mapDispatchToProps = (dispatch) => ({ dispatch });

const CaligrafixBookProgressContainerConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(SchoolGeneralCaligrafixBookProgressContainerComposed);

export default CaligrafixBookProgressContainerConnected;
