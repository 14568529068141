/* @flow */

import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import _ from 'lodash';
import Translations from '../hocs/TranslationsHelper';

/**
 * This component sets the title of the screen
 * It uses [react-helmet](https://github.com/nfl/react-helmet)
 *
 * We generate titles based on the account type and appending the screen title
 *
 * @param {string} pageTitle        - The current page string to complete the title
 */
const PageTitleContainer = ({ pageTitle, account_type, translations }) => {
  const accountTypeLabel = account_type === 'School' ? 'PleIQ School' : 'PleIQ';
  const title = `${accountTypeLabel} - ${translations.t(pageTitle)}`;

  return (
    <Helmet>
      <title>{title}</title>
    </Helmet>
  );
};

const mapStateToProps = ({ user }) => {
  return {
    account_type: _.get(user, 'account_type')
  };
};

const ContainerConnected = connect(
  mapStateToProps
)(Translations(PageTitleContainer));

export default ContainerConnected;
