/* @flow */

import _ from 'lodash';

export const responseHasError = (response: { status: number, errors: Object, timeout: boolean }) => {
  return (
    !_.isEmpty(response.errors) ||
    /*
      prevent error codes (>= 400) and redirection or not modified codes (>=300)
      have any effect within the application state
     */
    (response.status >= 300) ||
    response.timeout
  );
};
