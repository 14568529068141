/* @flow */

import React from 'react';
import { IntelligenceProgressAnalisisContainer } from './';
import { pure, compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getAllIntelligenceProgressByChild } from '../../selectors/intelligenceProgress';

/**
 * Container that receives the data to display the estadistics
 * of the user to be shown within simple cards
 * @method DataDisplayContainer
 *
 * @param  {array}         currentStudentProgress - injected by Redux includes all intelligence_progress from the store for the current child
 * @param  {array}         intelligences - injected by Redux includes all intelligences from the store
 */
export const DataDisplayStudentContainer = ({ currentStudentProgress, intelligences }) => {
  return (
    <IntelligenceProgressAnalisisContainer currentProgress={currentStudentProgress} intelligences={intelligences} />
  );
};

const ContainerComposed = compose(
  pure
)(DataDisplayStudentContainer);

const mapStateToProps = ({ student_intelligence_progress, intelligences }, ownProps) => {
  const { params } = ownProps.match;
  const { student_id } = params;

  const currentStudentProgress = getAllIntelligenceProgressByChild({ intelligence_progress: student_intelligence_progress }, student_id);

  return {
    currentStudentProgress,
    intelligences
  }
};

const ContainerConnected = connect(
  mapStateToProps
)(ContainerComposed);

export default withRouter(ContainerConnected);

const mapStateToProps2 = ({ student_intelligence_progress, intelligences }, ownProps) => { 
  const { student_id } = ownProps;

  const currentStudentProgress = getAllIntelligenceProgressByChild({ intelligence_progress: student_intelligence_progress }, student_id);

  return {
    currentStudentProgress,
    intelligences
  }
};


export const DataDisplayStudentPdf = connect(mapStateToProps2)(ContainerComposed);
