/* @flow */

import _ from 'lodash';

const customizer = (objValue, srcValue) => {
  if (_.isArray(objValue)) {
    return srcValue;
  }
};

/*
 * In some cases we want merge to replace nested arrays
 * https://stackoverflow.com/a/33247597/2443687
 * https://lodash.com/docs/4.17.4#mergeWith
 *
 * @example
 *
 * const foo = {
 *  a: [1, 2]
 * };
 *
 * const bar = {
 *  a: [3]
 * };
 *
 * mergeWithReplace({}, [foo, bar])
 * => { a: [3] }
 */
export const mergeWithReplace = (object, sources) => {
  return _.mergeWith(object, ...sources, customizer);
};

export default mergeWithReplace;
