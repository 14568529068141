/* @flow */

import { call, takeEvery, all } from 'redux-saga/effects';
import { workerRequest } from '../diplomatico/workers';
import type { Action } from '../diplomatico/types';

/**
 * Worker to handle the request of Intelligence Progress
 */
export const workerRequestIntelligenceProgressOptions = (child_id: string) => ({
  requestParams: {
    endpoint: `/children/${child_id}/intelligence_scores/current`,
    method: 'get',
    child_id
  }
});

export function* workerRequestIntelligenceProgress(action: Action): any {
  let { child_id } = action.payload;
  let workerRequestGenerator = workerRequest(workerRequestIntelligenceProgressOptions(child_id));

  yield call(workerRequestGenerator, action);
}

export function* watchIntelligenceProgressRequest(): any {
  yield all([
    takeEvery('REQUEST_INTELLIGENCE_PROGRESS', workerRequestIntelligenceProgress)
  ]);
}
