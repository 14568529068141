import React from "react";
import { compose, lifecycle } from "recompose";
import { throttle } from "lodash";

 
export class FillHeightDown extends React.PureComponent {

    constructor(props) {
        super(props)
        this.state = {height: null}
    }
    componentDidMount () {
      this.updateHeight()
      window.addEventListener("resize", this.updateHeight  )
      window.addEventListener("scroll",  this.updateHeight  )
    }
  
    componentWillUnmount () {
      window.removeEventListener("resize", this.updateHeight )
      window.addEventListener("scroll",  this.updateHeight )
    }
  
    updateHeight = () => {
      if(!this.wrapper)return;
      this.setState({height:  window.innerHeight - this.wrapper.getBoundingClientRect().top})
    }
  
    render() {
        return (
          <div  ref={(ref) => this.wrapper = ref} className={this.props.className} style={{height: this.state.height }}> 
          {
              this.props.children
          }
          </div>
        )
    }

}  
export class FillHeight extends React.PureComponent {

    constructor(props) {
        super(props)
        
        this.state = {height: null}
    }
    componentDidMount () {
      this.updateHeight()
      window.addEventListener("resize", this.updateHeight  ) 
    }
  
    componentWillUnmount () {
      window.removeEventListener("resize", this.updateHeight ) 
    }
  
    updateHeight = () => { 
      this.setState({height:  window.innerHeight  })
    }
  
    render() {
        return (
          <div  ref={(ref) => this.wrapper = ref} className={this.props.className} style={{height: this.state.height }}> 
          {
              this.props.children
          }
          </div>
        )
    }

} 

export class Collapser extends React.PureComponent {

    constructor(props) {
        super(Object.assign({maxWidth:200},props));
        this.state = {collapsed: true, shouldCollapse:false}
    }
    componentDidMount () {
      this.updateHeight()
      window.addEventListener("resize", this.updateHeight  ) 
    }
  
    componentWillUnmount () {
      window.removeEventListener("resize", this.updateHeight ) 
    }
  
    updateHeight = () => { 
        this.setState({shouldCollapse:  window.innerWidth < this.props.maxWidth , collapsed: (this.state.shouldCollapse)?this.state.collapsed:true }) 
    }
  
    render() {
        const {shouldCollapse, collapsed } = this.state;
        return (
          <div  ref={(ref) => this.wrapper = ref} className={this.props.className} style={{height: this.state.height }}> 
          {
              (!shouldCollapse || !collapsed)?
              this.props.children: null
          }
          {
              (shouldCollapse)?( 
                <div className={this.props.barClassName} onClick={()=>this.setState({collapsed:!collapsed})}>  
                    <span>{this.props.title}</span>
                    <span className={`pleiq-icon-icon_arrow_mini_${(collapsed)?"down":"up"}`} /> 
                </div> 
              ):null
          }
          </div>
        )
    }

}