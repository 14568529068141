/* @flow */

import React from 'react';
import { connect } from 'react-redux';
import { compose, lifecycle, pure, withHandlers } from 'recompose';
import { startSubmit, arrayPush, arrayRemove } from 'redux-form';
import { SchoolStudentsForm } from '../components/';
import { FORM_NAME } from '../components/SchoolStudentsForm';
import { getCourseStudents, getAllStudentsButFromCourse } from '../../selectors/students';
import type { Course, ReduxFunction } from '../components/types';
import getNewAndUpdated from '../../helpers/getNewAndUpdated';
import { apiValidColorValues, mapColorStringToHex } from '../components/ColorSelect';
import { studentsSearch } from '../../actions/students';
import { getStudents } from '../../actions/students';
import prepareForNullify from '../../helpers/prepareForNullify';
import _ from 'lodash';

type Props = {
  course: Course,
  dispatch: ReduxFunction,
  initialValues: Object
};

const SchoolStudentsFormContainer = (props: Props) => {
  return <SchoolStudentsForm {...props} />;
};

/**
 * This function will dispatch an action to search for students, it's debounced
 * so it doesn't get called too often
 *
 * @method dispatchStudentsSearch
 * @param  {function}  dispatch     - dispatch from redux
 * @param  {string}    query        - to search students
 */
export const dispatchStudentsSearch = _.debounce((dispatch, query) => {
  dispatch({ type: 'INIT_STUDENTS_SEARCH' });
  dispatch(studentsSearch({ query, page: 0, clean: true }));
}, 250);

export const SchoolStudentsFormContainerHandlers = {
  onSubmit: ({ dispatch, initialValues, course }: Props) => (values: Object) => {
    const removedStudents = values.removedStudents.map(prepareForNullify);
    const newCourseStudents = values.children;
    const oldCourseStudents = initialValues.children;
    const { newValues, updatedValues } = getNewAndUpdated(oldCourseStudents, newCourseStudents);
    const otherCourseStudents = values.children.filter(student => (student.id && student.course_id !== course.id));
    const students = [...newValues, ...otherCourseStudents, ...updatedValues, ...removedStudents];
    const payload = {
      course: {
        students_attributes: students.map(student => ({ ...student, color: apiValidColorValues[student.color] }))
      },
      removedStudents,
      otherCourseStudents,
      newStudentsCount: newValues.length + otherCourseStudents.length,
      course_id: course.id
    };

    dispatch(startSubmit(FORM_NAME));
    dispatch({ type: 'REQUEST_UPDATE_COURSE_STUDENTS', payload });
  },
  changeStudentCourse: ({ dispatch }: Props) => (selectOption: Object) => {
    const student = selectOption.value;
    dispatch(arrayPush(FORM_NAME, 'children', mapColorStringToHex(student)));
  },
  onRemoveStudent: ({ dispatch, course }: Props) => (student: Object, index: mixed) => {
    const persisted = student.id;
    dispatch(arrayRemove(FORM_NAME, 'children', index));

    if (persisted && student.course_id === course.id) {
      dispatch(arrayPush(FORM_NAME, 'removedStudents', student));
    }
  },
  onSearchBlur: ({ dispatch }: Props) => () => {
    dispatch({ type: 'CLEAN_STUDENTS_SEARCH' });
  },
  handleSearch: ({ dispatch }: Props) => (value: string) => {
    if (value) {
      dispatchStudentsSearch(dispatch, value);
    }

    // we return the value as react-select needs it to update the input with it
    return value;
  }
};

export const SchoolStudentsFormContainerLifecycle = {
  componentDidMount() {
    const { dispatch, course } = this.props;
    // ensure that the search is cleaned up when this component gets mounted
    dispatch({ type: 'CLEAN_STUDENTS_SEARCH' });
    // also ensure we have the students for this course
    dispatch(getStudents(course));
  }
};

const SchoolStudentsFormContainerComposed = compose(
  withHandlers(SchoolStudentsFormContainerHandlers),
  lifecycle(SchoolStudentsFormContainerLifecycle),
  pure
)(SchoolStudentsFormContainer);

const mapStateToProps = (state, ownProps) => {
  const searchStudents = state.students_search.students;
  const otherStudents = getAllStudentsButFromCourse({ students: searchStudents }, ownProps.course);
  const courseStudents = getCourseStudents(state, ownProps.course.id);

  return {
    otherStudents: otherStudents,
    searchLoading: state.students_search.loading,
    initialValues: {
      children: courseStudents.map(mapColorStringToHex),
      removedStudents: []
    }
  };
};

const mapDispatchToProps = (dispatch) => ({ dispatch });

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SchoolStudentsFormContainerComposed);
