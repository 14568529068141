/* @flow */

import React from 'react'; 
import { Label, Form, FormGroup,FormFeedback } from 'reactstrap';
import { FieldArray, reduxForm } from 'redux-form';
import { UserChildFormArray, Dropdown, SubmitButton } from './';
import Translations from '../hocs/TranslationsHelper';
import classNames from 'classnames';
import type { Student, TranslationsObject } from './types';

export function buildSelectOptions(students: Array<Student>, translations: TranslationsObject) {
  return students.map((student) => {
    const coursePart = student.course_name ? `${translations.t('course')} ${student.course_name}` : `${translations.t('unassigned')}`;
    return {
      value: student,
      label: `${translations.t('student')} ${student.name} - ${coursePart}`
    };
  });
}

const SchoolStudentsForm = ({error, changeStudentCourse, searchLoading, onSearchBlur, onRemoveStudent, otherStudents, handleSearch, submitting, valid, dirty, handleSubmit, translations }) => {
  const selectOptions = buildSelectOptions(otherStudents, translations);
  const formClassName = classNames({ 'has-danger': error }, 'form', "school-courses-form");
  return (
    <Form className={formClassName} onSubmit={handleSubmit}>
      <FormGroup>
        <Label>{translations.t('add_existing_student')}</Label>
        <Dropdown
          options={selectOptions}
          placeholder={translations.t('pick_a_student')}
          onChange={changeStudentCourse}
          onBlur={onSearchBlur}
          disabled={submitting}
          onInputChange={handleSearch}
          isLoading={searchLoading}
          noResultsText={false}
         // this prevents react-select to try to do any option filtering,
         // as we already handle the search
          filterOption={() => true}
          searchable
        />
      </FormGroup>
      <Label>{translations.t('edit_course_students')}</Label>
      <FieldArray name="children" component={UserChildFormArray} onRemoveChild={onRemoveStudent} studentMode />
     
      { error &&
          <FormGroup>
            <FormFeedback>{translations.t(error)}</FormFeedback>
          </FormGroup>}
      <SubmitButton loading={submitting} className="btn btn-primary--green" disabled={!valid || !dirty}>
        {translations.t('save')}
      </SubmitButton>
    </Form>
  );
};

export const FORM_NAME = 'schoolStudentsForm';

export default reduxForm({
  form: FORM_NAME,
  enableReinitialize: true
})(Translations(SchoolStudentsForm));
