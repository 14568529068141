/* @flow */

import React from 'react';
import { compose, lifecycle, shouldUpdate } from 'recompose';
import { getIntelligenceBySlug } from '../../selectors/intelligences';
import { getAchievementCardsByIntelligence } from '../../selectors/achievementCards';
import { getSchoolGeneralAchievementCardsProgress } from '../../selectors/schoolProgress';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import actions from '../../actions';
import deepEqualsUpdate from '../../helpers/deepEqualsUpdate';
import { CardProgressContainer } from './';
import {  withPdfHandlers } from '../../pdfer/Pdfable';
import { CardProgressContainerPdf } from './CardProgressContainer';
 
/**
 * Container to wrap a collection of SchoolGeneralAchievementCardProgressContainer elements
 * @method SchoolGeneralAchievementCardProgressContainer
 */
const SchoolGeneralAchievementCardProgressContainer = ({ achievementCards, achievementCardScores }) => {
  return (
    <CardProgressContainer achievementCards={achievementCards} achievementCardScores={achievementCardScores} />
  );
};

const dispatchGetData = ({ intelligence_id, language, dispatch }) => {
  if (intelligence_id) {
    dispatch(actions.getAchievementCards({
      intelligence_id,
      language
    }));

    dispatch(actions.getSchoolGeneralAchievementCardsProgress({
      intelligence_id
    }));
  }
};

export const ContainerLifecycle = {
  componentDidUpdate(prevProps) {
    const languageChanged = prevProps.language !== this.props.language;
    const intelligenceChanged = prevProps.intelligence_id !== this.props.intelligence_id;

    if (languageChanged || intelligenceChanged) {
      dispatchGetData(this.props);
    }
  },
  componentDidMount() {
    dispatchGetData(this.props);
  }
};

const ContainerComposed = compose(
  lifecycle(ContainerLifecycle),
  shouldUpdate(deepEqualsUpdate)
)(SchoolGeneralAchievementCardProgressContainer);

const mapStateToProps = (state, ownProps) => {
  const { school_general_achievement_cards_progress, language } = state;
  const { slug } = ownProps.match.params;

  const currentLanguage = language || 'es';
  const intelligence = getIntelligenceBySlug(state, slug);

  const achievementCards = getAchievementCardsByIntelligence(state, intelligence.id, currentLanguage);
  const achievementCardScores = getSchoolGeneralAchievementCardsProgress({ school_general_achievement_cards_progress }, intelligence.id);

  return {
    intelligence_id: intelligence.id,
    language,
    achievementCards,
    achievementCardScores
  };
};

const mapDispatchToProps = (dispatch) => ({ dispatch });

const ContainerConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(ContainerComposed);

export default withRouter(ContainerConnected);
 
  
export var SchoolGeneralAchievementCardProgressContainerPdf = connect(mapStateToProps ,mapDispatchToProps )(  withPdfHandlers ({path:"acc.", filter: "challenges_report"
, pre:(props)=>{ 

  dispatchGetData(props);
  return true; 
}, props:{newpage:true}  })(({intelligence_id,  achievementCards,achievementCardScores})=>{  
    return  <CardProgressContainerPdf id={intelligence_id} achievementCards={achievementCards} achievementCardScores={achievementCardScores}/>  
}));

export var SchoolGeneralAchievementCardProgressContainerPdfWithRouter =  withRouter(SchoolGeneralAchievementCardProgressContainerPdf);