import moment from "moment";

export function formatProgressReportDate(created_at){ 
    const format = 'YYYY-MM-DD-HH:mm';
    return moment(created_at).format(format);
}
export function formatName(name){
    return name.replace(/\s+/g, "-").replace(/[^\wá-úä-üÀ-ÙÄ-Ü]+/g, "");///[^\p{L}\d.]+/g, "-");
}
function simplifyName(name){
    var s = name.split(" ");
    if(s.length ==0)return "";
    else if(s.length ==1) return s[0];
    else return s[0]+ s[1];
}
export function formatProgressReportName(student, course, level, date){
    return formatProgressReportDate(date) + (level? "_"+formatName(level.name): "") + (course? "_"+formatName(course.name): "") + (student? "_"+simplifyName(student.name): "");

}