/* @flow */

import { responseHasError } from '../diplomatico/reducers/helper';
import type { Action } from '../diplomatico/types';
import mergeWithReplace from '../helpers/mergeWithReplace';
import { enableInitializing } from 'initializable-reducer';

const initialState = [];

function lessonPlanFilters(state: Object = initialState, { type, payload }: Action) {
  switch (type) {
/*     case 'REQUEST_ACHIEVEMENT_CARDS':

      const { intelligence_id, language } = payload.request; 
      const newAchievementCard = {
        [intelligence_id]: {
          [language]:  []
        }
      }; 
      return mergeWithReplace({}, [state, newAchievementCard])
     */
    
    case 'RESPONSE_LESSON_PLAN_FILTERS': 
      return  payload.body
    default:
      return state;
  }
}

export {
  lessonPlanFilters,
  initialState
};

export default lessonPlanFilters;// enableInitializing(lessonPlanFilters);
