/* @flow */

import React from 'react';
import classNames from 'classnames';

type PaperNavProps = {
  iconClass?: string,
  iconSrc?: string,
  className?: string,
  label: string,
  active?: boolean,
  onClick: () => mixed
};

type PaperContainerProps = {
  className: string,
  children?: React.Component<*>,
  lead?: boolean,
  /*
    narrow option is siggested for displaying the paper without topic container above
    see the components--paper.scss file to know more
   */
  narrow?: boolean,
  /*
   * the floating option behaves just like a lead container but without applying any
   * negative margins
   */
  floating?: boolean,
};

type Props = {
  children?: React.Component<*>,
  className: string
};

const PaperNavItemImage = ({ iconSrc }) => (
  <span className="paper-nav-image">
    <img src={iconSrc} alt="nav-indicator" />
  </span>
);

const PaperNavItemIcon = ({ iconClass }) => (
  <span className={iconClass} />
);

export const PaperNavItem = ({ iconClass, label, iconSrc, active, className, onClick, hideLabelSm, ...otherProps }: PaperNavProps) => {
  const paperNavClass = classNames('paper-nav-item', { active }, className);
  const paperNavLabelClass = classNames('paper-nav-label', { 'hidden-sm-down': hideLabelSm });

  return (
    <div className={paperNavClass} onClick={onClick} {...otherProps}>
      <div className="inner-holder">
        {
          iconSrc ? <PaperNavItemImage iconSrc={iconSrc} />
          : <PaperNavItemIcon iconClass={iconClass} />
        }
        <label className={paperNavLabelClass}>{' '}{label}</label>
      </div>
    </div>
  );
};

/**
 * Use this to create a divider that will contain
 * at least one <PaperNavItem> to give context to a group of
 * elements or toggle the content within.
 *
 * @method PaperNav
 *
 * @example
 * <PaperNav>
 *   <PaperNavItem iconClass="icon-foo" label="foo">
 * </PaperNav>
 */
export const PaperNav = ({ children, className }: { children: React.Component<*>, className: mixed }) => {
  const paperNavClassName = classNames('paper-nav d-flex justify-content-between align-items-center', className);
  return createElement({ className: paperNavClassName, children });
};

export const PaperContainer = ({ children, lead, narrow, floating, className }: PaperContainerProps) => {
  let paperClassName = classNames('paper', { lead, narrow, floating }, className);

  return (
    <div className={paperClassName}>
      <div className="paper-padder">
        <div className="paper-container">
          {children}
        </div>
      </div>
    </div>
  );
};

export const PaperSection = ({ className, children }: Props) => {
  let elementClass = classNames('paper-section', className);
  return createElement({ className: elementClass, children });
};

export const PaperHeading = ({ className, children }: Props) => {
  let elementClass = classNames('paper-heading', className);
  return createElement({ className: elementClass, children });
};

export const PaperTitle = ({ className, children }: Props) => {
  let elementClass = classNames('paper-title', className);
  return createElement({ className: elementClass, children });
};

export const PaperContent = ({ className, children }: Props) => {
  let elementClass = classNames('paper-content', className);
  return createElement({ className: elementClass, children });
};

const createElement = ({ className, children }) => {
  return React.createElement('div', { className }, children);
};

/**
 * This component is inspired on http://www.material-ui.com/#/components/paper
 * and it is intended to be used as a simple set of containers for other elements
 * in order to render a valid group of matrerial cards. Paper implementation can
 * be as simple or as complex as it is needed.
 *
 * @example
 * <Paper.Container>
 *  <p>Hello world!</p>
 * </Paper.Container>
 *
 * @example
 * <Paper.Container lead>
 *   <Paper.Title><h1>Title</h1></Paper.Title>
 *   <Paper.Section>
 *     <Paper.Heading><h2>Section Heading 1</h2></Paper.Heading>
 *     <Paper.Content>
 *       <p>Content 1</p>
 *     </Paper.Content>
 *   </Paper.Section>
 *   <Paper.Section>
 *     <Paper.Heading><h2>Section Heading 2</h2></Paper.Heading>
 *     <Paper.Content>
 *       <p>Content 2</p>
 *     </Paper.Content>
 *   </Paper.Section>
 * </Paper.Container>
 *
 * @example
 * <Paper.Container lead narrow>
 *   <Row>
 *     <Col>
 *       <Paper.Title>
 *         <h1>Title 1</h1>
 *       </Paper.Title>
 *     </Col>
 *     <Col>
 *       <Paper.Title>
 *         <h1>Title 2</h1>
 *       </Paper.Title>
 *     </Col>
 *   </Row>
 *   <Row>
 *     <Col>
 *       <Paper.Content>
 *         <p>Content 1</p>
 *       </Paper.Content>
 *     </Col>
 *     <Col>
 *       <Paper.Content>
 *         <p>Content 2</p>
 *       </Paper.Content>
 *     </Col>
 *   </Row>
 * </Paper.Container>
 */
const Paper = {
  Container: PaperContainer,
  Content: PaperContent,
  Heading: PaperHeading,
  Nav: PaperNav,
  NavItem: PaperNavItem,
  Title: PaperTitle,
  Section: PaperSection
};

export default Paper;
