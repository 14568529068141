/* @flow */

import _ from 'lodash';

export const getSchoolProgressByCourse = ({ progress }, level_id) => (_.get(progress, [level_id], {}));

export const getSchoolProgressByIntelligence = ({ progress }, intelligence_id) => (_.get(progress, [intelligence_id], {}));

export const getSchoolGeneralIntelligenceHistory = ({ history }, intelligence_id) => (_.get(history, [intelligence_id], null));

export const getSchoolGeneralAchievementCardsProgress = ({ school_general_achievement_cards_progress }, intelligence_id) => (_.get(school_general_achievement_cards_progress, [intelligence_id], []));


export const getSchoolProgressByCaligrafixBook = ({ progress }, caligrafix_book_id) => (_.get(progress, [caligrafix_book_id], {}));
