/* @flow */

import React from 'react';
import { FormGroup, Label, FormFeedback } from 'reactstrap';
import InputRadio from './InputRadio';
import Translations from '../hocs/TranslationsHelper';

/**
 * this component is supposed to be used with the Field component
 * from redux-form, passing this as the component prop.
 * This component only renders a checkbox
 *
 * NOTE: The normalize props should be passed in to ensure that
 * the value always is a boolean, and not an empty string on false
 *
 * @example
 * <Field
 *   type="checkbox"
 *   name="some_name"
 *   label="some label"
 *   disabled={submitting}
 *   component={InputFieldRadio}
 *   normalize={(v) => _.isBoolean(v) ? v : false}
 * />
 */
const InputFieldRadio = ({ className, translations, disabled, ...otherProps }) => {
  const { input, label, meta } = otherProps;
  const { error } = meta;
  const inputParam = {
    name: input.name,
    onChange: input.onChange,
    value: input.value
  }
  return (
    <FormGroup disabled={disabled} className={className}>
      <Label>
        <InputRadio {...inputParam} disabled={disabled} />{' '}
        <span className={"form-radio-label-text " +className}>
          {label}
        </span>
      </Label>
      {error && <FormFeedback>{translations.t(error)}</FormFeedback>}
    </FormGroup>
  );
};

export default Translations(InputFieldRadio);
