/* @flow */

/**
 * A class to wrap all the assets needed whenever a Feed
 * of this type need to be implemented check @link{Feed} documentation
 * to know more about it.
 *
 * @class ActivityFeed
 *
 * @example
 * <Feed.Group>
 *   <Feed.GroupItem data={{ ...ActivityFeed.TYPES.BEST_TIME, subheading: 'Reto Linguistico', time: 'Hace 20 min' }} />
 *   <Feed.GroupItem data={{ ...ActivityFeed.TYPES.CHALLENGE_COMPLETED, subheading: 'Letras Mayusculas', time: 'Hace 1 hora' }} />
 *   <Feed.GroupItem data={{ ...ActivityFeed.TYPES.REWARD, subheading: 'Reto Linguistico', time: 'Hace 3 horas' }} />
 * </Feed.Group>
 */

import challenge_completed_icon from '../../img/icon_challenge_general.png';
import best_time_icon from '../../img/icon_clock.png';
import reward_icon from '../../img/icon_coin.png';

class ActivityFeed {
  static ICONS = {
    CHALLENGE_COMPLETED: challenge_completed_icon,
    BEST_TIME: best_time_icon,
    REWARD:reward_icon
  };

  static TYPE_CHALLENGE_COMPLETED = {
    heading: 'challenge_completed',
    icon: ActivityFeed.ICONS.CHALLENGE_COMPLETED
  };

  static TYPE_CHALLENGE_BEST_TIME = {
    heading: 'best_time',
    icon: ActivityFeed.ICONS.BEST_TIME
  };

  static TYPES = {
    CHALLENGE_COMPLETED: ActivityFeed.TYPE_CHALLENGE_COMPLETED,
    BEST_TIME: ActivityFeed.TYPE_CHALLENGE_BEST_TIME
  };
}

export default ActivityFeed;
