/* @flow */

import _ from 'lodash';

export const dismissAlert = (index: number = 0) => {
  return {
    type: 'ALERT_DISMISS',
    payload: {
      index
    }
  }
}

export const addAlert = ({ message, type, dismissible }: Object) => {
  const defaults = {
    type: 'error',
    dismissible: true
  };

  const payload = _.defaults({
    message,
    type,
    dismissible
  }, defaults);

  return {
    type: 'ALERT_ADD',
    payload
  }
}
