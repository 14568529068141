/* @flow */

import {call, take,  fork, select, put, takeEvery, all } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { loggedIn, logout, localLogout } from '../actions/user';
import { addAlert } from '../actions/alerts';
import { getAuthorization } from '../diplomatico/selectors/authorization';
import { workerRequest , workerResponseHandler} from '../diplomatico/workers';
import { stopSubmit, reset } from 'redux-form';
import { FORM_NAME as SIGNUP_FORM_NAME } from '../js/components/ParentSignUpForm';
import { FORM_NAME as SIGNIN_FORM_NAME } from '../js/components/SignInForm';
import { FORM_NAME as DELETE_FORM_NAME } from '../js/components/DeleteAccountForm';
import { FORM_NAME as USER_PASSWORD_RESET_FORM_NAME } from '../js/components/UserPasswordResetForm';
import { FORM_NAME as USER_PASSWORD_NEW_FORM_NAME } from '../js/components/UserPasswordNewForm';
import type { Action } from '../diplomatico/types';
import _ from 'lodash'; 
import { request } from '../diplomatico/actions/request';

/**
 * Worker to handle the logout process
 */
export const workerRequestUserLogoutOptions = {
  requestParams: {
    endpoint: '/users/logout',
    method: 'delete',
    reportable: false
  },
  // return undefined as we should not pass parameters to this endpoint
  buildRequestBody: (actionPayload: Object) => undefined
};
export const workerRequestUserLogoutApprenderOptions = {
  requestParams: {
    endpoint: '/aprender/logout',
    method: 'post',
    reportable: false
  },
  // return undefined as we should not pass parameters to this endpoint
  buildRequestBody: (actionPayload: Object) => actionPayload
};

export function* workerRequestUserLogout(action: Action): any {
  /*
    Prevent that for any reason where are trying to make a logout
    without having a session active the diplomatico will enter in
    a infinite loop cause logout needs authorization in order to work
  */
  let inSession = yield select(getAuthorization);

  if (_.isEmpty(inSession)) {
    console.warn('Logout has been requested but no session is active');
    return;
  }

  let state = yield select((state) => state );
  let apprenderSid = yield select((state) => state.user.apprender_sid); 

  // If we request a logout, we need to locally clear user and authorization immediately
  if(apprenderSid){
    action = {...action, payload:{device_id: apprenderSid}}; 
  } 

  let workerRequestGenerator = (apprenderSid)?
      workerRequest(workerRequestUserLogoutApprenderOptions) 
      : workerRequest(workerRequestUserLogoutOptions);


  // We are using fork here to not block the UI waiting for a response
  // call does exactly that
  yield fork(workerRequestGenerator, action);

  yield put(localLogout());
}

/**
 * Worker to handle the login process
 */
export const workerRequestUserLoginOptions = {
  requestHandlers: {
    onError: function* ({ action, response }: Object): any {
      switch (response.payload.status) {
        case 401:
          yield put(stopSubmit(SIGNIN_FORM_NAME, { _error: 'cant_create_session' }));
          break;
        case 404:
          yield put(stopSubmit(SIGNIN_FORM_NAME, { _error: 'invalid_credentials' }));
          break;
        default:
          return;
      }
    },
    onSuccess: function* ({ action, response }: Object): any {
      let responseBody = response.payload.body;
      let { user } = responseBody;
      let { account_type } = user;

      if(user.activated && user.expired){

        yield put(stopSubmit(SIGNIN_FORM_NAME, { _error: 'license_expired' }));
        return;
      }

      if (user.role === 'unique_account') {
        yield put(stopSubmit(SIGNIN_FORM_NAME, { _error: 'unauthorized_account_role' }));
        return;
      }

      yield put(loggedIn({ ...response.payload }));

      if (user.children_count && user.activated) {
        yield put({ type: 'APPLICATION_INIT', payload: { account_type } });
      }
    }
  },
  requestParams: {
    endpoint: '/users/login',
    method: 'post',
    reportable: false,
    authorizable: false
  },
  buildRequestBody: (actionPayload: Object) => actionPayload
};

export const workerRequestUserLogin = function* workerRequestGenerator(action) {
  console.log('init saga');
  
  const { buildRequestBody, requestParams, requestHandlers } = workerRequestUserLoginOptions;

  const actionPayload = action.payload;
  let payload = {
    request: {
      endpoint: '/users/login',
      method: 'post',
      reportable: false,
      authorizable: false
    }
  };
  
  payload.body = buildRequestBody({ ...actionPayload });  
  const requestAction = request({ type: action.type, payload });
  const { responseActionType } = requestAction.payload;
  yield put(requestAction);
  
  let response = yield take(responseActionType);
  console.log(response, 'response login')
  
  if(response.payload.status ==404){
/*
    payload.request.endpoint= '/aprender/login';

    const apprenderSid = btoa( new Date().toString() + Math.floor(Math.random()*1e6));
 
    payload.body = buildRequestBody({username:  actionPayload.auth.email, password: actionPayload.auth.password, device_id: apprenderSid });

    const requestAction = request({ type: action.type, payload });
    const { responseActionType } = requestAction.payload;
  
    yield put(requestAction);
  
    response = yield take(responseActionType);
    if(response.payload.status <400){
      response.payload.apprenderSid = apprenderSid;
    }*/
  } 
  yield call(workerResponseHandler, { response, action, ...requestHandlers });
  
};


export const workerRequestAccountDelete = function* workerRequestGenerator(action) { 

  const actionPayload = action.payload;
  let payload = {
    request: {
      endpoint: '/users/delete',
      method: 'post',
      reportable: false,
      authorizable: false
    }
  };
 
  payload.body =  { ...actionPayload } ;  

  const requestAction = request({ type: action.type, payload });
  const { responseActionType } = requestAction.payload;

  yield put(requestAction);

  let response = yield take(responseActionType);

  if( response.payload.status >=200 && response.payload.status<300){ 
    yield put({type: 'CLEAR_USER'});
     yield put(push('/deleted'));
  }else{

    yield put(stopSubmit(DELETE_FORM_NAME, response.payload.errors));

  }
 

  //yield call(workerResponseHandler, { response, action, ...requestHandlers });
};

//workerRequest(workerRequestUserLoginOptions);

/**
 * Worker to handle the signup process
 */
export const workerRequestUserSignUpOptions = {
  requestHandlers: {
    onError: function* ({ action, response }: Object): any {
      yield put(stopSubmit(SIGNUP_FORM_NAME, response.payload.errors));
    },
    onSuccess: function* ({ action, response }: Object): any {
      yield put(loggedIn({ ...response.payload }));
      yield put(push('/activate'));
    }
  },
  requestParams: {
    endpoint: '/users/signup',
    method: 'post',
    reportable: false,
    authorizable: false
  },
  buildRequestBody: (actionPayload: Object) => actionPayload
};

export const workerRequestUserSignUp = workerRequest(workerRequestUserSignUpOptions);

/**
 * Worker to handle the password reset email request
 */
export const workerRequestUserResetPasswordEmailOptions = {
  requestHandlers: {
    onError: function* ({ action, response }: Object): any {
      yield put(addAlert({ message: 'connection_trouble_message', type: 'error'}));
    },
    onSuccess: function* ({ action, response }: Object): any {
      yield put(stopSubmit(USER_PASSWORD_NEW_FORM_NAME));
      yield put(reset(USER_PASSWORD_NEW_FORM_NAME));
      yield put(addAlert({ message: 'reset_password_email_sent', type: 'success'}));
    }
  },
  requestParams: {
    endpoint: '/users/reset_password',
    method: 'post',
    reportable: false,
    authorizable: false
  },
  buildRequestBody: (actionPayload: Object) => actionPayload
};

export const workerRequestUserResetPasswordEmail = workerRequest(workerRequestUserResetPasswordEmailOptions);

/**
 * Worker to handle the password change
 */
export const workerRequestUserResetPasswordOptions = {
  requestHandlers: {
    onError: function* ({ action, response }: Object): any {
      const { api } = response.payload.errors;
      yield put(stopSubmit(USER_PASSWORD_RESET_FORM_NAME, response.payload.errors));

      // This endpoints returns :api errors
      // We don't have a global handling for these kind of errors
      // I'll put this in here until we define a way to do it globally instead
      // on per case basis
      if (api && api.length) {
        yield put(addAlert({ message: response.payload.errors.api[0], type: 'error' }));
      }
    },
    onSuccess: function* ({ action, response }: Object): any {
      yield put(logout());
      yield put(push('/login'));
      yield put(addAlert({ type: 'success', message: 'password_succesfully_updated' }));
    }
  },
  requestParams: {
    endpoint: '/users/reset_password',
    method: 'put',
    reportable: false,
    authorizable: false
  },
  buildRequestBody: (actionPayload: Object) => actionPayload
};

export const workerRequestUserResetPassword = workerRequest(workerRequestUserResetPasswordOptions);

export function* watchUserRequest(): any {
  yield all([
    takeEvery('REQUEST_USER_SIGNUP', workerRequestUserSignUp),
    takeEvery('REQUEST_USER_LOGIN', workerRequestUserLogin),
    takeEvery('REQUEST_USER_DELETE', workerRequestAccountDelete),
    takeEvery('REQUEST_USER_LOGOUT', workerRequestUserLogout),
    takeEvery('REQUEST_USER_RESET_PASSWORD_EMAIL', workerRequestUserResetPasswordEmail),
    takeEvery('REQUEST_USER_RESET_PASSWORD', workerRequestUserResetPassword)
  ]);
}
