/* @flow */

import { call, takeEvery, all } from 'redux-saga/effects';
import { workerRequest } from '../diplomatico/workers';
import type { Action } from '../diplomatico/types';

/**
 * Worker to handle the request of Achievement Cards
 */
 export const workerRequestAchievementCardScoresOptions = ({ intelligence_id, child_id }) => ({
   requestParams: {
     endpoint: `/children/${child_id}/intelligences/${intelligence_id}/achievement_card_scores`,
     method: 'get',
     intelligence_id,
     child_id
   }
 });

export function* workerRequestAchievementCardScores(action: Action): any {
  let workerRequestGenerator = workerRequest(workerRequestAchievementCardScoresOptions(action.payload));

  yield call(workerRequestGenerator, action);
}

export function* watchAchievementCardScoresRequest(): any {
  yield all([
    takeEvery('REQUEST_ACHIEVEMENT_CARD_SCORES', workerRequestAchievementCardScores)
  ]);
}
