import React, { useState } from 'react';
import { Form, FormGroup, FormFeedback } from 'reactstrap';
import { Field, reduxForm, getFormValues } from 'redux-form';
import { SubmitButton } from '../../../components';
import TranslationsHelper from '../../../hocs/TranslationsHelper';
import classNames from 'classnames';
import { required } from '../../../components/validations';
import { times } from 'lodash';
import { coreMap, oaMap } from '../PlanificationValuesMap';
import { PlanificationField, PlanificationFieldMulti } from './PlanificationField';
export let PlanificationForm  = ({ error,  fields, handleSubmit, persisted, submitting, valid, dirty, change, translations }) => {
 

  let tmap = {};
  for(let {id,  constraints, fieldValues} of fields){ 
    if(constraints) { 
      for(let e of Object.values(fieldValues)){
        for(let [k,v] of e)
          tmap[k] = v;
      }
    }else{

      for(let [k,v] of fieldValues)
        tmap[k] = v;

    }
  }  
  console.log("TMAP:", JSON.stringify(tmap, null, 2));


  const formClassName = classNames({ 'has-danger': error }, 'form', "student-data-form");
 
  const [values, setValues] = useState({});
  console.log("SSSVALUES:", values);
  let constrainedValues = {};
  let constraintMap = {};
  for(let {id,  constraints} of fields){ 
    if(constraints) { 
        constraintMap[id] =  constraints;
    }
  } 
  /*for(let [id ] of Object.entries(constraintMap)){
    let id2 = id;
    while(){


    }
  }*/

  for(let {id,  constraints} of fields){ 
    if(constraints)for(let c of constraints){
      if(! (c in constrainedValues))
        constrainedValues[c] = [];
      constrainedValues[c].push(id);
    }
  } 

  function clearField(id,newValues){
    change(id, null);
    if(id in constrainedValues){
      newValues[id] = null ;
      for(let cid in constrainedValues[id]){
        clearField(cid, newValues);
      }
    }
  }

  return (
    <Form className={formClassName} onSubmit={handleSubmit}>

      <div>
        {error &&
          <FormGroup>
            <FormFeedback>{translations.t(error)}</FormFeedback>
          </FormGroup>}

        <div className="d-flex flex-column">
          {
            fields.map(({id, label, constraints, fieldValues, desc, multi})=>{ 

              let options = [];
              if(!constraints)
                options = fieldValues;
              else if(values){
                let valid=true;
                let optionKey = "";
                let first = true;
                for(let c of constraints){
                  if(!values[c]){
                    valid = false;
                    break;
                  }
                  if(first){

                    first = false;
                    
                  }else optionKey+= "-";
                  optionKey+= values[c];
                }
                if(valid && optionKey in fieldValues)
                  options = fieldValues[optionKey];
                if(valid && !(optionKey in fieldValues))
                  options = null;
              }
              console.assert(options, id);
              
              return  <Field
                name={id}
                placeholder={label}
                onChange={(id in constrainedValues) && ( (e, v)=>{
                  console.log("changed "+  id);
                  let newValues = Object.assign({}, values, {[id]: v});
                  for(let fid of constrainedValues[id]){ 
                    clearField(fid,newValues );
                  }
                  setValues(newValues);
                })}
                hidden={!options}
                selectOptions={(options || []).map((a)=>({value: a[0], label: a[1]}))}
                validate={options && !multi && [required]}
                desc={desc}
                component={(multi)?PlanificationFieldMulti:PlanificationField}
                disabled={!options || submitting} />
            }
            )
          } 
          <SubmitButton id="school-planifications-submit-button" loading={submitting} className="btn-primary--green gpt-planification-button align-self-center" disabled={!valid}>

            <span className="pleiq-icon-icon_play" />
          </SubmitButton>
        </div>
        {persisted}
      </div>
    </Form>
  );
};
export const FORM_NAME_PLANIFICATIONS= 'schoolPlanificationGeneratorMX';
PlanificationForm = reduxForm({
  form: FORM_NAME_PLANIFICATIONS,
})(TranslationsHelper(PlanificationForm));
