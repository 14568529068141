/* @flow */

import _ from 'lodash';

type Collection = Array<{ name: string }>;

export const sortByName = (collection: Collection) => {
  return _.sortBy(collection, 'name');
};

export default sortByName;
