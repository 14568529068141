/* @flow */

import React from 'react';
import { Progress } from 'reactstrap';
import { Intelligences } from '../models/';
import classNames from 'classnames';
import Translations from '../hocs/TranslationsHelper';
import { compose, pure } from 'recompose';
import { getBookInformation } from '../caligrafix/bookInfo';
import { getSzClassFromValue } from './TopicProgress';

type Props = {
  type: $Keys<typeof Intelligences.TYPES>,
  value: number,
  slug:String,
  translations: Object
};
 
/**
 * Component to display a composition of the book icon vs a progress bar
 * attached to that topic particular
 *
 * @method TopicProgress
 * @param  {Intelligences.TYPES}       type  - a valid intelligence type to render
 * @param  {number}                    value - the percentage of progress made represented by a number from 0 to 100
 * @param  {object}                    translations - injected by our Translations HoC to give translated strings
 */
const BookProgress = ({ slug, value, translations }: Props) => {
  const { imgSrc, label, color } = getBookInformation(  slug );
 // const iconWrapperClass = classNames('icon-wrapper');

  if (value > 100 || value < 0) {
    throw new Error(`[TopicProgress] value: ${value} should be > 0 and < 100`);
  }

  return (
    <div className="topic-progress">
      <div className="cx-book-container">
        <img src={imgSrc} className="cx-book-img"/>
      </div>
      <div className="info-wrapper">
        <label>{  label  }</label>
        <Progress title={`${value}%`} value={value} color={slug}>
          <span className={"progress-bar-indicator  " + getSzClassFromValue(value)}>{`${value}%`}</span>
        </Progress>
      </div>
    </div>
  );
};

const BookProgressTranslated = Translations(BookProgress);

export const BookProgressComposed = compose (
  pure
)(BookProgressTranslated);

export default BookProgressComposed;
