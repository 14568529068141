/* @flow */

import React from 'react';
import { compose, pure, withProps } from 'recompose';
import classNames from 'classnames';
import _ from 'lodash';
import type { Child } from './types';

type Props = {
  colors: Array<string>,
  selected: string,
  onClick: () => mixed
}

/**
 * A component to display a color selection
 *
 * @method ColorSelect
 * @param  {Array<string>}  colors    - array of hex colors to display and use
 * @param  {string}         selected  - selected color as hex string
 * @param  {Function}       onClick   - to handle color click
 *
 */
const ColorSelect = ({ colors, selected, onClick }: Props) => {
  const selectClass = classNames('color-select', 'form-control');
  const selectedColor = colorStringToHex(selected);
  return (
    <div className={selectClass}>
      {
        colors.map((color, index) => {
          return (
            <Circle
              key={color}
              className="color-select-item"
              color={color}
              check={color === selectedColor}
              onClick={() => onClick({ color })}
            />
          );
        })
      }
    </div>
  );
};

export const apiValidColorValues = {
  '#f1c300': 'yellow',
  '#16cc90': 'green',
  '#f7602c': 'orange',
  '#678cff': 'blue',
  '#d36cff': 'purple',
  '#ff7ec6': 'pink'
};

export const apiValidColorNames = _.values(apiValidColorValues);

export const availableColors = Object.keys(apiValidColorValues);

export const apiValidColorStrings = _.invert(apiValidColorValues);

/**
 * This helper function gets a child with a valid color name,
 * (one that is on `availableColors`), and returns the same
 * child but with a color formatted as a hex string.
 * This is useful when we get a child from the api, as it has
 * the color property as named color instead of a hex string.
 */
export function mapColorStringToHex(child: Child) {
  return {
    ...child,
    color: apiValidColorStrings[child.color]
  };
}

/**
 * This function will always return a correct display color (hex format)
 */
export function colorStringToHex(color: string) {
  return color && color[0] === '#'
    ? color
    : apiValidColorStrings[color];
}

const ColorSelectComposed = compose(
  withProps({ colors: availableColors }),
  pure
)(ColorSelect);

type CircleProps = {
  color: string,
  check?: boolean,
  className: string,
  onClick: Function
};

/**
 * A component to display a circle with a color
 *
 * @method Circle
 * @param  {string}    color      - hex color to use for circle
 * @param  {boolean}   check      - should this circle have a checkmark?
 * @param  {string}    className  - class to use on the circle container
 * @param  {Function}  onClick    - handle click on circle
 *
 */
export const Circle = ({ color, check, className, onClick }: CircleProps) => {
  const circleClass =
    classNames('circle', className, { [`${className}--selected`]: check });
  return (
    <div className={circleClass} style={{ backgroundColor: color }} onClick={onClick}>
      { check ? <span className="circle-icon pleiq-icon-icon_checkmark" /> : '' }
    </div>
  );
};

export default ColorSelectComposed;
