/* @flow */

import React from 'react';
import { Form, FormGroup, FormFeedback } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import Translations from '../hocs/TranslationsHelper';
import { RegistrationForm, InputField } from './';
import type { ReduxFormFunction, ReduxFormErrorGlobal, ReduxFormFlag, TranslationsObject } from '../components/types';
import { required, email, minLength } from './validations';
import classNames from 'classnames';
import RegistrationSubmitButton from './RegistrationSubmitButton';
import { Link } from 'react-router-dom';

type Props = {
  handleSubmit: ReduxFormFunction,
  translations: TranslationsObject,
  error: ReduxFormErrorGlobal,
  submitting: ReduxFormFlag,
  valid: ReduxFormFlag
};

/**
 * Component to display step 1 of the login form
 *
 * @method SignInForm
 * @param  {function}  handleSubmit     - this callback gets injected by redux-form to handle form submit
 * @param  {Object}    translations     - inject by the Translations HoC to give access to locale text
 */
const SignInForm = ({ handleSubmit, translations, error, submitting, valid, ...otherProps }: Props) => {
  const formClassName = classNames({ 'has-danger': error }, 'form');
  const disabled = submitting;
  return (
    <RegistrationForm title={translations.t('signin_form_title')} subtitle={translations.t('signin_form_subtitle')}>
      <Form id="signin-form" className={formClassName} onSubmit={handleSubmit}>
        {
          error &&
          <FormGroup>
            <FormFeedback>{translations.t(error)}</FormFeedback>
          </FormGroup>
        }
        <Field
          className="text-center lowercased"
          type="text"
          name="email"
          placeholder={translations.t('email_placeholder')}
          disabled={disabled}
          component={InputField}
          validate={[ required ]}
        />
        <Field
          className="text-center"
          type="password"
          name="password"
          placeholder={translations.t('password')}
          disabled={disabled}
          component={InputField}
          validate={[ required]}
        />
        <div className="registration-form-button-wrapper">
          <RegistrationSubmitButton loading={submitting} disabled={!valid}>
            {translations.t('continue')}
          </RegistrationSubmitButton>
          <span className="registration-form-next-icon pleiq-icon-icon_arrow_right" />
        </div>
        <p className="text-center registration-form-footer-text">
          <Link to="/sign_up" className="access-form-anchor">{translations.t('no_account_yet')}</Link>
        </p>
        <p className="text-center registration-form-footer-text">
          <Link to="/users/password/new" className="access-form-anchor">{translations.t('forgot_password')}</Link>
        </p>
      </Form>
    </RegistrationForm>
  );
};

export const FORM_NAME = 'signInForm';

export default reduxForm({
  form: FORM_NAME
})(Translations(SignInForm));
