/* @flow */

import React from 'react';
import { Paper } from '../components';
import {
  SchoolDashboardNavContainer, SchoolDashboardHeadingContainer, HeaderContainer,
  CoursesSectionContainer, PageTitleContainer
} from '../containers';
import { StudentPdfSelection, StudentCommentsPdfSelection, StudentCaligrafixPdfSelection } from '../pdfview/StudentView';
import { CourseSelection } from '../pdfview/CourseView';

import { HierarchyReportPdf } from '../pdfview/Hierarchy';
import LicenseFilter from '../containers/LicenseFilter'; 
import { Col, Row } from 'reactstrap';
import {BaseRegistrationScreen} from './Registration'
import SchoolUserSignUpFormContainer from '../containers/SchoolUserSignUpFormContainer';
/**
 * screen for the school courses
 *
 * @method SchoolProgress
 *
 */  
const SchoolUserSignUp = ({queryParams}) => {
  return (
   
    <BaseRegistrationScreen>
      <PageTitleContainer pageTitle="title_registration_signup" />
      <SchoolUserSignUpFormContainer queryParams = {queryParams}/>
    </ BaseRegistrationScreen>
  );
};

export default SchoolUserSignUp;
