/* @flow */

import _ from 'lodash';
import { Intelligences } from '../js/models';
import { getSchoolProgressByIntelligence, getSchoolProgressByCaligrafixBook } from './schoolProgress';
import CaligrafixBook from '../js/models/CaligrafixBook';

export const mapCoursesForSelect = ({ courses }: Object, trasnslations) => {
  return _.map(courses, (course) => ({
    value: course.id,
    label: `${trasnslations.t("level")} ${course.level_name} / ${trasnslations.t("course")} ${course.name}`
  }));
};

export const getCourseById = ({ courses }: Object, course_id: string) => (_.find(courses, { id: course_id }) || {});

export const getLevelCourses = ({ courses }: Object, level_id: string) => _.filter(courses, { level_id: level_id });

export const getAllCoursesButFromLevel = (state: Object, level: Object) => {
  const filteredCourses = _.filter(state.courses, course => course.level_id !== level.id);
  return filteredCourses;
};

export const getCoursesProgressByIntelligence = ({ courses, intelligences, progress }) => {
  return _.map(intelligences, (intelligence) => {
    const intelligenceProgress = getSchoolProgressByIntelligence({ progress }, intelligence.id);
    const slug = intelligence.slug.toUpperCase();
    const id = intelligence.id;

    return {
      id,
      slug,
      color: Intelligences.getIntelligenceColor(slug),
      data: getFormattedData({ courses, intelligenceProgress })
    };
  });
};

export const getCourseProgressByIntelligence = ({ courses, intelligences, progress, course_id }) => {
  const coursesProgressByIntelligence = getCoursesProgressByIntelligence({ courses, intelligences, progress });

  return coursesProgressByIntelligence.map((intelligenceProgress) => {
    intelligenceProgress.data = _.filter(intelligenceProgress.data, { id: course_id })

    return intelligenceProgress;
  });
};

const getFormattedData = ({ courses, intelligenceProgress }) => {
  return _.map(intelligenceProgress, (value, course_id) => {
    const course = getCourseById({ courses }, course_id);

    return {
      id: course.id,
      name: course.name,
      value: value || 0
    };
  });
};

const getFormattedData2 = ({ courses, intelligenceProgress }) => {
  return courses.map((course)=>{
    return {
      id: course.id,
      name: course.name,
      value: intelligenceProgress[course.id] || 0
    }

  });/* _.map(intelligenceProgress, (value, course_id) => {
    const course = getCourseById({ courses }, course_id);

    return {
      id: course.id,
      name: course.name,
      value: value || 0
    };
  });*/
};
export const getCoursesProgressByCaligrafixBook = ({ courses, caligrafix_books,user_books, progress }) => {
  return _.map(caligrafix_books, (caligrafix_book) => {
    const caligrafix_bookProgress = getSchoolProgressByCaligrafixBook({ progress }, caligrafix_book.id);
    const slug = caligrafix_book.slug;//.toUpperCase();
    const id = caligrafix_book.id;

    return (caligrafix_book.active_in_schools || user_books.includes(caligrafix_book.slug))?{
      id,
      slug,
      color: CaligrafixBook.getCaligrafixBookColor( caligrafix_book.slug),
      data: getFormattedData2({ courses, intelligenceProgress: caligrafix_bookProgress })
    } : null;
  }).filter((b)=>b);
};