import { getBookInformation } from "../caligrafix/bookInfo"

class CaligrafixBook{
    static getCaligrafixBookColor(slug) {
        return getBookInformation(slug).color;
    }
    static getCaligrafixBookTitle(slug) {
        return getBookInformation(slug).label;
    }

    
} 
export default CaligrafixBook;
