/* @flow */

import React from 'react';
import { BarChart } from './';

const IntelligenceProgressBarChart = ({ title, ...chartProps }) => {
  return (
    <div className="progress-bar-chart">
      <div className="progress-bar-chart-title">
        <h4 className="mt-3 mb-3">{title}</h4>
      </div>
      <div className="progress-bar-chart-container">
        <BarChart {...chartProps} /> 
      </div>
    </div>
  );
};

export default IntelligenceProgressBarChart;
