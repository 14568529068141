/* @flow */


import { call, put, take, takeEvery, select, all } from 'redux-saga/effects';
import { stopSubmit, initialize } from 'redux-form';
import { workerRequest } from '../diplomatico/workers';
import { logout } from '../actions/user';
import { apiValidColorValues } from '../js/components/ColorSelect';
import type { Action } from '../diplomatico/types';
import _ from 'lodash';
import { FORM_NAME as USER_CHILDREN_FORM_NAME } from '../js/components/UserChildrenForm';
import { deleteChild, updateChild, addChild } from '../actions/children';
import { getChildrenWithDisplayColors } from '../selectors/children';

export function* handleFormUpdate(formName: string, children: Object): any {
  yield put(initialize(formName, { children }));
  yield put(stopSubmit(formName));
}

export function* handleSuccessActions(actions: Object): any {
  for (let action of actions) {
    yield put(action);
  }
}

/**
 * Request children for a current parent user
 */
export const workerRequestChildrenOptions = {
  requestParams: {
    endpoint: '/children',
    method: 'get'
  }
};

export const workerRequestChildren = workerRequest(workerRequestChildrenOptions);

/**
 * Request the creation of a single child for a parent user
 */
export const workerRequestChildOptions = {
  requestHandlers: {
    onSuccess: function* ({ action, response }: Object): any {
      const { last_child, onSuccessActions, initApp, formName } = action.payload;
      const child = response.payload.body;
      yield put(addChild(child));

      if (last_child) {
        if (initApp) {
          /*
           * This request only gets send for Parent accounts. The account_type
           * is hardcoded here as the response payload has no user data.
           */
          yield put({ type: 'APPLICATION_INIT', payload: { account_type: 'Parent' } });
        }

        if (onSuccessActions) {
          yield call(handleSuccessActions, onSuccessActions);
        }

        if (formName) {
          const children = yield select(getChildrenWithDisplayColors);
          yield call(handleFormUpdate, formName, children);
        }
      }
    },
    onError: function* ({ action, response }: Object): any {
      yield put(logout());
    }
  },
  requestParams: {
    endpoint: '/children',
    method: 'post',
    reportable: false
  },
  buildRequestBody: (actionPayload: Object) => {
    let payload = _.omit(actionPayload, ['reference', 'last_child']);
    payload.color = apiValidColorValues[payload.color];
    return payload;
  }
};

/**
 * Trigger creation of a children for a parent user
 */
export const workerRequestChildCreate = workerRequest(workerRequestChildOptions);

/**
 * Trigger the creation of multiple children for a parent user
 */
export function* workerRequestChildrenCreate({ type, payload }: Action): any {
  const { children, onSuccessActions, formName, initApp } = payload;

  for (let child of children) {
    let last = children.indexOf(child) === (children.length - 1);
    yield put({ type: 'REQUEST_CHILD_CREATE', payload: { ...child, last_child: last, onSuccessActions, formName, initApp } });
    yield take('RESPONSE_CHILD_CREATE');
  }

  yield put({ type: 'DONE_CHILDREN_CREATE' });
}

/**
 * Request to delete an specific child
 */
export const workerRequestChildDeleteOptions = (childId: number) => ({
  requestHandlers: {
    onSuccess: function* ({ action, response }: Object): any {
      const { child, last_child, onSuccessActions, formName } = action.payload;
      yield put(deleteChild(child));

      if (last_child) {
        if (onSuccessActions) {
          yield call(handleSuccessActions, onSuccessActions);
        }

        if (formName) {
          const children = yield select(getChildrenWithDisplayColors);
          yield call(handleFormUpdate, formName, children);
        }
      }
    },
    onError: function* ({ action, response }: Object): any {
      yield put(stopSubmit(USER_CHILDREN_FORM_NAME, { ...response.payload.errors }));
    }
  },
  requestParams: {
    endpoint: `/children/${childId}`,
    method: 'delete'
  },
  buildRequestBody: (actionPayload: Object) => ({})
});

/**
 * Triggers the deletion of an specific child
 */
export function* workerRequestChildDelete({ type, payload }: Action): any {
  const { child } = payload;

  const workerRequestGenerator = workerRequest(
    workerRequestChildDeleteOptions(child.id)
  );

  yield call(workerRequestGenerator, { type, payload });
}

/**
 * Trigger the deletion of multiple children for a parent user
 */
export function* workerRequestChildrenDelete({ type, payload }: Action): any {
  const { children, onSuccessActions, formName } = payload;

  for (let child of children) {
    let last = children.indexOf(child) === (children.length - 1);
    yield put({ type: 'REQUEST_CHILD_DELETE', payload: { child, last_child: last, onSuccessActions, formName } });
    yield take('RESPONSE_CHILD_DELETE');
  }

  yield put({ type: 'DONE_CHILDREN_DELETE' });
}

/**
 * Request to update an specific child
 */
export const workerRequestChildUpdateOptions = (childId: number) => ({
  requestHandlers: {
    onSuccess: function* ({ action, response }: Object): any {
      const { last_child, onSuccessActions, formName } = action.payload;
      const child = response.payload.body;
      yield put(updateChild(child));
      if (last_child) {
        if (onSuccessActions) {
          yield call(handleSuccessActions, onSuccessActions);
        }

        if (formName) {
          const children = yield select(getChildrenWithDisplayColors);
          yield call(handleFormUpdate, formName, children);
        }
      }
    },
    onError: function* ({ action, response }: Object): any {
      yield put(stopSubmit(USER_CHILDREN_FORM_NAME, { ...response.payload.errors }));
    }
  },
  requestParams: {
    endpoint: `/children/${childId}`,
    method: 'put'
  },
  buildRequestBody: (actionPayload: Object) => {
    let { child } = actionPayload;
    child.color = apiValidColorValues[child.color];
    return { ...actionPayload, child };
  }
});

/**
 * Triggers the update of an specific child
 */
export function* workerRequestChildUpdate({ type, payload }: Action): any {
  const { child } = payload;

  const workerRequestGenerator = workerRequest(
    workerRequestChildUpdateOptions(child.id)
  );

  yield call(workerRequestGenerator, { type, payload });
}

/**
 * Trigger the update of multiple children for a parent user
 */
export function* workerRequestChildrenUpdate({ type, payload }: Action): any {
  const { children, onSuccessActions, formName } = payload;

  for (let child of children) {
    let last = children.indexOf(child) === (children.length - 1);
    yield put({ type: 'REQUEST_CHILD_UPDATE', payload: { child, last_child: last, onSuccessActions, formName } });
    yield take('RESPONSE_CHILD_UPDATE');
  }

  yield put({ type: 'DONE_CHILDREN_UPDATE' });
}

/**
 * Trigger the creation or edition of one or more children for a parent user
 */
export function* workerHandleChildrenEdition({ type, payload }: Action): any {
  const { newChildren = [], updatedChildren = [], deletedChildren = [], formName, onSuccessActions } = payload;

  let lastWorker = updatedChildren.length === 0 && deletedChildren.length === 0;
  if (newChildren.length > 0) {
    // delegate on workerRequestChildrenCreate to create the new children
    yield put({
      type: 'REQUEST_CHILDREN_CREATE',
      payload: {
        children: newChildren,
        onSuccessActions: lastWorker && onSuccessActions,
        formName: lastWorker && formName
      }
    });
    yield take('DONE_CHILDREN_CREATE');
  }

  lastWorker = deletedChildren.length === 0;
  if (updatedChildren.length > 0) {
    // delegate on workerRequestChildrenUpdate to create the update edited children
    yield put({
      type: 'REQUEST_CHILDREN_UPDATE',
      payload: {
        children: updatedChildren,
        onSuccessActions: lastWorker && onSuccessActions,
        formName: lastWorker && formName
      }
    });
    yield take('DONE_CHILDREN_UPDATE');
  }

  lastWorker = true;
  if (deletedChildren.length > 0) {
    yield put({
      type: 'REQUEST_CHILDREN_DELETE',
      payload: {
        children: deletedChildren,
        onSuccessActions: lastWorker && onSuccessActions,
        formName: lastWorker && formName
      }
    });
    yield take('DONE_CHILDREN_DELETE');
  }
}

export function* watchChildrenRequest(): any {
  yield all([
    takeEvery('REQUEST_CHILDREN', workerRequestChildren),
    takeEvery('REQUEST_CHILD_CREATE', workerRequestChildCreate),
    takeEvery('REQUEST_CHILD_UPDATE', workerRequestChildUpdate),
    takeEvery('REQUEST_CHILD_DELETE', workerRequestChildDelete),
    takeEvery('REQUEST_CHILDREN_CREATE', workerRequestChildrenCreate),
    takeEvery('REQUEST_CHILDREN_UPDATE', workerRequestChildrenUpdate),
    takeEvery('REQUEST_CHILDREN_DELETE', workerRequestChildrenDelete),
    takeEvery('HANDLE_CHILDREN_EDITION', workerHandleChildrenEdition)
  ]);
}
