/* @flow */

import React from 'react';
import { Button } from './';
import Translations from '../hocs/TranslationsHelper';
import RoleFilter from '../containers/RoleFilter';

type Props = {
  addLevelClick: () => mixed,
  addChildClick: () => mixed,
  translations: Object
};

/**
 * component to display some buttons on the Courses section
 *
 * @method CoursesActions
 * @param  {function}  addLevelClick     - callback that should dispatch an action to show a modal to add a level
 * @param  {function}  addChildClick     - callback that should dispatch an action to show a modal to add a child
 * @param  {Object}    translations      - object containing app translations
 *
 */
const CoursesActions = ({ addLevelClick, addChildClick, translations }: Props) => {
  return (
    <div className="courses-actions">
      <RoleFilter roles={'coordinator'}>

        <Button type="secondary--blue" className="courses-actions-button" onClick={addLevelClick}>
          <span className="courses-actions-icon pleiq-icon-icon_plus" />
          {translations.t('create_new_level')}
        </Button>
      </RoleFilter>
      <Button type="secondary--pink" className="courses-actions-button" onClick={addChildClick}>
        <span className="courses-actions-icon pleiq-icon-icon_plus" />
        {translations.t('create_child_profile')}
      </Button>
    </div>
  );
};

export default Translations(CoursesActions);
