/* @flow */

import React from 'react';
import type { Student } from './types';
import { StudentInfoList } from './index';
import Translations from '../hocs/TranslationsHelper';
import { CollapseGroupContainer } from '../containers';

type Props = {
  title: string,
  count: string,
  courseId: string,
  students: Array<Student>,
  onHeaderClick: () => mixed,
  onEditButtonClick: () => mixed,
  translations: Object
};

/**
 * component to display a Course with a students list inside a collapse
 *
 * @method CourseCollapseItem
 * @param  {string}          title                - title for the header
 * @param  {string}          count                - count of how many students
 * @param  {string}          courseId             - the id of the course
 * @param  {Array<Student>}  students             - array of students to render StudentInfoList
 * @param  {function}        onHeaderClick        - callback for when the whole header gets clicked
 * @param  {function}        onEditButtonClick    - callback for the edit button on the header
 * @param  {Object}          translations         - object containing app translations
 *
 */
const CourseCollapseItem = ({ title, count, all, courseId, students, onHeaderClick, onEditButtonClick, onPdfButtonClick, translations }: Props) => {
  const translatedCount = (courseId)? `${count} ${translations.t('students')}` : '';
  const translatedTitlte = (courseId)? `${translations.t('course')} ${title}` : translations.t('unassigned_students'); 
  return (
    <CollapseGroupContainer
      className={(courseId)?"course-collapse":"level-collapse"}
      title={translatedTitlte}
      id={courseId || "null-course"}
      icon="icon_course"
      all={all}
      hideButtons={courseId===null}
      count={translatedCount}
      onHeaderClick={onHeaderClick}
      onEditButtonClick={onEditButtonClick}
      onPdfButtonClick={onPdfButtonClick}
      editRoles={['coordinator', 'teacher']}
    >
      <StudentInfoList students={students} all={all} />
    </CollapseGroupContainer>
  );
};

export default Translations(CourseCollapseItem);
