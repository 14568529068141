/* @flow */

import React from 'react';
import { TopicProgress } from '.';
import { BookProgress } from '.';
import { getIntelligenceProgressById } from '../../selectors/intelligenceProgress';
import { pure, compose } from 'recompose';
import _ from 'lodash';

/**
 * Component that receives the data to display all the progress
 * over each of the Books in a TopicProgress Component
 * @method GeneralCaligrafixProgressContainer
 * @param  {array}               currentProgress - injected by Redux includes all intelligence_progress from the store for the current child
 * @param  {array}               intelligences - injected by Redux includes all intelligences from the store
 */
export const GeneralCaligrafixProgress = ({ currentProgress, books }) => {
  const currentBookProgress = getBooksProgress({ currentProgress, books });

  return (
    <div>
      {currentBookProgress.map((book) => <BookProgress key={book.id} slug={book.slug} value={book.value} />)}
    </div>
  );
};

const getBookProgressById = ({book_progress}, id)=>{ 
  return _.find(book_progress, { book_id: id}) || {};
}
export const getBooksProgress = ({ currentProgress, books }) => {
  return _.map(books, (book) => {
    const currentBookProgress = getBookProgressById({ book_progress: currentProgress }, book.id);
    const progress = _.get(currentBookProgress, 'score') || 0; 

    return {
      id: book.id,
      slug: book.slug,
      value: parseInt(progress, 10)
    };
  });
};

const GeneralCaligrafixProgressComposed = compose(
  pure
)(GeneralCaligrafixProgress);

export default GeneralCaligrafixProgressComposed;
