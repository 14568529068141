/* @flow */

import React, { useState, useEffect, useRef } from 'react'; 
import { Col, Row } from 'reactstrap';
import { Label } from 'reactstrap'; 
import { stopSubmit, initialize, submit, startSubmit } from 'redux-form';
import { Modal, Paper, InfoTooltip, Button, InputFieldSelect, SubmitButton, ConfirmationModal } from '../../components';
import {
  SchoolDashboardNavContainer, SchoolDashboardHeadingContainer, HeaderContainer,
  PageTitleContainer
} from '../../containers';
 
import { useTranslations } from '../../hocs/TranslationsHelper';
import topScroll from '../../hocs/topScroll';
import avatarImg from '../../../img/ai/avatar.png'
import avatarBas from '../../../img/ai/avatar_basica.png'
import pleiqLoader from '../../../img/ai/gif_cube_pleiq.gif'
import userImg from '../../../img/ai/avatar_user_chat.png'
import icon_micro from '../../../img/ai/icon_micro.png'
import icon_reset from '../../../img/ai/icon_reset.png'
import icon_talk from '../../../img/ai/icon_talk.png'
import icon_library from '../../../img/ai/folder_heart.png'
import icon_flag_cl from '../../../img/ai/flag_cl.png'
import icon_flag_mx from '../../../img/ai/flag_mx.png'
import icon_flag_us_fl from '../../../img/ai/flag_us_fl.png'

const icon_flags = {
  'CL_EN' : icon_flag_cl,
  'CL' : icon_flag_cl,
  'MX' : icon_flag_mx,
  'US_FL' : icon_flag_us_fl,

}
const avatar_imgs= {
  'CL_EB': avatarBas
}
import { useApi } from '../../../api_request';
import { useDispatch, useSelector } from 'react-redux';
import ReactMarkdown from 'react-markdown'; 

import Canvas3D from './Canvas3D'; 
import { PdfData } from '../../../pdfer/Pdfable';
import { genPdfMarkdown } from './genPdfMarkdown';
import { useCable } from '../../hocs/Cable';
import { FORM_NAME_CL, PlanificationFormCL } from './forms/PlanificationFormCL';
import { FORM_NAME_MX, PlanificationFormMX } from './forms/PlanificationFormMx';
import { FORM_NAME_PLANIFICATIONS, PlanificationForm } from './forms/PlanificationForm';
import { fieldDatas } from './fieldDatas';
 
const MessageBox = ({role, content, title,location, disabled, planification, onRegenerate}) => {

  const translations = useTranslations();

  const user = useSelector(({user})=> user)
  const api = useApi();
  const[ saving, setSaving] = useState(false);
  const[ downloading, setDownloading] = useState(false);
  const[ saved, setSaved] = useState(false);
 
  const planRef = useRef();

  const genPdf = async ()=>{
    setDownloading(true);
    await genPdfMarkdown(content, title);
    setDownloading(false);
    //const pdf = new jsPDF(  'p' , 'mm', "a4");
    //pdf.fromHTML(planRef.current.innerHTML);
    //pdf.save(title+".pdf");
  }
  if(role=="user")
    return <div className="gpt-chat-msg gpt-chat-msg-user">
        <p className="gpt-chat-msg-content gpt-chat-msg-content-user">
           {content}
        </p>
        <img className="gpt-chat-msg-avatar" src={userImg} />
    </div>
  else
    return <div className="gpt-chat-msg">
      <img className="gpt-chat-msg-avatar" src={avatar_imgs[location] || avatarImg} />
      <div className="gpt-chat-msg-content">
        {
          planification? ( 
          
          <div ref={planRef}><ReactMarkdown className= "gpt-chat-md" >{content}</ReactMarkdown></div>):
          <p>{content}</p>
        } 
        {planification && <div className="gpt-chat-msg-controls">
            <Button className="school-planifications-save-button" outline type="planification-options" onClick={
              async()=>{
                try{
                    
                  setSaving(true);
                  let tags = [];
                  for(let v of Object.values(planification)){
                    if(Array.isArray(v)){
                      tags.push(...v);
                    }else if(v!=null)tags.push(v);
                  }



                  let response = await api.post("/ai/generated_planifications", {planification: {tags,title, content, kind:location}}); 

                  if(response.status >=400)
                    throw "failed";

                 
                  setSaved(true);
                }catch(e){
 
                }finally{

                  setSaving(false);
                }
              }
            } disabled = {disabled ||saving|| saved}>{ translations.t("pg_p_save")} {(saving)&& <Spinner size="ssm-blue"/>} {(saved)&&(<span className="pleiq-icon-icon_checkmark" />)}</Button>
            <Button className="school-planifications-pdf-button" outline type="planification-options" disabled = {disabled|| downloading} onClick={genPdf}>{ translations.t("pg_p_download")}{(downloading)&& <Spinner size="ssm-blue"/>}</Button>
            <Button className="school-planifications-copy-button" outline type="planification-options" disabled = {disabled} onClick={
              ()=>{
                navigator.clipboard.writeText( content);

              }
            }>{ translations.t("pg_p_copy")}</Button>
            <Button className="school-planifications-make-similar-button" outline type="planification-options" disabled = {disabled} onClick={()=>onRegenerate(planification)}>{ translations.t("pg_p_make_similar")}</Button>
          </div>}
      </div> 
  </div>;
}

let s = false;

const Spinner = ({size, className})=>{

  return <div className={"spinner-"+(size || "sm") + ((className? (" " + className): ""))}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
 
}
const ChatPannel = ({chat, loading,  location, sendMessage,onRegenerate})=>{ 
  const [txt ,setTxt] = useState("");
  const elem=useRef();
  const translations = useTranslations();
  
  useEffect(()=>{
    elem.current.scrollIntoView({
      behavior: 'smooth', block: 'nearest'});
  },[chat, loading]);

  return   <div className="gpt-chat"> <div className="gpt-chat-container">
      {
        chat.map((msg, idx)=>(
          <MessageBox key={idx} {...msg} location={location} onRegenerate={onRegenerate} disabled={loading}/> 
        ))
      }
      { 
          loading && <img className="align-self-center gpt-chat-loader" src={pleiqLoader} onLoad={()=>elem.current.scrollIntoView({
            behavior: 'smooth', block: 'nearest'})}/>
      }
      <div ref={elem}/>
    </div>

   
    <div className="gpt-chat-input-container">
      <input  type="text" class="form-input-comment-field form-input-comment-box-input" placeholder={translations.t("pg_chat_placeholder")} aria-label="Username" disabled={loading} value={txt} onChange={(e)=>setTxt(e.target.value)}
        onKeyPress={(event)=>{
          if (event.key === "Enter") { 
            event.preventDefault(); 
            sendMessage( txt )
            setTxt("");
          }
        }}/>
      
      <SubmitButton loading={loading} className="gpt-chat-button " onClick={
        ()=>{
          s = !s;
          sendMessage( txt )
          setTxt("");
            
        }
      }  >
          <span className="pleiq-icon-icon_paper_plane" />
      </SubmitButton>  

    </div>
  </div>
}
/*


*/

const CircleButton = ({icon,toggled,  onClick})=>{
  return <button className={"circle-button" + ((toggled)?" circle-button-toggled":"")} onClick={onClick}><img src={icon}/></button>;
}
const sampleRate = 16000;

async function startRecording(onStop){

  if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
    console.log("getUserMedia supported.");

    const stream = await navigator.mediaDevices.getUserMedia(
      // constraints - only audio needed for this app
      {
        audio: {
          deviceId: "default",
          sampleRate: sampleRate,
          sampleSize: 16,
          channelCount: 1
        },
        video: false
      }
    );
    const mediaRecorder = new MediaRecorder(stream);
    let data = [];
    mediaRecorder.start();
    
    mediaRecorder.ondataavailable = (e) => { 
      console.log(e.data)
      data.push(e.data);
      const settings= stream.getAudioTracks()[0].getSettings();
      console.log("audio settings", settings);
    }; 
    const settings= stream.getAudioTracks()[0].getSettings();
    console.log("audio settings", settings);

    mediaRecorder.onstop = ()=> onStop(data, settings.sampleRate || sampleRate);
    return ()=> mediaRecorder.stop();

  } else {
    throw new Error("unsuported browser");
  }
} 
let stopRecording =null;

const STTButton = ({onNewText})=>{
  const api = useApi();
  const [recognizing, setRecognizing] = useState(false); 
  const [recording, setRecording] = useState(false); 

  return <button className={"circle-button" + ((recording)?(" circle-button-recording"):"")} onMouseDown={()=>{
      if(recognizing)return;

     startRecording((data, sampleRate)=>{
      setRecording(false);
      console.log("recording ended", data);

      let blob = new Blob(data,{type:'audio/webm;codecs=opus'});

      var reader = new window.FileReader();
      reader.readAsDataURL(blob); 
      reader.onloadend = async function() {
         let base64 = reader.result;
         base64 = base64.split(',')[1];
         console.log(base64 );

         setRecognizing(true);
         try{

          const response = await api.post("/ai/recognize", {data: base64 , sampleRate})
          if(response.status >=400)
            throw "failed"; 
            if (response.data.results.length > 0 && response.data.results[0].alternatives.length > 0 && response.data.results[0].alternatives[0].transcript  && response.data.results[0].alternatives[0].transcript != "")
            { 

                onNewText( response.data.results[0].alternatives[0].transcript);
            }
         }catch(e){

            console.error("errror", e);
         }
         setRecognizing(false);
      }
      
    }).then((sr)=>{
      setRecording(true);
      stopRecording = sr;
      
    }).catch((e)=>console.error(e));
     
    //start recording
  }} onMouseUp={
    ()=>{
      if(recording){ 
        
        setRecording(false);
        stopRecording();
      }
      //stop recording
    }
  }>
    <img src={icon_micro}/>
  </button>;
}
const bridge = {};


const generationMsg = [
  {file:"gen-1.mp3", label:"pg_gen_1" },
  {file:"gen-2.mp3", label:"pg_gen_2" },
  {file:"gen-3.mp3", label:"pg_gen_3" }
];

const helloMsg = [
  {file:"hello-1.mp3", label:"pg_hello_1" },
  {file:"hello-2.mp3", label:"pg_hello_2" },
  {file:"hello-3.mp3", label:"pg_hello_3" }
]
 
 
 

const modelIdPerLocation= {
  'CL_EB': "gptx_avatar_CL_EB.glb"
}
export const PlanificationGenerator = ({queryParams }) => {
 
  topScroll();
   const translations = useTranslations();
  const user = useSelector(({user})=> user)
  const audioRef = useRef();
  const api = useApi();
  const [speak, setSpeak] = useState(true);
  const [ready, setReady] = useState(false);
  const [chat, setChat] = useState([]);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const location  = queryParams.location || user.location;

// const PlanificationForm = PlanificationForms[user.location];
  const PlanificationFormName = FORM_NAME_PLANIFICATIONS;//schoolPlanificationGenerator+location;// PlanificationFormNames[location];


  const playMsg= (msg, play = true, salute=false)=>{
    const {label, file} = msg[Math.min(msg.length, Math.floor(Math.random()* msg.length))];
    const text = translations.t(label); 

    setChat([...chat, {role: "assistant", content: text }]); 
    if(play && bridge.onNewClip && speak){
   //   console.log("playing clip!");
      bridge.onNewClip( "/resources/ai/"+user.preferences.locale.toLowerCase()+"/"+ file, salute)
    }
    return text;
  }

  
  const cable = useCable("AiChannel");

  const onNewMessage= async (content)=>{
    const prev_chat = chat;
    setChat([...prev_chat, {role: "user", content }]); 

    setLoading(true);
    cable.send({type: "chat", data: [...(chat.filter(({planification,error})=>!planification && !error)), {role: "user", content }] } );
    try{

      const response = await cable.wait();

      const {data, audio}  = response;


      setChat([...chat, {role: "user", content }, {role: "assistant", content: data }]);
      
      if(bridge.onNewClip && speak){
        bridge.onNewClip( "data:audio/webm;codecs=opus;base64,"  + audio.audioContent)
      }  
    }catch(e){
      console.error("unable to chat", e);
    }finally{

      setLoading(false);
    }
   
 
/*
    setLoading(true);
    try{ 
      const response = await api.post("/ai/generator/chat",{chat: [...(chat.filter(({planification,error})=>!planification && !error)), {role: "user", content }]});

      console.log(response);
      if(response.status >=400)
        throw "failed"; 

        if(bridge.onNewClip && speak){
          bridge.onNewClip( "data:audio/webm;codecs=opus;base64,"  + response.data.audio.audioContent)
        } 
      setChat([...chat, {role: "user", content },   {role: "assistant", content: response.data.response }]);
    }catch(e){
      console.error(e);
      setChat([...prev_chat, {role: "user", content , error: e}]); 

    }finally{

      setLoading(false);
    }*/

  }; 
  useEffect(()=>{ 

    if(ready)playMsg(helloMsg ,true, true);

  }, [ready]); 


  async function generatePlanification(values){


      console.log("SPARAMS: ", values);

    
      var msg = playMsg(generationMsg);

      setLoading(true);
      try{
        cable.send({type: "planification", data: {parameters: values, location: location} } );  
        const {type, response, title, error} = await cable.wait();
        if(error)setChat([...chat,{role: "assistant", content: msg}, {role: "assistant", content:  translations.t("server_unavailable_message")}]);
        else setChat([...chat,{role: "assistant", content: msg}, {role: "assistant", content: response, title:  title,planification: values}]);
        
  

      }catch(e){
        console.error("unable to generate planification", e); 
      }finally{

        dispatch(stopSubmit(PlanificationFormName )); 
        setLoading(false); 
      }
 
  }
 
  return (
     <div id="school-dashboard" className="school-dashboard layout-base">
      <PageTitleContainer pageTitle="planification_generator" />
      <HeaderContainer />
      <SchoolDashboardHeadingContainer />
      <Paper.Container lead> 
        <div>
          <Row>
          <Col xs="12">
          <br/>
            <div class="d-flex">

              <Paper.Title className="flex-fill">
                  <h1 className="school-home-title">{translations.t("school_home_hello" ).replace("$user", user.user_name)} </h1> 
                  <span className="school-home-subtitle">{translations.t("school_planifications_intro" )  }</span>
                </Paper.Title> 

                  <a href={
                   "/school/gptx/library?location=" + location
                    } target="_blank" className="planification-library-button">
                    <img src={icon_library}/>
                    {translations.t("school_planifications_library_title" )  }
                    
                  </a> 
            </div>
          </Col>    
          </Row>
  
          <Row>
          <Col xs="12">
              <p>
              {translations.t("school_planifications_ai_desc" )  }
              </p>
          </Col>
          </Row>
          <Row>
            <Col  xs="4" >
              <div  className="gpt-avatar" >
                <Canvas3D bridge={bridge} className="gpt-avatar" modelId ={modelIdPerLocation[location]}  />
                <img className="gpt-avatar-flag" src={icon_flags[user.location]} />
              </div>
              {/* <img  alt="Avatar" className="gpt-avatar" src={avatarImg}/> */}
              <div className="d-flex justify-content-evenly">
                <STTButton onNewText={onNewMessage}/>
                <CircleButton toggled={!speak} icon={icon_talk} onClick={()=>{
                    if(speak){

                       if(bridge.stop)bridge.stop(); 
                    }
                    setSpeak(!speak);
                }}/>
                <CircleButton icon={icon_reset} onClick={()=>{
                    setChat([]);
                    dispatch(initialize(PlanificationFormName, { }));

                }}/> 
              </div>
              
              <PlanificationForm onSubmit={generatePlanification} fields = {fieldDatas[location]}/>
 
            </Col>
            <Col xs="8">
              <ChatPannel chat={chat} loading={loading} location={location} sendMessage={onNewMessage} onRegenerate={(values)=>{
                  dispatch(startSubmit(PlanificationFormName));
                  generatePlanification(values);


              }}/>
            </Col>
          </Row>
        </div>
      </Paper.Container> 
      <audio hidden ref={audioRef}/>
      <ConfirmationModal message='planification_generator_disclamer' active={!ready} onConfirm={()=>setReady(true)} onCancel={()=>setReady(true)}/>
    </div>
  );
};

export default  PlanificationGenerator ;
