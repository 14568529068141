/* @flow */

import React from 'react';
import { connect } from 'react-redux';
import { pure, compose, lifecycle, defaultProps } from 'recompose';
import actions from '../../actions';
import { change } from 'redux-form';
import _ from 'lodash';
import { DownloadApp } from '../components';

const DownloadAppContainer = () => {
  return <DownloadApp />;
};

const DownloadAppContainerEnhancements = compose(
  defaultProps({
    queryParams: {}
  })
);

const DownloadAppContainerLifecycle = {
  componentWillMount() {
    const queryParams = this.props.queryParams;

    if (queryParams.locale && _.includes(['es', 'en'], queryParams.locale)) {
      this.props.dispatch(actions.changeLanguage({ language: queryParams.locale }));
      this.props.dispatch(change(FORM_NAME, 'locale', queryParams.locale || 'es'));
    }
  }
};

const DownloadAppContainerComposed = compose(
  DownloadAppContainerEnhancements,
  lifecycle(DownloadAppContainerLifecycle),
  pure
)(DownloadAppContainer);

const DownloadAppContainerConnected = connect(
  null,
)(DownloadAppContainerComposed);

export { DownloadAppContainerConnected as DownloadAppContainer }
export default DownloadAppContainerConnected;

