/* @flow */

import { call, put, take, takeEvery, all } from 'redux-saga/effects';
import { stopSubmit } from 'redux-form'; 
import { FORM_NAME as SCHOOL_USER_INVITATION_FORM } from '../js/components/SchoolUserInvitationForm';
import { workerRequest } from '../diplomatico/workers';
import type { Action } from '../diplomatico/types'; 
import { getCourses, removeCoursesSchoolUserInvitation, removeCourses } from '../actions/courses';
import { getSchoolUserInvitations } from '../actions/schoolUserInvitations';

/**
 * Worker to get the school_user_invitations for current school
 */
export const workerRequestSchoolUserInvitationsOptions = {
  requestParams: {
    endpoint: `/school/school_user_invitations`,
    method: 'get'
  }
};

export const workerRequestSchoolUserInvitations = workerRequest(workerRequestSchoolUserInvitationsOptions);

/**
 * Worker to create a school_user_invitation for current school
 */
export const workerRequestSchoolUserInvitationCreateOptions = {
  requestHandlers: {
    onSuccess: function* ({ action, response }: Object): any {
      const school_user_invitation = response.payload.body;
      //yield put(addSchoolUserInvitation(school_user_invitation));
      //yield put(setModalSchoolUserInvitation(school_user_invitation));
      yield put(stopSubmit(SCHOOL_USER_INVITATION_FORM));
      yield put(getSchoolUserInvitations())
    },
    onError: function* ({ action, response }: Object): any {
     yield put(stopSubmit(SCHOOL_USER_INVITATION_FORM));
    }
  },
  requestParams: {
    endpoint: '/school/school_user_invitations',
    method: 'post'
  },
  buildRequestBody: (actionPayload: Object) => { 
    return actionPayload;

   
  }
};

export const workerRequestSchoolUserInvitationCreate = workerRequest(workerRequestSchoolUserInvitationCreateOptions);

 

/**
 * Worker to delete an specific school_user_invitation for current school
 */
export const workerRequestSchoolUserInvitationDeleteOptions = (school_user_invitationId: string) => ({
  requestHandlers: {
    onSuccess: function* ({ action, response }: Object): any {
      const { school_user_invitation } = action.payload;
     // yield put(deleteSchoolUserInvitation(school_user_invitation));
     // yield put(removeCoursesSchoolUserInvitation(school_user_invitation));
     // yield put(stopSubmit(SCHOOL_USER_INVITATION_DATA_FORM));
     // yield put(closeEditSchoolUserInvitationModal());
      yield put(getSchoolUserInvitations())
    },
    onError: function* ({ action, response }: Object): any {
     // yield put(stopSubmit(SCHOOL_USER_INVITATION_DATA_FORM));
     // yield put(closeEditSchoolUserInvitationModal());
    }
  },
  requestParams: {
    endpoint: `/school/school_user_invitations/${school_user_invitationId}`,
    method: 'delete'
  },
  // no payload needed for this request
  buildRequestBody: (actionPayload: Object) => ({})
});

/**
 * Triggers the removal of an specific school_user_invitation
 */
export function* workerRequestSchoolUserInvitationDelete({ type, payload }: Action): any {
  const { id } = payload;
  const workerRequestGenerator = workerRequest(workerRequestSchoolUserInvitationDeleteOptions( id));
  yield call(workerRequestGenerator, { type, payload });
}
 

export function* watchSchoolUserInvitations (): any {
  yield all([
    takeEvery('REQUEST_SCHOOL_USER_INVITATIONS', workerRequestSchoolUserInvitations),
    takeEvery('REQUEST_SCHOOL_USER_INVITATION_CREATE', workerRequestSchoolUserInvitationCreate),
      takeEvery('REQUEST_SCHOOL_USER_INVITATION_DELETE', workerRequestSchoolUserInvitationDelete),
  ]); 
}
       