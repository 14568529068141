/* @flow */

import planetExperienceImage from '../../img/cards/planet_planeta_1.png';
import colourExperienceImage from '../../img/cards/colour_color_1.png';
import organExperienceImage from '../../img/cards/organ_organo_1.png';
import xylophoneExperienceImage from '../../img/cards/xylophone_xilofono_3.png';
import volcanoExperienceImage from '../../img/cards/volcano_volcan_1.png';
import flowerExperienceImage from '../../img/cards/flower_flor_2.png';
import homeExperienceImage from '../../img/cards/home_hogar_1.png';
import yetiExperienceImage from '../../img/cards/yeti_yeti_4.png';
import airplaneExperienceImage from '../../img/cards/airplane_avion_1.png';
import zeppelinExperienceImage from '../../img/cards/zeppelin_zepelin_1.png';

/**
 * Model to hold the data about every experience on the application
 * ideally this should live within the application state and pull the information
 * from the server, making this class a way only to fill the initial information
 * and adding fallbacks to show the expected content
 */
class Experiences {
  static planet = {
    image: planetExperienceImage,
    cost: 250,
    title: 'Planeta Tierra',
    props: {
      newItem: true
    }
  }

  static colour = {
    image: colourExperienceImage,
    cost: 250,
    title: 'Planeta Tierra',
    props: {}
  }

  static organ = {
    image: organExperienceImage,
    cost: 250,
    title: 'Planeta Tierra',
    props: {}
  }

  static xylophone = {
    image: xylophoneExperienceImage,
    cost: 250,
    title: 'Planeta Tierra',
    props: {}
  }

  static volcano = {
    image: volcanoExperienceImage,
    cost: 250,
    title: 'Planeta Tierra',
    props: {}
  }

  static flower = {
    image: flowerExperienceImage,
    cost: 250,
    title: 'Coloreado de dibujos',
    props: {
      age: [3, 5],
      description: 'Aprender a identificar colores y mejorar la motricidad fina al pintar, mejorar la motricidad fina al pintar. Aprender a identificar colores y mejorar la motricidad fina al pintar, mejorar la motricidad fina al pintar. Aprender a identificar colores y mejorar la motricidad fina al pintar, mejorar la motricidad fina al pintar.',
      intelligences: ['visual', 'kinestésica'],
      previews: ['http://placehold.it/235x315/2E9C40/ffffff', 'http://placehold.it/235x315/EFC106/ffffff', 'http://placehold.it/235x315/FEE8CD/ffffff', 'http://placehold.it/235x315/F7D1A9/ffffff'],
      summary: 'Aprender a identificar colores y mejorar la motricidad fina al pintar, mejorar la motricidad fina al pintar.'
    }
  }

  static home = {
    image: homeExperienceImage,
    cost: 250,
    title: 'Planeta Tierra',
    props: {}
  }

  static airplane = {
    image: airplaneExperienceImage,
    cost: 250,
    title: 'Planeta Tierra',
    props: {}
  }

  static yeti = {
    image: yetiExperienceImage,
    cost: 250,
    title: 'Planeta Tierra',
    props: {}
  }

  static zeppelin = {
    image: zeppelinExperienceImage,
    cost: 250,
    title: 'Planeta Tierra',
    props: {}
  }

}

export default Experiences;
