import { times } from 'lodash';

export const fieldDataCLEN=   [
    {
      "id": "education_level",
      "label": "Age",
      "desc": "Choose an Age",
      "fieldValues": [
        [
          "el_2",
          "2 years"
        ],
        [
          "el_3",
          "3 years"
        ],
        [
          "el_4",
          "4 years"
        ],
        [
          "el_5",
          "5 years"
        ],
        [
          "el_6",
          "6 years"
        ] 
      ]
    },

    {
        "id": "expertice_scope",
        "label": "Expertice Scope",
        "desc": "Choose a Scope",
        "fieldValues": [
          [
            "es_1",
            "Personal and Social Development"
          ],
          [
            "es_2",
            "Integral communication"
          ],
          [
            "es_3",
            "Interaction and understanding of the environment"
          ] 
        ]
    },
    {
        "constraints": [
          "expertice_scope"
        ],
        "id": "education_core",
        "label": "Education Core",
        "desc": "Choose a Core",
        "fieldValues": {
          "es_1": [
            [
              "ec_1_1",
              "Identity and autonomy"
            ],
            [
              "ec_1_2",
              "Coexistence and citizenship"
            ],
            [
              "ec_1_3",
              "Corporality and movement"
            ],
          ],
          "es_2": [
            [
              "ec_2_1",
              "Verbal language"
            ],
            [
              "ec_2_2",
              "Artistic languages"
            ] 
          ],
          "es_3": [
            [
              "ec_3_1",
              "Exploration of the natural environment"
            ],
            [
              "ec_3_2",
              "Understanding the sociocultural environment"
            ],
            [
              "ec_3_3",
              "Mathematical Thinking"
            ],
          ] 

        }
    },

    {
        "constraints": [
          "education_core"
        ],
        "id": "education_target",
        "label": "Education Target",
        "desc": "Choose a Target",
        "fieldValues": {
          "ec_1_1":  times(13,(n)=>(["et_oa_"+(n+1), 'OA'+(n+1)])),
          "ec_1_2":  times(11,(n)=>(["et_oa_"+(n+1), 'OA'+(n+1)])),
          "ec_1_3":  times(9,(n)=>(["et_oa_"+(n+1), 'OA'+(n+1)])),

          "ec_2_1":  times(10,(n)=>(["et_oa_"+(n+1), 'OA'+(n+1)])),
          "ec_2_2":  times(7,(n)=>(["et_oa_"+(n+1), 'OA'+(n+1)])),

          "ec_3_1":  times(12,(n)=>(["et_oa_"+(n+1), 'OA'+(n+1)])),
          "ec_3_2":  times(11,(n)=>(["et_oa_"+(n+1), 'OA'+(n+1)])),
          "ec_3_3":  times(12,(n)=>(["et_oa_"+(n+1), 'OA'+(n+1)])),

        }
    },
    {
        "id": "activity_time",
        "label": "Activity Duration",
        "desc": "Choose a duration",
        "fieldValues": [
          [
            "at_15",
            "15 min"
          ],
          [
            "at_30",
            "30 min"
          ],
          [
            "at_45",
            "45 min"
          ] 
        ]
    },  
    {
        "id": "complexity",
        "label": "Level of Complexity",
        "desc": "Choose a level",
        "fieldValues": [
          [
            "c_low",
            "Low"
          ],
          [
            "c_medium",
            "Medium"
          ],
          [
            "c_high",
            "High"
          ] 
        ]
    }

]

export default fieldDataCLEN;
