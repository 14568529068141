/* @flow */

import { call, takeEvery, all } from 'redux-saga/effects';
import { workerRequest } from '../diplomatico/workers';
import type { Action } from '../diplomatico/types';
import moment from 'moment';

export const workerRequestCoursesProgressOverTimeOptions = (level_id: string, start_date: string, end_date: string, group_by: string) => ({
  requestParams: {
    endpoint: `/school/levels/${level_id}/intelligence_scores/history?start_date=${start_date}&end_date=${end_date}&group_by=${group_by}`,
    method: 'get',
    level_id
  }
});

const getDate = (option) => {
  const format = 'YYYY-MM-DD';

  switch (option) {
    case 'month':
      return moment().subtract(6, 'months').format(format);
    case 'week':
      return moment().subtract(3, 'weeks').format(format);
    case 'day':
      return moment().subtract(6, 'days').format(format);
    default:
      return moment().add(1, 'day').format(format);
  }
};

export function* workerRequestCoursesProgressOverTime(action: Action): any {
  const { level, group_by } = action.payload;
  const startDate = getDate(group_by);
  const endDate = getDate();

  let workerRequestGenerator = workerRequest(
    workerRequestCoursesProgressOverTimeOptions(level.id, startDate, endDate, group_by)
  );

  yield call(workerRequestGenerator, action);
}

export function* watchcoursesProgressOverTimeRequest(): any {
  yield all([
    takeEvery('REQUEST_COURSES_PROGRESS_OVER_TIME', workerRequestCoursesProgressOverTime)
  ]);
}
