/* @flow */

import React, { Component } from 'react';
import { Route } from 'react-router';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import TranslationsProvider from './js/providers/TranslationsProvider';
import { setupStore, history } from './setupStore';
import withTracker from './withTracker';
import { AlertContainer, StudentEditFormContainer , StudentCommentListContainer} from './js/containers/';
import App from './App';
import ConfirmationModalContainer from './js/containers/ConfirmationModalContainer';
import HTMLContentModalContainer from './js/containers/HTMLContentModalContainer';
import PdfPrintingScreen from './PdfPrintingScreen';
import { VersionHandler } from './js/hocs/VersionHandler';


class Root extends Component {
  onStorePersisted: () => mixed;
  state: {
    isLoading: boolean,
    store: Object
  };

  constructor() {
    super();
    this.state = {
      isLoading: true,
      store: setupStore(this.onStorePersisted.bind(this))
    };
  }

  onStorePersisted() {
    this.setState({ isLoading: false });
    
   // setTimeout(
     // ()=>this.setState({ isLoading: false }) 
    //, 1000);
  }

  render() {
    const { isLoading, store } = this.state;

    return (isLoading)?null:(  
      <Provider store={store}>
        <VersionHandler />
        <Router history={history}>
          <TranslationsProvider>
            <div >
              <Route component={withTracker(App, {/* additional attributes */})} />
              <AlertContainer />
              <ConfirmationModalContainer />
              <HTMLContentModalContainer />
              <StudentEditFormContainer />
              <StudentCommentListContainer/>
              <PdfPrintingScreen/>
            </div>
          </TranslationsProvider>
        </Router>
      </Provider>
    );
  }
};

export default Root;
