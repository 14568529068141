/* @flow */

import React from 'react';
import { compose, pure, withHandlers, withState } from 'recompose';
import InputProfile from './InputProfile';
import InputFieldColorSelect from './InputFieldColorSelect';
import type { Child, RecomposeStateName, RecomposeHandler, RecomposeStateUpdater } from './types';
import { Field } from 'redux-form';

type Props = {
  fieldName: string,
  expanded: RecomposeStateName,
  selectedColor: string,
  setExpanded: RecomposeStateUpdater,
  toggleExpanded: RecomposeHandler,
  child: Child,
  onRemoveChild: () => mixed,
  submitting: boolean
};

/**
 * A component to display a normal text input, with a color select input
 *
 * @method InputProfileWithColor
 * @param  {boolean}        expanded          - injected by `recompose` as an state, indicates what child should render
 * @param  {function}       setExpanded       - injected by `recompose` as an state updater, to update expanded state
 * @param  {Object}         child             - currently selected child
 * @param  {Function}       toggleExpanded    - injected by `recompose` as a handler, it calls setExpanded
 *
 * @example
 * <InputProfileWithColor
 *   submitting={someBoolean}
 *   selectedColor={someHexString}
 *   nameFieldName={someString}
 * />
 */
const InputProfileWithColor = ({ fieldName, child, studentMode, childIndex, onRemoveChild, selectedColor, expanded, toggleExpanded, submitting }: Props) => {
  return expanded
    ? <Field name={`${fieldName}.color`} component={InputFieldColorSelect} onChange={toggleExpanded} />
    : <InputProfile.Group childToDelete={child} selectedColor={selectedColor} childIndex={childIndex} fieldName={`${fieldName}.name`} submitting={submitting} onAvatarClick={toggleExpanded} onRemoveButtonClick={onRemoveChild} studentMode={studentMode} />;
};

export const InputProfileWithColorHandlers = {
  toggleExpanded: ({ setExpanded, expanded }: Props) => () => setExpanded(!expanded)
};

const InputProfileWithColorComposed = compose(
  withState('expanded', 'setExpanded', false),
  withHandlers(InputProfileWithColorHandlers),
  pure
)(InputProfileWithColor);

export default InputProfileWithColorComposed;
