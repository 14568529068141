export function logout() {
  return {
    type: 'REQUEST_USER_LOGOUT'
  };
}

export function localLogout() {
  return {
    type: 'LOGOUT'
  };
}

export function loggedIn(payload) {
  return {
    type: 'LOGGED_IN',
    payload
  };
}

export function login(payload = {}) {
  const { email, password } = payload;

  return {
    type: 'REQUEST_USER_LOGIN',
    payload: {
      auth: {
        email,
        password
      }
    }
  };
}
export function deleteAccount(payload = {}) {
  const { email, password, reason } = payload;

  return {
    type: 'REQUEST_USER_DELETE',
    payload: { 
        email,
        password,
        reason 
    }
  };
}

export function updateUserInfo(payload) {
  return {
    type: 'UPDATE_USER_INFO',
    payload
  };
}

export function requestUserResetPasswordEmail(payload) {
  const { email } = payload;

  return {
    type: 'REQUEST_USER_RESET_PASSWORD_EMAIL',
    payload: {
      user: {
        email
      }
    }
  };
}

export function requestUserResetPassword(payload) {
  const { password, password_confirmation, password_reset_token } = payload;

  return {
    type: 'REQUEST_USER_RESET_PASSWORD',
    payload: {
      user: {
        password,
        password_confirmation,
        password_reset_token
      }
    }
  };
}
