import React, { Component, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import PubSub from '../models/PubSub';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import Translations from '../../locales/Translations';
import queryString from 'query-string';
 
const TranslationsHelper = (ComponentToWrap) => {
  return class TranslationsHelperComponent extends Component {
    static contextTypes = {
      translations: PropTypes.func.isRequired
    }

    componentDidMount() {
      this._unsubscribe = PubSub.subscribe(this.onTranslationsChange.bind(this));
    }

    componentWillUnmount() {
      this._unsubscribe();
    }

    onTranslationsChange() {
      /*
        Make sure the component will re-render to load the
        right locale values
       */
      this.setState({});
    }

    render() {
      const { translations } = this.context;

      /*
        what we do is basically rendering `ComponentToWrap`
        with an added `translations` prop, like a hook
       */
      return (
        <ComponentToWrap {...this.props} translations={translations} />
      );
    }
  };
};

export const useTranslations = ()=>{
  const [ts, setTs] = useState({});
  useEffect(()=>{
    return PubSub.subscribe( ()=>setTs({}));
  },[]);
  return Translations;
}
export default TranslationsHelper;
