/* @flow */

import React from 'react';
import { Row, Col } from 'reactstrap';
import Translations from '../hocs/TranslationsHelper';
import Paper from './Paper';

type Props = {
  translations: Object
};

/**
 * component to render some info related to the Unique Account and its setup
 *
 * @method UniqueAccountInfo
 * @param  {Object}    translations            - object containing app translations, it gets injected by the Translations HoC
 *
 */
const UniqueAccountInfo = ({ translations }: Props) => {
  const instructions = [
    translations.t('unique_account_config_step_1'),
    translations.t('unique_account_config_step_2'),
    translations.t('unique_account_config_step_3')
  ];
  return (
    <div className="unique-account-info">
      <Row className="justify-content-center">
        <Col xs="12">

          <h2 className="unique-account-info-title text-center">
            {translations.t('unique_account_info_title')}
          </h2>  
          <p className="unique-account-info-text">
            {translations.t('unique_account_info_paragraph_1')}
          </p>
          <p className="unique-account-info-text">
            {translations.t('unique_account_info_paragraph_2')}
          </p>
          <ol className="unique-account-info-instructions">
            {
              instructions.map((instruction, index) => (
                <li key={index} className="unique-account-info-instruction">{instruction}</li>
              ))
            }
          </ol>
        </Col>
      </Row>
    </div>
  );
};

export default Translations(UniqueAccountInfo);
