/* @flow */

import React from 'react';
import CardCubicoin from './CardCubicoin';
import Plans from '../models/Plans';

/**
 * Render plans to offer within the store
 * @method StoreBuyCoins
 */
const StoreBuyCoins = () => {
  return (
    <div className="store-content-buy-coins">
      <div className="buy-coins-header text-center">
        <div className="paper-title">
          <h1>Cubimonedas</h1>
        </div>
        <p>Con las Cubimonedas podrás adquirir nuevos items en la tienda.</p>
      </div>
      <div className="container-card-cubicoin">
        {
          Object.keys(Plans.TYPES).map((plan, key) => {
            return (<CardCubicoin plan={plan} key={key} />);
          })
        }
      </div>
      <div className="buy-coins-footer text-center">
        <div className="paper-heading">
          <h2>Tip: Obtén Cubimonedas gratis</h2>
        </div>
        <p>Al jugar y superar retos en PleIQ, se obtienen Cubimonedas grátis cada día. Luego podras canjaerlas por cualquier item aquí en la tienda.</p>
      </div>
    </div>
  );
};

export default StoreBuyCoins;
