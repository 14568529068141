/* @flow */

import React from 'react';
import {
  Dashboard, Registration, Store, NotFound, ProfileSettings, Intelligence,
  SchoolProgress, SchoolUniqueAccount, SchoolChallenges,ActivityBank,
  SchoolCourses, SchoolStudentDashboard, SchoolStudentIntelligence,
  SchoolSearchStudent,
  CourseReport,
  SchoolUserInvitations,
  SchoolUserSignUp,
  SchoolUsers,
  SchoolUser,
  SchoolHome,
  SchoolResources,
} from './js/screens/'; 
import { CongratulationsContainer } from './js/containers';
import { Route, Redirect, Switch } from 'react-router';
import { Intelligences } from './js/models';
import {connect} from 'react-redux'
import { 
  PrivateRoute, WithQueryParamsRoute, ParentRoute,
  SchoolRoute, WithQueryParamsGuestRoute
} from './setupRoutes';
import { required } from './js/components/validations';
import CaligrafixProgress from './js/screens/CaligrafixProgress';
import VideoTutorials from './js/screens/VideoTutorials';
import SchoolProgressReports from './js/screens/progress-reports/SchoolProgressReports';
import SchoolProgressReportEdit from './js/screens/progress-reports/SchoolProgressReportEdit';
import SchoolProgressReportCmp from './js/screens/progress-reports/SchoolProgressReportCmp';
import SchoolPremiumPromo from './js/screens/SchoolPremiumPromo'
import PlanificationGenerator from './js/screens/planifications-generator/PlanificationGenerator';
import PlanificationSaved from './js/screens/planifications-generator/PlanificationsSaved';
import GeneratedPlanifications from './js/screens/planifications-generator/GeneratedPlanifications';
import DigitalPaper from './js/screens/DigitalPaper';
import DigitalPaperBook from './js/screens/DigitalPaperBook';
import PGLocationSelection from './js/screens/planifications-generator/PGLocationSelection';

class Main extends React.Component {
  render() {
    const {license_type,user} = this.props; 
    return (
      <Switch>
        <ParentRoute exact path='/' component={Dashboard} />
        <ParentRoute path='/activate' component={Registration.PleiqCode} />
        <ParentRoute path='/add_children' component={Registration.ChildrenInfo} />
        {/* This is for defaulting to /dashboard/all */}
        <Redirect exact from='/dashboard' to='/dashboard/all' />
        <ParentRoute exact path='/dashboard/all' component={Dashboard} />
        <ParentRoute path='/dashboard/:topic' component={Intelligence} />
        {/* This is for defaulting to /school/progress */}
        {
          /*(license_type==='pleiq_school')?
            <Redirect exact from='/school' to='/school/progress' />
          :<Redirect exact from='/school' to='/school/caligrafix' />*/
        }
         <Redirect exact from='/school' to='/school/home' />
        {
          //(license_type!='pleiq_cx')?
          <SchoolRoute path='/school/progress' component={SchoolProgress} /> 
        }
        <SchoolRoute path='/school/home' component={SchoolHome} />
        <SchoolRoute path='/school/digital_paper/:book_id' component={DigitalPaperBook} /> 
        <SchoolRoute path='/school/digital_paper' component={DigitalPaper} />
        <SchoolRoute path='/school/resources' component={SchoolResources} /> 
        <SchoolRoute path='/school/progress_reports/edit' component={SchoolProgressReportEdit} /> 
        <SchoolRoute path='/school/progress_reports/cmp' component={SchoolProgressReportCmp} /> 
        <SchoolRoute path='/school/progress_reports' component={SchoolProgressReports} /> 
        
        {
         <SchoolRoute path='/lesson_plans/:kind' component={ActivityBank} /> 
        }
        <SchoolRoute path='/video_tutorials/:kind' component={VideoTutorials} /> 
        {
         // (license_type!=undefined && license_type.startsWith('pleiq_cx'))? 
          <SchoolRoute path='/school/caligrafix' component={CaligrafixProgress} /> 
        }
        <SchoolRoute path='/school/courses' component={SchoolCourses} />
        <Route path='/school/pro' component={SchoolPremiumPromo} />
        <WithQueryParamsRoute path='/school/invitations' component={SchoolUserInvitations} />
        <WithQueryParamsRoute path='/school/users/:user_id' component={SchoolUser} />
        {
          //<WithQueryParamsRoute path='/school/users' component={SchoolUsers} />
        }
        <SchoolRoute premium path= '/school/gptx/saved' component={PlanificationSaved} />
        <SchoolRoute premium path= '/school/gptx/library' component={GeneratedPlanifications} />
        <SchoolRoute premium path= '/school/gptx/selection' component={PGLocationSelection} />
        <SchoolRoute premium path= '/school/gptx' component={PlanificationGenerator} />

        <SchoolRoute path='/school/pdf/course/:course_id' component={CourseReport} />
        <SchoolRoute path='/school/student/search/:query' component={SchoolSearchStudent} />
        <SchoolRoute path='/school/student/:student_id/general' component={SchoolStudentDashboard} />
        <SchoolRoute path='/school/student/:student_id/:slug' component={SchoolStudentIntelligence} />
        <Redirect exact from='/school/challenges' to={`/school/challenges/${Intelligences.DEFAULT_SLUG}`} />
        <SchoolRoute path='/school/challenges/:slug' component={SchoolChallenges} />
        <SchoolRoute path='/school/users' component={SchoolUniqueAccount} />
        <PrivateRoute path='/store' component={Store} />
        <PrivateRoute path='/settings' component={ProfileSettings} />
        <WithQueryParamsGuestRoute path='/school/signup' component={SchoolUserSignUp} />
        <WithQueryParamsGuestRoute path='/sign_up' component={Registration.ParentSignUpForm} />
        <WithQueryParamsGuestRoute path='/login' component={Registration.SignIn} />
        <WithQueryParamsGuestRoute path='/director' component={Registration.DirectorIE} />
        <Route path='/download' component={Registration.DownloadApp} />
        <Route path='/delete' component={Registration.AccountDelete} />
        <Route path='/deleted' component={Registration.AccountDeleted} />
        <Route path='/users/password/new' component={Registration.UserPasswordNewForm} />
        <WithQueryParamsRoute path='/users/password/edit' validate={{ token: [required] }} component={Registration.UserPasswordResetForm} /> 
       
        <PrivateRoute path='/congratulations' component={CongratulationsContainer} />
        <Route path='/account_confirmed' component={Registration.AccountConfirmed} />
        <Route component={NotFound} />
      </Switch>
    );
  }
}

const App =connect(({user})=>({
  license_type: user.license_type,
  user
}))(Main);

export default App;
