/* @flow */

import { responseHasError } from '../diplomatico/reducers/helper';
import type { Action } from '../diplomatico/types';
import { enableInitializing } from 'initializable-reducer';
import _ from 'lodash';

const initialState = {};

function activity(state: Object = initialState, { type, payload }: Action) {
  let newState = {};
  let responseBody;

  switch (type) {
    case 'RESPONSE_ACTIVITY':
      responseBody = payload.body;

      if (responseHasError(payload) || _.isEmpty(responseBody)) {
        return state;
      }

      newState[responseBody[0].child_id] = responseBody;

      return {
        ...state,
        ...newState
      };

    default:
      return state;
  }
}

export {
  activity,
  initialState
};

export default enableInitializing(activity);