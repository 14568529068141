/* @flow */

import React from 'react';
import { Field } from 'redux-form';
import InputProfileWithColor from './InputProfileWithColor';
import InputFieldSelect from './InputFieldSelect';
import { required } from './validations';
import Translations from '../hocs/TranslationsHelper';
import type { TranslationsObject } from './types';
import _ from 'lodash';

const MIN_AGE = 1;
const MAX_AGE = 15;

export function generateChildAgeOptions(translations: TranslationsObject) {
  return _.range(MIN_AGE, (MAX_AGE + 1))
    .map((value) => {
      const ageLabel = translations.t('years_old', { smart_count: value });
      return { value: `${value}`, label: `${ageLabel}` };
    });
}

const UserChildForm = ({ name, submitting, studentMode, child, color, index, selectedColor, onRemoveChild, translations }) => {
  const selectOptions = generateChildAgeOptions(translations);

  return (
    <div className="user-child-form">
      <div className="user-child-form-name-input">
        <InputProfileWithColor
          studentMode={studentMode}
          selectedColor={selectedColor}
          fieldName={name}
          child={child}
          childIndex={index}
          onRemoveChild={onRemoveChild}
          submitting={submitting}
        />
      </div>
      <Field
        name={`${name}.age`}
        placeholder={translations.t('age')}
        selectOptions={selectOptions}
        component={InputFieldSelect}
        searchable={false}
        validate={[ required ]}
      />
    </div>
  );
};

export default Translations(UserChildForm);
