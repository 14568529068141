/* @flow */

import React from 'react';
import { connect } from 'react-redux';
import { pure, compose, withHandlers, withState } from 'recompose';
import { ChildrenForm } from '../components/';
import { startSubmit, stopSubmit } from 'redux-form';
import type { RecomposeHandler, RecomposeStateUpdater, RecomposeStateName, ReduxFunction } from '../components/types';
import { FORM_NAME as CHILDREN_FORM_NAME } from '../components/ChildrenForm';
import { availableColors } from '../components/ColorSelect';
import uuid from 'uuid';
import _ from 'lodash';

type Props = {
  onSubmit: RecomposeHandler,
  dispatch: ReduxFunction,
  children: RecomposeStateName,
  updateChildren: RecomposeStateUpdater,
  activeIndex: RecomposeStateName,
  updateActiveIndex: RecomposeStateUpdater,
  onCreateChild: RecomposeStateUpdater,
  onDeleteChild: RecomposeStateUpdater,
  onUpdateChild: RecomposeStateUpdater,
  onSelectChild: RecomposeStateUpdater
};

/**
 * Component should mantain the children added by the parent,
 * in order to save them using the api once the form gets submitted
 *
 * @method ChildrenFormContainer
 * @param  { function }  onSubmit         - injected by `recompose` as a handler, it'll be passed to the form
 * @param  { function }  onCreateChild    - injected by `recimpose` as an state updater, creates a child and adds it to the children array
 * @param  { function }  onDeleteChild    - injected by `recimpose` as an state updater, removes a child from the children array
 * @param  { function }  onUpdateChild    - injected by `recimpose` as an state updater, update a child from the children array
 * @param  { function }  onSelectChild    - injected by `recimpose` as an state updater, updates activeIndex
 */
const ChildrenFormContainer = (props: Props) => {
  return <ChildrenForm {...props} />;
};

const validateChildren = (children) => {
  return children.every((child) => {
    return child.age && child.color && child.name;
  });
};

export const ChildrenFormContainerHandlers = {
  onSubmit: ({ children, dispatch }: Props) => () => {
    if (validateChildren(children)) {
      dispatch(startSubmit(CHILDREN_FORM_NAME));
      dispatch({ type: 'REQUEST_CHILDREN_CREATE', payload: { children, initApp: true } });
    } else {
      dispatch(stopSubmit(CHILDREN_FORM_NAME, { _error: 'all_fields_are_required' }));
    }
  },
  onCreateChild: ({ children, updateChildren, activeIndex, updateActiveIndex }: Props) => () => {
    let newChildrenSet = [...children, { ...defaultChild() }];
    updateChildren(newChildrenSet);
    updateActiveIndex(newChildrenSet.length - 1);
  },
  onDeleteChild: ({ children, updateChildren, activeIndex, updateActiveIndex }: Props) => () => {
    let newChildrenSet = children.filter((child, index) => index !== activeIndex);
    let newActiveIndex = activeIndex > 0 ? (activeIndex - 1) : 0;

    updateActiveIndex(newActiveIndex);
    updateChildren(newChildrenSet);
  },
  onUpdateChild: ({ children, updateChildren, activeIndex }: Props) => (newInfo: Object) => {
    let newChildrenSet = [
      ...children.slice(0, activeIndex),
      { ...children[activeIndex], ...newInfo },
      ...children.slice(activeIndex + 1)
    ];

    updateChildren(newChildrenSet);
  },
  onSelectChild: ({ updateActiveIndex }: Props) => (childIndex: number) => {
    updateActiveIndex(childIndex);
  }
};

export const defaultChild = () => ({ color: _.sample(availableColors), reference: uuid.v4() });

const ChildrenFormContainerEnhancements = compose(
  withState('children', 'updateChildren', [defaultChild()]),
  withState('activeIndex', 'updateActiveIndex', 0),
  withHandlers(ChildrenFormContainerHandlers)
);

const mapDispatchToProps = (dispatch) => {
  return { dispatch };
};
const mapStateToProps = ({ user  }) => {
   

  return { 
    maxChildren:user.child_capacity_limit
  };
};

const ChildrenFormContainerComposed = compose(
  ChildrenFormContainerEnhancements,
  pure
)(ChildrenFormContainer);

const ChildrenFormContainerConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(ChildrenFormContainerComposed);

export default ChildrenFormContainerConnected;
