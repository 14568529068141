
import React, { useContext, useState } from 'react';
import { Dropdown } from '../../components';
import TranslationsHelper from '../../hocs/TranslationsHelper';
import { useData } from '../../hocs/DataRetriever';

export const DataDropDown=TranslationsHelper(  ({label, className, dataUrl,translations, onItemSelect,selectedItem})=>{
    
    const [search,setSearch] = useState("");
    const {data  , loading} = useData({id: dataUrl, url: dataUrl+"?q=" + search}); 
    let selectOptions = [];
    if(data && data.body ){
      let arr = [];

      if(Array.isArray(data.body))
        arr = data.body;
      else if(data.body.data)
        arr = data.body.data;
      selectOptions = arr.map(({id,name})=>({
          label:name,
          value:id
      }));
    }
     
    if(selectedItem &&  !selectOptions.some(({value})=>value==selectedItem.id))
      selectOptions.push({
        label:selectedItem.name,
        value:selectedItem.id}
    );

    return         <Dropdown
    className={className}
    value={selectedItem&& selectedItem.id}
    options={selectOptions}
    placeholder={translations.t(label)}
    onChange={onItemSelect}
    onBlur={()=>{}}
    disabled={false}
    onInputChange={(s)=>{setSearch(s)}}
    isLoading={loading}
    noResultsText={false}
   // this prevents react-select to try to do any option filtering,
   // as we already handle the search
    filterOption={() => true}
    searchable
  />
});

export default DataDropDown;