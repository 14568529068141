/* @flow */

import React from 'react';
import { connect } from 'react-redux';
import { Header, RegistrationHeader } from '../components';

const HeaderContainer = ({ user }) => {
  return user.is_logged_in
    ? <Header user={user}/>
    : <RegistrationHeader />;
};

const mapStateToProps = (state) => ({ user: state.user });

const HeaderContainerConnected = connect(
  mapStateToProps
)(HeaderContainer);

export default HeaderContainerConnected;

