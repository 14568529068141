/* @flow */

export function showConfirmationModal(payload: Object) {
  return {
    type: 'SHOW_CONFIRMATION_MODAL',
    payload
  };
}

export function hideConfirmationModal() {
  return {
    type: 'HIDE_CONFIRMATION_MODAL'
  };
}
