/* @flow */

import { call, takeEvery, all } from 'redux-saga/effects';
import { workerRequest } from '../diplomatico/workers';
import type { Action } from '../diplomatico/types';

/**
 * Worker to handle the request of Student CaligrafixBook Progress
 */
export const workerRequestStudentCaligrafixBookProgressOptions = (student_id: string) => ({
  requestParams: {
    endpoint: `/school/students/${student_id}/caligrafix_book_scores/current`,
    method: 'get',
    student_id
  }
});

export function* workerRequestStudentCaligrafixBookProgress(action: Action): any {
  let { student_id } = action.payload;
  let workerRequestGenerator = workerRequest(workerRequestStudentCaligrafixBookProgressOptions(student_id));

  yield call(workerRequestGenerator, action);
}

export const workerRequestStudentCaligrafixBookHistoryOptions = ({ student_id, caligrafix_book_id, group_by, start_date, end_date }) => ({
  requestParams: {
    endpoint: `/school/students/${student_id}/caligrafix_book_scores/history?caligrafix_book_id=${caligrafix_book_id}&start_date=${start_date}&end_date=${end_date}&group_by=${group_by}`,
    method: 'get',
    student_id,
    caligrafix_book_id,
    group_by
  }
});

export function* workerRequestStudentCaligrafixBookHistory(action: Action): any {
  let workerRequestGenerator = workerRequest(workerRequestStudentCaligrafixBookHistoryOptions(action.payload));

  yield call(workerRequestGenerator, action);
}
 

export function* watchStudentCaligrafixBookProgressRequest(): any {
  yield all([
    takeEvery('REQUEST_STUDENT_CALIGRAFIX_BOOK_PROGRESS', workerRequestStudentCaligrafixBookProgress),
    takeEvery('REQUEST_STUDENT_CALIGRAFIX_BOOK_HISTORY', workerRequestStudentCaligrafixBookHistory), 
  ]);
}
