/* @flow */

import { enableInitializing } from 'initializable-reducer';

const initialState = {
  active: false
};

function htmlContentModal(state: Object = initialState, { type, payload }: Object) {
  switch (type) {
    case 'SHOW_HTML_CONTENT_MODAL':
      return {
        active: true,
        ...payload
      };
    case 'HIDE_HTML_CONTENT_MODAL':
      return initialState;
    default:
      return state;
  }
}

export {
  htmlContentModal,
  initialState
};

export default enableInitializing(htmlContentModal);
