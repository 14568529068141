/* @flow */

import { responseHasError } from '../diplomatico/reducers/helper';
import type { Action } from '../diplomatico/types';
import { enableInitializing } from 'initializable-reducer';
import _ from 'lodash';

const initialState = {};

function schoolGeneralCoursesProgress(state: Object = initialState, { type, payload }: Action) {
  switch (type) {
    case 'RESPONSE_SCHOOL_GENERAL_COURSES_PROGRESS':
      if (responseHasError(payload)) {
        return state;
      }

      const { level_id } = payload._meta.payload.request;

      const newLevel = {
        [level_id]: payload.body
      };

      return _.assign({}, state, newLevel);
    default:
      return state;
  }
}

export {
  schoolGeneralCoursesProgress,
  initialState
};

export default enableInitializing(schoolGeneralCoursesProgress);
