/* @flow */

import { enableInitializing } from 'initializable-reducer';
import type { Action } from '../diplomatico/types';

const initialState = {
  active: false,
  level: {}
};

function levelEditModal(state: Object = initialState, { type, payload }: Action) {
  switch (type) {
    case 'SHOW_LEVEL_EDIT_MODAL':
      return {
        active: true,
        ...payload
      };
    case 'HIDE_LEVEL_EDIT_MODAL':
      return { ...initialState };
    case 'SET_MODAL_LEVEL':
      return {
        ...state,
        ...payload
      };
    default:
      return state;
  }
}

export {
  levelEditModal,
  initialState
};

export default enableInitializing(levelEditModal);
