/* @flow */

import React from 'react';
import { connect } from 'react-redux';
import { compose, withHandlers, pure } from 'recompose';
import _ from 'lodash';
import { startSubmit, arrayRemove } from 'redux-form';
import { UserChildrenForm } from '../components/';
import { ParentComponent } from '../components/ParentComponent';
import { FORM_NAME } from '../components/UserChildrenForm';
import type { Child, ReduxFunction } from '../components/types';
import { handleChildrenEdition } from '../../actions/children';
import { showConfirmationModal } from '../../actions/confirmationModal';
import { getChildrenWithDisplayColors } from '../../selectors/children';
import { Paper } from '../components/';
import Translations from '../hocs/TranslationsHelper';

type Props = {
  children: Array<Child>,
  dispatch: ReduxFunction
};

const UserChildrenFormContainer = ({ children, translations, ...otherProps }: Props) => {
  return (
    <div>
      <Paper.Nav>
        <Paper.NavItem iconClass="pleiq-icon-icon_settings_profiles" label={ translations.t('account_settings_profiles') } />
      </Paper.Nav>
      <UserChildrenForm
        initialValues={{ children }}
        {...otherProps}
      />
    </div>
  );
};

export const UserChildrenFormContainerHandlers = {
  onSubmit: ({ dispatch, children }: Props) => (values: Object) => {
    const newChildren = values.children.filter(child => !child.id);

    const updatedChildren = values.children.filter((child) => {
      const lookUpChild = children.find((otherChild) => otherChild.id === child.id);

      if (!lookUpChild) {
        return false;
      }

      /**
       * child will have less properties than lookUpChild,
       * so we put it first to check its keys against lookUpChild
       */
      if (!_.isEqual(child, lookUpChild)) {
        return true;
      }

      return false;
    });

    dispatch(startSubmit(FORM_NAME));
    dispatch(handleChildrenEdition({
      newChildren,
      updatedChildren,
      formName: FORM_NAME
    }));
  },
  onRemoveChild: ({ dispatch, children }: Props) => (child: Child, index: mixed) => {
    const persisted = child.id;
    /**
     * if child id is not set, it means is not yet persisted on the api
     * so we can remove it directly from the form data.
     */
    if (!persisted) {
      dispatch(arrayRemove(FORM_NAME, 'children', index));
      return;
    }

    const deletedChildren = [child];

    const actions = [
      startSubmit(FORM_NAME),
      handleChildrenEdition({
        deletedChildren,
        formName: FORM_NAME,
        onSuccessActions: [
          arrayRemove(FORM_NAME, 'children', index)
        ]
      })
    ];

    dispatch(showConfirmationModal({
      title: 'delete_child_confirmation_title',
      message: 'delete_child_confirmation_message',
      actions
    }));
  }
};

const UserChildrenFormContainerComposed = compose(
  withHandlers(UserChildrenFormContainerHandlers),
  ParentComponent,
  pure
)(UserChildrenFormContainer);

const mapStateToProps = ({ children, user }) => {
  const { account_type ,child_capacity_limit } = user;

  return ({
    /*
      converting children color for string to a hex string,
      as components expect this for display purposes
   */
    maxChildren:child_capacity_limit, 
    children: getChildrenWithDisplayColors({ children }),
    account_type
  });
};

const mapDispatchToProps = (dispatch) => ({ dispatch });

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Translations(UserChildrenFormContainerComposed));
