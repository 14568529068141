/* @flow */

import React from 'react';
import { CourseCollapseItemContainer } from '../containers';
import type { Course } from './types';

type Props = {
  courses: Array<Course>
};

/**
 * component to display a Course with the students inside a collapse
 *
 * @method CourseCollapseList
 * @param  {Array<Course>}  courses     - contains all the courses to be rendered
 *
 */
const CourseCollapseList = ({ courses,all }: Props) => {
  return (
    <div className="course-collapse-list">
      {courses.map((course: Course) => {
        return (
          <CourseCollapseItemContainer
            key={course.id}
            course={course}
            all={all}
          />
        );
      })}
    </div>
  );
};

export default CourseCollapseList;
