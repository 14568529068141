/* @flow */

import { call, takeEvery, all } from 'redux-saga/effects';
import { workerRequest } from '../diplomatico/workers';
import type { Action } from '../diplomatico/types';

/**
 * Worker to handle the request of CaligrafixBook Progress
 */
export const workerRequestCaligrafixBookProgressOptions = (child_id: string) => ({
  requestParams: {
    endpoint: `/children/${child_id}/caligrafix_book_scores/current`,
    method: 'get',
    child_id
  }
});

export function* workerRequestCaligrafixBookProgress(action: Action): any {
  let { child_id } = action.payload;
  let workerRequestGenerator = workerRequest(workerRequestCaligrafixBookProgressOptions(child_id));

  yield call(workerRequestGenerator, action);
}

export function* watchCaligrafixBookProgressRequest(): any {
  yield all([
    takeEvery('REQUEST_CALIGRAFIX_BOOK_PROGRESS', workerRequestCaligrafixBookProgress)
  ]);
}
