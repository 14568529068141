import React, { useState } from 'react';
import { Form, FormGroup, FormFeedback } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { SubmitButton } from '../../../components';
import TranslationsHelper from '../../../hocs/TranslationsHelper';
import classNames from 'classnames';
import { required } from '../../../components/validations';
import { times } from 'lodash';
import { coreMap, oaMap } from '../PlanificationValuesMap';
import { PlanificationField } from './PlanificationField';
export let PlanificationFormMX = ({ error, fields, handleSubmit, persisted, submitting, valid, dirty, change, translations }) => {


  const formClassName = classNames({ 'has-danger': error }, 'form', "student-data-form");

  const validCores = useState([]);
  const validTargets = useState([]);

  return (
    <Form className={formClassName} onSubmit={handleSubmit}>

      <div>
        {error &&
          <FormGroup>
            <FormFeedback>{translations.t(error)}</FormFeedback>
          </FormGroup>}

        <div className="d-flex flex-column">

          <Field
            name="education_level"
            placeholder={"Nivel Educativo"}
            selectOptions={[
              { value: '3', label: "3 años" },
              { value: '4', label: "4 años" },
              { value: '5', label: "5 años" },
              { value: '6', label: "6 años" },
            ]}
            validate={[required]}
            desc="Elige un Nivel"
            component={PlanificationField}
            disabled={submitting} />
          <Field
            name="education_axis"
            placeholder={"Eje"}
            selectOptions={[
                "Inclusión",
                "Pensamiento crítico",
                "Interculturalidad crítica",
                "Igualdad de género",
                "Vida saludable",
                "Apropiación de las culturas a través de la lectura y la escritura",
                "Artes y experiencias estéticas "
                ].map((v)=>({value:v, label: v}))}
            desc="Elige un Eje"
            validate={[required]}
            onChange={(e, value) => { 

            }}
            component={PlanificationField}
            disabled={submitting} />
           
          <Field
            name="activity_time"
            placeholder={"Tiempo de la Actividad"}
            selectOptions={[
              { value: '15', label: '15min' },
              { value: '30', label: '30min' },
              { value: '45', label: '45min' },
            ]}
            validate={[required]}
            component={PlanificationField}
            desc="Elige una duración"
            disabled={submitting} />
          <Field
            name="complexity"
            placeholder={"Nivel de Complejidad"}
            selectOptions={[
              { value: 'Baja', label: 'Bajo' },
              { value: 'Media', label: 'Medio' },
              { value: 'Alta', label: 'Alto' },
            ]}
            validate={[required]}
            component={PlanificationField}
            desc="Elige un Nivel"
            disabled={submitting} />
          <SubmitButton loading={submitting} className="btn-primary--green gpt-planification-button align-self-center" disabled={!valid}>

            <span className="pleiq-icon-icon_play" />
          </SubmitButton>
        </div>
        {persisted}
      </div>
    </Form>
  );
};
export const FORM_NAME_MX = 'schoolPlanificationGeneratorMX';
PlanificationFormMX = reduxForm({
  form: FORM_NAME_MX,
})(TranslationsHelper(PlanificationFormMX));
