/* @flow */

import randomMC from 'random-material-color';
import moment from 'moment';
import { getFormatedDate } from '../helpers/defaultChartDomain';

/*
 * This method expected the data in the format of the following state keys
 * 1. coursesProgress from coursesProgressOverTime
 * 2. courses from courses
 *
 * Return a object structure looks like this:
 * {
 *   data: [{ name: 'ENE.', 'uuid-1': 10, 'uuid-2': 40, 'uuid-3': 20 }],
 *   linesData: [{ name: 'course-1', color: '#f7602c' }, ...]
 * }
 *
 * @return [Array] Data to be rendered in LineChart component
 */
export const courseProgressComparisonToLineChart = (coursesProgressOverTime: Object = {}, courses: Array<Object>, language: string, selectedOption: string, translations: Object = {}) => {
  moment.locale(language);

  let data = Object.keys(coursesProgressOverTime).map((date) => {
    let monthDataSet = coursesProgressOverTime[date];

    return {
      name: getFormatedDate(date, selectedOption, translations),
      ...monthDataSet
    };
  });

  let linesData = courses.map((course) => {
    return {
      id: course.id,
      name: course.name,
      color: randomMC.getColor({ text: `${course.id}${course.name}` })
    };
  });

  return {
    data,
    linesData
  };
};
