/* @flow */

import React from 'react';
import { Nav, NavLink } from 'reactstrap';
import { pure, compose, withHandlers } from 'recompose';
import ProfileAvatar from './ProfileAvatar';
import { apiValidColorValues } from './ColorSelect';
import type { Child, TranslationsObject } from './types';
import Translations from '../hocs/TranslationsHelper';
import classNames from 'classnames';
import _ from 'lodash';

type Props = {
  children: Array<Child>,
  className: string,
  onClickChildProfile: () => mixed,
  onClickNavLink: () => mixed,
  translations: TranslationsObject
};

/**
 * A list of the children for a given user
 *
 * @method ProfileList
 */
const ProfileList = ({ onClickNavLink, children, translations, className }: Props) => {
  const profileClassName = classNames(className, 'profile-list');

  if (_.isEmpty(children)) {
    return (
      <Nav className={profileClassName}>
        <NavLink className="navbar-header" tag="li"> { translations.t('no_children_yet') }</NavLink>
      </Nav>
    );
  }

  return (
    <Nav className={profileClassName} >
      <NavLink className="navbar-header" tag="li"> { translations.t('current_profile').toUpperCase() }</NavLink>
      {
        children.map((child, key) => {
          let color = _.findKey(apiValidColorValues, _.matches(child.color));
          return (
            <NavLink
              key={key}
              onClick={() => onClickNavLink(children[key])}
              tag="li"
              active={child.active}>
              <ProfileAvatar color={color} />
              <span className="truncate-child-name">{child.name}</span>
            </NavLink>
          );
        })
      }
    </Nav>
  );
};

export const ProfileListHandlers = {
  onClickNavLink: (props: Props) => (child: Child) => {
    props.onClickChildProfile(child);
  }
};

const ProfileListComposed = compose(
  withHandlers(ProfileListHandlers),
  pure
)(ProfileList);

export default Translations(ProfileListComposed);
