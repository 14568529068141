import { StudentGeneralIntelligencesStatic } from "../containers/StudentIntelligenceProgressContainer"
import { StudentVsCourseGraphStatic } from "../containers/StudentVsCourseGraphContainer"

import React from 'react';
import {connect} from 'react-redux' 
import { StudentEffortVsPerformanceGraphStatic } from "../containers/StudentEffortVsPerformanceGraphContainer";
import {  withPdf, withPdf2 } from "../../pdfer/Pdfable";
//import { lifecycle, compose } from "recompose";
import actions from '../../actions';
import { findStudentAcrossReducers } from '../../selectors/students';
import _ from 'lodash'; 
import Translations from '../hocs/TranslationsHelper';
import { Paper, InfoTooltip } from '../components';
import { Col, Row } from 'reactstrap';
import { DataDisplayStudentPdf } from "../containers/DataDisplayStudentContainer";
import { getSchoolGeneralCoursesProgress } from "../../actions/schoolGeneralCoursesProgress";
import { getCourseById } from "../../selectors/courses";
import { getLevelById } from "../../selectors/levels";
import { SchoolStudentComments } from "../containers/SchoolStudentDashboardComments";
import { StudentGeneralCaligrafixBooksStatic } from "../containers/StudentCaligrafixBookProgressContainer";
import { DataDisplayCaligrafixStudentPdf } from "../containers/DataDisplayCaligrafixStudentContainer";

 
export var StudentPdf = Translations( ({translations,student, student_id})=>{
   // console.log("rendering StudentPdf", student_id); 
    if(!student)
      return;

    return (
          <div>
            <Row>
            <Col xs="12">
              <h2>
                {translations.t("report_for_student") } <span>
                  {student.name}
                </span>  
              </h2>
            </Col>  
            <Col xs="12">
              <Paper.Content>
                  <StudentGeneralIntelligencesStatic student_id={student.id}/> 
              </Paper.Content>
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <Paper.Title>
                <h1 className="mt-5">
                  { translations.t('intelligences_progress_analysis') } {' '}
                  <InfoTooltip message="tooltips_intelligences_progress_analysis" />
                </h1>
              </Paper.Title>
              <Paper.Content>
                <DataDisplayStudentPdf student_id={student.id}/> 
              </Paper.Content>
            </Col>
          </Row>
          <Row>
            <Col>
              <Paper.Title>
                <h1 className="mt-5">
                  { translations.t('against_course') } {' '}
                  <InfoTooltip message="tooltips_intelligences_progress_analysis" />
                </h1>
              </Paper.Title>
              <Paper.Content>
                <StudentVsCourseGraphStatic student_id={student.id}/>  
              </Paper.Content>
            </Col>
            <Col>
              <Paper.Title>
                <h1 className="mt-5">
                  { translations.t('effort_vs_performance') } {' '}
                  <InfoTooltip message="tooltips_intelligences_progress_analysis" />
                </h1>
              </Paper.Title>
              <Paper.Content>
                <StudentEffortVsPerformanceGraphStatic student_id={student.id}/>  
              </Paper.Content>
            </Col>
          </Row>
          </div> 
    );
} );
export var StudentCaligrafixPdf = Translations( ({translations,student, student_id})=>{
   // console.log("rendering StudentPdf", student_id); 
    if(!student)
      return;

    return (
          <div>
            <Row>
            <Col xs="12">
              <h2>
                 {translations.t("report_for_student") } <span>
                  {student.name}
                </span>  
              </h2>
            </Col>  
            <Col xs="12">
              <Paper.Content>
                  <StudentGeneralCaligrafixBooksStatic student_id={student.id}/> 
              </Paper.Content>
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <Paper.Title>
                <h1 className="mt-5">
                  { translations.t('caligrafix_progress_analysis') } {' '}
                  <InfoTooltip message="tooltips_intelligences_progress_analysis" />
                </h1>
              </Paper.Title>
              <Paper.Content>
                <DataDisplayCaligrafixStudentPdf student_id={student.id}/> 
              </Paper.Content>
            </Col>
          </Row>  
          </div> 
    );
} );
export var StudentCommentsPdf = Translations( ({translations,student, student_id})=>{
   // console.log("rendering StudentPdf", student_id); 
    if(!student)
      return;

    return (
          <div>
            <Row>
            <Col xs="12">
              <h2>
                {translations.t("comments_for") } <span>
                  {student.name}
                </span>  
              </h2>
            </Col>  
            <Col xs="12">
              <Paper.Content>
                  <SchoolStudentComments match={{params:{student_id:student.id}}} hideHeader/> 
              </Paper.Content>
            </Col>
          </Row> 
          </div> 
    );
} ); 


const mapStateToProps2 = (state ) => { 
  
 // console.log("map state 2 being called", state.pdf );
  const { student_id } = state.pdf.props; 
  const student = findStudentAcrossReducers(state, student_id);  
  let level_id;
  if( student){
    level_id =   getCourseById(state,student.course_id).level_id ;
  //  console.log("mappppping", student, level_id,getCourseById(state,student.course_id));

  }

 // console.log("active requestss", state.activeRequests);

  return {
    level_id,
    student_id: _.get(student, 'id') ,
    student: student,
    pre_done: state.activeRequests===0
  }
};
var LE = 0;
const mapStateToProps3 = (state, ownProps) => { 
  const { student_id } = ownProps; 
  const student = findStudentAcrossReducers(state, student_id); 
  //console.log("map state 3 being called",student_id, student);
  return {
    student_id: _.get(student, 'id') ,
    pre_done: state.activeRequests===0,
    student: student,
    path: ownProps.path,
    student_intelligence_progress: state.student_intelligence_progress
  }
};


const mapDispatchToProps2 = (dispatch) => ({ dispatch });


export var StudentPdfSelection =  connect(mapStateToProps2,mapDispatchToProps2)(  withPdf2 ({ filter: "single_student"
, pre:({student_id,student, dispatch, level_id})=>{
// console.log("UPDATEING STIUDENT", student_id,"level id:", level_id);
  //dispatch(getSchoolGeneralCoursesProgress({ level_id: this.props.currentCourse.level_id }));
/*
  if(!student || student_id!==student.id){
    dispatch(actions.getStudentIntelligenceProgress({ student_id }))
    return true;
  }
  return false;*/
  dispatch(actions.getStudentIntelligenceProgress({ student_id }))
  dispatch(getSchoolGeneralCoursesProgress({ level_id }));
  return true;
 // 
}, props: {path:"student.current.content",newpage:true}  })(({ student})=>{ 
    return  <StudentPdf student={student}/>  
}));


export var StudentCaligrafixPdfSelection =  connect(mapStateToProps2,mapDispatchToProps2)(  withPdf2 ({ filter: "single_student"
, pre:({student_id,student, dispatch, level_id})=>{
// console.log("UPDATEING STIUDENT", student_id,"level id:", level_id);
  //dispatch(getSchoolGeneralCoursesProgress({ level_id: this.props.currentCourse.level_id }));
/*
  if(!student || student_id!==student.id){
    dispatch(actions.getStudentIntelligenceProgress({ student_id }))
    return true;
  }
  return false;*/
  dispatch(actions.getStudentCaligrafixBookProgress({ student_id })) 
  return true;
 // 
}, props: {path:"student.current.content",newpage:true}  })(({ student})=>{ 
    return  <StudentCaligrafixPdf student={student}/>  
}));

export var StudentCommentsPdfSelection =  connect(mapStateToProps2,mapDispatchToProps2)(  withPdf2 ({ filter: "single_student"
, pre:({student_id,student, dispatch, level_id})=>{
// console.log("UPDATEING STIUDENT", student_id,"level id:", level_id);
  //dispatch(getSchoolGeneralCoursesProgress({ level_id: this.props.currentCourse.level_id }));
/*
  if(!student || student_id!==student.id){
    dispatch(actions.getStudentIntelligenceProgress({ student_id }))
    return true;
  }
  return false;*/
  dispatch(actions.getStudentComments({ student_id }));  
  return true;
 // 
}, props: {path:"student.current.comments",newpage:true}  })(({ student})=>{ 
    return  <StudentCommentsPdf student={student} hiddeHeader={true}/>  
}));

export var StudentPdfCourse = connect(mapStateToProps3,mapDispatchToProps2)(  withPdf2 ({path:"student.", filter: "course_report"
, pre:({student_id, dispatch/*, student,student_intelligence_progress*/})=>{
  //console.log("HELLO STUDENT ",student, student_id,student_intelligence_progress);
  //if(!student){ 
  dispatch(actions.getStudentIntelligenceProgress({ student_id }));
  return true;  
 // }
  //return false;
}, props:{newpage:true}  })(({ student})=>{ 
  //  console.log("ren", student);
    return  <StudentPdf student={student}/>  
}));

export var StudentCaligrafixPdfCourse = connect(mapStateToProps3,mapDispatchToProps2)(  withPdf2 ({path:"student.", filter: "course_report"
, pre:({student_id, dispatch/*, student,student_intelligence_progress*/})=>{
  //console.log("HELLO STUDENT ",student, student_id,student_intelligence_progress);
  //if(!student){ 
  dispatch(actions.getStudentCaligrafixBookProgress({ student_id }));
  return true;  
 // }
  //return false;
}, props:{newpage:true}  })(({ student})=>{ 
  //  console.log("ren", student);
    return  <StudentCaligrafixPdf student={student}/>  
}));


export var StudentCommentsPdfCourse =  connect(mapStateToProps3,mapDispatchToProps2)(  withPdf2 ({ filter: "course_report"
, pre:({student_id,student, dispatch, level_id})=>{
// console.log("UPDATEING STIUDENT", student_id,"level id:", level_id);
  //dispatch(getSchoolGeneralCoursesProgress({ level_id: this.props.currentCourse.level_id }));
/*
  if(!student || student_id!==student.id){
    dispatch(actions.getStudentIntelligenceProgress({ student_id }))
    return true;
  }
  return false;*/
  dispatch(actions.getStudentComments({ student_id }));  
  return true;
 // 
}, props: { newpage:true}  })(({ student})=>{ 
    return  <StudentCommentsPdf student={student} hiddeHeader={true}/>  
}));
//StudentPdfSelection = connect(null,(dispatch) => ({ dispatch }))(StudentPdfSelection);

/*
export const StudentPdfSelection  = ({})=>{
    return <StudentPdfSelectionWithProps filter="single_student"/>
}
*/