/* @flow */

import React from 'react';
import { connect } from 'react-redux';
import { compose, lifecycle, pure, withHandlers } from 'recompose';
import { reset, startSubmit } from 'redux-form';
import { UniqueAccountForm } from '../components';
import type { RecomposeHandler, ReduxFunction } from '../components/types';
import { getUniqueAccount, editPassword, exitEditMode } from '../../actions/uniqueAccount';
import { showConfirmationModal } from '../../actions/confirmationModal';
import { FORM_NAME } from '../components/UniqueAccountForm';
import _ from 'lodash';

type Props = {
  dispatch: ReduxFunction,
  data: { email: string },
  onSubmitPassword: RecomposeHandler
};

/**
 * container component to handle password change on UniqueAccountForm
 *
 * @method UniqueAccountFormContainer
 * @param  {Object}    data                       - this object should have an email property used to render the UniqueAccountForm component
 * @param  {function}  onSubmitPassword           - this should dispatch an action that shows a modal to confirm the user password update
 *
 */
const UniqueAccountFormContainer = (props: Props) => {
  return <UniqueAccountForm {...props} />;
};

export const UniqueAccountFormContainerLifecycle = {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(getUniqueAccount());
  }
};

export const UniqueAccountFormContainerHandlers = {
  onSubmit: ({ dispatch }: Props) => (values: Object) => {
    const payload = {
      unique_account: _.omit(values, 'email')
    };
    const actions = [
      startSubmit(FORM_NAME),
      { type: 'REQUEST_UNIQUE_ACCOUNT_UPDATE', payload }
    ];
    const cancelActions = [
      reset(FORM_NAME),
      exitEditMode()
    ];

    dispatch(showConfirmationModal({
      title: 'unique_account_update_warning_title',
      message: 'unique_account_update_warning_paragraph',
      cancelActions,
      actions
    }));
  },
  onClickEditButton: ({ dispatch }: Props) => () => {
    dispatch(editPassword());
  },
  onCancelButtonClick: ({ dispatch }: Props) => () => {
    dispatch(reset(FORM_NAME));
    dispatch(exitEditMode());
  }
};

const UniqueAccountFormContainerEnhanced = compose(
  withHandlers(UniqueAccountFormContainerHandlers),
  lifecycle(UniqueAccountFormContainerLifecycle),
  pure
)(UniqueAccountFormContainer);

const mapStateToProps = ({ unique_account }) => ({
  initialValues: {
    email: unique_account.email
  },
  editMode: unique_account.edit_mode
});

const mapDispatchToProps = (dispatch) => ({ dispatch });

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UniqueAccountFormContainerEnhanced);
