/* @flow */

import React from 'react';
import { connect } from 'react-redux';
import { pure, compose, withHandlers, lifecycle, defaultProps } from 'recompose';
import { SignInForm } from '../components';
import actions from '../../actions';
import { startSubmit, change } from 'redux-form';
import { FORM_NAME } from '../components/SignInForm';
import type { ReduxFunction, ReduxFormFunction, RecomposeHandler } from '../components/types';
import _ from 'lodash';

type Props = {
  dispatch: ReduxFunction,
  onSubmit: RecomposeHandler,
  handleSubmit: ReduxFormFunction,
  queryParams?: Object
};

const SignInFormContainer = ({ onSubmit, ...otherProps }: Props) => {
  return <SignInForm onSubmit={onSubmit} />;
};

export const SignInFormContainerHandlers = {
  onSubmit: (props: Props) => ({ email, password }: Object) => {
    const { dispatch } = props;
    dispatch(startSubmit(FORM_NAME));
    dispatch(actions.login({ email, password }));
  }
};

const SignInFormContainerEnhancements = compose(
  withHandlers(SignInFormContainerHandlers),
  defaultProps({
    queryParams: {}
  })
);

const mapDispatchToProps = (dispatch) => {
  return { dispatch };
};

const SignInFormContainerLifecycle = {
  componentWillMount() {
    const queryParams = this.props.queryParams;

    if (queryParams.locale && _.includes(['es', 'en'], queryParams.locale)) {
      this.props.dispatch(actions.changeLanguage({ language: queryParams.locale }));
      this.props.dispatch(change(FORM_NAME, 'locale', queryParams.locale || 'es'));
    }
  }
};

const SignInFormContainerComposed = compose(
  SignInFormContainerEnhancements,
  lifecycle(SignInFormContainerLifecycle),
  pure
)(SignInFormContainer);

const SignInFormContainerConnected = connect(
  null,
  mapDispatchToProps
)(SignInFormContainerComposed);

export { SignInFormContainerConnected as SignInFormContainer }
export default SignInFormContainerConnected;
