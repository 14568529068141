/* @flow */

import React from 'react';
import logo from '../../img/logo.svg';
import logop from '../../img/logo.png';
import { PdfOnlyData } from '../../pdfer/Pdfable';

/**
 * component that will render the logo of the application
 *
 * @example
 * <BrandLogo />
 */
const BrandLogo = () => {
  return (
    <div className="navbar-brand">
      
      <PdfOnlyData path="__logo" pstyle="height:86px;" pdf={{header:true}} filter="pleiq">
      <img src={logop} alt="PleIQ" style={{height:"100%",width:"auto" }}/>
      </PdfOnlyData> 
      <img className="logo" src={logo} alt="PleIQ" /> 
    </div>
  );
};

export default BrandLogo;
