/* @flow */

export function getSchoolGeneralIntelligenceHistory({ intelligence_id, start_date, end_date, group_by }: Object) {
  return {
    type: 'REQUEST_SCHOOL_GENERAL_INTELLIGENCE_HISTORY',
    payload: {
      intelligence_id,
      start_date,
      end_date,
      group_by
    }
  };
}
