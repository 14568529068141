/* @flow */

import React from 'react';
import { compose, lifecycle, shouldUpdate } from 'recompose';
import { getActiveChild } from '../../selectors/children';
import { getIntelligenceBySlug } from '../../selectors/intelligences';
import { getAchievementCardsByIntelligence, getAchievementCardScores } from '../../selectors/achievementCards';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import actions from '../../actions';
import { dataNeedsUpdate } from '../../helpers/intelligences';
import deepEqualsUpdate from '../../helpers/deepEqualsUpdate';
import { CardProgressContainer } from './';

/**
 * Container to wrap a collection of ParentAchievementCardProgressContainer elements
 * @method CardProgressContainer
 * @param  {[]Object}              data - array of objects that hold information for each CardProgress to render
 */
const ParentAchievementCardProgressContainer = ({ achievementCards, achievementCardScores }) => {
  return (
    <CardProgressContainer achievementCards={achievementCards} achievementCardScores={achievementCardScores} />
  );
};

const dispatchGetData = ({ intelligence_id, language, child_id, dispatch }) => {
  if (intelligence_id && child_id) {
    dispatch(actions.getAchievementCards({
      intelligence_id,
      language
    }));

    dispatch(actions.getAchievementCardScores({
      child_id,
      intelligence_id
    }));
  }
};

export const ContainerLifecycle = {
  componentDidUpdate(prevProps) {
    const languageChanged = prevProps.language !== this.props.language;

    if (dataNeedsUpdate(prevProps, this.props) || languageChanged) {
      dispatchGetData(this.props);
    }
  },
  componentDidMount() {
    dispatchGetData(this.props);
  }
};

const ContainerComposed = compose(
  lifecycle(ContainerLifecycle),
  shouldUpdate(deepEqualsUpdate)
)(ParentAchievementCardProgressContainer);

const mapStateToProps = (state, ownProps) => {
  const { children, achievement_card_scores, language } = state;
  const { topic } = ownProps.match.params;

  const currentLanguage = language || 'es';
  const intelligence = getIntelligenceBySlug(state, topic);
  const child = getActiveChild({ children });

  const achievementCards = getAchievementCardsByIntelligence(state, intelligence.id, currentLanguage);
  const achievementCardScores = getAchievementCardScores({ achievement_card_scores }, child.id, intelligence.id);

  return {
    intelligence_id: intelligence.id,
    child_id: child.id,
    language,
    achievementCards,
    achievementCardScores
  };
};

const mapDispatchToProps = (dispatch) => ({ dispatch });

const ContainerConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(ContainerComposed);

export default withRouter(ContainerConnected);
