/* @flow */

import React from 'react';
import { NavigationPills, NavItemPill } from './NavigationPills';
import classNames from 'classnames';
import Translations from '../hocs/TranslationsHelper';

const timelineOptions = ['month', 'week', 'day'];

type Props = {
  label: string,
  selectedOption: 'month'|'week'|'day',
  onItemSelected: Function,
  translations: Object,
  timelineContainerClassName: string,
  children?: React.Component<*>
};

/**
 * component that will display a chart together with a navigation to change
 * the chart data between different options: weekly progress, monthly progress
 * and yearly progress.
 *
 * @method TimelineProgress
 * @param  {label}                        string        - label to display in the section header
 * @param  {selectedOption}               string        - the option to show as active within the nav pills (yearly|monthly|weekly)
 * @param  {onItemSelected}               string        - handler that gets called when a navigation option gets clicked
 * @param  {timelineContainerClassName}   string        - class to be inserted on the timeline container to easily position
 *
 */
const TimelineProgress = ({ label, selectedOption, onItemSelected, children, timelineContainerClassName, translations }: Props) => {
  const timelineContainerClass = classNames('timeline-progress-content', timelineContainerClassName);

  return (
    <div className="timeline-progress">
      <div className="d-flex justify-content-between timeline-progress-header">
        <div className="lead-title-container">
          <h1 className="lead-title">{label}</h1>
        </div>
        <NavigationPills>
        {
          timelineOptions.map((option) => {
            return (
              <NavItemPill
                key={option}
                label={option}
                onItemClick={onItemSelected}
                active={option === selectedOption}>
                {translations.t(option)}
              </NavItemPill>
            );
          })
        }
        </NavigationPills>
      </div>
      <div className={timelineContainerClass}>
        {children}
      </div>
    </div>
  );
};

export default Translations(TimelineProgress);
