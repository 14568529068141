/* @flow */

import React from 'react';
import { connect } from 'react-redux';
import { compose, withHandlers, pure } from 'recompose';
import { CourseEditForm, Modal } from '../components';
import type { Course, RecomposeHandler, ReduxFunction } from '../components/types';
import { closeEditCourseModal } from '../../actions/courses';

type Props = {
  course?: Course,
  active: boolean,
  closeModal: RecomposeHandler,
  dispatch: ReduxFunction
};

/**
 * this component will display a modal to edit a course and its students
 *
 * @method CourseEditFormContainer
 * @param  {Object}    course              - course to be edited, optional (if it's not passed in, then a new level is created)
 *
 */
const CourseEditFormContainer = ({ course, active, closeModal }: Props) => {
  return (
    <Modal
      backdropClassName="overlay-backdrop"
      contentClassName="overlay-modal-content"
      active={active}
      toggle={closeModal}
    >
      <CourseEditForm course={course} closeModal={closeModal} />
    </Modal>
  );
};

export const CourseEditFormContainerHandlers = {
  closeModal: ({ dispatch }) => () => {
    dispatch(closeEditCourseModal());
  }
};

const CourseEditFormContainerComposed = compose(
  withHandlers(CourseEditFormContainerHandlers),
  pure
)(CourseEditFormContainer);

const mapStateToProps = ({ courseEditModal }) => ({
  active: courseEditModal.active,
  course: courseEditModal.course
});

const CourseEditFormContainerConnected = connect(
  mapStateToProps
)(CourseEditFormContainerComposed);

export default CourseEditFormContainerConnected;
