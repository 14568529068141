
/**
 * @method getResponseAction
 * a simple function to get a RESPONSE action from a
 * specific REQUEST action type.
 */
const getResponseActionType = (requestActionType) =>
  requestActionType.replace('REQUEST', 'RESPONSE');

/**
 * @method request
 * an specific action for the requests in order to avoid manually
 * write the object action to perform a request
 */
export const request = ({ type, payload }, responseActionType) => {
  responseActionType = (responseActionType || getResponseActionType(type));

  return {
    type: 'REQUEST',
    payload: {
      ...payload,
      responseActionType
    }
  };
};

export const requestTimeout = (payload, responseActionType) => {
  return {
    type: responseActionType,
    payload: {
      ...payload,
      timeout: true
    }
  };
};

export const requestCancelled = (payload, responseActionType) => {
  return {
    type: responseActionType,
    payload: {
      ...payload,
      cancelled: true
    }
  };
};
