/* @flow */

import React from 'react';
import { StudentInfoItemContainer } from '../containers';
import type { Student } from './types';

type Props = {
  students: Array<Student>
};

/**
 * component to display a list of StudentInfoItemContainer using an
 * array of students
 *
 * @method StudentInfoList
 * @param  {Array<Object>}    students     - contains all the students to be rendered
 *
 */
const StudentInfoList = ({ students, showCourseName }: Props) => {
  return (
    <div className="student-info-list">
      {students.map((student: Student, index: number) => {
        return (
          <StudentInfoItemContainer
            key={student.id}
            student={student}
            showCourseName={showCourseName}
            number={index + 1}
          />
        );
      })}
    </div>
  );
};

export default StudentInfoList;
