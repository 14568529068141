/* @flow */

import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router';
import _ from 'lodash';
import queryString from 'query-string';

const schoolRoute = (user)=>('school/home')//(user.license_type==="pleiq_school")?'/school/progress':'/school/caligrafix')


const renderGuestRoute = (props: Object, { component: Component, user, ...rest }: Object) => {
  if (user.is_logged_in) {
    const redirectUrl = user.account_type === 'Parent' ? '/dashboard/all' :
     schoolRoute(user);

    return (
      <Redirect to={{
        pathname: redirectUrl
      }} />
    );
  }

  return <Component {...rest} {...props} />;
};

const renderPrivateRoute = (props: Object, { component: Component, user, children, ...rest }: Object) => {
  let pathname = props.location.pathname;

  if (!user.is_logged_in) {
    return (
      <Redirect to={{
        pathname: '/login',
        state: { from: props.location }
      }} />
    );
  }

  if (!user.activated) {
    if (props.location.pathname === '/activate') {
      return <Component {...props} />;
    }

    return (
      <Redirect to={{
        pathname: '/activate',
        state: { from: props.location }
      }} />
    );
  }

  if (user.account_type === 'Parent') {

    if (user.children_count === 0) {
      if (pathname === '/add_children') {
        return <Component {...props} />;
      }

      return (
        <Redirect to={{
          pathname: '/add_children',
          state: { from: props.location }
        }} />
      );
    }

    if (user.is_new && pathname === '/congratulations') {
      return <Component {...props} />;
    }

    if (user.is_new) {
      return (
        <Redirect to={{
          pathname: '/congratulations',
          state: { from: props.location }
        }} />
      );
    }

  }

  if (_.includes(['/add_children', '/activate'], pathname)) {
    switch (user.account_type) {
      case 'School':
        return (
          <Redirect to={{
            pathname: schoolRoute(user)
          }} />
        );
      default:
        return (
          <Redirect to={{
            pathname: '/dashboard/all'
          }} />
        );
    }
  }

  return <Component {...props} />;
};

const renderWithQueryParamsRoute = (props: Object, { component: Component, user, children, validate, ...rest }: Object) => {
  let queryParams = queryString.parse(props.location.search);

  // Do validation
  // this iterates through all the keys of the validation object ({token: [required]})
  // and executes the validation function, if an error it's present it sets `error` to true
  // and later redirect to root
  if (_.isObject(validate)) {
    let error = false

    Object.keys(validate).forEach((key) => {
      validate[key].forEach((validation) => {
        const result = validation(queryParams[key]);

        if (result) {
          error = true;
        }
      })
    })

    if (error) {
      return (
        <Redirect to={{
          pathname: '/',
          state: { from: props.location }
        }} />
      );
    }
  }

  return <Component queryParams={queryParams} {...props} />;
};

const renderWithQueryParamsGuestRoute = (props: Object, { component: Component, user, validate, ...rest }: Object) => {
  let queryParams = queryString.parse(props.location.search);

  // Do validation
  // this iterates through all the keys of the validation object ({token: [required]})
  // and executes the validation function, if an error it's present it sets `error` to true
  // and later redirect to root
  if (_.isObject(validate)) {
    let error = false

    Object.keys(validate).forEach((key) => {
      validate[key].forEach((validation) => {
        const result = validation(queryParams[key]);

        if (result) {
          error = true;
        }
      })
    })

    if (error) {
      return (
        <Redirect to={{
          pathname: '/',
          state: { from: props.location }
        }} />
      );
    }
  }

  return renderGuestRoute(props, { component: Component, user, queryParams, ...rest });
};

/*
 * Validates if account_type is Parent and then delegates to PrivateRoute
 * If account is not parent it will redirect to root ('/')
 */
const renderParentRoute = (props: Object, { component: Component, user, children, ...rest }: Object) => {
  if (!user.is_logged_in) {
    return (
      <Redirect to={{
        pathname: '/login',
        state: { from: props.location }
      }} />
    );
  }

  if (user.account_type !== 'Parent') {
    return (
      <Redirect to={{
        pathname: schoolRoute(user),
        state: { from: props.location }
      }} />
    );
  }

  return renderPrivateRoute(props, { component: Component, user, children, ...rest });
};

/*
 * Validates if account_type is School and then delegates to PrivateRoute
 * If account is not parent it will redirect to root ('/')
 */
const renderSchoolRoute = (props: Object, { component: Component, user, children, premium, ...rest }: Object) => {
  if (!user.is_logged_in) {
    return (
      <Redirect to={{
        pathname: '/login',
        state: { from: props.location }
      }} />
    );
  }
  let queryParams = queryString.parse(props.location.search);

  if (user.account_type !== 'School') {
    return (
      <Redirect to={{
        pathname: '/',
        state: { from: props.location }
      }} />
    );
  }
  
  if(premium && user.premium_status=="none"){ 
    return (
      <Redirect to={{
        pathname: '/school/pro',
       state: { from: props.location }
     }} />
    );
  }
    

  return renderPrivateRoute(Object.assign( {queryParams, ...props} ), { component: Component, user, children, ...rest });
};

/*
 * WithQueryParamsRoute using standard form validations (functions that return an error or undefined)
 * to validate queryParams. You can check for things like presence or format for example.
 *
 * In case a validation returns an error, we simple redirect to root ('/')
 *
 * Example:
 *   <WithQueryParamsRoute path='/users/password/edit'
 *     validate={{ token: [required] }}
 *     component={Registration.UserPasswordResetForm} />
 */
let WithQueryParamsRoute = ({ component: Component, user, children, ...rest }) => {
  let params = { component: Component, user, children, ...rest };
  return <Route {...rest} render={(props) => renderWithQueryParamsRoute(props, params) } />;
};

/*
 * WithQueryParamsGuestRoute behaves as WithQueryParamsRoute but render a GuestRoute instead of a Route
 *
 * Example:
 *   <WithQueryParamsGuestRoute path='/users/password/edit'
 *     validate={{ token: [required] }}
 *     component={Registration.UserPasswordResetForm} />
 */
let WithQueryParamsGuestRoute = ({ component: Component, user, ...rest }) => {
  let params = { component: Component, user, ...rest };
  return <Route {...rest} render={(props) => renderWithQueryParamsGuestRoute(props, params) } />;
};

let PrivateRoute = ({ component: Component, user, children, ...rest }) => {
  let params = { component: Component, user, children, ...rest };
  return <Route {...rest} render={(props) => renderPrivateRoute(props, params) } />;
};

let GuestRoute = ({ component: Component, user, ...rest }) => {
  let params = { component: Component, user, ...rest };
  return <Route {...rest} render={(props) => renderGuestRoute(props, params)} />;
};

/*
 * ParentRoute validates if user.account_type === 'Parent', if is not true then redirects
 * to root ('/')
 *
 * Example:
 *   <ParentRoute exact path='/dashboard/all' component={Dashboard} />
 */
let ParentRoute = ({ component: Component, user, children, ...rest }) => {
  let params = { component: Component, user, children, ...rest };
  return <Route {...rest} render={(props) => renderParentRoute(props, params) } />;
};

/*
 * SchoolRoute validates if user.account_type === 'School', if is not true then redirects
 * to root ('/')
 *
 * Example:
 *   <SchoolRoute path='/school/progress' component={SchoolProgress} />
 */
let SchoolRoute = ({ component: Component, user, children, ...rest }) => {
  let params = { component: Component, user, children, ...rest };
  return <Route {...rest} render={(props) => renderSchoolRoute(props, params) } />;
};

PrivateRoute = connect(({ user, children }) => ({ user, children }))(PrivateRoute);
GuestRoute = connect(({ user }) => ({ user }))(GuestRoute);
WithQueryParamsRoute = connect(({ user, children }) => ({ user, children }))(WithQueryParamsRoute);
WithQueryParamsGuestRoute = connect(({ user }) => ({ user }))(WithQueryParamsGuestRoute);
ParentRoute = connect(({ user, children }) => ({ user, children }))(ParentRoute);
SchoolRoute = connect(({ user, children }) => ({ user, children }))(SchoolRoute);

export {
  GuestRoute,
  PrivateRoute,
  WithQueryParamsRoute,
  WithQueryParamsGuestRoute,
  ParentRoute,
  SchoolRoute,
  renderGuestRoute,
  renderPrivateRoute,
  renderWithQueryParamsRoute,
  renderWithQueryParamsGuestRoute,
  renderParentRoute,
  renderSchoolRoute
};
