/* @flow */

import React from 'react';
import { Paper, InfoTooltip } from '../components';
import { Col, Row } from 'reactstrap';
import {
  HeaderContainer, SchoolDashboardHeadingContainer,
  SchoolDashboardNavContainer, StudentProgressNavContainer,
  StudentTimelineProgressContainer, StudentRadialProgressBarContainer,
  StudentAchievementCardProgressContainer, StudentProgressNameContainer,
  PageTitleContainer
} from '../containers';
import Translations from '../hocs/TranslationsHelper';
import { Link } from 'react-router-dom';
import topScroll from '../hocs/topScroll';

/**
 * screen for the school SchoolChallenges
 *
 * @method SchoolStudentDashboard
 *
 */
const SchoolStudentDashboard = ({ translations }) => {
  topScroll();
  return (
    <div id="school-dashboard" className="school-dashboard layout-base">
      <PageTitleContainer pageTitle="title_student_intelligence" />
      <HeaderContainer />
      <SchoolDashboardHeadingContainer />
      <Paper.Container lead>
        <SchoolDashboardNavContainer currentTab="courses" />
        <Row>
          <Col xs="12">
            <div className="mb-3">
              <Link to="/school/courses" className="back-link">
                <span className="pleiq-icon pleiq-icon-icon_arrow_left vcentered" />{' '}
                <span className="back-link-text vcentered">{translations.t('back_to_courses')}</span>
              </Link>
            </div>
          </Col>
          <Col xs="12">
            <Paper.Title>
              <StudentProgressNameContainer />
            </Paper.Title>
          </Col>
          <Col xs="12">
            <StudentProgressNavContainer />
          </Col>
          <Col xs="12" sm="8" lg="9">
            <h5 className="mt-2 mb-4">{translations.t('intelligence_screen_timeline_label')}</h5>
          </Col>
          <Col xs="12" sm="4" lg="3" className="hidden-sm-down">
            <h5 className="mt-2 mb-4 text-center">{translations.t('intelligence_screen_current_progress_label')}</h5>
          </Col>
          <Col xs="12" sm="8" lg="9" className="half-pad-col--right">
            <StudentTimelineProgressContainer />
          </Col>
          <Col xs="12" className="hidden-sm-up">
            <Paper.Title>
              <h1 className="mt-5">{translations.t('intelligence_screen_current_progress_label')}</h1>
            </Paper.Title>
          </Col>
          <Col xs="12" sm="4" lg="3">
            <div className="intelligence-pie-chart">
              <div className="pie-progress-graph">
                <StudentRadialProgressBarContainer />
                <div className="mt-4">
                  <a className="btn btn-transparent" target="_blank" rel="noopener noreferrer" href={translations.t('activities_url')}>{translations.t('how_to_improve')}</a>
                </div>
              </div>
            </div>
          </Col>
          <Col xs="12">
            <Paper.Title>
              <h5 className="mt-5 mb-4">
                {translations.t('intelligence_screen_achievements_label')} {' '}
                <InfoTooltip message="tooltips_intelligence_screen_achievements_label" />
              </h5>
            </Paper.Title>
          </Col>
          <Col xs="12">
            <StudentAchievementCardProgressContainer />
          </Col>
        </Row>
      </Paper.Container>
    </div>
  );
};

export default Translations(SchoolStudentDashboard);
