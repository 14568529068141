import React from 'react';
import classNames from 'classnames';
import { Paper } from './';
import downloadIcon from "../../img/icon_download.svg";
import {connect} from "react-redux"
import {pdfContext} from '../../pdfer/Pdfable'
import {withRouter} from 'react-router-dom'

/**
 * component to display some navigations tabs and a search bar
 *
 * @method SchoolDashboardNav
 * @param  {string}    currentTab              - the currently selected tab
 * @param  {boolean}   expanded                - injected by `recompose` as a state, controls wheter the search input should render expanded or not
 * @param  {function}  onTabClick              - gets called whenever a tab gets clicked, and it passes the name of that tab
 * @param  {function}  onSearchChange          - gets called when the search input changes
 * @param  {function}  closeSearchHandler      - callback that gets called when the input should close
 * @param  {function}  expandSearchHandler     - callback that gets called when the input should expand
 * @param  {object}    translations            - an object containing translations
 * @param  {Function}  keyPressHandler         - callback called when a key was pressed in the input
 */

class PdfPrinter extends React.Component {
  constructor(props) {
    super(props);
    this.state = { printing: false };
  }
  componentDidMount(){
    this.unsubscribe = pdfContext.state.subscribe((printing)=>{
        this.setState({printing:printing});
    });
  }
  componentWillUnmount(){
      this.unsubscribe();
  }
  render() {
    const { target, expanded, state , filter, pdfProps,match} = this.props;
    const navItemClassname = classNames({ 'hidden-md-down': expanded });
    //const {printing, setPrinting} = useState(false);
    const printPdf = () => { 
      
      pdfContext.print(typeof(target)==='string'? target: target({...state,match}), filter, typeof(pdfProps)==='function'?pdfProps(this.props): pdfProps);
     // this.setState({ printing: true });
     // printElement(target).then(()=>{
    //    this.setState({printing:false});
     // });
    };
    /* const {printing, setPrinting} = useState(false);
     const printPdf = ()=>{
       setPrinting(true);
     }*/
    return  (<Paper.NavItem key={"pdfprint"} onClick={printPdf} active={false} iconSrc={downloadIcon} className={navItemClassname} label={'PDF'} hideLabelSm />);
  }
}
export const PdfPrinterContainer = withRouter( connect((state, ownProps)=>{
  return {...ownProps, state};
})(PdfPrinter));
