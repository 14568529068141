/* @flow */

import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { Paper, InfoTooltip } from '../components';
import {  HeaderContainer, RadialProgressBarContainer,
  PageTitleContainer,
  SchoolDashboardHeadingContainer,
  SchoolDashboardNavContainer
} from '../containers';
import { pure, compose } from 'recompose';
import classNames from 'classnames';
import Translations, { useTranslations } from '../hocs/TranslationsHelper';
import topScroll from '../hocs/topScroll';
import { useApi } from '../../api_request';

const DigitalPaperBook = ( props) => {
  topScroll();

  console.log("PROPS:", props);
  const book_id = props.match.params.book_id;
  const mode = props.queryParams.mode;

  const [token, setToken] = useState(null);
  const translations = useTranslations();
  const api = useApi();

  const paperContentClass = classNames('intelligence-timeline');

  useEffect(()=>{
    api.get('/pdigital/token').then((r)=>{
        console.log("RESPONSE: ", r)
        if(r.status==200){
            setToken(r.data.token);
        }
    })
  },[])

  return (
    <div id="dashboard" className="layout-digital-paper-book">
    <PageTitleContainer pageTitle="Digital Paper" />
      <HeaderContainer />  
        <div className='digital-paper-book'>

            {
                (token)&&<iframe frameBorder="0" className='digital-paper-book-iframe' src={"https://pd-external.caligrafix.cl/#/pleiq_school_fullaccess?token="+token+"&&redirect="+ encodeURIComponent("/books/read/"+((mode=="write")?(parseInt(book_id)+1):0)+"/" + book_id)} title="W3Schools Free Online Web Tutorials"></iframe>
            }  
        </div>
    </div>
  );
};
/*
const IntelligenceComposed = compose(
  pure
)(Intelligence);*/

export default DigitalPaperBook;
