/* @flow */

import React from 'react';
import Button from './Button';
import { Intelligences } from '../models';
import { branch, renderComponent, pure, compose, setStatic } from 'recompose';
import classNames from 'classnames';
import Translations from '../hocs/TranslationsHelper';
import btnTopicImg from '../../img/icon_all_intelligences.svg';

const topicTypes = {
  all: 'all',
  ...Intelligences.TYPES
};

export type TopicType = $Keys<typeof topicTypes>

type Props = {
  className: string,
  type: TopicType;
  translations: Object,
  onClickTopic: Function
};

/**
 * Component that wraps the Button implementation in order to define
 * specific features of a topic button
 * @method Topic
 * @param  {string}     className     - string to inject as extra class for topic button
 * @param  {string}     type          - string that represent a available topic to display
 * @param  {function}   onClickTopic - function to determine what to do on click the button
 * @param  {object}     translations  - injected by our Translations HoC to give translated strings
 */
const TopicSpecific = ({ type, onClickTopic, className, translations }: Props) => {
  const icon = getTopicIcon(type);
  const containerClass = classNames('btn-topic-container', className);
  const buttonClass = classNames('btn-topic', type);

  return (
    <div className={containerClass}>
      <Button
        className={buttonClass}
        onClickButton={() => onClickTopic(type)}
        type="rounded">
        {icon}
      </Button>
      <label>{ translations.t(type) }</label>
    </div>
  );
};

/**
 * Render a special type of topic button that represents all the topics
 * available within the application, this button has they own set of styles and icon
 * @method TopicOverall
 * @param  {string}     className     - string to inject as extra class for topic button
 * @param  {string}     type          - string that represent a available topic to display ('all')
 * @param  {function}   onClickTopic - function to determine what to do on click the button
 */
const TopicOverall = ({ type, onClickTopic, className, translations }: Props) => {
  const containerClass = classNames('btn-topic-all-container d-inline-block', className);
  const buttonClass = classNames('btn-topic-all', type);

  return (
    <div className={containerClass}>
      <Button
        className={buttonClass}
        onClickButton={() => onClickTopic(type)}
        type="rounded">
        <img src={btnTopicImg} alt="all-topic-icon" />
      </Button>
      <label>{ translations.t('all') }</label>
    </div>
  );
};

export const getTopicIcon = (type: TopicType) => {
  return Intelligences.getIntelligenceIcon(type);
};

const displayTopicOverall = branch(
  (props) => (props.type).toLowerCase() === 'all',
  renderComponent(Translations(TopicOverall))
);

let Topic = compose(
  displayTopicOverall,
  pure
)(TopicSpecific);

Topic = Translations(Topic);
Topic = setStatic('TYPES', topicTypes)(Topic);

export default Topic;
