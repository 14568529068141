/* @flow */

import React from 'react';
import { TimelineProgress, LineChart } from '../components';
import type { RecomposeStateUpdater, RecomposeStateName } from '../components/types';
import { withState, withHandlers, pure, compose, lifecycle } from 'recompose';
import actions from '../../actions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import Translations from '../hocs/TranslationsHelper';
import { getActiveChild } from '../../selectors/children';
import { getIntelligenceBySlug } from '../../selectors/intelligences';
import { getIntelligenceHistory } from '../../selectors/intelligenceProgress';
import { dataNeedsUpdate } from '../../helpers/intelligences';
import { getDefaultChartDomain, getFormatedDate } from '../../helpers/defaultChartDomain';

type Props = {
  selectedOption: RecomposeStateName,
  onClickOption: RecomposeStateUpdater
};

/*
 * This function filters the data to get the data for the current option.
 * It creates an array with the format the LineChart component expects.
 */
const getCurrentOptionData = (data, selectedOption, translations) => {
  // make sure moment language match with the one within the state
  moment.locale(translations.locale());

  const currentData = getCurrentData(data, selectedOption);

  /* Map data to the expected array format */
  const mappedData = _.map(currentData, (value, date) => {
    return { name: getFormatedDate(date, selectedOption, translations), value: parseInt(value, 10), date };
  });

  if (_.isEmpty(mappedData)) {
    return getDefaultChartDomain(selectedOption, translations);
  }

  /* sort the array by date as Javascript objects don't have an explicit order */
  return _.sortBy(mappedData, ['date']);
};

const getCurrentData = (data, selectedOption) => (_.get(data, [selectedOption], null));

/**
 * A container to hold and render the data about the timeline progress
 * of a user in a certain intelligence.
 *
 * @method IntelligenceTimelineProgressContainer
 * @param  {Object}                              data           - the data in order to display the graph
 * @param  {string}                              selectedOption - injected by `recompose` as an state to to change the way the graph is rendered (yearly, monthly, weekly)
 * @param  {function}                            onClickOption  - injected by `recompose` as a stateUpdater to change the selectedOption
 */
const IntelligenceTimelineProgressContainer = ({ data, selectedOption, onClickOption, translations, language }: Props) => {
  const currentData = getCurrentOptionData(data, selectedOption, translations, language);
  const loading = !getCurrentData(data, selectedOption);

  return (
    <TimelineProgress selectedOption={selectedOption} onItemSelected={onClickOption}>
      <LineChart data={currentData} yAxis loading={loading} />
    </TimelineProgress>
  );
};

const getDate = (option) => {
  const format = 'YYYY-MM-DD';

  switch (option) {
    case 'month':
      return moment().subtract(5, 'months').format(format);
    case 'week':
      return moment().subtract(3, 'weeks').format(format);
    case 'day':
      return moment().subtract(6, 'days').format(format);
    default:
      return moment().add(1, 'day').format(format);
  }
};

export const IntelligenceTimelineProgressContainerHandlers = {
  onClickOption: (props: Object) => (selectedOption: 'month' | 'week' | 'day') => {
    dispatchGetIntelligenceHistory({
      ...props,
      selectedOption
    });

    props.onClickOption(selectedOption);
  }
};

const IntelligenceTimelineProgressContainerEnhancements = compose(
  withState('selectedOption', 'onClickOption', 'day'),
  withHandlers(IntelligenceTimelineProgressContainerHandlers)
);

export const dispatchGetIntelligenceHistory = ({ child_id, intelligence_id, dispatch, selectedOption }) => {
  const start_date = getDate(selectedOption);
  const end_date = getDate();

  if (child_id && intelligence_id) {
    dispatch(actions.getIntelligenceHistory({
      child_id,
      intelligence_id,
      start_date,
      end_date,
      group_by: selectedOption
    }));
  }
};

export const IntelligenceTimelineProgressContainerLifecycle = {
  /*
   * We also need to dispatch when the intelligence topic changes
   * as the component isn't mounted again
   */
  componentDidUpdate(prevProps) {
    if (dataNeedsUpdate(prevProps, this.props)) {
      dispatchGetIntelligenceHistory(this.props);
    }
  },
  componentDidMount() {
    dispatchGetIntelligenceHistory(this.props);
  }
};

const IntelligenceTimelineProgressContainerTranslated = Translations(IntelligenceTimelineProgressContainer);

const IntelligenceTimelineProgressContainerComposed = compose(
  IntelligenceTimelineProgressContainerEnhancements,
  lifecycle(IntelligenceTimelineProgressContainerLifecycle),
  pure
)(IntelligenceTimelineProgressContainerTranslated);

const mapStateToProps = ({ children, intelligences, intelligence_history }, ownProps) => {
  const { params } = ownProps.match;
  const { topic } = params;

  const intelligence = getIntelligenceBySlug({ intelligences }, topic);
  const child = getActiveChild({ children });

  const data = getIntelligenceHistory({ intelligence_history }, child.id, intelligence.id);

  return {
    intelligence_id: intelligence.id,
    child_id: child.id,
    data
  };
};

const mapDispatchToProps = (dispatch) => ({ dispatch });

const IntelligenceTimelineProgressContainerConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(IntelligenceTimelineProgressContainerComposed);

export default withRouter(IntelligenceTimelineProgressContainerConnected);
