/**
 * Simple module class to hold information about the
 * plans to buy cubicoins within the application. this ideally
 * will be pulled from server data or checked with server
 * before make any transaction
 *
 * @flow
 */

import iconPlanA from '../../img/plans/plan_a.png';
import iconPlanB from '../../img/plans/plan_b.png';
import iconPlanC from '../../img/plans/plan_c.png';

class Plans {
  static TYPES = {
    a: 'a',
    b: 'b',
    c: 'c'
  };

  static CUBICOINS_PLAN_A = '300';

  static CUBICOINS_PLAN_B = '1000';

  static CUBICOINS_PLAN_C = '3000';

  static ICON_PLAN_A = iconPlanA;

  static ICON_PLAN_B = iconPlanB;

  static ICON_PLAN_C = iconPlanC;

  static PRICE_PLAN_A = 0.99;

  static PRICE_PLAN_B = 2.99;

  static PRICE_PLAN_C = 5.99;

  static getPlan = (plan: $Keys<typeof Plans.TYPES>) => {
    return {
      cubicoins: Plans[`CUBICOINS_PLAN_${plan.toUpperCase()}`],
      icon: Plans[`ICON_PLAN_${plan.toUpperCase()}`],
      label: Plans.TYPES[plan],
      price: Plans[`PRICE_PLAN_${plan.toUpperCase()}`]
    };
  }
}

export default Plans;
