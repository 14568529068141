/* @flow */

import { mapColorStringToHex } from '../js/components/ColorSelect';
import _ from 'lodash';

export const getActiveChild = (state: Object) => (_.find(state.children, { active: true }) || {});

export const getAllChildren = (state: Object) => state.children;

export const getChildrenWithDisplayColors = ({ children }: Object) => children.map(mapColorStringToHex);
