import React from 'react';
import { compose, withState } from 'recompose';
import { Tooltip } from 'reactstrap';
export const SimpleToolTip = compose(withState("open", "setOpen"))(
  ({ text, open, setOpen, target }) => <Tooltip target={target} isOpen={open} toggle={() => setOpen(!open)} placement="center" tether={customTetherOptions}>
    {text}
  </Tooltip>
);
const customTetherOptions = {
  constraints: [{
    to: 'window',
    pin: true
  }]
};
