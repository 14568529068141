import _ from 'lodash';
import { Intelligences } from '../js/models/';

/*
 * This method expected the data in the format of the following selectors
 * 1. studentProgress from getAllIntelligenceProgressByChild
 * 2. intelligences reducer data
 *
 * Return array structure looks like this:
 * [{ x: 5, y: 100, subject: 'LIN', color: '#8884d8' }, { x: 4, y: 80, subject: 'MUS', color: '#678CFF' }]
 *
 * @return [Array] Data to be rendered in ScatterChart component
 */
export const studentEffortVsPerformanceToScatterChart = (studentProgress, intelligences) => {
  let returnValue = studentProgress.map((intelligenceProgress) => {

   // console.log(intelligences);
    
    const intelligence = _.find(intelligences, { id: intelligenceProgress.intelligence_id });
   // console.log(intelligenceProgress);
    const slug = intelligence.slug;
    const color = Intelligences.getIntelligenceColor(slug);

    // seconds to hours
    const x = _.round(intelligenceProgress.duration / 3600, 2);

    return {
      v:slug,
      x,
      y: intelligenceProgress.score,
      subject: slug,
      color
    }
  });

  // make sure the data is sorted
  returnValue = _.sortBy(returnValue, 'x');

  return returnValue;
};
