/* @flow */

import React from 'react';
import { Button, Modal, Paper } from './';
import type { TranslationsObject } from './types';
import Translations from '../hocs/TranslationsHelper';

type Props = {
  title: string,
  message: string,
  active: boolean,
  onConfirm: () => mixed,
  onCancel: () => mixed,
  translations: TranslationsObject
};

/**
 * this modal can be used when a confirmation from the user is needed
 *
 * @param  {string}   title            - title for the modal, this should be a valid translation key
 * @param  {string}   message          - text content for the body of the modal, this should be a valid translation key
 * @param  {boolean}  active           - indicates if the modal should be open or not
 * @param  {Array}    onConfirm        - callback thats gets fired when the confirm button is clicked
 * @param  {Array}    onClose          - callback thats gets fired when the close button gets clicked
 * @param  {Object}   translations     - object containing app translations
 */
const ConfirmationModal = ({ title, message, active,kind, onConfirm, onCancel, translations }: Props) => {
  const kclass = (kind)?("modal-"+kind): '';
  
  return (
    <Modal backdropClassName="overlay-backdrop" contentClassName="overlay-modal-content" className='dialog-centered' active={active} toggle={onCancel}>
      <div className={`confirmation-modal ${kclass}`}>
        <span className="modal-close pleiq-icon-icon_plus" onClick={onCancel} />
        <Paper.Container>
          <div className={`confirmation-modal-content ${kclass}` }>
            <h4 className={`confirmation-modal-title text-center ${kclass}`}>
              {title && translations.t(title)}
            </h4>
            <p className={`confirmation-modal-text text-center ${kclass}`} >
              {message && translations.t(message)}
            </p>
            <Button onClickButton={onConfirm} type={(kind=='succeed')?"secondary":"warning" }className={`confirmation-modal-button btn-block ${kclass}`}>
              {translations.t( (kind=='succeed')?'_ok':'confirm' )}
            </Button>
          </div>
        </Paper.Container>
      </div>
    </Modal>
  );
};

export default Translations(ConfirmationModal);
