/* @flow */

import React from 'react';
import { connect } from 'react-redux';
import { pure, compose, lifecycle } from 'recompose';
import { LevelCollapseList } from '../components';
import type { Level } from '../components/types';
import { getLevels } from '../../actions/levels';
import { getCourses } from '../../actions/courses';
import { getStudents } from '../../actions/students';

type Props = {
  levels: Array<Level>
};

/**
 * this component should get the levels from the store and pass them
 * to the LevelCollapseList component
 *
 * @method LevelCollapseListContainer
 * @param  {Object}    levels                - this object is an array objects, where each object is a Level
 * @param  {function}  onEditButtonClick     - injected by `recompose` as a handler, to dispatch an action that will trigger a level edition modal
 * @param  {function}  onHeaderClick         - injected by `recompose` as a handler, to dispatch an action that will fetch the level courses
 *
 */
const LevelCollapseListContainer = ({ levels , all}: Props) => {
  console.log("level clist");
  return <LevelCollapseList levels={levels} all={all} />;
};

export const LevelCollapseListContainerLifecycle = {
  componentDidMount() {
    console.log("levels mounted");

    const { dispatch, user } = this.props;
    dispatch(getLevels());
    dispatch(getCourses(null));
    if(user.role!='representative'){
        
      dispatch(getStudents(null));
        
    }
  }
};

export const LevelCollapseListContainerComposed = compose(
  lifecycle(LevelCollapseListContainerLifecycle),
  pure
)(LevelCollapseListContainer);

const mapStateToProps = ({ user,levels }, {all}) => {
  return ({ user,levels , all});
};

const LevelCollapseListContainerConnected = connect(
  mapStateToProps
)(LevelCollapseListContainerComposed);

export default LevelCollapseListContainerConnected;
