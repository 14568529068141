/* @flow */

import React from 'react';
import { Field } from 'redux-form';
import { Circle } from './ColorSelect';
import InputField from './InputField';
import { required, minLength } from './validations';
import type { ReduxFormFlag, Child, TranslationsObject } from './types';
import Translations from '../hocs/TranslationsHelper';

type Props = {
  color: string,
  child: Child,
  onClick: () => mixed,
  onChange: () => mixed,
  onUpdateChild: () => mixed,
  submitting: ReduxFormFlag,
  translations: TranslationsObject
};

const InputTextWithCircle = ({ color, onClick, onUpdateChild, submitting, child, translations }: Props) => {
  const disabled = submitting;
  return (
    <div className="input-circle">
      <Field
        className="text-center"
        type="text"
        name={`name-${child.reference}`}
        placeholder={translations.t('name')}
        disabled={disabled}
        onChange={(event, newValue) => onUpdateChild({ name: newValue })}
        component={InputField}
        validate={[ required, minLength(2) ]}
      />
      <Circle className="input-circle-circle" color={color} onClick={onClick} />
    </div>
  );
};

export default Translations(InputTextWithCircle);
