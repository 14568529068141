/* @flow */

import React from 'react';
import { connect } from 'react-redux';
import { compose, withHandlers, pure } from 'recompose';
import { LevelEditForm, Modal } from '../components';
import type { Level, RecomposeHandler, ReduxFunction } from '../components/types';
import { closeEditLevelModal } from '../../actions/levels';

type Props = {
  level?: Level,
  active: boolean,
  closeModal: RecomposeHandler,
  dispatch: ReduxFunction
};

/**
 * this component will pass the callbacks to LevelEditForm to ensure
 * that a level gets created/modififed, and that also courses
 * are added/removed to the level.
 * It also fetches the courses that are passed to it
 *
 * @method LevelEditFormContainer
 * @param  {Object}    level          - level to be edited, optional (if it's not passed in, then a new level is created)
 * @param  {boolean}   active         - indicated if the level edit modal should render
 * @param  {function}  closeModal     - injected by `recompose` as a handler, it closes the level edit modal when called
 *
 */
const LevelEditFormContainer = ({ level, active, closeModal }: Props) => {
  return (
    <Modal
      backdropClassName="overlay-backdrop"
      contentClassName="overlay-modal-content"
      active={active}
      toggle={closeModal}
    >
      <LevelEditForm closeModal={closeModal} level={level} />
    </Modal>
  );
};

export const LevelEditFormContainerHandlers = {
  closeModal: ({ dispatch }: Props) => () => {
    dispatch(closeEditLevelModal());
  }
};

const LevelEditFormContainerComposed = compose(
  withHandlers(LevelEditFormContainerHandlers),
  pure
)(LevelEditFormContainer);

const mapStateToProps = ({ levelEditModal }) => ({
  active: levelEditModal.active,
  level: levelEditModal.level
});

const LevelEditFormContainerConnected = connect(
  mapStateToProps
)(LevelEditFormContainerComposed);

export default LevelEditFormContainerConnected;
