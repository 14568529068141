/* @flow */

import React from 'react';
import { Row, Col } from 'reactstrap';
import {
  CoursesActionsContainer, LevelCollapseListContainer, LevelEditFormContainer,
  CourseEditFormContainer, CoursesSectionPlaceholderContainer
} from '../containers';  
import RoleFilter from '../containers/RoleFilter';

type Props = {
  hasLevels: boolean
};

/**
 * component to render the courses, using a collapse-like hierarchy based UI
 * and also some buttons for some actions
 *
 * @method CoursesSection
 *
 */
const CoursesSection = ({ hasLevels }: Props) => {
;
  const collapsesSection = (
    <Row> 
      <RoleFilter roles={['teacher','coordinator']}>


        <Col xs="12">
          <CoursesActionsContainer />
        </Col>


      </RoleFilter>
        <Col xs="12"> 
          <LevelCollapseListContainer />  
        </Col>
    </Row>
  );

  return (
    <div>
      {hasLevels && collapsesSection}
      {!hasLevels && <CoursesSectionPlaceholderContainer />}

      {/* These modals will only render when activated */}
      <LevelEditFormContainer />
      <CourseEditFormContainer />
    </div>
  );
};

export default CoursesSection;
