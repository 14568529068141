/* @flow */

export const setActiveChild = ({ id }: Object) => {
  return {
    type: 'SET_ACTIVE_CHILD',
    payload: {
      id
    }
  };
};

export const updateChild = (child: Object) => {
  return {
    type: 'UPDATE_CHILD',
    payload: {
      child
    }
  };
};

export const deleteChild = ({ id }: Object) => {
  return {
    type: 'DELETE_CHILD',
    payload: {
      id
    }
  };
};

export const addChild = (child: Object) => {
  return {
    type: 'ADD_CHILD',
    payload: {
      child
    }
  };
};

type handleChildrenEditionPayload = {
  newChildren?: Array<Object>,
  updatedChildren?: Array<Object>,
  deletedChildren?: Array<Object>,
  // additional actions to be dispatched on success
  onSuccessActions?: Array<Object>,
  formName?: string
};

export const handleChildrenEdition = (payload: handleChildrenEditionPayload) => {
  return {
    type: 'HANDLE_CHILDREN_EDITION',
    payload
  };
};
