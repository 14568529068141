/* @flow */

import React from 'react';
import { connect } from 'react-redux';
import { compose, pure, withHandlers, withState } from 'recompose';
import { startSubmit } from 'redux-form';
import  SchoolUserSignupForm   from '../components/SchoolUserSignUpForm';
import { FORM_NAME } from '../components/SchoolUserSignUpForm'; 
import actions from '../../actions';
import type { RecomposeHandler, ReduxFunction } from '../components/types';
import { Paper } from '../components/';
import Translations from '../hocs/TranslationsHelper'
import { withRouter } from 'react-router';

type Props = {
  data: Object,
  onSubmit: RecomposeHandler,
  dispatch: ReduxFunction
};

/**
 * @method SchoolUserSignupFormContainer
 * @param  {Object}  data
 */
const SchoolUserSignupFormContainer = ({ loading,error, data, onSubmit, onClickButton, translations }: Props) => {
 
    if(loading)
        return <div>loading...</div> 
    else
        return (
            <div> 
              {error}
              <SchoolUserSignupForm onSubmit={onSubmit}  />
            </div>
        );
};
 
export const SchoolUserSignupFormContainerHandlers = {
  onSubmit: ({ dispatch, token }: Props) => (values: Object) => {
    const payload = values ; 
    dispatch(startSubmit(FORM_NAME));
    dispatch(actions.signUpSchoolUser({...payload,token }));
  }    
};  

export const SchoolUserSignupFormContainerLifecycle = {
 
    componentDidMount() {
 
    }
};

const SchoolUserSignupFormContainerComposed = compose(
  withHandlers(SchoolUserSignupFormContainerHandlers),
    
  withState('loading', 'setLoading', false),   
  withState('error', 'setError', null),

  Translations, 
)(SchoolUserSignupFormContainer);

const mapStateToProps = ({ user, language,school_user_invitation_token_status }, ownProps) => {

  const { queryParams } = ownProps ;
  const { token } = queryParams; 
  return {
      token 
  }; 
};

const mapDispatchToProps = (dispatch) => ({ dispatch });

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SchoolUserSignupFormContainerComposed) );
