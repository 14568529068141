/* @flow */

import React from 'react';
import { connect } from 'react-redux';
import { compose, withHandlers, pure } from 'recompose';
import { Modal, StudentEditForm } from '../components';
import type { Student, RecomposeHandler, ReduxFunction } from '../components/types';
import { closeEditStudentModal } from '../../actions/students';

type Props = {
  student?: Student,
  active: boolean,
  closeModal: RecomposeHandler,
  dispatch: ReduxFunction
};

/**
 * this component passes the submit callback to the StudentEditForm.
 * It also fetches the student data and passes it down to StudentEditForm
 *
 * @method StudentEditFormContainer
 * @param  {Object}  student     - student to be edited
 *
 */
const StudentEditFormContainer = ({ student, active, closeModal }: Props) => {
  return (
    <Modal
      backdropClassName="overlay-backdrop"
      contentClassName="overlay-modal-content"
      active={active}
      toggle={closeModal}
    >
      <StudentEditForm student={student} closeModal={closeModal} />
    </Modal>
  );
};

export const StudentEditFormContainerHandlers = {
  closeModal: ({ dispatch }: Props) => () => {
    dispatch(closeEditStudentModal());
  }
};

const StudentEditFormContainerComposed = compose(
  withHandlers(StudentEditFormContainerHandlers),
  pure
)(StudentEditFormContainer);

const mapStateToProps = ({ studentEditModal }) => ({
  active: studentEditModal.active,
  student: studentEditModal.student
});

const StudentEditFormContainerConnected = connect(
  mapStateToProps
)(StudentEditFormContainerComposed);

export default StudentEditFormContainerConnected;
