/* @flow */
import React, { useContext, useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { Paper, InfoTooltip, Button } from '../components/';
import {
  DataDisplayParentContainer, IntelligencesTopicContainer, IntelligencesProgressContainer,
  ActivityFeedContainer, HeaderContainer, PageTitleContainer, SchoolDashboardNavContainer, SchoolDashboardHeadingContainer
} from '../containers/';
import '../../css/main.css';
import Translations from '../hocs/TranslationsHelper';
import TranslationsHelper from '../hocs/TranslationsHelper';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'react-router-redux';
import { SchoolRoleFilter } from '../components/SchoolRoleFilter';
import topScroll from '../hocs/topScroll';
import axios from 'axios';

const SectionColor = React.createContext("purple");
const SchoolSection = TranslationsHelper( ({translations,title, color ,children})=>{
  return <div>
    <p className='school-home-section-title'>{translations.t(title)}</p>
    <div className='school-home-links'>
      {children}
    </div>
  </div>
})
const SchoolLink = TranslationsHelper(({
  name,
  to,
  link,
  translations,
  roles,
  icon,
  description,
  bg
})=>{
  const sectionColor = useContext(SectionColor);
  const dispatch = useDispatch();
  const color = sectionColor;

  return <SchoolRoleFilter roles={roles}> 
    <div
      className="books-category-wrap"
      style={{backgroundColor:bg}}
      onClick={() => dispatch(push(to))}>
      <img crossorigin="anonymous" className="books-category-image" src={icon}/>
      <p className="school-home-link-title-down">{name}</p>
        <div className={`school-home-link-over school-home-${color}-hl`}>
          <p className="school-home-link-title">{name}</p>
          <p>{description}</p>
        </div>
    </div>
  </SchoolRoleFilter>;
});



const ModalPaper = ({dataModal, changeModal, onOpenRead, onOpenWrite}) => {
  console.log(dataModal, 'data')

  return (
    <div className='modal-paper'>
      <button
        className='modal-paper__close'
        onClick={() => changeModal()}
      >
        <i
          className="pleiq-icon-icon_arrow_mini_down"
          aria-hidden="true">
        </i>
      </button>
      <div className='modal-paper__wrapper'>
        <div className='modal-paper__image'>
          <img crossorigin="anonymous" src={dataModal.image}/>
          {/* <Button className='modal-paper__button' type='primary' onClickButton={onOpenRead}>Leer</Button> */}
          <Button className='modal-paper__button' type='primary' onClickButton={onOpenWrite}>Abrir</Button>
        </div>
        <div className='modal-paper__content'>
          <h4>{dataModal.title}</h4>
          <strong>{dataModal.subtitle}</strong>
          <div dangerouslySetInnerHTML={{ __html: dataModal.content }} className='modal-paper__content-text'/>
        </div>
      </div>
    </div>
  )
};

/**
 * Render DigitalPaper screen
 * @method DigitalPaper
 * @param  {object}                    translations - injected by our Translations HoC to give translated strings
 */
const DigitalPaper = ({ translations,queryParams,history }) => {
  topScroll();

  console.log("PARAMS: ", queryParams);

  const selectedCategoryId= queryParams.catalog_id;
  const selectedBookId = queryParams.book_id;

  const user = useSelector(({user})=>user);
  const [books, setBooks] = useState(null);
  const [categories, setCategories] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [dataModal, setDataModal] = useState({});
  const [showContent, setShowContent] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const categoriesResponse = await axios.get('https://pd.caligrafix.cl/api/categories?pageSize=100');
        const booksResponse = await axios.get('https://pd.caligrafix.cl/api/books?pageSize=100');
        setCategories(categoriesResponse.data.rows);
        setBooks(booksResponse.data.rows);
      } catch (error) {
        console.error('Error al obtener la data:', error);
      }
    };

    fetchData();
  }, []);
  let selectedBook = null;
  let selectedCategory = null;
  if(books ){
    selectedBook = selectedBookId && _.find(books, ({id})=> id == selectedBookId);
    selectedCategory = selectedCategoryId && _.find(categories, ({id})=> id == selectedCategoryId);

  }

  const handleSearch = term => {
    setSearchTerm(term);
  };

  const filteredCategories = categories && categories.filter(category => category.title.toLowerCase().includes(searchTerm.toLowerCase()));
  let filteredBooks = books && books.filter(book => book.title.toLowerCase().includes(searchTerm.toLowerCase()));

  if(selectedCategoryId)
    filteredBooks = books&& books.filter(book => book.categoryId == parseInt(selectedCategoryId)) ;
  const openModalDetail = (title, subtitle, content, image) => {
    setOpenModal(true)
    setShowContent(false)
    setDataModal( () => ({
      image: image,
      title: title,
      subtitle: subtitle,
      content: content,
    }))
  }

  const changeModal = () => {
    console.log('clic close')
    setOpenModal(false)
    setShowContent(true)
  }

  return (
    <div id="school-dashboard" className="school-dashboard layout-base digital-paper">
      <PageTitleContainer pageTitle="Digital Paper" />
      <HeaderContainer />
      <SchoolDashboardHeadingContainer />
      <Paper.Container lead>
        <SchoolDashboardNavContainer currentTab="home" />
        {
          !selectedBook && !selectedCategory && (
            <Row> 
              <Col xs="12">
                <div className='books-search'>
                  <Paper.Title>
                    <h1 className="school-home-title">Biblioteca Papel Digital</h1> 
                  </Paper.Title>
                  <input
                    type="text"
                    placeholder="Buscar..."
                    value={searchTerm}
                    className='books-search-input'
                    onChange={e => handleSearch(e.target.value)}
                  />
                </div>
              </Col>
              <Col xs="12" >
                <SchoolSection title="" color="purple">
                  {
                    filteredCategories &&
                      filteredCategories.map( category => (<SchoolLink
                        icon={category.mainImage}
                        name={category.title}
                        to={`/school/digital_paper?catalog_id=${category.id}`}
                        key={category.id}
                        description={category.subtitle}
                        bg={category.color}
                      />
                    ))
                  }
                </SchoolSection>
              </Col>
              <Col xs="12">
                <Paper.Title>
                  <h1 className="school-home-title">Cuadernos</h1> 
                </Paper.Title>
              </Col>
              <Col xs="12">
                <SchoolSection>
                  <Row>
                    {
                      filteredBooks &&
                        filteredBooks.map( book => (<Col xs="12" md="6">
                          <div
                            className='books-list'
                            onClick={() =>  dispatch(push("/school/digital_paper?book_id="+book.id))}
                          >
                            <img crossorigin="anonymous" src={book.coverImage} width={150}/>
                            <div className='books-content'>
                              <p><strong>{book.title}</strong></p>
                              <span>{book.subtitle}</span>
                            </div>
                          </div>
                        </Col>
                      ))
                    }
                  </Row>
                </SchoolSection>
              </Col>
            </Row> 
          )
        }
        {
          !selectedBook && selectedCategory && (
            <Row>  
              <Col xs="12">
                <Paper.Title> 
                  <div className='digital-paper-category-header'  >

                    <button
                      className='modal-paper__close2'
                      onClick={() => {dispatch(push("/school/digital_paper"))}}
                    >
                      <i
                        className="pleiq-icon-icon_arrow_mini_down"
                        aria-hidden="true">
                      </i>
                    </button>
                    <h1 className="school-home-title">{selectedCategory.title}</h1> 
                    <img crossorigin="anonymous" src={selectedCategory.mainImage}  width={ 50}/> 
                  </div>
                </Paper.Title>
              </Col>
              <Col xs="12">
                <SchoolSection>
                  <Row>
                    {
                      filteredBooks &&
                        filteredBooks.map( book => (<Col xs="12" md="6">
                          <div
                            className='books-list'
                            onClick={() =>  dispatch(push("/school/digital_paper?catalog_id="+ selectedCategoryId+"&&book_id="+book.id))}
                          >
                            <img crossorigin="anonymous" src={book.coverImage} width={150}/>
                            <div className='books-content'>
                              <p><strong>{book.title}</strong></p>
                              <span>{book.subtitle}</span>
                            </div>
                          </div>
                        </Col>
                      ))
                    }
                  </Row>
                </SchoolSection>
              </Col>
            </Row> 
          )
        }
        {
          selectedBook && <ModalPaper dataModal={{
            image: selectedBook.coverImage,
            title: selectedBook.title,
            subtitle: selectedBook.subtitle,
            content: selectedBook.summary,
          }} changeModal={()=>dispatch(push((selectedCategoryId)?("/school/digital_paper?catalog_id="+ selectedCategoryId):"/school/digital_paper" ))} 
            onOpenWrite={()=>window.open("/school/digital_paper/"+ selectedBookId+"?mode=write", "_blank") }
            onOpenRead={()=>dispatch(push("/school/digital_paper/"+ selectedBookId+"?mode=read"))}
            
          />
        }
      </Paper.Container> 
    </div>
  );
};

export default Translations(DigitalPaper);
