
import React from 'react';
import { compose, lifecycle, shouldUpdate, withState } from 'recompose';
import { connect } from 'react-redux';
import actions from '../../../actions';
import { Modal } from 'reactstrap';
import { Document, Page, pdfjs } from "react-pdf";

import starImg from '../../../img/activity_bank/star_99.png';
import starGrayImg from '../../../img/activity_bank/star_99_b.png';
import { rateLessonPlan } from '../../../actions/lessonPlans';
import { FillHeightDown } from '../../components/FillHeightDown';
import downloadIcon from "../../../img/icon_download.svg";
import { SimpleToolTip } from './SimpleToolTip';
import TranslationsHelper from '../../hocs/TranslationsHelper';
 
export class ExpandedPdfC extends React.Component {

  constructor(props) {
      super(props)
      
      this.state = {}
  }
  componentDidMount () {
    this.updateHeight()
    window.addEventListener("resize", this.updateHeight  ) 
    pdfjs.GlobalWorkerOptions.workerSrc =`https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`; 


  }

  componentWillUnmount () {
    window.removeEventListener("resize", this.updateHeight ) 
  }

  updateHeight = () => {
    this.setState({height:  this.wrapper.getBoundingClientRect().height ,width:  this.wrapper.getBoundingClientRect().width  })
  }

  render() {
      const {page, onLoadSuccess, pdf_uri, translations} = this.props;

      return (
        <div  ref={(ref) => this.wrapper = ref} className={this.props.className} > 
  
          <Document file={pdf_uri} loading={translations.t("ab_loading")} onLoadSuccess={onLoadSuccess}>
  
            <Page width={this.state.width} height={this.state.height}
              pageNumber={page}
            /> 
          </Document>  
        </div>
      )
  }

} 
const ExpandedPdf = TranslationsHelper(ExpandedPdfC);

const LessonPlanData  = compose(withState("pageCount", "setPageCount"),
                                withState("page", "setPage",1))(({page,translations,  setPage,lesson_plan ,kind, onClose,pageCount,setPageCount, dispatch})=>{
  if(!lesson_plan )
  return <div>Not selected</div>; 

  // onLoadSuccess={onDocumentLoadSuccess}
  const {id, user_score,name, pdf_uri,global_score, votes} = lesson_plan  ;
/*
  <Modal
    backdropClassName="overlay-backdrop"
    contentClassName="ab-modal-content"
    size="lg"
    active={true}
    isOpen={true}
    toggle={() => onClose()}>*/

  return <div className="ab-pdf"> 

      <div className="ab-pdf"> 
        <div className="ab-pdf-back" onClick={(e)=>{e.stopPropagation();e.preventDefault();onClose();}}></div>
        <div className="rcr">
          <div className="ab-pdf-rect">
            <div className="ab-pdf-rect-header"> 
              <a className="ab-pdf-rect-header-dbutton" id={"header-dbutton" } href={pdf_uri}  target="_blank" download onClick={(e)=>{e.stopPropagation()}}>
                <img src={downloadIcon}/>
                <SimpleToolTip text={translations.t("ab_download_tooltip")} target={"header-dbutton" } />
              </a>
              <div className="ab-pdf-rect-header-nav">

                <div className="ab-pdf-rect-header-nav-button" onClick={()=>{if(page>1) setPage(page-1);}}>
                  <span
                    className="pleiq-icon-icon_arrow_left"
                  />
                </div>
                <div className="ab-pdf-rect-header-nav-page">
                  {page}/{pageCount}
                </div>
                <div className="ab-pdf-rect-header-nav-button" onClick={()=>{if(page < pageCount )setPage(page+1);}}> 
                  <span
                    className="pleiq-icon-icon_arrow_right"
                  />
                </div> 

              </div>
              <button onClick={onClose}>X</button>
            </div>
            <ExpandedPdf className="ab-pdf-rect-content" pdf_uri={pdf_uri} page={page}  onLoadSuccess={({ numPages })=>{setPageCount(numPages); }}/> 
          {  
            } 
          </div>           
        </div>

      </div>
    </div>
 
  //</Modal> 
});
  


const LessonPlanDataContainer  = connect((state, {kind, id})=>{
    if(!state.lesson_plans[kind])return {};
  return {
    lesson_plan : state.lesson_plans[kind].data.find((p)=>p.id===id)
  }    
}, (dispatch)=>({dispatch}))(LessonPlanData);

export default TranslationsHelper( LessonPlanDataContainer );