/* @flow */

import * as lessonPlanActions from './lessonPlans';
import * as achievementCardActions from './achievementCards';
import * as activityActions from './activity';
import * as htmlContentModalActions from './htmlContentModal';
import * as intelligenceHistoryActions from './intelligenceHistory';
import * as intelligenceProgressActions from './intelligenceProgress';
import * as languageActions from './language';
import * as parentActions from './parent';
import * as schoolGeneralAchievementCardsProgress from './schoolGeneralAchievementCardsProgress';
import * as schoolGeneralCoursesProgressActions from './schoolGeneralCoursesProgress';
import * as schoolGeneralIntelligenceHistory from './schoolGeneralIntelligenceHistory';
import * as schoolStudentProgress from './schoolStudentProgress';
import * as userActions from './user';
import * as directorActions from './director';
import * as levelActions from './levels';
import * as courseActions from './courses';

import * as caligrafixBookHistoryActions from './caligrafixBookHistory';
import * as caligrafixBookProgressActions from './caligrafixBookProgress';
import * as schoolGeneralCaligrafixBookHistory from './schoolGeneralCaligrafixBookHistory';
import * as schoolGeneralCoursesCaligrafixBookProgressActions from './schoolGeneralCoursesCaligrafixBookProgress';

import * as schoolStudentComments from './schoolStudentComments';

import * as schoolUserInvitations from './schoolUserInvitations';
import * as schoolUsers from './schoolUsers';
import * as genericData from './genericData';
const actions = {
  ...achievementCardActions,
  ...activityActions,
  ...htmlContentModalActions,
  ...intelligenceHistoryActions,
  ...intelligenceProgressActions,
  ...languageActions,
  ...parentActions,
  ...schoolGeneralAchievementCardsProgress,
  ...schoolGeneralCoursesProgressActions,
  ...schoolGeneralIntelligenceHistory,
  ...schoolStudentProgress,
  ...userActions,
  ...directorActions,
  ...levelActions,
  ...courseActions,
  ...lessonPlanActions,

  
  ...caligrafixBookHistoryActions,
  ...caligrafixBookProgressActions,
  ...schoolGeneralCaligrafixBookHistory,
  ...schoolGeneralCoursesCaligrafixBookProgressActions,

  ...schoolStudentComments,
  ...schoolUserInvitations,

  ...schoolUsers,

  ...genericData

};

export default actions;
