/* @flow */

import React from 'react';
import classNames from 'classnames';
import Button from './Button';
import ProfileAvatar from './ProfileAvatar';
import type { Child } from './types';

export const MIN_CHILDREN = 1; 

const renderChildren = (children, activeIndex, onClick) => children.map((child, index) => {
  const baseClass = 'child';
  const itemClass = classNames(baseClass, {
    [`${baseClass}--selected`]: index === activeIndex }
  );
  return (
    <li key={index} className={itemClass} onClick={() => onClick(index)}>
      <ProfileAvatar color={child.color} />
    </li>
  );
});

type Props = {
  children: Array<Child>,
  activeIndex: number,
  onCreateChild: () => mixed,
  onDeleteChild: () => mixed,
  onSelectChild: () => mixed
};

/**
 * A component to display a list of children with add and remove buttons
 *
 * @method ChildrenController
 *
 */
const ChildrenController = ({ maxChildren,children, activeIndex, onCreateChild, onDeleteChild, onSelectChild }: Props) => {
  const deleteButton = (
    <li className="children-button">
      <Button type="ball" onClickButton={onDeleteChild}>
        <span className="pleiq-icon-icon_minus" />
      </Button>
    </li>
  );
  const addButton = (
    <li className="children-button">
      <Button type="ball" onClickButton={onCreateChild}>
        <span className="pleiq-icon-icon_plus" />
      </Button>
    </li>
  );

  return (
    <ul className="list-unstyled children">
      <div className="children-list">
        {renderChildren(children, activeIndex, onSelectChild)}
      </div>
      <div className="children-actions">
        {children.length > MIN_CHILDREN && deleteButton}
        {children.length < maxChildren && addButton}
      </div>
    </ul>
  );
};

export default ChildrenController;
