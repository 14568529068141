/* @flow */

import React from 'react';
import { Dropdown, DropdownMenu, DropdownItem } from 'reactstrap';
import { withState, withHandlers, pure, compose } from 'recompose';
import { ProfileListContainer } from '../containers/';
import { Link } from 'react-router-dom';
import Translations from '../hocs/TranslationsHelper';
import type { User, RecomposeStateName, RecomposeStateUpdater, RecomposeHandler } from './types';

type Props = {
  user: User,
  dropdownOpen: RecomposeStateName,
  onClickDropdownItem: () => mixed,
  _onClickDropdownItem: RecomposeHandler,
  toggle: RecomposeStateUpdater,
  translations: Object
};

/**
 * component that will have access to the user information and is able to
 * render properly the user avatar. check more about `reactstrap` components user at:
 * {@link https://reactstrap.github.io/components/dropdowns/}
 *
 * @method Avatar
 * @param  {boolean}      dropdownOpen          - injected by `recompose` as an state to control wether or not the DropdownMenu is visible
 * @param  {function}     toggle                - injected by `recompose` as a stateUpdater to toggle the dropdownOpen state
 * @param  {function}     onClickDropdownItem   - typically injected by its container to set the callback when dropdown option is clicked
 * @param  {function}     _onClickDropdownItem  - injected by `recompose` as a handler when a dropdown option is clicked
 * @param  {User}         user                  - object with fetched information needed to display this component
 * @param  {translations} Object                - object to use with "t" method in order to inject locale text
 *
 */
export const Avatar = ({ user, dropdownOpen, toggle, _onClickDropdownItem, translations }: Props) => {
  const { first_name ,user_name, apprender_sid} = user;
  
  return (
    <div className="avatar">
      <Dropdown isOpen={dropdownOpen} style={{left:"auto"}} inNavbar={true} toggle={() => toggle(!dropdownOpen)} nav={true} >
        <div className="dropdown-trigger" onClick={() => toggle(!dropdownOpen)}>
          <p className="avatar-name">{(user_name)?user_name:first_name}</p>
            <span
              className="dropdown-icon"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded={dropdownOpen}>
            <span className="pleiq-icon-icon_arrow_mini_down" />
          </span>
        </div>
        {AvatarDropdownMenu({ dropdownMenuProps: { end: true }, onClickDropdownItem: _onClickDropdownItem, translations, showConfig: !apprender_sid })}
      </Dropdown>
    </div>
  );
};

const AvatarDropdownMenu = ({showConfig,  dropdownMenuProps, onClickDropdownItem, translations }) => {
  return (
    <DropdownMenu {...dropdownMenuProps}>
      <ProfileListContainer />
      {
      (showConfig)?<DropdownItem tag={Link} to="/settings">
        <span className="pleiq-icon pleiq-icon-icon_settings" />
        {translations.t('settings')}
      </DropdownItem>:null
      }
      <DropdownItem onClick={onClickDropdownItem} data-action={'logout'}>
        <span className="pleiq-icon pleiq-icon-icon_logout" />
        {translations.t('logout')}
      </DropdownItem>
    </DropdownMenu>
  );
};

export const AvatarHandlers = {
  _onClickDropdownItem: (props: Object) => (event: Object) => {
    props.toggle();
    props.onClickDropdownItem(event);
  }
};

const AvatarEnhancements = compose(
  withState('dropdownOpen', 'toggle', false),
  withHandlers(AvatarHandlers)
);

const AvatarComposed = compose(
  AvatarEnhancements,
  pure
)(Avatar);

export default Translations(AvatarComposed);
