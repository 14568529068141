/* @flow */

import React from 'react';
import _ from 'lodash';
import { colorStringToHex } from './ColorSelect';

type Props = {
  color: string,
  onClick?: () => mixed
};

/**
 * The profile avatar rendered for each profile owned by a user, this component
 * allow us to set a specific set of params for a svg in order to do not need to
 * store multiples svg files within our assets for simple aesthetic changes
 *
 * @method ProfileAvatar
 * @param  {string}    color - HEX color to be usd in order to define a color for the profile
 */
const ProfileAvatar = ({ color, onClick = _.noop }: Props) => {
  const displayColor = colorStringToHex(color);
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className="svg-profile-avatar" onClick={onClick}>
      <title>A7D6C035-F7F1-4C8A-A57A-FBE4AFA58911</title>
      <defs>
        <path d="M18.156 4.137A11.974 11.974 0 0 0 9.094 0 11.974 11.974 0 0 0 .032 4.137v15.726A11.974 11.974 0 0 0 9.094 24c3.616 0 6.86-1.603 9.062-4.137V4.137z" id="a" />
        <path d="M3.556.037A1.538 1.538 0 0 0 3.439.03C2.76.007.249.03.249.03c-.07 0-.137.005-.2.014v1.02h3.507V.036z" id="c" />
      </defs>
      <g fill="none">
        <path d="M12 0c6.623 0 12 5.377 12 12s-5.377 12-12 12S0 18.623 0 12 5.377 0 12 0z" fill={displayColor} />
        <g transform="translate(2.906)">
          <mask id="b" fill="#fff">
            <use xlinkHref={'#a'} />
          </mask>
          <path d="M4.31 1.95c.11 2.68 1.465 5.19 4.78 5.19 3.314 0 4.629-2.34 4.78-5.344.12-2.435 0-8.464 0-8.464 0-2.542-2.143-4.605-4.78-4.605-2.639 0-4.78 2.063-4.78 4.605 0 0-.102 6.18 0 8.618zM13.878 25.05c-.111-2.68-1.465-5.19-4.78-5.19-3.314 0-4.63 2.34-4.78 5.343-.121 2.436 0 8.465 0 8.465 0 2.542 2.142 4.605 4.78 4.605 2.638 0 4.78-2.063 4.78-4.605 0 0 .101-6.181 0-8.618z" fill="#FFF" opacity=".7" mask="url(#b)" />
          <path d="M16.784 15.622c.626-.02 1.212-.266 1.212-.869 0-.602-.547-.841-1.248-.869-.569-.022-1.976 0-1.976 0-.593 0-1.075.39-1.075.87 0 .479.482.868 1.075.868 0 0 1.443.019 2.012 0zM3.277 15.622c.626-.02 1.212-.266 1.212-.869 0-.602-.546-.841-1.248-.869-.568-.022-1.976 0-1.976 0-.593 0-1.075.39-1.075.87 0 .479.482.868 1.075.868 0 0 1.443.019 2.012 0z" fill="#FFCECD" mask="url(#b)" />
          <path d="M9.764 15.587c.924-.03 1.594-.315 1.756-1.06.12-.556-.184-.984-.862-1.006-.68-.023-3.19 0-3.19 0-.709 0-1.094.476-.95 1.033.146.57.768 1.033 1.77 1.033 0 0 .796.022 1.476 0z" fill="#1C0000" mask="url(#b)" />
          <g mask="url(#b)">
            <g transform="translate(7.219 13.491)">
              <mask id="d" fill="#fff">
                <use xlinkHref={'#c'} />
              </mask>
              <path d="M2.566 1.054c.51-.02.988-.1.988-.702 0-.603.054-1.008-.518-1.036-.464-.022-2.443 0-2.443 0-.484 0-.544.556-.544 1.036s.393.702.876.702c0 0 1.177.019 1.641 0z" fill="#FFF" mask="url(#d)" />
            </g>
          </g>
          <path d="M2.444 8.352a2.413 2.413 0 0 1 0 4.824 2.413 2.413 0 0 1 0-4.824z" fill="#FFF" mask="url(#b)" />
          <path d="M2.444 9.184a1.58 1.58 0 1 1-.002 3.162 1.58 1.58 0 0 1 .002-3.162z" fill="#343434" mask="url(#b)" />
          <path d="M1.778 9.72a.38.38 0 1 1 0 .759.38.38 0 0 1 0-.76zM15.744 8.352a2.413 2.413 0 0 1 0 4.824 2.413 2.413 0 0 1 0-4.824z" fill="#FFF" mask="url(#b)" />
          <path d="M15.744 9.184a1.581 1.581 0 1 1-.002 3.162 1.581 1.581 0 0 1 .002-3.162z" fill="#343434" mask="url(#b)" />
          <path d="M15.079 9.72a.38.38 0 1 1 0 .758.38.38 0 0 1 0-.758z" fill="#FFF" mask="url(#b)" />
          <path d="M8.983 1.018c.103.002.205.01.306.025.234.037.463.114.663.242a1.38 1.38 0 0 1 .27.23 1.189 1.189 0 0 1 .286.608c.019.122.018.247.007.37-.015.16-.05.319-.113.467a1.222 1.222 0 0 1-.463.556 1.4 1.4 0 0 1-.913.212.955.955 0 0 1-.595-.315.958.958 0 0 1-.23-.523.841.841 0 0 1 .16-.607.74.74 0 0 1 .37-.26A.426.426 0 0 1 8.836 2a.316.316 0 0 1 .15.604c-.022.01-.045.015-.067.023a.128.128 0 0 0-.05.032c-.033.034-.042.085-.041.13a.323.323 0 0 0 .171.28c.077.039.164.04.248.032a.72.72 0 0 0 .435-.185.668.668 0 0 0 .179-.327 1.1 1.1 0 0 0 .027-.327.573.573 0 0 0-.073-.237c-.091-.156-.254-.255-.422-.31a1.182 1.182 0 0 0-.188-.045 1.526 1.526 0 0 0-.345-.016 1.021 1.021 0 0 0-.243.054.91.91 0 0 0-.381.262c-.163.193-.239.445-.271.691a2.536 2.536 0 0 0-.015.502c.005.084.017.168.033.25.076.387.26.745.5 1.055a3.549 3.549 0 0 0 .673.656l.02.014a.317.317 0 0 1 .096.366.319.319 0 0 1-.325.196.316.316 0 0 1-.107-.031c-.029-.014-.054-.033-.08-.052a4.01 4.01 0 0 1-.573-.52 3.414 3.414 0 0 1-.756-1.29 2.803 2.803 0 0 1-.117-.929c.013-.388.093-.784.298-1.12a1.616 1.616 0 0 1 .25-.312 1.498 1.498 0 0 1 .56-.34 1.654 1.654 0 0 1 .561-.088z" fill="#343434" mask="url(#b)" />
        </g>
      </g>
    </svg>
  );
};

export default ProfileAvatar;
