/* @flow */

import React from 'react';
import { Col, Row } from 'reactstrap';
import { Paper } from '../components/';
import { HeaderContainer, UserInfoFormContainer, UserChildrenFormContainer, PageTitleContainer } from '../containers/';
import Translations from '../hocs/TranslationsHelper';
import { SchoolLogoField } from '../containers/SchoolLogoField';
import SchoolDataForm from '../containers/school-admin/SchoolDataForm';
import { SchoolRoleFilter } from '../components/SchoolRoleFilter';
import topScroll from "../hocs/topScroll";

const ProfileSettings = ({ translations }) => {
  topScroll();
  return (
    <div id="profile-settings" className="layout-base">
      <PageTitleContainer pageTitle="title_user_settings" />
      <HeaderContainer />
      <div className="container-header-banner bg-secondary" />
      <Paper.Container lead narrow>
        <Row>
          <Col xs="12">
            <Paper.Title>
              <h1>{ translations.t('account_settings') }</h1>
            </Paper.Title>
          </Col> 
          <Col xs="12">
            <SchoolRoleFilter roles={['coordinator']}> 
              <SchoolDataForm/>
            </SchoolRoleFilter>

          </Col>
          <Col xs="12">
            <UserInfoFormContainer />
          </Col>
          <Col xs="12">
            <UserChildrenFormContainer />
          </Col>
        </Row>
      </Paper.Container>
    </div>
  );
};

export default Translations(ProfileSettings);
