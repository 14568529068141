/* @flow */

export function getSchoolGeneralCoursesProgress({ level_id }: Object) {
  return {
    type: 'REQUEST_SCHOOL_GENERAL_COURSES_PROGRESS',
    payload: {
      level_id
    }
  };
}
