/* @flow */

import React from 'react';
import type { Course } from './types';
import { CourseCollapseList } from './index';
import Translations from '../hocs/TranslationsHelper';
import { CollapseGroupContainer } from '../containers';

type Props = {
  title: string,
  count: string,
  levelId: string,
  courses: Array<Course>,
  onHeaderClick: () => mixed,
  onEditButtonClick: () => mixed,
  translations: Object
};

/**
 * component to display a Level with a course list inside a collapse
 *
 * @method LevelCollapseItem
 * @param  {string}         title                - title for the header
 * @param  {string}         count                - count of how many courses
 * @param  {string}         levelId              - the id of the level
 * @param  {Array<Course>}  courses              - array of courses to render CourseCollapseList
 * @param  {function}       onHeaderClick        - callback for when the whole header gets clicked
 * @param  {function}       onEditButtonClick    - callback for the edit button on the header
 * @param  {Object}         translations         - object containing app translations
 *
 */
const LevelCollapseItem = ({ title, count, all, levelId, courses, onHeaderClick, onEditButtonClick, translations }: Props) => {
  const translatedCount = `${count} ${translations.t('courses')}`;
  const translatedTitle = (title)?`${translations.t('level')} ${title}`
                                  :translations.t('unassigned_courses');
  return (
    <CollapseGroupContainer
      className="level-collapse"
      title={translatedTitle}
      id={levelId}
      icon="icon_level"
      count={translatedCount}
      onHeaderClick={onHeaderClick}
      onEditButtonClick={onEditButtonClick}
      editRoles={['coordinator']}
      all={all}
    >
      <CourseCollapseList courses={courses} all={all}/>
    </CollapseGroupContainer>
  );
};

export default Translations(LevelCollapseItem);
