/* @flow */

import React from 'react';
import classNames from 'classnames';
import _ from 'lodash';

type AlertType = 'success'|'warning'|'error';

type Props = {
  children: ?React.Component<*>,
  type: AlertType,
  onClose: Function,
  dismissible: ?boolean
};

/**
 * A custom alert component to render messages in the application.
 *
 * @method Alert
 * @param  {React.Components}     children      - a tree of elements within the component
 * @param  {string}               type          - the type of alert to render (can be success success or warning)
 * @param  {Function}             onClose       - a function callback that gets called when the close button is clicked
 *
 */
const Alert = ({ children, type, onClose, dismissible }: Props) => {
  const iconClass = getIconClass(type);
  const alertClass = getAlertClass(type);
  const close = dismissible ? alertClose(onClose) : <div />;

  return (
    <div className={alertClass}>
      <span className={iconClass} />
      <span className="alert-message">{children}</span>
      {close}
    </div>
  );
};

const alertClose = (onClose = _.noop) => {
  return (
    <div onClick={onClose} className="alert-close">
      <div className="pleiq-icon-icon_plus alert-close-icon" />
    </div>
  );
};

const getAlertClass = (type: AlertType) => {
  return classNames(
    'alert',
    'alert-success',
    `alert-type-${type}`,
    'd-flex',
    'justify-content-between',
    'align-items-center'
  );
};

const getIconClass = (type: AlertType) => {
  const alertType = alertTypes[type];

  return classNames(
    `${alertType.class}`,
    'alert-icon',
    'vcentered',
    'hidden-xs-down'
  );
};

const alertTypes = {
  success: { class: 'pleiq-icon-icon_checkmark' },
  warning: { class: 'pleiq-icon-icon_info' },
  error: { class: 'pleiq-icon-icon_info' }
};

export default Alert;
