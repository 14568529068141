/* @flow */

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { pure, compose } from 'recompose';
import { RadialProgressBar } from '../components';
import _ from 'lodash';
import { getIntelligenceBySlug } from '../../selectors/intelligences';
import { getIntelligenceProgressById, getAllIntelligenceProgressByChild } from '../../selectors/intelligenceProgress';

const getProgress = (intelligenceProgress) => {
  const hasScore = _.has(intelligenceProgress, 'score');
  return parseInt(hasScore ? intelligenceProgress.score : 0, 10);
};

/*
* Container to display intelligence progress
*
* @method StudentRadialProgressBarContainer
*/
const StudentRadialProgressBarContainer = ({ currentIntelligenceProgress }) => {
  const value = getProgress(currentIntelligenceProgress);

  return (
    <RadialProgressBar className="has-progress-rail" size="lg" value={value} />
  );
};

export const StudentRadialProgressBarContainerComposed = compose(
  pure
)(StudentRadialProgressBarContainer);

const mapStateToProps = ({ student_intelligence_progress, intelligences }, ownProps) => {
  const { params } = ownProps.match;
  const { slug, student_id } = params;

  const intelligence = getIntelligenceBySlug({ intelligences }, slug);
  const currentStudentProgress = getAllIntelligenceProgressByChild({ intelligence_progress: student_intelligence_progress }, student_id);
  const currentIntelligenceProgress = getIntelligenceProgressById({ intelligence_progress: currentStudentProgress }, intelligence.id);

  return {
    currentIntelligenceProgress
  };
};

const mapDispatchToProps = (dispatch) => ({ dispatch });

const StudentRadialProgressBarContainerConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(StudentRadialProgressBarContainerComposed);

export default withRouter(StudentRadialProgressBarContainerConnected);
