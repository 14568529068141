/* @flow */

import React from 'react';
import { connect } from 'react-redux';
import { pure, compose, withHandlers, lifecycle, defaultProps } from 'recompose';
import { UserPasswordResetForm } from '../components';
import { startSubmit,stopSubmit, change } from 'redux-form';
import { FORM_NAME } from '../components/UserPasswordResetForm';
import { requestUserResetPassword } from '../../actions/user';
import type { ReduxFunction, ReduxFormFunction, RecomposeHandler } from '../components/types';

type Props = {
  dispatch: ReduxFunction,
  onSubmit: RecomposeHandler,
  handleSubmit: ReduxFormFunction,
  queryParams?: Object
};

/**
 * Container that will handle the logic to allow a user to reset password
 *
 * @method UserPasswordResetFormContainer
 */
const UserPasswordResetFormContainer = ({ onSubmit }: Props) => {
  return <UserPasswordResetForm onSubmit={onSubmit} />;
};

export const UserPasswordResetFormContainerHandlers = {
  onSubmit: (props: Props) => ({ password, password_reset_token , passwordRetype}: Object) => {
    const { dispatch } = props;

    const payload = {
      password,
      password_confirmation: passwordRetype,
      password_reset_token
    };
    if(password !== passwordRetype) {
    // alert(locale == "es" ? "Ambos correos deben ser iguales" : "Both emails must match");
        dispatch(stopSubmit(FORM_NAME, {"passwordRetype":["passwords_should_match"]  }));
        //return;
    }else{
        dispatch(startSubmit(FORM_NAME));
        dispatch(requestUserResetPassword(payload));
        
    }
  }
};

const UserPasswordResetFormContainerEnhancements = compose(
  withHandlers(UserPasswordResetFormContainerHandlers),
  defaultProps({
    queryParams: {}
  })
);

const mapDispatchToProps = (dispatch) => {
  return { dispatch };
};

const UserPasswordResetFormContainerLifecycle = {
  componentWillMount() {
    // Set password_reset_token from queryParams to form state
    // this is the only way to set values in a form programatically in redux-form
    this.props.dispatch(change(FORM_NAME, 'password_reset_token', this.props.queryParams.token));
  }
};

const UserPasswordResetFormContainerComposed = compose(
  UserPasswordResetFormContainerEnhancements,
  lifecycle(UserPasswordResetFormContainerLifecycle),
  pure
)(UserPasswordResetFormContainer);

const UserPasswordResetFormContainerConnected = connect(
  null,
  mapDispatchToProps
)(UserPasswordResetFormContainerComposed);

export { UserPasswordResetFormContainerConnected as UserPasswordResetFormContainer };
export default UserPasswordResetFormContainerConnected;
