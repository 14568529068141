
import React from 'react';
import { Table  }   from "reactstrap" ;
import DataRetriever, { invalidateRetriever } from '../hocs/DataRetriever';
import { withRouter } from 'react-router';
import { compose, withState } from 'recompose';
import  {StudentRepresentantFormContainer, StudentRepresentantEditFormContainer } from './StudentRepresentantFormContainer';
import { Modal, Paper, Button } from '../components';
import { connect } from 'react-redux';
import actions from '../../actions';
import TranslationsHelper from '../hocs/TranslationsHelper';
import { showConfirmationModal } from '../../actions/confirmationModal';

const StudentRepresentants = ({translations, onEditClick, student_id, data,loading, dispatch})=>{
    if(!data)return null;
    const {body,  status,errors} = data;  
  
    if(loading ||  body===undefined || body===null) return <span>loading</span> ;
    if( status>=400)return <span>error</span>;
   

    return <div>
        <Table>
        <tr>
                { 
                  ["user_name","email"  ].map(
                    (c)=>( 
                      <th key={c}>
                        {translations.t(c)} 
                      </th> 
                      ) 
                  )


                } 
                <th></th>
                <th></th>
              </tr>
              {
                  body.map(({id, user_id, user_name,email, created_at})=>(
                      <tr key={id} className="collapse-header-base">
                          <td> 
                            {user_name}
                          </td>
                          <td> 
                            {email}
                          </td> 
                          <td> 
                            <Button type="edit" className="collapse-header-edit-button"  onClick={()=>{
                              onEditClick({user_id,id,user_name,email});
                            }}>
                              {translations.t("edit")}
                            </Button>
                          </td> 
                          < td> 
                            <Button type="ball" onClick={()=>{
                               dispatch(showConfirmationModal({
                                title: 'remove_representative_confirmation_title',
                                message: 'remove_representative_confirmation_message',
                                actions: [ 
                                  actions.genericDataRequest('', `/school/students/${student_id}/representatives/${id}`,'delete',null,(result)=>{
                                    invalidateRetriever('STUDENT_REPRESENTANTS'); 
                                  })
                                ]
                              }));
                            }}><span className="pleiq-icon-icon_minus" /></Button>
                          </td>  
                      </tr>
                  ))
              }
        </Table>

    </div>;
}

const StudentRepresentantsRetriever = connect(()=>({}), (dispatch)=>({dispatch}))(
  DataRetriever(TranslationsHelper(  StudentRepresentants) )
) ;

const StudentRepresentantsContainer = ({translations,editing, setEditing, match, showModal, setShowModal}) => {
    const {student_id} = match.params;
    return <div>
            <StudentRepresentantsRetriever onEditClick={(d)=>setEditing(d)} student_id={student_id} id="STUDENT_REPRESENTANTS" dataUrl={`/school/students/${student_id}/representatives`} />
            
            <Modal
              backdropClassName="overlay-backdrop"
              contentClassName="overlay-modal-content"
              active={showModal}
              toggle={()=>setShowModal(false)}
              >
              <div className="confirmation-modal">
                <span className="modal-close pleiq-icon-icon_plus" onClick={()=>setShowModal(false)} />
                <Paper.Container>
                  <div className="confirmation-modal-content"> 
                        <StudentRepresentantFormContainer closeModal={()=>setShowModal(false)} student_id={student_id}/>
                    
                  </div>
                </Paper.Container>
              </div>
            </Modal>

            <Modal
              backdropClassName="overlay-backdrop"
              contentClassName="overlay-modal-content"
              active={editing}
              toggle={()=>setEditing(null)}
              >
              <div className="confirmation-modal">
                <span className="modal-close pleiq-icon-icon_plus" onClick={()=>setEditing(null)} />
                <Paper.Container>
                  <div className="confirmation-modal-content"> 
                    <StudentRepresentantEditFormContainer data={editing}  closeModal={()=>setEditing(null)} student_id={student_id}/>
                    
                  </div>
                </Paper.Container>
              </div>
            </Modal>
            <Button onClick={()=>setShowModal(true)}> 
              <span className="courses-actions-icon pleiq-icon-icon_plus" />
              {translations.t("add_representative")}
            </Button>
        </div>

}
export default TranslationsHelper( withRouter( compose(withState("editing","setEditing"),withState("showModal","setShowModal"))(StudentRepresentantsContainer)  ));