/* @flow */

export function getSchoolGeneralCaligrafixBookHistory({ caligrafix_book_id, start_date, end_date, group_by }: Object) {
    return {
      type: 'REQUEST_SCHOOL_GENERAL_CALIGRAFIX_BOOK_HISTORY',
      payload: {
        caligrafix_book_id,
        start_date,
        end_date,
        group_by
      }
    };
  }
  