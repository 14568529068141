export function getStudentComments({ student_id }) {
    return {
      type: 'REQUEST_STUDENT_COMMENTS',
      payload: {
        student_id
      }
    };
}

export function createStudentComment({ student_id, content }) {
    return {
      type: 'REQUEST_STUDENT_COMMENT_CREATE',
      payload: {
        student_id,
        content
      }
    };
}
export function deleteStudentComment({ student_id, comment_id }) {
    return {
      type: 'REQUEST_STUDENT_COMMENT_DELETE',
      payload: {
        student_id,
        comment_id
      }
    };
}
export function updateStudentComment({ student_id, comment_id, content }) {
    return {
      type: 'REQUEST_STUDENT_COMMENT_UPDATE',
      payload: {
        student_id,
        comment_id,
        content
      }
    };
}