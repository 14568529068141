/* @flow */

import { call, takeEvery, all } from 'redux-saga/effects';
import { workerRequest } from '../diplomatico/workers';
import type { Action } from '../diplomatico/types';

/**
 * Worker to handle the request of Achievement Cards
 */
 export const workerRequestAchievementCardsOptions = ({ intelligence_id, language }) => ({
   requestParams: {
     endpoint: `/intelligences/${intelligence_id}/achievement_cards?locale=${language}`,
     method: 'get',
     intelligence_id,
     language
   }
 });

export function* workerRequestAchievementCards(action: Action): any {
  let workerRequestGenerator = workerRequest(workerRequestAchievementCardsOptions(action.payload));

  yield call(workerRequestGenerator, action);
}

export function* watchAchievementCardsRequest(): any {
  yield all([
    takeEvery('REQUEST_ACHIEVEMENT_CARDS', workerRequestAchievementCards)
  ]);
}
