/* @flow */

import React from 'react';
import { Slider } from '../components/';
import { ExperiencesBundle } from '../models/';

const experiences = ExperiencesBundle.featured;

const StoreSliderFeaturedBundles = () => {
  return (
    <Slider className="slider-hero" settings={headerExperiencesSlider}>
      {
        experiences.map(({ image }, key) => {
          return (
            <div key={key}>
              <img className="slider-image" src={image} alt={`slider-hero-${key}`} />
            </div>
          );
        })
      }
    </Slider>
  );
};

const headerExperiencesSlider = [
  {
    breakpoint: 992,
    settings: {
      infinite: false,
      variableWidth: true,
      slidesToScroll: 1,
      slidesToShow: 1
    }
  },
  {
    breakpoint: 7680,
    settings: {
      infinite: false,
      variableWidth: true,
      slidesToScroll: 1,
      slidesToShow: 1
    }
  }
];

export default StoreSliderFeaturedBundles;
