import * as informationActions from './information';
import * as requestActions from './request';
import * as responseActions from './response';

const actions = {
  ...informationActions,
  ...requestActions,
  ...responseActions
};

export default actions;

