/* @flow */

import React from 'react';
import { DataDisplay } from '../components/';
import classNames from 'classnames';
import Translations from '../hocs/TranslationsHelper';
import { pure, compose } from 'recompose';
import _ from 'lodash';
import { getCaligrafixBookProgressById } from '../../selectors/caligrafixBookProgress';
import { getBookInformation } from '../caligrafix/bookInfo';

/**
 * Component that receives the data to display the stats
 * of the user to be shown within simple cards
 * @method CaligrafixBookProgressAnalisisContainer
 *
 * @param  {object}        translations - injected by our Translations HoC to give translated strings
 * @param  {array}         currentProgress - injected by Redux includes all caligrafix_book_progress from the store for the current child
 * @param  {array}         caligrafix_books - injected by Redux includes all caligrafix_books from the store
 &
 */
export const CaligrafixBookProgressAnalisisContainer = ({ user_books,translations, currentProgress, caligrafix_books, totalTime }: Props) => {
  const entries = getDataDisplayInformation({ user_books,translations, currentProgress, caligrafix_books });

  const displayLeadClass = classNames('display-lead');
  return (
    <div className="displays-column">
      {
        (totalTime!==undefined)?
        <DataDisplay  title={translations.t("total_time")} narrow>
          <div className={displayLeadClass}>
            <p>{formatCaligrafixBookDuration(totalTime)}</p>
          </div>
        </DataDisplay>:undefined
      } 
    <div className="displays-row">
      {
        entries.map((entry, key) => {
          const { caligrafix_book, type, value } = entry;
          const title = translations.t(type);

          return (
            <DataDisplay key={key} label={caligrafix_book} title={title}>
              <div className={displayLeadClass}>
                <p>{value}</p>
              </div>
            </DataDisplay>
          );
        })
      }
    </div>
      
      </div>
  );
};

// Converts duration in seconds to duration in hours
// If duration is less than 1 hour converts duration to minutes
export const formatCaligrafixBookDuration = (duration) => {
  if(duration < 60)
    return duration+'s';
    
  const secondsInHours = 3600;
  const parsedDuration = duration >= secondsInHours ? parseInt(duration / (60 * 60), 10) : parseInt(duration / 60, 10);

  return duration >= secondsInHours ? `${parsedDuration}h` : `${parsedDuration}min`;
};

export const getDataDisplayInformation = ({user_books, translations, currentProgress, caligrafix_books }: Object) => {
  const defaultValue = {
    duration: 0,
    progress: 0,
    slug: 'no_data'
  };

  const caligrafix_booksWithProgress =(   _.map(caligrafix_books, (caligrafix_book) => {

    if(caligrafix_book.active_in_schools || user_books.includes(caligrafix_book.slug) ){

      const currentCaligrafixBookProgress = getCaligrafixBookProgressById({ caligrafix_book_progress: currentProgress }, caligrafix_book.id);
      const progress = _.has(currentCaligrafixBookProgress, 'score') ? parseInt(currentCaligrafixBookProgress.score, 10) : 0;
      const duration = _.has(currentCaligrafixBookProgress, 'duration') ? currentCaligrafixBookProgress.duration : 0;
      const completedOptions = _.has(currentCaligrafixBookProgress, 'completed_options') ? currentCaligrafixBookProgress.completed_options : 0;

      return {
        slug: caligrafix_book.slug,
        duration,
        progress,
        completedOptions
      };
      
    }
  })).filter((e)=>e);

  let maxWorked = _.maxBy(caligrafix_booksWithProgress, (int) => int.duration);
  let minWorked = _.minBy(caligrafix_booksWithProgress, (int) => int.duration); 

  let totalOptions = 0;
  let totalTime = 0;
  caligrafix_booksWithProgress.forEach(({duration, completedOptions})=>{
    totalOptions+= completedOptions;
    totalTime+= duration;
  });

  // check if there is any record different from zero
  const withoutData = _.isEmpty(caligrafix_booksWithProgress.find((int) => (int.duration !== 0 )));//&& int.progress !== 0

  if (withoutData) {
    maxWorked = defaultValue;
    minWorked = defaultValue; 
  }

  return [
    { caligrafix_book: (maxWorked.slug!="no_data")?getBookInformation(maxWorked.slug).label:translations.t("no_data"), type: 'book_most_used', value: formatCaligrafixBookDuration(maxWorked.duration) },
    { caligrafix_book: (minWorked.slug!="no_data")?getBookInformation(minWorked.slug).label:translations.t("no_data"), type: 'book_less_used', value: formatCaligrafixBookDuration(minWorked.duration) },
    { caligrafix_book: translations.t("all_notebooks"), type: 'total_use', value: formatCaligrafixBookDuration(totalTime) },
    { caligrafix_book: translations.t("all_notebooks"), type: 'activities_completed', value: totalOptions }
  ];
};

export default compose(
  pure
)(Translations(CaligrafixBookProgressAnalisisContainer));
