/* @flow */

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { push } from 'react-router-redux';
import { SchoolDashboardNav } from '../components';
import { compose, withHandlers, pure } from 'recompose';
import _ from 'lodash';

type Props = {
  currentTab: string,
  onTabClick: () => mixed
};

/**
 * this component should get the current route from the store,
 * and pass it down to SchoolDashboardNav.
 * It also passes callbacks to handle route changes.
 *
 * @method SchoolDashboardNavContainer
 * @param  {string}    currentTab     - the currently selected tab
 * @param  {function}  onTabClick     - injected by `recompose` as a handler, to dispatch an action that will change the route to the selected tab
 *
 */
const SchoolDashboardNavContainer = ({ items, role,currentTab,hideSearch, license_type, onTabClick, keyPressHandler, onSearchClick, pdfTarget,pdfFilter, pdfProps }: Props) => {
  return (
    <SchoolDashboardNav
      items = {items}
      role={role}
      licenseType={license_type}
      currentTab={currentTab}
      hideSearch={hideSearch}
      onTabClick={onTabClick}
      pdfTarget={pdfTarget}
      pdfProps={pdfProps}
      pdfFilter={pdfFilter}
      keyPressHandler={keyPressHandler}
      onSearchClick={onSearchClick}
    />
  );
};

export const SchoolDashboardNavContainerHandlers = {
  onTabClick: ({ dispatch }: Props) => (tab: string) => {
    if(!tab)
      return;
    const route = `/school${tab}`;
    dispatch(push(route));
  },
  onSearchClick: ({ dispatch }) => (searchValue) => {
    if (searchValue) {
      dispatch(push(`/school/student/search/${searchValue}`));
    }
  },
  keyPressHandler: ({ dispatch }) => (event) => {
    const { key, target } = event;
    const { value } = target;

    if (key === 'Enter' && !_.isEmpty(value)) {
      dispatch(push(`/school/student/search/${value}`));
    }
  }
};

const SchoolDashboardNavContainerComposed = compose(
  withHandlers(SchoolDashboardNavContainerHandlers),
  pure
)(SchoolDashboardNavContainer);

const mapDispatchToProps = (dispatch) => ({ dispatch });

const SchoolDashboardNavContainerConnected = withRouter(connect(
  ({user})=>({
    license_type: user.license_type,
    role: user.role
  }),
  mapDispatchToProps
)(SchoolDashboardNavContainerComposed));

export default SchoolDashboardNavContainerConnected;
