/* @flow */

export function getLevels() {
  return {
    type: 'REQUEST_LEVELS'
  };
}

export function addLevel(level: Object) {
  return {
    type: 'ADD_LEVEL',
    payload: {
      level
    }
  };
}

export function updateLevel(level: Object) {
  return {
    type: 'UPDATE_LEVEL',
    payload: {
      level
    }
  };
}

export function deleteLevel(level: Object) {
  return {
    type: 'DELETE_LEVEL',
    payload: {
      level
    }
  };
}

export function editLevel(level: Object) {
  return {
    type: 'SHOW_LEVEL_EDIT_MODAL',
    payload: {
      level
    }
  };
}

export function createLevel() {
  return {
    type: 'SHOW_LEVEL_EDIT_MODAL'
  };
}

export function closeEditLevelModal() {
  return {
    type: 'HIDE_LEVEL_EDIT_MODAL'
  };
}

export function setModalLevel(level: Object) {
  return {
    type: 'SET_MODAL_LEVEL',
    payload: {
      level
    }
  };
}
