/* @flow */

import React from 'react';
import { connect } from 'react-redux';
import { compose, lifecycle, pure, withHandlers } from 'recompose';
import { startSubmit, arrayPush, arrayRemove } from 'redux-form';
import { SchoolCoursesForm } from '../components/';
import { FORM_NAME } from '../components/SchoolCoursesForm';
import { getLevelCourses, getAllCoursesButFromLevel } from '../../selectors/courses';
import type { Level, ReduxFunction } from '../components/types';
import getNewAndUpdated from '../../helpers/getNewAndUpdated';
import { coursesSearch } from '../../actions/courses';
import { getCourses } from '../../actions/courses';
import prepareForNullify from '../../helpers/prepareForNullify';
import _ from 'lodash';

type Props = {
  level: Level,
  dispatch: ReduxFunction,
  initialValues: Object
};

const SchoolCoursesFormContainer = (props: Props) => {
  return <SchoolCoursesForm {...props} />;
};

/**
 * This function will dispatch an action to search for courses, it's debounced
 * so it doesn't get called too often
 *
 * @method dispatchCoursesSearch
 * @param  {function}  dispatch     - dispatch from redux
 * @param  {string}    query        - to search courses
 */
export const dispatchCoursesSearch = _.debounce((dispatch, query) => {
  dispatch({ type: 'INIT_COURSES_SEARCH' });
  dispatch({ type: 'START_COURSES_SEARCH' });
  dispatch(coursesSearch({ query, page: 0, clean: true }));
}, 250);

export const SchoolCoursesFormContainerHandlers = {
  onSubmit: ({ dispatch, initialValues, level }: Props) => (values: Object) => {
    const removedCourses = values.removedCourses.map(prepareForNullify);
    const newLevelCourses = values.levelCourses;
    const oldLevelCourses = initialValues.levelCourses;
    const otherLevelCourses = values.levelCourses.filter(course => (course.id && course.level_id !== level.id));
    const { newValues, updatedValues } = getNewAndUpdated(oldLevelCourses, newLevelCourses);
    const courses = [...newValues, ...otherLevelCourses, ...updatedValues, ...removedCourses];
    const payload = {
      level: {
        courses_attributes: courses
      },
      removedCourses,
      otherLevelCourses,
      newCoursesCount: newValues.length + otherLevelCourses.length,
      level_id: level.id
    };

    dispatch(startSubmit(FORM_NAME));
    dispatch({ type: 'REQUEST_UPDATE_LEVEL_COURSES', payload });
  },
  changeCourseLevel: ({ dispatch, level }: Props) => (selectOption: Object) => {
    const course = selectOption.value;
    dispatch(arrayPush(FORM_NAME, 'levelCourses', course));
  },
  onRemoveCourse: ({ dispatch, level }: Props) => (course: Object, index: mixed) => {
    const persisted = course.id;
    dispatch(arrayRemove(FORM_NAME, 'levelCourses', index));

    if (persisted && course.level_id === level.id) {
      dispatch(arrayPush(FORM_NAME, 'removedCourses', course));
    }
  },
  onSearchBlur: ({ dispatch }: Props) => () => {
    dispatch({ type: 'INIT_COURSES_SEARCH' });
  },
  handleSearch: ({ dispatch }: Props) => (value: string) => {
    if (value) {
      dispatchCoursesSearch(dispatch, value);
    }

    // we return the value as react-select needs it to update the input with it
    return value;
  }
};

export const SchoolCoursesFormContainerLifecycle = {
  componentDidMount() {
    const { dispatch, level } = this.props;
    // ensure that the search is cleaned up when this component gets mounted
    dispatch({ type: 'INIT_COURSES_SEARCH' });
    // also ensure we have the courses for this level
    dispatch(getCourses(level));
  }
};

const SchoolCoursesFormContainerComposed = compose(
  withHandlers(SchoolCoursesFormContainerHandlers),
  lifecycle(SchoolCoursesFormContainerLifecycle),
  pure
)(SchoolCoursesFormContainer);

const mapStateToProps = (state, ownProps) => {
  const searchCourses = state.courses_search.courses;
  const otherCourses = getAllCoursesButFromLevel({ courses: searchCourses }, ownProps.level);
  const levelCourses = getLevelCourses(state, ownProps.level.id);

  return {
    otherCourses: otherCourses,
    searchLoading: state.courses_search.loading,
    initialValues: {
      levelCourses: _.values(levelCourses),
      removedCourses: []
    }
  };
};

const mapDispatchToProps = (dispatch) => ({ dispatch });

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SchoolCoursesFormContainerComposed);
