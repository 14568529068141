import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Collapser } from '../components/FillHeightDown';
import { useTranslations } from '../hocs/TranslationsHelper';

const LINE_LIMIT = 13;

const FilterItem = ({label,options,  values, onFilterToggle }) => { 
    const [open,setOpen] = useState(false);
 
    var hasValues = options.find(({value})=> values.includes(value));
    return <Dropdown isOpen={open} toggle={()=>setOpen(!open)} className="ab-dropdown"> 
      <DropdownToggle caret className={`ab-filterbar-item-toggle${(hasValues)?" selected":""}`}>
        {
            label
        }
      </DropdownToggle>
      <DropdownMenu className="ab-filterbar-item-menu">
        {
          options.map(({value,label})=>(
            
            <div className="ab-filterbar-item-item" onClick={()=>{  
              onFilterToggle(value,values.includes(value));
            }}>
              {
                (label.length > LINE_LIMIT)? (

                  <div  className="ab-filterbar-item-item-title">

                    <label> {label}</label>
                  </div>  
                ):(

                <label> {label}</label>
                )
              }
              <input
                  type="checkbox"
                  checked={values.includes(value)}  
              />      
  
            </div>
          ))
        } 
      </DropdownMenu>
    </Dropdown>;
} 
  
export const FilterBar= ({filters, values,onFilterChange})=>{
    const translations = useTranslations(); 
    return <Collapser className='ab-filterbar-container' maxWidth={700} title={translations.t("ab_filters")} barClassName="ab-filterbar-bar"> 
      <div className="ab-filterbar">
      {
        filters.map(({id, label, options})=>(
          <FilterItem label={label} options={options} values={values&&values[id] || []} onFilterToggle={(t, included)=>{
              var newValues  = [...(values&&values[id] || []) ];
              if(included)
                  newValues.splice(newValues.indexOf(t),1);
              else
                  newValues.push(t);

              onFilterChange(Object.assign({}, values || {}, {[id]:newValues }))
                }}/>
        ))
      }
      </div>
    </Collapser>
    
}