/**
 * @flow
 *
 * translations for Spanish make sure to keep all the keys
 * sorted alphabetically in order to easily search for words
 */
const es = {
  /*
    app translations
    these are sorted alphabetically
   */
  GENERAL: 'General',
  INTER: 'Interpersonal',
  INTRA: 'Intrapersonal',
  KIN: 'Kinestésica',
  LIN: 'Lingüística',
  MAT: 'Lógica',
  MUS: 'Musical',
  NAT: 'Naturalista',
  VIS: 'Visual',
  about: 'Inicio',
  account_confirmed_form_subtitle: 'Tu cuenta ha sido confirmada exitosamente.',
  account_confirmed_form_title: 'Cuenta confirmada',
  account_settings: 'Configuración de la cuenta',
  account_settings_profiles: 'Perfiles',
  activities: 'Actividades',
  add_existing_course: 'Añadir curso existente',
  add_existing_student: 'Añadir estudiante existente',
  add_new_course: 'Añadir nuevo curso',
  add_new_profile: 'Añadir nuevo perfil',
  add_new_student: 'Añadir nuevo estudiante',
  age: 'Edad',
  all: 'Todos',
  all_fields_are_required: 'Por favor llene todos los campos',
  against_course: 'Estudiante respecto al curso',
  back_to_courses: 'Volver a cursos',
  best_progress: 'Mayor progreso',
  best_worked: 'Más ejercitada',
  blog: 'Blog',
  buy: 'Tienda',
  buy_now: 'Comprar ahora',
  cancel: 'Cancelar',
  cant_create_session: 'Ya tienes una sesión activa, cierra sesión y vuelve a intentarlo.',
  challenge: 'Retos',
  challenges: 'Competencias',
  children_addition_form_subtitle: 'Define el perfil del niño',
  children_addition_form_title: 'Crear nueva cuenta en PleIQ',
  configuration: 'Configuración',
  confirm: 'Confirmar',
  congratulations_form_subtitle: 'Tu cuenta ha sido creada exitosamente.',
  congratulations_form_title: '¡Felicitaciones!',
  continue: 'Continuar',
  course: 'Curso',
  course_data: 'Datos del curso',
  course_name: 'Nombre del curso',
  course_students: 'Estudiantes del curso',
  courses: 'Cursos',
  courses_progress_in_time: 'Progreso de cursos en el tiempo',
  courses_section_placeholder: 'Puedes organizar a tus alumnos mediante la creación de niveles y cursos.',
  create_child_profile: 'Crear perfil de niño',
  create_course: 'Crear curso',
  create_level: 'Crear nivel',
  create_new_level: 'Crear nuevo nivel',
  create_student: 'Crear estudiante',
  current_profile: 'Perfil actual',
  current_progress: 'Progreso actual',
  delete: 'Borrar',
  delete_child_confirmation_message: 'Si confirmas esta acción, la información del niño será borrada de manera permanente.',
  delete_child_confirmation_title: '¿Estás seguro?',
  delete_course_confirmation_message: 'Si confirmas esta acción, la información de este curso será borrada de manera permanente.',
  delete_course_confirmation_title: '¿Estás seguro?',
  delete_level_confirmation_message: 'Si confirmas esta acción, la información de este nivel será borrada de manera permanente.',
  delete_level_confirmation_title: '¿Estás seguro?',
  delete_student_confirmation_message: 'Si confirmas esta acción, la información de este estudiante será borrada de manera permanente.',
  delete_student_confirmation_title: '¿Estás seguro?',
  edit: 'Editar',
  edit_course: 'Editar curso',
  edit_course_students: 'Editar estudiantes del curso',
  edit_level: 'Editar nivel',
  edit_level_courses: 'Editar cursos del nivel',
  edit_student: 'Editar estudiante',
  effort: 'Esfuerzo',
  effort_vs_performance: 'Esfuerzo VS Desempeño',
  email: 'Email',
  email_confirmation_form_resend_email_button: 'Reenviar email de confirmación',
  email_confirmation_form_subtitle: 'Confirma tu cuenta desde tu correo',
  email_confirmation_form_title: 'Crea nueva cuenta en PleIQ',
  email_placeholder: 'Email',
  email_placeholder_retype: 'Confirma tu email',
  email_retype_alert: 'Ambos correos deben ser iguales',
  english: 'Inglés',
  expired: 'Token expirado, por favor solicita uno nuevo',
  explore: 'Explora',
  faq: 'Preguntas',
  first_name: 'Nombre',
  forgot_password: '¿Olvidaste tu contraseña?',
  forum: 'Foro',
  how_find_my_code: '¿En dónde encuentro mi código?',
  how_find_my_code_instructions: 'Encuentra tu código de 9 caracteres dentro del producto PleIQ/Caligrafix que posees.',
  how_to_improve: '¿Cómo mejorar?',
  intelligence_screen_achievements_label: 'Progreso por competencia',
  intelligence_screen_current_progress_label: 'Progreso actual',
  intelligence_screen_timeline_label: 'Progreso en el tiempo',
  intelligences_progress_analysis: 'Análisis del progreso en inteligencias',
  interpersonal: 'Interpersonal',
  intrapersonal: 'Intrapersonal',
  kinesthetic: 'Kinestésica',
  language: 'Idioma Principal',
  language2: 'Idioma secundario',
  last_name: 'Apellido',
  less_progress: 'Menor progreso',
  less_worked: 'Menos ejercitada',
  level: 'Nivel',
  level_courses: 'Cursos del nivel',
  level_data: 'Datos del nivel',
  level_name: 'Nombre del nivel',
  levels_courses_and_students: 'Niveles, cursos y alumnos',
  linguistics: 'Lingüística',
  load_more: 'Más resultados',
  logic: 'Lógica',
  login: 'Iniciar sesión',
  logout: 'Cerrar sesión',
  music: 'Músical',
  name: 'Nombre',
  nature: 'Naturalista',
  new: 'Nuevo',
  new_record: 'Nuevo record',
  no_account_yet: '¿Aún no tienes cuenta? Regístrate ahora',
  no_activity_yet: 'Ninguna actividad aún',
  no_children_yet: 'Sin niños asociados',
  not_pleiq_yet: '¿Aun no tienes un producto PleIQ/Caligrafix?',
  oauth_form_subtitle: 'Regístrate con:',
  oauth_form_title: 'Crea nueva cuenta en PleIQ',
  oauth_form_with_email: 'Registrarme con mi email',
  parent_settings_details: 'Datos personales',
  parent_sign_up_form_subtitle: 'Introduce tus datos',
  parent_sign_up_form_title: 'Crea nueva cuenta en PleIQ',
  password: 'Contraseña',
  password_confirmation: 'Confirma tu contraseña',
  password_reset_successfully: 'Tu contraseña se cambió correctamente',
  password_reset_token: 'Token',
  password_succesfully_updated: 'Tu contraseña se actualizó exitosamente',
  passwords_should_match: 'Las contraseñas deben ser iguales',
  performance: 'Desempeño',
  pick_a_course: 'Buscar curso por nombre',
  pick_a_student: 'Buscar estudiante por nombre',
  play_and_comeback: 'Ve a jugar y regresa luego',
  pleiq_code: 'Código de PleIQ',
  pleiq_code_form_subtitle: 'Agrega el código de PleIQ',
  pleiq_code_form_title: 'Crea nueva cuenta en PleIQ',
  popular: 'Popular',
  profiles: 'Perfiles',
  progress: 'Progreso',
  recent_activity: 'Actividad reciente',
  reports: 'Reportes',
  reports_preferences_weekly: 'Reportes semanales del progreso de/los niño(s)',
  receive_marketing_emails: 'Recibir noticias sobre PleIQ',
  terms_and_conditions: 'Al presionar el botón Continuar, aceptas nuestros <a href="//pleiq.com/es/terms">términos y condiciones</a>.',
  required: 'Esta información es requerida',
  reset_password_by_email: 'Restablecer contraseña por correo',
  reset_password_email_sent: 'Recibirá un correo electrónico con instrucciones sobre cómo restablecer su contraseña en unos minutos.',
  save: 'Guardar',
  school_current_general_progress: 'Progreso actual de la escuela',
  school_current_progress_by_course: 'Progreso actual por curso',
  school_progress_by_intelligence_title: 'Competencias trabajadas por inteligencia',
  school_settings_details: 'Datos de la cuenta',
  schools: 'Escuelas',
  search: 'Buscar',
  search_student: 'Buscar estudiante',
  settings: 'Configuración',
  shop: 'Tienda',
  signin_form_subtitle: 'Introduce tus datos',
  signin_form_title: 'Inicia sesión en PleIQ',
  director_form_tab1_title_1: 'Datos de la Institución Educativa',
  director_form_tab1_title_2: 'Recursos Educativos',
  director_form_tab1_title_3: 'Datos Primer Administrador Institución Educativa',
  director_form_tab1_title_4: 'Institución Educativa creada exitosamente',
  director_form_tab1_title_5: 'Institución Educativa creada exitosamente',
  director_form_placeholder_name: 'Maria Jose',
  director_form_placeholder_email: 'ejemplo@pleiq.com',
  director_form_placeholder_email_confirm: 'ejemplo@pleiq.com',
  director_form_placeholder_served: 'Nombre del consultor Caligrafix + PleIQ',
  director_form_location: 'Locación',
  director_form_placeholder_location: 'Elige una Locación',
  director_form_language: 'Idioma principal',
  director_form_placeholder_language: 'Elige un idioma',
  director_form_pleiq: 'Cubos interactivos PleIQ',
  director_form_caligrafix: 'Cuadernos interactivos Caligrafix',
  director_form_yes: 'Si',
  director_form_no: 'No',
  title_administrador_institucion_educativa: 'Administrador de Institución Educativa',
  director_form_name: 'Nombre',
  director_form_email: 'Email',
  director_form_confirm: 'Confirma tu email',
  director_form_served: 'Atendido por',
  director_check_title1: 'Linea preescholar',
  director_check_cal1: 'Caligrafía 2do Básico Script',
  director_check_cal2: 'Caligrafía 1ro Básico 2do Semestre Script',
  director_check_cal3: 'Caligrafía 1ro Básico 1er Semestre Script',
  director_check_cal4: 'Viaje a la Lectura',
  director_check_cal5: 'Caligrafía 2do Básico',
  director_check_cal6: 'Caligrafía 1ro Básico 2do Semestre',
  director_categoria_1: 'Línea Preescholar',
  director_categoria_2: 'Línea Fonoaudiologica',
  director_categoria_3: 'Línea Caligrafía',
  director_categoria_4: 'Línea Caligrafia Script',
  director_categoria_5: 'Otros',
  director_categoria_1_1: 'Trazos y Letras 1',
  director_categoria_1_2: 'Trazos y Letras 2',
  director_categoria_1_3: 'Lógica y Números 1',
  director_categoria_1_4: 'Lógica y Números 2',
  director_categoria_1_5: 'Balancin',
  director_categoria_2_1: 'Jugando con los Sonidos 1',
  director_categoria_2_2: 'Jugando con los Sonidos 2',
  director_categoria_2_3: 'Jugando con los Sonidos 3',
  director_categoria_3_1: 'Caligrafía 1ro Básico 1er Semestre',
  director_categoria_3_2: 'Caligrafía 1ro Básico 2do Semestre',
  director_categoria_3_3: 'Caligrafía 2do Básico',
  director_categoria_4_1: 'Caligrafía 1ro Básico 1er Semestre Script',
  director_categoria_4_2: 'Caligrafía 1ro Básico 2do Semestre Script',
  director_categoria_4_3: 'Caligrafía 2do Básico Script',
  director_categoria_5_1: 'Viaje a la Lectura',


  download_title: 'App gratis para iOS & Android',
  download_subtitle: 'La aplicación móvil de PleIQ funciona en dispositivos con sistema operativo iOS y Android tanto para tablets como para teléfonos inteligentes.',
  download_content_title: 'Transforma la Educación: Descarga Nuestra App Educativa',
  download_content_subtitle: 'Descargar ahora',
  download_list_1: 'Estimula las inteligencias múltiples.',
  download_list_2: 'Experiencias de VR sin lentes',
  download_list_3: 'Más de 40 experiencias interactivas',
  
  spanish: 'Español',
  store: 'Tienda',
  student: 'Estudiante',
  student_data: 'Datos del estudiante',
  student_name: 'Nombre del estudiante',
  student_not_found_message: 'El estudiante que solicitaste no fue encontrado',
  student_progress_name: 'Progreso de %{name}',
  students: 'Estudiantes',
  students_search_not_found: 'No se encontró ningún estudiante.',
  students_search_title: 'Buscar estudiantes',
  tooltips_current_school_courses_average_progress: 'Comparativa del progreso general de los cursos por cada nivel.',
  tooltips_current_school_courses_progress: 'Comparativa del progreso de los cursos por cada inteligencia.',
  tooltips_current_school_progress: 'Progreso global actual acumulado por cada inteligencia.',
  tooltips_intelligence_screen_achievements_label: 'Progreso según las competencias específicas.',
  tooltips_intelligences_progress_analysis: 'Indicadores clave del progreso según el tiempo y el esfuerzo.',
  tooltips_student_vs_course_progress: 'Estudiante vs Curso',
  treasures: 'Tesoros',
  unassigned: 'No asignado',
  unique_account: 'Cuenta Única',
  unique_account_config_step_1: 'Establece los datos de la Cuenta Única',
  unique_account_config_step_2: 'Comparte los datos con los maestros asociados.',
  unique_account_config_step_3: 'Inicia sesión en los dispositivos con PleIQ usando los datos de la Cuenta Única.',
  unique_account_form_title: 'Datos de la Cuenta Única',
  unique_account_info_paragraph_1: 'Con la Cuenta Única puedes configurar todos los dispositivos que usen PleIQ en tu escuela sin tener que preocuparte por crear cuentas para cada uno.',
  unique_account_info_paragraph_2: 'Configura PleIQ en tu escuela fácilmente siguiendo estos 3 pasos:',
  unique_account_info_title: '¿Qué es la Cuenta Única?',
  unique_account_update_warning_paragraph: 'Si modificas los datos, todos los dispositivos que actualmente usen PleIQ con esta cuenta serán desincronizados y necesitarán de inicio de sesión manual.',
  unique_account_update_warning_title: '¡Atención!',
  update_password: 'Cambiar contraseña',
  user_password_new_form_subtitle: 'Recibirás un correo con instrucciones para cambiar tu contraseña',
  user_password_new_form_title: 'Instrucciones para cambiar tu contraseña',
  user_password_reset_form_title: 'Introduce tu nueva contraseña',
  visual: 'Visual',
  where_my_code: '¿En dónde encuentro mi código?',
  years_old: '%{smart_count} año |||| %{smart_count} años',
  your_email: 'Email',
  your_last_name: 'Apellido',
  your_name: 'Nombre',

  /*
    error messages translations
    these are sorted by the code at the end, instead of alphabetically
   */
  auth_required_message: 'Debes iniciar sesión para poder realizar esa acción.',
  connection_trouble_message: 'Hay un problema para conectarse con el servidor. <a href=".">Intenta de nuevo.</a>',
  server_unavailable_message: '¡Hola! Actualmente estamos haciendo mejoras para ofrecerte un mejor servicio ¡Regresa en unos instantes!',
  invalid_credentials: 'Email o contraseña inválidos',
  invalid: 'La información proporcionada no es válida',
  invalid_code: 'El código ingresado no existe, revise que este bien escrito',
  in_use: 'El código ingresado ya ha sido usado en una cuenta existente',
  not_expired: 'Ya tienes una licencia activa correspondiente a este código',

  no_internet_connection_message: `No tienes conexión a internet, verifica tu conexión e <a href=".">intenta de nuevo.</a>`,
  pleiq_code_format: 'El formato del código debe ser: XXX-XXX-XXX',
  should_be_at_least_2: 'Debes usar al menos 2 caracteres',
  should_be_at_least_8: 'Debes usar al menos 8 caracteres',
  taken: 'El valor suministrado ya ha sido tomado por otro usuario',
  unauthorized_account_role: 'La cuenta proporcionada no ha sido autorizada para el uso de esta aplicación',
  valid_email: 'Email inválido',
  reset_password_token_invalid: '<p>El link utilizado ha expirado por razones de seguridad tras un uso previo o por 24 horas sin usarlo desde su envío.</p><p>Establezca la contraseña de su cuenta solicitando un nuevo link <a href="/users/password/new">haga click aquí</a>.</p>',
  reset_password_param_missing: 'Parámetros inválidos, por favor contacta a soporte@pleiq.com si crees que esto es un error.',
  session_expired: 'Tú sesión ha expirado, por favor ingresa nuevamente.',
  license_expired: 'La licencia para esta cuenta ha expirado, contacte a su proveedor.',
  email_doesnt_match: 'Ambos correos deben ser iguales',//'La confirmación del email no coincide.', 
  server_problem: '¡Oh! Hubo un problema.',
  server_problem_retry: 'Intenta de nuevo.',
  
  /*
   * Translations for line chart
   */
  day: 'Diario',
  empty_key: '',
  month: 'Mensual',
  no_data: 'Sin Datos',
  week: 'Semanal',
  week_date: 'Semana',

  /*
   * Urls that change depending on language
   */
   buy_url: process.env.REACT_APP_BUY_URL_ES,
   activities_url: process.env.REACT_APP_ACTIVITIES_URL,

   /*
    * Screen titles translations
    */
   title_parent_dashboard: 'Progreso',
   title_parent_intelligence: 'Progreso en inteligencias',
   title_registration_account_confirmed: 'Cuenta confirmada',
   title_registration_activation: 'Activar cuenta',
   title_registration_children: 'Agregar niños',
   title_registration_confirm_email: 'Confirmar email',
   title_registration_congratulations: 'Felicitaciones',
   title_registration_reset_password: 'Reestablecer contraseña',
   title_registration_signin: 'Iniciar sesión',
   title_registration_signup: 'Registrarse',
   title_school_challenges: 'Progreso general en inteligencias',
   title_school_courses: 'Cursos',
   title_school_progress: 'Progreso general',
   title_school_search_students: 'Buscar estudiantes',
   title_school_unique_account: 'Cuenta única',
   title_student_intelligence: 'Progreso de estudiante en inteligencia',
   title_student_progress: 'Progreso de estudiante',
   title_user_settings: 'Preferencias de la cuenta',

   /**
    * Challenges
    */
  best_time: 'Nuevo Record',
  challenge_completed: 'Reto Completado',
  LIN_VIS_1_Cards: 'Asociar letras con imagenes',
  LIN_VIS_2_Space: 'Reconocer letras en el espacio',
  MAT_NAT_1_Animals: 'Números y conteo',
  MAT_VIS_1_Balloons: 'Identificar colores y formas',
  NAT_KIN_1_Recycle: 'Reciclar residuos',
  MUS_LIN_1_Xylophone: 'Reconocer notas musicales',
  KIN_LIN_1_Uppercase: 'Trazar letras mayúsculas',
  KIN_LIN_2_Lowercase: 'Trazar letras minúsculas',
  KIN_MAT_1_Numbers: 'Trazar números',
  KIN_MAT_2_Shapes: 'Trazar formas geométricas',
  VIS_NAT_1_Draw: 'Colorear formas',
  INTRA_LIN_1_Emotions: 'Identificar emociones propias',
  INTER_LIN_Magic_Words: 'Reconocer palabras mágicas',
  KIN_LIN_Cursive_Letters_Lowercase: 'Letras cursivas minúsculas', 
  KIN_LIN_Cursive_Letters_Uppercase:'Letras cursivas mayúsculas', 
  MAT_VIS_1_Sequence: 'Secuencia de números',	  
  MAT_VIS_1_Rings: 'Completación de cantidades',		  
  LIN_VIS_1_Monster_Letters:'Fonética de las letras',	 
  MAT_NAT_1_Cells: 'Suma de celulas',
  LIN_VIS_1_Alphabet_Spiral: 'Espiral de letras',
  MUS_VIS_1_Notes_Runner:'Runner notas musicales',
  
  LIN_MAT_Count_Syllables_Filling_1: "Identificando la cantidad de sílabas",
  LIN_MAT_Count_Syllables_Filling_2: "Identificando la cantidad de sílabas 2",
  LIN_VIS_Coloring_Syllables_1: "Colorear por cantidad de sílabas",
  LIN_VIS_Coloring_Syllables_2: "Colorear por cantidad de sílabas 2",
  LIN_VIS_Coloring_Syllables_3: "Colorear por cantidad de sílabas 3",
  LIN_MAT_Count_Syllables_1: "Contando las sílabas 1",
  LIN_MAT_Count_Syllables_2: "Contando las sílabas 2",
  LIN_MAT_Count_Syllables_3: "Contando las sílabas 3",
  LIN_VIS_Initial_Syllable_1: "Sílaba inicial",
  LIN_VIS_Initial_Syllable_2: "Sílaba inicial 2",
  LIN_VIS_Medial_Syllable_1: "Sílaba medial",
  LIN_VIS_Medial_Syllable_2: "Sílaba medial 2",
  LIN_VIS_Final_Syllable_1: "Sílaba final",
  LIN_VIS_Manipulation_Sylabic_1: "Manipulación silábica",
  LIN_VIS_Manipulation_Sylabic_2: "Manipulación silábica 2",
  LIN_KIN_Vocal_Initial_1: "Vocal inicial ",
  LIN_KIN_Vocal_Initial_2: "Vocal Inicial 2",
  LIN_VIS_Vowel_Initial_Relation_1: "Parejas de vocal inicial",
  LIN_VIS_Vowel_Initial_Relation_2: "Parejas de vocal inicial 2",
  LIN_VIS_Vowel_Final_Relation_1: "Reconocer la vocal final",
  LIN_VIS_Vowel_Sequence_1: "Secuencia de vocales",
  LIN_VIS_Vowel_Sequence_Cards_1: "Sonido final vocálico",
  LIN_VIS_Vowel_Sequence_Cards_2: "Secuencia vocálica ",
  LIN_VIS_Coloring_Consonantal_1: "Colorear por sonido inicial consonántico",
  LIN_MAT_Syllable_Kitchen_1: "Cocina de sílabas 1",
  LIN_VIS_Capture_Final_Syllable_1: "Sílaba final en el espacio",
  LIN_VIS_Objects_Syllables_1: "Suma de sílabas y objetos 1",
  LIN_VIS_Objects_Syllables_2: "Suma de sílabas y objetos 2",
  LIN_VIS_Objects_Syllables_3: "Suma de sílabas y objetos 3",
  LIN_VIS_Coloring_Final_Syllable_1: "Colorear por sonido final vocálico",
  LIN_VIS_Form_New_Words_1: "Formar nuevas palabras 1",
  LIN_VIS_Memorice_Initial_Syllable_1: "Memorice la sílaba Inicial",
  LIN_VIS_Vocal_Medial_Final_Filling_1: "Sonido medial y final vocálico",
  LIN_VIS_Sound_Consonantal_Cards_1: "Sonido inicial consonántico",
  LIN_VIS_Initial_Consonant_Timer_1: "Sonido inicial consonántico en el espacio",
  LIN_VIS_Information_Integration_Cards_1: "Integración de la información",
  LIN_VIS_Syllable_Medial_Relation_1: "Parejas de sílaba medial",
  LIN_VIS_Grid_Medial_Consonantal_1: "Sonido medial consonántico P y M",
  LIN_VIS_Grid_Final_Consonantal_1: "Sonido final consonántico N, L y S",
  LIN_VIS_Grid_Initial_Consonantal_1: "Sonido inicial consonántico F, G, B, R y V",
  LIN_VIS_Capture_Final_Syllable_2: "Vocal Inicial en el espacio",
  LIN_VIS_Final_Consonant_Sound_1: "Sonido final consonántico R",
  LIN_VIS_Medial_Consonant_Exp_1: "Sonido Medial Consonántico M",
  LIN_VIS_Manipulation_Integration_Syllabic_1: "Manipulación silábica 2",
  LIN_VIS_Integration_Syllables_Space_1: "Integración de sílabas en el espacio",
  LIN_VIS_Same_Final_Syllable_1: "GUS Misma sílaba final",
  LIN_VIS_Vowel_Sequence_Cards_3: "Secuencia vocálica 2",
  LIN_VIS_Consonant_Sequence_Cards_1: "Sonido inicial consonántico 1",
  LIN_VIS_Consonant_Sequence_Cards_2: "Consonante medial",
  LIN_VIS_Phonemic_Synthesis_1: "Síntesis fonémica 1",
  LIN_VIS_Phonemic_Synthesis_Cards_1: "Síntesis fonémica 2",
  LIN_VIS_Phonemic_Analysis_1: "Análisis fonémico",
  LIN_VIS_Phonemic_Integration_1: "Integración fonémica 1",
  LIN_VIS_Phonemic_Integration_2: "Integración fonémica 2",
  LIN_VIS_Build_Words_Syllables_1: "Integración para formar palabras",
  VIS_KIN_1_Master_Pieces: "Colorear Obras Maestras",
  NAT_VIS_1_Ecosystem: "Ecosistemas de la Tierra",
  INTRA_VIS_1_Creation_Emotions: "Creación de Emociones",
  INTER_LIN_1_Inclusion_Values: "Valores de inclusión",
  LIN_MAT_Count_Syllables_Filling_1: "Identificando la cantidad de sílabas",
  LIN_KIN_Vocal_Initial_1: "Vocal inicial",
  LIN_VIS_Vowel_Sequence_Cards_2: "Secuencia vocálica",
  LIN_VIS_Memorice_Initial_Syllable_1: "Memorice la sílaba Inicial" ,
  MAT_VIS_1_Sum_Sets:"Suma de conjuntos 0 al 5",
  MAT_VIS_2_Sum_Sets: "Suma de conjuntos 6 al 10",
  MAT_VIS_3_Sum_Sets: "Suma de conjuntos 11 al 20	",
  MAT_VIS_1_Subtraction_Sets:"Resta de conjuntos 0 al 5",
  MAT_VIS_2_Subtraction_Sets:"Resta de conjuntos 6 al 10",
  MAT_VIS_3_Subtraction_Sets:"Resta de conjuntos 11 al 20",
  MAT_VIS_Comparing_Weights_1:"Comparación de pesos",
  KIN_VIS_Pattern_Trace_1:"Trazos fundamentales mixtos",
  INTER_KIN_Lead_Flock_1:"Lidera la bandada",
INTRA_VIS_Emotion_Machine_1:"Clasificador de emociones",
VIS_KIN_Identify_Patterns_1:"Reconocimiento de patrones",
NAT_KIN_Recycle_Ocean_1:"Rescate del océano",
MUS_VIS_Musical_Instruments_1:"Instrumentos musicales",
NAT_VIS_Sources_Energy_1:"Fuentes de Energía",


KIN_VIS_Modeling_Clay_1:"Crea objetos en plasticina",
VIS_KIN_Identify_Volumes_1:"Identifica volúmenes",
VIS_MAT_Color_Mixer_1:"Mezcla colores primarios",
VIS_MAT_Color_Mixer_2:"Mezcla colores secundarios",
VIS_NAT_3D_Coloring_1:"Colorea en 3D",
VIS_KIN_Master_Pieces_Artel_1:"Colorea en 2D",
VIS_KIN_Chromatic_Circle_1:"Ordena los colores", 
VIS_NAT_Stickers_Histories_1:"Crea tu historia",
VIS_MAT_Step_Drawing_1:"Dibuja paso a paso", 
"KIN_LIN_Cursive_Letters_Pair_Vocals": "Trazo de vocales en cursiva mayúscula y minúscula",
"LIN_VIS_Recognize_Words_1": "Palabras que comienzan con vocales",
"KIN_LIN_Cursive_Letters_Pair_Consonants": "Trazo de letras cursivas mayúscula y minúscula 1",
"LIN_VIS_Select_Image_Article_1": "Relación del artículo con palabra según género",
"LIN_VIS_Identify_Syllable_Word_1": "Identificar sílaba inicial de palabras que comienzan con M",
"LIN_VIS_Recognize_Words_3": "Relacionar sonido de la sílaba con la imagen",
"LIN_VIS_Select_Image_Article_2": "Relacionar artículos definidos con una palabra según género",
"LIN_KIN_Discover_Words_Start_Letter_2": "Leer palabras que llevan letra D",
"LIN_VIS_Recognize_Words_2": "Leer palabras que contienen ca, co, cu",
"LIN_KIN_Discover_Words_Start_Letter_1": "Identificar palabras que llevan letra T",
"LIN_VIS_Select_Words_Rr_1": "Leer palabras con r y rr reconociendo su fonema",
"LIN_VIS_Form_Words_Contain_1": "Formar palabras que llevan H a partir de sílabas",
"LIN_VIS_Order_Words_Sentence_1": "Ordenar palabras para formar frases",
"LIN_VIS_Select_Words_J_1": "Leer palabras que contienen J",
"LIN_VIS_Recognize_Words_4": "Identificar sonido inicial silábico ga, go, gu",
"LIN_VIS_Recognize_Words_5": "Identificar sonido final silábico ga, go, gu",
"LIN_VIS_Recognize_Words_6": "Reconocer sonidos silábicos ga, go, gu, gue, gui, ge, gi",
"LIN_VIS_Form_Words_Contain_2": "Formar palabras que llevan letra b",
"LIN_VIS_Identify_Article_1": "Relacionar artículo con palabra según género",
"LIN_VIS_Read_Words_F_1": "Leer palabras que llevan F y clasificarla según número de sílabas",
"LIN_VIS_Recognize_Strong_Soft_R_1": "Reconocer palabras que llevan r fuerte y r suave",
"Kin_Lin_Cursive_Letters_Pair_Consonants_2": "Trazo de letras cursivas mayúscula y minúscula 2",
"Lin_Mat_Count_Syllables_Filling_3": "Identificar cantidad de sílabas",
"Lin_Vis_Underline_Word_1": "Identificar palabras que llevan ll",
"Lin_Kin_Read_Relation_1": "Leer y relacionar palabras singular plural",
"Lin_Vis_Read_Words_1": "Leer palabras con Ñ y su relación con imagen",
"Lin_Kin_Classify_Words_Syllables_1": "Clasificar palabras con V según cantidad de sílaba",
"Lin_Kin_Read_Relation_Words_1": "Relacionar palabra con dibujo que la representa",
"Lin_Vis_2_Space_Words_K": "Buscar palabras que comienzan con K",
"Lin_Vis_Form_Words_Contain_4": "Formar palabras que llevan X a partir de sílabas",
"Lin_Kin_Classify_Words_Syllables_2": "Clasificar palabras con Y según cantidad de sílabas",
"Lin_Kin_Discover_Words_Start_Letter_3": "Leer palabras grupo consonántico pr",
"Lin_Vis_Form_Words_Contain_3": "Formar palabras que llevan pl con sílabas",
"Lin_Kin_Words_Relation_Br_1": "Palabras grupo consonántico br",
"Lin_Vis_Completed_Words_Bl_1": "Completar palabras del grupo consonántico bl",
"Lin_Vis_Order_Words_Sentence_2": "Ordenar palabras para formar frases",
"Lin_Kin_Discover_Words_Start_Letter_4": "Leer palabras del grupo consonántico fr",
"Lin_Vis_Consonantal_Groups_Read_Gr": "Leer palabras grupo consonántico gr",
"Lin_Kin_Words_Rhyme_Relation_1": "Reconocer palabras que riman",
"Lin_Vis_Consonantal_Groups_Read_Gr": "Leer palabras grupo consonántico gr",
"Lin_Vis_Underline_Word_1": "Identificar palabras que llevan ll",
"Lin_Vis_Read_Description_Word_1": "Leer y relacionar palabra descripción correcta",
"LIN_KIN_Discover_Words_Start_Letter_5": "Leer palabras que se escriben con grupos consonánticos pl pr",
"LIN_VIS_Select_Element_Group_1": "Distinguir palabras de los grupos consonánticos bl br",
"LIN_VIS_Select_Element_Group_2": "Distinguir palabras que se escriben con cl cr",
"LIN_KIN_Listening_Relation_Words_1": "Distinguir palabras grupos consonánticos gl gr",
"LIN_VIS_2_Space_Words_Dr": "Reconocer palabras compuestas grupo consonántico dr",
"LIN_KIN_Discover_Words_Start_Letter_6": "Leer palabras que se escriben con ce ci",
"LIN_VIS_Form_Words_Contain": "Formar palabras con determinado número de sílabas",
"LIN_VIS_Complete_Word_1": "Distinguir palabras con güe, güi, gue, gui",
"LIN_VIS_Complete_Word_2": "Distinguir palabras con la sílaba ge, gi",
"LIN_VIS_Found_Words_Qui_1": "Distinguir palabras con que o qui",
"LIN_VIS_Consonantal_Groups_Find_Fr_Fl": "Distinguir palabras grupos consonánticos fr fl",

  LIN_KIN_Read_Relation_Words_2:      "Relacionar palabra con dibujo que la representa",
  LIN_VIS_Body_Parts_1:               "Partes del Cuerpo",
  LIN_MAT_Count_Syllables_Filling_4 : "Identificar cantidad de sílabas",
  report_for_student: 'Reporte para ',
  report_for_course: 'Reporte para el curso ',
  intelligence: 'Inteligencia',
  
  LIN_KIN_Machine_Words_1                      : "Distinguir palabras grupos consonánticos tl y tr",
  LIN_VIS_Identify_Article_2                   : "Completar frases con artículo definido que sea coherente",
  LIN_VIS_Identify_Article_3                   : "Seleccionar el artículo apropiado",
  LIN_VIS_Recognize_Words_Number_Syllables_1   : "Reconocer palabras según el número de sílabas",
  LIN_VIS_Space_Letters_Hand_Written_1         : "Reconocer letras manuscritas del abecedario",
  LIN_VIS_Recognize_Common_Proper_Nouns_1      : "Reconocer sustantivos comunes y propios",
  LIN_VIS_identify_Personal_Pronouns_1         : "Leer textos breves que usan pronombres personales",
  LIN_VIS_Identify_Words_Start_Capital_Letter_1: "Reconocer palabras que se escriben en mayúscula",

  LIN_KIN_Discover_Words_Start_Letter_7: "Identificar palabras que llevan letra T",
  LIN_KIN_Kitchen_Words_Diphthongs_1:    "Leer palabras con diptongo",
  LIN_VIS_Read_Words_Fl_1              : "Leer palabras grupo consonántico fl",   
  LIN_VIS_Identify_Antonyms_1          : "Relacionar dos palabras de significado opuesto",       
  LIN_VIS_Interrog_Phrases_1           : "Subrayar frases interrogativas y exclamativas",       
  LIN_VIS_Recognize_Strong_Soft_R_2    : "Distinguir palabras con r-rr",             
  LIN_VIS_Identify_Verb_Tenses_1       : "Utilizar correctamente presente, pasado y futuro",           
  LIN_VIS_Identify_Words_Homonyms_1    : "Identificar significado de una palabra homónima",             
  LIN_VIS_Identify_Diminutive_Words_1  : "Identificar diminutivo de palabras dadas",               
  LIN_VIS_Identify_Augmentative_Words_1: "Identificar aumentativos",             
  
  LIN_VIS_Words_Rhyme_1:"Reconocer palabras que riman",
  LIN_VIS_Recognize_Verbs_1:"Asociar imagen con acciones.",
  LIN_VIS_Identify_Words_Homophones_1:"Distinguir palabras homófonas",
  LIN_VIS_Understand_Story_1:"Comprender un cuento",
  LIN_VIS_Complete_Word_3:"Reconocer adjetivos calificativos",
  LIN_VIS_Use_Gender_Words_1:"Usar correctamente palabras de género",
  LIN_VIS_Identifies_Synonyms_1:"Reconocer sinónimos",
  LIN_VIS_Words_Singular_Plural_1:"Distinguir cuando se usan palabras en singular y plural",
  LIN_VIS_Complete_Word_CR_1:"Completar oraciones con la palabra faltante",

  LIN_VIS_Read_Words_CL_1:"Completar palabras grupo consonántico cl.",
  LIN_VIS_Read_Words_GL_1:"Leer palabras que llevan el grupo consonántico gl",
  LIN_VIS_Read_Words_DR_1:"Leer y comprender oraciones con palabras grupo consonántico dr",
  LIN_VIS_Comprises_Legends_1:"Comprender leyendas",
  LIN_VIS_Understands_Fable_1:"Comprender fábulas",
  LIN_VIS_Read_Comprehensive_News:"Leer comprensivamente una noticia",
  LIN_VIS_Read_Comprehensive_Recipes:"Leer comprensivamente una receta",

  license_count_exceded: "Ha alcanzado el máximo de licencias en uso, contacte su proveedor.",

  intelligences: "Inteligencias",
  /* Caligrafix */
  book_less_used: "Cuaderno menos usado",
  book_most_used: "Cuaderno más usado",
  total_use: "Total de horas ejercitadas",
  activities_completed : "Actividades completadas",
  caligrafix_current_general_progress: "Progreso por cuadernos interactivos",
  caligrafix_progress_analysis: "Análisis de uso",
  caligrafix: "Caligrafix",
  all_notebooks: "En todos los cuadernos",

  tooltips_current_caligrafix_courses_progress: 'Comparativa del progreso de los cursos por cada cuaderno interactivo.',
  tooltips_current_caligrafix_progress: 'Progreso global actual acumulado por cada cuaderno interactivo.',
  
  intelligences_progress: "Progreso de las inteligencias",
  total_time: "Tiempo total de aprendizaje con PleIQ app", 

   
  no_data: 'No hay datos',


  
  comments_for: 'Registro Anecdótico y Pedagógico para', 
  student_comments: 'Registro Anecdótico y Pedagógico',
  student_comments_short: 'Registro',
  add_comment: "Editar",
  empty_comment: "Escribe tu comentario aquí",

  
  unassigned_courses: "Cursos no asignados",
  ab_planification_title  : "Planificaciones",
  ab_orientation_title    : "Orientaciones",
  ab_orientation_tooltip  : "Secuencia sugerida pedagógica para las familias",
  ab_planification_tooltip: "Secuencia sugerida pedagógica para las familias",
  ab_search               : "Ingrese su busqueda",
  ab_no_results           : "No hay resultados",
  ab_global_score         : "Valoración Global",
  ab_stars_tooltip        : "Calificación",
  ab_download_tooltip     : "Descarga en .PDF",
  ab_filters: "Filtrar",
  ab_clear_filter: "Borrar filtro",
  ab_loading: "Cargando...",

  video_tutorials_title: "Videotutoriales",
  video_tutorials_duration: "Duración",
  video_tutorials_general_title:"General",
  video_tutorials_books_title: "Cuadernos interactivos",
  video_tutorials_cubes_title: "Cubos interactivos", 
  video_tutorials_pleiq_school_title: "Plataforma PleIQ School",
  video_tutorials_pleiq_app_title: "App de PleIQ",

  level_books:"Cuadernos Interactivos",
  pick_a_book:"Buscar cuaderno por nombre",
  edit_level_books:"Editar cuadernos interactivos",
  add_book:"Añadir cuaderno interactivo",

  unassigned_students:"Perfiles sin asignar" ,
  title_registration_deletes: "Eliminación de cuenta",
  delete_account_form_title: "Eliminación de cuenta",
  delete_account_form_subtitle: "Puedes eliminar tu cuenta si así lo deseas. Si cambias de parecer luego, no podrás recuperarla y los productos activados anteriormente no podrán ser reutilizados, tus datos serán eliminados de nuestra plataforma.",
  reason: "Motivo",
  account_delete_confirmation: "He leído y entiendo las implicaciones de borrar mi cuenta.",
  delete_account: "Eliminar cuenta", 
  delete_account_deleted: "Su cuenta ha sido eliminada exitosamente.",

  user_delete_not_parent: "Las cuentas de las escuelas no pueden ser eliminadas por esta vía, deben ser solicitadas por la institución.",

  role_teacher: "Educador",
  role_coordinator: "Administrador",
  role_representative: "Representante",
  role_observer: "Observador",
  new_user_teacher: "Crear nuevo educador",
  new_user_coordinator: "Crear nuevo administrador",
  new_user_representative: "Crear nuevo representante",
  new_user_observer: "Crear nuevo observador",
  user_children: "Estudiantes",
  we_will_send_email_new_user: "Enviaremos un correo de invitación al nuevo usuario.",
  teacher_data: "Datos del educador",
  coordinator_data: "Datos del administrador",
  representative_data: "Datos del representante",
  observer_data: "Datos del observador",
  create: "Crear",
  email_placeholder_retype_user:"Confirmación del Email",
  edit_teacher: "Editar educador",
  edit_coordinator: "Editar administrador",
  edit_representative: "Editar representante",
  edit_observer: "Editar observador",

  add_courses: "Agregar Curso",
  add_children: "Agregar Estudiante",

  student_representants: "Representantes",
  user_name: "Nombre",
  add_representative: "Agregar Representante",
  tooltips_profiles: "Administra los diversos perfiles en la plataforma.",
  remove_representative_confirmation_title: "¿Estás seguro?",
  remove_representative_confirmation_message: "Si confirmas está acción, se removerá el representante de este perfil.",


  my_account: "Mi cuenta",
  my_account_description: "Revisa la configuración de tu cuenta en la plataforma.",
  my_books: "Cuadernos interactivos",
  my_books_description: "Consulta el progreso de la escuela en los recursos Caligrafix.",
  intelligences_description: "Consulta el progreso general de la escuela en las inteligencias múltiples.",
  my_courses: "Mis cursos",
  my_courses_description: "Revisa los grupos de estudiantes asignados a tí.",
  progress_reports: "Informes de progreso",
  progress_reports_description: "Prepara tus informes de progresos personalizados.",
  curricular_coverage: "Cobertura curricular",
  curricular_coverage_description: "Revisa los objetivos de aprendizajes cubiertos de los estudiantes",
  activity_assignment: "Asignación de actividades",
  activity_assignment_description: "Asigna a tus estudiantes actividades educativas a realizar.",
  planifications_and_orientations: "Planificaciones y orientaciones",
  planifications_and_orientations_description: "Contenido pedagógico y ubicación de los objetivos pedagógicos asociados al contenido.",
  resources: "Recursos",
  resources_description:"Descubre los recursos educativos que puedes descargar.",
  video_tutorials: "Videotutoriales",
  video_tutorials_description: "Nuestra colección de videos sobre todo lo relacionado al ecosistema PleIQ.",
  pleiq_learning: "Academia",
  pleiq_learning_description: "Accede a los cursos de preparación sobre PleIQ School y certificate en el manejo de la plataforma.",
  educators_4_0: "Educadores 4.0",
  educators_4_0_description: "Comparte experiencias con otros educadores en nuestra comunidad de Facebook",
  profiles_description: "Administra los perfiles de los usuarios que utilizan la plataforma.",

  download: "Descargar",
  home: "Inicio",
  
  school_home_hello: "¡Hola $user!",
  school_home_subtitle: "¿Qué haremos hoy?", 
  school: "Escuela",
  learning: "Aprendizaje",
  administration: "Administración",
  comming_soon: "Próximamente",
  premium_benefits: "Conoce las ventajas de ser Premium",
  premium: "Premium",

  resource_cube_lin: "Cubo Lingüístico",
  resource_cube_lin_desc: "PDF del primer cubo del set de 8 cubos para imprimir.",
  resource_cube_lin_url: "https://pleiq.com/trial/pleiq_cube_templates_spanish.pdf",
  resource_pleiq_cube: "PleIQ Digital",
  resource_pleiq_cube_desc: "PDF del set de los 8 cubos para imprimir.",
  resource_pleiq_cube_url: "https://pleiq.com/digital/pleiq_digital_templates_spanish.pdf",
  resource_didactic_guide: "Guía didáctica",
  resource_guide_latam_desc: "PDF de la guía didáctica versión LATAM.",
  resource_guide_latam_url: "https://pleiqpro.s3.amazonaws.com/Assets/PDF/didactic_guide_latam.pdf",
  resource_guide_chile_desc: "PDF de la guía didáctica versión Chile.",
  resource_guide_chile_url: "https://pleiqpro.s3.amazonaws.com/Assets/PDF/didactic_guide_chile.pdf",
  resource_cx_books: "Cuadernillos interactivos Caligrafix + PleIQ",
  resource_cx_cal_desc: "PDF Cuadernillo Interactivo Línea Caligrafía (6 a 8 años).",
  resource_cx_cal_url: "https://pleiq.com/downloads/caligrafix_pleiq_interactive_book_cal.pdf",
  resource_cx_jcs_desc: "PDF Cuadernillo Interactivo Línea Fonoaudiológica (4 a 6 años).",
  resource_cx_jcs_url: "https://pleiq.com/downloads/caligrafix_pleiq_interactive_book_jcs.pdf",
  resource_cx_ibook_desc: "PDF Cuadernillo Interactivo Línea Preescolar (3 a 5 años).",
  resource_cx_ibook_url: "https://pleiq.com/downloads/caligrafix_pleiq_interactive_book.pdf",
  
  tooltips_school_resources: "Descubre los recursos educativos que puedes descargar.",

  progress_reports_for: "Informes de progreso de %{name}",
  select_progress_report_type:"Elige el tipo de informe de progreso que deseas consultar",
  select_progress_report_student:"Selecciona el estudiante a consultar los informes de progreso",
  select_progress_report_course:"",
  created_by:"Creado por",
  view:"Ver",
  date:"Fecha",
  create_new_progress_report:"Crear nuevo informe",
  by_course:"Por curso", 
  by_student:"Por estudiante", 
  qualitative_report:"Informe cualitativo",
  domain:"Dominio",
  teacher: "Docente",
  progress_report_for: "Informe de progreso de %{name}",
  progress_report_explanation: "Los dominios de logro que se agregan en el informe de manera automatizada, están definidos por los marcadores y actividades de la App PleIQ ubicados en los recursos educativos interactivos que los estudiantes han trabajado, donde:",
  progress_report_legend: "No Observado = N Iniciado = I En Proceso = P Adquirido = A",
  progress_reports_premium_notice: "En modo Premium podrás generar informes ilimitados y conservarlos en un historico para consultarlos cuando quieras, así como compararlos para ver la evolución de su progreso.",
  progress_report_reminder: "Recuerda que puedes seleccionar la casilla del dominio para modificar o agregar su valor.",
  compare_progress_reports: "Comparar informes",
  progress_report_saved: "Informe de progreso",
  progress_report_saved_message:"El informe ha sido guardado exitosamente",
  _ok: "Entendido",
  report: "Informe",
  progress_report_type_select: "Seleccione el tipo de informe a crear",
  school_data: "Datos de la escuela",
  school_logo:"Logo",
  school_logo_msg:"Solo se admite imagenes en formato PNG",
  upload_image: "Subir Imagen",
  school_name: "Nombre",

  image_is_not_png: "El formato debe ser PNG",
  image_is_too_big: "La imagen es muy grande",
  KIN_LIN_Script_Letters_Pair_Vocals: 	"Trazo de vocales en script mayúscula y minúscula",
  KIN_LIN_Script_Letters_Pair_Consonants:	"Trazo de letras script mayúscula y minúscula 1",
  KIN_LIN_Script_Letters_Pair_Consonants_2:	"Trazo de letras script mayúscula y minúscula 2",


  delete_user_confirmation_title: "Confirmación de eliminación",
  delete_user_confirmation_message: "¿Está seguro de eliminar este perfil?",
  next: "Siguiente",
  tooltips_school_progress_reports: "Genera informes de progresos basados en los objetivos de aprendizaje.",

  "school_pro_promo_title":"Únete a las Escuelas Premium",
  "school_pro_promo_subtitle":"Conviértete en premium y aprovecha al máximo todas las funciones de la plataforma",
  "school_pro_promo_free":"Versión Free",
  "school_pro_promo_premium":"Versión Premium",
  "school_pro_promo_interactive_resources":"Uso de los recursos interactivos con la aplicación PleIQ",
  "school_pro_promo_progress_analysis":"Seguimiento y análisis de progreso",
  "school_pro_promo_general_progress":"Reporte del progreso global",
  "school_pro_promo_planifications":"Planificaciones y orientaciones",
  "school_pro_promo_progress_reports":"Sección informes de progreso",
  "school_pro_promo_progress_reports_download":"Guardado, descarga y edición de informes de progreso ilimitados",
  "school_pro_promo_progress_reports_cmp":"Comparación de informes de progreso",
  "school_pro_promo_task_assignment":"Asignación de actividades (próximamente)",
  "school_pro_promo_curricular_coverage":"Informes de cobertura curricular (próximamente)",
  "school_pro_promo_unlimited_profiles":"Perfiles de usuarios ilimitados",
  "school_pro_promo_unlimited_registry":"Históricos de datos ilimitados",
  "school_pro_promo_disclamer":"*Funcionalidades disponibles solo durante el periodo de prueba de 90 días o por el uso de las cantidades disponibles para probar en versión gratuita.",
  "school_pro_promo_footer":"Escríbenos a <b>plataforma@caligrafix.com</b> y solicita más información obtener a la versión Premium.",
  "school_pro_promo_planification_generator": "Mi Asistente IA Educativa",


  school_home_cant_use_pro:      "Se ha acabado el tiempo de prueba de esta funcionalidad premium. Contacte a su consultor para adquirir el modo premium o contáctenos a hola@pleiq.com.",
  school_home_cant_use_disabled: "Esta funcionalidad está planificada para ser habilitada próximamente.",

  LIN_VIS_Associate_Cognates_1    : "Recordar y asociar cognados de los medios de transporte",
  LIN_KIN_Relation_3D_Figures_1   : "Recordar y asociar cognados de figuras de tres dimensiones",
  LIN_KIN_Read_Understand_Select_1: "Leer y comprender",
  LIN_VIS_Apply_Cognates_1        : "Aplicar cognados en estudio",
  LIN_VIS_Face_Parts_1            : "Distinguir y recordar vocabulario de las partes de la cara",
  LIN_VIS_Recognize_Words_Rhyme_1 : "Reconocer palabras que riman",
  LIN_VIS_Fluently_Read_Words_1   : "Leer con fluidez palabras de uso frecuente",
  LIN_VIS_Vocabulary_Professions_1: "Distinguir y recordar vocabulario de las profesiones y oficios",
  LIN_VIS_2D_Cognates_1           : "Recordar y asociar cognados de figuras de dos dimensiones",
  LIN_VIS_Recognize_Synonym_1     : "Desarrollar conciencia semántica",
  LIN_VIS_Associate_Articles_1    : "Asociar y escribir artículos determinados",
  LIN_VIS_Order_Words_1           : "Leer, comprender y escribir oraciones",
  LIN_VIS_Riddle_1                : "Comprender textos: adivinanzas",
  LIN_VIS_Read_TongueTwisters_1   : "Leer y practicar trabalenguas",

  planification_generator: "Mi Asistente IA Educativa", 
  planification_generator_description: "Herramientas Educativas asistidas por Inteligencia Artificial.",
  planification_generator_disclamer: "Esta es una herramienta experimental que utiliza Inteligencia Artificial. Al usarla, reconoces que es su responsabilidad como docente validar la pertinencia pedagógica del contenido generado.",

  LIN_VIS_Stories_FSC_2               : "Preguntas Lectura vocalizada #2",
  LIN_VIS_Stories_FSC_3               : "Preguntas Lectura vocalizada #3",
  KIN_VIS_Pattern_Trace_1             : "Trazos fundamentales mixtos",
  INTRA_VIS_Emotion_Machine_1         : "Clasificador de emociones",
  LIN_VIS_Dobble_1                    : "Dobble",
  LIN_VIS_Coloring_Syllables_4        : "Colorear por cantidad de sílabas",
  LIN_VIS_Position_Objects_1          : "Arriba y Abajo",
  INTER_VIS_Values_Positive_Negative_1: "Valores positivos y negativos", 
  LIN_VIS_Information_Integration_Cards_FSC_MX : "Integración de la información",
  LIN_KIN_Discover_Words_Start_Letter_8        : "Sopa de Letras",
  LIN_VIS_Stories_FSC_1                        : "Preguntas Lectura vocalizada #1",
  VIS_KIN_1_Free_Painting                      : "Pintura Libre",
  LIN_VIS_2_Space_Words_FSC_1                  : "Letras en el espacio",
  VIS_NAT_3D_Coloring_Zeppelin                 : "Colorear en 3D",

  school_planifications_intro:"¿Qué planificaremos hoy?",
  school_planifications_library_title:"Biblioteca de Planificaciones",
  school_planifications_ai_desc: "Generador de Planificaciones de Clase asistidas por inteligencia artificial (IA)",
  pg_gen_1: "¡Muy Bien! vamos a crear una propuesta de planificación de clase con esa configuración pedagógica.",
  pg_gen_2: "Perfecto, estamos creando una propuesta de planificación de clase con las características que nos diste.",
  pg_gen_3: "Genial, crearemos una propuesta de  planificación de clase para ti.", 
  pg_hello_1:"Hola, planifiquemos juntos una clase.",
  pg_hello_2:"Hola, bienvenido a nuestro generador de planificaciones.",
  pg_hello_3:"Hola, vamos a planificar una clase. Puedes escribirme o hablarme directamente al apretar el micrófono y así saber lo que necesitas.",

  pg_chat_placeholder: "Escribe tu mensaje aquí",
  pg_p_save: "Guardar",
  pg_p_download: "Descargar",
  pg_p_copy: "Copiar",
  pg_p_make_similar: "Hacer otra similar",
  school_planifications_library_desc: "Aquí puedes encontrar tus planificaciones guardadas",
  pg_p_delete_title:"Eliminar planificacion",
  pg_p_delete_desc:"Esta seguro de eliminar esta planificacion? esta accion no es reversible",
  pg_p_delete_button:"Borrar",

  title_download_app: "Descarga la App",

  LIN_VIS_Identifies_Beneficial_Harmful_Elements  : "Identifica palabras que representan elementos beneficiosos y nocivos para el medio ambiente.",
  LIN_KIN_Tracing_Upper_Practice_1                : "Trazado de letras mayúsculas ligadas de la A a la F.",
  LIN_KIN_Tracing_Lower_Practice_1                : "Trazado de letras minúsculas ligadas de la A a la F.",
  LIN_KIN_Tracing_Upper_Practice_2                : "Trazado de letras mayúsculas ligadas de la G a la L.",
  LIN_KIN_Tracing_Lower_Practice_2                : "Trazado de letras minúsculas ligadas de la G a la L.",
  LIN_KIN_Tracing_Upper_Practice_3                : "Trazado de letras mayúsculas ligadas de la M a la P.",
  LIN_KIN_Tracing_Lower_Practice_3                : "Trazado de letras minúsculas ligadas de la M a la P.",
  LIN_KIN_Tracing_Upper_Practice_4                : "Trazado de letras mayúsculas ligadas de la Q a la V.",
  LIN_KIN_Tracing_Lower_Practice_4                : "Trazado de letras minúsculas ligadas de la Q a la V.",
  LIN_KIN_Tracing_Upper_Practice_5                : "Trazado de letras mayúsculas ligadas de la W a la Z y dígrafos (ch, ll, rr).",
  LIN_KIN_Tracing_Lower_Practice_5                : "Trazado de letras minúsculas ligadas de la W a la Z y dígrafos (ch, ll, rr).",
  LIN_VIS_Association_Initial_Letter_Word_3       : "Bachillerato",
  LIN_VIS_Read_Texts_Fluently                     : "Leer textos de forma fluida.",
  LIN_VIS_Sort_Alpha                              : "Ordena alfabéticamente.",
  LIN_VIS_Article_Definite_Indefinite             : "Completa oraciones con artículos definidos e indefinidos.",
  LIN_VIS_Write_Nouns_Commons_Proper              : "Sustantivos propios y comunes.",
  LIN_VIS_Nouns_Plural_Singular                   : "Contar sustantivos en plural y singular.",
  LIN_VIS_Noun_Gender                             : "Utiliza el sustantivo según el género.",
  LIN_VIS_Relate_Nouns_Individual_Colectives      : "Relaciona sustantivos individuales y colectivos.",
  LIN_VIS_Use_Qualifying_Adjectives_Correctly     : "Selecciona adjetivos calificativos según el contexto.",
  LIN_VIS_Select_Demonstrative_Adjectives         : "Aplica los adjetivos demostrativos según el contexto.",
  LIN_VIS_Replace_Subject_Personal_Pronoun        : "Reemplaza sujeto por pronombre personal.",
  LIN_VIS_Recognizes_Words_Same_Syllable          : "Reconoce palabras que contienen la misma sílaba.",
  LIN_VIS_Consonantal_Groups_Find_Ge_Gi           : "Distinguir palabras con los grupos consonánticos Ge-Gi.",
  LIN_VIS_Apply_Plural_Words_3                    : "Utiliza palabras en plural.",
  LIN_KIN_Tracing_Upper_Practice_Script_1   : "Trazado de letras mayúsculas script de la A a la F.",
  LIN_KIN_Tracing_Lower_Practice_Script_1   : "Trazado de letras minúsculas script de la A a la F.",
  LIN_KIN_Tracing_Upper_Practice_Script_2   : "Trazado de letras mayúsculas script de la G a la L.",
  LIN_KIN_Tracing_Lower_Practice_Script_2   : "Trazado de letras minúsculas script de la G a la L.",
  LIN_KIN_Tracing_Upper_Practice_Script_3   : "Trazado de letras mayúsculas script de la M a la P.",
  LIN_KIN_Tracing_Lower_Practice_Script_3   : "Trazado de letras minúsculas script de la M a la P.",
  LIN_KIN_Tracing_Upper_Practice_Script_4   : "Trazado de letras mayúsculas script de la Q a la V.",
  LIN_KIN_Tracing_Lower_Practice_Script_4   : "Trazado de letras minúsculas script de la Q a la V.",
  LIN_KIN_Tracing_Upper_Practice_Script_5   : "Trazado de letras mayúsculas script de la W a la Z y dígrafos (ch, ll, rr).",
  LIN_KIN_Tracing_Lower_Practice_Script_5   : "Trazado de letras minúsculas script de la W a la Z y dígrafos (ch, ll, rr).",
  LIN_VIS_Qualifying_Adjective_1            : "Asociación de letra inicial con la palabra",
  LIN_VIS_Recognize_Diphthong_Words         : "Identifica palabras que contienen diptongo",
  LIN_VIS_Identify_Hiatus_Words             : "Identifica el hiato en la palabra.",
  LIN_VIS_Identify_Tonic_Atonic_Syllable    : "Identifica la sílaba tónica y átona en palabras.",
  LIN_VIS_Identify_Serious_Words            : "Identifica y escribe palabras graves.",
  LIN_VIS_Identify_Esdrujulas_Words         : "Identifica y escribe palabras esdrújulas.",
  LIN_VIS_Identify_Sobresdrújulas_Words     : "Identifica y escribe palabras sobreesdrújulas",
  LIN_VIS_Apply_Rules_NV_DV_BV_Venir        : "Aplica correctamente las reglas NV - DV - BV - Venir.",
  LIN_VIS_Correct_Use_AHA                   : "Aplica correctamente el uso del ahí, hay, ay",
  LIN_VIS_Write_Nouns_4                     : "Escribe sustantivos aplicando las reglas ortográficas.",
  LIN_VIS_Association_Initial_Letter_Word_4 : "Utiliza los adjetivos adecuados atendiendo al contexto.",
  LIN_VIS_Identify_Verb_Time                : "Identifica los tiempos verbales en un texto.",
  LIN_VIS_Identify_Conjunctions             : "Identifica las Conjunciones",
  LIN_VIS_Identify_Subject_Predicate        : "Identifica al sujeto y predicado en una oración.",

  LIN_VIS_Consonantal_Groups_Find_Gue_Gui : "Reconocer palabras que contengan los sonidos GUE y GUI.",
  LIN_VIS_Conjugation_Verb                : "Conjugación de verbos.",
  LIN_VIS_Consonant_Cluster_3             : "Reconoce palabras que se escriben con el grupo consonántico mp y mb.",
  LIN_VIS_Identifies_Diminutives_3        : "Identifica los diminutivos.",
  LIN_VIS_Write_Correct_Use_B             : "Aplica correctamente la letra b.",
  LIN_VIS_Write_Correct_Use_H             : "Aplica correctamente la letra h.",

  digital_paper: "Biblioteca Papel Digital",
  digital_paper_description: "Consulta los cuadernos Caligrafix en su versión digital.",

  LIN_VIS_Relationship_Verbs         : "Relación de verbos con imagen.",
  LIN_VIS_Demonstrative_Pronouns     : "Pronombres demostrativos.",
  LIN_VIS_Order_Syllables_Accordingly: "Ordena las sílabas y crea palabras utilizando las sílabas ge-gi-je-ji.",
  LIN_VIS_Figurative_Language_3      : "Interpreta estrofas en lenguaje figurado.",
  LIN_VIS_Apply_Articles_4           : "Aplica los artículos de forma correcta en una oración.",
  LIN_VIS_Personal_Pronouns_4        : "Sustituye de forma correcta sustantivos por pronombres.",
  LIN_VIS_Recognize_Nouns            : "Reconoce sustantivos a partir de pronombres demostrativos.",

  LIN_VIS_Classify_Words_Accordingly_3: "Clasificar palabras según correspondan a las sílabas CE - CI.",

  LIN_KIN_Accent_Sharp_Words               : "Selecciona y escribe palabras agudas.",
  LIN_VIS_Identify_Demonstrative_Adjectives: "Identifica adjetivos demostrativos en un texto.",
  LIN_VIS_Adverds_Text_Oral_Written        : "Comprender la función de los adverbios en textos orales y escritos.",
  LIN_VIS_Use_Connectors_Sentence          : "Utiliza los conectores atendiendo de acuerdo a la oración.",
  LIN_VIS_Recognize_Prefixes_Suffixes      : "Reconoce los prefijos y sufijos.",
  LIN_KIN_Distinguish_Word_Family          : "Distinguir familia de palabras.",

  LIN_VIS_Recognize_Strong_Soft_3               : "Identifica palabras que llevan güe o güi.",
  LIN_VIS_Words_Consonant_Groups                : "Distinguir palabras con grupos consonánticos nf y nv.",
  LIN_KIN_Identify_Word_Families_3              : "Identifica familias de palabras.",
  LIN_VIS_Apply_Prefix_3                        : "Aplica el prefijo en una oración.",
  LIN_VIS_Suitable_Connectors_3                 : "Utiliza los conectores adecuados.",
  LIN_VIS_Reading_Comprehension_Story_3         : "Comprensión lectora de una leyenda.",
  LIN_VIS_Write_Letter_3                        : "Escribe una carta.",
  LIN_VIS_Identify_Prepositions                 : "Identifica las preposiciones.",
  LIN_VIS_Reading_Comprehension_Sayings_Proverbs: "Comprensión lectora (Lenguaje figurado: dichos y refranes).",

  LIN_VIS_Reading_Comprehension_Legend_3         : "Comprensión lectora de un cuento.",
  LIN_VIS_Reading_Comprehension_Non_literary     : "Comprende un texto no literario.",
  LIN_VIS_Apply_Suffix_3                         : "Aplica el sufijo que corresponde o no.",
  LIN_VIS_Understands_Texts_Non_literary_Comic   : "Comprende un texto basado en comic.",
  LIN_KIN_Machine_Recognize_Parts_News           : "Reconocer las partes de una noticia.",
  LIN_VIS_Reading_Comprehension_News_3           : "Comprende textos noticiosos.",

  LIN_VIS_Understands_Texts_Poem_P1   : "Autoevaluación lectura de poema.",
  LIN_VIS_Understands_Texts_Interview : "Comprende un texto basado en una entrevista.",
  LIN_VIS_Understand_Words_Spelling   : "Comprende el origen de las palabras y su importancia en la ortografía.",
  LIN_VIS_Understands_Texts_Poem_P2   : "Comprende un texto basado en el poema.",

  LIN_VIS_Reading_Comprehension_Poem_3  : "Interpreta el lenguaje figurado en un poema.",
  VIS_NAT_3D_Coloring_Chiloe            : "Iglesia Chiloé.",
  LIN_VIS_Reading_Comprehension_Myth_P2 : "Comprensión lectora (Mito).",
  LIN_VIS_Read_Comics_Comprehensively   : "Leer comprensivamente las imágenes y textos de un cómic.",
  MUS_VIS_Runner_Notes_Recipe           : "Identifica los textos instruccionales (Receta).",
  LIN_VIS_Informative_Marker_Biography  : "La biografía 2.",
  LIN_VIS_Understands_Texts_News_P2     : "La noticia.",
  LIN_VIS_Understand_Text_Story_P1      : "Autoevaluación comprende un texto basado en el cuento.",
  LIN_VIS_Understand_Text_Story_P2      : "Comprende un texto basado en el cuento.",
  LIN_VIS_Reading_Comprehension_Fable_P1: "Autoevaluación comprensión lectora (Fábula).",
  LIN_VIS_Reading_Comprehension_Fable   : "Comprensión lectora (Fábula).",
  LIN_VIS_Reading_Comprehension_Myth_P1 : "Autoevaluación comprensión lectora (Mito).",
  LIN_VIS_The_Biography_P1              : "Autoevaluación la biografía.",
  LIN_VIS_The_Biography_P2              : "La biografía.",
  LIN_VIS_Understands_Texts_News_P1     : "Autoevaluación la noticia.",
  LIN_VIS_Identify_Semantic_Fields      : "Identifica los campos semánticos.",
  LIN_VIS_Understand_Text_Poster        : "Comprende un texto basado en un afiche.",
  VIS_NAT_3D_Coloring_Beto_Rocket       : "Beto jetpack.",

  KIN_LIN_Letters_Script_Upper_TLN2S       : "Trazado de consonantes en mayúsculas script.",
  KIN_LIN_Letters_Script_Lower_TLN2S       : "Trazado de consonantes en minúsculas script.",
  LIN_VIS_Identifying_Syllabic_Segmentation: "Identificando Segmentación silábica.",
  LIN_VIS_Same_Final_Syllable_2            : "Misma Sílaba Final.",
  LIN_VIS_Vocals_Script_Upper              : "Trazado de las vocales en mayúsculas script.",
  LIN_VIS_Vocals_Script_Lower              : "Trazado de las vocales en minúsculas script.",
  LIN_VIS_Letters_Script_Upper             : "Trazado de consonantes en mayúsculas script.",
  LIN_VIS_Letters_Script_Lower             : "Trazado de consonantes en minúsculas script.",
  MAT_VIS_Identify_Location_Concepts       : "Identificar conceptos de ubicación: Encima, debajo, dentro, fuera.",
  MAT_VIS_Perception_Object_Position       : "Percepción de un objeto según posición.",
  MAT_KIN_Identify_Directions_Birds        : "Identificar direccionalidad:izquierda- derecha, arriba - abajo.",
  MAT_VIS_Orientation_Before_After_1       : "Orientaciones temporales empleando nociones y relaciones de secuencias (antes-después) 1.",
  MAT_VIS_Orientation_Before_After_2       : "Orientarse temporalmente empleando nociones y relaciones de secuencias (antes-después) 2.",
  MAT_VIS_Orientation_Before_After_3       : "Orientarse temporalmente empleando nociones y relaciones de secuencias (antes-después) 3.",
  MAT_VIS_Classify_By_Attributes_Size      : "Clasificar por dos o tres atributos a la vez (Tamaño).",
  MAT_VIS_Classify_By_Attributes_Weight    : "Clasificar por dos o tres atributos a la vez Peso (liviano-pesado).",
  MAT_VIS_Quantify_Compare_Quantities_1    : "Emplear los números, para contar, identificar, cuantificar y comparar cantidades 1.",
  MAT_VIS_Quantify_Compare_Quantities_2    : "Emplear los números, para contar, identificar, cuantificar y comparar cantidades 2.",
  MAT_VIS_Quantify_Compare_Form_1          : "Emplear los números, para contar, identificar, cuantificar y comparar cantidades.",
  MAT_VIS_Serialization_Quantity_1         : "Clasificar por seriación por cantidad y números.",
  MAT_VIS_Sides_Vertices_Figures           : "Propiedades básicas de las figuras geométricas; número de lados y vértices.",
  MAT_VIS_Identify_Figures_3D              : "Identificar atributos de cuerpos geométricos 3D.",
  MAT_VIS_Create_Patterns_Elements_2       : "Crear patrones de dos o tres elementos 2.",

  LIN_VIS_Memorice_Initial_Syllable_2 : "Sílaba Inicial Memorice 2.",
  LIN_VIS_Manipulation_Sylabic_3      : "Formar palabras con Manipulación Silábica 2.",
  MAT_VIS_Attributes_Figures_2D_3D_1  : "Atributos de figuras 2D y 3D 1.",
  MAT_VIS_Create_Patterns_Elements_1  : "Crear patrones de dos o tres elementos 1.", 
  MAT_VIS_Count_To_39                 : "Conteo hasta el 39.",
  MAT_VIS_Number_Sequence_49          : "Secuencia numérica hasta el 49.",

  MAT_VIS_Time_Orientation_Clock: "Orientarse temporalmente con el uso del reloj.",
  MAT_VIS_Tens_Composition_Tower: "Composición del 10; introducir la decena.",
  MAT_VIS_Monster_Numbers       : "Emplear los números de las cantidades del 11 al 19.",
  MAT_KIN_Tracing_Numbers_9     : "Trazado de los números del 0 al 9.",
  MAT_VIS_Number_Sequence_Maze_1: "Secuencia numérica hasta el 29.",
  MAT_VIS_Number_Sequence_69    : "Emplear la secuencia numérica hasta el 69.",
  MAT_VIS_Count_Tens_10         : "Conteo decenas 10.",
  MAT_VIS_Count_Five_By_Five    : "Contemos de 5 en 5.",

  VIS_LIN_3D_Coloring_Rocket_Lnn3             : "Marcador creatividad aumentada (cuerpos geométricos)",
  MAT_VIS_Quantity_Relationship_92            : "Relación número - cantidad hasta el 92.",
  MAT_VIS_Additive_Composition_Decomposition  : "Composición y descomposición aditiva.",
  MAT_VIS_Subtraction_Until_20                : "Sustracción hasta el 20.",
  MAT_VIS_Measuring_Objects_1                 : "Medición de objetos.",
  LIN_VIS_Join_Words_Rhyme_1                  : "Reconocer palabras que riman.",
  LIN_VIS_Initial_Vocal_Couples_1             : "Parejas de Vocal Inicial.",
  
  LIN_VIS_Ocean_Stars_To_10          : "Rescatando del océano las estrellas de mar hasta el 10",
  MAT_VIS_Identify_Number_Rings_1_9  : "Emplear los números de las cantidades del 11 al 19.",
  MAT_VIS_Composition_Additive_1     : "Composición y descomposición aditiva.",
  MAT_VIS_Use_Numbers_1_To_29        : "Emplear los números de las cantidades del 1 al 29.",
  MAT_VIS_Completing_Graphics        : "Completación de gráficos.",

  upload_csv_file: "Subir .CSV"
};
 
//module.exports = es;

export default es;