/* @flow */

import React from 'react';
import { Paper, InfoTooltip } from '../components';
import {
  SchoolDashboardNavContainer, SchoolDashboardHeadingContainer,
  HeaderContainer, SchoolGeneralIntelligenceProgressContainer,
  DataDisplayGeneralSchoolContainer,
  SchoolGeneralCoursesProgressContainer,
  PageTitleContainer
} from '../containers';
import { Col, Row } from 'reactstrap';
import Translations from '../hocs/TranslationsHelper';


import { PdfData } from '../../pdfer/Pdfable';
import { SchoolReportPdf } from '../pdfview/SchoolView';
import topScroll from '../hocs/topScroll';

/**
 * screen for the school Progress
 *
 * @method SchoolProgress
 *
 */
  

const SchoolProgress = ({ translations }) => { 
  topScroll();
  
  return (
    <div id="school-dashboard" className="school-dashboard layout-base">
      <PageTitleContainer pageTitle="title_school_progress" />
      <HeaderContainer />
      <SchoolDashboardHeadingContainer /> 
        <Paper.Container lead>
          <SchoolDashboardNavContainer currentTab="progress" pdfFilter="course_report" pdfTarget={({user})=>("pleiq_report_school_"+ user.name)}/>
              
          <Row>
            <Col xs="12">
              <Paper.Title>
                <PdfData path={"school_progress._intelligences"}>
                  <h1>
                    { translations.t('school_current_general_progress') }{' '}
                    <InfoTooltip message="tooltips_current_school_progress" />
                  </h1>
                </PdfData>

              </Paper.Title>
            </Col>
            <Col xs="12">
              <Paper.Content>
                <PdfData path={"school_progress.intelligences"}>
                  <SchoolGeneralIntelligenceProgressContainer />
                </PdfData>
              </Paper.Content>
            </Col>

            <Col xs="12">
              <Paper.Title>
                <PdfData path={"school_progress._intelligence_analysis"}>
                  <h1 className="mt-5">
                    { translations.t('intelligences_progress_analysis') } {' '}
                    <InfoTooltip message="tooltips_intelligences_progress_analysis" />
                  </h1>
                </PdfData>
              </Paper.Title>
            </Col>
            <Col xs="12">
              <Paper.Content>
                <PdfData path={"school_progress.intelligence_analysis"} pdf={{newpage:true}}>
                  <DataDisplayGeneralSchoolContainer />
                </PdfData>
              </Paper.Content>
            </Col> 

            <Col xs="12">
              <Paper.Title> 
                  <h1 className="mt-5">
                    { translations.t('school_current_progress_by_course') }{' '}
                    <InfoTooltip message="tooltips_current_school_courses_progress" />
                  </h1> 
              </Paper.Title>
            </Col>
            <Col xs="12">
              <div id = "paper-contentss" >
                <Paper.Content>
                        <SchoolGeneralCoursesProgressContainer />
                </Paper.Content>
              </div>
            </Col>
          </Row>
        </Paper.Container> 
      <SchoolReportPdf/>
    </div>
  );
};

export default Translations(SchoolProgress);
