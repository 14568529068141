/* @flow */

import React from 'react';
import { StudentInfoList, SubmitButton } from '../components';
import { compose, lifecycle, shouldUpdate, withHandlers } from 'recompose';
import { studentsSearch } from '../../actions/students';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Translations from '../hocs/TranslationsHelper';
import deepEqualsUpdate from '../../helpers/deepEqualsUpdate';
import { Col, Row } from 'reactstrap';

/**
 * Component to display the search results for students
 *
 * @class SchoolSearchStudentContainer
 *
 * In case loading state is false and students is empty, an empty component is shown
 */
const SchoolSearchStudentContainer = ({ students, loading, translations, hasNextPage, requestMoreItems }) => {
  const noResults = students.length === 0 && !loading;

  return (
    <Row>
      <Col xs="12">
        {noResults ? (<NoSearchResults translations={translations} />) : false}
        <StudentInfoList showCourseName students={students} />
      </Col>
      {hasNextPage && <LoadMore loading={loading} translations={translations} onClick={requestMoreItems} />}
    </Row>
  );
};

const NoSearchResults = ({ translations }) => <h5>{translations.t('students_search_not_found')}</h5>;

const LoadMore = ({ translations, loading, onClick }) => (
  <Col xs="12" className="mt-4 text-center">
    <SubmitButton loading={loading} onClick={onClick} className="btn btn-small btn-primary--green">
      {translations.t('load_more')}
    </SubmitButton>
  </Col>
);

const dispatchSearch = (dispatch, query) => {
  /*
  * Reset the reducer each time this component renders and request for data
  */
  dispatch({ type: 'INIT_STUDENTS_SEARCH' });
  dispatch(studentsSearch({ query, page: 0 }));
};

export const ContainerLifecycle = {
  componentWillMount() {
    const { dispatch, query } = this.props;

    dispatchSearch(dispatch, query);
  },
  componentWillUpdate(nextProps) {
    const { dispatch, query } = nextProps;

    if (query !== this.props.query) {
      dispatchSearch(dispatch, query);
    }
  }
};

export const ContainerHandlers = {
  requestMoreItems: ({ dispatch, currentPage, query }) => () => {
    dispatch({ type: 'START_STUDENTS_SEARCH' });
    dispatch(studentsSearch({ query, page: currentPage }));
  }
};

const ContainerComposed = compose(
  withHandlers(ContainerHandlers),
  lifecycle(ContainerLifecycle),
  shouldUpdate(deepEqualsUpdate),
  Translations
)(SchoolSearchStudentContainer);

const mapStateToProps = ({ students_search }, ownProps) => {
  const { params } = ownProps.match;
  const { query } = params;
  const { currentPage, hasNextPage, loading, students } = students_search;

  return {
    currentPage: currentPage || 0,
    hasNextPage,
    loading,
    students,
    query
  };
};

const ContainerConnected = connect(
  mapStateToProps
)(ContainerComposed);

export default withRouter(ContainerConnected);
