/* @flow */

export function getAllCourses() {
  return {
    type: 'REQUEST_ALL_COURSES'
  };
}

export function getCourses(level: Object) {
  return {
    type: 'REQUEST_COURSES',
    payload: {
      level
    }
  };
}

export function addCourse(course: Object) {
  return {
    type: 'ADD_COURSE',
    payload: {
      course
    }
  };
}

export function updateCourse(course: Object) {
  return {
    type: 'UPDATE_COURSE',
    payload: {
      course
    }
  };
}

export function deleteCourse(course: Object) {
  return {
    type: 'DELETE_COURSE',
    payload: {
      course
    }
  };
}

export function removeCourses(courses: Array<Object>) {
  return {
    type: 'REMOVE_COURSES',
    payload: {
      courses
    }
  };
}

export function removeCoursesLevel(level: Object) {
  return {
    type: 'REMOVE_COURSES_LEVEL',
    payload: {
      level_id: level.id
    }
  };
}

export function removeCoursesAssociation(courses: Array<Object>) {
  return {
    type: 'REMOVE_COURSES_ASSOCIATION',
    payload: {
      courses
    }
  };
}

export function editCourse(course: Object) {
  return {
    type: 'SHOW_COURSE_EDIT_MODAL',
    payload: {
      course
    }
  };
}

export function createCourse() {
  return {
    type: 'SHOW_COURSE_EDIT_MODAL'
  };
}

export function closeEditCourseModal() {
  return {
    type: 'HIDE_COURSE_EDIT_MODAL'
  };
}

export function setModalCourse(course: Object) {
  return {
    type: 'SET_MODAL_COURSE',
    payload: {
      course
    }
  };
}

export function coursesSearch({ query, page, clean = false }: Object) {
  return {
    type: 'REQUEST_SEARCH_COURSES',
    payload: {
      query,
      page: page + 1,
      clean
    }
  };
}
