
import React from 'react'; 
import {Nav, NavItem } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { Paper } from '../../components';
 
export const adminNavItems = [{
    name: 'invitations',
    translation: 'invitations', 
    url: '/invitations',
    license: ['pleiq_cx_plus','pleiq_cx']
  },{
    name: 'users',
    translation: 'users', 
    url: '/users',
    license: ['pleiq_cx_plus','pleiq_cx']
}]
 

const SchoolAdminNav = ({currentTab}) => (
  <Paper.Nav>
    <Paper.NavItem NavItem>
      <NavLink to="/school/invitations">Invitations</NavLink>
    </Paper.NavItem>
    <Paper.NavItem>
      <NavLink to="/school/users">Users</NavLink>
    </Paper.NavItem>
  </Paper.Nav>
)
export default SchoolAdminNav;