/* @flow */

import React from 'react';
import { Col, Row } from 'reactstrap';
import { Paper } from './';
import type { Level, TranslationsObject } from './types';
import Translations from '../hocs/TranslationsHelper';
import { LevelDataFormContainer, SchoolCoursesFormContainer } from '../containers/';
import SchoolLevelBooksFormContainer from '../containers/SchoolLevelBooksFormContainer';
import LicenseFilter from '../containers/LicenseFilter';


type Props = {
  level?: Level,
  closeModal: () => mixed,
  translations: TranslationsObject
};

/**
 * component to display a form to edit/create a level info
 *
 * @method LevelEditForm
 * @param  {Object}         level            - optional parameter, used to fill the name input if passed in
 * @param  {function}       closeModal       - callback that gets fired when the close button gets clicked
 * @param  {Object}         translations     - object containing app translations
 *
 */
const LevelEditForm = ({ level, closeModal, translations }: Props) => {
  const titleTranslation = level ? 'edit_level' : 'create_level';
  const coursesSection = (
    <Col xs="12">
      <Paper.Nav>
        <Paper.NavItem iconClass="pleiq-icon-icon_course" label={translations.t('level_courses')} />
      </Paper.Nav>
      <SchoolCoursesFormContainer level={level} />
    </Col>
  );
  const booksSection = ( 
    <LicenseFilter filter={["pleiq_cx", "pleiq_cx_plus"]}>  
      <Col xs="12">
        <Paper.Nav>
          <Paper.NavItem iconClass="pleiq-icon-icon_course" label={translations.t('level_books')} />
        </Paper.Nav>
        <SchoolLevelBooksFormContainer level={level} />
      </Col>
    </LicenseFilter>
  );

  return (
    <div className="modal-edit-form">
      <span className="modal-close pleiq-icon-icon_plus" onClick={closeModal} />
      <Paper.Container>
        <Row>
          <Col xs="12">
            <Paper.Title>
              <h1>{translations.t(titleTranslation)}</h1>
            </Paper.Title>
          </Col>
          <Col xs="12">
            <Paper.Nav>
              <Paper.NavItem iconClass="pleiq-icon-icon_level" label={translations.t('level_data')} />
            </Paper.Nav>
            <LevelDataFormContainer level={level} />
          </Col>
          {level && coursesSection} 
        </Row>
      </Paper.Container>
    </div>
  );
};

export default Translations(LevelEditForm);
