
import React, { useState } from 'react';
import { Field } from 'redux-form';
import { Button, Dropdown } from '../../components';
import { Label, FormGroup } from 'reactstrap';

import classNames from 'classnames';
import { useData } from '../../hocs/DataRetriever';
import TranslationsHelper from '../../hocs/TranslationsHelper';

const renderField  = TranslationsHelper(  ({mapName, searchResult,searchLoading, handleSearch, translations, ...otherProps}) =>{ 
 
    const { input, label,   placeholder, type, meta } = otherProps;
    const { dirty, error, submitting, submitFailed, active }:ReduxFormMeta = meta;
    const showErrors = !active && (submitFailed || dirty) && error;
    const formColor = classNames({
      danger: showErrors
    });

    var value = input.value || [];

    return (
        <FormGroup color={formColor}>
          {/* {label && <Label for={name}>{label}</Label>} */}
          <FormGroup>
              {/* <Label>{translations.t('add_book')}</Label> */}
              <Dropdown
              options={(searchResult||[]).filter(({id})=>!value.some((p)=>p.id==id)).map((v)=>({
                  label: (mapName)?  mapName(v): v.name,
                  value: v
              }))}
              onInputChange={handleSearch}
              isLoading={searchLoading}
              placeholder={translations.t('add_' + label)}
             
              onChange={(v)=>{
                  input.onChange([...value, v.value ]); 
              }}
              disabled={submitting}  
              noResultsText={false}
              // this prevents react-select to try to do any option filtering,
              // as we already handle the search
              
              searchable
              />
          </FormGroup>
  
          <div style={{padding: "8px"}}>
              <div className="course-inputs">
              {value.map((v, index ) => (
                  <div className="course-inputs-group" key={index}>
                      <div className="course-inputs-input"> 
                          <div>
                              <Label style={{color: "rgba(0, 0, 0, 0.4)"}}  >
                                  { (mapName)? mapName(v): v.name }
                          </Label> 
                          
                          </div>
                      </div>
                      <Button type="ball" onClickButton={() => input.onChange(value.filter((i2)=>i2.id!=v.id))} disabled={submitting}>
                          <span className="pleiq-icon-icon_minus" />
                      </Button>
                  </div>
              ))} 
              </div>
          </div>  
        </FormGroup>
      );
      /*
    <ul>
      <li>
        <button type="button"  >
          Add 
        </button>
      </li>
      {value.map(({id,name}, index) => (
        <li key={index}>
          <button
            type="button"
            title="Remove Hobby"
            onClick={()=>onChange(value.filter((p)=> p.id!==id))}
          />
          <span>{name}</span>
        </li>
      ))} 
    </ul>*/
});
  
export const EntityArrayField = ({name, mapName,searchUrl})=> {

    const [search,setSearch] = useState("");
    const {data  , loading} = useData({id: "school_data_"+ name, url: searchUrl+"?q=" + search});
    const {body, status} = data;

    var tbody = body;
    if(tbody && !Array.isArray(tbody))
      tbody = tbody.data;
    return <Field mapName={mapName} validate={[(e)=> (e && e.length > 0)? null: "need_"+name]} name={ name } label={name} searchResult={tbody} searchLoading={loading} handleSearch={(s)=>setSearch(s)} component={renderField} />
}

export default EntityArrayField;
