/* @flow */

import { responseHasError } from '../diplomatico/reducers/helper';
import type { Action } from '../diplomatico/types';
import { enableInitializing } from 'initializable-reducer';
import mergeWithReplace from '../helpers/mergeWithReplace';

const initialState = {};

function intelligenceProgress(state: Array<Object> = initialState, { type, payload }: Action) {
  switch (type) {
    case 'RESPONSE_INTELLIGENCE_PROGRESS':
      if (responseHasError(payload)) {
        return state;
      }

      const { child_id } = payload._meta.payload.request;

      const newProgress = {
        [child_id]: payload.body
      };

      return mergeWithReplace({}, [state, newProgress]);
    default:
      return state;
  }
}

export {
  intelligenceProgress,
  initialState
};

export default enableInitializing(intelligenceProgress);
