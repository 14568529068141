/* @flow */

import React from 'react';
import pleiqIconSchool from '../../img/pleiq_icon_school.png';

type Props = {
  schoolName: string
};

/**
 * component to the school name next to an icon
 *
 * @method SchoolDashboardHeading
 * @param  {string}  schoolName     - the name of the current logged in school
 *
 */
const SchoolDashboardHeading = ({ schoolName }: Props) => {
  return (
    <div className="container-header-banner bg-secondary">
      <div className="school-heading">
        <img className="school-heading-image" src={pleiqIconSchool} alt={`${schoolName} icon`} />
        <h1 className="school-heading-name">{schoolName}</h1>
      </div>
    </div>
  );
};

export default SchoolDashboardHeading;
