/* @flow */

import { responseHasError } from '../diplomatico/reducers/helper';
import type { Action } from '../diplomatico/types';

const initialState = [];

function caligrafixBooks(state: Array<Object> = initialState, { type, payload }: Action) {
  switch (type) {
    case 'RESPONSE_CALIGRAFIX_BOOKS':
      if (responseHasError(payload)) {
        return state;
      }
     // console.log("we have the CALIGRAFIX BOOKS! ", payload);
      
      return payload.body
    default:
      return state;
  }
}

export {
  caligrafixBooks,
  initialState
};

export default caligrafixBooks;
