 

import React from 'react';
import {connect} from 'react-redux' 

import {withPdfHandlers,  PdfOnlyData, withPdfHandlers2 } from "../../pdfer/Pdfable";
//import { lifecycle, compose } from "recompose"; 

import { getCourseStudents } from '../../selectors/students'; 
import {  StudentPdfCourse, StudentCommentsPdfSelection, StudentCommentsPdfCourse, StudentCaligrafixPdfCourse } from './StudentView';
import Translations from "../hocs/TranslationsHelper";
import { getCourseById } from "../../selectors/courses";
import { getStudents } from "../../actions/students"; 
import { CoursesCompareChartPdf } from "../containers/CoursesCompareChartContainer";
import { SchoolCoursesProgressPdf } from "../containers/SchoolCoursesProgressContainer";
import { getSchoolGeneralCoursesProgress } from '../../actions/schoolGeneralCoursesProgress';

import LicenseFilter from '../containers/LicenseFilter';



  
  

const mapStateToProps  = (state, ownProps)=>{
    const {course_name} = ownProps; 
    var {pdf} = state;
    var {course_id,level_id} = pdf.props; 

    var course= getCourseById(state, course_id); 
    return {
        students: getCourseStudents(state, course_id),
        course_name: course_name,
        course: course,
        course_id:  course_id,
        level_id: level_id,
        pre_done: state.activeRequests===0
    };
}
var CourseReportDiv =  ( {course_name,level_id, students, translations}) =>(
    <div> 
        
        <LicenseFilter filter={["pleiq_school", "pleiq_cx_plus"]}>  
            <PdfOnlyData path="course.title" filter="course_report"> 
                <h1> 
                    {translations.t("report_for_course") } <span>
                        {course_name}
                    </span> 
                </h1> 
            </PdfOnlyData>
            <SchoolCoursesProgressPdf level_id={level_id} path="course.course_progress"/>
        </LicenseFilter>

        <CoursesCompareChartPdf level_id={level_id} path="course.course_compare"selectedOption="month"/>
        {
            (students.length >0)? students.map( 
                (student) =>(
                    <div key={student.id} > 
                    <LicenseFilter filter={["pleiq_cx", "pleiq_cx_plus"]}>  
                       <StudentCaligrafixPdfCourse path={"student."+student.id+".caligrafix"} student_id={student.id}/>
                    </LicenseFilter>
                    <LicenseFilter filter={["pleiq_school", "pleiq_cx_plus"]}>  
                       <StudentPdfCourse path={"student."+student.id+".content"} student_id={student.id}/>
                    </LicenseFilter>
                       <StudentCommentsPdfCourse path={"student."+student.id+".comments"} student_id={student.id}/>
                    </div>
                )
            ): <p></p>
        }
    </div>
)
CourseReportDiv = Translations (CourseReportDiv);
// <PdfData path="course.nl" pdf={{newpage:true}}/>
//<CoursesCompareChartContainer levelId={level_id} />

//export const CourseReportContainer = connect(mapStateToPropsCourseReport)(CourseReportDiv)

 
/*
CourseReportDiv = compose( lifecycle({ 
  componentDidUpdate() {
    //console.log("CourseReportDiv did update");
  },
  componentDidMount() {
   // console.log("CourseReportDiv did mount");
  }
}))(CourseReportDiv); 
*/
 
const mapDispatchToProps  = (dispatch) => ({ dispatch });



export var CourseSelection = connect(mapStateToProps ,mapDispatchToProps )( withPdfHandlers2 (
    {path:"course.current", 
    filter: "course_report",
    pre: ({course_id,level_id, course_name,dispatch}) =>{
    
        dispatch(getSchoolGeneralCoursesProgress({ level_id: level_id })); 
        dispatch( getStudents({id: course_id}));
        return true;//wait for students, ok?  
    }
})( ({ students, course_name, level_id})=>{ 
   // console.log("students:,", students);
    return  <CourseReportDiv students={students } level_id={level_id} course_name={course_name}/>  
}));
 
 