/* @flow */

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { IntelligencesNavContainer } from './';

export const StudentProgressNavContainer = ({ student_id }) => {
  return (
    <IntelligencesNavContainer general redirectRouteBase={`/school/student/${student_id}`} defaultRoute={`/school/student/${student_id}/general`} />
  );
};

const mapStateToProps = ({ intelligences }, ownProps) => {
  const { params } = ownProps.match;
  const { student_id } = params;

  return {
    student_id
  }
};

const StudentProgressNavContainerConnected = connect(
  mapStateToProps
)(StudentProgressNavContainer);

export default withRouter(StudentProgressNavContainerConnected);
