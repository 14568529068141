/* @flow */

import React from 'react';
import Select from 'react-select';
import type { RecomposeStateName, RecomposeHandler } from './types';

const arrowRenderer = (searchable) => {
  if (searchable) {
    return null;
  }

  return (
    <span className="select-custom-arrow pleiq-icon-icon_arrow_mini_down" />
  );
};

type Props = {
  value: RecomposeStateName,
  placeholder: string,
  options: Array<Object>,
  onChange: RecomposeHandler,
  searchable?: boolean
};

/**
 * A select component using React Select
 *
 * Documentation for React Select can be found here: https://github.com/JedWatson/react-select
 *
 * @method Dropdown
 * @param  {value}             value                - injected by 'recompose' as an state, this is the current value of the dropdown
 * @param  {string}            placeholder          - the placeholder text to display when no option is select
 * @param  {boolean}           searchable           - true if the dropdown should be searchable
 * @param  {Array<Object>}     options              - an array with the options to display within this component
 * @param  {Function}          onChange             - function that gets called when the value changes
 *
 * @example
 *
 *  const options = [
 *    { value: 'one', label: 'One' },
 *    { value: 'two', label: 'Two' }
 *  ];
 *
 *  <Select value="one" options={options} placeholder="Selecciona un nivel" />
 *
 */
export const Dropdown = ({ searchable, ...props }: Props) => {
  return (
    <Select
      {...props}
      searchable={!!searchable}
      arrowRenderer={() => arrowRenderer(searchable)}
      inputProps={{ 'autoComplete': 'off' }}
      clearable={false}
    />
  );
};

export default Dropdown;
